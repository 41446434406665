import { RichTextOption } from '@morpho/form';
import { RichTextInlineText, RichTextParagraph } from '../models/rich-text.model';

export enum ElementType {
  Paragraph = 'paragraph',
  Hyperlink = 'hyperlink',
  OrderedList = 'ordered_list',
  UnorderedList = 'unordered_list',
  ListItem = 'list_item',
  Mention = 'mention',
  Table = 'table',
  TableRow = 'table_row',
  TableHeaderCell = 'table_header', // todo remove
  TableDataCell = 'table_data',
  Equation = 'equation',
}

export enum EditorType {
  Full = 'full',
  Mentions = 'mentions',
}

export const EMPTY_TEXT: RichTextInlineText = {
  text: '',
};

export const EMPTY_PARAGRAPH: RichTextParagraph = {
  type: ElementType.Paragraph,
  children: [EMPTY_TEXT],
};

export const EMPTY_RICH_TEXT_OPTION: RichTextOption = {
  title: '',
  content: null,
};

export const LIST_TYPES = [ElementType.UnorderedList, ElementType.OrderedList];

export const MENTION_TRIGGER_CHAR = '@';
