import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../patterns/snack-bar/snack-bar.component';
import { SnackBarParams } from '../../patterns/snack-bar/snack-bar.model';
import { DEFAULT_ERROR_MESSAGE } from '../constants/display';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  constructor(private snackBar: MatSnackBar) {}

  message(message: string) {
    this.snackBar.open(message);
  }

  alert(message: string) {
    this.snackBar.open(message, undefined, {
      panelClass: 'is-alert',
    });
  }

  error(message: string, data?: SnackBarParams) {
    if (Object.entries(data ?? {}).length) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        panelClass: 'is-warning',
        data,
      });
    } else {
      this.snackBar.open(message, undefined, {
        panelClass: 'is-warning',
      });
    }
  }

  apiError(error: any, defaultError = DEFAULT_ERROR_MESSAGE) {
    let message = defaultError;
    let data: SnackBarParams = {};
    if (error) {
      if (typeof error === 'string') {
        message = error;
      } else {
        message = 'Unable to save. Please ensure the form is filled in correctly.';
        const nonFieldErrorKey = 'non_field_errors';
        if (error[nonFieldErrorKey]) {
          message = error[nonFieldErrorKey][0];
        }
        if (typeof error === 'object') {
          const title = 'Unable to save. Please ensure the following errors are resolved first: ';
          const children: string[] = [];
          Object.entries(error).forEach(([key, value]) => {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            children.push(`${capitalizedKey}: ${value}`);
          });
          data = { message_title: title, message_children: children };
        }
      }
    }
    this.error(message, data);
    throw new Error(JSON.stringify(error));
  }
}
