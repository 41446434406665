import { Component, forwardRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATETIME_FORMAT,
  DEFAULT_DATE_PLACEHOLDER,
  DEFAULT_TIME,
  GMT,
  TIME_ZONES,
  ZERO_OFFSET_VALUES,
} from '@morpho/core';
import * as moment from 'moment-timezone';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

@Component({
  standalone: false,
  selector: 'om-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: getCustomFormFieldProviders(forwardRef(() => DatetimeInputComponent)),
})
export class DatetimeInputComponent extends CustomFormFieldControlComponent implements OnInit {
  public timezones = TIME_ZONES;
  public timezone = GMT;

  private dateTimeMoment: moment.Moment = moment();
  public dateInputMoment: moment.Moment | null;

  readonly fallbackPlacholder = DEFAULT_DATE_PLACEHOLDER;
  public timeString = DEFAULT_TIME;
  private parseFormat = BACKEND_DATETIME_FORMAT;

  public minDate: string;
  public maxDate: string;

  onTzChange() {
    this.setTimezone();
    this.updateValue();
  }

  private getTzOffset(code: string) {
    return moment().tz(code).format('ZZ');
  }

  @Input()
  get min() {
    return this._min;
  }
  set min(min: string) {
    this.minDate = moment(min, this.parseFormat).format(BACKEND_DATE_FORMAT);
    this._min = min;
  }
  _min: string;

  @Input()
  get max() {
    return this._max;
  }
  set max(max: string) {
    this.maxDate = moment(max, this.parseFormat).format(BACKEND_DATE_FORMAT);
    this._max = max;
  }
  _max: string;

  @Input() userTz: string;

  @Input()
  get value() {
    return this._value;
  }
  set value(value: string | null) {
    this._value = value;
    this.emitChanges();

    if (!this._value) {
      this.dateInputMoment = null;
    } else {
      this.dateTimeMoment = moment.parseZone(this._value, this.parseFormat);
      this.dateInputMoment = moment(this.dateTimeMoment);
    }
    this.setDisplayValues();
  }
  _value: string | null;

  @HostBinding('class.is-wide') isWide: boolean;

  ngOnInit(): void {
    this.setTime();
    this.setTimezone();
    // Check sizing
    this.isWide = this.elementRef.nativeElement.offsetWidth > 510;
  }

  private updateValue() {
    this._value = this.dateInputMoment ? this.dateTimeMoment.format(this.parseFormat) : null;
    this.emitChanges();
  }

  onDateInputChange() {
    if (this.dateInputMoment) {
      this.dateTimeMoment.set({
        year: this.dateInputMoment.year(),
        month: this.dateInputMoment.month(),
        date: this.dateInputMoment.date(),
      });
    }
    this.updateValue();
  }

  onTimeChange() {
    this.setTime();
    this.updateValue();
  }

  private setTime() {
    const timeArray = this.timeString.split(':');
    this.dateTimeMoment.set('hours', parseInt(timeArray[0], 10));
    this.dateTimeMoment.set('minutes', parseInt(timeArray[1], 10));
  }

  onTimezoneChange() {
    this.setTimezone();
    this.updateValue();
  }

  private setTimezone() {
    this.dateTimeMoment.tz(this.timezone, true);
  }

  private getTimezoneFromOffset(offset: string) {
    if (ZERO_OFFSET_VALUES.includes(offset)) {
      this.timezone = GMT;
      return;
    }

    //* if offset matches the user's default...
    if (offset === moment().format('ZZ')) {
      //* set timezone value to the user's default timezone
      this.timezone = moment().tz() as string;
      return;
    }

    const found = this.timezones.find(({ value }) => {
      const tzOffset = this.getTzOffset(value as string);
      return offset === tzOffset;
    });
    this.timezone = (found?.value as string) ?? GMT;
  }

  private setDisplayValues() {
    const offset = this.dateTimeMoment.format('ZZ');
    this.getTimezoneFromOffset(offset);
  }
}
