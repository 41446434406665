import { Editor, Node, NodeEntry, Range, Element as SlateElement, Transforms } from 'slate';
import { ElementType } from '../../constants/rich-text.constants';
import { RichTextHyperlink, RichTextInlineText } from '../../models/rich-text.model';

function createNewHyperlink(url: string): RichTextHyperlink {
  const hyperlink: RichTextHyperlink = {
    type: ElementType.Hyperlink,
    url,
    children: [],
  };

  return hyperlink;
}

function insertUrl(editor: Editor, url: string, selection: any) {
  Transforms.select(editor, selection);
  const [, activeHyperlinkPath] = getActiveElement(editor, ElementType.Hyperlink) ?? [, null];

  if (activeHyperlinkPath) {
    const props: Partial<RichTextHyperlink> = { url };
    Transforms.setNodes(editor, props, { at: activeHyperlinkPath });
  } else {
    const isCollapsed = selection && Range.isCollapsed(selection);
    const hyperlink = createNewHyperlink(url);
    if (isCollapsed) {
      const text: RichTextInlineText = {
        text: url,
        ...Editor.marks(editor),
      };
      hyperlink.children = [text];
      Transforms.insertNodes(editor, hyperlink);
    } else {
      Transforms.wrapNodes(editor, hyperlink, { split: true });
    }
  }
}

function removeLink(editor: Editor, selection: any) {
  Transforms.select(editor, selection);
  const [, activeHyperlinkPath] = getActiveElement(editor, ElementType.Hyperlink) ?? [, null];
  if (activeHyperlinkPath) {
    Transforms.unwrapNodes(editor, {
      at: activeHyperlinkPath,
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === ElementType.Hyperlink,
    });
  }
}

function getActiveElement(editor: Editor, elementType: ElementType): NodeEntry<Node> | null {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === elementType,
  });
  return link ?? null;
}

export const HyperlinkEditor = {
  getActiveElement,
  insertUrl,
  removeLink,
};
