import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OmCache } from '@morpho/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

/*
  Right now, a simple url: Response cache.
  Only caching endpoints where response is expected to never change.
  If want to extend to be able to invalidate the cache:
    1. extend api-service to append invalidate header
    2. check for header here, and if exists remove it & clear cache.
*/

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private static CACHE_CAPACITY = 20;
  private cache: OmCache<string, HttpResponse<any>> = new OmCache(CachingInterceptor.CACHE_CAPACITY);

  // pls leave a comment when adding a url here
  private urlsToCacheRegex = [
    new RegExp('transactions/version/\\d+/docs/\\d+'), // plain/annotated termsheet pdfs
    new RegExp('compare-document-with'), // comparison termsheet pdfs, both pro-forma & versions
  ];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET' || !this.urlsToCacheRegex.some(regex => req.url.match(regex))) {
      return next.handle(req);
    }
    const cachedResponse = this.cache.get(req.url);

    if (cachedResponse) {
      return of(cachedResponse.clone());
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.addToCache(req.url, event.clone());
        }
      }),
    );
  }

  private addToCache(key: string, value: HttpResponse<any>) {
    this.cache.add(key, value);
  }
}
