import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getCdnUrl } from '@morpho/core';
import { environment, license } from '@morpho/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';

// provide pdf viewer a local copy of worker, so it doesn't fetch from cdn.jsdelivr.net
// used in node_modules/ng2-pdf-viewer/fesm2022/ng2-pdf-viewer.mjs
(window as any).pdfWorkerSrc =
  `${environment.appBaseHref}/assets/resources/pdfjs-dist.3.11.174.pdf.worker.min.mjs`.replace('//', '/');

// provide intlTelInput a local copy
(window as any).intlTelInput = `${environment.appBaseHref}/assets/resources/intl-tel-input.23.8.0.utils.js`.replace(
  '//',
  '/',
);

[
  ['link', 'assets/img/origin-markets/icon_solid.png'],
  ['apple-touch-icon', 'assets/img/origin-markets/icons/touch-icon.png'],
].forEach(([rel, url]) => {
  const link = document.createElement('link');
  link.rel = rel;
  link.href = getCdnUrl(url);
  document.head.appendChild(link);
});

if (environment.deployed) {
  enableProdMode();
}

LicenseManager.setLicenseKey(license.agGrid);

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    defaultEncapsulation: ViewEncapsulation.Emulated,
  })
  .catch(err => console.error(err));
