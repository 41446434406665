import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  AgGridUtilService,
  BACKEND_DATE_FORMAT,
  BasisType,
  DEFAULT_QUOTE_TYPE,
  DIALOG_WIDTH_MEDIUM,
  LevelsUtilService,
  UtilService,
  ValueAndLabel,
} from '@morpho/core';
import { DialogService } from '@morpho/dialog';
import { DynamicFormModalCloseEvent, DynamicFormModalParams } from '@morpho/dynamic-form';
import { Store } from '@ngrx/store';
import { SecondaryPlotMode } from '@shared-echarts/models';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, filter, forkJoin, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { PricingService, SingleLevelForPricing } from '../../../core/patterns/pricing/pricing.service';
import { StateService } from '../../../core/services/state.service';
import { ConstantObject, Constants } from '../../../models/constants';
import { DEFAULT_REGRESSION_CONFIG, RegressionData, RegressionObject } from '../../../models/regression.model';
import { RegressionService } from '../../curve-analysis/services/regression.service';
import { AddPricingTabsDialogComponent } from '../components/pricing-completion/add-pricing-tabs-dialog/add-pricing-tabs-dialog.component';
import { PricingRationaleDialogComponent } from '../components/pricing-completion/pricing-rationale-dialog/pricing-rationale-dialog.component';
import {
  ComparablesManagerDialogComponent,
  ComparablesManagerDialogEvent,
} from '../components/pricing-request/comparables-manager-dialog/comparables-manager-dialog.component';
import { DEFAULT_PRICING_BREAKDOWN } from '../constants/breakdown';
import { BondPricing, BondPricingParams, GovieBond, SecondaryBond } from '../models/comparable.model';
import {
  AdditionalCurve,
  ComparablesInformation,
  HISTORICAL_IHS,
  IHS,
  NewIssueCurveType,
  PricingData,
  PricingRequestCreation,
  PricingType,
  SecondaryLevels,
} from '../models/pricing-request.model';
import { PricingCompletionAction } from '../state/pricing-completion/pricing-completion.actions';
import {
  Maturities,
  PricingCompletionPageSettings,
  PricingTabDataMap,
  PricingTabsMap,
  SharedTabDataMap,
} from '../state/pricing-completion/pricing-completion.model';
import { PricingCompletionSelector } from '../state/pricing-completion/pricing-completion.selectors';
import { getPricingBasisForTab } from '../syndicate-pricer.functions';
import { SyndicatePricerSelectorService } from './syndicate-pricer-selector.service';
import { SyndicatePricerUtilService } from './syndicate-pricer-util.service';

export interface RowPricingParams {
  rowId: string;
  curveType: string;
  historicalDate?: string;
}
export interface MaturityRowPricingParams extends RowPricingParams {
  tab: string;
  currency: string;
  maturitiesToPrice: Maturities; // can include range, e.g. { 1Y: '1-6', 2Y: '7' }
}

@Injectable({ providedIn: 'root' })
export class PricingCompletionService {
  constructor(
    private agGridUtilService: AgGridUtilService,
    private dialogService: DialogService,
    private pricingService: PricingService,
    private regressionService: RegressionService,
    private stateService: StateService,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
    private syndicatePricerSelectorService: SyndicatePricerSelectorService,
    private store: Store,
    private utilService: UtilService,
    private levelsUtilService: LevelsUtilService,
  ) {}

  updateMaturities(maturities: string[] | undefined, maturitiesToBeUpdated: Maturities | undefined): Maturities {
    const newMaturities: Maturities = {};
    maturities?.forEach(maturity => {
      if (maturitiesToBeUpdated?.[maturity]) {
        newMaturities[maturity] = maturitiesToBeUpdated[maturity];
      } else {
        newMaturities[maturity] = '';
      }
    });
    return newMaturities;
  }

  govieGroupedRegression(bonds: GovieBond[]): Record<string, RegressionObject> {
    const groupedGovies: Record<string, GovieBond[]> = bonds.reduce(
      (acc: Record<string, GovieBond[]>, govie: GovieBond) => {
        acc[govie.funding_basis] ??= [];
        acc[govie.funding_basis].push(govie);
        return acc;
      },
      {},
    );
    return Object.fromEntries(
      Object.entries(groupedGovies).map(([basis, govies]) => {
        const regressionData: RegressionData = [];
        govies.forEach((govie: GovieBond) => {
          const tenor: number = this.levelsUtilService.getValueFromMaturity(govie.maturity);
          const level: string | null = govie.mid_ytm ?? govie.mid_discount_margin;
          const formattedLevel: number | null = level
            ? this.levelsUtilService.getValueFromBasis(level, govie.funding_basis)
            : null;
          if (tenor !== null && formattedLevel !== null && tenor > 0) {
            regressionData.push([tenor, formattedLevel, 0]);
          }
        });
        const regression: RegressionObject = this.regressionService.regression(
          regressionData,
          DEFAULT_REGRESSION_CONFIG,
        );
        return [basis, regression];
      }),
    );
  }

  priceBonds<T extends SecondaryBond | GovieBond>(
    params: BondPricingParams,
  ): Observable<T[] | SecondaryLevels | undefined> {
    return combineLatest([
      this.stateService.get.constants$,
      this.store.select(PricingCompletionSelector.quoteType).pipe(map(quoteType => quoteType ?? DEFAULT_QUOTE_TYPE)),
      this.syndicatePricerSelectorService.secondaryBondsPricingMap$,
    ]).pipe(
      take(1),
      switchMap(([constants, quoteType, secondaryBondsPricingMap]) => {
        const swapOption = constants.arrayFor.swap_options.find(option => option.value === params.pricingBasis);
        if (swapOption) {
          const validBonds = (params.secondaryBonds ?? []).filter(bond => {
            return (
              constants.arrayFor.swap_options.some(fb => fb.value === bond.funding_basis) &&
              !this.levelsUtilService.isMaturityDateInThePast(bond.maturity_date)
            );
          });
          const levelsForPricing: SingleLevelForPricing[] = [];
          validBonds.forEach((bond: SecondaryBond | GovieBond) => {
            const tenor = this.levelsUtilService.getValueFromMaturity(
              (<SecondaryBond>bond).tenor || bond.assumed_maturity || bond.maturity_date,
            );
            const bondPricingMap = secondaryBondsPricingMap?.[bond.isin] ?? {};
            const ytmKey = this.agGridUtilService.generateQuoteTypeField('ytm', quoteType);
            const discountMarginKey = this.agGridUtilService.generateQuoteTypeField('discount_margin', quoteType);
            const pricingOverride = secondaryBondsPricingMap[bond.isin]?.override;
            const bondPricingOverride = pricingOverride ? Number(pricingOverride).toFixed(2) : undefined;

            const rate = (bond as any)[discountMarginKey]
              ? Number((bond as any)[discountMarginKey])
              : Number((bond as any)[ytmKey]) * 100;
            const bondSwapOption = constants.arrayFor.basis_metadata_mapping[bond.funding_basis as any];
            const isPriced =
              bondPricingMap[
                params.historicalSecondaryBondsDate ? `${HISTORICAL_IHS}-${params.historicalSecondaryBondsDate}` : IHS
              ];

            if (params.pricePricingBasis && (!isPriced || params.repriceBonds)) {
              levelsForPricing.push({
                tenor: this.utilService.roundValue(tenor).toString(),
                spread: rate.toFixed(2),
                source_funding_basis: bond.funding_basis,
                source_payment_frequency: bondSwapOption.payment_freq,
                source_day_count: bondSwapOption.daycount,
                collateral_currency: bondSwapOption.currency,
                target_funding_basis: params.pricingBasis,
                target_payment_frequency: swapOption.payment_freq,
                target_day_count: swapOption.day_count,
                identifier: `${bond.isin}|${params.pricingBasis}`,
                price_as_of: params.historicalSecondaryBondsDate,
              });
            }

            params.swapBases?.forEach(swapBasis => {
              if (!bondPricingMap[swapBasis] || params.repriceBonds) {
                const args = bondPricingOverride
                  ? {
                      spread: bondPricingOverride,
                      source_funding_basis: params.pricingBasis,
                      source_payment_frequency: swapOption.payment_freq,
                      source_day_count: swapOption.day_count,
                    }
                  : {
                      spread: rate.toFixed(2),
                      source_funding_basis: bond.funding_basis,
                      source_payment_frequency: bondSwapOption.payment_freq,
                      source_day_count: bondSwapOption.daycount,
                    };
                const innerSwapOption = constants.arrayFor.basis_metadata_mapping[swapBasis as any];
                levelsForPricing.push({
                  tenor: this.utilService.roundValue(tenor).toString(),
                  ...args,
                  collateral_currency: bondSwapOption.currency,
                  target_funding_basis: swapBasis,
                  target_payment_frequency: innerSwapOption.payment_freq,
                  target_day_count: innerSwapOption.day_count,
                  identifier: `${bond.isin}|${swapBasis}`,
                });
              }
            });
          });

          if (!levelsForPricing.length) {
            return of(undefined);
          }
          const bases = [params.pricingBasis, ...(params.swapBases || [])];

          return this.pricingService.getPricingData({ levels_list: levelsForPricing }).pipe(
            map(pricedData => {
              const pricedDataMap: Record<string, BondPricing> = {};
              validBonds?.forEach((pricedBond: Partial<SecondaryBond>) => {
                if (pricedBond.isin) {
                  const priced: BondPricing = {};
                  bases.forEach(basis => {
                    const spread = pricedData.priced[`${pricedBond.isin}|${basis}`]?.spread;
                    if (!spread) {
                      return;
                    }
                    if (basis === params.pricingBasis) {
                      if (params.historicalSecondaryBondsDate) {
                        priced[`ihs-historical-${params.historicalSecondaryBondsDate}`] = spread;
                      } else {
                        priced.ihs = spread;
                      }
                    } else {
                      priced[basis] = spread;
                    }
                  });
                  pricedDataMap[pricedBond?.isin] = {
                    ...pricedDataMap[pricedBond?.isin],
                    ...priced,
                  };
                }
              });
              return pricedDataMap;
            }),
          );
        }
        return of(undefined);
      }),
    );
  }

  openAddPricingTabsDialog(): Observable<any> {
    const config = {
      panelClass: ['is-modal', 'is-modal-fixed-height'],
      height: '600px',
      width: '600px',
    };
    return this.dialogService.openInModal(AddPricingTabsDialogComponent, config);
  }

  openPricingRationaleDialog(): Observable<any> {
    const config = {
      panelClass: ['is-modal', 'is-modal-fixed-height'],
      height: '600px',
      width: '600px',
    };
    return this.dialogService.openInModal(PricingRationaleDialogComponent, config);
  }

  openSettingsDialog(): Observable<DynamicFormModalCloseEvent> {
    const secondaryPlotModeOptions: ValueAndLabel[] = Object.entries(SecondaryPlotMode).map(([label, value]) => ({
      value,
      label,
    }));
    return this.store.select(PricingCompletionSelector.pageSettings).pipe(
      take(1),
      mergeMap(pageSettings => {
        const config: DynamicFormModalParams = {
          title: 'Pricing settings',
          text: 'Save',
          options: [
            {
              element: 'autocomplete',
              label: 'Secondary Plot Mode',
              name: 'secondaryPlotMode',
              options: secondaryPlotModeOptions,
              initial: pageSettings.pricingGraph?.secondaryPlotMode,
            },
          ],
        };
        return forkJoin([this.dialogService.dynamicFormModal(config), of(pageSettings)]);
      }),
      tap(([response, pageSettings]: [DynamicFormModalCloseEvent | undefined, PricingCompletionPageSettings]) => {
        if (response) {
          const secondaryPlotMode = response?.formValue.secondaryPlotMode;
          const pricingGraph = { secondaryPlotMode };
          this.store.dispatch(
            PricingCompletionAction.savePageSettings({
              params: {
                pageSettings: {
                  ...pageSettings,
                  pricingGraph,
                } as PricingCompletionPageSettings,
              },
            }),
          );
        }
      }),
      map(([response]: [DynamicFormModalCloseEvent | undefined, PricingCompletionPageSettings]) => response),
    );
  }

  addPricingInboxGroupDialog(): Observable<DynamicFormModalCloseEvent> {
    const config: DynamicFormModalParams = {
      title: 'Add Group',
      text: 'Add',
      optionsUrl: 'companies/issuing-entity-groups/options/',
    };
    return this.dialogService.dynamicFormModal(config);
  }

  editPricingInboxGroupDialog(groupId: number): Observable<DynamicFormModalCloseEvent> {
    const config: DynamicFormModalParams = {
      title: 'Edit Group',
      text: 'Save',
      optionsUrl: `companies/issuing-entity-groups/${groupId}/options/`,
    };
    return this.dialogService.dynamicFormModal(config);
  }

  openComparablesManagerDialog(data: ComparablesInformation): Observable<ComparablesManagerDialogEvent> {
    const dialogConfig: MatDialogConfig = {
      data,
      panelClass: 'is-modal',
      width: DIALOG_WIDTH_MEDIUM,
    };
    return this.dialogService.openInModal(ComparablesManagerDialogComponent, dialogConfig);
  }

  openAddHistoricalBondsDialog(): Observable<DynamicFormModalCloseEvent> {
    return this.syndicatePricerSelectorService.savedSecondaryBondDates$.pipe(
      take(1),
      mergeMap(dates => {
        if (!dates) {
          return of();
        }
        const config: DynamicFormModalParams = {
          title: 'Manage Historical Data',
          text: 'Save',
          options: [
            {
              name: 'dates',
              label: 'Select Dates',
              element: 'list',
              type: 'date',
              initial: [...dates],
              max: moment().format(BACKEND_DATE_FORMAT),
              min: '2023-07-16',
            },
          ],
        };
        return this.dialogService.dynamicFormModal(config);
      }),
    );
  }

  priceMaturities(priceParams: MaturityRowPricingParams): Observable<Maturities> {
    return forkJoin([
      this.stateService.get.constants$,
      this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(take(1)),
    ]).pipe(
      mergeMap(([constants, pricingTabDataMap]: [Constants, PricingTabDataMap]) => {
        const pricingBasis = getPricingBasisForTab(pricingTabDataMap[priceParams.tab]);
        const hasGovieFundingBasis = this.syndicatePricerUtilService.isGovieBasis(pricingBasis, constants);

        const curveType = priceParams.curveType as NewIssueCurveType;

        const toFundingBasis = [
          NewIssueCurveType.OverviewSwap,
          NewIssueCurveType.Swap,
          NewIssueCurveType.ReferenceCurve,
        ].includes(curveType)
          ? this.syndicatePricerUtilService.getPricingBasisFromRowId(priceParams.rowId)
          : pricingBasis;

        const toSwapOption: ConstantObject | undefined = constants.arrayFor.swap_options.find(option => {
          if ([NewIssueCurveType.Yield, NewIssueCurveType.ReferenceCurve].includes(curveType)) {
            return option.value === `FIXED_${priceParams.currency}`;
          }
          return option.value === toFundingBasis;
        });

        const fromSwapOption: ConstantObject | undefined = constants.arrayFor.swap_options.find(option => {
          if ([NewIssueCurveType.Swap, NewIssueCurveType.OverviewSwap].includes(curveType)) {
            if (hasGovieFundingBasis) {
              return option.value === `FIXED_${priceParams.currency}`;
            } else {
              return option.value === getPricingBasisForTab(pricingTabDataMap[priceParams.tab]);
            }
          } else {
            return option.value === toFundingBasis;
          }
        });

        if (!fromSwapOption || !toSwapOption) {
          return of({});
        }

        const levelsForPricing: SingleLevelForPricing[] = [];

        Object.keys(priceParams.maturitiesToPrice ?? {}).forEach(maturity => {
          const maturityInputtedArray = this.utilService.getValuesFromRange(priceParams.maturitiesToPrice[maturity]);

          if (!maturityInputtedArray) {
            return;
          }

          maturityInputtedArray.forEach((spread, index) => {
            levelsForPricing.push({
              tenor: `${this.levelsUtilService.getValueFromMaturity(maturity)}Y`,
              spread: spread,
              source_funding_basis: fromSwapOption.value as string,
              source_payment_frequency: fromSwapOption.payment_freq,
              source_day_count: fromSwapOption.daycount,
              collateral_currency: priceParams.currency,
              target_funding_basis: toSwapOption.value as string,
              target_payment_frequency: toSwapOption.payment_freq,
              target_day_count: toSwapOption.day_count,
              identifier: this.pricingService.getPricingCurveIdentifier(priceParams.curveType, maturity, index),
              ...(priceParams.historicalDate
                ? { price_as_of: moment(priceParams.historicalDate).format(BACKEND_DATE_FORMAT) }
                : {}),
            });
          });
        });
        return this.pricingService
          .getPricingData({
            levels_list: levelsForPricing,
          })
          .pipe(
            map(response => {
              const results: Maturities = {};
              Object.keys(priceParams.maturitiesToPrice ?? {}).forEach(maturity => {
                const maturityInputtedArray = this.utilService.getValuesFromRange(
                  priceParams.maturitiesToPrice[maturity],
                );

                const spread = this.utilService.getRangeFromValues(
                  (maturityInputtedArray || []).map((_, index) => {
                    const pricingIdentifier = this.pricingService.getPricingCurveIdentifier(
                      priceParams.curveType,
                      maturity,
                      index,
                    );
                    return (response.priced[pricingIdentifier].spread ?? '').toString();
                  }),
                );
                results[maturity] = spread;
              });
              return results;
            }),
          );
      }),
    );
  }

  generateTabs(mappedTabs: { tab: ValueAndLabel; maturity: string[] }[], constants: Constants) {
    return mappedTabs.reduce((accumulator: PricingTabsMap, mappedTab: { tab: ValueAndLabel; maturity: string[] }) => {
      accumulator[mappedTab.tab.value as string] = {
        pricingBasis: this.getDefaultPricingBasis(mappedTab.tab.value as string, constants),
        tab: mappedTab.tab,
        maturities: mappedTab.maturity.length ? mappedTab.maturity : [],
      };
      return accumulator;
    }, {});
  }

  generateNewPricingRequests(pricingTabsMap: PricingTabsMap): Observable<PricingRequestCreation[]> {
    return forkJoin([
      this.store.select(PricingCompletionSelector.issuingEntities).pipe(
        filter(issuingEntities => !!issuingEntities),
        take(1),
        map(issuingEntities => Object.keys(issuingEntities ?? {})),
      ),
      this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(
        filter(pricingTabDataMap => !!pricingTabDataMap),
        take(1),
      ),
      this.store.select(PricingCompletionSelector.govieBonds).pipe(take(1)),
      this.stateService.get.constants$,
    ]).pipe(
      map(
        ([issuingEntityIds, currentPricingTabDataMap, govieBonds, constants]: [
          string[],
          PricingTabDataMap,
          Record<string, GovieBond[]>,
          Constants,
        ]) => {
          const pricingRequests: PricingRequestCreation[] = [];

          const templatePricingRequest: PricingRequestCreation = JSON.parse(
            JSON.stringify(Object.values(Object.values(currentPricingTabDataMap)[0])[0].pricingRequest),
          );

          delete templatePricingRequest.id;
          delete templatePricingRequest.created_by;
          delete templatePricingRequest.created_at;
          delete templatePricingRequest.filters;

          Object.keys(pricingTabsMap).forEach(tab => {
            const pricingType: PricingType = this.syndicatePricerUtilService.generatePricingTypeFromTabName(
              tab,
              constants,
            );

            issuingEntityIds.forEach(issuingEntityId => {
              const additionalCurves: AdditionalCurve[] = [];
              const pricingBasis = pricingTabsMap[tab].pricingBasis;
              const maturities = pricingTabsMap[tab].maturities ?? templatePricingRequest.maturities;

              if (this.syndicatePricerUtilService.getBasisType(pricingBasis, constants) === BasisType.Govie) {
                const defaultBenchmarkSelection = this.syndicatePricerUtilService.getDefaultBenchmarkSelection({
                  maturities,
                  pricingBasis,
                  govieBonds: govieBonds[pricingBasis],
                });

                additionalCurves.push({
                  type: NewIssueCurveType.BenchmarkInput,
                  value: pricingBasis,
                  data: defaultBenchmarkSelection,
                });

                additionalCurves.push({
                  type: NewIssueCurveType.BenchmarkYield,
                  value: pricingBasis,
                  data: this.syndicatePricerUtilService.getBenchmarkYields(
                    defaultBenchmarkSelection,
                    govieBonds[pricingBasis],
                  ),
                });
              }

              const pricing: PricingData = {
                funding_basis: pricingTabsMap[tab].pricingBasis,
                breakdown: DEFAULT_PRICING_BREAKDOWN,
                show_references: false,
                additional_curves: additionalCurves,
                levels: [],
              };
              pricingRequests.push({
                ...templatePricingRequest,
                ...pricingType,
                issuing_entity: +issuingEntityId,
                maturities,
                pricing,
              });
            });
          });
          return pricingRequests;
        },
      ),
    );
  }
  generateSharedTabData(pricingTabsMap: PricingTabsMap): SharedTabDataMap {
    return Object.keys(pricingTabsMap).reduce((sharedTabDataMap: SharedTabDataMap, tab: string) => {
      sharedTabDataMap[tab] = {
        tempPricingMap: {},
        pricedKeys: {},
      };
      return sharedTabDataMap;
    }, {});
  }

  private getDefaultPricingBasis(value: string, constants: Constants): string {
    const currency = value.split('_')[0];
    const couponType = Object.values(constants.mappingFor.structure_options).find(
      option => option.short_name === value.split('_')[1],
    )?.value as string;
    return (constants.arrayFor.syndicate_pricer_default_funding_basis_mapping as any)[currency][couponType];
  }
}
