import { BACKEND_DATETIME_PRECISE_FORMAT } from '@morpho/core';
import { RowNode } from 'ag-grid-community';
import * as moment from 'moment';
import { DataSource } from '../features/clients/services/clients-display.service';
import { CommentThread } from '../models/comments';

const DATA_SOURCE_ORDER = Object.values(DataSource);

export const sortObjectArrayAlphabetically = (array: any[], key: string) => {
  return array.sort((a, b) => sortByAlphabetical(a[key], b[key]));
};

export const sortByAlphabetical = (lhsValue: string, rhsValue: string): number => {
  const [lhs, rhs] = [lhsValue.toLocaleLowerCase(), rhsValue.toLocaleLowerCase()];
  if (lhs < rhs) {
    return -1;
  } else if (lhs > rhs) {
    return 1;
  }
  return 0;
};

export const sortByAlphabeticalInverse = (lhsValue: string, rhsValue: string) => {
  return -sortByAlphabetical(lhsValue, rhsValue);
};

interface ObjectWithId {
  id: number;
  [key: string]: any;
}
export const sortByIdAscending = (lhs: ObjectWithId, rhs: ObjectWithId): number => {
  if (lhs.id > rhs.id) {
    return 1;
  } else if (lhs.id < rhs.id) {
    return -1;
  }
  return 0;
};

interface ObjectWithCreatedAt {
  created_at: string;
  [key: string]: any;
}
interface ObjectWithUploadedAt {
  uploaded_at: string;
  [key: string]: any;
}

export const sortByCreatedAtDescending = (lhs: ObjectWithCreatedAt, rhs: ObjectWithCreatedAt): number => {
  const lhsTime = moment(lhs.created_at, BACKEND_DATETIME_PRECISE_FORMAT);
  const rhsTime = moment(rhs.created_at, BACKEND_DATETIME_PRECISE_FORMAT);
  return lhsTime.isBefore(rhsTime) ? 1 : lhsTime.isSame(rhsTime) ? 0 : -1;
};

export const sortByUploadedAtDescending = (lhs: ObjectWithUploadedAt, rhs: ObjectWithUploadedAt): number => {
  const lhsTime = moment(lhs.uploaded_at, BACKEND_DATETIME_PRECISE_FORMAT);
  const rhsTime = moment(rhs.uploaded_at, BACKEND_DATETIME_PRECISE_FORMAT);
  return lhsTime.isBefore(rhsTime) ? 1 : lhsTime.isSame(rhsTime) ? 0 : -1;
};

export const sortCommentThreadArrayByLatest = (lhs: CommentThread, rhs: CommentThread): number => {
  const lhsCreated = lhs.comments[lhs.comments.length - 1]?.created;
  const rhsCreated = rhs.comments[rhs.comments.length - 1]?.created;
  if (!lhsCreated) {
    return -1;
  }
  if (!rhsCreated) {
    return 1;
  }
  const lhsTime = moment(lhsCreated, BACKEND_DATETIME_PRECISE_FORMAT);
  const rhsTime = moment(rhsCreated, BACKEND_DATETIME_PRECISE_FORMAT);

  if (lhsTime.isAfter(rhsTime)) {
    return -1;
  }
  return lhsTime.isSame(rhsTime) ? 0 : 1;
};

interface ObjectWithTeam {
  team: string;
  [key: string]: any;
}

export const sortByTeamDescending = (lhs: ObjectWithTeam, rhs: ObjectWithTeam): number => {
  const lhTeam = lhs.team;
  const rhTeam = rhs.team;
  return lhTeam > rhTeam ? 1 : lhTeam === rhTeam ? 0 : -1;
};

export const sortByDataSource = (lhs: any, rhs: any): number => {
  return DATA_SOURCE_ORDER.indexOf(lhs) - DATA_SOURCE_ORDER.indexOf(rhs);
};

interface ObjectWithTabs {
  tabs: any[];
  [key: string]: any;
}

export const sortEmptyTabsToBottom = (lhs: ObjectWithTabs, rhs: ObjectWithTabs): number => {
  if (!lhs.tabs.length) {
    return 1;
  }
  if (!rhs.tabs.length) {
    return -1;
  }
  return 0;
};

export const sortByObjectAttributeString = (lhs: any, rhs: any, attributeName: string): number => {
  const lhsValue = lhs[attributeName] ?? '';
  const rhsValue = rhs[attributeName] ?? '';
  return lhsValue.localeCompare(rhsValue, 'en', { sensitivity: 'base' });
};

export const sortGridRowByIssuingEntity = (lhNode: RowNode, rhNode: RowNode): number => {
  const lhsValue = lhNode.data?.issuing_entity?.name ?? '';
  const rhsValue = rhNode.data?.issuing_entity?.name ?? '';
  return lhsValue.localeCompare(rhsValue, 'en', { sensitivity: 'base' });
};

export const sortByArrayOrderComparator = (lhs: string, rhs: string, sortOrder: any[]) => {
  return sortOrder.indexOf(lhs) - sortOrder.indexOf(rhs);
};

export const sortByTenor = (lhs: string, rhs: string) => {
  const getNumberValue = (tenor: string) => {
    if (typeof tenor !== 'string') {
      return 0;
    }
    if (tenor.includes('D')) {
      return Number(tenor.replace('D', ''));
    }
    if (tenor.includes('W')) {
      return 7 * Number(tenor.replace('W', ''));
    }
    if (tenor.includes('M')) {
      return 30 * Number(tenor.replace('M', ''));
    }
    if (tenor.includes('Y')) {
      return 365 * Number(tenor.replace('Y', ''));
    }
    return 0;
  };

  return getNumberValue(lhs) - getNumberValue(rhs);
};

interface ObjectWithLastUpdated {
  last_updated: string;
  [key: string]: any;
}

export const sortByLastUpdatedDescending = (lhs: ObjectWithLastUpdated, rhs: ObjectWithLastUpdated) => {
  const lhsTime = moment(lhs.last_updated, BACKEND_DATETIME_PRECISE_FORMAT);
  const rhsTime = moment(rhs.last_updated, BACKEND_DATETIME_PRECISE_FORMAT);
  return lhsTime.isBefore(rhsTime) ? 1 : lhsTime.isSame(rhsTime) ? 0 : -1;
};
