<form>
  <div class="dual-panel">
    <div class="dual-panel-section dual-panel-section--top">
      <div class="dual-panel-section-header">
        Your Edits <span class="count">{{ directEditInputs?.length }}</span>
      </div>

      @for (input of directEditInputs; track input) {
        <ng-container *ngTemplateOutlet="formFieldTemplate; context: { input: input }"></ng-container>
      }
    </div>
    @if (impactedEditInputs?.length) {
      <div
        class="dual-panel-section dual-panel-section--bottom"
        [class.dual-panel-section--is-collapsed]="!impactedInputsAreOpen"
      >
        <div class="dual-panel-section-header" (click)="impactedInputsAreOpen = !impactedInputsAreOpen">
          Impacted Inputs <span class="count">{{ impactedEditInputs?.length }}</span>
          <span class="mat-expansion-indicator"></span>
        </div>
        <div class="dual-panel-section-content">
          <div class="note">
            Some inputs are calculated based on the value of another input. This section shows inputs impacted by your
            edits.
          </div>
          @for (input of impactedEditInputs; track input) {
            <ng-container
              *ngTemplateOutlet="formFieldTemplate; context: { input: input, isImpacted: true }"
            ></ng-container>
          }
        </div>
      </div>
    }
  </div>

  <ng-template #formFieldTemplate let-input="input" let-isImpacted="isImpacted">
    <div class="dual-panel-section-row">
      <om-dynamic-form-field
        [id]="fieldIdPrefix + input.fieldName"
        [ngClass]="{
          'is-locked': model.fieldMapping[input.fieldName].locked,
          'is-added': isImpacted && input.type === EditType.Added,
          'is-changed': isImpacted && input.type === EditType.Changed,
          'is-unchanged': !isImpacted && input.type === EditType.Unchanged,
          'is-alert': !isImpacted && input.type === EditType.Unchanged,
        }"
        [model]="model"
        [form]="model.form"
        [formConfig]="formConfig"
        [fieldConfig]="model.fieldMapping[input.fieldName]"
        [hasDeleteAction]="true"
        (inputChange)="onInput($event)"
        [editActions]="dynamicActionMapping[input.fieldName]"
        [findActions]="findActionMapping[input.fieldName]"
        [hideContent]="input.hideContent"
        (actionSelected)="onActionSelected($event)"
      ></om-dynamic-form-field>
    </div>
  </ng-template>
</form>
