import { NgModule } from '@angular/core';
import { CoreModule } from '@morpho/core';
import { DynamicFormModule } from '@morpho/dynamic-form';
import { FormModule } from '@morpho/form';
import { BooleanPopupComponent } from './components/boolean-popup/boolean-popup.component';
import { CarouselModalComponent } from './components/carousel-modal/carousel-modal.component';

@NgModule({
  declarations: [BooleanPopupComponent, CarouselModalComponent],
  imports: [CoreModule, FormModule, DynamicFormModule],
  exports: [],
})
export class DialogModule {}
