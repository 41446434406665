<div class="om-document-download-panel__header">
  <div class="om-document-download-panel__heading">
    <mat-icon svgIcon="folder"></mat-icon>
    <h3>Documents</h3>
  </div>
  <div class="om-document-download-panel__actions">
    @if (downloadUrl) {
      <a mat-button download [href]="downloadUrl">Download All</a>
    } @else if (downloadModal) {
      <button mat-button (click)="downloadAll()">Download All</button>
    }
  </div>
</div>

<om-document-list [documents]="documents"></om-document-list>
