import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurveAnalysisLoadingStage, CurveAnalysisState } from './curve-analysis.model';
import { CURVE_ANALYSIS_STATE_KEY } from './curve-analysis.reducer';

const curveAnalysisState = createFeatureSelector<CurveAnalysisState>(CURVE_ANALYSIS_STATE_KEY);

export const CurveAnalysisSelector = {
  state: curveAnalysisState,

  rawCurves: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.rawCurves),
  pricedCurves: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.pricedCurves),
  pricingInfoList: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.pricingInfoList),
  lastPriced: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.lastPriced),

  savedView: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.savedView),
  selectedIssuerIds: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.selectedIssuers),
  previouslySelectedIssuerIds: createSelector(
    curveAnalysisState,
    (state: CurveAnalysisState) => state.previouslySelectedIssuers,
  ),
  pricerValue: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.pricerValue),

  selectedCurves: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.selectedCurves),
  selectedBonds: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.selectedBonds),
  legendSelection: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.legendSelection),

  regression: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.regression),

  colorConfig: createSelector(curveAnalysisState, (state: CurveAnalysisState) => state.colorConfig),

  loadingStage: createSelector(
    curveAnalysisState,
    (state: CurveAnalysisState) =>
      Object.entries(state.loadingState).find(([, isLoading]) => isLoading)?.[0] as CurveAnalysisLoadingStage,
  ),

  isLoading: createSelector(curveAnalysisState, (state: CurveAnalysisState) =>
    Object.values(state.loadingState).some(isLoading => isLoading),
  ),
};
