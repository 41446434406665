import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-activity-card-collapse-wrapper',
  templateUrl: './activity-card-collapse-wrapper.component.html',
  styleUrls: ['./activity-card-collapse-wrapper.component.scss'],
})
export class ActivityCardCollapseWrapper implements AfterViewInit {
  @ViewChild('collapseContainer', { read: ElementRef }) collapseContainer: ElementRef;

  @Input()
  get previewHeight(): string {
    return `${this._previewHeight}px`;
  }
  set previewHeight(previewHeight: string | number) {
    if (typeof previewHeight === 'string') {
      this._previewHeight = parseInt(previewHeight);
    } else {
      this._previewHeight = previewHeight;
    }
  }
  _previewHeight = 200;

  isOverflowing = false;
  isExpanded = false;

  ngAfterViewInit(): void {
    // Timeout needed to wait until after child components have rendered
    setTimeout(() => {
      const childHeight = [...this.collapseContainer?.nativeElement.children].reduce(
        (tot: number, c: HTMLElement) => tot + c.offsetHeight,
        0,
      );

      this.isOverflowing = childHeight > this._previewHeight;
    });
  }

  toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
  }
}
