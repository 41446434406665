import { PrimitiveCellEditorComponent } from '@morpho/form';
import { DateCellEditorComponent } from '../cell-editors/date-cell-editor/date-cell-editor.component';
import { SelectCellEditorComponent } from '../cell-editors/select-cell-editor/select-cell-editor.component';
import { DataTableRowDeleteCellRenderer } from '../components/data-table-input/data-table-row-delete-cell-renderer';

const componentMap = {
  omDateCellEditor: { omDateCellEditor: DateCellEditorComponent },
  omSelectCellEditor: { omSelectCellEditor: SelectCellEditorComponent },
  omDataTableRowDeleteCellRenderer: { omDataTableRowDeleteCellRenderer: DataTableRowDeleteCellRenderer },
  omPrimitiveCellEditor: { omPrimitiveCellEditor: PrimitiveCellEditorComponent },
};

export const allFrameworkComponents = Object.keys(componentMap).reduce((accumulator: any, current: string) => {
  return {
    ...accumulator,
    ...(componentMap as any)[current],
  };
}, {});

export const dataTableInputFrameworkComponents = {
  ...componentMap.omSelectCellEditor,
  ...componentMap.omDataTableRowDeleteCellRenderer,
  ...componentMap.omPrimitiveCellEditor,
};
