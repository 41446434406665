@if (!isCellEditor) {
  <mat-form-field>
    @if (type === 'textarea') {
      <textarea
        matInput
        #childInput
        [disabled]="disabled"
        [maxlength]="maxlength"
        [placeholder]="placeholder"
        [type]="childType"
        [(ngModel)]="childValue"
        (ngModelChange)="onChildValueChange()"
        (blur)="onBlur()"
        autocomplete="off"
      ></textarea>
    } @else {
      <input
        matInput
        #childInput
        [disabled]="disabled"
        [email]="isEmail"
        [maxlength]="maxlength"
        [placeholder]="placeholder"
        [type]="childType"
        [(ngModel)]="childValue"
        (ngModelChange)="onChildValueChange()"
        (blur)="onBlur()"
        autocomplete="off"
      />
    }
  </mat-form-field>
} @else {
  <input
    matInput
    #childInput
    [disabled]="disabled"
    [email]="isEmail"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    [type]="childType"
    [(ngModel)]="childValue"
    (ngModelChange)="onChildValueChange()"
    (blur)="onBlur()"
    autocomplete="off"
  />
}
