import { Sidenav, SidenavMode, SidenavPosition, toggleSidebarAnimationTransitionDuration } from '@morpho/core';
import { Action, createReducer, on } from '@ngrx/store';
import { DocumentExtractorAction } from './document-extractor.actions';
import { DocumentExtractorState } from './document-extractor.model';

export const DOCUMENT_EXTRACTOR_STATE_KEY = 'documentExtractor';

const initialState: DocumentExtractorState = {
  formProgress: 0,
  isDataConfirmed: false,
  isFormSaving: false,
  isSelectionSidebarOpen: true,
  sidenav: {
    mode: SidenavMode.Side,
    position: SidenavPosition.Open,
    isTooltipDisabled: true,
  },
  seenFields: [],
};

const reducer = createReducer(
  initialState,

  on(
    DocumentExtractorAction.getUploadedDocumentSuccess,
    (state: DocumentExtractorState, action): DocumentExtractorState => {
      return { ...state, uploadedDocument: action.params };
    },
  ),

  on(DocumentExtractorAction.toggleSidenav, (state: DocumentExtractorState) => {
    const sidenav: Sidenav = {
      ...state.sidenav,
      ...(state.sidenav.position === SidenavPosition.Mini
        ? {
            position: SidenavPosition.Open,
            isTooltipDisabled: true,
          }
        : {
            position: SidenavPosition.Mini,
            isTooltipDisabled: false,
          }),
    };

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, toggleSidebarAnimationTransitionDuration);
    return { ...state, sidenav };
  }),

  on(DocumentExtractorAction.setFormProgress, (state: DocumentExtractorState, action): DocumentExtractorState => {
    return { ...state, formProgress: action.params.formProgress };
  }),

  on(DocumentExtractorAction.reset, (state: DocumentExtractorState) => initialState),

  on(DocumentExtractorAction.setFormStatus, (state: DocumentExtractorState, action) => {
    return {
      ...state,
      ...action.params,
    };
  }),

  on(DocumentExtractorAction.setSeenFields, (state: DocumentExtractorState, action) => {
    return {
      ...state,
      seenFields: action.params.seenFields,
    };
  }),

  on(DocumentExtractorAction.setFormIsSavingOn, (state: DocumentExtractorState) => {
    return { ...state, isFormSaving: true, isDataConfirmed: true };
  }),

  on(DocumentExtractorAction.setFormIsSavingOff, (state: DocumentExtractorState) => {
    return { ...state, isFormSaving: false };
  }),
);

export function documentExtractorReducer(state: DocumentExtractorState, action: Action) {
  return reducer(state, action);
}
