import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    if (!error) {
      return;
    }
    if (error.originalError) {
      error = error.originalError;
    }

    let shouldErrorBeSentToSentry = true;

    const chunkFailedMessage = /Loading chunk .+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      return;
    }

    const pdfViewer = /The `container` must be absolutely positioned./;
    if (pdfViewer.test(error.message)) {
      shouldErrorBeSentToSentry = false;
    }

    if (error instanceof HttpErrorResponse || typeof error === 'string' || !error.message) {
      shouldErrorBeSentToSentry = false;
    }

    if (shouldErrorBeSentToSentry) {
      console.log(`CustomErrorHandler: ${error.message}`);
      const eventId = Sentry.captureException(error);
      // Sentry.showReportDialog({ eventId });
      console.error(error);
    } else {
      /* eslint-disable no-console */
      console.log(error);
    }
    // super.handleError(error);
  }
}
