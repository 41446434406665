import { LegendSelection } from '@shared-echarts/models';
import { SavedView } from 'apps/bankangle/src/app/models/saved-view';
import { Curve } from '../../../models/curve.model';
import { RegressionConfig } from '../../../models/regression.model';
import { PricingInfo } from '../../patterns/pricing/pricing.model';

export enum CurveAnalysisLoadingStage {
  Init = 'Initializing Page',
  Get = 'Getting curves',
  Rate = 'Attaching ratings data',
  Price = 'Pricing curves',
  Regression = 'Applying regression',
  InitialSelection = 'Applying selection rules',
}

export const CurveAnalysisLoadingStageProgress: Record<CurveAnalysisLoadingStage, number> = {
  [CurveAnalysisLoadingStage.Init]: 0,
  [CurveAnalysisLoadingStage.Get]: 5,
  [CurveAnalysisLoadingStage.Rate]: 15,
  [CurveAnalysisLoadingStage.Price]: 20,
  [CurveAnalysisLoadingStage.Regression]: 70,
  [CurveAnalysisLoadingStage.InitialSelection]: 90,
};

export interface CurveAnalysisState {
  rawCurves?: Curve[];
  pricedCurves?: Curve[];
  tenors?: string[];

  selectedCurves?: string[];
  selectedBonds?: Record<string, boolean>;
  legendSelection: LegendSelection;
  lastPriced?: string;
  pricingInfoList?: PricingInfo[];

  loadingState: Record<CurveAnalysisLoadingStage, boolean>;

  savedView?: SavedView;
  selectedIssuers?: number[];
  previouslySelectedIssuers?: number[];
  pricerValue?: string;

  regression?: RegressionConfig;
  colorConfig?: Record<string, number>;
}
