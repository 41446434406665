<mat-form-field #formField (click)="onClickFormField()">
  @if (prefixIcon) {
    <mat-icon matPrefix [svgIcon]="prefixIcon"></mat-icon>
  }

  <mat-chip-grid #chipList (click)="onClickChipList()" [disabled]="disabled">
    @if (multiple) {
      @for (val of chipsList; track $index; let last = $last) {
        <mat-chip-row
          [id]="last ? LAST_SELECTED_CHIP_ID : ''"
          [disabled]="disabled"
          [removable]="removable"
          (removed)="remove(val); deselectChildrenCheckboxes(val)"
          (click)="onClickChip($event)"
        >
          {{ val.label }}
          <span>
            @if (removable) {
              <mat-icon svgIcon="remove" matChipRemove></mat-icon>
            }
          </span>
        </mat-chip-row>
      }
    }
    @for (suggestion of filteredSuggestions; track suggestion; let idx = $index) {
      <mat-chip-row
        (click)="onAddSuggestion(suggestion)"
        class="is-suggestion"
        omTooltip="Add to list"
        [disabled]="disabled"
      >
        {{ suggestion.label }}
        <span><mat-icon svgIcon="plus"></mat-icon></span>
      </mat-chip-row>
    }

    <input
      #childInput
      [disabled]="disabled"
      [formControl]="inputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [placeholder]="chipsList.length ? '' : placeholder"
      (blur)="onBlur($event)"
      (click)="onClick($event)"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>

  @if (suffixIcon) {
    <mat-icon matSuffix [svgIcon]="suffixIcon"></mat-icon>
  }
  @if (suffixButton && !multiple && value != null) {
    <button mat-icon-button matSuffix [disabled]="disabled" (click)="removeSingleValue($event)">
      <mat-icon svgIcon="remove"></mat-icon>
    </button>
  }

  <mat-autocomplete #auto="matAutocomplete" [class]="classList" (optionSelected)="selected($event)">
    @if (hasNestedOptions && !allOptionGroupsEmpty) {
      @for (group of filteredOptionGroups$ | async; track group.divider_label) {
        <mat-optgroup [label]="group.divider_label">
          @if (multiple && group.options.length && group.enable_select_all) {
            <mat-checkbox
              #checkbox
              class="is-select-all-enabled"
              [checked]="isCheckedOptionGroup(group)"
              [indeterminate]="isIndeterminateOptionGroup(group)"
              (change)="toggleOptionGroup(group, checkbox.checked)"
              (click)="onClickCheckbox($event)"
            >
              Select all {{ group.filtered ? 'filtered' : '' }}
            </mat-checkbox>
          }
          @for (option of group.options; track option.value) {
            <mat-option
              [value]="option"
              [disabled]="linkedSelection && disabledMap[option.label]"
              [ngClass]="{ 'is-multi-select': multiple, 'has-nested-children': linkedSelection }"
              tabindex="0"
              (click)="$event.stopImmediatePropagation()"
            >
              <ng-container
                *ngTemplateOutlet="optionTemplate; context: { option: option, group: group }"
              ></ng-container>
            </mat-option>
          } @empty {
            <mat-option class="has-no-options" [disabled]="true">
              <span> {{ group.empty_options_message }} </span>
            </mat-option>
          }
        </mat-optgroup>
      }
      @if (messageTitle && messageDetails) {
        <mat-option class="is-sticky" [disabled]="true">
          <ng-container *ngTemplateOutlet="messageTemplate; context: { fieldConfig: fieldConfig }"></ng-container>
        </mat-option>
      }
    } @else if (!hasNestedOptions) {
      @for (option of filteredOptions$ | async; track option.value) {
        <mat-option [value]="option" [class.is-multi-select]="multiple" tabindex="0">
          <ng-container *ngTemplateOutlet="optionTemplate; context: { option: option }"></ng-container>
        </mat-option>
      }
      @if (messageTitle && messageDetails) {
        <mat-option class="is-sticky" [disabled]="true">
          <ng-container *ngTemplateOutlet="messageTemplate; context: { fieldConfig: fieldConfig }"></ng-container>
        </mat-option>
      }
    }
  </mat-autocomplete>
</mat-form-field>

<ng-template #optionTemplate let-option="option" let-group="group">
  @if (option.src) {
    <img [src]="option.src" />
  }
  <div>
    <span>{{ option.label }}</span>
    @if (option.description) {
      <span class="is-pre-line">{{ option.description }}</span>
    }
  </div>
  <div class="icon-tags-container">
    @for (tag of option.tags; track tag) {
      @if (tag.icon) {
        <img class="is-transparent" [src]="tag.icon" [omTooltip]="tag.tooltip" />
      }
    }
  </div>
  <mat-chip-set>
    @for (tag of option.tags; track tag) {
      @if (!tag.icon) {
        <mat-chip [class]="tag.class">
          {{ tag.label }}
        </mat-chip>
      }
    }
  </mat-chip-set>
  @if (multiple) {
    @if (!linkedSelection) {
      <mat-checkbox
        #checkbox
        [checked]="value?.includes(option.value)"
        (change)="toggleOption(option, checkbox.checked)"
        (click)="onClickCheckbox($event)"
      >
      </mat-checkbox>
    } @else {
      <mat-checkbox
        #checkbox
        [disabled]="disabledMap[option.label]"
        [checked]="linkedSelectionValues.includes(option.value)"
        (change)="toggleLinkedSelectionOption(option, checkbox.checked)"
        (click)="onClickCheckbox($event)"
      >
      </mat-checkbox>
    }
  }
</ng-template>

<ng-template #messageTemplate let-fieldConfig="fieldConfig">
  <div>
    <span>{{ messageTitle }}</span>
    <span>{{ messageDetails }}</span>
  </div>
</ng-template>
