import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-expand-button',
  templateUrl: './expand-button.component.html',
  styleUrls: ['./expand-button.component.scss'],
})
export class ExpandButtonComponent {
  @Input() isExpanded: boolean;

  constructor() {}
}
