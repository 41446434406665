import { Component, Input, OnChanges } from '@angular/core';
import { FormUtilService, RichTextOption } from '@morpho/form';
import { EditorType, RichTextService } from '@morpho/rich-text';

@Component({
  standalone: false,
  selector: 'om-richtext-value-viewer',
  templateUrl: './richtext-value-viewer.component.html',
  styleUrls: ['./richtext-value-viewer.component.scss'],
})
export class RichtextValueViewerComponent implements OnChanges {
  @Input() editorType = EditorType.Full;
  @Input() userFullName: string;
  @Input() value: RichTextOption | null;

  displayValue: string;

  constructor(
    private utilService: FormUtilService,
    private richTextService: RichTextService,
  ) {}

  ngOnChanges(): void {
    this.displayValue = this.getFormattedValue();
  }

  private getFormattedValue() {
    if (!this.utilService.formInputHasValue(this.value)) {
      return '';
    }
    if (this.value?.content) {
      const valueCopy = JSON.parse(JSON.stringify(this.value));
      valueCopy.content = this.richTextService.sanitiseRichTextInputValue(valueCopy.content);
      return valueCopy;
    }
    return this.value;
  }
}
