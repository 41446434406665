import { Injectable } from '@angular/core';
import { BasisType, LevelsUtilService, PricingUnits, UtilService, ValueAndLabel } from '@morpho/core';
import { IRowNode } from 'ag-grid-community';
import * as moment from 'moment';
import { sortByAlphabetical, sortByAlphabeticalInverse } from '../../../constants/sortComparators';
import { Constants } from '../../../models/constants';
import { RegressionObject } from '../../../models/regression.model';
import {
  DEFAULT_PRICING_BREAKDOWN,
  PricingBreakDownType,
  PricingBreakdownEditableRows,
  PricingBreakdownFilters,
} from '../constants/breakdown';
import { GovieBond } from '../models/comparable.model';
import {
  COMBINED_INTERPOLATION_LABEL,
  CurveLevel,
  GLOBAL_FIELD,
  HISTORICAL_PRICING,
  NewIssueCurveType,
  OVERVIEW_TAB,
} from '../models/pricing-request.model';
import {
  GenerateReferenceCurveParams,
  GenerateRowDataParams,
  IssuerPricingDataMap,
  Maturities,
  NewIssueRowCreationParams,
  NewIssueRowDef,
  PricingTabDataMap,
  SharedTabData,
  SharedTabDataMap,
} from '../state/pricing-completion/pricing-completion.model';
import {
  generateCurveID,
  getCurveTypeFromCurveID,
  getDisplayMaturitiesForTab,
  getHistoricalDateFromCurveID,
  getIssuingEntityFromCurveID,
  getPricingBasisForTab,
  getShowReferenceCurvesForTab,
  getTabFromCurveID,
  getValueFromCurveID,
} from '../syndicate-pricer.functions';
import { SyndicatePricerUtilService } from './syndicate-pricer-util.service';

@Injectable({
  providedIn: 'root',
})
export class NewIssuePricingRowsService {
  constructor(
    private levelsUtilService: LevelsUtilService,
    private utilService: UtilService,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
  ) {}

  readonly baseRowTitles: Record<string, string> = {
    [NewIssueCurveType.Spread]: 'Fair Value',
    [NewIssueCurveType.NewIssuePremium]: 'New Issue Premium',
    [NewIssueCurveType.Total]: 'Pricing',
    [NewIssueCurveType.Yield]: 'Yield',
  };

  generateCurveID(params: {
    tab: string;
    curveType: NewIssueCurveType;
    title?: string;
    issuingEntityId?: string | number;
    historicalDate?: string;
    isHistoricalPricing?: boolean;
  }): string {
    return generateCurveID(params);
  }

  getCurveTypeFromCurveID(rowId: string): NewIssueCurveType | null {
    return getCurveTypeFromCurveID(rowId);
  }

  getIssuingEntityFromCurveID(rowId: string): string {
    return getIssuingEntityFromCurveID(rowId);
  }

  getTabFromCurveID(rowId: string): string {
    return getTabFromCurveID(rowId);
  }

  getValueFromCurveID(rowId: string): string {
    return getValueFromCurveID(rowId);
  }

  getHistoricalDateFromCurveID(rowId: string): string {
    return getHistoricalDateFromCurveID(rowId);
  }

  getInitialRowDataForTab(params: {
    tab: string;
    pricingTabDataMap: PricingTabDataMap;
    constants: Constants;
    isViewMode?: boolean;
    hidePricedData?: boolean;
    showPricingBasisInRow?: boolean;
  }): NewIssueRowDef[] {
    const rowData: NewIssueRowDef[] = [];
    const issuerPricingDataMap: IssuerPricingDataMap = params.pricingTabDataMap[params.tab];

    const pricingBasis = getPricingBasisForTab(issuerPricingDataMap);

    if (!pricingBasis) {
      return [];
    }

    Object.entries(issuerPricingDataMap ?? {}).forEach(([issuingEntityId, issuerPricingData]) => {
      const pricingBreakdown = issuerPricingData.pricingRequest.pricing.breakdown ?? DEFAULT_PRICING_BREAKDOWN;
      const savedLevels = [
        ...(issuerPricingData.pricingRequest.pricing.levels ?? []),
        ...(params.hidePricedData
          ? []
          : (issuerPricingData.pricingRequest.pricing.additional_curves
              ?.filter(curve => curve.type === NewIssueCurveType.Yield)
              ?.map(curve => ({ key: curve.type, data: curve.data }) as CurveLevel) ?? [])),
      ];
      const pricingRows = this.generatePricingRows({
        ...params,
        pricingBasis,
        issuingEntityId,
        levels: savedLevels,
        pricingBreakdown,

        ...(params.isViewMode
          ? {
              historicalDate: issuerPricingData.pricingRequest.pricing.completed_at,
              editableHistorical: true,
            }
          : {}),
      });

      rowData.push(...pricingRows);
    });

    return rowData;
  }

  generatePricingRows(params: {
    tab: string;
    pricingBasis: string;
    issuingEntityId: number | string;
    levels: CurveLevel[];
    pricingBreakdown: PricingBreakDownType;
    constants: Constants;
    yieldMaturities?: Maturities;
    historicalDate?: string;
    isHistoricalPricing?: boolean;
    showPricingBasisInRow?: boolean;
    editableHistorical?: boolean;
  }): NewIssueRowDef[] {
    const basisType = this.syndicatePricerUtilService.getBasisType(params.pricingBasis, params.constants);
    const isPricedToOriginalBasis = this.syndicatePricerUtilService.isPricedToOriginalBasis(
      params.tab,
      params.pricingBasis,
      params.constants,
    );

    return [
      NewIssueCurveType.Spread,
      NewIssueCurveType.NewIssuePremium,
      NewIssueCurveType.Total,
      ...(basisType === BasisType.Floating || isPricedToOriginalBasis ? [] : [NewIssueCurveType.Yield]),
    ]
      .filter(curveType => !PricingBreakdownFilters[params.pricingBreakdown].includes(curveType))
      .reduce((acc: NewIssueRowDef[], curveType) => {
        const maturities = params.levels.find(level => curveType === level.key)?.data;
        const formattedRowTitle =
          curveType === NewIssueCurveType.Total
            ? this.getTotalRowLabel({
                ...params,
                showPricingBasis: params.showPricingBasisInRow,
              })
            : this.baseRowTitles[curveType];
        acc.push(
          this.generateNewIssueRow({
            ...params,
            curveType,
            issuingEntityId: `${params.issuingEntityId}`,
            maturities,
            rowTitle: {
              value: curveType,
              label: formattedRowTitle,
            },
          }),
        );
        return acc;
      }, []);
  }

  generateNewIssueRow(params: NewIssueRowCreationParams): NewIssueRowDef {
    const id = this.generateCurveID({
      tab: params.tab,
      curveType: params.curveType,
      issuingEntityId: params.issuingEntityId,
      ...(params.historicalDate
        ? {
            historicalDate: params.historicalDate,
            isHistoricalPricing: params.isHistoricalPricing,
          }
        : {}),
      ...([
        NewIssueCurveType.Swap,
        NewIssueCurveType.OverviewSwap,
        NewIssueCurveType.Custom,
        NewIssueCurveType.BenchmarkInput,
        NewIssueCurveType.BenchmarkYield,
        NewIssueCurveType.Interpolated,
        NewIssueCurveType.ReferenceCurve,
      ].includes(params.curveType)
        ? { title: params.rowTitle.value as string }
        : {}),
    });
    return {
      id,
      tab: params.tab,
      pricingBasis: params.pricingBasis,
      curveType: params.curveType,
      issuingEntityId: params.issuingEntityId,
      rowTitle: params.rowTitle,
      maturities: params.maturities ?? {},
      ...((params.historicalDate && { historicalDate: params.historicalDate }) ?? {}),
      ...((params.pricingBreakdown && { pricingBreakdown: params.pricingBreakdown }) ?? {}),
      ...((params.selectOptions && { selectOptions: params.selectOptions }) ?? {}),
      units: this.getUnitsForRow({
        basisType: this.syndicatePricerUtilService.getBasisType(params.pricingBasis, params.constants),
        curveType: params.curveType,
        swapBasisType:
          this.syndicatePricerUtilService.getBasisType(params.rowTitle.value as string, params.constants) ?? undefined,
      }),
      editable: this.isEditableRow({ ...params }),
    } as NewIssueRowDef;
  }

  getUnitsForRow(params: {
    basisType: BasisType;
    curveType: NewIssueCurveType;
    swapBasisType?: BasisType;
  }): PricingUnits {
    switch (params.curveType) {
      case NewIssueCurveType.Spread:
      case NewIssueCurveType.NewIssuePremium:
      case NewIssueCurveType.Total:
      case NewIssueCurveType.Custom:
      case NewIssueCurveType.Interpolated:
        return params.basisType === BasisType.Fixed ? PricingUnits.Percentage : PricingUnits.BasisPoints;
      case NewIssueCurveType.Yield:
      case NewIssueCurveType.BenchmarkYield:
        return PricingUnits.Percentage;
      case NewIssueCurveType.ReferenceCurve:
      case NewIssueCurveType.OverviewSwap:
      case NewIssueCurveType.Swap:
        return params.swapBasisType &&
          (params.swapBasisType === BasisType.Fixed ||
            (params.curveType === NewIssueCurveType.ReferenceCurve &&
              (params.swapBasisType === BasisType.Govie || params.swapBasisType === BasisType.MS)))
          ? PricingUnits.Percentage
          : PricingUnits.BasisPoints;
      default:
        return PricingUnits.None;
    }
  }

  isEditableRow(params: {
    curveType: NewIssueCurveType;
    pricingBreakdown?: PricingBreakDownType;
    historicalDate?: string;
    editableHistorical?: boolean;
  }) {
    if (!params.editableHistorical && params.historicalDate) {
      return false;
    }
    if (
      [
        NewIssueCurveType.Custom,
        NewIssueCurveType.BenchmarkInput,
        ...PricingBreakdownEditableRows[params.pricingBreakdown ?? DEFAULT_PRICING_BREAKDOWN],
      ].includes(params.curveType)
    ) {
      return true;
    }

    return false;
  }

  getTotalRowLabel(params: { tab: string; pricingBasis: string; constants: Constants; showPricingBasis?: boolean }) {
    const isPricedToOriginalBasis = this.syndicatePricerUtilService.isPricedToOriginalBasis(
      params.tab,
      params.pricingBasis,
      params.constants,
    );

    if (isPricedToOriginalBasis) {
      return 'Yield';
    }

    const isFloatingRate =
      this.syndicatePricerUtilService.getBasisType(params.pricingBasis, params.constants) === BasisType.Floating;

    const title = isFloatingRate ? 'Discount Margin' : this.baseRowTitles[NewIssueCurveType.Total];
    return params.showPricingBasis
      ? `${title} vs ${this.syndicatePricerUtilService.getBasisLabel(params.pricingBasis, params.constants)}`
      : title;
  }

  generateCustomRow(params: {
    tab: string;
    rowName: string;
    pricingBasis: string;
    constants: Constants;
    maturities?: Maturities;
  }): NewIssueRowDef {
    return this.generateNewIssueRow({
      ...params,
      curveType: NewIssueCurveType.Custom,
      issuingEntityId: GLOBAL_FIELD,
      rowTitle: {
        value: params.rowName,
        label: params.rowName,
      },
    });
  }

  generateSwapPricingRows(params: {
    tab: string;
    issuingEntityIds: (string | number)[];
    pricingBasis: string;
    bases: string[];
    constants: Constants;
    maturities?: Maturities;
    isOverviewSwap?: boolean;
  }): NewIssueRowDef[] {
    return params.issuingEntityIds.reduce((acc: NewIssueRowDef[], issuingEntity: string) => {
      params.bases.forEach(swapBasis => {
        acc.push(
          this.generateNewIssueRow({
            ...params,
            issuingEntityId: `${issuingEntity}`,
            curveType: params.isOverviewSwap ? NewIssueCurveType.OverviewSwap : NewIssueCurveType.Swap,
            rowTitle: {
              value: swapBasis,
              label: `vs ${this.syndicatePricerUtilService.getBasisLabel(swapBasis, params.constants)}`,
            },
          }),
        );
      });
      return acc;
    }, []);
  }

  generateReferenceCurveRows(params: GenerateReferenceCurveParams): NewIssueRowDef[] {
    return params.bases.reduce((acc: NewIssueRowDef[], basis: string) => {
      // TODO: Do we still want this check here since we are checking before adding to additional curves
      const basisType = this.syndicatePricerUtilService.getBasisType(basis, params.constants);

      if (basisType === BasisType.Floating || basisType === BasisType.Fixed) {
        return acc;
      }

      if (basisType === BasisType.Govie && basis === params.pricingBasis) {
        acc.push(
          this.generateNewIssueRow({
            ...params,
            issuingEntityId: GLOBAL_FIELD,
            curveType: NewIssueCurveType.BenchmarkInput,
            maturities: params.maturityMap?.[NewIssueCurveType.BenchmarkInput],
            rowTitle: {
              value: basis,
              label: `Benchmark ${this.syndicatePricerUtilService.getBasisLabel(basis, params.constants)}`,
            },
          }),
        );

        const benchmarkYieldParams: NewIssueRowCreationParams = {
          ...params,
          issuingEntityId: GLOBAL_FIELD,
          curveType: NewIssueCurveType.BenchmarkYield,
          rowTitle: {
            value: NewIssueCurveType.BenchmarkYield,
            label: 'Benchmark Yield',
          },
          maturities: params.maturityMap?.[NewIssueCurveType.BenchmarkYield],
        };
        if (benchmarkYieldParams.selectOptions) {
          delete benchmarkYieldParams.selectOptions;
        }
        acc.push(this.generateNewIssueRow(benchmarkYieldParams));
      } else {
        acc.push(
          this.generateNewIssueRow({
            ...params,
            issuingEntityId: GLOBAL_FIELD,
            curveType: NewIssueCurveType.ReferenceCurve,
            maturities: params.maturityMap?.[NewIssueCurveType.ReferenceCurve],
            rowTitle: {
              value: basis,
              label: this.syndicatePricerUtilService.getBasisLabel(basis, params.constants),
            },
          }),
        );
      }
      return acc;
    }, []);
  }

  generateRegressionRows(params: {
    tab: string;
    pricingBasis: string;
    regression: Record<string, RegressionObject>;
    constants: Constants;
  }): NewIssueRowDef[] {
    return Object.keys(params.regression).map((key, idx) => {
      return this.generateNewIssueRow({
        ...params,
        issuingEntityId: GLOBAL_FIELD,
        curveType: NewIssueCurveType.Interpolated,
        rowTitle: {
          value: idx,
          label:
            key === 'combined'
              ? COMBINED_INTERPOLATION_LABEL
              : (params.constants.mappingFor.product_types?.[key]?.label ?? key),
        },
      });
    });
  }

  generateRowsFromAdditionalCurves(params: {
    tab: string;
    pricingBasis: string;
    pricingTabDataMap: PricingTabDataMap;
    sharedTabData: SharedTabData;
    govieBonds: Record<string, GovieBond[]>;
    constants: Constants;
    asOf?: string;
    hidePricedData?: boolean;
  }) {
    const rowDataMap: Record<string, NewIssueRowDef> = {};
    const rowIds: Record<string, moment.Moment> = {};
    const issuerPricingDataMap = params.pricingTabDataMap?.[params.tab];
    const issuingEntityIds = Object.keys(issuerPricingDataMap);
    const showReferenceCurves = getShowReferenceCurvesForTab(issuerPricingDataMap);
    const swapSet = new Set<string>();
    const referenceSet = new Set<string>();
    let initialBenchmarksBonds: Maturities = {};
    let initialBenchmarksYields: Maturities = {};
    let hasReferenceCurve = false;

    const updateRowBasedOnVersion = (
      row: NewIssueRowDef,
      lastUpdated: moment.Moment,
      additionalCondition?: boolean,
    ) => {
      if (!rowIds[row.id] || additionalCondition || lastUpdated.isAfter(rowIds[row.id])) {
        rowIds[row.id] = lastUpdated;
        rowDataMap[row.id] =
          rowDataMap[row.id] && additionalCondition
            ? {
                ...row,
                maturities: {
                  ...row.maturities,
                  ...rowDataMap[row.id].maturities,
                },
              }
            : row;
      }
    };

    Object.entries(issuerPricingDataMap).forEach(([issuingEntityId, issuerPricingData]) => {
      const lastUpdated = moment(issuerPricingData.pricingRequest.pricing.updated_at);
      const historicalYields: Record<string, Maturities> = {};
      issuerPricingData.pricingRequest.pricing.additional_curves?.forEach(curve => {
        switch (curve.type) {
          case NewIssueCurveType.Custom:
            const customRow = this.generateCustomRow({
              ...params,
              rowName: curve.value,
              maturities: curve.data,
            });
            updateRowBasedOnVersion(customRow, lastUpdated);
            break;
          case NewIssueCurveType.Swap:
            [
              ...this.generateSwapPricingRows({
                ...params,
                issuingEntityIds: [issuingEntityId],
                bases: [curve.value],
                maturities: params.hidePricedData ? {} : curve.data,
              }),
              ...this.generateSwapPricingRows({
                ...params,
                issuingEntityIds: issuingEntityIds.filter(id => id !== issuingEntityId),
                bases: [curve.value],
              }),
            ].forEach(swapRow => {
              updateRowBasedOnVersion(swapRow, lastUpdated, true);
            });
            swapSet.add(curve.value);
            break;
          case NewIssueCurveType.ReferenceCurve:
            if (curve.value === params.pricingBasis || showReferenceCurves) {
              if (curve.value === params.pricingBasis) {
                hasReferenceCurve = true;
              }
              const referenceRow = this.generateReferenceCurveRows({
                ...params,
                bases: [curve.value],
                maturityMap: { [NewIssueCurveType.ReferenceCurve]: params.hidePricedData ? {} : curve.data },
              });
              if (referenceRow.length) {
                updateRowBasedOnVersion(referenceRow[0], lastUpdated);
              }
            }
            referenceSet.add(curve.value);
            break;
          // todo: add date so that it prioritises by most recent selection
          case NewIssueCurveType.BenchmarkInput:
            if (curve.value === params.pricingBasis) {
              initialBenchmarksBonds = {
                ...initialBenchmarksBonds,
                ...curve.data,
              };
            }
            break;
          case NewIssueCurveType.BenchmarkYield:
            if (curve.value === params.pricingBasis) {
              initialBenchmarksYields = {
                ...initialBenchmarksYields,
                ...curve.data,
              };
            }
            break;
          case NewIssueCurveType.Historical:
            if (curve.data) {
              historicalYields[curve.value] = curve.data;
            }
            break;
          default:
            break;
        }
      });

      const historicalPricingRequests = params.sharedTabData?.historicalPricingRequestsData?.[issuingEntityId];
      Object.entries(historicalPricingRequests ?? {}).forEach(([historicalDate, historicalPricingRequest]) => {
        this.generatePricingRows({
          ...params,
          issuingEntityId,
          levels: [
            ...historicalPricingRequest.pricing.levels,
            ...(historicalYields[historicalDate]
              ? [{ key: NewIssueCurveType.Yield, data: historicalYields[historicalDate] }]
              : (historicalPricingRequest.pricing.additional_curves
                  ?.filter(curve => curve.type === NewIssueCurveType.Yield)
                  ?.map(curve => ({ key: curve.type, data: curve.data }) as CurveLevel) ?? [])),
          ],
          pricingBreakdown: historicalPricingRequest.pricing.breakdown ?? DEFAULT_PRICING_BREAKDOWN,
          historicalDate,
          isHistoricalPricing: true,
          yieldMaturities:
            historicalYields[historicalDate] ??
            historicalPricingRequest.pricing.additional_curves?.find(
              curve => curve.type === NewIssueCurveType.Yield && curve.value === params.pricingBasis,
            )?.data,
        }).forEach(row => {
          rowDataMap[row.id] = row;
        });
      });
    });

    const hasGovieBasis = this.syndicatePricerUtilService.isGovieBasis(params.pricingBasis, params.constants);
    if (!hasReferenceCurve || hasGovieBasis) {
      let selectOptions: Record<string, ValueAndLabel[]> = {};
      const referenceCurveParams: GenerateReferenceCurveParams = {
        ...params,
        bases: [params.pricingBasis],
      };

      if (hasGovieBasis) {
        referenceCurveParams.maturityMap = {
          [NewIssueCurveType.BenchmarkInput]: initialBenchmarksBonds,
          [NewIssueCurveType.BenchmarkYield]: initialBenchmarksYields,
        };
        const govieBonds = params.govieBonds[params.pricingBasis];
        if (govieBonds) {
          referenceCurveParams.basisGovieBonds = govieBonds;
          selectOptions = getDisplayMaturitiesForTab(params.tab, params.pricingTabDataMap).reduce(
            (selectOptions: Record<string, ValueAndLabel[]>, maturity) => {
              const colTenor = this.levelsUtilService.getValueFromMaturity(maturity, params.asOf);
              selectOptions[maturity] =
                this.syndicatePricerUtilService
                  .getBondSelectionForTenor(colTenor, govieBonds, params.asOf)
                  .sort((a, b) => sortByAlphabetical(a.maturity_date, b.maturity_date))
                  .map(
                    (bond: GovieBond): ValueAndLabel => ({
                      value: bond.isin,
                      label: this.syndicatePricerUtilService.formatBenchmarkLabel(bond.isin, govieBonds ?? []),
                    }),
                  ) ?? [];
              return selectOptions;
            },
            {},
          );
        }
      } else {
        referenceCurveParams.maturityMap = {
          [NewIssueCurveType.ReferenceCurve]: {},
        };
      }
      this.generateReferenceCurveRows({ ...referenceCurveParams, selectOptions }).forEach(curve => {
        rowDataMap[curve.id] = curve;
      });
    }

    if (showReferenceCurves && !this.utilService.areSetsEquivalent(swapSet, referenceSet)) {
      // Covers edge cases in some groups in which they have swaps that could reference curves but don't show it being viewed in a group where they show references curves
      this.generateReferenceCurveRows({
        ...params,
        bases: [...swapSet],
      }).forEach(row => {
        if (!rowDataMap[row.id]) {
          rowDataMap[row.id] = row;
        }
      });
    }

    const regression = params.sharedTabData.regression;
    if (regression) {
      this.generateRegressionRows({
        ...params,
        regression,
      }).forEach(row => {
        if (!rowDataMap[row.id]) {
          rowDataMap[row.id] = row;
        }
      });
    }

    return Object.values(rowDataMap);
  }

  generateRowData(params: GenerateRowDataParams) {
    const rowData: NewIssueRowDef[] = [];
    const isOverviewTab = params.selectedTab === OVERVIEW_TAB;
    [...(isOverviewTab ? Object.keys(params.pricingTabDataMap) : [params.selectedTab])].forEach((tab: string) => {
      const issuerPricingDataMap = params.pricingTabDataMap[tab];
      if (!issuerPricingDataMap) {
        return;
      }
      const pricingBasis = getPricingBasisForTab(issuerPricingDataMap);

      const initialRowData = this.getInitialRowDataForTab({
        ...params,
        tab,
        showPricingBasisInRow: isOverviewTab || params.isExport,
      });
      rowData.push(...initialRowData);

      if (isOverviewTab) {
        Object.entries(issuerPricingDataMap).forEach(([issuingEntityId, issuerPricingData]) => {
          if (params.summaryTabSwapBasis) {
            const additionalCurves = issuerPricingData.pricingRequest.pricing.additional_curves ?? [];
            this.generateSwapPricingRows({
              tab,
              issuingEntityIds: [issuingEntityId],
              pricingBasis,
              bases: [params.summaryTabSwapBasis],
              constants: params.constants,
              isOverviewSwap: true,
            }).forEach(row => {
              const pricedData =
                additionalCurves.find(curve => curve.type === NewIssueCurveType.OverviewSwap)?.data ?? {};

              rowData.push({
                ...row,
                maturities: pricedData,
              });
            });
          }
        });
      } else {
        const sharedTabData = params.sharedTabDataMap?.[tab];

        if (sharedTabData) {
          const additionalCurveRows = this.generateRowsFromAdditionalCurves({
            ...params,
            tab,
            pricingBasis,
            sharedTabData,
          });
          rowData.push(...additionalCurveRows);
        }
      }
    });
    return rowData;
  }

  generatePricedRowData(baseRowData: NewIssueRowDef[], sharedTabDataMap: SharedTabDataMap) {
    return baseRowData.reduce((rowData: NewIssueRowDef[], row) => {
      const pricedMaturities = sharedTabDataMap[row.tab]?.tempPricingMap?.[row.id];
      rowData.push(
        pricedMaturities
          ? {
              ...row,
              maturities: pricedMaturities,
            }
          : row,
      );
      return rowData;
    }, []);
  }

  getMaturitiesFromRegression(
    regressionData: Record<string, RegressionObject>,
    tab: string,
    displayMaturities: string[],
  ) {
    return Object.values(regressionData).reduce((maturities: Record<string, Maturities>, regressionObject, idx) => {
      const id = this.generateCurveID({
        tab,
        curveType: NewIssueCurveType.Interpolated,
        title: String(idx),
      });
      const interpolatedValues: Maturities = {};
      displayMaturities.forEach(maturity => {
        interpolatedValues[maturity] = this.syndicatePricerUtilService.getMaturityForInterpolatedCurve(
          regressionObject,
          maturity,
        );
      });
      maturities[id] = interpolatedValues;
      return maturities;
    }, {});
  }

  newIssueRowSort(
    nodeA: IRowNode,
    nodeB: IRowNode,
    selectedPricingTabBasis: string,
    issuingEntities: Record<string, string>,
    rowOrder: string[],
    constants: Constants,
  ) {
    if (!nodeA || !nodeB || !nodeA.key || !nodeB.key) {
      return 0;
    }
    const valueA = nodeA.key;
    const valueB = nodeB.key;

    if (nodeA.level === 0) {
      if (nodeB.level === 0) {
        const issuerA = issuingEntities[valueA];
        const issuerB = issuingEntities[valueB];
        if (issuerA && issuerB) {
          return sortByAlphabetical(issuerA, issuerB);
        }
      }
      return nodeA.key === NewIssueCurveType.ReferenceCurve || nodeB.key === NewIssueCurveType.Interpolated ? -1 : 1;
    }

    if (nodeA.level === 1 && valueA.includes(HISTORICAL_PRICING) && valueB.includes(HISTORICAL_PRICING)) {
      const getDateFromValue = (value: string) => value.slice(HISTORICAL_PRICING.length + 1);
      return sortByAlphabeticalInverse(getDateFromValue(valueA), getDateFromValue(valueB));
    }

    const positionOne = rowOrder.indexOf(nodeA.data ? nodeA.data.curveType : valueA);
    const positionTwo = rowOrder.indexOf(nodeB.data ? nodeB.data.curveType : valueB);
    if ((positionOne === -1 && positionTwo === -1) || positionOne === positionTwo) {
      if (nodeA.data && nodeA.data.curveType === NewIssueCurveType.ReferenceCurve) {
        if (valueA === selectedPricingTabBasis) {
          return -1;
        } else if (valueB === selectedPricingTabBasis) {
          return 1;
        }
      }

      const getFormattedValue = (value: string, node: IRowNode) => {
        let formattedValue;
        switch (node.data?.curveType) {
          case NewIssueCurveType.Swap:
          case NewIssueCurveType.OverviewSwap:
          case NewIssueCurveType.ReferenceCurve:
            formattedValue = this.syndicatePricerUtilService.getBasisLabel(value, constants);
            break;
          case NewIssueCurveType.Interpolated:
            formattedValue = node.data.rowTitle.label;
            break;
          default:
            formattedValue = value;
        }
        return formattedValue;
      };

      const formattedValueA = getFormattedValue(valueA, nodeA);
      const formattedValueB = getFormattedValue(valueB, nodeB);

      if (formattedValueA === COMBINED_INTERPOLATION_LABEL) {
        return -1;
      } else if (formattedValueB === COMBINED_INTERPOLATION_LABEL) {
        return 1;
      }
      return sortByAlphabetical(formattedValueA, formattedValueB);
    } else if (positionOne === -1) {
      return 1;
    } else if (positionTwo === -1) {
      return -1;
    }

    return positionOne > positionTwo ? 1 : -1;
  }
}
