import { SidebarType, smallScreenBreakPoint } from '@morpho/core';
import { Action, createReducer, on } from '@ngrx/store';
import { TradeWorkingGroup } from '../models';
import { TradesAction } from './trades.actions';
import { TradeDownloadOption, TradesState } from './trades.model';
export const TRADES_STATE_KEY = 'trades';

const initialState: TradesState = {
  tradeBlotter: {},
  activeTrade: {
    timelineSidebar: window.innerWidth > smallScreenBreakPoint ? SidebarType.TimelineActivities : null,
  },
};

function workingGroupComparator(a: TradeWorkingGroup, b: TradeWorkingGroup): number {
  if (a.can_user_add) {
    return -1;
  }
  if (b.can_user_add) {
    return 1;
  }
  if (a.company?.company_name < b.company?.company_name || a.can_user_add) {
    return -1;
  }
  if (a.company?.company_name > b.company?.company_name || b.can_user_add) {
    return 1;
  }
  return 0;
}

const reducer = createReducer(
  initialState,
  on(TradesAction.getTradeActivitiesSuccess, (state, action): TradesState => {
    if (state.activeTrade.id !== action.params.id) {
      return state;
    }
    return {
      ...state,
      activeTrade: { ...state.activeTrade, activities: action.params.activities },
    };
  }),

  on(TradesAction.getTradeDetails, (state, action): TradesState => {
    if (state.activeTrade.id === action.params.id) {
      return state;
    }
    return {
      ...state,
      activeTrade: { ...state.activeTrade, id: action.params.id },
    };
  }),

  on(TradesAction.getTradeDetailsSuccess, (state, action): TradesState => {
    if (state.activeTrade.id !== action.params.id) {
      return state;
    }
    return {
      ...state,
      activeTrade: {
        ...state.activeTrade,
        details: action.params.details,
        shortDescription: action.params.shortDescription,
      },
    };
  }),

  on(TradesAction.getTradePermissionsSuccess, (state, action): TradesState => {
    if (state.activeTrade.id !== action.params.id) {
      return state;
    }
    return {
      ...state,
      activeTrade: { ...state.activeTrade, permissions: action.params.permissions },
    };
  }),

  on(TradesAction.getTradeStagesSuccess, (state, action): TradesState => {
    if (state.activeTrade.id !== action.params.id) {
      return state;
    }
    return {
      ...state,
      activeTrade: { ...state.activeTrade, stages: action.params.stages },
    };
  }),

  on(TradesAction.getUploadedTradeDocumentsSuccess, (state, action): TradesState => {
    if (state.activeTrade.id !== action.params.id) {
      return state;
    }
    return {
      ...state,
      activeTrade: { ...state.activeTrade, uploadedTradeDocuments: action.params.uploadedTradeDocuments },
    };
  }),

  on(TradesAction.getTradeWorkingGroupsSuccess, (state, action): TradesState => {
    if (Number(state.activeTrade.id) !== Number(action.params.id)) {
      return state;
    }

    return {
      ...state,
      activeTrade: {
        ...state.activeTrade,
        workingGroups: action.params.workingGroups ? [...action.params.workingGroups].sort(workingGroupComparator) : [],
      },
    };
  }),

  on(TradesAction.addTradeWorkingGroupSuccess, (state, action): TradesState => {
    if (Number(state.activeTrade.id) !== Number(action.params.tradeId)) {
      return state;
    }
    return {
      ...state,
    };
  }),

  on(TradesAction.addWorkingGroupUsersSuccess, (state, action): TradesState => {
    if (Number(state.activeTrade.id) !== Number(action.params.tradeId)) {
      return state;
    }

    return {
      ...state,
      activeTrade: {
        ...state.activeTrade,
        workingGroups: state.activeTrade.workingGroups
          ? [...state.activeTrade.workingGroups].map((wg: TradeWorkingGroup) => {
              if (wg.id === action.params.workingGroup.id) {
                return action.params.workingGroup;
              }
              return wg;
            })
          : state.activeTrade.workingGroups,
      },
    };
  }),

  on(TradesAction.deleteWorkingGroupUsersSuccess, (state, action): TradesState => {
    if (Number(state.activeTrade.id) !== Number(action.params.tradeId)) {
      return state;
    }
    return {
      ...state,
      activeTrade: {
        ...state.activeTrade,
        workingGroups: state.activeTrade.workingGroups
          ? [...state.activeTrade.workingGroups].map((wg: TradeWorkingGroup) => {
              if (wg.id === action.params.workingGroup.id) {
                return action.params.workingGroup;
              }
              return wg;
            })
          : state.activeTrade.workingGroups,
      },
    };
  }),

  on(TradesAction.getTradeCommentUsersSuccess, (state, action): TradesState => {
    return {
      ...state,
      activeTrade: {
        ...state.activeTrade,
        commentUsersOptions: action.params.commentUsersOptions ? [...action.params.commentUsersOptions] : [],
      },
    };
  }),

  on(TradesAction.getTradeDownloadOptionsSuccess, (state, action): TradesState => {
    const downloadOptions = action.params.downloadOptions;
    const tradeId = action.params.tradeId;
    const newOptions: { [tradeId: number]: TradeDownloadOption[] } = { ...(state.tradeBlotter.downloadOptions ?? {}) };
    newOptions[tradeId] = downloadOptions;
    return {
      ...state,
      tradeBlotter: { ...state.tradeBlotter, downloadOptions: newOptions },
    };
  }),

  on(TradesAction.getTradeReportOptionsSuccess, (state, action): TradesState => {
    return {
      ...state,
      tradeBlotter: { ...state.tradeBlotter, reportOptions: action.params.reportOptions },
    };
  }),

  on(TradesAction.sendMessageOptimistic, (state, action): TradesState => {
    const activities = [...(state.activeTrade.activities || [])];
    activities.unshift({ ...action.params, isOptimistic: true });
    return {
      ...state,
      activeTrade: { ...state.activeTrade, activities },
    };
  }),

  on(TradesAction.sendMessageUndo, (state): TradesState => {
    const activities = [...(state.activeTrade.activities || [])].filter(activity => !activity.isOptimistic);
    return {
      ...state,
      activeTrade: { ...state.activeTrade, activities },
    };
  }),

  on(TradesAction.toggleTimelineSidebar, (state, action): TradesState => {
    return {
      ...state,
      activeTrade: {
        ...state.activeTrade,
        timelineSidebar: action.params === state.activeTrade.timelineSidebar ? null : action.params,
      },
    };
  }),

  on(TradesAction.resetActiveTrade, (state): TradesState => ({ ...state, activeTrade: initialState.activeTrade })),
  on(
    TradesAction.getTradeDocumentCompletionSuccess,
    (state, action): TradesState => ({
      ...state,
      activeTrade: { ...state.activeTrade, documentPercentComplete: action.params.complete },
    }),
  ),
);

export function TradesReducer(state: TradesState | undefined, action: Action) {
  return reducer(state, action);
}
