import { Component, Input, OnInit } from '@angular/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { DocumentInfo, DocumentListItem } from '../document-list.model';
import { DocumentListService } from '../document-list.service';

const iconKey = ['docx', 'pdf', 'csv'] as const;

export type IconKey = (typeof iconKey)[number];

export type IconMap = {
  [key in IconKey]: string;
};

@Component({
  standalone: false,
  selector: 'om-document-list',
  templateUrl: './document-list.component.html',
})
export class DocumentListComponent implements OnInit {
  @Input() documents: DocumentInfo[];

  documentDownloadList: DocumentListItem[] = [];
  selectedIndex: number | null;

  readonly documentIcons: IconMap = {
    docx: 'file_word',
    pdf: 'file_pdf',
    csv: 'document',
  };

  constructor(
    private dialogService: DialogService,
    private documentListService: DocumentListService,
  ) {}

  ngOnInit(): void {
    this.documentDownloadList = this.documentListService.createDocumentList(this.documents);
  }

  onDownloadModal(config: BooleanPopupParams) {
    this.dialogService.booleanPopup(config).subscribe();
  }

  onMenuClosed(): void {
    this.selectedIndex = null;
  }

  onSelectDocument(i: number): void {
    this.selectedIndex = i;
  }
}
