export enum MaturityType {
  Date = 'date',
  Tenor = 'tenor',
  CallableStructure = 'callable_structure',
  PerpetualStructure = 'perpetual_structure',
  None = '',
}

export enum BasisType {
  Fixed = 'fixed',
  Floating = 'floating',
  Govie = 'govie',
  MS = 'ms',
}
