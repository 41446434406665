import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CarouselModalOverlayRef } from '../../../../../../dialog/src/lib/components/carousel-modal/carousel-modal-ref';

@Component({
  standalone: false,
  selector: 'om-carousel-slide',
  templateUrl: './carousel-slide.component.html',
  styleUrls: ['./carousel-slide.component.scss'],
})
export class CarouselSlideComponent implements AfterViewInit, OnDestroy {
  @Input() slideComponent: any;
  @Input() slideData: any;

  // if used in a carousel modal
  @Input() carouselDialogRef: CarouselModalOverlayRef;
  @Output() closeSlide: EventEmitter<any> = new EventEmitter();

  @ViewChild('slideContainer', { read: ViewContainerRef }) slideContainer: any;
  componentRef: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver) {}

  ngAfterViewInit() {
    this.createslideContainer();
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.changeDetectorRef.detach();
    }
  }

  private createslideContainer(): void {
    if (!this.slideContainer) {
      return;
    }
    this.slideContainer.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.slideComponent);
    this.componentRef = this.slideContainer.createComponent(factory);
    this.componentRef.instance.slideData = this.slideData;

    // if used in a carousel modal
    if (this.carouselDialogRef) {
      this.componentRef.instance.carouselDialogRef = this.carouselDialogRef;
      this.componentRef.instance.closeSlide = this.closeSlide;
    }

    this.componentRef.changeDetectorRef.detectChanges();
  }
}
