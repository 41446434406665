import { EChartsOption } from 'echarts';
import { RegressionMode } from '../../models/regression.model';

export type LegendSelection = Record<any, boolean>;

export interface ChartConfig<T> {
  eChartsOption: EChartsOption;
  colorConfig: Record<string, number>;
  legendConfig?: ChartLegendConfig<T>;
}

export interface ChartLegendConfig<T> {
  items: T[];
  itemType: string;
  itemKey: keyof T;
  initialLegendSelection: LegendSelection;
  getItemName: (item: T) => string;
  getItemColor?: (item: T) => string;
  getItemLayers?: (item: T) => string[];
}

export enum SecondaryPlotMode {
  Bonds = 'bonds',
  Curve = 'curve',
  Both = 'both',
}

export interface ChartConfigOptions {
  maxColorCount?: number;
  hideScrollBar?: boolean;
  singleShade?: boolean;
  showBondSize?: boolean;
  regression?: RegressionMode;
  regressionOrder?: number;
  secondaryPlotMode?: SecondaryPlotMode;
}

export const PRIMARY_POSTFIX = '-primary';
export const SECONDARY_POSTFIX = '-secondary';
export const PRICING_POSTFIX = '-pricing';
export const CALLABLE_POSTFIX = '-callable-pricing';
