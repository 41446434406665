<mat-expansion-panel (closed)="closePanel()" (opened)="openPanel()">
  <mat-expansion-panel-header>
    <mat-panel-title
      ><span> {{ columnConfig.label }} </span>
      @if (selectedOption && !isPanelOpen) {
        <mat-chip-listbox>
          <mat-chip>
            {{ chipDisplay }}
            <span><mat-icon svgIcon="remove" matChipRemove (click)="reset()"></mat-icon></span>
          </mat-chip>
        </mat-chip-listbox>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>
  <section class="om-date-filter">
    <mat-form-field>
      <mat-select [(ngModel)]="dateOperator" (selectionChange)="selectDateOption()">
        @for (option of dateOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <section>
      <mat-form-field>
        <om-date-input [(ngModel)]="minDate" (ngModelChange)="onDateCalenderChanged($event)"> </om-date-input>
      </mat-form-field>
      @if (dateOperator === 'range-between') {
        <mat-form-field>
          <om-date-input [(ngModel)]="maxDate" (ngModelChange)="onDateMaxCalenderChanged($event)"> </om-date-input>
        </mat-form-field>
      }
    </section>
  </section>
</mat-expansion-panel>
