import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface RowLevelAction {
  label: string;
  icon?: string;
  class?: string;
  url?: string;
  download?: boolean;
  onClick?: (params: RowLevelActionsCellRendererParams, event?: MouseEvent) => void;
  isHidden?(params: RowLevelActionsCellRendererParams): boolean;
}

export interface RowLevelActionsCellRendererParams extends ICellRendererParams {
  showBadge?: boolean;
  omHideGroupIcons: boolean; // only show icon if group is of size 1 (i.e. single action)
  omData?: any;
  showSingleActionOnMenu?: boolean;
  omGetActions: (params: RowLevelActionsCellRendererParams) => RowLevelAction[];
  onOpen?: (params: RowLevelActionsCellRendererParams) => void;
  isLoading?: (params: RowLevelActionsCellRendererParams) => boolean;
}

@Component({
  standalone: false,
  selector: 'om-row-level-actions-cell-renderer',
  templateUrl: './row-level-actions-cell-renderer.component.html',
  styleUrls: ['./row-level-actions-cell-renderer.component.scss'],
})
export class RowLevelActionsCellRendererComponent implements ICellRendererAngularComp {
  params: RowLevelActionsCellRendererParams;
  actions: RowLevelAction[];
  singleAction: RowLevelAction | null;
  areGroupIconsHidden: boolean;

  loading = false;

  agInit(params: RowLevelActionsCellRendererParams) {
    this.refresh(params);
    this.areGroupIconsHidden = params.omHideGroupIcons;
  }

  refresh(params: RowLevelActionsCellRendererParams): boolean {
    this.params = params;
    this.actions = (params.omGetActions(params) ?? []).filter(action => !action.isHidden || !action.isHidden(params));
    this.singleAction = this.actions?.length === 1 ? this.actions[0] : null;
    this.loading = params.isLoading ? params.isLoading(params) : false;
    return true;
  }

  onOpen() {
    if (this.params.onOpen) {
      this.params.onOpen(this.params);
    }
  }

  onClick(action: RowLevelAction, event?: MouseEvent) {
    if (!action.url && action.onClick) {
      action.onClick(this.params, event);
    }
  }
}
