import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MAXIMUM_CHART_ITEMS } from '@shared-echarts/services/chart-options.constant';
import { ActivityMonitoringService } from 'apps/bankangle/src/app/core/services/activity-monitoring.service';
import { CurveAnalysisSelector } from 'apps/bankangle/src/app/core/state/curve-analysis/curve-analysis.selectors';
import { ActivityCheckpoint } from 'apps/bankangle/src/app/models/activity-monitoring';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IssuerType, PartialIssuer } from '../../../core/patterns/issuer/issuer.model';
import { PricingInfo } from '../../../core/patterns/pricing/pricing.model';
import { ConstantsState } from '../../../core/state/constants/constants.model';
import { Curve, CurveLevel, CurveType } from '../../../models/curve.model';
import { europeanCountries } from '../../main-view/main-view.constants';

export const DEFAULT_CURVE_SELECTION_SIZE = 10;
@Injectable({
  providedIn: 'root',
})
export class CurveAnalysisService {
  private readonly selectedIssuerIds$ = this.store.select(CurveAnalysisSelector.selectedIssuerIds);
  private readonly fundingBases$ = this.store
    .select(CurveAnalysisSelector.pricingInfoList)
    .pipe(map((infoList: PricingInfo[]) => infoList?.map(pricingInfo => pricingInfo.funding_basis)));
  private readonly loggingContextData$ = combineLatest([this.selectedIssuerIds$, this.fundingBases$]);

  constructor(
    private store: Store<any>,
    private activityMonitoringService: ActivityMonitoringService,
  ) {}

  logActivity(
    checkpoint: ActivityCheckpoint,
    params?: { [key: string]: any },
    additional_context?: { [key: string]: any },
  ) {
    this.loggingContextData$.pipe(take(1)).subscribe(([selectedIds, fundingBases]) => {
      const curve_analysis = {
        issuers: selectedIds ?? [],
        funding_bases: fundingBases ?? [],
        ...additional_context,
      };
      this.activityMonitoringService.logActivity(checkpoint, { curve_analysis, ...params });
    });
  }

  isPrimaryCurve(curve: Curve): boolean {
    return ['primary', 'custom'].includes(curve.curve_type);
  }

  isSecondaryCurve(curve: Curve): boolean {
    return curve.curve_type === 'secondary';
  }

  groupCurvesByType(curves: Curve[]): { primary: Curve[]; secondary: Curve[] } {
    const primary: Curve[] = [];
    const secondary: Curve[] = [];

    curves.forEach(curve => {
      if (this.isPrimaryCurve(curve)) {
        primary.push(curve);
      } else if (this.isSecondaryCurve(curve)) {
        secondary.push(curve);
      }
    });

    return { primary, secondary };
  }

  private getNumberOfPricedLevels(curve: Curve): number {
    return Object.values(curve.levels).reduce(
      (count: number, current: CurveLevel) => (current.priced?.[0]?.spread ? count + 1 : count),
      0,
    );
  }

  private getNumberOfPricedBonds(curve: Curve): number {
    return curve.bonds.filter(bond => bond.priced).length;
  }

  private commonCurveFiltering(curves: Curve[], constants: ConstantsState): Curve[] {
    const g13Currencies: string[] = [
      ...constants.arrayFor.currency_options_g10.map(currency => currency.value),
      'SGD',
      'CNY',
      'HKD',
    ] as string[];

    return curves.filter(
      curve =>
        !Object.values(curve.levels).some(level => level.is_re) &&
        curve.structures.some(structure => ['Fixed Rate', 'Floating Rate'].includes(structure)) &&
        curve.currencies.some(currency => g13Currencies.includes(currency)) &&
        this.getNumberOfPricedLevels(curve) > 1,
    );
  }

  private getInitialCurvesFig(issuer: PartialIssuer, curves: Curve[]): string[] {
    const groupedCurves = this.groupCurvesByType(curves);
    let selectedCurrency: string;

    if (issuer.country === 'GB') {
      selectedCurrency = 'GBP';
    } else if (europeanCountries.includes(issuer.country)) {
      selectedCurrency = 'EUR';
    } else {
      selectedCurrency = 'USD';
    }

    const primaryMap: Record<string, Curve> = {};
    groupedCurves.primary.forEach(curve => {
      if (!curve.currencies.includes(selectedCurrency)) {
        return;
      }
      const existing = primaryMap[curve.seniority];
      if (!existing || new Date(curve.last_updated ?? 0) > new Date(existing.last_updated ?? 0)) {
        primaryMap[curve.seniority] = curve;
      }
    });

    const secondaryMap: Record<string, Curve> = {};
    groupedCurves.secondary.forEach(curve => {
      if (!curve.currencies.includes(selectedCurrency)) {
        return;
      }
      const existing = secondaryMap[curve.seniority];
      if (!existing || this.getNumberOfPricedBonds(curve) > this.getNumberOfPricedBonds(existing)) {
        secondaryMap[curve.seniority] = curve;
      }
    });

    let filteredCurves = [...Object.values(primaryMap), ...Object.values(secondaryMap)];
    if (!filteredCurves.length) {
      filteredCurves = curves;
    }
    return filteredCurves.map(curve => curve.identifier);
  }

  private getInitialCurvesSSACorp(curves: Curve[], constants: ConstantsState): string[] {
    const groupedCurves = this.groupCurvesByType(curves.filter(curve => curve.seniority === 'senior_unsecured'));

    const primaryMap: Record<string, Curve> = {};
    groupedCurves.primary.forEach(curve => {
      const existing = primaryMap[curve.funding_basis];
      if (existing) {
        const sizeDiff: number =
          parseFloat(curve.min_size_millions) +
          parseFloat(curve.max_size_millions) -
          parseFloat(existing.min_size_millions) -
          parseFloat(existing.max_size_millions);
        if (sizeDiff < 0) {
          return;
        }
        if (sizeDiff > 0 || new Date(curve.last_updated ?? 0) > new Date(existing.last_updated ?? 0)) {
          primaryMap[curve.funding_basis] = curve;
        }
      } else {
        primaryMap[curve.funding_basis] = curve;
      }
    });

    const secondaryMap: Record<string, Curve> = {};
    groupedCurves.secondary.forEach(curve => {
      const basisCurrency: string = constants.arrayFor.basis_metadata_mapping[curve.funding_basis as any].currency;
      const isG10: boolean = constants.mappingFor.currency_options[basisCurrency].is_g10;
      if (!isG10) {
        return;
      }
      const existing = secondaryMap[basisCurrency];
      if (!existing || this.getNumberOfPricedLevels(curve) > this.getNumberOfPricedLevels(curve)) {
        secondaryMap[basisCurrency] = curve;
      }
    });

    let primaryFiltered = Object.values(primaryMap);
    const primaryNoThemes: Curve[] = primaryFiltered.filter(curve => !curve.issuance_themes?.length);
    if (primaryNoThemes.length) {
      primaryFiltered = primaryNoThemes;
    }
    const secondaryFiltered: Curve[] = Object.values(secondaryMap);

    let filteredCurves = [...primaryFiltered, ...secondaryFiltered];
    if (!filteredCurves.length) {
      filteredCurves = [...groupedCurves.primary, ...groupedCurves.secondary];
    }
    return filteredCurves.map(curve => curve.identifier);
  }

  private getInitialCurveSelection(curves: Curve[], constants: ConstantsState): string[] {
    if (!curves.length) {
      return [];
    }

    const issuer: PartialIssuer = curves[0].issuing_entity.issuer;
    const filteredCurves: Curve[] = this.commonCurveFiltering(curves, constants);
    let initialCurveIds =
      issuer.issuer_type === IssuerType.FIG
        ? this.getInitialCurvesFig(issuer, filteredCurves)
        : this.getInitialCurvesSSACorp(filteredCurves, constants);

    if (!initialCurveIds.length) {
      initialCurveIds = curves
        .filter(curve => this.getNumberOfPricedLevels(curve) > 1)
        .slice(0, 10)
        .map(curve => curve.identifier);
    }

    return initialCurveIds;
  }

  private filterCurvesSimilarToSelected(
    curves: Curve[],
    selectedCurveIds: string[],
    newIssuers: Set<number>,
    constants: ConstantsState,
  ): Curve[] {
    let seniorityScore = 0;
    let basisCurrencyScore = 0;
    const targetThemes: Set<string> = new Set<string>();
    const targetStructures: Set<String> = new Set<string>();
    const seniorityMap: Record<string, number> = {};
    const basisCurrencyMap: Record<string, number> = {};

    curves.forEach(curve => {
      if (selectedCurveIds.includes(curve.identifier)) {
        const basisCurrency: string = constants.arrayFor.basis_metadata_mapping[curve.funding_basis as any].currency;
        curve.issuance_themes?.forEach(theme => targetThemes.add(theme));
        if (curve.curve_type === CurveType.SECONDARY) {
          curve.structures.forEach(structure => targetStructures.add(structure));
        }
        seniorityMap[curve.seniority] ??= 0;
        seniorityMap[curve.seniority]++;
        basisCurrencyMap[basisCurrency] ??= 0;
        basisCurrencyMap[basisCurrency]++;
        seniorityScore = Math.max(seniorityScore, seniorityMap[curve.seniority]);
        basisCurrencyScore = Math.max(basisCurrencyScore, basisCurrencyMap[basisCurrency]);
      }
    });

    const targetSeniorities: Set<string> = new Set<string>(
      Object.entries(seniorityMap)
        .filter(([, value]) => value === seniorityScore)
        .map(([key]) => key),
    );
    const targetBasisCurrencies: Set<string> = new Set<string>(
      Object.entries(basisCurrencyMap)
        .filter(([, value]) => value === basisCurrencyScore)
        .map(([key]) => key),
    );

    return this.commonCurveFiltering(
      curves.filter(
        curve =>
          newIssuers.has(curve.issuing_entity.issuer.id) &&
          targetSeniorities.has(curve.seniority) &&
          targetBasisCurrencies.has(constants.arrayFor.basis_metadata_mapping[curve.funding_basis as any].currency) &&
          (!targetThemes.size || curve.issuance_themes?.some(theme => targetThemes.has(theme))) &&
          (this.isPrimaryCurve(curve) || curve.structures.some(structure => targetStructures.has(structure))),
      ),
      constants,
    );
  }

  private removeClashingCurveSuggestions(curves: Curve[]): string[] {
    let maxMinSize = 0;
    let maxDataPoints = 0;
    let latestUpdate: Date = new Date(0);

    const [filteredPrimary, filteredSecondaries]: [Curve | undefined, Curve[]] = curves.reduce(
      ([primary, secondaries]: [Curve | undefined, Curve[]], current: Curve): [Curve | undefined, Curve[]] => {
        if (this.isSecondaryCurve(current)) {
          const dataPointCount: number = this.getNumberOfPricedBonds(current);
          if (dataPointCount < maxDataPoints) {
            return [primary, secondaries];
          }
          if (dataPointCount > maxDataPoints) {
            maxDataPoints = dataPointCount;
            secondaries = [];
          }
          secondaries.push(current);
        } else {
          const currentUpdated: Date = new Date(current.last_updated ?? 0);
          const currentMinSize: number = parseFloat(current.min_size_millions);
          if (currentUpdated < latestUpdate) {
            return [primary, secondaries];
          }
          if (currentUpdated > latestUpdate || (currentUpdated === latestUpdate && currentMinSize > maxMinSize)) {
            maxMinSize = currentMinSize;
            latestUpdate = currentUpdated;
            primary = current;
          }
        }
        return [primary, secondaries];
      },
      [undefined, []],
    );

    return [
      ...(filteredPrimary ? [filteredPrimary.identifier] : []),
      ...filteredSecondaries.map(curve => curve.identifier),
    ];
  }

  getCurveSelection(
    curves: Curve[],
    selectedCurveIds: string[],
    newIssuers: Set<number>,
    constants: ConstantsState,
  ): string[] {
    if (!selectedCurveIds?.length) {
      return this.getInitialCurveSelection(curves, constants);
    }

    if (selectedCurveIds.length >= MAXIMUM_CHART_ITEMS) {
      return selectedCurveIds;
    }

    const filteredNewCurves: Curve[] = this.filterCurvesSimilarToSelected(
      curves,
      selectedCurveIds,
      newIssuers,
      constants,
    );
    const newSelection: string[] = this.removeClashingCurveSuggestions(filteredNewCurves);

    return [...selectedCurveIds, ...newSelection];
  }
}
