import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-label-value-table',
  templateUrl: './label-value-table.component.html',
  styleUrls: ['./label-value-table.component.scss'],
})
export class LabelValueTableComponent {
  @Input() data: { label: string; value: string }[];
}
