import { ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { BaseTextComponent } from 'slate-angular';

@Component({
  standalone: false,
  selector: 'span[textmark]',
  template: `<slate-leaves [context]="context" [viewContext]="viewContext"> {{ context }}</slate-leaves>`,
  host: {
    'data-slate-node': 'text',
  },
})
export class RichTextInlineComponent extends BaseTextComponent {
  private attributes: string[] = [];

  constructor(
    public elementRef: ElementRef,
    public renderer2: Renderer2,
    cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
  }

  applyTextMark() {
    this.attributes.forEach(attr => {
      this.renderer2.removeAttribute(this.elementRef.nativeElement, attr);
    });
    this.attributes = [];
    for (const [key, value] of Object.entries(this.text)) {
      if (Object.prototype.hasOwnProperty.call(this.text, key) && key !== 'text') {
        const attr = `slate-${key}`;
        this.renderer2.setAttribute(this.elementRef.nativeElement, attr, value);
        this.attributes.push(attr);
      }
    }
  }

  onContextChange() {
    super.onContextChange();
    this.applyTextMark();
  }
}
