import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SecurityContext,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import tippy, { Props, followCursor } from 'tippy.js';

export const tippyDefaultProps: Partial<Props> = {
  allowHTML: true,
  appendTo: document.body,
  arrow: true,
  hideOnClick: false,
  maxWidth: 300,
  offset: [0, 6],
  theme: 'light-border',
  trigger: 'mouseenter focus',
  onUntrigger(instance: any) {
    // weird random/rare bug where tooltip will be hidden with mouseleave, even though trigger is manual only
    if (instance.props.trigger === 'manual') {
      setTimeout(() => {
        instance.show();
      });
    }
  },
  plugins: [followCursor],
};

@Directive({
  standalone: false,
  selector: '[omTooltip]',
})
export class TooltipDirective implements OnDestroy, OnChanges {
  domElement: any;

  @Input('omTooltip')
  set content(content: HTMLElement | string) {
    if (typeof content === 'string') {
      this._content = this.domSanitizer.sanitize(SecurityContext.HTML, content) ?? '';
    } else {
      this._content = content;
    }
  }
  get content(): HTMLElement | string {
    return this._content;
  }
  private _content: HTMLElement | string;

  @Input() set omTooltipProperties(tooltipProps: Partial<Props>) {
    this.tooltipProps = { ...tippyDefaultProps, ...tooltipProps };
  }

  @Input() set omTooltipDisabled(disabled: boolean) {
    this.disabled = coerceBooleanProperty(disabled);
    if (this.disabled) {
      this.tooltip?.hide();
      this.tooltip?.disable();
    } else {
      this.tooltip?.enable();
    }
  }
  private disabled: boolean;

  @Input() set omTooltipClickToStick(isClickToStick: boolean) {
    this.isClickToStick = coerceBooleanProperty(isClickToStick);
  }
  private isClickToStick: boolean;
  private isSticky: boolean;

  @Input() omTooltipFill: boolean;

  @Input() set omTooltipOnlyShowWhenEllipsis(isTooltipOnlyShowWhenEllipsis: boolean) {
    this.isTooltipOnlyShowWhenEllipsis = coerceBooleanProperty(isTooltipOnlyShowWhenEllipsis);
  }
  private isTooltipOnlyShowWhenEllipsis: boolean;

  @Input() set omTooltipInteractive(isInteractive: boolean) {
    this.isInteractive = coerceBooleanProperty(isInteractive);
  }
  private isInteractive: boolean;

  @Input() set omTooltipNavigation(isNavigationTooltip: boolean) {
    this.isNavigationTooltip = coerceBooleanProperty(isNavigationTooltip);
  }
  private isNavigationTooltip: boolean;

  @Input() omTooltipHeader: string;

  @Input() set omTooltipArrayMax(arrayMaxLength: number) {
    if (Array.isArray(this.content) && this.content.length > arrayMaxLength) {
      this.content = this.content.join(', ');
      return;
    }
    this.disabled = true;
  }

  private tooltip: any;
  private tooltipProps: any;
  private tooltipContainer: HTMLElement;
  private observer: MutationObserver;

  disable() {
    this.tooltip?.disable();
  }
  enable() {
    this.tooltip?.enable();
  }

  constructor(
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    if (elementRef.nativeElement.parentNode) {
      const config = { attributes: true, childList: true, subtree: true };
      const callback = (mutationList: MutationRecord[], observer: any) => {
        const filteredList = mutationList.filter(
          (mutation: MutationRecord) => mutation.type === 'attributes' && mutation.attributeName === 'style',
        );
        if (filteredList.length) {
          const mutation = filteredList[filteredList.length - 1]; // only care about the 'final' state in a given set of mutation records. no need to iterate
          const offset = (mutation.target as any)?.offsetParent;
          if (offset === null) {
            this.disable();
          } else {
            this.enable();
          }
        }
      };
      this.observer = new MutationObserver(callback);
      this.observer.observe(elementRef.nativeElement.parentNode, config);
    }
    this.tooltipProps = tippyDefaultProps;
  }

  private createTooltip(properties: Partial<Props>) {
    this.tooltip = tippy(this.elementRef.nativeElement, properties);
    if (this.disabled) {
      this.tooltip.disable();
    }
  }

  @HostListener('click') click() {
    if (!this.isClickToStick) {
      return;
    }
    this.isSticky ? this.close() : this.open();
  }

  open() {
    if (this.isSticky) {
      return;
    }
    this.isSticky = true;
    this.tooltip.setProps({
      trigger: 'manual',
      interactive: true,
    });
    if (this.tooltipContainer) {
      this.renderer.addClass(this.tooltipContainer, 'om-tooltip-sticky');
    }
    this.tooltip.show();
  }

  private isEllipsis() {
    return this.elementRef.nativeElement.offsetWidth < this.elementRef.nativeElement.scrollWidth;
  }

  close() {
    if (!this.isSticky) {
      return;
    }
    this.isSticky = false;
    this.tooltip.setProps({
      trigger: this.tooltipProps.trigger,
      interactive: this.isInteractive,
    });
    if (this.tooltipContainer) {
      this.renderer.removeClass(this.tooltipContainer, 'om-tooltip-sticky');
    }
    this.tooltip.hide();
  }

  ngOnDestroy() {
    this.observer?.disconnect();
    setTimeout(() => {
      this.tooltip?.destroy();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.content) {
      if (this.tooltip) {
        this.tooltip.destroy();
      }

      if (!this.content || (this.isTooltipOnlyShowWhenEllipsis && !this.isEllipsis())) {
        return;
      }
      if (this.isNavigationTooltip) {
        this.createTooltip({
          ...this.tooltipProps,
          content: this.content,
          interactive: true,
          placement: 'right-start',
          theme: 'navigation',
        });
        return;
      }

      if (!(this.isClickToStick || this.isInteractive || this.omTooltipHeader)) {
        this.createTooltip({
          ...this.tooltipProps,
          content: this.content,
        });
        return;
      }

      const tooltipContainer = this.renderer.createElement('div');
      this.renderer.addClass(tooltipContainer, 'om-tooltip-container');

      if (coerceBooleanProperty(this.omTooltipFill)) {
        this.renderer.addClass(tooltipContainer, 'om-tooltip-fill');
      }

      if (this.omTooltipHeader) {
        const toolTipHeader = this.renderer.createElement('div');
        this.renderer.addClass(toolTipHeader, 'om-tooltip-header');
        toolTipHeader.innerHTML = this.omTooltipHeader;
        this.renderer.appendChild(tooltipContainer, toolTipHeader);
      }

      const tooltipContent = this.renderer.createElement('div');
      this.renderer.addClass(tooltipContent, 'om-tooltip-content');
      if (this.content instanceof HTMLElement) {
        this.renderer.appendChild(tooltipContent, this.content);
      } else {
        tooltipContent.innerHTML = this.content;
      }

      this.renderer.appendChild(tooltipContainer, tooltipContent);

      const closeButton = this.renderer.createElement('button');
      closeButton.onclick = () => {
        this.close();
      };
      this.renderer.addClass(closeButton, 'om-tooltip-close-button');
      this.renderer.appendChild(tooltipContainer, closeButton);

      this.tooltipContainer = tooltipContainer;
      this.createTooltip({
        ...this.tooltipProps,
        content: this.tooltipContainer,
        ...(this.isInteractive
          ? {
              interactive: true,
              maxWidth: 600,
            }
          : null),
      });
    }
  }
}
