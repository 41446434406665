export const ColorThemeDefault: Record<string, string> = {
  'text-primary': '32, 54, 75',
  'text-secondary': '71, 94, 114',
  label: '164, 170, 177',
  'accent-soft': '58, 130, 188',
  accent: '67, 190, 156',
  alert: '233, 155, 0',
  warning: '228, 91, 91',
};

export const ChartColorsDefault: Record<string, string> = {
  'color-chart-line-0': '233, 155, 0',
  'color-chart-line-1': '83, 112, 198',
  'color-chart-line-2': '145, 204, 117',
  'color-chart-line-3': '250, 198, 87',
  'color-chart-line-4': '238, 102, 102',
  'color-chart-line-5': '115, 192, 222',
  'color-chart-line-6': '59, 162, 114',
  'color-chart-line-7': '252, 132, 82',
  'color-chart-line-8': '154, 96, 180',
  'color-chart-line-9': '234, 124, 204',
  'color-chart-line-disabled': '126, 126, 126',
};
