<div class="om-ag-grid-sidebar-wrapper">
  <mat-toolbar> Group By </mat-toolbar>
  <section>
    <h3>Select</h3>
    <p>
      Grouping affects how rows are arranged in the Secondary Bonds Grid. Each group of bonds will have a corresponding
      interpolated curve calculated and shown in the New Issue Pricing table above.
    </p>
    <mat-radio-group [(ngModel)]="value" (ngModelChange)="onValueChange()">
      @for (option of options; track option) {
        <mat-radio-button [value]="option.value">
          {{ option.label }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </section>
</div>
