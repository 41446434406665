<mat-expansion-panel (closed)="closePanel()" (opened)="openPanel()">
  <mat-expansion-panel-header>
    <mat-panel-title
      ><span> {{ columnConfig?.label ?? 'Size' }} </span>
      @if (chipDisplay && !isPanelOpen) {
        <mat-chip-listbox>
          <mat-chip>
            {{ chipDisplay }}
            <span><mat-icon svgIcon="remove" matChipRemove (click)="reset()"></mat-icon></span>
          </mat-chip>
        </mat-chip-listbox>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>
  <section class="om-size-filter">
    <mat-form-field>
      <mat-select [(ngModel)]="sizeOperator" (selectionChange)="selectSizeOperator()">
        @for (option of operatorOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <section>
      <div>
        <mat-form-field>
          <om-primitive-input
            type="number"
            placeholder="Enter Value"
            [(ngModel)]="sizeLower"
            (ngModelChange)="updateSize()"
          ></om-primitive-input>
        </mat-form-field>
        <mat-form-field>
          <mat-select [(ngModel)]="sizeCurrency" (selectionChange)="selectSizeCurrency()">
            @for (option of columnConfig.options; track option) {
              <mat-option [value]="option.value">
                {{ option.label }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (sizeOperator === '-') {
        <div>
          <mat-form-field>
            <om-primitive-input
              type="number"
              placeholder="Enter Value"
              [(ngModel)]="sizeUpper"
              (ngModelChange)="updateSizeUpper($event)"
            ></om-primitive-input>
          </mat-form-field>
          <mat-form-field>
            <mat-select [(ngModel)]="sizeCurrency" (selectionChange)="selectSizeCurrency()">
              @for (option of columnConfig.options; track option) {
                <mat-option [value]="option.value">
                  {{ option.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      }
    </section>
  </section>
</mat-expansion-panel>
