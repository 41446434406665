import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { KeyCode } from '@morpho/core';
import tippy from 'tippy.js';

@Component({
  standalone: false,
  selector: 'om-popover',
  templateUrl: './popover.component.html',
})
export class PopoverComponent implements AfterViewInit, OnDestroy {
  static nextId = 0;
  id = `om-popover-${PopoverComponent.nextId++}`;

  @ViewChild('popoverContent') popoverContent: ElementRef;
  @Input() anchor: HTMLElement;
  @Input()
  set keepOpenOnAction(value: boolean) {
    this._keepOpenOnAction = coerceBooleanProperty(value);
  }
  private _keepOpenOnAction: boolean;

  @Output() opened = new EventEmitter<void>();

  private popover: any;
  private isShown = false;

  constructor() {}

  private addListeners() {
    window.addEventListener('click', this.onClick);
    window.addEventListener('keydown', this.onKeydown);
  }
  private removeListeners() {
    window.removeEventListener('click', this.onClick);
    window.removeEventListener('keydown', this.onKeydown);
  }

  private onClick = (event: any) => {
    if (document.getElementById(this.id)?.contains(event.target)) {
      if (!(event.metaKey || event.ctrlKey) && !this._keepOpenOnAction) {
        this.close();
      }
    } else {
      if (this.isShown) {
        this.close();
      }
    }
  };

  private onKeydown = (event: KeyboardEvent) => {
    if (event.key === KeyCode.Escape) {
      this.close();
    }
  };

  ngAfterViewInit(): void {
    this.popover = tippy(this.anchor, {
      allowHTML: true,
      appendTo: document.body,
      arrow: true,
      hideOnClick: false,
      maxWidth: 600,
      offset: [0, 6],
      trigger: 'click',
      content: this.popoverContent.nativeElement,
      interactive: true,
      placement: 'bottom',
      theme: 'light-border popover',
      onShown: instance => {
        this.isShown = true;
        this.opened.emit();
        this.addListeners();
      },
      onHide: instance => {
        this.isShown = false;
        this.removeListeners();
      },
    });
  }

  open() {
    this.popover?.show();
  }

  close() {
    this.popover?.hide();
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.popover?.destroy();
      this.removeListeners();
    });
  }
}
