import { Injectable } from '@angular/core';
import { TypedStateAction, toggleSidebarAnimationTransitionDuration } from '@morpho/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map } from 'rxjs/operators';
import { DisplayActions, DisplayEffect, ToggleSidebarParams } from './display.actions';

@Injectable()
export class DisplayEffects {
  toggleSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayEffect.TOGGLE_SIDEBAR),
      delay(toggleSidebarAnimationTransitionDuration),
      map((action: TypedStateAction<ToggleSidebarParams>) => DisplayActions.toggleMainSidebarComplete(action.params)),
    ),
  );

  constructor(private actions$: Actions) {}
}
