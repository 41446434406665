import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes, UrlSegment, UrlSegmentGroup } from '@angular/router';

const routes: Routes = [
  {
    matcher: (url: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
      const authPages = ['login', 'change-password', 'status'];
      return group.segments.length && authPages.includes(group.segments[0].path) ? { consumed: [] } : null;
    },
    loadChildren: () => import('./projects/auth/auth.module').then(m => m.AuthModule),
  },
  {
    matcher: () => {
      return { consumed: [] };
    },
    loadChildren: () => import('./projects/bankangle/bankangle.module').then(m => m.BankangleModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
