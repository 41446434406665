import { Injectable } from '@angular/core';
import { LevelsUtilService, TypedStateAction } from '@morpho/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { PricingService } from 'apps/bankangle/src/app/core/patterns/pricing/pricing.service';
import { CoreApiService } from 'apps/bankangle/src/app/core/services/core-api.service';
import { StateService } from 'apps/bankangle/src/app/core/services/state.service';
import { Curve, CurveLevel, CurveType, CurvesRequestOptions } from 'apps/bankangle/src/app/models/curve.model';
import { forkJoin } from 'rxjs';
import { debounceTime, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { CurveAnalysisService } from '../../../features/curve-analysis/services/curve-analysis.service';
import { RegressionService } from '../../../features/curve-analysis/services/regression.service';
import { NewIssueCurveType } from '../../../features/syndicate-pricer/models/pricing-request.model';
import { Constants } from '../../../models/constants';
import {
  DEFAULT_REGRESSION_CONFIG,
  RegressionConfig,
  RegressionMode,
  RegressionObject,
} from '../../../models/regression.model';
import { PricingInfo } from '../../patterns/pricing/pricing.model';
import { RatingsService } from '../../patterns/ratings/ratings.service';
import { CurveAnalysisAction, CurveAnalysisEffect } from './curve-analysis.actions';
import { CurveAnalysisLoadingStage, CurveAnalysisState } from './curve-analysis.model';
import { CurveAnalysisSelector } from './curve-analysis.selectors';

@Injectable()
export class CurveAnalysisEffects {
  private updateLoadingState = (stage: CurveAnalysisLoadingStage, isLoading: boolean) =>
    this.store.dispatch(
      CurveAnalysisAction.updateLoadingState({
        params: { stage, isLoading },
      }),
    );

  getCurves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurveAnalysisEffect.GET_CURVES),
      tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Get, true)),
      mergeMap((action: TypedStateAction<CurvesRequestOptions>) => {
        return this.coreApiService.getCurves(action?.params).pipe(
          tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Get, false)),
          tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Rate, true)),
          map(response => response.curves),
          mergeMap(curves => this.ratingsService.addRatingsToArray(curves)),
          tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Rate, false)),
          switchMap(curves => [
            CurveAnalysisAction.setRawCurves({ params: { curves } }),
            CurveAnalysisAction.priceCurves(),
          ]),
        );
      }),
    ),
  );

  priceLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurveAnalysisEffect.PRICE_CURVES),
      tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Price, true)),
      concatLatestFrom(() => [
        this.store.select(CurveAnalysisSelector.rawCurves),
        this.store.select(CurveAnalysisSelector.pricingInfoList),
        this.store.select(CurveAnalysisSelector.regression),
      ]),
      switchMap(
        ([, curves, pricingInfoList, regression]: [any, Curve[], PricingInfo[], RegressionConfig | undefined]) => {
          const cleanedCurvesForPricing = curves.map(curve => {
            if (!curve.identifier.includes(CurveType.PRICING)) {
              return curve;
            }
            const cleanedLevels = Object.entries(curve.levels).reduce(
              (curveLevels: Record<string, CurveLevel>, [tenor, level]) => {
                let cleanedLevel: CurveLevel = JSON.parse(JSON.stringify(level));
                [NewIssueCurveType.NewIssuePremium, NewIssueCurveType.Spread, NewIssueCurveType.Total].forEach(
                  curveType => {
                    const value = cleanedLevel[curveType as keyof CurveLevel];
                    if (typeof value === 'string' && value.includes('a')) {
                      cleanedLevel = {
                        ...cleanedLevel,
                        [curveType]: value.replaceAll('a', ''),
                      };
                    }
                  },
                );
                curveLevels[tenor] = cleanedLevel;
                return curveLevels;
              },
              {},
            );
            return {
              ...curve,
              levels: cleanedLevels,
            };
          });
          return this.pricingService.price2(cleanedCurvesForPricing, pricingInfoList).pipe(
            tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Price, false)),
            map(pricedCurves => pricedCurves.curves),
            switchMap(curves => [
              CurveAnalysisAction.setPricedCurves({ params: { curves } }),
              CurveAnalysisAction.computeCurveRegressions({ params: regression ?? DEFAULT_REGRESSION_CONFIG }),
              CurveAnalysisAction.setInitialCurvesToDisplay(),
            ]),
          );
        },
      ),
    ),
  );

  computeRegression$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurveAnalysisEffect.COMPUTE_CURVE_REGRESSIONS),
      tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.Regression, true)),
      debounceTime(250),
      mergeMap((action: any) =>
        forkJoin([
          this.store.select(CurveAnalysisSelector.pricedCurves).pipe(
            filter(curves => !!curves),
            take(1),
          ),
          this.store.select(CurveAnalysisSelector.selectedBonds).pipe(
            filter(bonds => !!bonds),
            take(1),
          ),
        ]).pipe(
          switchMap(([curves, selectedBonds]: [Curve[], Record<string, boolean>]) => {
            const curveRegressions: Record<string, RegressionObject[]> = curves.reduce(
              (acc, curve) => {
                const regressionConfig: RegressionConfig =
                  curve.curve_type === CurveType.SECONDARY ? action.params : { mode: RegressionMode.Interpolated };
                acc[curve.identifier] = this.regressionService
                  .generateCurveDataset(curve, selectedBonds)
                  .map(data => this.regressionService.regression(data, regressionConfig));
                return acc;
              },
              {} as Record<string, RegressionObject[]>,
            );
            this.updateLoadingState(CurveAnalysisLoadingStage.Regression, false);
            return [CurveAnalysisAction.setCurveRegressions({ params: { config: action.params, curveRegressions } })];
          }),
        ),
      ),
    ),
  );

  setInitialCurves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CurveAnalysisEffect.SET_INITIAL_CURVES_TO_DISPLAY),
      tap(() => this.updateLoadingState(CurveAnalysisLoadingStage.InitialSelection, true)),
      concatLatestFrom(() => [
        this.store.select(CurveAnalysisSelector.pricedCurves).pipe(filter(curves => !!curves)),
        this.store.select(CurveAnalysisSelector.selectedCurves),
        this.store.select(CurveAnalysisSelector.selectedIssuerIds),
        this.store.select(CurveAnalysisSelector.previouslySelectedIssuerIds),
        this.stateService.get.constants$,
      ]),
      switchMap(
        ([, curves, selectedCurvesIds, selectedIssuerIds, previousSelectedIssuerIds, constants]: [
          any,
          Curve[],
          string[] | undefined,
          number[],
          number[] | undefined,
          Constants,
        ]) => {
          const newIssuersIds: number[] = previousSelectedIssuerIds?.length
            ? selectedIssuerIds.filter(issuer => !previousSelectedIssuerIds.includes(issuer))
            : selectedIssuerIds;

          const newCurveSelection = this.curveAnalysisService.getCurveSelection(
            curves ?? [],
            selectedCurvesIds ?? [],
            new Set<number>(newIssuersIds),
            constants,
          );
          this.updateLoadingState(CurveAnalysisLoadingStage.InitialSelection, false);
          return [CurveAnalysisAction.setCurvesToDisplay({ params: { curveIds: newCurveSelection } })];
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private coreApiService: CoreApiService,
    private curveAnalysisService: CurveAnalysisService,
    private pricingService: PricingService,
    private ratingsService: RatingsService,
    private regressionService: RegressionService,
    private store: Store<CurveAnalysisState>,
    private stateService: StateService,
    private levelsUtilService: LevelsUtilService,
  ) {}
}
