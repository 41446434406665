import { Injectable } from '@angular/core';
import { environment } from '@morpho/environment';
import { RoutingService } from '../../patterns/routing/routing.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  readonly legacyUrlParam = '?bankangle';

  constructor(private routingService: RoutingService) {}

  convertBankangleUrlToRoute(url: string): string {
    const morphoPath = this.getMorphoPath(url);
    return morphoPath || `/l${url.replace(window.location.origin, '')}${url.includes('?') ? '' : this.legacyUrlParam}`;
  }

  redirect(path: string): boolean {
    const morphoPath = this.getMorphoPath(path);
    if (morphoPath) {
      setTimeout(() => {
        this.routingService.routeTo(morphoPath);
      });
      return true;
    }
    return false;
  }

  private getMorphoPath(path: string): string {
    if (environment.appBaseHref !== '/' && path.startsWith(environment.appBaseHref)) {
      path = path.slice(environment.appBaseHref.length);
    }

    if (path.startsWith('/admin') || path.startsWith('/api') || path.startsWith('/change-password')) {
      return '';
    }

    if (path.includes('/view-levels')) {
      return path;
    }

    if (path.includes('/issuer-profile/')) {
      const issuerId: string = this.getPathSection(path.substring(path.indexOf('#/') + 2)) ?? '';
      return `/issuers/${issuerId}`;
    }

    if (path.includes('/dealer-profile/')) {
      const dealerId: string = this.getPathSection(path.substring(path.indexOf('#/') + 2)) ?? '';
      const termsheetClauseSections = ['disclaimers', 'provisions', 'languages'];
      let url = `/dealers/${dealerId}`;
      if (termsheetClauseSections.some((section: string) => path.includes(section))) {
        url += '/termsheet-clauses';
      }
      if (path.includes('contacts')) {
        url += '/contacts';
      }
      return url;
    }

    if (path.includes('/issuers/')) {
      return '/issuers';
    }

    if (path === '/users/' || path.includes('/user-list') || path.includes('/support_dashboard/')) {
      return '/user-list';
    }

    if (path.includes('/bank-list')) {
      return '/dealers';
    }

    if (path.includes('bullet-grid')) {
      return '/levels/bullets';
    }

    if (path.includes('callable-grid')) {
      return '/levels/callables';
    }

    if (path.includes('level_input/senior_unsecured/')) {
      const issuerId = path.split('/')[3];
      return `/levels/input/bullets/${issuerId}`;
    }

    if (path.includes('dealer-dashboard') || path.includes('issuer-dashboard') || path.includes('market-insights')) {
      return `/`;
    }

    if (path.includes('/levels/input/bullets')) {
      return '/levels/input/bullets';
    }

    if (path.includes('callables/levelinput')) {
      return '/levels/input/callables';
    }

    if (path.includes('/custom-issuer-curves')) {
      return '/levels/custom/bullets';
    }

    if (path.includes('trades/bullet_blotter') || path.includes('trades/trades_blotter')) {
      return '/trades';
    }

    if (path.includes('/trades/detail/')) {
      const parts = path.split('/');
      return `/trades/${parts[parts.length - 2]}`;
    }

    if (path.includes('trade_deals/trade-deal')) {
      return '/staff/trade-announcements';
    }

    // todo: check if other routes need to be changed for version history
    if (path.includes('trades/termsheet-workflow-2/')) {
      const tradeId = this.getPathSection(path.substring(path.indexOf('-2') + 3));
      if (path.includes('history')) {
        const startingString = path.substring(path.indexOf('history') + 8);
        const stage = this.getHistoryStageFromPath(startingString);
        const docId = this.getHistoryDocIdFromPath(startingString);
        const versionId = this.getHistoryVersionIdFromPath(startingString);
        return `/trades/${tradeId}/version-history/${docId}/${stage}/${versionId}`;
      }

      if (path.includes('documents')) {
        const startingString = path.substring(path.indexOf('documents') + 10);
        const docId = this.getPathSection(startingString);

        if (docId) {
          return `/trades/${tradeId}/document-generator/documents/${docId}/`;
        }
        return `/trades/${tradeId}/document-generator/documents/`;
      }

      return `/trades/${tradeId}/document-generator/`;
    }

    if (path.includes('/api/keys/')) {
      return '/staff/api-keys';
    }

    if (path.includes('/api/requests/')) {
      return '/staff/api-usage';
    }

    if (path.includes('/curve-analysis')) {
      return path;
    }

    if (path.includes('/dealers')) {
      return path;
    }

    if (path.includes('/settings/email')) {
      return '/email-settings';
    }

    return '/';
  }

  private getHistoryStageFromPath(subPath: string) {
    return subPath.substring(0, subPath.indexOf('/'));
  }

  private getHistoryDocIdFromPath(subPath: string) {
    const stringStart = this.moveToNextSubpath(subPath, 2);
    return this.getPathSection(stringStart);
  }

  private getHistoryVersionIdFromPath(subPath: string) {
    const stringStart = this.moveToNextSubpath(subPath, 1);
    return this.getPathSection(stringStart);
  }

  private moveToNextSubpath(path: string, num: number) {
    for (let index = 0; index < num; index++) {
      const indexOfnextSection = path.indexOf('/');
      if (indexOfnextSection === -1) {
        return null;
      }
      path = path.substring(path.indexOf('/') + 1);
    }
    return path;
  }

  private getPathSection(subPath: string | null) {
    return subPath ? subPath.substring(0, subPath.indexOf('/')) : null;
  }
}
