import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@morpho/core';
import * as moment from 'moment-timezone';
import { AgGridSidebarComponentBase } from '../../ag-grid-panels-base';
import { DateFilterConfig } from '../../models/ag-grid-panel.model';

@Component({
  standalone: false,
  selector: 'om-select-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateFilterComponent extends AgGridSidebarComponentBase {
  @Input() columnConfig: DateFilterConfig;
  @Input() model: Record<string, any>;
  @Output() sendfilters = new EventEmitter<{ id: string; label: string; filters: any }>();

  selectedOption: string;

  dateOptions = [
    { value: '<', label: 'Before' },
    { value: '>', label: 'After' },
    { value: 'range-between', label: 'Range Between' },
  ];

  dateOperator = '<';
  minDate: string;
  maxDate: string;
  chipDisplay: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.model) {
      return;
    }
    if (changes.model) {
      const value = this.model[this.columnConfig.id] ?? this.columnConfig.default;
      if (value) {
        const currentValueArray = value.split(' ');
        if (currentValueArray.includes('-')) {
          this.minDate = currentValueArray[0];
          this.dateOperator = 'range-between';
          this.maxDate = currentValueArray[2];
        } else {
          this.dateOperator = currentValueArray[0];
          this.minDate = currentValueArray[1];
        }
        this.updateDate();
      }
    }
  }

  selectDateOption() {
    if (this.minDate !== 'range-between') {
      this.maxDate = '';
    }
    this.updateDate();
  }

  onDateCalenderChanged(date: string) {
    this.minDate = date;
    this.updateDate();
  }

  onDateMaxCalenderChanged(date: string) {
    this.maxDate = date;
    this.updateDate();
  }

  updateDate() {
    let option = '';
    if (this.maxDate) {
      const [firstDateMoment, secondDateMoment] = [moment(this.minDate), moment(this.maxDate)];
      const orderedDates = firstDateMoment.isBefore(secondDateMoment)
        ? [firstDateMoment, secondDateMoment]
        : [secondDateMoment, firstDateMoment];

      const [minDateValue, maxDateValue] = orderedDates.map(date => date.format(DATE_FORMAT));
      const [minBEDateValue, maxBEDateValue] = orderedDates.map(date => date.format(BACKEND_DATE_FORMAT));

      if (minBEDateValue && maxBEDateValue) {
        option = `${minBEDateValue} - ${maxBEDateValue}`;
        this.chipDisplay = `${minDateValue} - ${maxDateValue}`;
      }
    } else {
      const type = this.dateOperator;
      const dateFormat = moment(this.minDate).format(DATE_FORMAT);
      const dateBEFormat = moment(this.minDate).format(BACKEND_DATE_FORMAT);
      if (type && this.minDate) {
        this.chipDisplay = `${type} ${dateFormat}`;
        option = `${type} ${dateBEFormat}`;
      }
    }
    this.selectedOption = option;
    this.sendFilterDate();
  }

  reset() {
    this.selectedOption = '';
    this.chipDisplay = '';
    this.minDate = '';
    this.maxDate = '';
    this.dateOperator = this.columnConfig.default?.split(' ')[0] ?? '<';
    this.sendFilterDate();
  }

  sendFilterDate() {
    this.sendfilters.emit({
      id: this.columnConfig.id,
      label: this.columnConfig.label ?? '',
      filters: this.selectedOption,
    });
  }
}
