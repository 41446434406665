import { Component, HostListener, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { KeyCode, TrackByService } from '@morpho/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CarouselModalOverlayRef } from './carousel-modal-ref';
import { CAROUSEL_MODAL_DATA, CAROUSEL_MODAL_SLIDE_COMPONENT } from './carousel-modal.tokens';

@Component({
  standalone: false,
  selector: 'om-carousel-modal',
  templateUrl: './carousel-modal.component.html',
  styleUrls: ['./carousel-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselModalComponent implements OnDestroy {
  slides: any[];
  currentIndex: number;
  index = 0;

  private ngOnDestroy$ = new Subject<void>();

  @HostListener('document:keydown', ['$event']) handleKeydown(event: KeyboardEvent) {
    if (event.key === KeyCode.Escape) {
      this.dialogRef.close();
    }
  }
  constructor(
    public dialogRef: CarouselModalOverlayRef,
    @Inject(CAROUSEL_MODAL_DATA) public slideData$: Observable<any[]>,
    @Inject(CAROUSEL_MODAL_SLIDE_COMPONENT) public slideComponent: any,
    public trackBy: TrackByService,
  ) {
    this.setCurrentIndex(0);

    this.slideData$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((slideData: any[]) => {
      this.slides = slideData;
    });
  }

  moveLeft(): void {
    this.setCurrentIndex(this.currentIndex > 0 ? --this.currentIndex : this.slides.length - 1);
  }

  moveRight(): void {
    this.setCurrentIndex(this.currentIndex < this.slides.length - 1 ? ++this.currentIndex : 0);
  }

  setCurrentIndex(index: number): void {
    this.currentIndex = index;
  }

  closeSlide(slides: any[], idx: number) {
    if (slides.length === 1) {
      this.dialogRef.close();
      return;
    }
    this.removeSlide(idx);
    if (this.currentIndex === this.slides.length) {
      this.currentIndex--;
    }
  }

  private removeSlide(index: number): void {
    this.slides.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
