import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkMonitoringInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('.svg')) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      tap((request: any) => {
        const increment = request.status ? -1 : 1;
        this.apiService.notifyOfRequest(increment);
      }),
      catchError(error => {
        this.apiService.notifyOfRequest(-1);
        return throwError(error);
      }),
    );
  }
}
