import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GENERIC_AUTOCOMPLETE_PLACEHOLDER, ValueAndLabel } from '@morpho/core';
import { IssuerApiService } from 'apps/bankangle/src/app/core/patterns/issuer/issuer-api.service';
import { Observable, Subject, combineLatest, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { ComparableData, ComparablesInformation } from '../../../models/pricing-request.model';
import { SyndicatePricerApiService } from '../../../services/syndicate-pricer-api.service';
import { SyndicatePricerUtilService } from '../../../services/syndicate-pricer-util.service';

export type ComparablesManagerDialogEvent = ComparablesInformation | undefined;

@Component({
  standalone: false,
  selector: 'om-comparables-manager-dialog',
  templateUrl: './comparables-manager-dialog.component.html',
  styleUrls: ['./comparables-manager-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ComparablesManagerDialogComponent implements OnInit, OnDestroy {
  private readonly ngOnDestroy$ = new Subject<void>();

  readonly form: FormGroup = this.formBuilder.group({
    issuers: { value: null, disabled: false },
    curves: { value: null, disabled: false },
    isins: { value: null, disabled: false },
  });

  issuersOptions$: Observable<ValueAndLabel[]>;
  curveOptions$: Observable<ValueAndLabel[]>;
  isinOptions$: Observable<ValueAndLabel[]>;
  isInitialized$: Observable<boolean>;

  readonly placeholder = GENERIC_AUTOCOMPLETE_PLACEHOLDER;

  private data: ComparablesManagerDialogEvent;
  private excludedIsinsMap: Record<string, string[]> = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ComparablesManagerDialogEvent,
    private issuerApiService: IssuerApiService,
    public dialogRef: MatDialogRef<ComparablesManagerDialogComponent>,
    private formBuilder: FormBuilder,
    private syndicatePricerApiService: SyndicatePricerApiService,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }

    if (this.data.comparable_isins?.length) {
      this.form.get('isins')?.setValue([...this.data.comparable_isins].sort());
    }

    if (this.data.comparable_criteria?.length) {
      this.issuerApiService.issuingEntityFlattenedOptions$
        .pipe(
          tap(entityOptions => {
            const issuers = new Set();
            const issuingEntities = new Set();
            const curves = new Set();

            this.data?.comparable_criteria?.forEach((criteria: any) => {
              if (!criteria.seniority) {
                return;
              }
              issuingEntities.add(criteria.issuing_entity_id);
              const id = this.syndicatePricerUtilService.generateComparableCurveId(criteria);
              curves.add(id);
              this.excludedIsinsMap[id] = criteria.excluded_isins;
            });

            entityOptions.forEach(option => {
              if (issuingEntities.has(option.value)) {
                issuers.add((option as any).issuer_id);
              }
            });

            this.form.get('issuers')?.setValue([...issuers].sort());
            this.form.get('curves')?.setValue([...curves].sort());
          }),
          tap(() => {
            this.setOptions();
          }),
        )
        .subscribe();
    } else {
      this.setOptions();
    }
  }

  private setOptions() {
    const issuersFormControl = this.form.get('issuers');
    if (!issuersFormControl) {
      return;
    }

    this.issuersOptions$ = this.issuerApiService.issuerFlattenedOptions$;

    this.curveOptions$ = issuersFormControl.valueChanges.pipe(
      startWith(issuersFormControl.value),
      switchMap(issuerIds => {
        return this.syndicatePricerApiService.getIssuersComparableCurveOptions(issuerIds);
      }),
      shareReplay(),
    );

    this.isinOptions$ = this.syndicatePricerApiService.getBondOptions();

    this.isInitialized$ = combineLatest([this.issuersOptions$, this.curveOptions$, this.isinOptions$]).pipe(
      filter(([issuersOptions, curveOptions, isinOptions]) => {
        return !!(issuersOptions && (curveOptions || !this.form.get('curves')?.value.length) && isinOptions);
      }),
      map(() => true),
      shareReplay(),
    );
  }

  onSave(): void {
    const criteriaMap: Record<string, ComparableData> = {};
    if (this.form.value.issuers?.length) {
      this.form.value.curves?.forEach((id: string) => {
        criteriaMap[id] = {
          ...this.syndicatePricerUtilService.getDataFromComparableCurveId(id),
          excluded_isins: this.excludedIsinsMap[id] ?? [],
        };
      });
    }
    const event: ComparablesManagerDialogEvent = {
      comparable_criteria: Object.values(criteriaMap),
      comparable_isins: this.form.value.isins ?? [],
    };

    this.dialogRef.close(event);
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
