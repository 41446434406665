import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarParams } from './snack-bar.model';

@Component({
  standalone: false,
  selector: 'om-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SnackBarComponent {
  data: SnackBarParams;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: SnackBarParams) {
    this.data = data;
  }
}
