import { NgModule } from '@angular/core';
import { CoreModule } from '@morpho/core';
import { DynamicFormModule } from '@morpho/dynamic-form';
import { FormModule } from '@morpho/form';
import { ColumnSidebarPanelComponent } from './components/column-sidebar-panel/column-sidebar-panel.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { FilterSidebarPanelComponent } from './components/filter-sidebar-panel/filter-sidebar-panel.component';
import { GroupSidebarPanelComponent } from './components/group-sidebar-panel/group-sidebar-panel.component';
import { SelectMultipleFilterComponent } from './components/select-multiple-filter/select-multiple-filter.component';
import { SelectSingleFilterComponent } from './components/select-single-filter/select-single-filter.component';
import { SizeAndCurrencyFilterComponent } from './components/size-and-currency-filter/size-and-currency-filter.component';

@NgModule({
  declarations: [
    ColumnSidebarPanelComponent,
    DateFilterComponent,
    FilterSidebarPanelComponent,
    GroupSidebarPanelComponent,
    SelectMultipleFilterComponent,
    SelectSingleFilterComponent,
    SizeAndCurrencyFilterComponent,
  ],
  exports: [ColumnSidebarPanelComponent, FilterSidebarPanelComponent, GroupSidebarPanelComponent],
  imports: [CoreModule, FormModule, DynamicFormModule],
})
export class AgGridPanelModule {}
