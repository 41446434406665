import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import * as intlTelInput from 'intl-tel-input';

@Directive({
  standalone: false,
  selector: '[omPhoneInput]',
  exportAs: 'omPhoneInput',
})
export class PhoneInputDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('omPhoneInput') phoneInputConfig: intlTelInput.SomeOptions;

  iti: intlTelInput.Iti;

  private overlayRef: OverlayRef;
  private dropdownElement: HTMLElement;
  private selectCountryButton: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
  ) {}

  ngOnInit(): void {
    const phoneInput = this.elementRef.nativeElement;

    this.iti = intlTelInput.default(phoneInput, {
      ...this.phoneInputConfig,
    });

    this.dropdownElement = phoneInput.parentElement.querySelector('.iti__dropdown-content') as HTMLElement;
    this.selectCountryButton = phoneInput.parentElement.querySelector('.iti__selected-country') as HTMLElement;

    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    this.selectCountryButton.addEventListener('click', () => this.toggleDropdown());
  }

  ngAfterViewInit(): void {
    this.setDropdownPosition();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  private setDropdownPosition() {
    const { width, bottom, left } = this.elementRef.nativeElement
      .closest('.mat-mdc-form-field-infix')
      .getBoundingClientRect();

    this.dropdownElement.style.width = `${width - 4}px`;
    this.dropdownElement.style.top = `${bottom - 4}px`;
    this.dropdownElement.style.left = `${left + 2}px`;
  }

  private toggleDropdown() {
    if (this.dropdownElement.style.display === 'none' || this.dropdownElement.style.display === '') {
      this.openDropdown();
    } else {
      this.closeDropdown();
    }
  }

  private openDropdown() {
    this.setDropdownPosition();
    if (this.dropdownElement && !this.overlayRef.hasAttached()) {
      this.overlayRef.overlayElement.appendChild(this.dropdownElement);
    }
  }

  private closeDropdown() {
    if (this.dropdownElement && this.overlayRef.hasAttached()) {
      this.overlayRef.dispose();
    }
  }

  setInitialValue(initial?: string) {
    const country = intlTelInput.default
      .getCountryData()
      .find(country => country.dialCode === initial?.replace('+', ''));

    if (!country && initial) {
      this.setNumber(initial);
    } else {
      this.iti.setCountry(country?.iso2 ?? 'GB');
    }
  }

  getNumber(): string {
    return this.iti.getNumber();
  }

  setNumber(val: string) {
    this.iti.setNumber(val);
  }

  destroy() {
    this.iti.destroy();
  }
}
