import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'arrayToString',
})
export class ArrayToStringPipe implements PipeTransform {
  transform(value: string[], max?: number): string {
    if (Array.isArray(value)) {
      if (max && value.length > max) {
        const arrValue = value.slice(0, max);
        return `${arrValue?.join(', ')} +${value.length - max} more`;
      }

      return value?.join(', ');
    }
    return value;
  }
}
