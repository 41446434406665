<div class="om-button-list-wrapper" [class.is-redrawing]="isRedrawing">
  <ul class="om-button-list" #buttonListElement>
    @for (listItem of displayedButtonList; track $index) {
      <li>
        <button
          mat-button
          class="is-chip"
          [disabled]="isRedrawing"
          [class.is-active]="selected === listItem.name"
          (click)="selectValue(listItem)"
        >
          {{ listItem.name }}
        </button>
      </li>
    }
  </ul>

  @if (menuList.length) {
    <button mat-button class="is-chip is-flat" [class.is-strong]="isMoreSelected" [matMenuTriggerFor]="moreMenuList">
      More ({{ menuList.length }})<mat-icon iconPositionEnd svgIcon="chevron_down"></mat-icon>
    </button>
  }
</div>

<mat-menu #moreMenuList="matMenu">
  @for (menuItem of menuList; track menuItem) {
    <a mat-menu-item (click)="selectValue(menuItem)" [class.is-selected]="selected === menuItem.name">
      <span>{{ menuItem.name }}</span>
    </a>
  }
</mat-menu>
