import { createAction, props } from '@ngrx/store';
import { PocSettings } from 'apps/bankangle/src/app/models/poc';
import { TradeShortcut } from '../../../models/trade';
import { AuthenticatedUserState } from '../authenticated-user/authenticated-user.model';
import { CalculatedPermissions } from './permission.model';

const CALCULATE_PERMISSIONS = '[Permission] Calculate Permissions';
const SET_CALCULATED_PERMISSIONS = '[Permission] Set Calculated Permissions';

const GET_POC_SETTINGS = '[Permission] Get POC settings';
const GET_POC_SETTINGS_SUCCESS = `${GET_POC_SETTINGS} SUCCESS`;
const GET_POC_SETTINGS_FAIL = `${GET_POC_SETTINGS} FAIL`;

const GET_TRADE_SHORTCUTS = '[Permission] Get Trade Shortcuts';
const GET_TRADE_SHORTCUTS_SUCCESS = `${GET_TRADE_SHORTCUTS} SUCCESS`;
const GET_TRADE_SHORTCUTS_FAIL = `${GET_TRADE_SHORTCUTS} FAIL`;

const SET_CUSTOMER_SUPPORT_CHAT_SUPPORTED = '[Permission] Set Customer Support Chat Supported';

export const PermissionEffect = {
  CALCULATE_PERMISSIONS,
  GET_POC_SETTINGS,
  GET_TRADE_SHORTCUTS,
};

export const PermissionAction = {
  calculatePermissions: createAction(
    CALCULATE_PERMISSIONS,
    props<{ params: { authenticatedUser: AuthenticatedUserState } }>(),
  ),
  setCalculatedPermissions: createAction(SET_CALCULATED_PERMISSIONS, props<{ params: CalculatedPermissions }>()),

  getPocSettings: createAction(GET_POC_SETTINGS),
  getPocSettingsSuccess: createAction(GET_POC_SETTINGS_SUCCESS, props<{ params: PocSettings }>()),
  getPocSettingsFail: createAction(GET_POC_SETTINGS_FAIL),

  getTradeShortcuts: createAction(GET_TRADE_SHORTCUTS),
  getTradeShortcutsSuccess: createAction(GET_TRADE_SHORTCUTS_SUCCESS, props<{ params: TradeShortcut[] }>()),
  getTradeShortcutsFail: createAction(GET_TRADE_SHORTCUTS_FAIL),

  setCustomerSupportChatSupported: createAction(SET_CUSTOMER_SUPPORT_CHAT_SUPPORTED),
};
