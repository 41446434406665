@if (filename) {
  <span class="om-filename">
    <span>{{ filename.start }}</span>
    <span>{{ filename.end }}</span>
  </span>
} @else {
  <span class="om-placeholder">Choose a file or drag it here</span>
}
@if (value) {
  <button mat-icon-button class="is-not-strong">
    <mat-icon svgIcon="remove"></mat-icon>
  </button>
} @else {
  <mat-icon svgIcon="file"></mat-icon>
}
<input #fileInput type="file" [accept]="accept" [multiple]="multiple" (change)="onFileInputChange($event)" />

@if (history) {
  <button #menuButton [matMenuTriggerFor]="matMenu"></button>
  <mat-menu #matMenu>
    <mat-option (click)="openFileExplorer()">
      <mat-icon svgIcon="upload"> </mat-icon>
      <span> Upload new file </span>
    </mat-option>
    @for (group of history; track $index) {
      <mat-optgroup [label]="group.divider_label">
        @for (option of group.options; track $index) {
          <mat-option [value]="option.value" (click)="onOptionClick(option)">
            <div>
              <span>{{ option.label }}</span>
              @if (option.description) {
                <span>{{ option.description }}</span>
              }
            </div>
          </mat-option>
        } @empty {
          <mat-option [disabled]="true">
            @if (group.divider_label === 'History') {
              <span>Previously uploaded files will show here</span>
            }
          </mat-option>
        }
      </mat-optgroup>
    }
  </mat-menu>
}
