import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { KeyCode } from '../../constants/system';

@Component({
  standalone: false,
  selector: 'om-shared-search',
  templateUrl: './shared-search.component.html',
  styleUrls: ['./shared-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SharedSearchComponent implements OnInit, OnChanges {
  readonly searchControl: FormControl = new FormControl();
  private readonly ngOnDestroy$ = new Subject<void>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchTerm = '';
  @Input() placeholder = 'Search...';
  @Input() fieldClass: string;

  @Input()
  get enableKeyCombinations(): boolean {
    return this._enableKeyCombinations;
  }
  set enableKeyCombinations(enableKeyCombinations: boolean) {
    this._enableKeyCombinations = coerceBooleanProperty(enableKeyCombinations);
  }
  private _enableKeyCombinations: boolean;

  @Input() searchIcon: boolean;

  @Input()
  get debounce(): number {
    return this._debounce;
  }
  set debounce(debounce: number) {
    this._debounce = Number(debounce);
  }
  private _debounce: number;

  @ViewChild('input', { static: false })
  set inputRef(elementRef: ElementRef) {
    this._inputRef = elementRef;
  }
  get inputRef() {
    return this._inputRef;
  }

  private _inputRef: ElementRef;
  private hasFocus = false;
  isActive = true;

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(debounceTime(this.debounce ?? 250), distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
      .subscribe((value: string) => {
        this.onSearch.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchTerm && changes.searchTerm.currentValue !== changes.searchTerm.previousValue) {
      this.searchControl.setValue(changes.searchTerm.currentValue, { emitEvent: false });
      if (this.searchIcon && changes.searchTerm.currentValue) {
        this.showSearchField();
      }
    }
    if (changes.searchIcon && changes.searchIcon.currentValue !== changes.searchIcon.previousValue) {
      if (changes.searchIcon.currentValue === false) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    }
  }

  @HostListener('window:keydown', ['$event'])
  captureKeyDown(event: KeyboardEvent) {
    if (
      (!this.isActive || !this.hasFocus) &&
      this._enableKeyCombinations &&
      (event.metaKey || event.ctrlKey) &&
      event.code === KeyCode.F
    ) {
      event.preventDefault();
      if (this.searchIcon) {
        this.showSearchField();
      } else {
        this.focusOnInput();
      }
    }
  }

  @HostListener('keydown.escape', ['$event'])
  captureSearchEscapeKeyDown(event: KeyboardEvent) {
    if (this._enableKeyCombinations && this.isActive) {
      this.clearForm();
      this.blurInput();
      if (this.searchIcon) {
        this.hideSearchField();
      }
    }
  }

  showSearchField() {
    this.isActive = true;
    setTimeout(() => {
      this.focusOnInput();
    });
  }

  onFocusIn() {}

  onFocusOut() {
    this.blurInput();
    if (this.searchIcon) {
      this.hideSearchField();
    }
  }

  private focusOnInput() {
    this.hasFocus = true;
    this.inputRef?.nativeElement?.focus();
  }

  private blurInput() {
    this.hasFocus = false;
    this.inputRef?.nativeElement.blur();
  }

  private hideSearchField() {
    if (!this.searchControl.value) {
      this.clearForm();
      this.isActive = false;
    }
  }

  clearForm() {
    this.searchControl.reset('', { emitEvent: false, onlySelf: true });
    this.onSearch.emit('');
  }
}
