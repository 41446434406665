import { environment } from '@morpho/environment';
import { MonoTypeOperatorFunction, ReplaySubject, distinctUntilChanged, share } from 'rxjs';
import { ONE_THOUSAND } from './elements/constants/system';

export function distinctUntilChangedJson<T>(): MonoTypeOperatorFunction<T> {
  return distinctUntilChanged((prev, curr) => {
    return JSON.stringify(prev) === JSON.stringify(curr);
  });
}

export function shareLastEmitted<T>(): MonoTypeOperatorFunction<T> {
  return share({
    connector: () => new ReplaySubject(1),
    resetOnError: false,
    resetOnComplete: false,
    resetOnRefCountZero: true,
  });
}

export function distinctUntilChangedSet(): MonoTypeOperatorFunction<Set<any> | null> {
  return distinctUntilChanged((prev, curr) => {
    return (prev && JSON.stringify([...prev])) === (curr && JSON.stringify([...curr]));
  });
}

export function getCdnUrl(path: string): string {
  return environment.cdnUrl + path;
}

export function minutesToMilliseconds(minutes: number) {
  return 60 * ONE_THOUSAND * minutes;
}

export function patchObjectData(object: Record<any, any>, path: string, data?: any): Record<any, any> {
  /*
    - path should be a string of keys separated by full stops or just the key if it's only level
    - data will be spread into the paths output so write the path leading 1 level higher than the value you want to change
    - data being null will delete the data of the given path including the last value in the path
    - data being and empty object will rewrite the value to be and empty object
  */

  if (!path) {
    return object;
  }

  const updatedObject = { ...object };

  const dir = [...(path.includes('.') ? path.split('.') : [path])];
  const lastDir = dir.pop();

  if (!lastDir) {
    return object;
  }

  let objectReference = updatedObject;

  for (let i = 0; i < dir.length; i++) {
    if (!objectReference[dir[i]]) {
      return object;
    }
    objectReference = objectReference[dir[i]];
  }

  if (data === undefined) {
    delete objectReference[lastDir];
  } else if (typeof data === 'object' && !Array.isArray(data)) {
    const combinedData = !!Object.keys(data).length ? { ...objectReference[lastDir], ...data } : {};

    objectReference[lastDir] = Object.keys(combinedData).reduce((acc: Record<string, any>, curr) => {
      if (combinedData[curr] != null) {
        acc[curr] = combinedData[curr];
      }
      return acc;
    }, {});
  } else {
    objectReference[lastDir] = data;
  }

  return updatedObject;
}
