import { TypedStateAction } from '@morpho/core';
import { ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { filter, Observable } from 'rxjs';
import { sortByAlphabetical } from '../../constants/sortComparators';
import { IssuingEntity } from '../../core/patterns/issuer/issuer.model';
import {
  AdditionalCurve,
  ComparableData,
  ComparablesInformation,
  GLOBAL_FIELD,
  HISTORICAL_PRICING,
  NewIssueCurveType,
  OVERVIEW_TAB,
  PRICING,
  SecondaryLevels,
} from './models/pricing-request.model';
import { PricingCompletionEffect } from './state/pricing-completion/pricing-completion.actions';
import {
  IssuerPricingData,
  IssuerPricingDataMap,
  PricingTabDataMap,
  SharedTabData,
} from './state/pricing-completion/pricing-completion.model';

export function filterAdditionalCurveEffect(curveType: NewIssueCurveType) {
  return filter(
    (
      action: TypedStateAction<{
        tab: string;
        additionalCurveMap: Record<NewIssueCurveType, AdditionalCurve[]>;
      }>,
    ) => Object.keys(action.params.additionalCurveMap).includes(curveType),
  );
}

export function updateAdditionalCurvesEffectCalled(actions: Observable<any>, curveType: NewIssueCurveType) {
  return actions.pipe(ofType(PricingCompletionEffect.UPDATE_ADDITIONAL_CURVES), filterAdditionalCurveEffect(curveType));
}

export function generateComparableCurveId(params: {
  issuing_entity_id?: number;
  issuing_entity?: IssuingEntity;
  funding_basis: string;
  seniority: string;
}): string {
  const entityId = params.issuing_entity_id ?? params.issuing_entity?.id;
  return `${entityId}/${params.funding_basis}/${params.seniority}`;
}

export function getDataFromComparableCurveId(id: string): {
  issuing_entity_id: number;
  funding_basis: string;
  seniority: string;
} {
  const split = id.split('/');
  return {
    issuing_entity_id: Number(split[0]),
    funding_basis: split[1],
    seniority: split[2],
  };
}

// todo: move to utils + combine into one function with key as parameter;
export function getPricingBasisForTab(issuerPricingDataMap: IssuerPricingDataMap) {
  return Object.values(issuerPricingDataMap ?? {})[0]?.pricingRequest?.pricing.funding_basis;
}

export function getPricingBreakdownForTab(issuerPricingDataMap: IssuerPricingDataMap) {
  return Object.values(issuerPricingDataMap ?? {})[0]?.pricingRequest?.pricing.breakdown;
}

export function getShowReferenceCurvesForTab(issuerPricingDataMap: IssuerPricingDataMap) {
  return Object.values(issuerPricingDataMap ?? {})[0]?.pricingRequest?.pricing.show_references;
}

export function getShowLatestHistoricalForTab(issuerPricingDataMap: IssuerPricingDataMap) {
  return Object.values(issuerPricingDataMap ?? {})[0]?.pricingRequest?.pricing.show_latest_historical;
}

export function getQuoteTypeForTab(issuerPricingDataMap: IssuerPricingDataMap) {
  return Object.values(issuerPricingDataMap ?? {})[0]?.pricingRequest?.pricing.quote_type;
}

export function getDisplayMaturitiesForTab(tabName: string, pricingTabDataMap: PricingTabDataMap) {
  const groupMaturitiesFromLevels = (issuerPricingDataMap: IssuerPricingDataMap) =>
    Object.values(issuerPricingDataMap ?? {}).reduce((maturities: Set<string>, issuerPricingData) => {
      const levels = issuerPricingData.pricingRequest.pricing.levels;
      (levels.length ? Object.keys(levels[0].data) : issuerPricingData.pricingRequest.maturities).forEach(maturity => {
        if (maturity) {
          maturities.add(maturity);
        }
      });
      return maturities;
    }, new Set<string>());
  if (tabName === OVERVIEW_TAB) {
    return [
      ...Object.keys(pricingTabDataMap).reduce((maturities: Set<string>, tab) => {
        return new Set([...maturities, ...groupMaturitiesFromLevels(pricingTabDataMap[tab])]);
      }, new Set<string>()),
    ];
  } else {
    return [...groupMaturitiesFromLevels(pricingTabDataMap[tabName])];
  }
}

export function getBenchmarkBondsForTab(issuerPricingDataMap: IssuerPricingDataMap) {
  const copy: IssuerPricingDataMap = JSON.parse(JSON.stringify(issuerPricingDataMap ?? {}));

  let benchmarkBonds: Record<string, string> | undefined;
  Object.values(copy).forEach(issuerPricingData => {
    const benchmarkCurve = issuerPricingData.pricingRequest.pricing.additional_curves?.find(
      curve => curve.type === NewIssueCurveType.BenchmarkInput,
    );

    if (benchmarkCurve) {
      if (!benchmarkBonds) {
        benchmarkBonds = {};
      }
      Object.entries(benchmarkCurve.data ?? {}).forEach(([maturity, bond]: [string, string]) => {
        if (!benchmarkBonds![maturity]) {
          benchmarkBonds![maturity] = bond;
        }
      });
    }
  });
  return benchmarkBonds;
}

export function getAdditionalPricingCurvesByType(
  curveType: NewIssueCurveType,
  issuerPricingDataMap: IssuerPricingDataMap,
): AdditionalCurve[] {
  return [
    ...Object.values(issuerPricingDataMap).reduce(
      (filteredAdditionalCurves: Set<string>, issuerPricingData: IssuerPricingData) => {
        issuerPricingData.pricingRequest?.pricing.additional_curves
          ?.filter(curve => curve.type === curveType)
          .forEach(curve => {
            filteredAdditionalCurves.add(JSON.stringify(curve));
          });
        return filteredAdditionalCurves;
      },
      new Set<string>(),
    ),
  ].map(curve => JSON.parse(curve));
}

export function getSelectedHistoricalDatesForIssuer(
  issuerPricingDataMap: IssuerPricingDataMap,
  availableHistoricalDates?: Record<string, string[]>,
  issuingEntityId?: string | number,
): Record<string, string[]> {
  const issuingEntityIds = issuingEntityId ? [issuingEntityId] : Object.keys(issuerPricingDataMap);
  const selectedDatesByIssuer = issuingEntityIds.reduce((acc: Record<string, string[]>, entityId: string) => {
    const issuerPricingData = issuerPricingDataMap[entityId];
    if (!issuerPricingData) {
      return acc;
    }
    issuerPricingData.pricingRequest.pricing.additional_curves
      ?.filter(curve => curve.type === NewIssueCurveType.Historical)
      .forEach(curve => {
        if (acc[entityId] && !acc[entityId].includes(curve.value)) {
          acc[entityId].push(curve.value);
        } else {
          acc[entityId] = [curve.value];
        }
      });
    return acc;
  }, {});

  if (getShowLatestHistoricalForTab(issuerPricingDataMap)) {
    issuingEntityIds.forEach(entityId => {
      const issuerPricingData = issuerPricingDataMap[entityId];
      if (!issuerPricingData) {
        return;
      }
      const showLatest = issuerPricingData.pricingRequest.pricing.show_latest_historical;
      const availableDates = availableHistoricalDates?.[entityId];
      if (!showLatest && availableDates) {
        let latestHistoricalIdx = 0;
        if (issuerPricingData.pricingRequest.pricing.completed_at) {
          const completionDate = moment(issuerPricingData.pricingRequest.pricing.completed_at).preciseUTCFormat();
          if (completionDate) {
            latestHistoricalIdx = availableDates.indexOf(completionDate) + 1;
          }
        }
        const mostRecentHistorical = availableDates[latestHistoricalIdx];
        if (mostRecentHistorical) {
          if (selectedDatesByIssuer[entityId]) {
            selectedDatesByIssuer[entityId].push(mostRecentHistorical);
          } else {
            selectedDatesByIssuer[entityId] = [mostRecentHistorical];
          }
        }
      }
    });
  }
  return selectedDatesByIssuer;
}

export function getHistoricalSecondaryBondDates(issuerPricingDataMap: IssuerPricingDataMap): string[] {
  return [
    ...Object.values(issuerPricingDataMap).reduce(
      (selectedHistoricalDates: Set<string>, issuerPricingData: IssuerPricingData) => {
        issuerPricingData.pricingRequest.pricing.included_dates?.forEach((date: string) =>
          selectedHistoricalDates.add(date),
        );
        return selectedHistoricalDates;
      },
      new Set<string>(),
    ),
  ];
}

export function getIssuingEntities(issuerPricingDataMap: IssuerPricingDataMap) {
  return Object.values(issuerPricingDataMap).reduce((issuingEntities: Record<number, string>, issuerPricingData) => {
    const issuerData = issuerPricingData.pricingRequest.issuing_entity;
    issuingEntities[issuerData.id] = issuerData.name;
    return issuingEntities;
  }, {});
}

export function getComparablesInformation(pricingTabDataMap: PricingTabDataMap): ComparablesInformation {
  const groupedByIssuerArray = Object.values(pricingTabDataMap ?? {});
  const tabDataArray = groupedByIssuerArray.map(groupedByIssuer => Object.values(groupedByIssuer)).flat();

  const comparableCriteriaMap: Record<string, ComparableData> = {};
  const comparableIsinSet = new Set<string>();
  tabDataArray.forEach(tabData => {
    tabData.pricingRequest.comparable_isins?.forEach(isin => {
      comparableIsinSet.add(isin);
    });

    tabData.pricingRequest.comparable_criteria?.forEach(criteria => {
      const id = generateComparableCurveId(criteria);
      comparableCriteriaMap[id] = criteria;
    });
  });

  const data: ComparablesInformation = {
    comparable_criteria: Object.values(comparableCriteriaMap),
    comparable_isins: [...comparableIsinSet],
  };

  return data;
}

export function getSelectedBonds(issuerPricingDataMap: IssuerPricingDataMap): Set<string> | undefined {
  const issuerPricingDataList = Object.values(issuerPricingDataMap);

  if (!issuerPricingDataList.some(issuerPricingData => issuerPricingData.pricingRequest.pricing.included_isins)) {
    return;
  }

  return issuerPricingDataList.reduce((selectedBonds: Set<string>, issuerPricingData) => {
    getSelectedBondsFromLevels(issuerPricingData.pricingRequest.pricing.secondary_levels ?? {}, selectedBonds);
    return selectedBonds;
  }, new Set<string>());
}

export function getSelectedBondsFromLevels(
  secondaryLevels?: SecondaryLevels,
  setObjReference?: Set<string>,
): Set<string> | undefined {
  if (!secondaryLevels) {
    return;
  }

  const selectedBonds = setObjReference ?? new Set<string>();

  Object.keys(secondaryLevels).forEach(isin => {
    if (!Object.keys(secondaryLevels[isin]).length || secondaryLevels[isin].ihs) {
      selectedBonds.add(isin);
    }
  });

  return selectedBonds;
}

export function getSecondaryLevels(issuerPricingDataMap: IssuerPricingDataMap): SecondaryLevels | undefined {
  const getLatestActionTime = (issuerPricingData: IssuerPricingData) => {
    return (
      issuerPricingData.pricingRequest.pricing.completed_at ?? issuerPricingData.pricingRequest.pricing.updated_at ?? ''
    );
  };

  const issuerPricingDataList = Object.values(issuerPricingDataMap).sort((a, b) => {
    return sortByAlphabetical(getLatestActionTime(a), getLatestActionTime(b));
  });

  if (!issuerPricingDataList.some(issuerPricingData => issuerPricingData.pricingRequest.pricing.secondary_levels)) {
    return;
  }

  return issuerPricingDataList.reduce((combinedSecondaryLevels: SecondaryLevels, issuerPricingData) => {
    const secondaryLevels = issuerPricingData.pricingRequest.pricing.secondary_levels ?? {};
    Object.entries(secondaryLevels).forEach(([isin, bondPricing]) => {
      combinedSecondaryLevels[isin] = {
        ...(combinedSecondaryLevels[isin] && combinedSecondaryLevels[isin]),
        ...bondPricing,
      };
    });
    return combinedSecondaryLevels;
  }, {});
}

export function getSecondaryBondsPricingMap(
  issuerPricingDataMap: IssuerPricingDataMap,
  sharedTabData: SharedTabData,
): SecondaryLevels {
  const secondaryPricingMap = JSON.parse(JSON.stringify(getSecondaryLevels(issuerPricingDataMap) ?? {}));

  Object.entries(sharedTabData?.unsavedSecondaryLevels ?? {}).forEach(([isin, bondPricing]) => {
    secondaryPricingMap[isin] = secondaryPricingMap[isin]
      ? {
          ...secondaryPricingMap[isin],
          ...bondPricing,
        }
      : bondPricing;
  });
  return secondaryPricingMap;
}

export function getSecondaryBondOverrides(issuerPricingDataMap: IssuerPricingDataMap): Record<string, string> {
  return Object.entries(getSecondaryLevels(issuerPricingDataMap) ?? {})?.reduce(
    (acc: Record<string, string>, [isin, bondPricing]) => {
      if (bondPricing.override) {
        acc[isin] = bondPricing.override;
      }
      return acc;
    },
    {},
  );
}

export function getHistoricalDateFromCurveID(rowId: string): string {
  if (!rowId) {
    return '';
  }
  return rowId.split('/')[2].split('_')[2] ?? '';
}

export function getCurveTypeFromCurveID(rowId: string): NewIssueCurveType | null {
  const split = rowId?.split('/');
  const validCurveTypes = Object.values(NewIssueCurveType);
  for (const index of [2, 3]) {
    const possibleCurveType = split[index];
    if (validCurveTypes.includes(<NewIssueCurveType>possibleCurveType)) {
      return <NewIssueCurveType>possibleCurveType;
    }
  }

  return null;
}

export function getIssuingEntityFromCurveID(rowId: string): string {
  if (!rowId) {
    return '';
  }
  return rowId.split('/')[0];
}

export function getTabFromCurveID(rowId: string): string {
  if (!rowId) {
    return '';
  }
  return rowId.split('/')[1];
}

export function getValueFromCurveID(rowId: string): string {
  if (!rowId) {
    return '';
  }
  return rowId.split('/')[3];
}

export function generateCurveID(params: {
  tab: string;
  curveType: NewIssueCurveType;
  title?: string;
  issuingEntityId?: string | number;
  historicalDate?: string;
  isHistoricalPricing?: boolean;
}): string {
  let tabId = `${params.issuingEntityId ? params.issuingEntityId : GLOBAL_FIELD}/`;
  if (
    [
      NewIssueCurveType.NewIssuePremium,
      NewIssueCurveType.Spread,
      NewIssueCurveType.Total,
      NewIssueCurveType.Yield,
    ].includes(params.curveType)
  ) {
    tabId += `${params.tab}/${
      params.historicalDate && params.isHistoricalPricing ? `${HISTORICAL_PRICING}_${params.historicalDate}` : PRICING
    }/${params.curveType}`;
  } else {
    tabId += `${params.tab}/${params.curveType}/${params.title}`;
  }
  return tabId;
}
