import { Injectable } from '@angular/core';
import { ApiService, HttpMethod, NipMode, ValueAndLabel, minutesToMilliseconds } from '@morpho/core';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, map, of, shareReplay, switchMap, take, tap } from 'rxjs';
import { sortByAlphabetical } from '../../../constants/sortComparators';
import { TeamColleague } from '../../../core/elements/models/team-colleague.model';
import { StateService } from '../../../core/services/state.service';
import { ConstantsState } from '../../../core/state/constants/constants.model';
import { CurveType } from '../../../models/curve.model';
import { GovieBond, GovieBondResponse, SecondaryBond } from '../models/comparable.model';
import { PricingInboxResponse } from '../models/pricing-inbox.model';
import {
  ComparableData,
  PricingRequestCreation,
  PricingRequestHistory,
  PricingRequestHistoryList,
  PricingRequestParams,
  PricingRequestResponse,
  RequestingDesk,
  SyndicateTeam,
} from '../models/pricing-request.model';
import { SecondaryBondFilters, SecondaryBondsBody } from '../models/secondaries.model';
import { PricingCompletionSelector } from '../state/pricing-completion/pricing-completion.selectors';
import { SyndicatePricerUtilService } from './syndicate-pricer-util.service';

@Injectable({
  providedIn: 'root',
})
export class SyndicatePricerApiService {
  readonly requestingDesks$: Observable<RequestingDesk[]> = this.apiService
    .get('companies/dealer-desks/?only_my_desks=true')
    .pipe(shareReplay());
  readonly syndicateTeams$: Observable<SyndicateTeam[]> = this.apiService
    .get('companies/dealer-desks/')
    .pipe(shareReplay());
  readonly teamColleagues$: Observable<TeamColleague[]> = this.apiService.get('teams/colleagues/').pipe(shareReplay());

  readonly requestingDeskOptions$: Observable<ValueAndLabel[]> = this.requestingDesks$.pipe(
    map(desks => {
      return desks.map(desk => this.syndicatePricerUtilService.optionFromRequestingDesk(desk));
    }),
    shareReplay(),
  );
  readonly syndicateTeamOptions$: Observable<ValueAndLabel[]> = this.syndicateTeams$.pipe(
    map(teams => {
      return teams.map(team => this.syndicatePricerUtilService.optionFromSyndicateTeam(team));
    }),
    shareReplay(),
  );
  readonly teamColleagueOptions$: Observable<ValueAndLabel[]> = this.teamColleagues$.pipe(
    map(colleagues => {
      return colleagues.map(colleague => this.syndicatePricerUtilService.optionFromTeamColleague(colleague));
    }),
    shareReplay(),
  );

  private bondOptions$: Observable<ValueAndLabel[]> | null;
  private issuerComparableCurveOptionsMap: Record<string, ValueAndLabel[]> = {};
  private pricingRequestEndpointBase = 'levels/pricing-requests/';

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private store: Store,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
  ) {}

  getIssuingEntityGroups(): Observable<PricingInboxResponse[]> {
    const endpoint = `${this.pricingRequestEndpointBase}inbox/`;
    return this.apiService.get(endpoint);
  }

  addIssuingEntityGroup(options: {
    groupName: string;
    issuingEntities: number[];
    pricingDesk: number;
  }): Observable<void> {
    const endpoint = `companies/issuing-entity-groups/`;
    return this.apiService.post(endpoint, options);
  }

  updateIssuingEntityGroup(
    groupId: number,
    options: { groupName: string; issuingEntities: number[]; pricingDesk: number },
  ): Observable<void> {
    const endpoint = `companies/issuing-entity-groups/${groupId}/`;
    return this.apiService.patch(endpoint, options);
  }

  deleteIssuingEntityGroup(groupId: number): Observable<void> {
    const endpoint = `companies/issuing-entity-groups/${groupId}/`;
    return this.apiService.delete(endpoint);
  }

  createPricingRequest(pricingRequest: Partial<PricingRequestCreation>[]): Observable<any> {
    const endpoint = `${this.pricingRequestEndpointBase}bulk-create/`;
    return this.apiService.post(endpoint, pricingRequest);
  }

  removePricingRequests(ids: number[]): Observable<any> {
    const endpoint = `${this.pricingRequestEndpointBase}bulk-archive/`;
    return this.apiService.patch(
      endpoint,
      ids.map(id => ({ id })),
    );
  }

  getPricingRequestData(params?: PricingRequestParams): Observable<PricingRequestResponse[]> {
    const endpoint = this.pricingRequestEndpointBase;
    return this.apiService.get(endpoint, params);
  }

  getPricingRequestDataById(pricingRequestId: number, includePricing?: boolean): Observable<PricingRequestResponse> {
    const endpoint = `${this.pricingRequestEndpointBase}${pricingRequestId}/`;
    const body = includePricing ? { include_pricing: includePricing } : {};
    return this.apiService.get(endpoint, body);
  }

  getCombinedSecondaryFilters(pricingRequestIds: number[]): Observable<SecondaryBondFilters> {
    const endpoint = `levels/pricing-requests/combine-filters/`;
    const body = {
      pricing_request_ids: pricingRequestIds,
    };
    return this.apiService.post(endpoint, body);
  }

  getHistoricalPricingRequestData(issuingEntityIds: number[]): Observable<PricingRequestHistory[]> {
    const endpoint = `${this.pricingRequestEndpointBase}lite-history`;
    const queryParams = { issuing_entity_id: issuingEntityIds };
    return this.apiService.get(endpoint, queryParams);
  }

  getHistoricalPricingRequestListById(pricingRequestId: number): Observable<PricingRequestHistory[]> {
    const endpoint = `${this.pricingRequestEndpointBase}${pricingRequestId}/lite-history/`;
    return this.apiService.get(endpoint);
  }

  getHistoricalPricingRequestList(issuingEntityIds: number[]): Observable<PricingRequestHistoryList> {
    const endpoint = `${this.pricingRequestEndpointBase}historical-pricing-date/`;
    const queryParams = { issuing_entity_id: issuingEntityIds };
    return this.apiService.get(endpoint, queryParams);
  }

  getBondOptions(): Observable<ValueAndLabel[]> {
    if (this.bondOptions$) {
      return this.bondOptions$;
    }
    const endpoint = `secondaries/bonds/choices/`;
    return this.apiService.get(endpoint).pipe(
      map((isins: string[]) =>
        isins.map(isin => ({
          value: isin,
          label: isin,
        })),
      ),
      tap(options => {
        this.bondOptions$ = of(options);
        setTimeout(() => {
          this.bondOptions$ = null;
        }, minutesToMilliseconds(60));
      }),
    );
  }

  private getIssuerComparableCurveOptions(id: number): Observable<ValueAndLabel[]> {
    const options = this.issuerComparableCurveOptionsMap[id.toString()];
    if (options) {
      return of(options);
    }
    const endpoint = `levels/`;
    const params = { issuer_id: id, source: CurveType.SECONDARY };
    return this.apiService.get(endpoint, params).pipe(
      concatLatestFrom(() => this.stateService.get.constants$),
      map(([response, constants]) => {
        const curveOptions: Record<string, ValueAndLabel> = {};

        response.curves.forEach((curve: any) => {
          const value = this.syndicatePricerUtilService.generateComparableCurveId(curve);
          if (!curveOptions[value]) {
            curveOptions[value] = {
              value: this.syndicatePricerUtilService.generateComparableCurveId(curve),
              label: [
                curve.issuing_entity.name,
                constants.translationFor.funding_basis_options[curve.funding_basis],
                constants.mappingFor.product_types[curve.seniority].short_name,
                curve.structures,
              ].join(' | '),
              data: curve,
            };
          }
        });

        return Object.values(curveOptions).sort((optionA: ValueAndLabel, optionB: ValueAndLabel) => {
          return sortByAlphabetical(optionA.label, optionB.label);
        });
      }),
      tap(options => {
        this.issuerComparableCurveOptionsMap[id.toString()] = options;
      }),
    );
  }

  getIssuersComparableCurveOptions(ids: number[]): Observable<ValueAndLabel[]> {
    if (!ids?.length) {
      return of([]);
    }
    return forkJoin(ids.map(id => this.getIssuerComparableCurveOptions(id))).pipe(
      map(optionsList => optionsList.flat()),
    );
  }

  private bondChoices: string[] | null;
  getBondChoices(): Observable<string[]> {
    if (this.bondChoices) {
      return of(this.bondChoices);
    }
    const endpoint = `secondaries/bonds/choices/`;
    return this.apiService.get(endpoint).pipe(
      tap(response => {
        this.bondChoices = response;
        setTimeout(() => {
          this.bondChoices = null;
        }, 10000);
      }),
    );
  }

  getIndividualBonds(options: SecondaryBondsBody, doNotFilter = false): Observable<SecondaryBond[]> {
    const endpoint = `secondaries/bonds/data/`;
    let body: SecondaryBondsBody;

    if (doNotFilter) {
      body = {};
      Object.entries(options).forEach(([key, value]) => {
        if (key === 'comparable_criteria') {
          body.comparable_criteria = value.map((criteria: ComparableData) => {
            return <ComparableData>{ ...criteria, excluded_isins: undefined };
          });
        } else if (['comparable_isins', 'as_of'].includes(key)) {
          (body as any)[key] = value;
        }
      });
    } else {
      body = options;
    }

    if (body.comparable_criteria) {
      body.comparable_criteria = body.comparable_criteria.filter(criteria => criteria.seniority);
    }

    if (!(body.comparable_criteria?.length || body.comparable_isins?.length)) {
      return of([]);
    }
    return this.apiService.getExpandedPaginationResponse<SecondaryBond>(HttpMethod.POST, endpoint, body);
  }

  savePricing(
    pricingRequest: Partial<PricingRequestResponse>[],
    isSubmission = false,
  ): Observable<PricingRequestResponse[]> {
    return this.store.select(PricingCompletionSelector.mode).pipe(
      take(1),
      switchMap(mode => {
        let endpoint: string;
        if (isSubmission && mode === NipMode.Input) {
          endpoint = `${this.pricingRequestEndpointBase}bulk-submit-pricing/`;
        } else {
          endpoint = `${this.pricingRequestEndpointBase}bulk-update/`;
        }
        return this.apiService.patch(endpoint, pricingRequest);
      }),
    );
  }

  getGovieBonds(fundingBases: string[]): Observable<GovieBond[]> {
    const endpoint = 'secondaries/bonds/govies/';
    return forkJoin([this.apiService.get(endpoint, { govie: fundingBases }), this.stateService.get.constants$]).pipe(
      map(([response, constants]: [GovieBondResponse[], ConstantsState]): GovieBond[] => {
        const fundingBasisLookUp: Record<string, string> = Object.fromEntries(
          Object.entries(constants.arrayFor.basis_metadata_mapping).map(([basis, metaData]) => [
            metaData.ref_issuer_shortname,
            basis,
          ]),
        );
        return response.map(govie => ({
          ...govie,
          identifier: govie.isin,
          maturity_date: govie.maturity,
          funding_basis: fundingBasisLookUp[govie.shortname_of_issuer],
        }));
      }),
    );
  }

  validatePricingRequestCreation(source: string, pricingRequest: any): Observable<any> {
    const endpoint = `${this.pricingRequestEndpointBase}validate-creation/`;
    const payload = {
      source,
      pricing_requests: pricingRequest,
    };
    return this.apiService.post(endpoint, payload);
  }
}
