import { Component } from '@angular/core';
import { PdfZoomOptions } from '@morpho/core';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { PdfViewerService } from '../../services/pdf-viewer.service';

interface ControlAction {
  action(): void;
  icon: string;
  id: string;
  tooltip: string;
  class?: string;
  disabled: boolean;
}
@Component({
  standalone: false,
  selector: 'om-pdf-viewer-controller',
  templateUrl: './pdf-viewer-controller.component.html',
})
export class PdfViewerControllerComponent {
  public isPdfViewerReady = false;

  public isPdfViewerReady$ = this.pdfViewerService.isPdfViewerReady();

  public controlActions$: Observable<ControlAction[]> = combineLatest([
    this.pdfViewerService.isFitToPageWidth(),
    this.pdfViewerService.isPdfViewerReady().pipe(startWith(false)),
  ]).pipe(
    map(([isFitToPageWidth, isPdfViewerReady]) => {
      return [
        {
          action: this.onZoomIn.bind(this),
          icon: 'zoom_plus',
          id: 'zoom_in',
          tooltip: 'Zoom in',
          disabled: !isPdfViewerReady,
        },
        {
          action: this.onZoomToggle.bind(this),
          icon: 'max_width',
          id: 'zoom_fit_toggle',
          tooltip: 'Fit to page',
          class: isFitToPageWidth ? 'is-active' : '',
          disabled: !isPdfViewerReady,
        },
        {
          action: this.onZoomOut.bind(this),
          icon: 'zoom_minus',
          id: 'zoom_out',
          tooltip: 'Zoom out',
          disabled: !isPdfViewerReady,
        },
      ];
    }),
  );

  constructor(private pdfViewerService: PdfViewerService) {}

  public onZoomIn() {
    this.pdfViewerService.setCustomControlZoom(PdfZoomOptions.In);
  }
  public onZoomOut() {
    this.pdfViewerService.setCustomControlZoom(PdfZoomOptions.Out);
  }
  public onZoomToggle() {
    this.pdfViewerService.toggleFitToPageWidth();
  }
}
