import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@morpho/environment';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, expand, filter, reduce, share } from 'rxjs/operators';
import { DEFAULT_ERROR_MESSAGE } from '../../elements/constants/display';
import { UtilService } from '../../elements/services/util.service';
import { HttpMethod } from './http.enum';
import { PaginatedResponse } from './response.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private httpOptions = {
    withCredentials: true,
  };

  private requestsInProgress = 0;
  private readonly requestsInProgressChanged$ = new Subject<void>();
  readonly networkIdle$ = this.requestsInProgressChanged$.pipe(
    debounceTime(250),
    filter(() => this.requestsInProgress === 0),
    share(),
  );
  get isNetworkIdle(): boolean {
    return !this.requestsInProgress;
  }

  private formatErrors = (error: HttpErrorResponse) => {
    let errorDetails = error.error;
    if (typeof errorDetails === 'string' && errorDetails.trim().startsWith('<!')) {
      errorDetails = DEFAULT_ERROR_MESSAGE;
      this.utilService.logError(`ApiThrowingHtmlError: ${error.url}`);
    }
    return throwError(errorDetails);
  };

  constructor(
    private http: HttpClient,
    private utilService: UtilService,
  ) {}

  notifyOfRequest(increment: 1 | -1) {
    this.requestsInProgress += increment;
    this.requestsInProgressChanged$.next();
  }

  options(path: string, params: object = {}): Observable<any> {
    const options: any = {};
    if (params) {
      options.params = new HttpParams(
        typeof params === 'string' ? { fromString: String(params) } : { fromObject: Object(params) },
      );
    }
    return this.http
      .options(`${environment.apiUrl}${path}`, { ...this.httpOptions, ...options })
      .pipe(catchError(this.formatErrors));
  }

  get(path: string, params: object = {}, options: object = {}, encodePlus = false): Observable<any> {
    const combinedOptions: any = { ...options };
    if (params) {
      combinedOptions.params = new HttpParams(
        typeof params === 'string' ? { fromString: String(params) } : { fromObject: Object(params) },
      );
    }
    /*
       https://github.com/angular/angular/issues/11058
       Issue with HTTP params & '+ -'. wont endoe them, but will encode %.
       To workaround, strinfigy the params, and then manually replace the affected chars
       with their encoding.
    */
    if (encodePlus) {
      const stringifiedParams = combinedOptions.params.toString().replace(/\+/gi, '%2B');
      const url = `${environment.apiUrl}${path}?${stringifiedParams}`;
      return this.http.get(url, { ...this.httpOptions, ...options }).pipe(catchError(this.formatErrors));
    }

    return this.http
      .get(`${environment.apiUrl}${path}`, { ...this.httpOptions, ...combinedOptions })
      .pipe(catchError(this.formatErrors));
  }

  getFromExternalAPI(path: string, params: object = {}, responseType?: string): Observable<any> {
    const options: any = {};
    if (params) {
      options.params = new HttpParams(
        typeof params === 'string' ? { fromString: String(params) } : { fromObject: Object(params) },
      );
    }

    if (responseType) {
      options.responseType = responseType;
    }

    return this.http
      .get(`${environment.baseUrl}${path}`, {
        ...this.httpOptions,
        ...options,
      })
      .pipe(catchError(this.formatErrors));
  }

  callThirdPartyAPI(path: string, method: HttpMethod, params?: object | string, options?: any): Observable<any> {
    switch (method) {
      case HttpMethod.POST:
      case HttpMethod.PATCH:
      case HttpMethod.PUT:
        return this.http[method](path, params, options).pipe(catchError(this.formatErrors));
      default:
        return (<any>this.http[method])(path, options).pipe(catchError(this.formatErrors));
    }
  }

  post(path: string, body: object | FormData = {}, options: object = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}${path}`, body, { ...this.httpOptions, ...options })
      .pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: object | FormData = {}): Observable<any> {
    return this.http.patch(`${environment.apiUrl}${path}`, body, this.httpOptions).pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object | FormData = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, body, this.httpOptions).pipe(catchError(this.formatErrors));
  }

  delete(path: string, body?: object): Observable<any> {
    if (body) {
      return this.http
        .request('delete', `${environment.apiUrl}${path}`, { ...this.httpOptions, body })
        .pipe(catchError(this.formatErrors));
    }
    return this.http.delete(`${environment.apiUrl}${path}`, this.httpOptions).pipe(catchError(this.formatErrors));
  }

  getExpandedPaginationResponse<T>(
    method: HttpMethod,
    endpoint: string,
    payload: any,
    pageSize = 100,
  ): Observable<T[]> {
    let offset = 0;
    const queryParams = {
      limit: pageSize,
    };
    return this.makeDynamicHttpCall(method, endpoint, payload, { params: queryParams }).pipe(
      expand((response: PaginatedResponse<T>) => {
        offset += pageSize;
        return response.next
          ? this.makeDynamicHttpCall(method, endpoint, payload, { params: { ...queryParams, offset } })
          : EMPTY;
      }),
      reduce((acc: T[], current: PaginatedResponse<T>) => acc.concat(current.results), []),
    );
  }

  private makeDynamicHttpCall(method: HttpMethod, endpoint: string, payload: any, options?: any): Observable<any> {
    switch (method) {
      case HttpMethod.POST:
        return this.post(endpoint, payload, options);
      case HttpMethod.PATCH:
        return this.patch(endpoint, payload);
      case HttpMethod.PUT:
        return this.put(endpoint, payload);
      case HttpMethod.DELETE:
        return this.delete(endpoint, payload);
      default:
        return this.get(endpoint, { ...payload, ...options?.params });
    }
  }
}
