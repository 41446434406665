import { Injectable } from '@angular/core';
import { ApiService, BasisType, LevelsUtilService, MaturityType, UtilService } from '@morpho/core';
import { DialogService } from '@morpho/dialog';
import { DynamicFormModalCloseEvent, DynamicFormModalParams } from '@morpho/dynamic-form';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { europeanCountries } from 'apps/bankangle/src/app/features/main-view/main-view.constants';
import { Curve, CurveBond, CurveLevel, CurveType } from 'apps/bankangle/src/app/models/curve.model';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PricerDebugForm } from '../../../features/staff/model';
import { SyndicatePricerUtilService } from '../../../features/syndicate-pricer/services/syndicate-pricer-util.service';
import { PricingCompletionSelector } from '../../../features/syndicate-pricer/state/pricing-completion/pricing-completion.selectors';
import { ConstantObject, Constants } from '../../../models/constants';
import { StateService } from '../../services/state.service';
import { ConstantsState } from '../../state/constants/constants.model';
import { PricedLevel, PricerSwapDetails, PricingInfo } from './pricing.model';

export enum DataToPrice {
  Levels = 'levels',
  Bonds = 'bonds',
  BondYield = 'bond_yield',
}

export interface PricerReference {
  market_feeds: {
    [currency: string]: PricingReference;
  }[];
  tenors: string[];
}

export interface PricingReference {
  divisor: number;
  key: string;
  last_received: string;
  mid: number;
  tenor: string;
  update_time: Date;
}

export interface CurveForPricing {
  collateral_currency: string;
  day_count: string;
  funding_basis: string;
  identifier: string;
  payment_frequency: number;
  tenors: LevelForPricing[];
}

export interface SingleLevelForPricing {
  identifier: string;
  collateral_currency: string;
  source_funding_basis: string;
  source_payment_frequency: number;
  source_day_count: string;
  target_funding_basis: string;
  target_payment_frequency: number;
  target_day_count: string;
  tenor: string;
  spread: string;
  price_as_of?: string;
}

export interface LevelForPricing {
  tenor: string;
  spread: string;
}

export interface DataForPricing extends PricingInfo {
  levels_list: CurveForPricing[];
}

export interface LevelDataForPricing {
  levels_list: SingleLevelForPricing[];
}

interface PricedDataResponseWithReferences {
  levels_list: {
    tenors: { tenor: string; spread: string }[];
    funding_basis: string;
    payment_freq: number;
    daycount: string;
    collateral_currency: string;
    identifier: string;
    swapped_basis: string;
    swapped_daycount: string;
    swapped_payment_frequency: number;
  }[];
  fra_pricer_references: PricerReference;
  non_fra_pricer_references: PricerReference;
}

interface PricedDataResponse {
  levels_list: {
    identifier: string;
    spread: string;
  }[];
}

export interface PricedData {
  [identifier: string]: {
    tenors: LevelForPricing[];
    bonds?: LevelForPricing[];
    [key: string]: any;
  };
}

export interface PricedData2 {
  [identifier: string]: {
    spread: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  constructor(
    private store: Store,
    private apiService: ApiService,
    private dialogService: DialogService,
    private levelsUtilService: LevelsUtilService,
    private stateService: StateService,
    private utilService: UtilService,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
  ) {}

  getPricingDetailsByIssuerCountry(country: string): PricerSwapDetails {
    return {
      fundingBasis: this.getFundingBasisByIssuerCountry(country),
    };
  }

  private getFundingBasisByIssuerCountry(country: string): string {
    if (country === 'GB') {
      return 'SONIA';
    } else if (europeanCountries.includes(country)) {
      return 'MS_EUR';
    } else {
      return 'MS_USD';
    }
  }

  // todo rename
  buildPricingInfoList(swapDetails: PricerSwapDetails): Observable<PricingInfo[]> {
    return this.stateService.get.constants$.pipe(
      map(constants => {
        const specialSwapMapping = constants.mappingFor.special_swap_mapping[swapDetails.fundingBasis];
        if (specialSwapMapping) {
          return [
            {
              funding_basis: specialSwapMapping.to_basis[0],
              day_count: specialSwapMapping.to_dcb[0],
              payment_frequency: specialSwapMapping.to_payment_freq[0],
            },
            {
              funding_basis: specialSwapMapping.to_basis[1],
              day_count: specialSwapMapping.to_dcb[1],
              payment_frequency: specialSwapMapping.to_payment_freq[1],
            },
          ];
        }

        const swapOption = constants.mappingFor.combined_swap_options[swapDetails.fundingBasis];

        return [
          {
            funding_basis: swapDetails.fundingBasis,
            payment_frequency: swapDetails.paymentFrequency ?? swapOption?.payment_freq,
            day_count: swapDetails.dayCount ?? swapOption?.day_count,
          },
        ];
      }),
    );
  }

  getPricingDataWithReferences(data: DataForPricing): Observable<{
    priced: PricedData;
    references: { fra_pricer_references: PricerReference; non_fra_pricer_references: PricerReference };
  }> {
    const updatedLevelsList = data.levels_list.reduce((acc: CurveForPricing[], levelList) => {
      const newLevelList = {
        ...levelList,
        tenors: levelList.tenors.filter(level => level.spread),
      };
      acc.push(newLevelList);
      return acc;
    }, []);

    const updatedData = {
      ...data,
      levels_list: updatedLevelsList,
    };
    if (!updatedData.funding_basis) {
      return of();
    }
    const endpoint = 'levels/price/';

    const pricedData$ = data.levels_list.length ? this.apiService.post(endpoint, updatedData) : of({ levels_list: [] });
    return pricedData$.pipe(
      map((pricedPrimaryResponse: PricedDataResponseWithReferences) => {
        const priced: PricedData = this.utilService.convertArrayToMap(pricedPrimaryResponse.levels_list, 'identifier');
        return {
          priced,
          references: {
            fra_pricer_references: pricedPrimaryResponse.fra_pricer_references,
            non_fra_pricer_references: pricedPrimaryResponse.non_fra_pricer_references,
          },
        };
      }),
    );
  }

  getPricingData(data: LevelDataForPricing): Observable<{
    priced: PricedData2;
  }> {
    if (!data.levels_list?.length) {
      return of();
    }
    const endpoint = 'levels/price3/';

    const pricedData$ = this.apiService.post(endpoint, data);
    return pricedData$.pipe(
      map((pricedPrimaryResponse: PricedDataResponse) => {
        const priced: PricedData2 = this.utilService.convertArrayToMap(pricedPrimaryResponse.levels_list, 'identifier');
        return {
          priced,
        };
      }),
    );
  }

  priceWithOptions(
    data: Curve[],
    options: {
      swapDetails: PricerSwapDetails | null;
      dataToPrice: DataToPrice;
      targetSpread?: number;
    },
  ): Observable<{
    curves: Curve[];
    references?: { fra_pricer_references: PricerReference; non_fra_pricer_references: PricerReference };
  }> {
    if (!options.swapDetails?.fundingBasis) {
      return this.price(data, null, options.dataToPrice);
    }

    const pricedData$ = this.buildPricingInfoList(options.swapDetails).pipe(
      mergeMap(pricingInfos => {
        return this.price(data, pricingInfos, options.dataToPrice);
      }),
    );

    if (!options.targetSpread) {
      return pricedData$;
    }

    const isAboveTargetBasis = (item: CurveLevel): boolean => {
      const pricedItem = item.priced?.[0];
      if (!pricedItem) {
        return false;
      }

      let spread = this.utilService.parseNumber(pricedItem.spread) as number;
      if (options.swapDetails?.fundingBasis?.toString().toLocaleLowerCase().includes('fixed')) {
        spread = this.utilService.parseNumber(spread / 100) as number;
      }
      const meetsTarget = spread >= (options.targetSpread as number);
      return meetsTarget;
    };

    const updateLevels = (curveLevel: Record<string, CurveLevel>) => {
      return Object.entries(curveLevel).reduce((acc, [tenor, level]: [string, CurveLevel]) => {
        acc = { ...acc, [tenor]: { ...level, is_target_basis_met: isAboveTargetBasis(level) } };
        return acc;
      }, {});
    };

    return pricedData$.pipe(
      map(response => {
        if (options.dataToPrice === DataToPrice.BondYield) {
          return {
            ...response,
            curves: response.curves.filter(curve => isAboveTargetBasis((curve as any)[options.dataToPrice])),
          };
        }

        const curves = response.curves.reduce((accumulator: Curve[], curve: Curve) => {
          curve = {
            ...curve,
            levels: updateLevels(curve.levels),
          };
          if (Object.values(curve.levels).some(level => level.is_target_basis_met)) {
            accumulator.push(curve);
          }
          return accumulator;
        }, []);
        return {
          ...response,
          curves,
        };
      }),
    );
  }

  price(
    data: Curve[],
    pricingInfos: PricingInfo[] | null,
    dataToPrice = DataToPrice.Levels,
  ): Observable<{
    curves: Curve[];
    references?: { fra_pricer_references: PricerReference; non_fra_pricer_references: PricerReference };
  }> {
    if (!data?.length) {
      return of({ curves: [] });
    }
    data = JSON.parse(JSON.stringify(data));

    if (!pricingInfos?.length) {
      return of({ curves: this.deletePricingData(data, dataToPrice) });
    }

    let i = 1;
    data = data.map(row => ({ ...row, temporary_pricing_identifier: `row_${i++}` }));

    return this.stateService.get.constants$.pipe(
      mergeMap(constants => {
        const curvesForPricing = this.prepareCurvesForPricing(data, constants, dataToPrice);
        if (dataToPrice === DataToPrice.Levels) {
          curvesForPricing.push(...this.prepareCurvesForPricing(data, constants, DataToPrice.Bonds));
        }

        if (!curvesForPricing.length) {
          return of({ curves: data });
        }
        const pricingRequests: Observable<{
          priced: PricedData;
          references: { fra_pricer_references: PricerReference; non_fra_pricer_references: PricerReference };
        }>[] = pricingInfos.map(pricingInfo => {
          const dataForPricing: DataForPricing = {
            levels_list: curvesForPricing,
            day_count: pricingInfo.day_count,
            funding_basis: pricingInfo.funding_basis,
            payment_frequency: pricingInfo.payment_frequency,
          };
          return this.getPricingDataWithReferences(dataForPricing);
        });
        return forkJoin(pricingRequests).pipe(
          map(response => {
            return {
              curves: this.addPricingData(
                data,
                response.map(pricedReference => pricedReference.priced),
                pricingInfos,
                dataToPrice,
              ),
              references: response[0].references, // NOTE the references are == for MS so we take the first
            };
          }),
        );
      }),
    );
  }

  private prepareCurvesForPricing(data: Curve[], constants: Constants, dataToPrice: DataToPrice): CurveForPricing[] {
    const swapOptionMapping: { [key: string]: any } = { ...constants.mappingFor.swap_options };
    const supportedFundingBases = Object.keys(swapOptionMapping);

    return data.reduce((curves: CurveForPricing[], row) => {
      const fundingBasis = row.funding_basis;

      const levelsForPricing: LevelForPricing[] = [];
      const addLevelForPricing = (tenor: string, level: Partial<CurveLevel>) => {
        level.priced = [];
        if (!level.is_re && level.spread) {
          let totalPricing;

          if (level.total) {
            totalPricing = level.total;
          } else if (level.new_issue_premium) {
            totalPricing = this.utilService.getValueAggregate([level.spread, level.new_issue_premium]);
          } else {
            totalPricing = level.spread;
          }

          if (totalPricing) {
            this.utilService.getValuesFromRange(totalPricing)?.forEach(spread => {
              const formattedSpread = spread.slice(0, spread.indexOf('.') + 6);
              levelsForPricing.push({
                tenor,
                spread: formattedSpread,
              });
            });
          }
        }
      };

      switch (dataToPrice) {
        case DataToPrice.Levels:
          Object.keys(row.levels).forEach(tenor => {
            const level = row.levels[tenor];
            addLevelForPricing(tenor, level);
          });
          break;
        case DataToPrice.Bonds:
          if (row.bonds) {
            row.bonds.forEach(bond => addLevelForPricing(bond.tenor, bond));
          }
          break;
        case DataToPrice.BondYield:
          addLevelForPricing((row as any).tenor, (row as any)[dataToPrice]);
          break;
        default:
          console.error(`Pricing not implemented for ${dataToPrice}`);
      }

      if (!levelsForPricing.length || !supportedFundingBases.includes(fundingBasis)) {
        return curves;
      }

      const isCurveMS = this.utilService.isMidSwap(fundingBasis);

      const paymentFrequency =
        (isCurveMS ? null : row.payment_frequency) ??
        swapOptionMapping[fundingBasis].payment_frequency ??
        swapOptionMapping[fundingBasis].payment_freq;

      const dayCount =
        (isCurveMS ? null : row.day_count) ??
        swapOptionMapping[fundingBasis].day_count ??
        swapOptionMapping[fundingBasis].daycount;

      if (paymentFrequency > 0) {
        const curveForPricing: CurveForPricing = {
          collateral_currency: row.collateral_currency,
          day_count: dayCount,
          funding_basis: swapOptionMapping[fundingBasis].funding_basis ?? fundingBasis,
          identifier:
            dataToPrice === DataToPrice.Bonds
              ? this.tempBondIdentifier(row.temporary_pricing_identifier ?? '')
              : (row.temporary_pricing_identifier ?? ''),
          payment_frequency: paymentFrequency,
          tenors: levelsForPricing,
        };
        curves.push(curveForPricing);
      }

      return curves;
    }, []);
  }

  private addPricingData(
    data: Curve[],
    pricedDataList: PricedData[],
    pricingInfos: PricingInfo[],
    dataToPrice: DataToPrice,
  ): Curve[] {
    if (!pricedDataList.length) {
      return data;
    }
    data.map(curve => {
      pricedDataList.forEach((pricedData, index) => {
        const funding_basis: string = pricingInfos[index].funding_basis;
        const formatLevel = (level: LevelForPricing): PricedLevel => ({
          ...level,
          funding_basis,
        });
        if (dataToPrice === DataToPrice.Levels) {
          pricedData[curve.temporary_pricing_identifier ?? '']?.tenors?.forEach(pricedLevel =>
            (curve.levels[pricedLevel.tenor].priced ??= []).push(formatLevel(pricedLevel)),
          );
          pricedData[this.tempBondIdentifier(curve.temporary_pricing_identifier ?? '')]?.tenors?.forEach(pricedLevel =>
            (curve.bonds.find(bond => bond.tenor === pricedLevel.tenor)?.priced ?? []).push(formatLevel(pricedLevel)),
          );
        } else {
          pricedData[curve.temporary_pricing_identifier ?? '']?.tenors?.forEach(pricedLevel =>
            ((curve as any)[dataToPrice].priced ??= []).push(formatLevel(pricedLevel)),
          );
        }
      });
      delete curve.temporary_pricing_identifier;
      return curve;
    });
    return data;
  }

  price2(
    data: Curve[],
    pricingInfos: PricingInfo[] | null,
    dataToPrice = DataToPrice.Levels,
  ): Observable<{
    curves: Curve[];
    references?: { fra_pricer_references: PricerReference; non_fra_pricer_references: PricerReference };
  }> {
    if (!data?.length) {
      return of({ curves: [] });
    }
    data = JSON.parse(JSON.stringify(data));
    if (!pricingInfos?.length) {
      return of({ curves: this.deletePricingData(data, dataToPrice) });
    }

    let i = 1;
    data = data.map(row => ({ ...row, temporary_pricing_identifier: `row_${i++}` }));

    return this.stateService.get.constants$.pipe(
      mergeMap(constants => {
        const curvesForPricing = this.prepareCurvesForPricing2(data, constants, dataToPrice);
        if (dataToPrice === DataToPrice.Levels) {
          curvesForPricing.push(...this.prepareCurvesForPricing2(data, constants, DataToPrice.Bonds));
        }

        if (!curvesForPricing.length) {
          return of({ curves: data });
        }

        const pricingRequests = pricingInfos.map(pricingInfo => {
          const levelList = curvesForPricing.map(curve => {
            return {
              ...curve,
              target_funding_basis: pricingInfo.funding_basis,
              target_payment_frequency: pricingInfo.payment_frequency,
              target_day_count: pricingInfo.day_count,
            };
          });
          return this.getPricingData({
            levels_list: levelList as SingleLevelForPricing[],
          });
        });
        return forkJoin(pricingRequests).pipe(
          map(response => {
            return {
              curves: this.addPricingData2(
                data,
                response.map(pricedReference => pricedReference.priced),
                pricingInfos,
                dataToPrice,
              ),
            };
          }),
        );
      }),
    );
  }

  getPricingCurveIdentifier(curveType: string, maturity: string, idx?: number) {
    const maturityType = this.levelsUtilService.getMaturityTypeFromMaturity(maturity);

    const transformedMaturity =
      maturityType === MaturityType.CallableStructure
        ? maturity
        : `${this.levelsUtilService.getValueFromMaturity(maturity)}Y`;

    return `${curveType}|${transformedMaturity}|${idx ?? 0}`;
  }

  private prepareCurvesForPricing2(
    data: Curve[],
    constants: Constants,
    dataToPrice: DataToPrice,
  ): Partial<SingleLevelForPricing>[] {
    const swapOptionMapping: { [key: string]: any } = { ...constants.mappingFor.swap_options };
    const supportedFundingBases = Object.keys(swapOptionMapping);

    return data.reduce((curves: Partial<SingleLevelForPricing>[], curve, curveIndex) => {
      const fundingBasis = curve.funding_basis;
      const levelMap: Record<string, { spreadToPrice: string; isin?: string }[]> = {};

      switch (dataToPrice) {
        case DataToPrice.Levels:
          Object.entries(curve.levels).forEach(([maturity, level]) => {
            levelMap[maturity] = this.getLevelsToPriceFromCurve(level);
          });
          break;
        case DataToPrice.Bonds:
          if (curve.bonds) {
            curve.bonds.forEach(bond => {
              levelMap[bond.tenor] = this.getLevelsToPriceFromCurve(bond);
            });
          }
          break;
        case DataToPrice.BondYield:
          // TODO: Check where this is used and see if it works
          levelMap[(curve as any).tenor] = this.getLevelsToPriceFromCurve((curve as any)[dataToPrice]);
          break;
        default:
          console.error(`Pricing not implemented for ${dataToPrice}`);
      }
      if (!Object.keys(levelMap).length || !supportedFundingBases.includes(fundingBasis)) {
        return curves;
      }

      const isCurveMS = this.utilService.isMidSwap(fundingBasis);

      const paymentFrequency =
        (isCurveMS ? null : curve.payment_frequency) ??
        swapOptionMapping[fundingBasis].payment_frequency ??
        swapOptionMapping[fundingBasis].payment_freq;

      const dayCount =
        (isCurveMS ? null : curve.day_count) ??
        swapOptionMapping[fundingBasis].day_count ??
        swapOptionMapping[fundingBasis].daycount;

      if (paymentFrequency > 0) {
        Object.entries(levelMap).forEach(([maturity, levelArray]) => {
          levelArray.forEach((level, idx) => {
            let spread = level.spreadToPrice;
            if (curve.curve_type === CurveType.PRICING && this.utilService.isFixed(fundingBasis)) {
              spread = `${this.utilService.roundValue(Number(spread) * 100)}`;
            }
            curves.push({
              tenor: `${this.levelsUtilService.getValueFromMaturity(maturity)}Y`,
              spread,
              source_funding_basis: fundingBasis,
              source_payment_frequency: paymentFrequency,
              source_day_count: dayCount,
              collateral_currency: curve.collateral_currency,
              identifier: this.getPricingCurveIdentifier(
                level.isin ?? curve.temporary_pricing_identifier ?? curve.identifier ?? curveIndex,
                maturity,
                idx,
              ),
            });
          });
        });
      }

      return curves;
    }, []);
  }

  private addPricingData2(
    data: Curve[],
    pricedDataList: PricedData2[],
    pricingInfos: PricingInfo[],
    dataToPrice: DataToPrice,
  ): Curve[] {
    const getMaturityInYears = (maturity: string) => {
      // TODO: Does this exist?
      return `${this.levelsUtilService.getValueFromMaturity(maturity)}Y`;
    };
    if (!pricedDataList.length) {
      return data;
    }
    const formattedData: Record<string, Record<string, PricedLevel[]>> = {};
    pricedDataList.forEach((pricedData, idx) => {
      const funding_basis: string = pricingInfos[idx].funding_basis;
      Object.entries(pricedData).forEach(([identifier, pricedValues]) => {
        const [curveId, maturity] = identifier.split('|').slice(0, 2);
        const pricedLevel = {
          spread: pricedValues.spread,
          tenor: maturity,
          funding_basis,
        };
        if (!formattedData[curveId]) {
          formattedData[curveId] = {
            [maturity]: [pricedLevel],
          };
        } else if (!formattedData[curveId][maturity]) {
          formattedData[curveId][maturity] = [pricedLevel];
        } else {
          formattedData[curveId][maturity] = [
            {
              ...pricedLevel,
              spread: this.utilService.getRangeFromValues([
                formattedData[curveId][maturity][0].spread.toString(),
                pricedLevel.spread.toString(),
              ]),
            },
          ];
        }
      });
    });

    data.map(curve => {
      const pricedMaturitiesForCurve = formattedData[curve.temporary_pricing_identifier ?? curve.identifier] ?? [];
      if (dataToPrice === DataToPrice.Levels) {
        Object.keys(curve.levels).forEach(maturity => {
          const maturityValue = getMaturityInYears(maturity);
          curve.levels[maturity].priced = pricedMaturitiesForCurve[maturityValue] ?? [];
        });
        curve.bonds.forEach(bond => {
          bond.priced = formattedData[bond.isin]?.[getMaturityInYears(bond.tenor)] ?? [];
        });
      } else {
        (curve as any)[dataToPrice].priced = pricedMaturitiesForCurve[getMaturityInYears((curve as any).tenor)];
      }
      delete curve.temporary_pricing_identifier;
      return curve;
    });
    return data;
  }

  private deletePricingData(data: Curve[], dataToPrice: DataToPrice): Curve[] {
    data.map(curve => {
      switch (dataToPrice) {
        case DataToPrice.Levels:
          Object.keys(curve.levels).forEach(tenor => {
            delete curve.levels[tenor].priced;
          });
          (curve.bonds ?? []).forEach(bond => delete bond.priced);
          break;
        case DataToPrice.BondYield:
          delete (curve as any)[dataToPrice].priced;
          break;
        default:
          console.error(`Pricing not implemented for ${dataToPrice}`);
      }
      return curve;
    });
    return data;
  }

  private getBasisDayCount(basis: any, constants: ConstantsState): string {
    return constants.arrayFor.basis_metadata_mapping[basis].daycount;
  }

  private getBasisPaymentFrequency(basis: any, constants: ConstantsState): number {
    return constants.arrayFor.basis_metadata_mapping[basis].payment_freq;
  }

  private tempBondIdentifier(identifier: string): string {
    return `${identifier}_bonds`;
  }

  priceLevels(
    priceForm: PricerDebugForm,
    rowData: any[],
  ): Observable<Record<string, PricedDataResponseWithReferences>> {
    const endpoint = 'levels/price/';
    return this.stateService.get.constants$.pipe(
      mergeMap(constants => {
        const tenors: LevelForPricing[] = [];
        const tenorRow = rowData[0].values;
        const spreadRow = rowData[1].values;

        for (let i = 0; i < tenorRow.length; i++) {
          const tenor = tenorRow[i];
          const spread = spreadRow[i];
          if (tenor && spread) {
            tenors.push({ spread, tenor });
          }
        }

        if (!tenors.length) {
          return of({});
        }

        const fromBasisType = this.syndicatePricerUtilService.getBasisType(priceForm.fromBasis, constants);
        const convertToBPSCondition = [BasisType.Floating, BasisType.Govie].includes(fromBasisType);

        const curveForPricing: CurveForPricing[] = [
          {
            collateral_currency: priceForm.collateralCurrency,
            day_count: priceForm.fromDCB ?? this.getBasisDayCount(priceForm.fromBasis, constants),
            funding_basis: priceForm.fromBasis,
            identifier: 'pricer-debug',
            payment_frequency: priceForm.fromTenor ?? this.getBasisPaymentFrequency(priceForm.fromBasis, constants),
            tenors: tenors.map(({ tenor, spread }) => {
              return {
                tenor,
                spread: convertToBPSCondition ? spread : this.levelsUtilService.convertYieldToBps(spread),
              };
            }),
          },
        ];

        const responses: Observable<Record<string, any>>[] = priceForm.toSwapRows
          .filter(toSwapRow => toSwapRow.toBasis)
          .map(toSwapRow => {
            const data: DataForPricing = {
              levels_list: curveForPricing,
              day_count: toSwapRow.toDCB ?? this.getBasisDayCount(toSwapRow.toBasis, constants),
              funding_basis: toSwapRow.toBasis,
              payment_frequency: toSwapRow.toTenor ?? this.getBasisPaymentFrequency(toSwapRow.toBasis, constants),
            };
            return this.apiService.post(endpoint, data).pipe(
              map(response => ({
                key: toSwapRow.toBasis,
                value: response,
              })),
            );
          });

        return forkJoin(responses).pipe(
          map(results => {
            return results.reduce((acc, item) => {
              acc[item.key] = item.value;
              return acc;
            }, {});
          }),
        );
      }),
    );
  }

  changePricingBasis(initial?: string): Observable<string> {
    const key = 'funding_basis';
    return this.stateService.get.constants$.pipe(
      concatLatestFrom(() => [this.store.select(PricingCompletionSelector.pricingType)]),
      map(([constants, pricingType]) => {
        const couponType = pricingType.coupon_type.split(' ')[0].toLowerCase();
        const allowedTypes =
          couponType === BasisType.Floating ? [BasisType.Floating] : [BasisType.Govie, BasisType.Fixed, BasisType.MS];
        const fundingBasisOptions = constants.arrayFor.swap_options.filter(option => {
          return allowedTypes.includes(
            (constants.arrayFor.basis_metadata_mapping[option.value as any] as ConstantObject).type,
          );
        });
        return fundingBasisOptions;
      }),
      mergeMap(options => {
        const params: DynamicFormModalParams = {
          title: 'Pricing Basis',
          options: [
            {
              element: 'autocomplete',
              name: key,
              label: 'Pricing Basis',
              options,
              initial,
            },
          ],
        };
        return this.dialogService.dynamicFormModal(params);
      }),
      map((response: DynamicFormModalCloseEvent) => {
        return response?.formValue?.[key];
      }),
    );
  }

  getLevelsToPriceFromCurve(level: Partial<CurveLevel | CurveBond>) {
    let levelArray: { spreadToPrice: string; isin?: string }[] = [];
    if (!(<CurveLevel>level).is_re) {
      let totalPricing;

      if ((<CurveLevel>level).total) {
        totalPricing = (<CurveLevel>level).total;
      } else if ((<CurveLevel>level).new_issue_premium) {
        const valuesToAggregate = [(<CurveLevel>level).new_issue_premium as string];
        if ((<CurveLevel>level).spread) {
          valuesToAggregate.push((<CurveLevel>level).spread as string);
        }
        totalPricing = this.utilService.getValueAggregate(valuesToAggregate);
      } else {
        totalPricing = level.spread;
      }

      if (totalPricing) {
        levelArray = (this.utilService.getValuesFromRange(totalPricing) ?? [])?.map(spread => {
          return {
            spreadToPrice: spread.slice(0, spread.indexOf('.') + 6),
            ...((<CurveBond>level).isin ? { isin: (<CurveBond>level).isin } : {}),
          };
        });
      }
    }

    return levelArray;
  }
}
