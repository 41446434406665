import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionMenuEvent } from '@morpho/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

const contactColumnKey = [
  'name',
  'full_name',
  'team',
  'team_type',
  'position',
  'desk_location',
  'location',
  'email',
  'phone',
  'mobile',
  'actions',
] as const;

export type ContactColumnKey = (typeof contactColumnKey)[number];

export type ContactColumnLabelMap = {
  [key in ContactColumnKey]: string;
};
@Component({
  standalone: false,
  selector: 'om-contacts-table',
  templateUrl: './contacts-table.component.html',
})
export class ContactsTableComponent implements OnInit {
  @Input()
  set data$(data$: Observable<any[]>) {
    this._data$ = data$;
  }
  get data$(): Observable<any[]> {
    return this._data$;
  }
  private _data$: Observable<any[]>;

  @Input()
  set columns$(columns$: Observable<string[]>) {
    this._columns$ = columns$;
  }
  get columns$(): Observable<string[]> {
    return this._columns$;
  }
  private _columns$: Observable<string[]>;

  @Output() actionSelected = new EventEmitter<ActionMenuEvent>();

  readonly columnLabels: ContactColumnLabelMap = {
    name: 'Name',
    full_name: 'Name',
    team: 'Team',
    team_type: 'Team',
    position: 'Role',
    desk_location: 'Location',
    location: 'Location',
    email: 'Email',
    phone: 'Phone',
    mobile: 'Mobile',
    actions: 'Actions',
  };

  columnDefinitions$: Observable<{ label: string; val: string }[]>;

  ngOnInit(): void {
    this.columnDefinitions$ = combineLatest([this.data$, this.columns$]).pipe(
      filter(([data, columns]) => !!data?.length && !!columns?.length),
      map(([_, columns]) => {
        return columns.map((column: string) => {
          return {
            label: this.columnLabels[column as keyof ContactColumnLabelMap],
            val: column,
          };
        });
      }),
    );
  }

  onRowActionSelected(event: ActionMenuEvent): void {
    this.actionSelected.emit(event);
  }
}
