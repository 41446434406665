import { Deque } from './deque';

export class OmCache<T, X> {
  private keyPriority: Deque<T>;
  private map: Map<T, X>;
  private capacity: number;

  constructor(capacity = 50) {
    this.keyPriority = new Deque<T>();
    this.map = new Map<T, X>();
    this.capacity = capacity;
  }

  add(key: T, item: X): void {
    if (this.isFull()) {
      this.pop();
    }
    this.keyPriority.push(key);
    this.map.set(key, item);
  }

  isFull(): boolean {
    return this.keyPriority.size() >= this.capacity;
  }

  get(key: T): X | undefined {
    return this.map.get(key);
  }

  size(): number {
    return this.keyPriority.size();
  }

  private pop(): void {
    const keyToRemove = this.keyPriority.popLeft();
    this.map.delete(keyToRemove);
  }
}
