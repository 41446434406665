<h2 mat-dialog-title>
  Rationale
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h2>
<div mat-dialog-content>
  @if (pricingRationaleForm && sharingFormModel) {
    <table class="is-new-style" [formGroup]="pricingRationaleForm">
      <thead>
        <tr>
          <th>Pricing Tab</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        @for (row of rows; track row) {
          <tr>
            <td>{{ row.label }}</td>
            <td>
              <mat-form-field class="is-inline">
                <textarea matInput [formControlName]="row.value"></textarea>
              </mat-form-field>
            </td>
          </tr>
        }
      </tbody>
    </table>
    <om-dynamic-form [model]="sharingFormModel"></om-dynamic-form>
  } @else {
    <om-loading></om-loading>
  }
</div>
<div class="mat-mdc-dialog-actions">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="!(pricingRationaleForm && sharingFormModel)" (click)="submit()">
    Submit
    @if (isLoading) {
      <mat-spinner></mat-spinner>
    }
  </button>
</div>
