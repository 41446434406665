import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-progress-indicator',
  templateUrl: './progress-indicator.component.html',
})
export class ProgressIndicatorComponent implements OnInit {
  @HostBinding('class.om-progress-indicator-100') isComplete = false;
  @HostBinding('style.height') height: string;
  @HostBinding('style.width') width: string;
  @HostBinding('style.font-size') fontSize: string;

  @Input()
  get progress(): number {
    return this._progress;
  }
  set progress(val: number) {
    val = val > 100 ? 100 : val;
    val = val < 0 ? 0 : val;
    this._progress = Math.floor(val);
    this.isComplete = val === 100;
  }
  private _progress: number;

  @Input()
  get diameter(): number {
    return this._diameter;
  }
  set diameter(val: number) {
    this._diameter = val;
    this.setDimensions();
  }
  private _diameter = 150;

  @Input()
  get showNumber(): boolean {
    return this._showNumber;
  }
  set showNumber(val: boolean) {
    this._showNumber = coerceBooleanProperty(val);
  }
  private _showNumber: boolean;

  @Input()
  get showFullProgressCircle(): boolean {
    return this._showFullProgressCircle;
  }
  set showFullProgressCircle(showFullProgressCircle: boolean) {
    this._showFullProgressCircle = coerceBooleanProperty(showFullProgressCircle);
  }
  private _showFullProgressCircle: boolean;

  constructor() {
    this.setDimensions();
  }

  ngOnInit() {
    if (this._progress === 100) {
      this.isComplete = true;
    }
  }

  setDimensions() {
    const diameterPx = `${this._diameter}px`;
    this.height = diameterPx;
    this.width = diameterPx;
    this.fontSize = diameterPx;
  }
}
