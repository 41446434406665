import { Injectable } from '@angular/core';
import {
  BACKEND_DATE_FORMAT,
  BasisType,
  DEFAULT_QUOTE_TYPE,
  LevelsUtilService,
  NipMode,
  PRICING_INBOX_URL,
  PopupService,
  PricingUnits,
  QuoteType,
  RouterSelector,
  RoutingService,
  TypedStateAction,
  UtilService,
} from '@morpho/core';
import { DialogService } from '@morpho/dialog';
import { DynamicFormModalCloseEvent } from '@morpho/dynamic-form';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { sortByAlphabetical } from 'apps/bankangle/src/app/constants/sortComparators';
import { RatingsService } from 'apps/bankangle/src/app/core/patterns/ratings/ratings.service';
import { CoreApiService } from 'apps/bankangle/src/app/core/services/core-api.service';
import { StateService } from 'apps/bankangle/src/app/core/services/state.service';
import { Constants } from 'apps/bankangle/src/app/models/constants';
import { SavedView, SavedViewIdentifier } from 'apps/bankangle/src/app/models/saved-view';
import * as moment from 'moment-timezone';
import {
  EMPTY,
  Observable,
  catchError,
  concatMap,
  debounceTime,
  filter,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { PricingBreakDownType, PricingBreakdownEditableRows } from '../../constants/breakdown';
import { BondPricing, BondPricingParams, SecondaryBond } from '../../models/comparable.model';
import { IssuingEntityRequests } from '../../models/pricing-inbox.model';
import {
  AdditionalCurve,
  ComparablesInformation,
  CurveLevel,
  HISTORICAL_IHS,
  HISTORICAL_PRICING,
  NewIssueCurveType,
  OVERVIEW_TAB,
  PRICING,
  PricingData,
  PricingRequestCreation,
  PricingRequestParams,
  PricingRequestResponse,
  PricingType,
  REFERENCE_PRICING,
  SecondaryLevels,
} from '../../models/pricing-request.model';
import { SecondaryBondFilters, SecondaryBondsBody } from '../../models/secondaries.model';
import { NewIssuePricingRowsService } from '../../services/new-issue-pricing-rows.service';
import {
  MaturityRowPricingParams,
  PricingCompletionService,
  RowPricingParams,
} from '../../services/pricing-completion.service';
import { SyndicatePricerApiService } from '../../services/syndicate-pricer-api.service';
import { SyndicatePricerSelectorService } from '../../services/syndicate-pricer-selector.service';
import { SyndicatePricerUtilService } from '../../services/syndicate-pricer-util.service';
import {
  getAdditionalPricingCurvesByType,
  getDisplayMaturitiesForTab,
  getPricingBasisForTab,
} from '../../syndicate-pricer.functions';
import {
  PricingCompletionAction,
  PricingCompletionEffect,
  RemovePricingRequestsParams,
} from './pricing-completion.actions';
import {
  IssuerPricingDataMap,
  Maturities,
  PricingCompletionPageSettings,
  PricingTabDataMap,
  SharedTabDataMap,
  SubmitPricingDetails,
} from './pricing-completion.model';
import { initialPageSettings } from './pricing-completion.reducer';
import { PricingCompletionSelector } from './pricing-completion.selectors';

@Injectable()
export class PricingCompletionEffects {
  getTabData: (tabName: string) => Observable<IssuerPricingDataMap | undefined> = (tabName: string) => {
    return this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(
      filter(dataMap => !!dataMap),
      map(dataMap => dataMap?.[tabName]),
    );
  };

  getActionTabData = (action: TypedStateAction<{ tab: string }>): Observable<IssuerPricingDataMap | undefined> => {
    return this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(
      map((dataMap: PricingTabDataMap | undefined): IssuerPricingDataMap | undefined => dataMap?.[action.params.tab]),
      filter(tabData => !!tabData),
      take(1),
    );
  };

  getActionTabPricingBasis = (action: TypedStateAction<{ tab: string }>): Observable<string> => {
    return this.getActionTabData(action).pipe(
      map((data: IssuerPricingDataMap): string => getPricingBasisForTab(data)),
      take(1),
    );
  };

  setSelectedTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_SELECTED_TAB),
      map(() => PricingCompletionAction.redrawGraph()),
    ),
  );

  getPricingInboxGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_PRICING_INBOX_LIST),
      tap(() => this.store.dispatch(PricingCompletionAction.addProcessToLoadingStack())),
      switchMap(() => this.syndicatePricerApiService.getIssuingEntityGroups()),
      map(pricingInboxResponse => {
        const pricingInboxGroups = pricingInboxResponse
          .map(group => {
            const entityMap = group.issuing_entities.reduce(
              (acc: Record<number, IssuingEntityRequests>, issuingEntity) => {
                acc[issuingEntity.id] = {
                  ...issuingEntity,
                  pricing_requests: [],
                };
                return acc;
              },
              {},
            );

            group.pricing_requests.forEach(request =>
              entityMap[request.issuing_entity.id].pricing_requests.push(request),
            );

            const entities = Object.values(entityMap).sort((a, b) => sortByAlphabetical(a.name, b.name));

            return {
              id: group.id,
              name: group.name,
              issuing_entities: entities,
            };
          })
          .sort((a, b) => {
            if (a.id == null) {
              return 1;
            }
            if (b.id == null) {
              return -1;
            }
            return sortByAlphabetical(a.name, b.name);
          });

        return pricingInboxGroups;
      }),
      map(pricingInboxGroups =>
        PricingCompletionAction.getPricingInboxGroupsSuccess({
          params: { pricingInboxGroups },
        }),
      ),
      tap(() => this.store.dispatch(PricingCompletionAction.removeProcessFromLoadingStack())),
    ),
  );

  addPricingInboxGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.ADD_PRICING_INBOX_GROUP),
      switchMap(() => this.pricingCompletionService.addPricingInboxGroupDialog().pipe(filter(response => !!response))),
      switchMap((res: DynamicFormModalCloseEvent) =>
        this.syndicatePricerApiService.addIssuingEntityGroup(
          res?.formValue as {
            groupName: string;
            issuingEntities: number[];
            pricingDesk: number;
          },
        ),
      ),
      map(() => PricingCompletionAction.addPricingInboxGroupSuccess()),
      tap(() => this.store.dispatch(PricingCompletionAction.getPricingInboxGroups())),
    ),
  );

  deletePricingInboxGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.DELETE_PRICING_INBOX_GROUP),
      switchMap((action: TypedStateAction<{ groupId: number }>) =>
        this.syndicatePricerApiService.deleteIssuingEntityGroup(action.params.groupId),
      ),
      switchMap(() => [
        PricingCompletionAction.deletePricingInboxGroupSuccess(),
        PricingCompletionAction.getPricingInboxGroups(),
      ]),
    ),
  );

  editPricingInboxGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.EDIT_PRICING_INBOX_GROUP),
      switchMap((action: TypedStateAction<{ groupId: number }>) =>
        forkJoin([
          of(action),
          this.pricingCompletionService
            .editPricingInboxGroupDialog(action.params.groupId)
            .pipe(filter(response => !!response)),
        ]),
      ),
      switchMap(([action, res]: [TypedStateAction<{ groupId: number }>, DynamicFormModalCloseEvent]) =>
        this.syndicatePricerApiService.updateIssuingEntityGroup(
          action.params.groupId,
          res?.formValue as {
            groupName: string;
            issuingEntities: number[];
            pricingDesk: number;
          },
        ),
      ),
      map(() => PricingCompletionAction.editPricingInboxGroupSuccess()),
      tap(() => this.store.dispatch(PricingCompletionAction.getPricingInboxGroups())),
    ),
  );

  removePricingRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.REMOVE_PRICING_REQUESTS),
      concatLatestFrom(() => this.stateService.get.constants$),
      switchMap(([action, constants]: [TypedStateAction<RemovePricingRequestsParams>, Constants]) => {
        const pricingRequestIdSet = new Set<number>();
        const tabLabels = new Set();
        action.params.requests.forEach(request => {
          if (!request.id) {
            return;
          }
          pricingRequestIdSet.add(request.id);
          const tab = this.syndicatePricerUtilService.generatePricingTabFromObject(request, constants);
          tabLabels.add(tab.label);
        });
        const requestIds = [...pricingRequestIdSet];
        const tabsText = [...tabLabels].join(', ');
        return this.dialogService
          .booleanPopup({
            title: `Remove ${tabsText}?`,
            message: `This will remove the ${action.params.label.toLowerCase()} from this worksheet and inbox. Any historical pricing data will be retained and can be accessed from your inbox via view pricing. You can request this pricing again at any time.`,
            right_action: {
              text: 'Remove',
              class: 'is-warning',
            },
          })
          .pipe(
            switchMap((response: any) => {
              if (!response) {
                return EMPTY;
              }
              return of(null);
            }),
            switchMap(() => {
              return this.syndicatePricerApiService.removePricingRequests(requestIds);
            }),
            map(() => {
              return PricingCompletionAction.removePricingRequestsSuccess({
                params: { requestIds, tabName: action.params.tabName },
              });
            }),
          );
      }),
    ),
  );

  priceBonds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_PRICING_BASIS_FOR_TAB_SUCCESS),
      concatLatestFrom(() => [
        this.store.select(PricingCompletionSelector.selectedTab),
        this.store.select(PricingCompletionSelector.secondaryBonds),
        this.store.select(PricingCompletionSelector.selectedHistoricalDates),
      ]),
      // todo: reprice bonds on tab change
      switchMap(([, tab, secondaryBonds, selectedHistoricalDates]) =>
        tab
          ? [
              ...(secondaryBonds?.length
                ? [PricingCompletionAction.getSecondaryBondsPriced({ params: { tab, bonds: secondaryBonds } })]
                : []),
              ...(selectedHistoricalDates?.length
                ? [
                    // TODO: FIX THIS once side panel is sorted
                    PricingCompletionAction.getHistoricalSecondaryBonds({
                      params: {
                        tab,
                        historicalSecondaryBondsDates: [
                          ...new Set<string>(Object.values(selectedHistoricalDates).flat()),
                        ],
                      },
                    }),
                  ]
                : []),
            ]
          : [],
      ),
    ),
  );

  getHistoricalPricingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_HISTORICAL_PRICING_LIST),
      tap(() => this.store.dispatch(PricingCompletionAction.addProcessToLoadingStack())),
      mergeMap((action: TypedStateAction<{ tab: string; issuingEntityId: string; pricingRequestId: number }>) =>
        this.syndicatePricerApiService.getHistoricalPricingRequestListById(action.params.pricingRequestId).pipe(
          map(historicalData => {
            return PricingCompletionAction.getHistoricalPricingListSuccess({
              params: {
                tab: action.params.tab,
                issuingEntityId: action.params.issuingEntityId,
                historicalDates: historicalData.map(submission => moment(submission.completed_at).preciseUTCFormat()),
              },
            });
          }),
        ),
      ),
      tap(() => this.store.dispatch(PricingCompletionAction.removeProcessFromLoadingStack())),
    ),
  );

  getHistoricalPricingRequestData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_HISTORICAL_PRICING_REQUEST_DATA),
      tap(() => this.store.dispatch(PricingCompletionAction.addProcessToLoadingStack())),
      concatLatestFrom(() => this.stateService.get.constants$),
      mergeMap(
        ([action, constants]: [
          TypedStateAction<{ tab: string; pricingRequestParams: PricingRequestParams }>,
          Constants,
        ]) => {
          const pricingType = this.syndicatePricerUtilService.generatePricingTypeFromTabName(
            action.params.tab,
            constants,
          );
          return this.syndicatePricerApiService.getPricingRequestData(action.params.pricingRequestParams).pipe(
            map(historicalPricingRequests => {
              return historicalPricingRequests
                .filter(pricingRequest => {
                  return (
                    pricingRequest.coupon_type === pricingType.coupon_type &&
                    pricingRequest.seniority === pricingType.seniority &&
                    pricingRequest.currency === pricingType.currency
                  );
                })
                .reduce((acc: Record<string, Record<string, PricingRequestResponse>>, curr) => {
                  acc[`${curr.issuing_entity.id}`] = {
                    ...acc[curr.issuing_entity.id],
                    [moment(curr.pricing.completed_at).preciseUTCFormat() ?? '']: curr,
                  };
                  return acc;
                }, {});
            }),
            map(historicalPricingRequestsData => {
              return PricingCompletionAction.getHistoricalPricingRequestDataSuccess({
                params: { tab: action.params.tab, historicalPricingRequestsData },
              });
            }),
          );
        },
      ),
      tap(() => this.store.dispatch(PricingCompletionAction.removeProcessFromLoadingStack())),
    ),
  );

  setQuoteType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_QUOTE_TYPE),
      map(() => PricingCompletionAction.refreshGrid()),
    ),
  );

  setPricingBreakdownType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_PRICING_BREAKDOWN),
      concatLatestFrom(() => [this.store.select(PricingCompletionSelector.pricingTabDataMap)]),
      map(
        ([action, pricingTabDataMap]: [
          TypedStateAction<{ tabName: string; pricingBreakdown: PricingBreakDownType }>,
          PricingTabDataMap,
        ]) => {
          const data = getDisplayMaturitiesForTab(action.params.tabName, pricingTabDataMap).reduce(
            (data: Maturities, maturity) => {
              data[maturity] = '';
              return data;
            },
            {},
          );
          const levels = PricingBreakdownEditableRows[action.params.pricingBreakdown].reduce(
            (newLevels: CurveLevel[], curveType: NewIssueCurveType) => {
              newLevels.push({
                key: curveType,
                data,
              });
              return newLevels;
            },
            [],
          );
          return PricingCompletionAction.setPricingBreakdownSuccess({
            params: { ...action.params, levels },
          });
        },
      ),
    ),
  );

  combineSecondaryBondsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.ON_VISIT_PRICING_TAB, PricingCompletionEffect.GET_COMBINED_FILTERS),
      switchMap((action: TypedStateAction<{ tabName: string } | undefined>) => {
        return action.params?.tabName
          ? of(action.params.tabName)
          : this.store.select(PricingCompletionSelector.selectedTab);
      }),
      switchMap((tabName: string) => {
        return this.store.select(PricingCompletionSelector.sharedTabDataMap).pipe(
          take(1),
          map(sharedTabDataMap => [sharedTabDataMap?.[tabName]?.combinedFilters, tabName]),
        );
      }),
      mergeMap(([filters, tabName]) => {
        if (filters) {
          return of(EMPTY);
        } else {
          return of(tabName);
        }
      }),
      mergeMap((tabName: string) => {
        return this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(
          filter(pricingTabDataMap => !!Object.keys(pricingTabDataMap ?? {}).length),
          take(1),
          map(pricingTabDataMap => pricingTabDataMap?.[tabName]),
          switchMap((currentTabData: IssuerPricingDataMap) => {
            const pricingRequestIds: number[] = [];
            const filtersArray: SecondaryBondFilters[] = [];
            Object.values(currentTabData ?? {}).forEach(data => {
              pricingRequestIds.push(data.pricingRequest.id as number);
              let filters: SecondaryBondFilters = {};
              if (data.pricingRequest.filters && Object.keys(data.pricingRequest.filters).length) {
                filters = data.pricingRequest.filters;
              } else {
                const pricingType: PricingType = { ...data.pricingRequest };
                filters = this.syndicatePricerUtilService.generateDefaultSecondaryBondsFilter(pricingType);
              }
              filtersArray.push(filters);
            });

            const filter = filtersArray[0];

            for (let i = 1; i < filtersArray.length; i += 1) {
              const areEqual = this.utilService.areObjectsEquivalent(filter, filtersArray[i]);
              if (!areEqual) {
                return this.syndicatePricerApiService.getCombinedSecondaryFilters(pricingRequestIds);
              }
            }
            return of(filter);
          }),
          map(combinedFilters => {
            return [tabName, combinedFilters];
          }),
        );
      }),
      map(([tabName, combinedFilters]: [string, SecondaryBondFilters]) => {
        return PricingCompletionAction.setCombinedFilters({ params: { tabName, filters: combinedFilters } });
      }),
    ),
  );

  getSecondaryBonds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_SECONDARY_BONDS),
      switchMap(action => {
        return forkJoin([
          of(action),
          this.store.select(PricingCompletionSelector.comparables).pipe(take(1)),
          this.store.select(PricingCompletionSelector.sharedTabDataMap).pipe(take(1)),
        ]);
      }),
      mergeMap(
        ([action, comparables, sharedTabDataMap]: [
          TypedStateAction<{
            tabName: string;
            individualComparables?: Partial<SecondaryBondsBody>;
            initialiseBondsSelected: boolean;
          }>,
          ComparablesInformation,
          SharedTabDataMap,
        ]) => {
          const body: SecondaryBondsBody = {
            ...sharedTabDataMap[action.params.tabName].combinedFilters,
            ...comparables,
          };

          return this.syndicatePricerApiService.getIndividualBonds(body).pipe(
            switchMap((bonds: SecondaryBond[]) => this.ratingsService.addRatingsToArray(bonds)),
            concatLatestFrom(() => [
              this.syndicatePricerSelectorService.secondaryBonds$,
              this.syndicatePricerSelectorService.secondaryLevels$,
              this.store.select(PricingCompletionSelector.selectedBonds),
            ]),
            switchMap(
              ([bonds, savedSecondaryBonds, savedSecondaryLevels, selectedBonds]: [
                SecondaryBond[],
                SecondaryBond[] | undefined,
                SecondaryLevels | undefined,
                Set<string> | undefined,
              ]) => {
                const bondsMap = this.utilService.convertArrayToMap(bonds, 'isin');
                const uniqueBonds = Object.values(bondsMap);
                // TODO: When the backend supports it, we should check !savedSecondaryLevels instead of !includedIsins
                const initialiseBondsSelected = action.params.initialiseBondsSelected || !selectedBonds;

                const secondaryLevels: SecondaryLevels = JSON.parse(JSON.stringify(savedSecondaryLevels ?? {}));

                Object.keys(secondaryLevels).forEach(isin => {
                  if (!bondsMap[isin]) {
                    delete secondaryLevels[isin];
                  }
                });

                if (initialiseBondsSelected) {
                  const savedSecondaryBondIsins: string[] = (savedSecondaryBonds ?? []).map(bond => bond.isin);

                  Object.keys(bondsMap).forEach(isin => {
                    if (!savedSecondaryBondIsins.includes(isin)) {
                      secondaryLevels[isin] = {};
                    }
                  });
                }
                const includedIsins = new Set<string>(
                  Object.entries(secondaryLevels)
                    .filter(([_, levels]) => !Object.keys(levels).length || levels.ihs)
                    .map(([isin]) => isin),
                );
                return [
                  PricingCompletionAction.getSecondaryBondsSuccess({
                    params: {
                      ...action.params,
                      bonds: uniqueBonds,
                    },
                  }),
                  PricingCompletionAction.priceSecondaryBonds({
                    params: {
                      ...action.params,
                      bonds: uniqueBonds,
                      repriceBonds: !savedSecondaryBonds,
                      pricePricingBasis: true,
                    },
                  }),
                  PricingCompletionAction.updateSecondaryLevels({
                    params: {
                      tabName: action.params.tabName,
                      secondaryLevels,
                    },
                  }),
                  // TODO: Remove once fully transitioned to secondary levels
                  PricingCompletionAction.updateIncludedIsins({
                    params: {
                      tab: action.params.tabName,
                      includedIsins,
                    },
                  }),
                ];
              },
            ),
          );
        },
      ),
    ),
  );

  priceSecondaryBonds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.PRICE_SECONDARY_BONDS),
      concatLatestFrom(
        (
          action: TypedStateAction<{
            tabName: string;
            bonds?: SecondaryBond[];
            repriceBonds?: boolean;
            pricePricingBasis?: boolean;
          }>,
        ) => [
          this.store.select(PricingCompletionSelector.secondaryBonds),
          this.getTabData(action.params.tabName),
          this.store.select(PricingCompletionSelector.quoteType),
        ],
      ),
      mergeMap(([action, secondaryBonds, issuerPricingDataMap, quoteType]) => {
        const pricingBasis = getPricingBasisForTab(issuerPricingDataMap ?? {});
        const swapBases = getAdditionalPricingCurvesByType(NewIssueCurveType.Swap, issuerPricingDataMap ?? {}).map(
          curve => curve.value,
        );
        const bonds = action.params.bonds ?? secondaryBonds;
        if (!bonds?.length || !pricingBasis) {
          return [
            PricingCompletionAction.updateSecondaryLevels({
              params: { tabName: action.params.tabName, secondaryLevels: {}, unsavedSecondaryLevels: {} },
            }),
          ];
        }
        return this.pricingCompletionService
          .priceBonds({
            tab: action.params.tabName,
            secondaryBonds: bonds,
            pricingBasis,
            pricePricingBasis: action.params.pricePricingBasis,
            swapBases,
            repriceBonds: action.params.repriceBonds,
          })
          .pipe(
            concatLatestFrom(() => [
              this.syndicatePricerSelectorService.secondaryLevels$,
              this.syndicatePricerSelectorService.unsavedSecondaryLevels$,
              this.syndicatePricerSelectorService.selectedBonds$,
            ]),
            switchMap(
              ([pricedBonds, secLevels, unsavedSecLevels, selectedBonds]: [
                SecondaryLevels,
                SecondaryLevels,
                SecondaryLevels,
                Set<string> | undefined,
              ]) => {
                if (!pricedBonds) {
                  return [];
                }
                const secondaryLevels = JSON.parse(JSON.stringify(secLevels));
                const unsavedSecondaryLevels = JSON.parse(JSON.stringify(unsavedSecLevels));
                const currentQuoteType = quoteType ?? DEFAULT_QUOTE_TYPE;

                let savePricing = false;
                bonds.forEach(bond => {
                  const isin = bond.isin;
                  if (!pricedBonds[isin]) {
                    return;
                  }
                  const isBondSelected = selectedBonds?.has(isin);
                  const levels: BondPricing = {};
                  Object.entries(pricedBonds[isin]).forEach(([key, value]) => {
                    levels[key] = `${value}`;
                  });
                  if (isBondSelected) {
                    const currentLevel = secondaryLevels[isin];
                    if (!currentLevel || !currentLevel.price || !currentLevel.yield || !currentLevel.size) {
                      savePricing = true;
                      levels.price = this.syndicatePricerUtilService.getPriceValue(currentQuoteType, bond);
                      levels.yield = this.syndicatePricerUtilService.getYieldValue(currentQuoteType, bond);
                      levels.size = bond.size;
                    }

                    secondaryLevels[isin] = {
                      ...secondaryLevels[isin],
                      ...levels,
                    };
                  } else {
                    unsavedSecondaryLevels[isin] = {
                      ...unsavedSecondaryLevels[isin],
                      ...levels,
                    };
                  }
                });

                // TODO: Do we remove isin if there is no pricing? How would this work if it doesn't get priced but then pricing is fine on next load (Wouldn't count as selected)
                // TODO: bring back included isin to help simplify logic and update secondary levels calls

                Object.entries(secondaryLevels).forEach(([isin, levels]) => {
                  if (!levels) {
                    delete secondaryLevels[isin];
                  }
                });

                return [
                  PricingCompletionAction.updateSecondaryLevels({
                    params: {
                      tabName: action.params.tabName,
                      secondaryLevels,
                      unsavedSecondaryLevels,
                    },
                  }),
                  ...(savePricing ? [PricingCompletionAction.savePricing()] : []),
                ];
              },
            ),
          );
      }),
    ),
  );

  getHistoricalSecondaryBonds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_HISTORICAL_SECONDARY_BONDS),
      switchMap(action => {
        return forkJoin([
          of(action),
          this.store.select(PricingCompletionSelector.comparables).pipe(take(1)),
          this.store.select(PricingCompletionSelector.selectedBonds).pipe(take(1)),
          this.store.select(PricingCompletionSelector.sharedTabDataMap).pipe(take(1)),
          this.store.select(PricingCompletionSelector.isViewMode).pipe(take(1)),
        ]);
      }),
      mergeMap(
        ([action, comparables, selectedBonds, sharedTabDataMap, isViewMode]: [
          TypedStateAction<{
            tab: string;
            historicalSecondaryBondsDates: string[];
            overrideSecondaries?: boolean;
          }>,
          ComparablesInformation,
          Set<string>,
          SharedTabDataMap,
          boolean,
        ]) => {
          const baseParams = {
            ...action.params,
          };

          if (!action.params.historicalSecondaryBondsDates.length) {
            this.store.dispatch(
              PricingCompletionAction.getHistoricalSecondaryBondsPricedSuccess({
                params: {
                  tab: action.params.tab,
                  secondaryPricedKeys: {},
                },
              }),
            );
            // todo: can we just return empty?
            return of({
              historicalSecondaryBonds: [],
              ...baseParams,
            });
          }

          const historicalSecondaryBonds = action.params.historicalSecondaryBondsDates.map(date => {
            return this.syndicatePricerApiService.getIndividualBonds({
              as_of: date,
              ...(isViewMode
                ? {
                    comparable_isins: [...(selectedBonds ?? [])],
                  }
                : {
                    ...(sharedTabDataMap?.[action.params.tab]?.combinedFilters ?? []),
                    comparable_criteria: comparables.comparable_criteria,
                    comparable_isins: comparables.comparable_isins,
                  }),
            });
          });

          return forkJoin(historicalSecondaryBonds).pipe(
            concatMap(historicalSecondaryBonds =>
              of({
                historicalSecondaryBonds,
                ...baseParams,
              }),
            ),
          );
        },
      ),
      map(params => {
        return PricingCompletionAction.getHistoricalSecondaryBondsPriced({
          params,
        });
      }),
    ),
  );

  getHistoricalSecondaryBondsPriced$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_HISTORICAL_SECONDARY_BONDS_PRICED),
      mergeMap(
        (
          action: TypedStateAction<{
            tab: string;
            historicalSecondaryBondsDates: string[];
            historicalSecondaryBonds: SecondaryBond[][];
            overrideSecondaries: boolean;
          }>,
        ) =>
          this.getActionTabPricingBasis(action).pipe(
            mergeMap((pricingBasis: string) => {
              if (action.params.overrideSecondaries) {
                const bondsMap = this.utilService.convertArrayToMap(action.params.historicalSecondaryBonds[0], 'isin');
                const uniqueBonds = Object.values(bondsMap);

                this.store.dispatch(
                  PricingCompletionAction.getSecondaryBondsSuccess({
                    params: { tabName: action.params.tab, bonds: uniqueBonds },
                  }),
                );
                this.store.dispatch(
                  PricingCompletionAction.priceSecondaryBonds({
                    params: { tabName: action.params.tab, bonds: uniqueBonds, pricePricingBasis: false },
                  }),
                );
              }
              const pricingParams: BondPricingParams[] = [];
              action.params.historicalSecondaryBondsDates.forEach((date, idx) => {
                pricingParams.push({
                  tab: action.params.tab,
                  secondaryBonds: action.params.historicalSecondaryBonds[idx],
                  pricingBasis,
                  pricePricingBasis: true,
                  historicalSecondaryBondsDate: date,
                  repriceBonds: true,
                });
              });

              const historicalDataForPricing$ = pricingParams.map(params => {
                return this.pricingCompletionService.priceBonds(params);
              });

              return forkJoin(historicalDataForPricing$);
            }),
            concatLatestFrom(() => [this.syndicatePricerSelectorService.unsavedSecondaryLevels$]),
            switchMap(([pricedData, unsavedSecLevels]: [SecondaryLevels[], SecondaryLevels]) => {
              const unsavedSecondaryLevels = JSON.parse(JSON.stringify(unsavedSecLevels));
              const secondaryPricedKeys: Record<string, boolean> = {};

              pricedData.forEach(dataArray => {
                Object.entries(dataArray ?? {}).forEach(([isin, historicalData]) => {
                  if (isin) {
                    Object.entries(historicalData).forEach(([pricedKey, pricing]) => {
                      secondaryPricedKeys[pricedKey] = true;

                      unsavedSecondaryLevels[isin] = {
                        ...unsavedSecondaryLevels[isin],
                        [pricedKey]: pricing,
                      };
                    });
                  }
                });
              });

              return [
                PricingCompletionAction.getHistoricalSecondaryBondsPricedSuccess({
                  params: {
                    ...action.params,
                    secondaryPricedKeys,
                  },
                }),
                PricingCompletionAction.updateSecondaryLevels({
                  params: {
                    tabName: action.params.tab,
                    unsavedSecondaryLevels,
                  },
                }),
              ];
            }),
          ),
      ),
    ),
  );

  setSecondaryBondsRegression$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_SECONDARY_BONDS_REGRESSION),
      switchMap(() => [PricingCompletionAction.refreshGrid(), PricingCompletionAction.redrawGraph()]),
    ),
  );

  getGovieBonds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_GOVIE_BONDS),
      tap(() => this.store.dispatch(PricingCompletionAction.addProcessToLoadingStack())),
      mergeMap((action: TypedStateAction<{ pricingBasis: string }>) => {
        return this.syndicatePricerApiService.getGovieBonds([action.params.pricingBasis]).pipe(
          map(bonds =>
            PricingCompletionAction.getGovieBondsSuccess({
              params: {
                pricingBasis: action.params.pricingBasis,
                bonds,
              },
            }),
          ),
        );
      }),
      tap(() => this.store.dispatch(PricingCompletionAction.refreshGrid())),
      tap(() => this.store.dispatch(PricingCompletionAction.removeProcessFromLoadingStack())),
    ),
  );

  savePricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SAVE_PRICING),
      concatLatestFrom(() => this.store.select(PricingCompletionSelector.mode)),
      switchMap(([action, mode]) => {
        if (mode !== NipMode.Input) {
          return EMPTY;
        } else {
          return of(action);
        }
      }),
      tap(() => {
        this.store.dispatch(PricingCompletionAction.updateSavingStatus({ params: { isSaving: true } }));
      }),
      debounceTime(2000),
      switchMap(action => this.savePricing(action, false)),
    ),
  );

  submitPricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SUBMIT_PRICING),
      tap(() => {
        this.store.dispatch(PricingCompletionAction.updateSavingStatus({ params: { isSaving: true } }));
      }),
      switchMap(action => this.savePricing(action, true)),
    ),
  );

  createPricing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.CREATE_PRICING),
      switchMap((action: TypedStateAction<{ pricingRequests: Partial<PricingRequestCreation>[] }>) => {
        return this.syndicatePricerApiService.createPricingRequest(action.params.pricingRequests);
      }),
    ),
  );

  private savePricing(action: Action | TypedStateAction<SubmitPricingDetails>, isSubmission: boolean): Observable<any> {
    return of(action).pipe(
      concatLatestFrom(() => [
        this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(filter(tabDataMap => !!tabDataMap)),
        this.stateService.get.constants$,
      ]),
      map(
        ([action, pricingTabDataMap, constants]: [
          TypedStateAction<SubmitPricingDetails>,
          PricingTabDataMap,
          Constants,
        ]) => {
          const pricingRequestUpdates: Partial<PricingRequestResponse>[] = [];
          Object.keys(pricingTabDataMap ?? {}).forEach(tab => {
            Object.values(pricingTabDataMap[tab]).forEach(issuerPricingData => {
              const currentPricingRequest = issuerPricingData.pricingRequest;
              const savedPricingRequest = issuerPricingData.savedPricingRequest;

              const pricingRequestDifferences = Object.keys(currentPricingRequest)
                .map(key => key as keyof PricingRequestResponse)
                .reduce((diffMap: Record<string, any>, key) => {
                  if (key === PRICING) {
                    const currentPricing = currentPricingRequest.pricing;
                    const savedPricing = savedPricingRequest.pricing;
                    const pricingDiff = Object.keys(currentPricingRequest[key])
                      .map(key => key as keyof PricingData)
                      .reduce((pricingDiffMap: Record<string, any>, pricingKey) => {
                        if (pricingKey === 'pricing_rationale') {
                          if (action.params?.pricingRationale) {
                            const tabName = <string>(
                              this.syndicatePricerUtilService.generatePricingTabFromObject(
                                currentPricingRequest,
                                constants,
                              ).value
                            );
                            const rationale = action.params.pricingRationale[tabName];
                            if (rationale) {
                              pricingDiffMap.pricing_rationale = rationale;
                            }
                          }
                        } else if (
                          pricingKey === 'id' ||
                          JSON.stringify(currentPricing[pricingKey]) != JSON.stringify(savedPricing[pricingKey])
                        ) {
                          pricingDiffMap[pricingKey] = currentPricing[pricingKey];
                        }

                        return pricingDiffMap;
                      }, {});
                    if (Object.keys(pricingDiff).length > 1) {
                      diffMap[key] = pricingDiff;
                    }
                  } else if (
                    key === 'id' ||
                    JSON.stringify(currentPricingRequest[key]) != JSON.stringify(savedPricingRequest[key])
                  ) {
                    diffMap[key] = currentPricingRequest[key];
                  }
                  return diffMap;
                }, {});

              if (pricingRequestDifferences.id && (Object.keys(pricingRequestDifferences).length > 1 || isSubmission)) {
                pricingRequestUpdates.push(pricingRequestDifferences as Partial<PricingRequestResponse>);
              }
            });
          });
          return pricingRequestUpdates;
        },
      ),
      switchMap(pricingRequestUpdates =>
        pricingRequestUpdates.length
          ? this.syndicatePricerApiService.savePricing(pricingRequestUpdates, isSubmission)
          : of(null),
      ),
      concatLatestFrom(() => [this.store.select(PricingCompletionSelector.mode), this.stateService.get.constants$]),
      switchMap(([response, mode, constants]) => {
        const actions: Action<any>[] = [];
        if (!response) {
          actions.push(
            PricingCompletionAction.updateSavingStatus({
              params: { isSaving: false },
            }),
          );
        } else if (isSubmission && mode === NipMode.Input) {
          actions.push(PricingCompletionAction.updateSavingStatus({ params: { isSaving: false } }));
        } else {
          const savedPricingRequestsMap = response.reduce(
            (acc: Record<string, PricingRequestResponse[]>, pricingRequest) => {
              const tabName = this.syndicatePricerUtilService.generatePricingTabFromObject(pricingRequest, constants)
                .value as string;
              if (acc[tabName]) {
                acc[tabName].push(pricingRequest);
              } else {
                acc[tabName] = [pricingRequest];
              }
              return acc;
            },
            {},
          );
          actions.push(
            PricingCompletionAction.savePricingSuccess({ params: { savedPricingRequestsMap } }),
            PricingCompletionAction.updateSavingStatus({
              params: { isSaving: false },
            }),
          );
        }

        if (response && isSubmission) {
          if (mode === NipMode.Input) {
            this.popupService.message('Pricing completed successfully');
            this.routingService.routeTo(PRICING_INBOX_URL);
          } else {
            this.popupService.message('Changes saved successfully');
            this.routingService.routeToPricingRequest({ mode: NipMode.View });
          }
        }

        return of(...actions);
      }),
      catchError(error => {
        console.error(error);
        return of(PricingCompletionAction.updateSavingStatus({ params: { isSaving: false } }));
      }),
    );
  }

  repriceMaturities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.REPRICE_MATURITIES),
      tap(() => this.store.dispatch(PricingCompletionAction.addProcessToLoadingStack())),
      concatLatestFrom(() => [
        this.store.select(PricingCompletionSelector.selectedTab),
        this.store.select(PricingCompletionSelector.pricingTabDataMap),
        this.store.select(PricingCompletionSelector.sharedTabDataMap),
        this.store.select(PricingCompletionSelector.swapPricingBases),
        this.store.select(PricingCompletionSelector.showSwapReferenceCurves),
        this.store.select(PricingCompletionSelector.summaryTabSwapBasis),
        this.stateService.get.constants$,
      ]),
      mergeMap(
        ([
          action,
          selectedTab,
          pricingTabDataMap,
          sharedTabDataMap,
          swapPricingBases,
          showSwapReferenceCurves,
          summaryTabSwapBasis,
          constants,
        ]: [
          TypedStateAction<{
            tab: string;
            issuingEntityId: string;
            maturities?: string[];
            rowsToPrice?: RowPricingParams[];
          }>,
          string,
          PricingTabDataMap,
          SharedTabDataMap,
          string[],
          boolean,
          string,
          Constants,
        ]) => {
          const issuerPricingDataMap = pricingTabDataMap?.[action.params.tab];
          const issuerPricingData = issuerPricingDataMap?.[action.params.issuingEntityId];

          if (!issuerPricingData) {
            return of();
          }
          const pricingBasis = getPricingBasisForTab(issuerPricingDataMap);

          const historicalDates =
            this.syndicatePricerUtilService.getSelectedHistoricalDatesByIssuer({
              issuerPricingDataMap,
              issuingEntityId: action.params.issuingEntityId,
            })[action.params.issuingEntityId] ?? [];

          const maturitiesToPrice: Record<string, Maturities> = {};
          const hasGoviePricingBasis = this.syndicatePricerUtilService.isGovieBasis(pricingBasis, constants);

          const maturities = action.params.maturities
            ? action.params.maturities
            : getDisplayMaturitiesForTab(action.params.tab, pricingTabDataMap);

          const rows: MaturityRowPricingParams[] = [];

          const generateRowToPrice = () => {
            const rowsToPrice: RowPricingParams[] = [
              {
                rowId: this.newIssuePricingRowService.generateCurveID({
                  ...action.params,
                  curveType: NewIssueCurveType.Yield,
                }),
                curveType: NewIssueCurveType.Yield,
              },
            ];

            if (selectedTab === OVERVIEW_TAB) {
              rowsToPrice.push({
                rowId: this.newIssuePricingRowService.generateCurveID({
                  ...action.params,
                  curveType: NewIssueCurveType.OverviewSwap,
                  title: summaryTabSwapBasis,
                }),
                curveType: NewIssueCurveType.OverviewSwap,
              });
            } else {
              if (!hasGoviePricingBasis) {
                rowsToPrice.push({
                  rowId: this.newIssuePricingRowService.generateCurveID({
                    tab: action.params.tab,
                    curveType: NewIssueCurveType.ReferenceCurve,
                    title: getPricingBasisForTab(issuerPricingDataMap),
                  }),
                  curveType: NewIssueCurveType.ReferenceCurve,
                });
              }
              historicalDates.forEach(historicalDate => {
                rowsToPrice.push({
                  rowId: this.newIssuePricingRowService.generateCurveID({
                    ...action.params,
                    curveType: NewIssueCurveType.Yield,
                    historicalDate,
                    isHistoricalPricing: true,
                  }),
                  curveType: NewIssueCurveType.Yield,
                  historicalDate,
                });
              });
              swapPricingBases.forEach(basis => {
                rowsToPrice.push({
                  rowId: this.newIssuePricingRowService.generateCurveID({
                    ...action.params,
                    curveType: NewIssueCurveType.Swap,
                    title: basis,
                  }),
                  curveType: NewIssueCurveType.Swap,
                });
                if (
                  showSwapReferenceCurves &&
                  [BasisType.MS, BasisType.Govie].includes(
                    this.syndicatePricerUtilService.getBasisType(basis, constants),
                  )
                ) {
                  rowsToPrice.push({
                    rowId: this.newIssuePricingRowService.generateCurveID({
                      tab: action.params.tab,
                      curveType: NewIssueCurveType.ReferenceCurve,
                      title: basis,
                    }),
                    curveType: NewIssueCurveType.ReferenceCurve,
                  });
                }
              });
            }
            return rowsToPrice;
          };

          let rowsToPrice: RowPricingParams[] = [];
          let pricingTypes: string[];
          if (action.params.rowsToPrice) {
            rowsToPrice = action.params.rowsToPrice;
            const requestedYieldTypes: string[] = [];
            rowsToPrice.forEach(row => {
              if (row.historicalDate) {
                requestedYieldTypes.push(row.historicalDate);
              } else if (row.curveType === NewIssueCurveType.ReferenceCurve) {
                requestedYieldTypes.push(REFERENCE_PRICING);
              } else {
                requestedYieldTypes.push(PRICING);
              }
            });
            pricingTypes = [...new Set(requestedYieldTypes)];
          } else {
            rowsToPrice = generateRowToPrice();
            pricingTypes = [PRICING, REFERENCE_PRICING, ...historicalDates];
          }

          const maturitiesToClear: Record<string, Set<string>> = {};
          const maturitiesWithArea: Record<string, Set<string>> = {};

          pricingTypes.forEach(pricingType => {
            [maturitiesToClear, maturitiesWithArea].forEach(object => {
              object[pricingType] = new Set<string>();
            });
          });

          maturities.forEach(maturity => {
            pricingTypes.forEach(pricingType => {
              let totalPricing;
              if (pricingType === REFERENCE_PRICING) {
                totalPricing = '0';
              } else {
                totalPricing = this.syndicatePricerUtilService.getMaturityForPricing({
                  maturity,
                  tab: action.params.tab,
                  pricingRequest: ((pricingType !== PRICING &&
                    sharedTabDataMap[action.params.tab].historicalPricingRequestsData?.[
                      action.params.issuingEntityId
                    ]?.[pricingType]) ||
                    issuerPricingData.pricingRequest) as PricingRequestResponse,
                  pricingMap: sharedTabDataMap[action.params.tab].tempPricingMap,
                  issuingEntityId: action.params.issuingEntityId,
                });

                if (totalPricing.includes(PricingUnits.Area)) {
                  totalPricing = totalPricing.replaceAll(PricingUnits.Area, '');
                  maturitiesWithArea[pricingType].add(maturity);
                }
                if (!totalPricing && pricingType === PRICING) {
                  maturitiesToClear[pricingType].add(maturity);
                } else {
                  if (hasGoviePricingBasis) {
                    const benchmarkYield =
                      issuerPricingData.pricingRequest.pricing.additional_curves?.find(
                        curve => curve.type === NewIssueCurveType.BenchmarkYield,
                      )?.data?.[maturity] ?? '';
                    if (!benchmarkYield || !totalPricing) {
                      totalPricing = '';
                      maturitiesToClear[pricingType].add(maturity);
                    } else {
                      totalPricing = this.utilService.getValueAggregate([totalPricing, benchmarkYield], false);
                    }
                  }
                }
              }
              if (totalPricing) {
                if (
                  pricingType !== REFERENCE_PRICING &&
                  this.syndicatePricerUtilService.getBasisType(pricingBasis, constants) === BasisType.Fixed
                ) {
                  totalPricing = this.levelsUtilService.convertYieldToBps(totalPricing);
                }
                maturitiesToPrice[pricingType] = {
                  ...maturitiesToPrice[pricingType],
                  [maturity]: totalPricing,
                };
              }
            });
          });

          rowsToPrice.forEach(row => {
            let maturitiesToPriceForRow;
            if (row.curveType === NewIssueCurveType.Yield && hasGoviePricingBasis) {
              return;
            }
            const pricingParams: MaturityRowPricingParams = {
              tab: action.params.tab,
              currency: issuerPricingData.pricingRequest.currency,
              maturitiesToPrice: {},
              rowId: row.rowId,
              curveType: row.curveType,
            };
            if (row.historicalDate) {
              maturitiesToPriceForRow = maturitiesToPrice[row.historicalDate];
              pricingParams.historicalDate = row.historicalDate;
            } else if (row.curveType === NewIssueCurveType.ReferenceCurve) {
              maturitiesToPriceForRow = maturitiesToPrice[REFERENCE_PRICING];
            } else {
              maturitiesToPriceForRow = maturitiesToPrice[PRICING];
            }
            pricingParams.maturitiesToPrice = maturitiesToPriceForRow;
            rows.push(pricingParams);
          });

          if (
            hasGoviePricingBasis &&
            !!pricingTypes.find(pricingType => pricingType !== REFERENCE_PRICING) &&
            rowsToPrice.find(row => row.curveType === NewIssueCurveType.Yield)
          ) {
            pricingTypes.forEach(pricingType => {
              if (pricingType !== REFERENCE_PRICING) {
                const yieldMaturities = maturities.reduce((acc: Maturities, maturity: string) => {
                  if (maturitiesToClear[pricingType].has(maturity)) {
                    acc[maturity] = '';
                  } else if (maturitiesToPrice[pricingType]?.[maturity]) {
                    acc[maturity] = maturitiesToPrice[pricingType][maturity];
                  }
                  return acc;
                }, {});
                const rowId = this.newIssuePricingRowService.generateCurveID({
                  ...action.params,
                  ...(pricingType === PRICING
                    ? {}
                    : {
                        historicalDate: pricingType,
                        isHistoricalPricing: true,
                      }),
                  curveType: NewIssueCurveType.Yield,
                });
                this.store.dispatch(
                  PricingCompletionAction.repriceMaturitiesSuccess({
                    params: {
                      tab: action.params.tab,
                      maturities: yieldMaturities,
                      rowId,
                      hasGoviePricingBasis,
                    },
                  }),
                );
              }
            });
          }
          return forkJoin(
            rows.map(priceParams => {
              if (!priceParams.maturitiesToPrice) {
                return of({ rowId: priceParams.rowId, maturities: {} });
              }
              return this.pricingCompletionService.priceMaturities(priceParams).pipe(
                map(maturities => {
                  return { rowId: priceParams.rowId, maturities };
                }),
              );
            }),
          ).pipe(
            concatLatestFrom(() => [
              this.store.select(PricingCompletionSelector.pricingTabDataMap),
              this.store.select(PricingCompletionSelector.sharedTabDataMap),
            ]),
            switchMap(
              ([maturitiesResponse, pricingTabDataMap, sharedTabDataMap]: [
                { rowId: string; maturities: Maturities }[],
                PricingTabDataMap,
                SharedTabDataMap,
              ]) => {
                return maturitiesResponse.map(({ rowId, maturities }) => {
                  const pricedMaturities: Maturities = { ...maturities };
                  if (!rowId.includes(NewIssueCurveType.ReferenceCurve)) {
                    let pricingType;
                    if (!rowId.includes(HISTORICAL_PRICING)) {
                      pricingType = PRICING;
                      const pricingRequest =
                        pricingTabDataMap[action.params.tab]?.[action.params.issuingEntityId]?.pricingRequest;
                      Object.keys(maturities).forEach(maturity => {
                        const currentPricing = this.syndicatePricerUtilService.getMaturityForPricing({
                          maturity,
                          tab: action.params.tab,
                          pricingRequest,
                          pricingMap: sharedTabDataMap[action.params.tab].tempPricingMap,
                          issuingEntityId: action.params.issuingEntityId,
                        });
                        if (!currentPricing) {
                          pricedMaturities[maturity] = '';
                        }
                      });
                      if (maturitiesToClear[PRICING]?.size) {
                        maturitiesToClear[PRICING].forEach(maturity => {
                          pricedMaturities[maturity] = '';
                        });
                      }
                    } else {
                      pricingType = this.newIssuePricingRowService.getHistoricalDateFromCurveID(rowId);
                    }
                    if (maturitiesWithArea[pricingType]?.size) {
                      maturitiesWithArea[pricingType].forEach(maturity => {
                        if (pricedMaturities[maturity]) {
                          pricedMaturities[maturity] = `${pricedMaturities[maturity]}${PricingUnits.Area}`;
                        }
                      });
                    }
                  }
                  return PricingCompletionAction.repriceMaturitiesSuccess({
                    params: { tab: action.params.tab, maturities: pricedMaturities, rowId },
                  });
                });
              },
            ),
          );
        },
      ),
      tap(() => this.store.dispatch(PricingCompletionAction.redrawGraph())),
      tap(() => this.store.dispatch(PricingCompletionAction.removeProcessFromLoadingStack())),
      tap(() => this.store.dispatch(PricingCompletionAction.savePricing())),
    ),
  );

  getPageSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.GET_PAGE_SETTINGS),
      mergeMap(() => this.coreApiService.getSavedViews(SavedViewIdentifier.SYNDICATE_PRICER)),
      map((savedViews: SavedView[]) => {
        if (!savedViews.length) {
          return PricingCompletionAction.setPageSettings({ params: { pageSettings: initialPageSettings } });
        }
        const id = savedViews[0].id;
        const pageSettings = savedViews[0].data.pageSettings ?? initialPageSettings;
        return PricingCompletionAction.setPageSettings({ params: { id, pageSettings } });
      }),
    ),
  );

  savePageSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SAVE_PAGE_SETTINGS),
      tap((action: TypedStateAction<{ pageSettings: PricingCompletionPageSettings }>) => {
        this.store.dispatch(
          PricingCompletionAction.setPageSettings({ params: { pageSettings: action.params.pageSettings } }),
        );
      }),
      concatLatestFrom(() => this.store.select(PricingCompletionSelector.savedViewId)),
      mergeMap(
        ([action, savedViewId]: [
          TypedStateAction<{ pageSettings: PricingCompletionPageSettings }>,
          number | undefined,
        ]) => {
          const view: SavedView = {
            id: savedViewId,
            identifier: SavedViewIdentifier.SYNDICATE_PRICER,
            name: SavedViewIdentifier.SYNDICATE_PRICER,
            data: { pageSettings: action.params.pageSettings },
          };
          if (savedViewId) {
            return this.coreApiService.updateSavedView(view);
          }

          return this.coreApiService.addSavedView(view);
        },
      ),
      map((savedView: SavedView) =>
        PricingCompletionAction.savePageSettingsSuccess({ params: { savedViewId: savedView.id ?? 1 } }),
      ),
      tap(() => this.store.dispatch(PricingCompletionAction.redrawGraph())),
    ),
  );

  setDataFromPricingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_PRICED_REQUEST_DATA),
      tap(() => this.store.dispatch(PricingCompletionAction.addProcessToLoadingStack())),
      switchMap(() => this.store.select(RouterSelector.state).pipe(take(1))),
      switchMap(routerState => {
        const body: PricingRequestParams = {
          include_pricing: true,
        };

        const issuingEntityIdString: string | string[] = routerState.queryParams.issuing_entity;
        body.issuing_entity_id = (
          Array.isArray(issuingEntityIdString) ? issuingEntityIdString : [issuingEntityIdString]
        ).map(id => Number(id));

        if ([NipMode.View, NipMode.Override].includes(routerState.urlParams.mode)) {
          const date = routerState.queryParams.date
            ? moment(routerState.queryParams.date, BACKEND_DATE_FORMAT)
            : moment();
          date.add(1, 'day');
          body.as_of = date.format(BACKEND_DATE_FORMAT);
        }

        // todo - what is this for, FYI, we don't recall the api when switching from view to override
        if (routerState.urlParams.mode === NipMode.View) {
          body.read_only = true;
        }

        return forkJoin([this.syndicatePricerApiService.getPricingRequestData(body), this.stateService.get.constants$]);
      }),
      map(([pricingRequestData, constants]) => {
        return this.syndicatePricerUtilService.generatePricingDataMaps(pricingRequestData, constants);
      }),
      tap((tabDataMaps: { pricingTabDataMap: PricingTabDataMap; sharedTabDataMap: SharedTabDataMap }) => {
        const sortedPricingTabData = Object.entries(tabDataMaps.pricingTabDataMap).sort(([a], [b]) =>
          sortByAlphabetical(a, b),
        );
        this.store.dispatch(
          PricingCompletionAction.setOverviewSwapBasis({
            params: { swapBasis: getPricingBasisForTab(sortedPricingTabData[0][1]) },
          }),
        );
      }),
      tap((tabDataMaps: { pricingTabDataMap: PricingTabDataMap; sharedTabDataMap: SharedTabDataMap }) => {
        const issuingEntities: Record<number, string> = {};
        Object.keys(tabDataMaps.pricingTabDataMap).forEach(tab => {
          Object.entries(tabDataMaps.pricingTabDataMap[tab]).forEach(([issuingEntityId, issuerPricingData]) => {
            issuingEntities[+issuingEntityId] = issuerPricingData.pricingRequest.issuing_entity.name;
          });
        });

        this.store.dispatch(PricingCompletionAction.setIssuingEntities({ params: { issuingEntities } }));
      }),
      map((tabDataMaps: { pricingTabDataMap: PricingTabDataMap; sharedTabDataMap: SharedTabDataMap }) =>
        PricingCompletionAction.updatePricingTabData({ params: { ...tabDataMaps } }),
      ),
      tap(() => this.store.dispatch(PricingCompletionAction.removeProcessFromLoadingStack())),
    ),
  );

  updateSelectedSwaps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.UPDATE_SELECTED_SWAPS),
      concatLatestFrom(() => [this.stateService.get.constants$]),
      map(
        ([action, constants]: [
          TypedStateAction<{ tab: string; swapBases: string[]; showSwapReferenceCurves?: boolean }>,
          Constants,
        ]) => {
          return PricingCompletionAction.updateAdditionalCurves({
            params: {
              ...action.params,
              additionalCurveMap: action.params.swapBases.reduce(
                (curveMap: Record<string, AdditionalCurve[]>, basis) => {
                  if (action.params.showSwapReferenceCurves) {
                    const basisType = this.syndicatePricerUtilService.getBasisType(basis, constants);
                    if (![BasisType.Fixed, BasisType.Floating].includes(basisType)) {
                      curveMap[NewIssueCurveType.ReferenceCurve].push({
                        type: NewIssueCurveType.ReferenceCurve,
                        value: basis,
                      });
                    }
                  }
                  curveMap[NewIssueCurveType.Swap].push({ type: NewIssueCurveType.Swap, value: basis });

                  return curveMap;
                },
                {
                  [NewIssueCurveType.Swap]: [],
                  [NewIssueCurveType.ReferenceCurve]: [],
                },
              ),
            },
          });
        },
      ),
    ),
  );

  updateCustomCurves$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.UPDATE_CUSTOM_CURVES),
      map((action: TypedStateAction<{ tab: string; customRowNames: string[] }>) =>
        PricingCompletionAction.updateAdditionalCurves({
          params: {
            ...action.params,
            additionalCurveMap: {
              [NewIssueCurveType.Custom as NewIssueCurveType]: action.params.customRowNames.map(rowName => {
                return { type: NewIssueCurveType.Custom, value: rowName, data: {} };
              }),
            },
          },
        }),
      ),
    ),
  );

  updateSelectedHistoricalDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.UPDATE_SELECTED_HISTORICAL_DATES),
      map((action: TypedStateAction<{ tab: string; selectedDates: string[] }>) => {
        return PricingCompletionAction.updateAdditionalCurves({
          params: {
            ...action.params,
            additionalCurveMap: {
              [NewIssueCurveType.Historical as NewIssueCurveType]: action.params.selectedDates.map(date => {
                return { type: NewIssueCurveType.Historical, value: date };
              }),
            },
          },
        });
      }),
    ),
  );

  setPricingBasisForTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_PRICING_BASIS_FOR_TAB),
      concatLatestFrom(() => [
        this.store.select(PricingCompletionSelector.displayMaturities),
        this.stateService.get.constants$,
      ]),
      switchMap(
        ([action, maturities, constants]: [
          TypedStateAction<{ tab: string; pricingBasis: string }>,
          string[],
          Constants,
        ]) => {
          const basisType = this.syndicatePricerUtilService.getBasisType(action.params.pricingBasis, constants);
          const isGovieBasis = basisType === BasisType.Govie;
          if (isGovieBasis) {
            setTimeout(() => {
              this.store.dispatch(PricingCompletionAction.getGovieBonds({ params: action.params }));
            });
          }
          return (
            isGovieBasis
              ? forkJoin([this.actions$.pipe(ofType(PricingCompletionEffect.GET_GOVIE_BONDS_SUCCESS), take(1))])
              : of({})
          ).pipe(
            concatLatestFrom(() => [this.store.select(PricingCompletionSelector.govieBonds)]),
            map(([_, govieBondMap]) => {
              let initialBenchmarkSelection: Record<string, string> | undefined;
              let benchmarkYields: Record<string, string> | undefined;
              const govieBonds = govieBondMap[action.params.pricingBasis];
              if (isGovieBasis && govieBonds) {
                initialBenchmarkSelection = this.syndicatePricerUtilService.getDefaultBenchmarkSelection({
                  maturities,
                  pricingBasis: action.params.pricingBasis,
                  govieBonds,
                });

                benchmarkYields = this.syndicatePricerUtilService.getBenchmarkYields(
                  initialBenchmarkSelection,
                  govieBonds,
                );
              }
              return PricingCompletionAction.setPricingBasisForTabSuccess({
                params: {
                  ...action.params,
                  basisType,
                  initialBenchmarkSelection,
                  benchmarkYields,
                },
              });
            }),
          );
        },
      ),
    ),
  );

  setSelectedBonds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PricingCompletionEffect.SET_SELECTED_BONDS),
      concatLatestFrom(() => [
        this.syndicatePricerSelectorService.secondaryLevels$,
        this.syndicatePricerSelectorService.unsavedSecondaryLevels$,
        this.syndicatePricerSelectorService.secondaryBonds$,
        this.syndicatePricerSelectorService.quotationType$,
      ]),
      map(
        ([action, secondaryLevels, unsavedSecondaryLevels, secondaryBonds, quoteType]: [
          TypedStateAction<{ tab: string; selectedBonds: Set<string> }>,
          SecondaryLevels,
          SecondaryLevels,
          SecondaryBond[],
          QuoteType,
        ]) => {
          const selectedBonds = action.params.selectedBonds;
          const updatedSecondaryLevels: SecondaryLevels = JSON.parse(JSON.stringify(secondaryLevels));
          const updatedUnsavedSecondaryLevels: SecondaryLevels = JSON.parse(JSON.stringify(unsavedSecondaryLevels));

          Object.keys(secondaryLevels).forEach(isin => {
            // move isin and priced content to unsaved

            // TODO: remove price/ytm/discount_margin/outstanding_size from saved
            const secondaryLevel = updatedSecondaryLevels[isin];
            // isin no longer selected therefore move to unsaved
            if (!selectedBonds.has(isin)) {
              // move isin and priced content to unsaved
              updatedUnsavedSecondaryLevels[isin] = {
                ...updatedUnsavedSecondaryLevels[isin],
                ...secondaryLevel,
              };
              // keep only override in saved and not in unsaved
              if (secondaryLevel.override) {
                updatedSecondaryLevels[isin] = { override: secondaryLevel.override };
                delete updatedUnsavedSecondaryLevels[isin].override;
              } else {
                // if no override, remove isin completely form saved object
                delete updatedSecondaryLevels[isin];
              }
            }
          });
          // whats left in selectedBonds is the bonds that were not already in SL
          selectedBonds.forEach(isin => {
            const bond = secondaryBonds.find(bond => bond.isin === isin);
            if (!bond) {
              return;
            }

            const secondaryLevel: Partial<BondPricing> = {}; // should never be null at this point
            const historicalSecondaryLevels: Partial<BondPricing> = {};

            // split historical pricing
            Object.entries(updatedUnsavedSecondaryLevels[isin] ?? {}).forEach(([key, value]: [string, string]) => {
              (key.includes(HISTORICAL_IHS) ? historicalSecondaryLevels : secondaryLevel)[key] = value;
            });

            updatedSecondaryLevels[isin] = {
              ...updatedSecondaryLevels[isin], // in case it already has override
              ...secondaryLevel,
              price: this.syndicatePricerUtilService.getPriceValue(quoteType, bond),
              yield: this.syndicatePricerUtilService.getYieldValue(quoteType, bond),
              size: bond.size,
            };

            if (Object.keys(historicalSecondaryLevels).length) {
              updatedUnsavedSecondaryLevels[isin] = historicalSecondaryLevels;
            } else {
              delete updatedUnsavedSecondaryLevels[isin];
            }
          });

          this.store.dispatch(
            PricingCompletionAction.updateIncludedIsins({
              params: {
                tab: action.params.tab,
                includedIsins: new Set(Object.keys(updatedSecondaryLevels)),
              },
            }),
          );

          return PricingCompletionAction.setSelectedBondsSuccess({
            params: {
              tabName: action.params.tab,
              secondaryLevels: updatedSecondaryLevels,
              unsavedSecondaryLevels: updatedUnsavedSecondaryLevels,
            },
          });
        },
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private coreApiService: CoreApiService,
    private dialogService: DialogService,
    private levelsUtilService: LevelsUtilService,
    private popupService: PopupService,
    private pricingCompletionService: PricingCompletionService,
    private store: Store,
    private stateService: StateService,
    private syndicatePricerApiService: SyndicatePricerApiService,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
    private syndicatePricerSelectorService: SyndicatePricerSelectorService,
    private newIssuePricingRowService: NewIssuePricingRowsService,
    private routingService: RoutingService,
    private utilService: UtilService,
    private ratingsService: RatingsService,
  ) {}
}
