import { Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../services/util.service';

@Pipe({
  standalone: false,
  name: 'valueToDisplayFormat',
})
export class ValueToDisplayFormatPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}

  transform(value: any, type: any): unknown {
    switch (type) {
      case 'date':
      case 'datetime':
      case 'date-no-year':
        return this.utilService.convertFromBackendDateStringFormat(value, type);
      case 'number':
      case 'decimal':
        return this.utilService.applyCommasToNumber(value);
      case 'token':
        return value.join('; ');
      case null:
        if (Array.isArray(value)) {
          return value
            .map(item => {
              if (!isNaN(item)) {
                return this.utilService.applyCommasToNumber(item);
              } else {
                return item;
              }
            })
            .join('; ');
        }
        return value;
      default:
        return value;
    }
  }
}
