import { getCdnUrl } from '../../helper.functions';

export const toggleSidebarAnimationTransitionDuration = 250; // also change $sidebar-animation-duration
// eslint-disable-next-line max-len
export const toggleSidebarAnimationTransition = `${toggleSidebarAnimationTransitionDuration}ms ease-in`; // also change $sidebar-animation-timing

export const sidebarWidthMini = 64; // also change $sidebar-width-mini
export const sidebarWidthOpen = 250; // also change $sidebar-width-open
export const sidebarLogoUrl = {
  large: getCdnUrl('assets/img/origin-markets/logo-white.svg'),
  small: getCdnUrl('assets/img/origin-markets/icon-white.svg'),
};

export enum SidenavPosition {
  Default = 'default',
  Open = 'open',
  Mini = 'mini',
}

export enum SidenavMode {
  Side = 'side',
  Over = 'over',
}

export enum SidebarType {
  TimelineActivities = 'timeline-activities',
  DocumentVault = 'document-vault',
}
