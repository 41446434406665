import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@morpho/environment';
import { RedirectionService } from './redirection.service';

@Injectable({
  providedIn: 'root',
})
export class LegacyService {
  private readonly urlParam = this.redirectionService.legacyUrlParam;

  constructor(
    private redirectionService: RedirectionService,
    private router: Router,
  ) {}

  routeToBankangleUrl(url: string) {
    this.router.navigate([this.convertBankangleUrlToRoute(url)]);
  }

  convertBankangleUrlToRoute(url: string): string {
    return this.redirectionService.convertBankangleUrlToRoute(url);
  }

  convertBankangleUrlToHref(url: string): string {
    return (
      window.location.origin +
      (environment.appBaseHref + this.convertBankangleUrlToRoute(url))
        .replace('//', '/')
        .replace('#', '%23')
        .replace('?', '%3F')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
    );
  }

  convertRouteToBankangleUrl(route: string): string {
    return decodeURIComponent(route.slice(2)).replace(this.urlParam, '');
  }

  goToClassic(event: MouseEvent) {
    event.preventDefault();
    window.sessionStorage.noredirect = true;
    if (window.location.href.includes('localhost')) {
      window.location.href = 'http://localhost:8000';
      return;
    }
    window.location.href = '/';
  }
}
