import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CoreMaterialModule } from './core-material.module';
import { ActionMenuComponent } from './elements/components/action-menu/action-menu.component';
import { ContactComponent } from './elements/components/contact/contact.component';
import { DocumentCompareViewerComponent } from './elements/components/document-compare-viewer/document-compare-viewer.component';
import { EquationComponent } from './elements/components/equation/equation.component';
import { LinearProgressIndicatorComponent } from './elements/components/linear-progress-indicator/linear-progress-indicator.component';
import { PdfViewerControllerComponent } from './elements/components/pdf-viewer-controller/pdf-viewer-controller.component';
import { PdfViewerComponent } from './elements/components/pdf-viewer/pdf-viewer.component';
import { ProgressIndicatorComponent } from './elements/components/progress-indicator/progress-indicator.component';
import { SharedSearchComponent } from './elements/components/shared-search/shared-search.component';
import { TabControlComponent } from './elements/components/tab-control/tab-control.component';
import { PinchZoomDirective } from './elements/directives/pinchZoom/pinchZoom.directive';
import { StickyToolbarsDirective } from './elements/directives/sticky-toolbars/sticky-toolbars.directive';
import { ArrayToStringPipe } from './elements/pipes/array-to-string.pipe';
import { CountryFlagPipe } from './elements/pipes/country-flag.pipe';
import { DateToDisplayPipe } from './elements/pipes/date-to-display.pipe';
import { EmptyFillerPipe } from './elements/pipes/empty-filler.pipe';
import { GetSidepanelsPipe } from './elements/pipes/get-sidepanels.pipe';
import { MentionsInfoPipe } from './elements/pipes/mentions-info.pipe';
import { PluckPipe } from './elements/pipes/pluck.pipe';
import { SafeUrlPipe } from './elements/pipes/safe-url.pipe';
import { TruncatePipe } from './elements/pipes/truncate.pipe';
import { ValueToDisplayFormatPipe } from './elements/pipes/value-to-display.pipe';
import { ButtonCellRendererComponent } from './patterns/ag-grid/cell-renderers/button-cell-renderer/button-cell-renderer.component';
import { RowLevelActionsCellRendererComponent } from './patterns/ag-grid/cell-renderers/row-level-actions-cell-renderer/row-level-actions-cell-renderer.component';
import { SquareLogoCellRendererComponent } from './patterns/ag-grid/cell-renderers/square-logo-cell-renderer/square-logo-cell-renderer.component';
import { UsersIconsCellRendererComponent } from './patterns/ag-grid/cell-renderers/users-icons-cell-renderer/users-icons-cell-renderer.component';
import { LoggingInComponent } from './patterns/auth/logging-in/logging-in.component';
import { CarouselSlideComponent } from './patterns/carousel/carousel-slide/carousel-slide.component';
import { CarouselComponent } from './patterns/carousel/carousel/carousel.component';
import { ModalCarouselSlideContainerComponent } from './patterns/carousel/modal-carousel-slide-container/modal-carousel-slide-container.component';
import { ForbiddenComponent } from './patterns/error-pages/components/forbidden/forbidden.component';
import { NotActiveComponent } from './patterns/error-pages/components/not-active/not-active.component';
import { NotFoundComponent } from './patterns/error-pages/components/not-found/not-found.component';
import { EmptySearchComponent } from './patterns/placeholder/components/empty-search/empty-search.component';
import { EmptyStateComponent } from './patterns/placeholder/components/empty-state/empty-state.component';
import { LoadingComponent } from './patterns/placeholder/components/loading/loading.component';
import { CdnPipe } from './patterns/routing/pipes/cdn.pipe';
import { RoutingPipe } from './patterns/routing/pipes/routing.pipe';
import { SidenavPanelComponent } from './patterns/sidenav/sidenav-panel/sidenav-panel.component';
import { SidenavToggleComponent } from './patterns/sidenav/sidenav-toggle/sidenav-toggle.component';
import { SnackBarComponent } from './patterns/snack-bar/snack-bar.component';
import { TooltipComponent } from './patterns/tooltip/tooltip.component';
import { TooltipDirective } from './patterns/tooltip/tooltip.directive';
import { PrototypeModule } from './prototype.module';

const declarations = [
  ActionMenuComponent,
  CarouselComponent,
  CarouselSlideComponent,
  ModalCarouselSlideContainerComponent,
  EmptyStateComponent,
  EmptySearchComponent,
  LoadingComponent,
  LoggingInComponent,
  LinearProgressIndicatorComponent,
  ProgressIndicatorComponent,
  SidenavToggleComponent,
  SidenavPanelComponent,
  DocumentCompareViewerComponent,
  ContactComponent,
  TabControlComponent,
  SharedSearchComponent,
  PdfViewerComponent,
  PdfViewerControllerComponent,
  NotActiveComponent,
  NotFoundComponent,
  ForbiddenComponent,
  EquationComponent,
  TooltipComponent,
  SnackBarComponent,
  // directives
  TooltipDirective,
  StickyToolbarsDirective,
  PinchZoomDirective,
  // pipes
  ArrayToStringPipe,
  CdnPipe,
  CountryFlagPipe,
  DateToDisplayPipe,
  EmptyFillerPipe,
  GetSidepanelsPipe,
  MentionsInfoPipe,
  PluckPipe,
  RoutingPipe,
  SafeUrlPipe,
  TruncatePipe,
  ValueToDisplayFormatPipe,
];

const agGridDeclarations = [
  RowLevelActionsCellRendererComponent,
  ButtonCellRendererComponent,
  UsersIconsCellRendererComponent,
  SquareLogoCellRendererComponent,
];

const modules = [
  PrototypeModule,
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CoreMaterialModule,
  PdfViewerModule,
];

@NgModule({
  imports: modules,
  declarations: [...declarations, ...agGridDeclarations],
  exports: [...modules, ...declarations, ...agGridDeclarations],
})
export class CoreModule {}
