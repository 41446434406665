import { EquationEditor } from '../equation/equation-commands';

export function deserializeEquation(element: HTMLElement) {
  const text = element.textContent ?? '';
  if (!text) {
    return '';
  }
  const equation = text.slice(text.indexOf('>') + 1, text.lastIndexOf('<!'));
  const equationXML = new DOMParser().parseFromString(equation, 'text/html');
  const fragment = deserialize(equationXML.body);
  return fragment;
}

function deserialize(element: HTMLElement | ChildNode): string {
  switch (element.nodeName) {
    case 'M:R':
      // text
      return EquationEditor.cleanEquationContent(element.textContent) || '\\:'; // return text or space
    case 'M:F':
      // fraction
      let numerator = '';
      let denominator = '';
      element.childNodes.forEach(child => {
        if (child.nodeName === 'M:NUM') {
          numerator = deserialize(child);
        } else if (child.nodeName === 'M:DEN') {
          denominator = deserialize(child);
        }
      });
      return `\\tfrac{${numerator}}{${denominator}}`;
    case 'M:D':
      // matrix, used as () or []
      let brackets = ['(', ')'];
      if ((element as HTMLElement).innerHTML.startsWith(`<m:dpr><m:begchr m:val="[">`)) {
        brackets = ['[', ']'];
      }
      return '\\left' + brackets[0] + deserializeChildren(element) + '\\right' + brackets[1];
    case 'M:SUB':
      // subscript
      return `_{${deserializeChildren(element)}}`;
    case 'M:SUP':
      // superscript
      return `^${deserializeChildren(element)}`;
    case 'M:RAD':
      // radical / square root
      let root = '';
      let expression = '';
      element.childNodes.forEach(child => {
        if (child.nodeName === 'M:DEG') {
          root = deserialize(child);
        } else if (child.nodeName === 'M:E') {
          expression = deserialize(child);
        }
      });
      return `\\sqrt[${root}]{${expression}}`;
    case 'M:NARY':
      // sum, product, integral
      return extractComplexFunction(element);
    default:
      break;
  }

  const nodes: any = Array.from(element.childNodes)
    .map((child: any) => deserialize(child))
    .flat();
  return nodes.join('');
}

function deserializeChildren(element: HTMLElement | ChildNode) {
  return Array.from(element.childNodes)
    .map(child => deserialize(child))
    .join('');
}

function extractComplexFunction(element: HTMLElement | ChildNode): string {
  let type = '';
  let subscript = '';
  let superscript = '';
  let expression = '';
  element.childNodes.forEach(child => {
    switch (child.nodeName) {
      case 'M:NARYPR':
        const innerHTML = (child as HTMLElement).innerHTML;
        if (innerHTML.startsWith(`<m:chr m:val="∑">`)) {
          type = 'sum';
        } else if (innerHTML.startsWith(`<m:chr m:val="∏">`)) {
          type = 'prod';
        } else if (innerHTML.startsWith(`<m:limloc m:val="undOvr">`)) {
          type = 'int';
        } else {
          type = 'sum';
        }
        break;
      case 'M:SUB':
        subscript = deserializeChildren(child);
        break;
      case 'M:SUP':
        superscript = deserializeChildren(child);
        break;
      case 'M:E':
        expression = deserializeChildren(child);
        break;
      default:
        break;
    }
  });

  return `\\${type}_{${subscript}}^{${superscript}}${expression}`;
}
