import { Injectable } from '@angular/core';
import { RouterSelector } from '@morpho/core';
import { select, Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PocSettings } from '../../models/poc';
import { AuthenticatedUserState } from '../state/authenticated-user/authenticated-user.model';
import { AuthenticatedUserSelector } from '../state/authenticated-user/authenticated-user.selectors';
import { ConstantsState } from '../state/constants/constants.model';
import { CONSTANTS_STATE_KEY } from '../state/constants/constants.reducer';
import { DisplayState } from '../state/display/display.models';
import { DISPLAY_STATE_KEY } from '../state/display/display.reducer';
import { PermissionSelector } from '../state/permission/permission.selectors';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  // a stream of state values that would need to be unsubscribed from
  readonly stream = {
    constants$: this.store.pipe<ConstantsState>(select(CONSTANTS_STATE_KEY)),
    display$: this.store.pipe<DisplayState>(select(DISPLAY_STATE_KEY)),
  };

  // a single state value that has been initialized
  private getConstants$: Observable<ConstantsState> = this.stream.constants$.pipe(
    filter((val: any) => val && !!Object.keys(val).length),
    take(1),
  );
  private getPocSettings$ = this.store.select(PermissionSelector.pocSettings).pipe(
    filter((pocSettings: PocSettings) => !!pocSettings),
    take(1),
  );
  private getDisplay$ = this.stream.display$.pipe(take(1));
  private getRouter$ = this.store.select(RouterSelector.state).pipe(
    filter(router => !!router.path),
    take(1),
  );
  private getAuthenticatedUser$ = this.store.select(AuthenticatedUserSelector.state).pipe(
    filter(
      (user: AuthenticatedUserState) =>
        !!(user?.id && (!user.isDealer || user.favouriteListId || user.isImpersonation)),
    ),
    take(1),
  );
  readonly get = {
    constants$: this.getConstants$,
    display$: this.getDisplay$,
    authenticatedUser$: this.getAuthenticatedUser$,
    router$: this.getRouter$,
    pocSettings$: this.getPocSettings$,
    ready$: forkJoin([
      // todo add navbar and display
      this.getConstants$,
      this.getAuthenticatedUser$,
      this.getPocSettings$,
      this.getRouter$,
    ]),
  };

  constructor(private store: Store<any>) {}
}
