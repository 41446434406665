import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticatedUserAction } from './authenticated-user.actions';
import { AuthenticatedUserState } from './authenticated-user.model';

export const AUTHENTICATED_USER_STATE_KEY = 'authenticatedUser';

const initialState: AuthenticatedUserState | Partial<AuthenticatedUserState> = {};

const reducer = createReducer(
  initialState,

  on(
    AuthenticatedUserAction.getAuthenticatedUserSuccess,
    (state: AuthenticatedUserState | null, action): AuthenticatedUserState => ({
      ...state,
      ...action.params,
    }),
  ),

  on(
    AuthenticatedUserAction.getFavouriteIssuersSuccess,
    (state: AuthenticatedUserState | null, action): AuthenticatedUserState => ({
      ...state,
      ...(action.params ? { favouriteListId: action.params.id, favouriteIssuers: action.params.issuers } : null),
    }),
  ),

  on(AuthenticatedUserAction.isImpersonation, (state: AuthenticatedUserState | null): AuthenticatedUserState => {
    document.body.classList.add('is-impersonation');
    return {
      ...state,
      isImpersonation: true,
    };
  }),
);

export function authenticatedUserReducer(state: AuthenticatedUserState, action: Action) {
  return reducer(state, action);
}
