/*
  Node for linked list implemtation, used for deque
*/
class ListNode<T> {
  public data: T;

  public next: ListNode<T> | null;
  public prev: ListNode<T> | null;

  constructor(datum: T) {
    this.data = datum;
    this.next = null;
    this.prev = null;
  }
}

/*
  Double-ended queue, a la pythons deque.
  Double ended queue gives flexibility of choosing which side of the (abstract)
  queue you are removing from.

  Can choose whether to push to the right (push), and remove from the front (popLeft)
  or push to the front (pushLeft) and remove from the back (pop)

  Implementation here uses a double linked list, with the two root nodes being the first (head) and last (tail)
  items in the queue.
*/
export class Deque<T> {
  private head: ListNode<T> | null = null;
  private tail: ListNode<T> | null = null;
  private _size: number;

  constructor() {
    this._size = 0;
  }

  // O(1)
  // remove element from the front of the queue
  public popLeft(): T {
    if (!this.head) {
      throw 'Cannot remove elements from empty deque';
    }

    const temp = this.head;

    this.head = this.head?.next;
    if (!this.head) {
      this.tail = null;
    } else {
      this.head.prev = null;
    }
    this._size--;
    return temp.data;
  }

  // O(1)
  // remove element from the back of the queue
  public pop(): T {
    if (!this.head) {
      throw 'Cannot remove elements from empty deque';
    }
    if (!this.tail) {
      const temp = this.head;
      this.head = null;
      this._size--;
      return temp.data;
    } else {
      const temp = this.tail;
      this.tail = this.tail.prev;
      if (this.tail === this.head) {
        this.tail = null;
      }
      if (this.tail) {
        this.tail.next = null;
      }
      this._size--;
      return temp.data;
    }
  }

  // O(1)
  // insert element to the back of the queue
  public push(datum: T): void {
    const newNode = new ListNode<T>(datum);

    if (!this.tail) {
      this.head = this.tail = newNode;
    } else {
      newNode.prev = this.tail;
      this.tail.next = newNode;
      this.tail = newNode;
    }
    this._size += 1;
  }

  // O(1)
  // insert element to the front of the queue
  public pushLeft(datum: T) {
    const newNode = new ListNode<T>(datum);

    if (!this.head) {
      this.tail = this.head = newNode;
    } else {
      newNode.next = this.head;
      this.head.prev = newNode;
      this.head = newNode;
    }

    this._size += 1;
  }

  // O(1)
  public size() {
    return this._size;
  }

  // O(1)
  public empty() {
    return this.head === null;
  }
}
