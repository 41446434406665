import { ValueAndLabel } from '../models/value-and-label.model';

export const ZERO_OFFSET_DISPLAY_FORMAT = '+0000';
export const DEFAULT_TIME = '18:00';
export const ZERO_OFFSET_VALUES = ['Z', '+0000', '-0000', '±0000'];
export const GMT = 'GMT';

export const TIME_ZONES: ValueAndLabel[] = [
  { label: 'Abidjan', value: 'Africa/Abidjan' },
  { label: 'Hawaii', value: 'US/Hawaii' },
  { label: 'Abidjan', value: 'Africa/Abidjan' },
  { label: 'Hawaii', value: 'US/Hawaii' },
  { label: 'Los Angeles, Vancouver', value: 'America/Vancouver' },
  { label: 'Chicago, Mexico City', value: 'America/Mexico_City' },
  { label: 'EST', value: 'EST' },
  { label: 'Panama, Lima', value: 'America/Lima' },
  { label: 'New York, Montreal, Cuba, Toronto', value: 'America/New_York' },
  { label: 'Santiago', value: 'America/Santiago' },
  { label: 'Buenos Aires', value: 'America/Buenos_Aires' },
  { label: 'Sao Paulo', value: 'America/Sao_Paulo' },
  { label: 'GMT', value: 'GMT' },
  { label: 'Reykjavik', value: 'Atlantic/Reykjavik' },
  { label: 'London, Dublin, Lisbon', value: 'Europe/London' },
  { label: 'Tunis', value: 'Africa/Tunis' },
  { label: 'CET: Paris, Brussels, Stockholm, Berlin, Madrid', value: 'Europe/Paris' },
  { label: 'Johannesburg, Cairo', value: 'Africa/Cairo' },
  { label: 'EET: Tallinn, Helsinki, Athens, Riga', value: 'EET' },
  { label: 'Tel Aviv, Beirut', value: 'Asia/Tel_Aviv' },
  { label: 'Moscow, Istanbul', value: 'Europe/Moscow' },
  { label: 'Riyadh, Qatar, Bahrain', value: 'Asia/Riyadh' },
  { label: 'Muscat, Dubai', value: 'Asia/Dubai' },
  { label: 'Calcutta', value: 'Asia/Kolkata' },
  { label: 'Jakarta, Ho Chi Minh, Bangkok', value: 'Asia/Bangkok' },
  { label: 'Singapore, Hong Kong, Taipei, Shanghai', value: 'Asia/Singapore' },
  { label: 'Kuala Lumpur, Manila', value: 'Asia/Kuala_Lumpur' },
  { label: 'Tokyo, Seoul', value: 'Asia/Tokyo' },
  { label: 'Queensland, Brisbane', value: 'Australia/Brisbane' },
  { label: 'Sydney, Melbourne, Canberra', value: 'Australia/Sydney' },
  { label: 'Auckland', value: 'Pacific/Auckland' },
];
