import { Injectable } from '@angular/core';
import { AuthService } from '@morpho/core';
import { environment, localProxyTarget } from '@morpho/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly proxyKey = 'omProxyTarget';

  constructor(private authService: AuthService) {}

  init() {
    if (environment.deployed) {
      return;
    }
    const storedProxyTarget = localStorage.getItem(this.proxyKey);
    if (!storedProxyTarget) {
      localStorage.setItem(this.proxyKey, localProxyTarget);
    } else if (storedProxyTarget !== localProxyTarget) {
      this.authService.resetSession();
    }
  }
}
