export type RegressionData = [number, number, number][];

export type PolynomialExpression = number[];

export type LinearLogarithmicExpression = { gradient: number; intercept: number } | { gradient: null; intercept: null };

export enum RegressionMode {
  Interpolated = 'interpolated',
  Polynomial = 'polynomial',
  Logarithmic = 'logarithmic',
  Linear = 'linear',
}

export interface RegressionConfig {
  mode: RegressionMode;
  order?: number;
}

// This is needed because the typing in the ecStat Library is incorrect/outdated
export interface ecStatRegressionResult {
  points: RegressionData;
  parameter: PolynomialExpression | LinearLogarithmicExpression;
}

export interface RegressionObject {
  data: RegressionData;
  mode: RegressionMode;
  expression?: PolynomialExpression | LinearLogarithmicExpression;
}

export const DEFAULT_REGRESSION_CONFIG: Required<RegressionConfig> = {
  mode: RegressionMode.Logarithmic,
  order: 2,
};
