import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DealerTeam, IssuerTeam } from '../../../constants/teams';
import { AuthenticatedUserState } from './authenticated-user.model';
import { AUTHENTICATED_USER_STATE_KEY } from './authenticated-user.reducer';

const authenticatedUserState = createFeatureSelector<AuthenticatedUserState>(AUTHENTICATED_USER_STATE_KEY);

export const AuthenticatedUserSelector = {
  state: authenticatedUserState,

  // todo, change to look at ids, and remove e.g. isDealer from state;
  isDealer: createSelector(authenticatedUserState, state => state.isDealer),
  isDcmDealer: createSelector(
    authenticatedUserState,
    state => !!(state.isDealer && state.team_name === DealerTeam.Dcm),
  ),
  canEditPriority: createSelector(
    authenticatedUserState,
    state => !!(state.isDealer && [DealerTeam.Mtn, DealerTeam.Syndicate].includes(state.team_name as DealerTeam)),
  ),
  isIssuer: createSelector(authenticatedUserState, state => state.isIssuer),
  isNotIssuer: createSelector(authenticatedUserState, state => state.id && !state.isIssuer),
  isSupport: createSelector(
    authenticatedUserState,
    state =>
      !!(
        (state.isIssuer && state.team_name === IssuerTeam.Support) ||
        (state.isDealer && state.team_name === DealerTeam.Support)
      ),
  ),
  isLegal: createSelector(
    authenticatedUserState,
    state =>
      !!(
        (state.isIssuer && state.team_name === IssuerTeam.Legal) ||
        (state.isDealer && state.team_name === DealerTeam.Legal)
      ),
  ),
  isStaff: createSelector(authenticatedUserState, state => state.isStaff),
  isApprover: createSelector(
    authenticatedUserState,
    state => state.isIssuer && [IssuerTeam.Funding, IssuerTeam.Legal].includes(state.team_name as IssuerTeam),
  ),

  companyName: createSelector(authenticatedUserState, state => state.company_name),
  teamName: createSelector(authenticatedUserState, state => state.team_name),

  fullName: createSelector(authenticatedUserState, state => state.fullName),

  isImpersonation: createSelector(authenticatedUserState, state => state.isImpersonation),

  aliasAccounts: createSelector(authenticatedUserState, state => state.aliases),

  favouriteListId: createSelector(authenticatedUserState, state => state.favouriteListId),

  favouriteIssuers: createSelector(authenticatedUserState, state => state.favouriteIssuers),
};
