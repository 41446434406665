import { BasePoint, BaseRange, Editor, Element, Range, Transforms } from 'slate';
import { EMPTY_PARAGRAPH, ElementType } from '../../constants/rich-text.constants';
import { RichTextTable, RichTextTableCell, RichTextTableRow } from '../../models/rich-text.model';
import { EditorHelper } from '../editor-helper';

export const EMPTY_TABLE_DATA_CELL: RichTextTableCell = {
  type: ElementType.TableDataCell,
  children: [EMPTY_PARAGRAPH],
};

/**
 *
 * @returns empty: if no tables are selected or the selection is inside one cell
 * @returns regularSelection: if every row is fully selected, can be achieved with slate selection
 * @returns tableSelection: if only some columns in a table are selected
 */
function getSelectedTableDetails(editor: Editor): {
  regularSelection?: BaseRange;
  tableSelection?: { selectedCellPaths: number[][]; minX: number; maxX: number; minY: number; maxY: number };
} {
  if (!editor.selection || Range.isCollapsed(editor.selection)) {
    // no selection
    return {};
  }

  const selectedTables = EditorHelper.getNodes(editor, ElementType.Table);
  if (!selectedTables) {
    // no table selected
    return {};
  }

  const [anchor, focus] = [editor.selection.anchor, editor.selection.focus];
  const isSelectionForward = JSON.stringify(anchor.path) < JSON.stringify(focus.path);
  const [startPath, endPath] = isSelectionForward ? [anchor.path, focus.path] : [focus.path, anchor.path];

  const [startCell, startCellPath] = EditorHelper.getNode(editor, ElementType.TableDataCell, { at: startPath });
  const [endCell, endCellPath] = EditorHelper.getNode(editor, ElementType.TableDataCell, { at: endPath });

  if (!startCell && !endCell) {
    // all tables fully selected
    return { regularSelection: { anchor, focus } };
  }

  if (startCell && endCell) {
    if (JSON.stringify(startCellPath) === JSON.stringify(endCellPath)) {
      // selection inside one cell only
      return {};
    }

    const startTablePath = startCellPath.slice(0, -2);
    const endTablePath = endCellPath.slice(0, -2);
    if (JSON.stringify(startTablePath) === JSON.stringify(endTablePath)) {
      // selection is one table or subset of one table
      const [startX, startY] = startCellPath.slice(-2);
      const [endX, endY] = endCellPath.slice(-2);

      const [minX, maxX] = startX < endX ? [startX, endX] : [endX, startX];
      const [minY, maxY] = startY < endY ? [startY, endY] : [endY, startY];

      const selectedCellPaths: number[][] = [];

      for (let x = minX; x <= maxX; x += 1) {
        for (let y = minY; y <= maxY; y += 1) {
          selectedCellPaths.push([...startTablePath, x, y]);
        }
      }

      return { tableSelection: { selectedCellPaths, minX, maxX, minY, maxY } };
    }
  }

  // selection starts or ends outside of table
  let selectionStart: BasePoint | null = null;
  let selectionEnd: BasePoint | null = null;

  // selection on full row
  if (startCell) {
    selectionStart = Editor.start(editor, startCellPath.slice(0, -1));
  }
  if (endCell) {
    selectionEnd = Editor.end(editor, endCellPath.slice(0, -1));
  }

  // selection outside of table
  if (!selectionStart) {
    selectionStart = isSelectionForward ? anchor : focus;
  }
  if (!selectionEnd) {
    selectionEnd = isSelectionForward ? focus : anchor;
  }

  const [newAnchor, newFocus]: [BasePoint, BasePoint] = isSelectionForward
    ? [selectionStart, selectionEnd]
    : [selectionEnd, selectionStart];

  if (isSelectionForward) {
    return { regularSelection: { anchor: selectionStart, focus: selectionEnd } };
  }
  return { regularSelection: { anchor: newAnchor, focus: newFocus } };
}

function insertTable(editor: Editor, size: { rows: number; columns: number }) {
  Editor.withoutNormalizing(editor, () => {
    if (!(size.rows || size.columns)) {
      return;
    }

    const dataCell: RichTextTableCell = EMPTY_TABLE_DATA_CELL;

    const dataRow: RichTextTableRow = {
      type: ElementType.TableRow,
      children: new Array(size.columns).fill(dataCell),
    };

    const table: RichTextTable = {
      type: ElementType.Table,
      children: [],
    };

    table.children = [...new Array(size.rows).fill(dataRow)];

    const pathToInsert: number[] = editor.selection
      ? [Editor.path(editor, editor.selection)[0] + 1] // next top level block
      : [0]; // at the beginning editor

    EditorHelper.insertNestedBlock(editor, table, pathToInsert);
  });
}

function setEditorSelection(editor: Editor, setPath: number[]) {
  Transforms.select(editor, {
    anchor: Editor.start(editor, setPath),
    focus: Editor.end(editor, setPath),
  });
}

function insertTableRow(editor: Editor, position: 'above' | 'below', setPath?: number[]) {
  if (setPath) {
    setEditorSelection(editor, setPath);
  }

  Editor.withoutNormalizing(editor, () => {
    const currentRow = Editor.above(editor, {
      match: (node: Element | any) => {
        return node.type === ElementType.TableRow;
      },
      mode: 'lowest',
    });

    if (!currentRow) {
      return;
    }

    const pathToInsert = currentRow[1];
    if (position === 'below') {
      pathToInsert[pathToInsert.length - 1] += 1;
    }

    const newRow: RichTextTableRow = {
      type: ElementType.TableRow,
      children: currentRow[0].children.map((node: Element) => ({
        type: (node as any).type, // cells of type data or header
        children: [EMPTY_PARAGRAPH],
      })),
    };

    EditorHelper.insertNestedBlock(editor, newRow, pathToInsert);
  });
}

function deleteTableRow(editor: Editor, setPath?: number[]) {
  if (setPath) {
    setEditorSelection(editor, setPath);
  }
  Editor.withoutNormalizing(editor, () => {
    Transforms.removeNodes(editor, {
      match: (node: Element | any) => {
        return node.type === 'table_row';
      },
      mode: 'lowest',
    });
  });
}

function insertTableColumn(editor: Editor, position: 'left' | 'right', setPath?: number[]) {
  if (setPath) {
    setEditorSelection(editor, setPath);
  }

  Editor.withoutNormalizing(editor, () => {
    const currentCell = Editor.above(editor, {
      match: (node: Element | any) => {
        return [ElementType.TableHeaderCell, ElementType.TableDataCell].includes(node.type);
      },
      mode: 'lowest',
    });

    const currentTable = Editor.above(editor, {
      match: (node: Element | any) => {
        return node.type === ElementType.Table;
      },
      mode: 'lowest',
    });

    if (!currentCell || !currentTable) {
      return;
    }

    const currentCellPath = [...currentCell[1]];
    currentTable[0].children.forEach((row, index) => {
      const pathToInsert = [...currentCellPath];

      const cellToInsert = {
        type: (row as any).children[pathToInsert[pathToInsert.length - 1]].type,
        children: [EMPTY_PARAGRAPH],
      };

      pathToInsert[pathToInsert.length - 2] = index;

      if (position === 'right') {
        pathToInsert[pathToInsert.length - 1] += 1;
      }

      EditorHelper.insertNestedBlock(editor, cellToInsert, pathToInsert);
    });
  });
}

function deleteTableColumn(editor: Editor, setPath?: number[]) {
  if (setPath) {
    setEditorSelection(editor, setPath);
  }
  Editor.withoutNormalizing(editor, () => {
    const currentCell = Editor.above(editor, {
      match: (node: Element | any) => {
        return [ElementType.TableHeaderCell, ElementType.TableDataCell].includes(node.type);
      },
      mode: 'lowest',
    });

    const currentTable = Editor.above(editor, {
      match: (node: Element | any) => {
        return node.type === ElementType.Table;
      },
      mode: 'lowest',
    });

    if (!currentCell || !currentTable) {
      return;
    }

    currentTable[0].children.forEach((row, index) => {
      const pathToDelete = currentCell[1];
      pathToDelete[pathToDelete.length - 2] = index;
      Transforms.removeNodes(editor, { at: pathToDelete });
    });
  });
}

export const TableEditor = {
  getSelectedTableDetails,
  insertTable,
  insertTableRow,
  deleteTableRow,
  insertTableColumn,
  deleteTableColumn,
};
