import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment-timezone';
import { map, mergeMap } from 'rxjs/operators';
import { IssuerApiService } from '../../patterns/issuer/issuer-api.service';
import { CoreApiService } from '../../services/core-api.service';
import { AuthenticatedUserAction, AuthenticatedUserEffect } from './authenticated-user.actions';
import { AuthenticatedUser } from './authenticated-user.model';

@Injectable()
export class AuthenticatedUserEffects {
  getAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticatedUserEffect.GET_AUTHENTICATED_USER),
      mergeMap(() =>
        this.coreApiService.getAuthenticatedUser().pipe(
          map((user: AuthenticatedUser) => {
            const timezone = user.timezone;
            if (timezone) {
              moment.tz.setDefault(timezone); // for consistency we want to use what the backend is using for timezones
            }
            return AuthenticatedUserAction.getAuthenticatedUserSuccess({ params: user });
          }),
        ),
      ),
    ),
  );

  getFavouriteIssuers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticatedUserEffect.GET_FAVOURITE_ISSUERS),
      mergeMap(() =>
        this.issuerApiService
          .getFavouriteIssuers()
          .pipe(map(response => AuthenticatedUserAction.getFavouriteIssuersSuccess({ params: response }))),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private coreApiService: CoreApiService,
    private issuerApiService: IssuerApiService,
  ) {}
}
