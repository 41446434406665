import { Injectable } from '@angular/core';
import { RouterSelector, RoutingService } from '@morpho/core';
import { Store } from '@ngrx/store';
import {
  ActivityCheckpoint,
  ActivityData,
  IssuerProfileViewActivity,
  LevelInputsViewActivity,
} from 'apps/bankangle/src/app/models/activity-monitoring';
import { debounceTime } from 'rxjs/operators';
import { CoreApiService } from './core-api.service';

enum Page {
  ISSUER_LIST = 'issuer-list',
  ISSUER_PROFILE = 'issuer-profile',
  LEVEL_INPUTS = 'levels',
}

@Injectable({
  providedIn: 'root',
})
export class ActivityMonitoringService {
  private routerState$ = this.store.select(RouterSelector.state).pipe(debounceTime(1000));

  private page: string | undefined;
  private tab: string | undefined;

  constructor(
    private coreApiService: CoreApiService,
    private store: Store<any>,
    private routingService: RoutingService,
  ) {
    this.start();
  }

  private start(): void {
    this.routerState$.subscribe(state => {
      if (!state) {
        return;
      }
      const module = this.getPathPart(state.path, 1);

      switch (module) {
        case 'issuers':
          const issuerId = this.getPathPart(state.path, 2);
          if (issuerId) {
            const tab = this.getPathPart(state.path, 3) || 'overview';
            const data = { issuerId: Number(issuerId) };
            this.send(Page.ISSUER_PROFILE, tab, data);
          } else {
            this.send(Page.ISSUER_LIST);
          }
          return;
        case 'levels':
          const levelsComponent = this.getPathPart(state.path, 2);
          const levelsIssuerId = this.getPathPart(state.path, 3);
          if (levelsComponent === 'input') {
            this.send(Page.LEVEL_INPUTS, 'bullet', { issuerId: Number(levelsIssuerId) });
          }
          return;
        case 'curve-analysis':
          const referrer = this.routingService.referrer ?? 'url';
          this.logActivity(ActivityCheckpoint.VIEWED_CURVE_ANALYSIS, { referrer });
          return;
        case 'xccy-pricer':
          this.logActivity(ActivityCheckpoint.XCCY_PRICER_PAGE_LOADED);
          return;
        case 'l':
          const legacyModule = this.getPathPart(state.path, 5);
          switch (legacyModule) {
            case 'callables':
              const issuerId: string = this.getPathPart(state.path, 4);
              this.send(Page.LEVEL_INPUTS, 'callable', { issuerId: Number(issuerId) });
              return;
            default:
              return;
          }

        default:
          break;
      }

      this.page = undefined;
      this.tab = undefined;
    });
  }

  logActivity(activity: ActivityCheckpoint, data?: any): void {
    this.coreApiService.monitorActivity(activity, data).subscribe();
  }

  private getPathPart(path: string, part = 1): string {
    const split = path?.split('/');
    if (split.length > part) {
      return split[part];
    }
    return '';
  }

  private send(page: string, tab?: string, data?: any) {
    let activity: ActivityData | undefined;
    let activityName = '';

    if (page !== this.page || tab !== this.tab) {
      switch (page) {
        case Page.ISSUER_LIST:
          activityName = 'issuer-list-viewed';
          break;
        case Page.ISSUER_PROFILE:
          const issuerProfileViewActivity: IssuerProfileViewActivity = {
            issuer: data.issuerId,
            tab: tab as string,
            is_navigation_between_tabs: page === this.page,
          };
          activity = issuerProfileViewActivity;
          activityName = 'issuer-profile-viewed';
          break;
        case Page.LEVEL_INPUTS:
          const levelInputsViewActivity: LevelInputsViewActivity = {
            issuer: data.issuerId,
            redemption_type: tab as LevelInputsViewActivity['redemption_type'],
          };
          activity = levelInputsViewActivity;
          activityName = 'level-inputs-viewed';
          break;
        default:
          break;
      }

      this.coreApiService.monitorActivity(activityName, activity).subscribe();

      this.page = page;
      this.tab = tab || undefined;
    }
  }
}
