import { ActivatedRouteSnapshot, Data, Params, RouterStateSnapshot } from '@angular/router';
import { CustomRouterState } from '@morpho/core';
import { RouterStateSerializer } from '@ngrx/router-store';
import { page } from '../../constants/wording';

export class CustomRouterStateSerializer implements RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    const data = mergeRouteData(routerState.root);
    if (!data.title && routerState.url.startsWith('/l/')) {
      // legacy pages
      data.title = routerState.url.includes('luxse-programme-info')
        ? 'LuxSe Programme Fetcher Api'
        : routerState.url.includes('change-password')
          ? page.PASSWORD_CHANGE
          : '';
    }
    return {
      url: routerState.url,
      path: routerState.url.split('?')[0],
      urlParams: mergeRouteParams(routerState.root, r => r.params),
      queryParams: mergeRouteParams(routerState.root, r => r.queryParams),
      data,
    };
  }
}

function getPrimaryChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  return (route.children.find(c => c.outlet === 'primary') || route.firstChild) as ActivatedRouteSnapshot;
}

function mergeRouteParams(route: ActivatedRouteSnapshot, getter: (r: ActivatedRouteSnapshot) => Params): Params {
  if (!route) {
    return {};
  }
  const currentParams = getter(route);
  const primaryChild = getPrimaryChild(route);
  return { ...currentParams, ...mergeRouteParams(primaryChild, getter) };
}

function mergeRouteData(route: ActivatedRouteSnapshot): Data {
  if (!route) {
    return {};
  }

  const currentData = route.data;
  const primaryChild = getPrimaryChild(route);
  return { ...currentData, ...mergeRouteData(primaryChild) };
}
