import { mergeAll, Observable, of, shareReplay, Subject, switchMap } from 'rxjs';
/*
  Decorator to cache an http request.
  created to utilise ./cache.service.ts to provide cache/storage ds

  When using this decorator, pass the target class as the type, and provide three args:
    Storage (the cache service -> needs to be inhected to target service)
    refreshSubject (subject to call for refresh. needs to be defined on target class)
    shouldCache (callback to determine if a set of arguments should be cached or ignored)

*/

interface CacheStorage {
  getItem(key: string, item: Observable<any>): void;
  setItem(key: string, item: Observable<any>): void;
}

interface CacheOptions {
  storage: CacheStorage;
  refreshSubject: Observable<unknown> | Subject<unknown>;
  shouldCache: (args?: any[]) => boolean;
}

export function HttpRequestCache<T extends Record<string, any>>(optionsHandler: (this: T) => CacheOptions) {
  return (target: T, methodName: string, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> => {
    const cacheKeyPrefix = `${target.constructor.name}_${methodName}`;
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]): Observable<any> {
      const { storage, refreshSubject, shouldCache } = optionsHandler.call(this);
      const key = storage.getItem(...args) ?? `${cacheKeyPrefix}_${JSON.stringify({ ...args })}`;

      if (!shouldCache(...args)) {
        return originalMethod.apply(this, args);
      }

      let observable = storage.getItem(key);

      if (observable) {
        return observable;
      }

      observable = of(
        originalMethod.apply(this, args),
        refreshSubject.pipe(switchMap(() => originalMethod.apply(this, args))),
      ).pipe(mergeAll(), shareReplay(1));

      storage.setItem(key, observable);

      return observable;
    };

    return descriptor;
  };
}
