import { Component, forwardRef, Input } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

@Component({
  standalone: false,
  selector: 'om-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: getCustomFormFieldProviders(forwardRef(() => RadioInputComponent)),
})
export class RadioInputComponent extends CustomFormFieldControlComponent {
  @Input()
  options: ValueAndLabel[];
}
