<mat-toolbar class="is-accent">
  <section left>
    <p>{{ lastPriced ? 'Last Priced' : 'Swap Pricer' }}</p>
  </section>
  <section [formGroup]="formGroup">
    <mat-form-field class="is-inline">
      <om-autocomplete-input
        formControlName="fundingBasis"
        placeholder="Select funding basis"
        [options]="fundingBasisOptions"
      >
      </om-autocomplete-input>
    </mat-form-field>

    @if (areAdditionalFieldsShown && !hideAdditionalFields) {
      <mat-form-field class="is-inline">
        <om-autocomplete-input formControlName="dayCount" placeholder="Day count" [options]="dayCountOptions">
        </om-autocomplete-input>
      </mat-form-field>
      <mat-form-field class="is-inline">
        <om-autocomplete-input
          formControlName="paymentFrequency"
          placeholder="Frequency"
          [options]="paymentFrequencyOptions"
        >
        </om-autocomplete-input>
      </mat-form-field>
    }
    @if (lastPriced) {
      <span>on {{ lastPriced | dateToDisplayFormat: 'datetime' }}</span>
    }
    @if (isLoading) {
      <mat-spinner diameter="24"></mat-spinner>
    }
  </section>
  <section right>
    @if (lastPriced) {
      @if (allowReset) {
        <button mat-button class="is-text" [disabled]="isLoading" (click)="reset()">
          <b>Reset</b>
        </button>
      }
      <button mat-button class="is-chip" [disabled]="isLoading" (click)="reprice()">
        <b>Reprice</b>
      </button>
    }
  </section>
</mat-toolbar>
