import { Pipe, PipeTransform } from '@angular/core';
import { getCdnUrl } from '../../../helper.functions';

@Pipe({
  standalone: false,
  name: 'cdn',
})
export class CdnPipe implements PipeTransform {
  transform(path: string): string {
    return getCdnUrl(path);
  }
}
