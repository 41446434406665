import { AfterViewInit, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as intlTelInput from 'intl-tel-input';
import { PhoneInputDirective } from '../../directives/phone-input.directive';
import { PhoneInputConfig } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

@Component({
  standalone: false,
  selector: 'om-phone-input',
  templateUrl: './phone-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => forwardRef(() => PhoneInputComponent))),
})
export class PhoneInputComponent extends CustomFormFieldControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('phoneInput') phoneInputDirective: PhoneInputDirective;
  phoneNumber: string;
  private isInitialised = false;

  @Input() fieldConfig: PhoneInputConfig;
  @Input()
  get value(): string {
    return this._value;
  }
  set value(val: string) {
    if (this._value !== val) {
      this._value = val;
      if (this.isInitialised && this.phoneInputDirective) {
        this.phoneInputDirective.setNumber(val);
      }
      this.emitChanges();
    }
  }
  _value: string;

  phoneInputConfig: intlTelInput.SomeOptions;

  ngOnInit(): void {
    const customPlaceholder = (selectedCountryPlaceholder: any, selectedCountryData: any) => {
      return this.fieldConfig.placeholder ?? '';
    };

    this.phoneInputConfig = {
      strictMode: true,
      allowDropdown: true,
      validationNumberType: 'MOBILE',
      fixDropdownWidth: true,
      separateDialCode: true,
      formatAsYouType: false,
      formatOnDisplay: false,
      showFlags: true,
      countryOrder: ['FR', 'DE', 'NL', 'NO', 'SE', 'GB'],
      utilsScript: (window as any).intlTelInput,
      customPlaceholder: customPlaceholder,
    };
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.isInitialised && this.phoneInputDirective) {
        this.phoneInputDirective.setInitialValue(this._value);
        this.isInitialised = true;
      }
    });
  }

  ngOnDestroy() {
    this.phoneInputDirective.destroy();
  }

  onPhoneNumberChanged() {
    if (this.isInitialised && this.phoneInputDirective) {
      this.value = this.phoneInputDirective.getNumber();
    }
  }
}
