@if (tradeWorkingGroups$ | async; as tradeWorkingGroups) {
  @if (currentStage === STAGES.Initial) {
    <ng-container *ngTemplateOutlet="viewWorkingGroupsTemplate"></ng-container>
  }
  @if (currentStage === STAGES.SearchMembers) {
    <ng-container
      *ngTemplateOutlet="
        searchListTemplate;
        context: {
          headerText: 'Add members to the Working Group',
          searchLabel: 'Find members',
          listType: 'members'
        }
      "
    ></ng-container>
  }
  @if (currentStage === STAGES.SearchCompanies) {
    <ng-container
      *ngTemplateOutlet="
        searchListTemplate;
        context: {
          headerText: 'STEP 1 - Select company',
          singleSelect: true,
          searchLabel: 'Find company',
          listType: 'companies'
        }
      "
    ></ng-container>
  }
  @if (currentStage === STAGES.SearchCompanyEmployees) {
    <ng-container
      *ngTemplateOutlet="
        searchListTemplate;
        context: {
          headerText: 'STEP 2 - Select company’s employees',
          searchLabel: 'Find members',
          listType: 'members'
        }
      "
    ></ng-container>
  }
  <ng-template #viewWorkingGroupsTemplate>
    <h2 mat-dialog-title>
      Working Group
      <span class="working-group__modal-title-members"
        >({{ allUsers.length }} member{{ allUsers.length === 1 ? '' : 's' }})</span
      >
      <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
    </h2>
    <div mat-dialog-content>
      <p class="working-group__intro">
        The working group should include any colleagues, including legal and operations, that need to see or interact
        with this trade
      </p>
      @if (companiesList$ | async; as allowedToAddCompanies) {
        <span
          class="is-tooltip-anchor"
          [omTooltip]="!allowedToAddCompanies.length && 'No companies available to add at this time'"
        >
          @if ((trade$ | async)?.activeTrade?.permissions?.can_add_additional_working_groups) {
            <button
              mat-button
              class="is-secondary is-text"
              (click)="goToStage(STAGES.SearchCompanies)"
              [disabled]="!allowedToAddCompanies.length"
              [ngClass]="allowedToAddCompanies.length ? 'is-accent' : ''"
            >
              <span>Add another company to this trade</span>
            </button>
          }
        </span>
      }
      @for (tradeWorkingGroup of tradeWorkingGroups; track tradeWorkingGroup) {
        <div>
          <div class="working-group__header">
            <img mat-card-avatar [src]="tradeWorkingGroup.company.square_logo" />
            <span class="working-group__header-title">
              <h3>{{ tradeWorkingGroup.company?.company_name }}</h3>
              <h4>{{ tradeWorkingGroup.company?.company_type_label }}</h4>
            </span>
            @if (tradeWorkingGroup.can_user_add) {
              <button
                mat-button
                class="is-secondary is-accent is-text"
                (click)="goToStage(STAGES.SearchMembers, tradeWorkingGroup.id)"
              >
                <mat-icon svgIcon="addUser"></mat-icon><span>Add member</span>
              </button>
            }
          </div>
          @for (user of tradeWorkingGroup.users; track user) {
            <mat-card
              appearance="outlined"
              class="om-info-card"
              [ngClass]="{ 'om-info-card--is-emphasised': user.is_trade_owner }"
            >
              <div class="om-info-card__content">
                <h3>{{ user.full_name }}</h3>
                <h4>{{ user.team_name }}</h4>
              </div>
              <div class="om-info-card__extra">
                @if (
                  (authenticatedUser$ | async)?.id !== user.id &&
                  tradeWorkingGroup.can_user_delete &&
                  !user.is_trade_owner
                ) {
                  <button mat-icon-button class="is-warning" (click)="deleteUser(tradeWorkingGroup.id, user)">
                    <mat-icon svgIcon="delete"></mat-icon>
                  </button>
                }
                @if (user.is_trade_owner) {
                  <span>OWNER</span>
                }
              </div>
            </mat-card>
          }
        </div>
      }
    </div>
  </ng-template>
  <ng-template
    #searchListTemplate
    let-headerText="headerText"
    let-singleSelect="singleSelect"
    let-searchLabel="searchLabel"
    let-listType="listType"
  >
    <!-- heading text -->
    <h2 mat-dialog-title>
      {{ headerText }}
      <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
    </h2>
    @if (companySelected?.company_type_label === 'Issuer') {
      <div class="om-banner is-alert">
        <strong>Adding an Issuer suspends other reviews</strong>
      </div>
    }
    <div mat-dialog-content>
      @if (filteredWorkingGroupLists$ | async; as filteredWorkingGroupLists) {
        <mat-form-field>
          <mat-label>{{ searchLabel }}</mat-label>
          <input
            matInput
            [placeholder]="listType === 'members' ? 'Search for name or team' : 'Search for name or company type'"
            [formControl]="filterControl"
          />
          <mat-icon matPrefix svgIcon="search"></mat-icon>
        </mat-form-field>
        <ng-container
          [ngTemplateOutlet]="filteredWorkingGroupLists.filteredList.length ? cardList : emptyCardList"
          [ngTemplateOutletContext]="{
            filteredTeamList: filteredWorkingGroupLists.filteredTeamList,
            filteredList: filteredWorkingGroupLists.filteredList,
            singleSelect: singleSelect,
            filterControl: filterControl,
            listType: listType
          }"
        >
        </ng-container>
      }
    </div>
    @if (currentStage === STAGES.SearchMembers) {
      <div mat-dialog-actions>
        <button mat-button (click)="cancelToStage(STAGES.Initial)">CANCEL</button>
        <button mat-button (click)="addToWorkingGroup()" [disabled]="!idsToAdd.length">
          ADD
          @if (idsToAdd.length) {
            <span>({{ idsToAdd.length }})</span>
          }
        </button>
      </div>
    }
    @if (currentStage === STAGES.SearchCompanies) {
      <div mat-dialog-actions>
        <button mat-button (click)="cancelToStage(STAGES.Initial)">CANCEL</button>
        <button mat-button (click)="selectCompany()" [disabled]="!companySelected">SELECT COMPANY</button>
      </div>
    }
    @if (currentStage === STAGES.SearchCompanyEmployees) {
      <div mat-dialog-actions>
        <button mat-button (click)="cancelToStage(STAGES.SearchCompanies)">CANCEL</button>
        <button mat-button (click)="addWorkingGroup()" [disabled]="!idsToAdd.length">
          ADD
          @if (idsToAdd.length) {
            <span>({{ idsToAdd.length }})</span>
          }
        </button>
      </div>
    }
  </ng-template>
}

<ng-template
  #cardList
  let-filteredTeamList="filteredTeamList"
  let-filteredList="filteredList"
  let-singleSelect="singleSelect"
>
  @for (team of filteredTeamList; track team) {
    <mat-card
      appearance="outlined"
      class="om-info-card om-info-card--is-selectable"
      [ngClass]="{
        'om-info-card--is-selected mat-elevation-z3': isTeamSelected(team)
      }"
      class="om-info-card om-info-card--is-selectable"
      (click)="team.selected = !team.selected; updateTeamSelectedIds(team)"
    >
      <div class="om-info-card__content">
        <div class="om-info-card__team">
          <mat-icon svgIcon="team"></mat-icon>
          <h3>{{ team.employer_name }} {{ team.team_name }}</h3>
        </div>
        <span [omTooltip]="teamTooltip" [omTooltipProperties]="followCursorTooltipProperties"
          >{{ team.team_users.length }} {{ team.team_users.length > 1 ? 'Users' : 'User' }}</span
        >
      </div>

      <div class="om-info-card__extra">
        <mat-checkbox [checked]="isTeamSelected(team)"></mat-checkbox>
      </div>
    </mat-card>

    <div #teamTooltip>
      @for (user of team.team_users; track user.id) {
        <span> {{ user.first_name }} {{ user.last_name }} </span>
        <br />
      }
    </div>
  }
  @for (item of filteredList; track item) {
    <mat-card
      appearance="outlined"
      class="om-info-card om-info-card--is-selectable"
      [ngClass]="{
        'om-info-card--is-selected mat-elevation-z3': singleSelect
          ? companySelected === item
          : idsToAdd.includes(item.id),
        'om-info-card--is-disabled': disabledMap?.[item.id]
      }"
      (click)="singleSelect ? (companySelected = item) : !disabledMap?.[item.id] ? updateSelectedIds(item) : ''"
    >
      <div class="om-info-card__content">
        <h3>{{ item.display_title }}</h3>
        <h4>{{ item.display_subtitle }}</h4>
      </div>
      @if (!singleSelect) {
        <div class="om-info-card__extra">
          <mat-checkbox [disabled]="disabledMap?.[item.id]" [checked]="idsToAdd.includes(item.id)"></mat-checkbox>
        </div>
      }
      @if (singleSelect) {
        <div class="om-info-card__extra">
          <mat-radio-group [(ngModel)]="companySelected">
            <mat-radio-button [value]="item"></mat-radio-button>
          </mat-radio-group>
        </div>
      }
    </mat-card>
  }
</ng-template>

<ng-template #emptyCardList let-filterControl="filterControl" let-listType="listType">
  <ng-container
    [ngTemplateOutlet]="filterControl.value ? noSearchResults : noItemsToAdd"
    [ngTemplateOutletContext]="{
      filterControl: filterControl,
      listType: listType
    }"
  >
  </ng-container>
</ng-template>

<ng-template #noSearchResults>
  <!-- ToDo: style this... -->
  <p>There are no search results for "{{ filterControl.value }}"</p>
</ng-template>
<ng-template #noItemsToAdd let-listType="listType">
  <!-- ToDo: style this... -->
  <p>There are no {{ listType }} available to add.</p>
</ng-template>
