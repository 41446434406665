import { Editor } from 'slate';
import { ElementType } from '../../constants/rich-text.constants';

export const withEquations = (editor: Editor) => {
  const { isInline, isVoid, normalizeNode, onKeyDown } = editor;

  editor.isVoid = element => {
    return element.type === ElementType.Equation || isVoid(element);
  };

  editor.isInline = element => {
    return element.type === ElementType.Equation || isInline(element);
  };

  editor.normalizeNode = entry => {
    // TODO: normalizeNode to go here if required
    normalizeNode(entry);
  };

  editor.onKeyDown = (editor: Editor, event: KeyboardEvent) => {
    // TODO: onKeyDown to go here if required
    onKeyDown?.(editor, event);
  };

  return editor;
};
