<mat-form-field>
  <mat-select
    #selectInput
    [disabled]="disabled"
    [ngModel]="childValue"
    [placeholder]="placeholder"
    (selectionChange)="selected($event)"
    [multiple]="multiple"
  >
    <mat-select-trigger>
      <span> {{ labelValue }}</span>
    </mat-select-trigger>

    @if (hasGroupOptions) {
      @for (group of groupOptions; track group.divider_label) {
        <mat-optgroup [label]="group.divider_label">
          @for (option of group.options; track option.value) {
            <mat-option [value]="option.value">
              <ng-container *ngTemplateOutlet="optionTemplate; context: { option: option }"></ng-container>
            </mat-option>
          }
        </mat-optgroup>
      }
    } @else {
      @for (option of flatOptions; track option.value) {
        <mat-option [value]="option.value">
          <ng-container *ngTemplateOutlet="optionTemplate; context: { option: option }"></ng-container>
        </mat-option>
      }
    }
  </mat-select>
</mat-form-field>

<ng-template #optionTemplate let-option="option">
  <div>
    <span>{{ option.label }}</span>
    @if (option.description) {
      <span>{{ option.description }}</span>
    }
  </div>
</ng-template>
