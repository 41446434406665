import { Directive, HostListener, Output } from '@angular/core';
import { PinchZoomDirection } from '@morpho/core';
import { Subject, concatMap, of, throttleTime } from 'rxjs';

@Directive({
  standalone: false,
  selector: '[omPinchZoom]',
})
export class PinchZoomDirective {
  pinchZoom$ = new Subject<PinchZoomDirection>();
  scale = 1;

  @Output() onPinchZoom = this.pinchZoom$.asObservable().pipe(
    throttleTime(100),
    concatMap(value => of(value)),
  );

  @HostListener('wheel', ['$event'])
  onWheel($event: WheelEvent) {
    if (!$event.ctrlKey) {
      return;
    }
    $event.preventDefault();

    const scale = this.scale - $event.deltaY;
    this.calculatePinch(scale);
  }

  calculatePinch(scale: number) {
    const pinchZoomDirection: PinchZoomDirection = this.scale < scale ? PinchZoomDirection.In : PinchZoomDirection.Out;
    this.pinchZoom$.next(pinchZoomDirection);
    this.scale = scale;
  }
}
