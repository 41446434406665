import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { DATETIME_FORMAT } from '../constants/display';
import { UtilService } from '../services/util.service';

@Pipe({
  standalone: false,
  name: 'dateToDisplayFormat',
})
export class DateToDisplayPipe implements PipeTransform {
  constructor(private utilService: UtilService) {}

  transform(
    value: string | number | moment.Moment,
    dateType: 'date' | 'date-no-year' | 'datetime' | 'hour-minute',
  ): string {
    // todo move into util service + make dateType and enum;

    if (!value) {
      return '';
    }

    if (moment.isMoment(value)) {
      // todo support all types
      return this.utilService.convertMomentToString(value, DATETIME_FORMAT);
    }

    // todo move into the util service
    if (dateType === 'hour-minute') {
      const seconds = parseInt(value.toString());
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds / 60) % 60);
      return `${hours}h${minutes}`;
    }
    return this.utilService.convertFromBackendDateStringFormat(value.toString(), dateType);
  }
}
