import { ElementType, MentionElement, MentionsOption } from '@morpho/rich-text';
import { Editor, Transforms } from 'slate';

function insertMention(editor: Editor, mention: MentionsOption) {
  const mentionTag: MentionElement = {
    type: ElementType.Mention,
    children: [{ text: '' }],
  };

  for (const [key, value] of Object.entries(mention.data)) {
    mentionTag[key as keyof MentionElement] = value;
  }

  Transforms.insertNodes(editor, mentionTag);
  Transforms.move(editor);
}

export const MentionsEditor = {
  insertMention,
};
