import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  agGridOptionsAutoHeight,
  agGridOptionsBase,
  agGridOptionsNoSidebar,
  FrontendColumnDefinition,
  ValueAndLabel,
} from '@morpho/core';
import {
  CellEditingStoppedEvent,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
} from 'ag-grid-community';
import { FormInputConfig } from '../../models/form.model';
import { dataTableInputFrameworkComponents } from '../../services/framework-components.constant';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

interface ColumnGroup {
  groupLabel: string;
  columns: FormInputConfig[];
}
@Component({
  standalone: false,
  selector: 'om-data-table-input',
  templateUrl: './data-table-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => DataTableInputComponent)),
})
export class DataTableInputComponent extends CustomFormFieldControlComponent implements OnInit, OnChanges {
  frameworkComponents = {
    ...dataTableInputFrameworkComponents,
  };

  gridOptions: GridOptions = {
    ...agGridOptionsBase,
    ...agGridOptionsAutoHeight,
    ...agGridOptionsNoSidebar,
    context: this,
    defaultColDef: {
      enableCellChangeFlash: false,
      menuTabs: [],
      resizable: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressMovable: true,
      flex: 1,
    },
    components: this.frameworkComponents,
    suppressColumnVirtualisation: true,
    suppressFieldDotNotation: true,
    tooltipShowDelay: 500,
  };

  gridApi: GridApi;

  @Input() getNewRow: (data: any[] | null) => any;
  @Input() columns: (FormInputConfig | ColumnGroup)[];
  @Input() minSize: number;
  @Input() maxSize: number;

  @Input() labels?: string[];
  @Input() labelPrefix?: string;
  @Input() labelHeader?: string;

  @Input()
  get labelWidth(): number {
    return this._labelWidth;
  }
  set labelWidth(width: number) {
    this._labelWidth = Number(width);
  }
  protected _labelWidth: number;

  @Input()
  get value(): any[] | null {
    return this._value;
  }
  set value(value: any[] | null) {
    this._value = value;
    this.setRowData();
    this.emitChanges(true);
  }
  protected _value: any[] | null;

  @Input()
  set longList(longList: boolean) {
    if (coerceBooleanProperty(longList)) {
      this.gridOptions.suppressColumnVirtualisation = false;
      this.gridOptions.domLayout = 'normal';
      (this.gridOptions.defaultColDef ??= {}).editable = false;
      this.gridOptions.getRowId = params => {
        return params.data.isin ?? params.data.id ?? params.data.name ?? JSON.stringify(params.data);
      };
    }
  }

  rowData: any[] | null;

  private newRowData = {};

  add() {
    const newRowData = this.getNewRow?.(this.value) ?? this.newRowData;
    this.value = [...(this.value ?? []), { ...newRowData }];

    if (this.labels) {
      const newCount = this.value.length;
      this.labels[newCount - 1] = newCount.toString();
    }
    this.setActionsDisplay();
    this.emitChanges();
  }

  private setRowData() {
    if ((this.value?.length ?? 0) < (this.labels?.length ?? 0)) {
      this.rowData = [...(this.value ?? []), ...(this.labels ?? []).slice(this.value?.length ?? 0).map(() => ({}))];
    } else {
      this.rowData = this.value;
    }
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.newValue !== event.oldValue) {
      let data = JSON.parse(JSON.stringify(this.rowData));
      for (let i = data.length - 1; i >= 0; i -= 1) {
        const row = data[i];
        if (JSON.stringify(row) !== '{}') {
          data = data.slice(0, i + 1).map((row: any[]) => {
            return Object.entries(row).reduce((acc: Record<any, any>, [key, value]) => {
              acc[key] = !value && value !== 0 ? null : value;
              return acc;
            }, {});
          });
          break;
        } else if (i === 0) {
          data = null;
        }
      }
      this._value = data;
      this.emitChanges();
    }
  }

  ngOnInit() {
    const newRowData: any = {};

    let columnDefs: (ColGroupDef | ColDef)[] = this.columns.map(column => {
      if ((column as ColumnGroup).groupLabel) {
        const colDef: ColGroupDef = {
          headerName: (column as ColumnGroup).groupLabel,
          children: (column as ColumnGroup).columns.map(column => {
            return this.createColumnDef(column, newRowData);
          }),
        };
        return colDef;
      }
      return this.createColumnDef(column as FormInputConfig, newRowData);
    });

    this.newRowData = newRowData;

    if (this.labels || this.labelHeader) {
      columnDefs = this.addLabelColumn(columnDefs);
    }

    if (
      !this.disabled &&
      this.value?.length &&
      !(this.minSize === this.maxSize && this.minSize === this.value.length)
    ) {
      columnDefs.push(this.createActionColumnDef());
    }

    this.gridOptions.columnDefs = columnDefs;
  }

  private createColumnDef(column: FormInputConfig, newRowData: any = {}): ColDef {
    const isDisabled = this.disabled || column.element === 'data';
    if (isDisabled) {
      newRowData[column.name] = this.value?.[0]?.[column.name];
    }
    let colDef: ColDef = {
      colId: column.name,
      field: column.name,
      tooltipField: column.name,
      headerName: column.label,
      editable: !isDisabled,
      cellClass: isDisabled ? 'is-readonly' : '',
    };

    if (column.element === 'primitive') {
      if (['number', 'integer'].includes(column.type)) {
        colDef = {
          ...colDef,
          cellEditor: 'omPrimitiveCellEditor',
          cellEditorParams: {
            field: {
              type: 'number',
            },
          },
        };
      }
    }
    if (column.element === 'select') {
      colDef = {
        ...colDef,
        cellEditor: 'omSelectCellEditor',
        cellEditorParams: { values: column.options },
        cellRenderer: (params: ICellRendererParams) => {
          const result = (column.options as ValueAndLabel[]).find(option => option.value === params.value)?.label;
          return result ?? '';
        },
      };
    }

    return colDef;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.labels) {
      this.setRowData();
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.setActionsDisplay();
  }

  private createActionColumnDef(): ColDef {
    return {
      colId: FrontendColumnDefinition.RowLevelActions,
      cellRenderer: 'omDataTableRowDeleteCellRenderer',
      cellRendererParams: {
        onClick: (rowNode: IRowNode) => this.delete(rowNode),
      },
      pinned: 'right',
      width: this.gridOptions.rowHeight,
      minWidth: this.gridOptions.rowHeight,
      maxWidth: this.gridOptions.rowHeight,
      resizable: false,
    };
  }

  private addLabelColumn(columnDefs: ColDef[]): ColDef[] {
    const labelColumn: ColDef = {
      colId: FrontendColumnDefinition.Label,
      headerName: this.labelHeader ?? '',
      editable: false,
      cellClass: 'is-readonly',
      tooltipValueGetter: params => {
        return params.value;
      },
      valueGetter: ({ node }) => {
        const rowIndex = node?.rowIndex;
        return this.getLabelValue(Number(rowIndex), this.labels, this.labelPrefix);
      },
      minWidth: this.labelWidth,
    };

    return [labelColumn, ...columnDefs];
  }

  private delete(rowNode: IRowNode) {
    if (this.value?.length === this.minSize) {
      return;
    }
    if (!this.value?.length || this.value.length === 1) {
      this.value = null;
    } else {
      const index = rowNode.rowIndex;
      if (index == null) {
        return;
      }
      this.value = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
    }
    this.setActionsDisplay();
    this.emitChanges();
  }

  private setActionsDisplay(): void {
    const isVisible = this.minSize != null && this.value?.length === this.minSize;
    this.gridApi?.setColumnsVisible([FrontendColumnDefinition.RowLevelActions], isVisible);
  }

  private getLabelValue(idx: number, labels: string[] | undefined, labelPrefix: string | undefined): string {
    const counter = (idx + 1).toString();
    const prefix = labelPrefix ? `${labelPrefix} ` : '';
    const value = labels?.[idx] ?? counter;
    return `${prefix}${value}`;
  }
}
