@switch (true) {
  @case (!!routeUrl) {
    <ng-container *ngTemplateOutlet="aTagTemplate"></ng-container>
  }
  @case (!!externalUrl) {
    <ng-container *ngTemplateOutlet="aTagTemplateExt"></ng-container>
  }
  @default {
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  }
}

<ng-template #aTagTemplate>
  <a
    [routerLink]="routeUrl | routing: 'routerLink'"
    [queryParams]="routeUrl | routing: 'queryParams'"
    (click)="markAsRead()"
  >
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #aTagTemplateExt>
  <a [href]="externalUrl" target="_blank" class="om-hide-external-link-icon">
    <ng-container *ngTemplateOutlet="cardTemplate"></ng-container>
  </a>
</ng-template>

<ng-template #cardTemplate>
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ title }}</mat-card-title>
      <mat-card-subtitle>{{ datetime | timeago: true }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p [class.om-external-link]="externalUrl">
        {{ content }}
      </p>
      @if (areActionsShown) {
        <mat-card-actions>
          @if (declineUrl) {
            <button mat-button (click)="respondToRequest(declineUrl)" class="is-warning">Decline</button>
          }
          @if (acceptUrl) {
            <button mat-button (click)="respondToRequest(acceptUrl)">Accept</button>
          }
        </mat-card-actions>
      }
    </mat-card-content>
  </mat-card>
</ng-template>
