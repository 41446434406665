import { Component, OnDestroy, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { IToolPanelParams } from 'ag-grid-community';
import { Observable, Subject, filter, map, takeUntil, tap } from 'rxjs';
import { AgGridPanel, BaseSidebarConfig, FilterSidebarConfig } from '../../ag-grid-panels.model';
import { FilterSidebarItemConfig, FilterType } from '../../models/ag-grid-panel.model';
import { AgGridPanelsService } from '../../services/ag-grid-panels.service';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { SelectMultipleFilterComponent } from '../select-multiple-filter/select-multiple-filter.component';
import { SelectSingleFilterComponent } from '../select-single-filter/select-single-filter.component';
import { SizeAndCurrencyFilterComponent } from '../size-and-currency-filter/size-and-currency-filter.component';

export interface ToolPanel extends BaseSidebarConfig {
  columns: Observable<FilterSidebarItemConfig[]>;
}

@Component({
  standalone: false,
  selector: 'om-filter-sidebar-panel',
  templateUrl: './filter-sidebar-panel.component.html',
  styleUrls: ['./filter-sidebar-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FilterSidebarPanelComponent implements IToolPanelAngularComp, OnDestroy {
  private ngOnDestroy$ = new Subject<void>();

  private params!: IToolPanelParams;
  rowData: any;
  selectedFilters: Record<string, any> = {};

  public filterType = FilterType;

  isInitialised = false;

  @ViewChildren(SizeAndCurrencyFilterComponent)
  sizeAndCurrencyFilterComponents: QueryList<SizeAndCurrencyFilterComponent>;
  @ViewChildren(SelectSingleFilterComponent)
  singleSelectComponents: QueryList<SelectSingleFilterComponent>;
  @ViewChildren(SelectMultipleFilterComponent)
  multipleSelectComponents: QueryList<SelectMultipleFilterComponent>;
  @ViewChildren(DateFilterComponent)
  dateFilterComponents: QueryList<DateFilterComponent>;

  config: FilterSidebarConfig;
  model: Record<string, any>;
  columnConfigs: FilterSidebarItemConfig[];

  constructor(private agGridPanelsService: AgGridPanelsService) {}

  agInit(params: IToolPanelParams): void {
    setTimeout(() => this.refresh(params));
  }

  refresh(params: IToolPanelParams): boolean | void {
    this.params = params;
    this.params.api.addEventListener('modelUpdated', this.agGridLoaded.bind(this));
    this.config = this.agGridPanelsService.getPanelConfig(params, AgGridPanel.FilterSideBar);

    this.config.columns$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        map(columns => JSON.parse(JSON.stringify(columns))),
        map(columns => {
          return this.agGridPanelsService.generateFilterColumnConfig(columns, this.params.context.gridOptions);
        }),
        tap(columnConfigs => {
          this.columnConfigs = columnConfigs;
          this.isInitialised = true;
        }),
      )
      .subscribe();
    this.config.model$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        filter(model => !!model),
        map(model => JSON.parse(JSON.stringify(model))),
        tap(model => {
          this.model = model;
        }),
      )
      .subscribe();
  }

  agGridLoaded(): void {
    if (!this.isInitialised) {
      const gridOptions = this.params.context.gridOptions;

      const columns$ = gridOptions.sideBar?.toolPanels.find(
        (option: ToolPanel) => option.id === AgGridPanel.FilterSideBar,
      ).columns$;

      const updatedFilterColOption$ = this.agGridPanelsService.generateOptions(columns$, gridOptions);

      updatedFilterColOption$.subscribe((mappedFilterColOptions: FilterSidebarItemConfig[]) => {
        if (mappedFilterColOptions) {
          this.columnConfigs = JSON.parse(JSON.stringify(mappedFilterColOptions));
        }
      });
      this.isInitialised = true;
    }
  }

  acceptFilters(selectedFilter: any) {
    this.selectedFilters[selectedFilter.id] = {
      value: selectedFilter.filters,
      label: selectedFilter.label,
    };
  }

  submitFilter() {
    const mappedFilters = Object.entries(this.selectedFilters).reduce((acc: any, [key, data]) => {
      if (data.value) {
        if (Array.isArray(data.value)) {
          if (data.value.length) {
            acc[key] = {
              value: data.value,
              label: data.label,
            };
          }
        } else {
          acc[key] = {
            value: data.value,
            label: data.label,
          };
        }
      }
      return acc;
    }, {});
    this.params.context.applyAgGridFilter(mappedFilters);
  }

  resetFilter(): void {
    this.columnConfigs.forEach(columnConfig => {
      switch (columnConfig.type) {
        case FilterType.Date:
          this.reset(this.dateFilterComponents);
          break;
        case FilterType.SelectMultiple:
          this.reset(this.multipleSelectComponents);
          break;
        case FilterType.SelectSingle:
          this.reset(this.singleSelectComponents);
          break;
        case FilterType.SizeAndCurrency:
          this.reset(this.sizeAndCurrencyFilterComponents);
          break;
        default:
          return;
      }
    });
  }

  reset(components: any): void {
    components.forEach((component: any) => {
      component.reset();
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
