import { Pipe, PipeTransform } from '@angular/core';

export enum ElementValueType {
  Default = 'default',
  Richtext = 'richtext',
}

@Pipe({
  standalone: false,
  name: 'elementTypeFromValue',
})
export class ElementTypeFromValuePipe implements PipeTransform {
  transform(value: any): string {
    if (value != null && typeof value === 'object' && 'content' in value && JSON.stringify(value).startsWith('{')) {
      return ElementValueType.Richtext;
    }

    return ElementValueType.Default;
  }
}
