<table mat-table [dataSource]="tableData" [trackBy]="trackBy.index">
  <ng-container matColumnDef="labels">
    <th mat-header-cell *matHeaderCellDef>{{ labelHeader }}</th>
    <td mat-cell *matCellDef="let element; let i = index" (click)="focusInput(i)">{{ element.label }}</td>
  </ng-container>

  <ng-container matColumnDef="inputs">
    <th mat-header-cell *matHeaderCellDef>{{ inputHeader }}</th>
    <td mat-cell *matCellDef="let element; let i = index">
      <om-basic-input-container
        [item]="element.value"
        [type]="type"
        [isTable]="true"
        [context]="context"
        (itemChange)="onInputChange($event, i)"
      ></om-basic-input-container>
    </td>
  </ng-container>
  @if (labelHeader || inputHeader) {
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  }
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
</table>
