@if (actions?.length) {
  @if (singleAction && !params.showSingleActionOnMenu) {
    <div [omTooltip]="singleAction.label">
      @if (singleAction.url && !singleAction.download) {
        <a
          mat-icon-button
          class="is-flat"
          [ngClass]="singleAction.class"
          [routerLink]="singleAction.url | routing: 'routerLink'"
          [queryParams]="singleAction.url | routing: 'queryParams' : { referrer: 'agGridAction' }"
        >
          <mat-icon [svgIcon]="singleAction.icon"></mat-icon>
        </a>
      } @else if (singleAction.url) {
        <a [href]="singleAction.url" download class="om-hide-external-link-icon" mat-menu-item>
          @if (singleAction.icon) {
            <mat-icon [svgIcon]="singleAction.icon"></mat-icon>
          }
          <span>{{ singleAction.label }}</span>
        </a>
      } @else {
        <button mat-icon-button class="is-flat" [ngClass]="singleAction.class" (click)="onClick(singleAction, $event)">
          <mat-icon
            [svgIcon]="singleAction.icon"
            [matBadge]="params.value"
            [matBadgeHidden]="!params.value"
            matBadgeSize="small"
            class="badge-is-alert is-small"
            aria-hidden="false"
            [attr.aria-label]="params.value"
          ></mat-icon>
        </button>
      }
    </div>
  } @else {
    <button mat-icon-button class="is-flat" [matMenuTriggerFor]="menu" (menuOpened)="onOpen()">
      <mat-icon svgIcon="more"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      @for (action of actions; track action.label) {
        <div>
          @if (action.url && !action.download) {
            <a
              mat-menu-item
              [routerLink]="action.url | routing: 'routerLink'"
              [queryParams]="action.url | routing: 'queryParams' : { referrer: 'agGridAction' }"
              [ngClass]="action.class"
            >
              @if (action.icon) {
                <mat-icon [svgIcon]="action.icon"></mat-icon>
              }
              <span>{{ action.label }}</span>
            </a>
          } @else {
            @if (action.url) {
              <a [href]="action.url" download class="om-hide-external-link-icon" mat-menu-item>
                @if (action.icon) {
                  <mat-icon [svgIcon]="action.icon"></mat-icon>
                }
                <span>{{ action.label }}</span>
              </a>
            } @else {
              <button mat-menu-item (click)="onClick(action, $event)" [ngClass]="action.class">
                @if (action.icon && !areGroupIconsHidden) {
                  <mat-icon [svgIcon]="action.icon"></mat-icon>
                }
                <span>{{ action.label }}</span>
              </button>
            }
          }
        </div>
      }
      @if (loading) {
        <om-loading> </om-loading>
      }
    </mat-menu>
  }
}
