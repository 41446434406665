import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { DynamicHttpAction, DynamicRequestAction } from '../../elements/models/dynamic-action.model';
import { PopupService } from '../../elements/services/popup.service';
import { RoutingService } from '../routing/routing.service';
import { CoreApiService } from './core-api.service';
import { HttpMethod } from './http.enum';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    private coreApiService: CoreApiService,
    private popupService: PopupService,
    private routingService: RoutingService,
  ) {}

  handleRequest(action: DynamicRequestAction | DynamicHttpAction, method?: HttpMethod, body = ''): Observable<any> {
    return this.coreApiService.callApi(action.url, body, method ?? action.method).pipe(
      tap(() => {
        if (action.success_message) {
          this.popupService.message(action.success_message);
        }
        if (action.success_url) {
          this.routingService.routeTo(action.success_url);
        }
      }),
      catchError(error => {
        this.popupService.error('Unable to perform action. Please try again later');
        return throwError(error);
      }),
    );
  }
}
