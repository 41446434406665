import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { UtilService } from '@morpho/core';
import { ElementValueType } from '@morpho/dynamic-form';
import { EditorType } from '@morpho/rich-text';
import { ActivityCardType, TradeActivity } from 'apps/bankangle/src/app/features/trades/models';

@Component({
  standalone: false,
  selector: 'om-activity-card',
  templateUrl: './activity-card.component.html',
})
export class ActivityCardComponent implements AfterViewInit {
  @ViewChildren('titleClamp', { read: ElementRef }) titleClamps: QueryList<ElementRef>;

  @Input()
  set config(config: TradeActivity) {
    this.actor = config.actor.full_name;
    this.date = this.utilService.convertFromBackendDateStringFormat(config.datetime, 'datetime');
    this.title = config.title;
    this.image = this.utilService.processBackendResource(config.square_logo);
    this.teamName = config.actor.team_name;
    this._config = config;
  }
  get config() {
    return this._config;
  }

  EditorType = EditorType;

  ngAfterViewInit(): void {
    // Overflowing custom content titles are cut off and so are missing the last end quote.
    // Add end quote back and other styling in this case.
    for (const titleClamp of this.titleClamps) {
      const titleElem = titleClamp.nativeElement;

      if (titleElem.scrollHeight > titleElem.offsetHeight) {
        titleElem.parentElement.classList.add('is-overflowing');
      }
    }
  }

  readonly activityCardType = ActivityCardType;
  readonly elementValueType = ElementValueType;

  _config: TradeActivity;

  actor: string;
  date: string;
  title: string;
  image: string;
  teamName: string;

  previewHeight = '50px';
  clickedLinks: Record<string, boolean> = {};

  constructor(private utilService: UtilService) {
    const savedLinks = localStorage.getItem('activityCardLinks');
    if (savedLinks) {
      this.clickedLinks = JSON.parse(savedLinks);
    }
  }

  markAsClicked(url: string) {
    this.clickedLinks[url] = true;
    localStorage.setItem('activityCardLinks', JSON.stringify(this.clickedLinks));
  }
}
