import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';
import { FileInputComponent } from '../file-input/file-input.component';

@Component({
  standalone: false,
  selector: 'om-drag-file-input',
  templateUrl: './drag-file-input.component.html',
  styleUrls: ['./drag-file-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: getCustomFormFieldProviders(forwardRef(() => forwardRef(() => DragFileInputComponent))),
})
export class DragFileInputComponent extends FileInputComponent implements OnInit, OnDestroy {
  @HostBinding('class.om-drag-file-dropzone') isInFileDropzone = false;

  @Input() buttonText: string;
  @Input() helpText: string;
  @Input() variant: 'standard' | 'full' | 'slim';

  @Input()
  get isDropZone(): boolean {
    return this._isDropZone;
  }
  set isDropZone(isDropZone: boolean) {
    this._isDropZone = coerceBooleanProperty(isDropZone);
  }
  private _isDropZone: boolean;

  removeUploadFile() {
    this.value = null;
  }

  @HostListener('window:dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isInFileDropzone = true;
  }

  @HostListener('window:dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    if (event.relatedTarget === null) {
      event.preventDefault();
      event.stopPropagation();
      this.isInFileDropzone = false;
    }
  }

  @HostListener('window:drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isInFileDropzone = false;
    const target = event.target as HTMLElement;
    const dropzone = target.closest('.om-drag-file-dropzone__dropzone');
    if (dropzone) {
      const files = event.dataTransfer?.files;
      this.onFilesAdded(files);
    }
  }
}
