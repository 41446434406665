<span [omTooltip]="tooltip">
  @if (action.type === 'redirect') {
    @if (!isLinkStyle) {
      @if (action.external) {
        <a mat-button [disabled]="disabled" [ngClass]="buttonClass" [href]="action.url | safeUrl" target="_blank">{{
          action.text
        }}</a>
      } @else {
        <a mat-button [disabled]="disabled" [ngClass]="buttonClass" [routerLink]="action.url">{{ action.text }}</a>
      }
    } @else {
      @if (action.external) {
        <a [ngClass]="buttonClass" [href]="action.url | safeUrl" target="_blank">{{ action.text }}</a>
      } @else {
        <a [ngClass]="buttonClass" [routerLink]="action.url">{{ action.text }}</a>
      }
    }
  } @else {
    @if (!(isLinkStyle || isMenuItem)) {
      <button mat-button [ngClass]="buttonClass" [disabled]="disabled" (click)="onClick(action)">
        {{ action.text }}
        @if (isLoading) {
          <mat-spinner></mat-spinner>
        }
      </button>
    } @else {
      <a
        [ngClass]="buttonClass"
        [class.is-menu-item]="isMenuItem"
        class="om-hide-external-link-icon"
        target="_blank"
        (click)="onClick(action)"
        >{{ isMenuItem ? action.text || action.link_text : action.link_text || action.text }}</a
      >
    }
  }
</span>
