import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Sidenav } from '../sidenav.models';

@Component({
  standalone: false,
  selector: 'om-sidenav-panel',
  templateUrl: './sidenav-panel.component.html',
  styleUrls: ['./sidenav-panel.component.scss'],
})
export class SidenavPanelComponent {
  @Input() panelName: string;
  @Input() headerIcon: string;
  @Input() headerTextTemplate: string;
  @Input() itemsTemplate: string;
  @Input() isAlert: boolean;
  @Input() activePanel: string;
  @Input() isPanelOpen: { [key: string]: boolean };
  @Input() sidenav$: Observable<Sidenav>;
  @Input() inputsSectionClass: string;
}
