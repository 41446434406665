import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss'],
})
export class TextBlockComponent {
  @Input()
  set previewLength(previewLength: number) {
    this._previewLength = previewLength;
  }
  get previewLength() {
    return this._previewLength;
  }
  private _previewLength = 440;

  @Input() text: string;

  isExpanded = false;

  constructor() {}

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
