import {
  ColDef,
  GetContextMenuItemsParams,
  GridOptions,
  MenuItemDef,
  ProcessCellForExportParams,
  SideBarDef,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { scrollbarWidth } from '../../elements/constants/display';
import { KeyCode } from '../../elements/constants/system';

// columns that are frontend only, with no backend data
export const COLUMN_PREFIX_NO_DATA = 'fe__';
export const COLUMN_PREFIX_TENOR = 't__';
export const COLUMN_PREFIX_STRUCTURE = 's__';
export const COLUMN_PREFIX_QUOTE_TYPE = 'qt__';
export const EMPTY_BENCHMARK_INPUT = 'Select bond ▾';
export const EMPTY_RATING = 'NR';
export const EMPTY_LEVEL = '-';
export const REVERSE_ENQUIRY_LEVEL = 'R/E';
export const FUNDING_BASIS_FIXED = 'FIXED';

export const COLUMN_WIDTH_PRICED = 75;
export const COLUMN_WIDTH_MS_PRICED = 125;

export const FrontendColumnDefinition = {
  Checkbox: `${COLUMN_PREFIX_NO_DATA}checkbox`,
  RowExpand: `${COLUMN_PREFIX_NO_DATA}rowExpand`,
  RowLevelActions: `${COLUMN_PREFIX_NO_DATA}rowLevelActions`,
  Comments: `${COLUMN_PREFIX_NO_DATA}Comments`,
  Label: `${COLUMN_PREFIX_NO_DATA}Label`,
};

const agGridRowDefault: GridOptions = {
  rowHeight: 30,
  rowStyle: { 'line-height': '28px' },
};
export const agGridRowShorter: GridOptions = {
  rowHeight: 24,
  rowStyle: { 'line-height': '22px' },
};
export const agGridRowTaller: GridOptions = {
  rowHeight: 40,
  rowStyle: { 'line-height': '38px' },
};

const agGridSideBarOptions: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressColumnFilter: true,
        suppressPivotMode: true,
        suppressRowGroups: true,
        suppressValues: true,
      },
    },
  ],
};
const processCellForClipboard = (params: ProcessCellForExportParams): string => {
  const colDef: ColDef = params.column.getColDef();
  const exportFunction: ColDef['omExportMapping'] | undefined = colDef.omExportMapping;
  return exportFunction ? exportFunction(params.value, params.node?.data) : params.value;
};

const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
  return ['copy', 'copyWithHeaders'];
};

const suppressKeyboardEvent = (params: SuppressKeyboardEventParams): boolean => {
  const { editing, event, api, node, column } = params;

  if (
    !editing ||
    ![KeyCode.Right, KeyCode.Left, KeyCode.Up, KeyCode.Down, KeyCode.Enter].includes(event.key as KeyCode)
  ) {
    return false;
  }

  api.stopEditing();
  return false;
};
// https://www.ag-grid.com/javascript-grid-properties/
export const agGridOptionsBase: GridOptions = {
  defaultColDef: {
    enableCellChangeFlash: true,
    filter: 'omSetFilter',
    filterParams: {
      suppressMiniFilter: false,
    },
    menuTabs: ['filterMenuTab', 'generalMenuTab'],
    resizable: true,
    sortable: true,
    suppressKeyboardEvent,
  },
  headerHeight: 36,
  rowBuffer: 20,
  ...agGridRowDefault,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  suppressRowDeselection: true,
  suppressCopyRowsToClipboard: true,
  alwaysShowHorizontalScroll: true,
  alwaysShowVerticalScroll: true,
  scrollbarWidth,
  sideBar: agGridSideBarOptions,
  sortingOrder: ['asc', 'desc'],
  enableRangeSelection: true,
  suppressColumnVirtualisation: false,
  suppressGroupRowsSticky: true,
  singleClickEdit: true,
  tooltipShowDelay: 0,
  getContextMenuItems,
  processCellForClipboard,
};

export const agGridOptionsAutoHeight: GridOptions = {
  domLayout: 'autoHeight',
};

export const agGridOptionsHeaderMenuAll: GridOptions = {
  defaultColDef: {
    ...agGridOptionsBase.defaultColDef,
    menuTabs: [...(agGridOptionsBase.defaultColDef?.menuTabs ?? []), 'columnsMenuTab'],
  },
};

export const agGridOptionsHeaderMenuFilterOnly: GridOptions = {
  defaultColDef: {
    ...agGridOptionsBase.defaultColDef,
    menuTabs: ['filterMenuTab'],
  },
};

export const agGridOptionsNoHeaderMenu: GridOptions = {
  defaultColDef: {
    filter: false,
    menuTabs: [],
  },
};

export const agGridOptionsNoSidebar: GridOptions = {
  sideBar: null,
};

export const agGridOptionsServerSideDatasource: GridOptions = {
  rowModelType: 'serverSide',
  suppressServerSideInfiniteScroll: false,
  maxConcurrentDatasourceRequests: 1,
};

export const agGridSidebarFilterBase = {
  id: 'filter',
  labelDefault: 'Custom Filters',
  labelKey: 'filter',
  iconKey: 'custom-stats',
  toolPanel: 'omFilterSidebar',
};

export const agGridSidebarColumnBase = {
  id: 'custom-column',
  labelDefault: 'Custom Columns',
  labelKey: 'custom-column',
  iconKey: 'custom-stats',
  toolPanel: 'omColumnSidebar',
};

export const agGridSidebarColumnVisibilityBase = {
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  toolPanelParams: {
    suppressColumnFilter: true,
    suppressPivotMode: true,
    suppressRowGroups: true,
    suppressValues: true,
  },
};

export const agGridSidebarGroupBase = {
  id: 'group',
  labelDefault: 'Group Filters',
  labelKey: 'group',
  iconKey: 'custom-stats',
  toolPanel: 'omGroupSidebar',
};
