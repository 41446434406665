@if (helpText) {
  <div class="help-text">
    <mat-icon svgIcon="help_info"> </mat-icon>
    <span>{{ fieldConfig.help }}</span>
  </div>
}
@if (!value || isDropZone) {
  <div class="om-drag-file-dropzone-box" [class.is-slim-box]="variant === 'slim'">
    <input #fileInput type="file" [accept]="accept" [multiple]="multiple" (change)="onFileInputChange($event)" />
    <div class="om-drag-file-dropzone__dropper">
      @if (variant === 'full') {
        <mat-icon svgIcon="upload"></mat-icon>
        <p>Drag & Drop a PDF or DOCX file<br />or</p>
      }

      <button mat-button class="is-accent" [class.is-strong]="variant === 'full'" (click)="$event.preventDefault()">
        <b> {{ buttonText ?? 'Browse Files' }}</b>
      </button>
    </div>
    <div class="om-drag-file-dropzone__dropzone">
      <div>
        <mat-icon svgIcon="document"></mat-icon>
        <p>Drop files here</p>
      </div>
    </div>
  </div>
} @else {
  <div class="upload-card" [class.is-slim-box]="variant === 'slim'">
    <mat-icon svgIcon="file_description"></mat-icon>
    @if (filename) {
      <span class="om-filename">
        <span>{{ filename.start }}</span>
        <span>{{ filename.end }}</span>
      </span>
    }

    <button mat-icon-button class="is-warning">
      <mat-icon svgIcon="remove"></mat-icon>
    </button>
  </div>
}
