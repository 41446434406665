import { FormGroup } from '@angular/forms';
import { DialogHeightMode, DialogSize, DynamicActionBase, HttpMethod, ValueAndLabel } from '@morpho/core';
import { FormConfig, FormInputConfig, RichTextOption } from '@morpho/form';

export type DynamicFormModalInfoSection = {
  title: string;
  message?: string;
  list?: string[];
  url?: ValueAndLabel;
};

export type DynamicFormModalSubmitValue = {
  formValue: { [key: string]: any };
  response?: { [key: string]: any };
  error?: any;
};

export interface DynamicFormModalParams extends DynamicActionBase {
  type?: 'dynamic_modal';
  formConfig?: FormConfig;
  title: string;
  subtitle?: string;
  supertitle?: string;
  text?: string;
  submit_text?: string;
  message?: string | RichTextOption;
  disabled?: boolean;
  options?: FormInputConfig[];
  url?: string;
  optionsUrl?: string;
  submit_mapping?: (formValue: any) => any;
  method?: HttpMethod;
  success_message?: string;
  success_url?: string;
  status?: 'negative' | 'positive' | 'neutral';
  extra_info?: {
    sections: DynamicFormModalInfoSection[];
  };
  primary_info?: {
    sections: DynamicFormModalInfoSection[];
  };
  dialog_size?: DialogSize;
  dialog_height_mode?: DialogHeightMode;
  notice?: string;
  toggle_buttons?: {
    initial_value: string | number | boolean;
    options: ValueAndLabel[];
    config: { [key: string]: CreateTradeModalToggleButton };
  };
}

export interface FormModelParams {
  options?: any[] | FormInputConfig[];
  url?: string;
  optionsUrl?: string;
}

export interface CreateTradeModalToggleButton {
  text: string;
  options?: (FormInputConfig | any)[];
  url?: string;
  optionsUrl?: string;
  submitAction?: (data: any) => any;
}

export type DynamicFormModalCloseEvent =
  | {
      formValue: { [key: string]: any };
      apiResponse?: any;
      fieldMapping?: DynamicFormFieldMapping;
      dialogHeight?: string;
    }
  | undefined;

export interface DynamicFormModel {
  form: FormGroup;
  fieldMapping: DynamicFormFieldMapping;
  commentMapping?: DynamicFormFieldMapping;
  sections: DynamicFormSection[];
  sectionsForDisplay: DynamicFormSection[];
  savedFields: string[];
  savedFormValue: Record<string, any>;
  isUnsaved: boolean;
  progress: number;
  errorCount: number;
  partialSaveAllowed?: boolean;
  editedInputs: FlexibleEditInput[];
}

export enum TagTypes {
  Input = 'input',
  Text = 'content',
}

export enum EditType {
  Added = 'added',
  Changed = 'changed',
  Unchanged = 'unchanged',
}

export interface FlexibleEditInput {
  fieldName: string;
  type: EditType;
  isImpacted: boolean;
  isPersistent?: boolean;
}

export interface DynamicFormSection {
  name: string;
  label: string;
  fieldNames: string[];

  errorCount?: number;
  progress?: number;
  unresolvedComments?: number;
  validFields?: number;
  subsections?: DynamicFormSection[];
}

export type DynamicFormFieldMapping = { [key: string]: FormInputConfig };
