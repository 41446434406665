@if (text) {
  <p>
    {{ isExpanded || text.length <= previewLength ? text : (text | slice: 0 : previewLength) + '...' }}
    @if (text.length > previewLength) {
      <button mat-button class="is-text is-accent" (click)="toggleExpanded()">
        {{ isExpanded ? 'Less' : 'See More' }}
      </button>
    }
  </p>
}
