import { Component, Input, OnInit } from '@angular/core';
import { getCdnUrl } from '@morpho/core';

enum PlaceHolderType {
  search = 'search',
  file = 'file',
}

@Component({
  standalone: false,
  selector: 'om-page-placeholder',
  templateUrl: './page-placeholder.component.html',
})
export class PagePlaceholderComponent implements OnInit {
  private readonly iconMap: { [key in PlaceHolderType]: string } = {
    file: getCdnUrl('assets/img/placeholder/unsupported-document-type.svg'),
    search: getCdnUrl('assets/img/placeholder/search.svg'),
  };
  private readonly altTextMap: { [key in PlaceHolderType]: string } = {
    file: 'An icon depicting an unsupported document type',
    search: 'An icon depicting a search',
  };

  readonly PlaceholderType = PlaceHolderType;
  icon: string;
  iconAltText: string;

  @Input() subtitle: string;
  @Input() title: string;
  @Input() url: string;
  @Input() message: string;
  @Input() type: PlaceHolderType;

  ngOnInit(): void {
    this.icon = this.iconMap[this.type];
    this.iconAltText = this.altTextMap[this.type];
  }
}
