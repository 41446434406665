import {
  Ancestor,
  BasePoint,
  Editor,
  EditorAboveOptions,
  EditorNodesOptions,
  Element,
  Node,
  NodeEntry,
  Path,
  Point,
  Transforms,
} from 'slate';
import { ElementType } from '../constants/rich-text.constants';
import { RichTextParagraph } from '../models/rich-text.model';

function parent(editor: Editor, path: number[]): NodeEntry<Node> {
  return Editor.node(editor, Path.parent(path));
}

function previousSibling(editor: Editor, path: number[]): NodeEntry<Node> | [null, null] {
  const nextPath = [...path];
  nextPath[nextPath.length - 1] -= 1;
  try {
    return Editor.node(editor, nextPath);
  } catch {
    return [null, null];
  }
}

function nextSibling(editor: Editor, path: number[]): NodeEntry<Node> | [null, null] {
  const nextPath = [...path];
  nextPath[nextPath.length - 1] += 1;
  try {
    return Editor.node(editor, nextPath);
  } catch {
    return [null, null];
  }
}

function getPlainText(editor: Editor): string {
  // TODO: At the moment this will return empty string for an empty table in the editor
  // TODO: Is this correct? (i.e. should a field with an empty table be seen as an 'empty' value...?)
  const childStrings = editor.children
    .map(node => {
      return Node.string(node);
    })
    .join('');
  return childStrings;
}

function getNode(editor: Editor, type: ElementType, options: EditorNodesOptions<Node> = {}): NodeEntry<Node> {
  const [node] = Editor.nodes(editor, {
    match: (n: any) => n.type === type,
    mode: 'lowest',
    ...options,
  });
  return node ?? [null, null];
}

function getNodes(editor: Editor, type: ElementType, options: EditorNodesOptions<Node> = {}): NodeEntry<Node>[] | null {
  const nodes = Array.from(
    Editor.nodes(editor, {
      match: (n: any) => n.type === type,
      mode: 'lowest',
      ...options,
    }),
  );
  return nodes;
}

function getBlock(editor: Editor, options: EditorAboveOptions<any> = {}): NodeEntry<Ancestor> | undefined {
  return Editor.above(editor, {
    match: node => {
      return Element.isElement(node) && ![ElementType.Paragraph, ElementType.Hyperlink].includes(node.type);
    },
    mode: 'lowest',
    ...options,
  });
}

function getParagraphBlocks(editor: Editor): NodeEntry<RichTextParagraph>[] {
  if (!editor.selection) {
    return [];
  }

  return Array.from(
    Editor.nodes(editor, {
      at: editor.selection,
      match: (node, path) => {
        const isParagraph = Element.isElement(node) && node.type === ElementType.Paragraph;
        if (!isParagraph) {
          return false;
        }
        if (path.length === 1) {
          return true;
        }
        const [parentNode] = parent(editor, path);
        return Element.isElement(parentNode) && parentNode.type !== ElementType.ListItem;
      },
      mode: 'lowest',
    }),
  );
}

function isPointAtTheStart(editor: Editor, entry: NodeEntry<Ancestor | Node>, point?: BasePoint): boolean {
  const [node, path] = entry;
  return !!(point && Point.equals(point, Editor.start(editor, path)));
}

function isPointAtTheEnd(editor: Editor, entry: NodeEntry<Ancestor | Node>, point?: BasePoint): boolean {
  const [node, path] = entry;
  return !!(point && Point.equals(point, Editor.end(editor, path)));
}

function insertNestedBlock(editor: Editor, node: Node | Node[], path: number[]): void {
  const insert = (node: Node, path: number[]) => {
    if (Element.isElement(node) && node.children?.length) {
      const children = [...node.children];
      node.children = [];

      Transforms.insertNodes(editor, node, { at: path });
      children.forEach((child, index) => {
        insert(child, [...path, index]);
      });
    } else {
      Transforms.insertNodes(editor, node, { at: path });
    }
  };

  const toInsert = JSON.parse(JSON.stringify(node));
  if (Array.isArray(toInsert)) {
    toInsert.reverse().forEach(n => insert(n, path));
  } else {
    insert(toInsert, path);
  }
}

export const EditorHelper = {
  parent,
  previousSibling,
  nextSibling,
  getBlock,
  getParagraphBlocks,
  getPlainText,
  getNode,
  getNodes,
  insertNestedBlock,
  isPointAtTheStart,
  isPointAtTheEnd,
};
