@if (model) {
  <h2 mat-dialog-title>
    {{ model.title }}
    <button mat-icon-button (click)="onClose()"><mat-icon svgIcon="close"></mat-icon></button>
  </h2>
  <div mat-dialog-content>
    @if (model.banner) {
      <div class="om-banner is-alert">{{ model.banner }}</div>
    }
    <img [src]="model.logo" />
    <p>
      @for (detail of model.details; track detail) {
        <span class="is-block">{{ detail }}</span>
      }
    </p>
    <aside class="is-secondary">
      @if (model.originatorType) {
        <section>
          <strong>{{ model.originatorType }}:</strong>
          <p>{{ model.originatorName }}</p>
        </section>
      }
      @if (model.datetime) {
        <section>
          <strong>Date:</strong>
          <p>{{ model.datetime }}</p>
        </section>
      }
      @if (model.documents?.length) {
        <section>
          <strong>{{ model.documents?.length > 1 ? 'Documents' : 'Document' }}:</strong>
          <ol>
            @for (document of model.documents; track document) {
              <li>{{ document.name }}</li>
            }
          </ol>
        </section>
      }
      @if (model.message) {
        <section>
          <strong>Comment:</strong>
          <p>{{ model.message }}</p>
        </section>
      }
    </aside>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onPickUp()">{{ model.action }}</button>
  </div>
}
