import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import {
  DynamicHttpAction,
  DynamicRedirectAction,
  DynamicRequestAction,
  HttpMethod,
  RequestService,
  RoutingService,
} from '@morpho/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { DynamicFormModalParams } from '@morpho/dynamic-form';
import { finalize } from 'rxjs';
import { TradesDialogService } from '../../../core/services/trades-dialog.service';
import { TradeWorkingGroupModalAction } from '../../../features/trades/components/trade-working-group-modal/trade-working-group-modal.component';

export type DynamicActionParams = {
  class?: string;
  link_text?: string;
  tooltip_text?: string;
  text?: string;
  disabled?: boolean;
} & (
  | BooleanPopupParams
  | DynamicFormModalParams
  | DynamicRequestAction
  | DynamicRedirectAction
  | DynamicHttpAction
  | TradeWorkingGroupModalAction
);

@Component({
  standalone: false,
  selector: 'om-dynamic-action',
  templateUrl: './dynamic-action.component.html',
  styleUrls: ['./dynamic-action.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicActionComponent {
  @HostBinding('class.is-loading') isLoading = false;

  @Input() action: DynamicActionParams;
  @Input() buttonClass: string;
  @Input() isLinkStyle: boolean;
  @Input() isMenuItem: boolean;

  @Input('disabled')
  get disabled(): boolean {
    return !!(this._disabled || this.action.disabled);
  }
  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }
  private _disabled: boolean;

  @Input('tooltip')
  get tooltip(): string {
    return this.disabled ? this._tooltip || this.action.tooltip_text || '' : '';
  }
  set tooltip(tooltip: string) {
    this._tooltip = tooltip;
  }
  private _tooltip: string;

  constructor(
    private dialogService: DialogService,
    private tradesDialogService: TradesDialogService,
    private requestService: RequestService,
    private routingService: RoutingService,
  ) {}

  private handleRequest(action: DynamicRequestAction | DynamicHttpAction, method: HttpMethod) {
    this.isLoading = true;
    this.requestService
      .handleRequest(action, method)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  onClick(action: DynamicActionParams) {
    switch (action.type) {
      case HttpMethod.GET:
      case HttpMethod.POST:
      case HttpMethod.PATCH:
      case HttpMethod.PUT:
      case HttpMethod.DELETE:
        // todo why do we have 'request' and this
        this.handleRequest(action, action.type);
        break;
      case 'request':
        this.handleRequest(action, action.method);
        break;
      case 'dynamic_modal':
        this.dialogService.dynamicFormModal(action).subscribe(response => {
          const modal = response?.apiResponse?.modal;
          if (modal) {
            switch (modal.type) {
              case 'simple_modal':
                this.dialogService.simplePopup({ ...modal, dialog_height: response?.dialogHeight });
                break;
              default:
                break;
            }
          }
        });
        break;
      case 'boolean_modal':
        this.dialogService.booleanPopup(action);
        return;
      case 'redirect':
        // handled in the template with <a> tag
        return;
      case 'working_group_modal':
        this.tradesDialogService.tradeWorkingGroupModal();
        return;
      default:
        return;
    }
  }
}
