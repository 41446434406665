import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValueAndLabel } from '@morpho/core';
import { FileInputConfig, FormValidator, OptionGroup } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

export interface EventListener {
  type: string;
  action: EventListenerOrEventListenerObject;
}
@Component({
  standalone: false,
  selector: 'om-file-input',
  templateUrl: './file-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => forwardRef(() => FileInputComponent))),
})

/*
  todo:
    Drag events intercepted by embedded pdf view
*/
export class FileInputComponent extends CustomFormFieldControlComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('menuButton') menuButton: ElementRef;
  @ViewChild('matMenu') matMenu: ElementRef;

  @HostBinding('class.om-dropzone') protected isInTransitionDropzone = false;
  @HostBinding('class.om-file-dropzone') protected isInFileDropzone = false;

  // common
  private readonly validFileTypesMapping: { [key: string]: string[] } = {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/msword': ['.doc'],
    'application/pdf': ['.pdf'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  };

  filename: { start: string; end: string } | null;

  private windowDragCounter = 0;
  private hostDragCounter = 0;
  private isInitialised = false;

  @Input() fieldConfig: FileInputConfig;
  @Input() maxSize: number;

  @Input()
  get history(): OptionGroup[] | null {
    return this._history;
  }
  set history(history: OptionGroup[] | null) {
    this._history = history;
  }

  private _history: OptionGroup[] | null;

  @Input()
  get accept(): string[] {
    return this._accept;
  }
  set accept(values: string[]) {
    if (!values?.length) {
      return;
    }
    this._accept = values;
  }
  private _accept: string[] = [];

  @Input()
  get value(): File | File[] | null {
    return this._value;
  }
  set value(val: File | File[] | null) {
    this.setFileInputValue(val);
    this.emitChanges(true);
    this.isInitialised = true;
  }
  _value: File | File[] | null;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(multiple: boolean) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  private _multiple: boolean;

  @Output() uploadOptionClicked = new EventEmitter<number>();
  @Output() uploadOptionDeleted = new EventEmitter<void>();

  @HostListener('click', ['$event']) onClick(event: Event) {
    if (!event.composedPath().some(element => (element as HTMLElement)?.classList?.contains('help-text'))) {
      // If there is no value or didn't click on delete icon then open dialog
      if (
        !this.value ||
        !event.composedPath().some(element => (element as HTMLElement)?.classList?.contains('mat-mdc-icon-button'))
      ) {
        if (!this.history) {
          this.fileInput?.nativeElement.click();
        } else {
          this.menuButton.nativeElement.click();
        }
      } else {
        this.setFileInputValue(null);
        this.uploadOptionDeleted.emit();
        this.emitChanges();
      }
    }
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isInTransitionDropzone = true;
    this.isInFileDropzone = true;
    this.hostDragCounter++;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isInTransitionDropzone = false;
    this.hostDragCounter--;
    if (this.hostDragCounter === 0) {
      this.isInFileDropzone = false;
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isInTransitionDropzone = false;
    this.isInFileDropzone = false;
    const files = event.dataTransfer?.files;
    this.onFilesAdded(files);
    this.windowDragCounter = 0;
  }

  openFileExplorer() {
    this.fileInput.nativeElement.click();
  }

  onOptionClick(option: ValueAndLabel) {
    const file = new File([], option.label);
    this.uploadOptionClicked.emit(option.value as number);
    this.setFileInputValue(file);
    this.emitChanges();
  }

  maxSizeValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if ((this.multiple && !control.value?.length) || !control.value) {
      return null;
    }

    let greaterThanMax = false;
    if (this.multiple) {
      greaterThanMax = control.value.some((value: any) => value.size > this.maxSize);
    } else {
      greaterThanMax = control.value.size > this.maxSize;
    }

    const formattedBytes = this.utilService.bytesToDisplayString(this.maxSize);
    const message = `Ensure file size is less than ${formattedBytes}`;
    return greaterThanMax ? { maxSize: { value: control.value, message } } : null;
  };

  acceptedFilesValidator = (control: AbstractControl): { [key: string]: any } | null => {
    if ((this.multiple && !control.value?.length) || !control.value) {
      return null;
    }

    let fileNotAccepted = false;
    if (this.multiple) {
      fileNotAccepted = control.value.some((value: any) => !this.isFileAccepted(value));
    } else {
      fileNotAccepted = !this.isFileAccepted(control.value);
    }

    const acceptedTypes = this._accept.join(', ');
    const message = `Ensure file has one of the following extensions: ${acceptedTypes}`;
    return fileNotAccepted ? { notAccepted: { value: control.value, message } } : null;
  };

  isFileAccepted(file: File) {
    if (!this._accept.length) {
      return true;
    }
    const acceptedFileExtensions = this.validFileTypesMapping[file.type];
    if (acceptedFileExtensions) {
      for (const extension of acceptedFileExtensions) {
        if (this._accept.includes(extension)) {
          return true;
        }
      }
    }
    return this._accept.includes(file.type);
  }

  onFileInputChange(event: Event) {
    this.onFilesAdded((event.target as HTMLInputElement)?.files);
    this.fileInput.nativeElement.value = null;
  }

  onFilesAdded(files: FileList | undefined | null) {
    if (files?.length) {
      if (this.multiple) {
        this.setFileInputValue(Array.from(files));
      } else {
        this.setFileInputValue(files[0]);
      }
      this.emitChanges();
    }
  }

  protected setFileInputValue(value: File | File[] | null) {
    this._value = value;
    if (value) {
      if (this.multiple && Array.isArray(value)) {
        this.setFile(value[0]);
      } else if (!Array.isArray(value)) {
        this.setFile(value);
      }
    } else {
      this.filename = null;
      if (this.fileInput) {
        this.fileInput.nativeElement.value = null;
      }
    }
    if (this.isInitialised) {
      this.ngControl.control?.markAsTouched();
    }
  }

  protected setFile(file: File) {
    const charsAtEndOfFilename = 12;
    this.filename = {
      start: file?.name?.slice(0, -charsAtEndOfFilename) || '',
      end: file?.name?.slice(-charsAtEndOfFilename) || '',
    };
  }

  ngOnInit() {
    //this is to prevent the broswer from opening the document if
    // the file is accidentally dropped off dropzone
    document.addEventListener('dragover', e => e.preventDefault());
    document.addEventListener('drop', e => e.preventDefault());

    if (!this.ngControl.control) {
      return;
    }
    let validators: FormValidator[] = [];

    if (this.fieldConfig) {
      validators = this.validationService.createValidators(this.fieldConfig);
    }

    if (this.maxSize) {
      validators.push(this.maxSizeValidator);
    }
    if (this._accept) {
      validators.push(this.acceptedFilesValidator);
    }
    if (!validators.length) {
      return;
    }

    this.ngControl.control?.setValidators(validators);
  }

  ngOnDestroy(): void {
    document.removeEventListener('dragover', e => e.preventDefault());
    document.removeEventListener('drop', e => e.preventDefault());
  }
}
