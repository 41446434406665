export enum NavigationAction {
  CreateFinalTerms = 'CreateFinalTerms',
  CreateTrade = 'CreateTrade',
  ReuseTrade = 'ReuseTrade',
}

export interface NavigationItem {
  id: string;
  label: string;
  class?: string;
  icon?: string;
  url?: string;
  action?: NavigationAction;
  isSectionCollapsable?: boolean;
  options?: NavigationItem[];
  params?: any;
}
