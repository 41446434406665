@if (formConfig?.showFormFieldPrefix) {
  <div class="om-dynamic-form-field-prefix">
    @if (prefixActions) {
      <om-action-menu
        [config]="prefixActions"
        [dataToEmit]="{ fieldConfig: fieldConfig }"
        (selected)="onActionSelected($event)"
      ></om-action-menu>
    }
  </div>
}
@if (fieldConfig.element !== 'data') {
  <mat-form-field
    #formField
    [formGroup]="form"
    [ngClass]="{
      'is-alert': fieldWarningMessage,
      'is-warning': isErrorState,
      'hide-outline': fieldConfig.element === 'button_toggle' || fieldConfig.element === 'drag_file',
    }"
  >
    @if (fieldConfig.label) {
      <mat-label>
        <span [ngClass]="{ 'has-suggestion': fieldConfig.suggestion }">
          {{ fieldConfig.label }}
          @if (fieldConfig.suggestion) {
            <span>{{ fieldConfig.suggestion }}</span>
          }
        </span>
        @if (!fieldConfig.required && fieldConfig.element !== 'label') {
          <span>(optional)</span>
        }
        @if (tooltip) {
          <mat-icon [omTooltip]="tooltip" svgIcon="help"></mat-icon>
        }
      </mat-label>
    }
    @if (hideContent) {
      <om-placeholder-input
        [formControl]="form.controls[fieldConfig.name]"
        [omDynamicFormInput]="inputDirectiveParams"
      ></om-placeholder-input>
    } @else {
      @switch (fieldConfig.element) {
        @case ('primitive') {
          <om-primitive-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [placeholder]="fieldConfig.placeholder"
            [type]="fieldConfig.type"
            [decimalPlaces]="fieldConfig.decimalPlaces"
          ></om-primitive-input>
        }
        @case ('maturity') {
          <om-maturity-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
          ></om-maturity-input>
        }
        @case ('textarea') {
          <textarea
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            matInput
          ></textarea>
        }
        @case ('richtext') {
          <om-rich-text-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [options]="fieldConfig.options"
          ></om-rich-text-input>
        }
        @case ('autocomplete') {
          <om-autocomplete-input
            [fieldConfig]="fieldConfig"
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [options]="fieldConfig.options"
            [placeholder]="fieldConfig.placeholder"
            [multiple]="fieldConfig.multiple"
            [linkedSelection]="fieldConfig.linkedSelection"
            [relaxed]="fieldConfig.relaxed"
            [suggestions]="fieldConfig.suggestions"
            [prefixIcon]="fieldConfig.prefixIcon"
            [suffixIcon]="fieldConfig.suffixIcon"
          >
          </om-autocomplete-input>
        }
        @case ('date') {
          <om-date-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [hideYear]="fieldConfig.hideYear"
            [min]="fieldConfig.min"
            [max]="fieldConfig.max"
            [placeholder]="fieldConfig.placeholder"
          ></om-date-input>
        }
        @case ('date_range') {
          <om-date-range-input
            [formControlName]="fieldConfig.name"
            [omDynamicFormInput]="inputDirectiveParams"
            [hideYear]="fieldConfig.hideYear"
            [min]="fieldConfig.min"
            [max]="fieldConfig.max"
            [placeholder]="fieldConfig.placeholder"
          ></om-date-range-input>
        }
        @case ('datetime') {
          <om-datetime-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [min]="fieldConfig.min"
            [max]="fieldConfig.max"
            [placeholder]="fieldConfig.placeholder"
          ></om-datetime-input>
        }
        @case ('checkbox') {
          <om-checkbox-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [options]="fieldConfig.options"
          ></om-checkbox-input>
        }
        @case ('radio') {
          <om-radio-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [options]="fieldConfig.options"
          ></om-radio-input>
        }
        @case ('button_toggle') {
          <om-button-toggle-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [options]="fieldConfig.options"
          ></om-button-toggle-input>
        }
        @case ('select') {
          <om-select-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [multiple]="fieldConfig.multiple"
            [placeholder]="fieldConfig.placeholder"
            [options]="fieldConfig.options"
          ></om-select-input>
        }
        @case ('file') {
          <om-file-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [fieldConfig]="fieldConfig"
            [accept]="fieldConfig.accept"
            [maxSize]="fieldConfig.maxSize"
          ></om-file-input>
        }
        @case ('drag_file') {
          <om-drag-file-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [fieldConfig]="fieldConfig"
            [accept]="fieldConfig.accept"
            [maxSize]="fieldConfig.maxSize"
            [buttonText]="fieldConfig.buttonText"
            [helpText]="fieldConfig.help"
            [variant]="fieldConfig.variant"
          ></om-drag-file-input>
        }
        @case ('phone') {
          <om-phone-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [fieldConfig]="fieldConfig"
          ></om-phone-input>
        }
        @case ('list') {
          <om-list-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [options]="fieldConfig.options"
            [placeholder]="fieldConfig.placeholder"
            [type]="fieldConfig.type"
            [min]="fieldConfig.min"
            [max]="fieldConfig.max"
          >
          </om-list-input>
        }
        @case ('table') {
          <om-table-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [labels]="fieldConfig.labels"
            [labelPrefix]="fieldConfig.labelPrefix"
            [options]="fieldConfig.options"
            [placeholder]="fieldConfig.placeholder"
            [size]="fieldConfig.size"
            [type]="fieldConfig.type"
          >
          </om-table-input>
        }
        @case ('data_table') {
          <om-data-table-input
            [formControl]="form.controls[fieldConfig.name]"
            [omDynamicFormInput]="inputDirectiveParams"
            [minSize]="fieldConfig.minSize"
            [maxSize]="fieldConfig.maxSize"
            [columns]="fieldConfig.columns"
            [labels]="fieldConfig.labels"
            [labelPrefix]="fieldConfig.labelPrefix"
            [labelHeader]="fieldConfig.labelHeader"
            [getNewRow]="fieldConfig.getNewRow"
          ></om-data-table-input>
        }
        @default {
          <input disabled matInput type="text" [value]="fieldConfig.initial" />
        }
      }
    }

    <!-- todo: mat-hint with *ngIf causing ExpressionChangedAfterItHasBeenCheckedError https://github.com/angular/components/issues/16209 -->
    @if (fieldConfig.hint) {
      <mat-hint>{{ fieldConfig.hint }}</mat-hint>
    }
    @if (isErrorState) {
      <mat-hint [id]="null" class="is-warning">{{ errorMessage }}</mat-hint>
    } @else if (fieldWarningMessage || fieldConfig.warning) {
      <mat-hint [id]="null" class="is-alert"> {{ fieldWarningMessage || fieldConfig.warning }}&nbsp; </mat-hint>
    }
    @if (tagsConfig.length) {
      <mat-hint [id]="null">
        <span> Enabled for: </span>
        @for (tagConfig of tagsConfig; track tagConfig) {
          <mat-chip [class]="'is-' + tagConfig.type">
            <img class="is-transparent" [src]="tagConfig.icon | cdn" />
            <span>{{ tagConfig.text }}</span>
          </mat-chip>
        }
      </mat-hint>
    }

    @if (findActions || editActions) {
      <div class="suffix-action-menu-container">
        @if (findActions) {
          <om-action-menu
            matSuffix
            [config]="findActions"
            [dataToEmit]="{ fieldName: fieldConfig.name }"
            (selected)="onActionSelected($event)"
            (opened)="onMenuOpened()"
            (closed)="onMenuClosed()"
          >
          </om-action-menu>
        }
        @if (editActions) {
          <om-action-menu
            matSuffix
            [config]="editActions"
            [dataToEmit]="{ fieldName: fieldConfig.name }"
            (selected)="onActionSelected($event)"
            (opened)="onMenuOpened()"
            (closed)="onMenuClosed()"
          ></om-action-menu>
        }
      </div>
    }
    @if (fieldConfig.low_confidence && !isLowConfidenceChecked) {
      <span
        [omTooltip]="lowConfidenceTooltip"
        [omTooltipProperties]="lowConfidenceTooltipProperties"
        class="low-confidence-tooltip"
      >
        <button mat-icon-button class="low-confidence-button" (click)="onConfirmLowConfidence()">
          <mat-icon class="is-low-confidence" svgIcon="low_confidence"></mat-icon>
          <mat-icon class="is-check" svgIcon="check_resolved"></mat-icon>
        </button>
      </span>
    }
  </mat-form-field>
} @else {
  <div class="om-readonly-form-field">
    @if (fieldConfig.label; as label) {
      <b
        >{{ label }}
        @if (form.controls[fieldConfig.name].value) {
          <span>: </span>
        }
      </b>
    }
    @if (form.controls[fieldConfig.name].value; as value) {
      <span>: {{ value }}</span>
    }
  </div>
}
@if (suffixActions) {
  <om-action-menu
    [config]="suffixActions"
    [dataToEmit]="{ fieldName: fieldConfig.name }"
    (selected)="onActionSelected($event)"
  ></om-action-menu>
}
