import { ctrlKey, KeyCode, shiftKey } from '@morpho/core';
import { Ancestor, Editor, Element, NodeEntry, Path, Range, Transforms } from 'slate';
import { ElementType, LIST_TYPES } from '../../constants/rich-text.constants';
import { RichTextListItem } from '../../models/rich-text.model';
import { EditorHelper } from '../editor-helper';
import { EMPTY_LIST_ITEM, ListEditor } from './list-commands';

export const withLists = (editor: Editor) => {
  const { normalizeNode, onKeyDown } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (Element.isElement(node) && node.type === ElementType.ListItem) {
      const [parentNode] = path.length === 1 ? [null] : EditorHelper.parent(editor, path);
      if (!parentNode || (Element.isElement(parentNode) && !LIST_TYPES.includes(parentNode.type))) {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
    }

    if (Element.isElement(node) && LIST_TYPES.includes(node.type)) {
      if (!node.children.length) {
        Transforms.removeNodes(editor, {
          at: path,
        });
        return;
      }

      const checkChildrenAfterMerge = (parentPath: number[]) => {
        const [parentNode] = Editor.node(editor, parentPath);
        if (!Element.isElement(parentNode)) {
          return;
        }
        for (let i = parentNode.children.length - 1; i > 0; i -= 1) {
          const childNode = parentNode.children[i];
          const previousChildNode = parentNode.children[i - 1];
          if (
            Element.isElement(childNode) &&
            Element.isElement(previousChildNode) &&
            childNode.type === previousChildNode.type
          ) {
            const child = Editor.node(editor, [...parentPath, i]);
            editor.normalizeNode(child);
          }
        }
      };

      const [previousNode, previousPath] = EditorHelper.previousSibling(editor, path);
      if (previousPath && Element.isElement(previousNode) && node.type === previousNode.type) {
        Transforms.mergeNodes(editor, {
          at: path,
        });
        checkChildrenAfterMerge(previousPath);
        return;
      }

      const [nextNode, nextPath] = EditorHelper.nextSibling(editor, path);
      if (nextPath && Element.isElement(nextNode) && node.type === nextNode.type) {
        Transforms.mergeNodes(editor, {
          at: nextPath,
        });
        checkChildrenAfterMerge(path);
        return;
      }
    }

    normalizeNode(entry);
  };

  editor.onKeyDown = (editor: Editor, event: KeyboardEvent) => {
    const block = EditorHelper.getBlock(editor);

    switch (event.code) {
      case KeyCode.Backspace:
        if (block?.[0].type === ElementType.ListItem && onListItemBackspace(editor, event, block)) {
          event.preventDefault();
          return;
        }
        break;
      case KeyCode.Enter:
        if (block?.[0].type === ElementType.ListItem && onListItemEnter(editor, event, block)) {
          event.preventDefault();
          return;
        }
        break;
      case KeyCode.Tab:
        if (onListItemTab(editor, event)) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }

    onKeyDown?.(editor, event);
  };

  return editor;
};

function onListItemBackspace(editor: Editor, event: KeyboardEvent, listItem: NodeEntry<Ancestor>): boolean {
  if (
    editor.selection &&
    Range.isCollapsed(editor.selection) &&
    EditorHelper.isPointAtTheStart(editor, listItem, editor.selection.anchor)
  ) {
    return ListEditor.outdent(editor, true);
  }

  return false;
}

function onListItemEnter(editor: Editor, event: KeyboardEvent, listItem: NodeEntry<Ancestor>): boolean {
  let hasEdited = false;
  if (ctrlKey(event) || shiftKey(event)) {
    return hasEdited;
  }

  Editor.withoutNormalizing(editor, () => {
    if (!editor.selection) {
      return;
    }

    if (Range.isExpanded(editor.selection)) {
      Transforms.delete(editor);
    }

    if (EditorHelper.isPointAtTheStart(editor, listItem, editor.selection.anchor)) {
      hasEdited = ListEditor.outdent(editor, true);
      return;
    }

    const [element] = Editor.nodes(editor, {
      match: node => Element.isElement(node),
      mode: 'lowest',
    });
    const [elementNode, elementPath] = element;

    const editListItem = EditorHelper.getNode(editor, ElementType.ListItem);
    const [listItemNode, listItemPath] = editListItem;

    if (!editListItem) {
      return;
    }

    const nextPath = [...listItemPath];
    nextPath[nextPath.length - 1] += 1;

    const isPointAtTheStart = EditorHelper.isPointAtTheStart(editor, editListItem, editor.selection.anchor);
    const isPointAtTheEnd = EditorHelper.isPointAtTheEnd(editor, editListItem, editor.selection.anchor);

    if (isPointAtTheStart) {
      if ((editListItem as any)[0]?.children?.[0]?.children?.[0].text) {
        EditorHelper.insertNestedBlock(editor, EMPTY_LIST_ITEM, listItemPath);
      } else {
        let canOutdent = true;
        while (canOutdent) {
          canOutdent = ListEditor.outdent(editor, true);
        }
      }
    } else if (isPointAtTheEnd) {
      EditorHelper.insertNestedBlock(editor, EMPTY_LIST_ITEM, nextPath);
      Transforms.select(editor, nextPath);
    } else {
      Transforms.splitNodes(editor);
      const splitPath = Path.next(elementPath);

      const splitNode = Editor.node(editor, splitPath)?.[0] as Element;
      const isSplitOnlyChildren = LIST_TYPES.includes(splitNode.type);

      if (isSplitOnlyChildren) {
        EditorHelper.insertNestedBlock(editor, EMPTY_LIST_ITEM, nextPath);
        Transforms.select(editor, nextPath);
      } else {
        Transforms.wrapNodes(
          editor,
          { type: ElementType.ListItem, children: [] },
          {
            at: splitPath,
          },
        );

        Transforms.moveNodes(editor, {
          at: splitPath,
          to: nextPath,
        });
      }

      const listItemChildren = (listItemNode as RichTextListItem).children;
      if (LIST_TYPES.includes(listItemChildren[listItemChildren.length - 1].type)) {
        Transforms.moveNodes(editor, {
          at: [...listItemPath, listItemChildren.length - 1],
          to: [...nextPath, 1],
        });
      }
    }

    hasEdited = true;
  });

  return hasEdited;
}

function onListItemTab(editor: Editor, event: KeyboardEvent) {
  return shiftKey(event) ? ListEditor.outdent(editor) : ListEditor.indent(editor);
}
