import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ValueAndLabel } from '@morpho/core';
import { DynamicFormModel, DynamicFormService } from '@morpho/dynamic-form';
import { FormInputConfig } from '@morpho/form';
import { Store } from '@ngrx/store';
import { combineLatest, filter, take, tap } from 'rxjs';
import { SyndicatePricerUtilService } from '../../../services/syndicate-pricer-util.service';
import { PricingCompletionSelector } from '../../../state/pricing-completion/pricing-completion.selectors';
import { SyndicatePricerApiService } from './../../../services/syndicate-pricer-api.service';

@Component({
  standalone: false,
  selector: 'om-pricing-rationale-dialog',
  templateUrl: './pricing-rationale-dialog.component.html',
  styleUrls: ['./pricing-rationale-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PricingRationaleDialogComponent implements OnInit {
  sharingFormModel: DynamicFormModel | null;
  pricingRationaleForm: FormGroup;
  rows: ValueAndLabel[];
  isLoading = false;

  constructor(
    private store: Store,
    private syndicatePricerApiService: SyndicatePricerApiService,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
    private dynamicFormService: DynamicFormService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PricingRationaleDialogComponent>,
  ) {}

  ngOnInit(): void {
    const pricingRationaleForm = this.formBuilder.group({});

    combineLatest([
      this.store.select(PricingCompletionSelector.issuingEntities).pipe(filter(issuingEntities => !!issuingEntities)),
      this.syndicatePricerApiService.syndicateTeamOptions$,
      this.syndicatePricerApiService.teamColleagueOptions$,
      this.store
        .select(PricingCompletionSelector.pricingTabDataMap)
        .pipe(filter(pricingTabData => !!(pricingTabData && Object.keys(pricingTabData).length))),
    ])
      .pipe(
        take(1),
        tap(([issuingEntities, syndicateTeamOptions, teamColleagueOptions, pricingTabData]) => {
          const issuingEntitiesMap = Object.entries(issuingEntities ?? {}).map(([id, name]) => {
            return {
              id,
              name,
            };
          });

          const sharingOptions: FormInputConfig[] = [];

          issuingEntitiesMap.forEach(entity => {
            sharingOptions.push(
              ...([
                {
                  name: `desks_${entity.id}`,
                  label: `Desks that can view ${entity.name}`,
                  help: `These are other desks who can view the pricing and pricing request, but can't complete it or edit the data`,
                  element: 'autocomplete',
                  options: syndicateTeamOptions,
                  multiple: true,
                },
                {
                  name: `users_${entity.id}`,
                  label: `Users that can view ${entity.name}`,
                  help: `These are other users who can view the pricing and pricing request, but can't complete it or edit the data`,
                  element: 'autocomplete',
                  options: teamColleagueOptions,
                  multiple: true,
                },
              ] as FormInputConfig[]),
            );
          });

          this.sharingFormModel = this.dynamicFormService.generateFormModel(sharingOptions);

          Object.keys(pricingTabData ?? {}).forEach((key: string) => {
            pricingRationaleForm.addControl(key, new FormControl(''));
          });
        }),
        tap(() => {
          this.pricingRationaleForm = pricingRationaleForm;
          this.updateRows();
        }),
      )
      .subscribe();
  }

  private updateRows() {
    this.rows = Object.keys(this.pricingRationaleForm.value).map(key => {
      return this.syndicatePricerUtilService.generatePricingTabFromName(key);
    });
  }

  submit() {
    const result = {
      pricing_rationale: this.pricingRationaleForm.value,
      share_with: this.sharingFormModel?.form.value,
    };
    this.dialogRef.close(result);
  }
}
