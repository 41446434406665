<h2 mat-dialog-title>
  @if (data.supertitle) {
    <span class="mat-mdc-dialog-supertitle">{{ data.supertitle }}</span>
  }
  {{ data.title }}
  @if (finalSectionIndex) {
    <span>({{ currentSectionIndex + 1 }}/{{ finalSectionIndex + 1 }})</span>
  }
  @if (data.subtitle) {
    <span class="mat-mdc-dialog-subtitle">{{ data.subtitle }}</span>
  }
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h2>
<div mat-dialog-content #content>
  @if (data.message) {
    @switch (data.message | elementTypeFromValue) {
      @case (elementValueType.Richtext) {
        <om-rich-text-input [value]="data.message" [readonly]="true"></om-rich-text-input>
      }
      @default {
        <p class="is-pre-line">{{ data.message }}</p>
      }
    }
  }

  @if (data.primary_info?.sections?.length) {
    <ng-container
      *ngTemplateOutlet="infoTemplate; context: { sections: data.primary_info?.sections, class: '' }"
    ></ng-container>
  }
  @if (model?.sectionsForDisplay) {
    @if (data.toggle_buttons && currentSectionIndex === 0) {
      <div class="toggle-container">
        <om-button-toggle-input
          [options]="data.toggle_buttons.options"
          [formControl]="buttonToggleFormControl"
          class="is-dynamic-modal-variant"
        >
        </om-button-toggle-input>
      </div>
    }
    @if (model.sectionsForDisplay.length) {
      <form>
        <div>
          @if (model.sectionsForDisplay[currentSectionIndex].subsections?.length) {
            @for (subsection of model.sectionsForDisplay[currentSectionIndex].subsections; track subsection) {
              <div class="is-subsection">{{ subsection.name }}</div>
              @for (fieldName of subsection.fieldNames; track fieldName) {
                <ng-container
                  *ngTemplateOutlet="
                    formFieldTemplate;
                    context: { fieldName: fieldName, class: 'is-subsection-' + fieldName.split('_')?.[1] }
                  "
                ></ng-container>
              }
            }
          } @else {
            @for (fieldName of model.sectionsForDisplay[currentSectionIndex].fieldNames; track fieldName) {
              <ng-container *ngTemplateOutlet="formFieldTemplate; context: { fieldName: fieldName }"></ng-container>
            }
          }
        </div>
        <ng-template #formFieldTemplate let-fieldName="fieldName" let-class="class">
          <om-dynamic-form-field
            [class]="class"
            [model]="model"
            [form]="model.form"
            [fieldConfig]="model.fieldMapping[fieldName]"
            (inputChange)="onInputChange($event)"
          ></om-dynamic-form-field>
        </ng-template>
      </form>
    }
  } @else {
    <om-loading></om-loading>
  }
  @if (data.extra_info?.sections?.length) {
    <ng-container
      *ngTemplateOutlet="infoTemplate; context: { sections: data.extra_info?.sections, class: 'is-secondary' }"
    ></ng-container>
  }

  <ng-template #infoTemplate let-sections="sections" let-class="class">
    @for (section of sections; track section) {
      <aside [ngClass]="class">
        <section>
          <strong> {{ section.title }}: </strong>
          @if (section.message) {
            <p class="is-pre-line">{{ section.message }}</p>
          }
          @if (section.list?.length) {
            <ol>
              @for (item of section.list; track item) {
                <li>{{ item }}</li>
              }
            </ol>
          }
          @if (section.url) {
            <a [href]="section.url.value" target="_blank">{{ section.url.label }}</a>
          }
        </section>
      </aside>
    }
  </ng-template>

  @if (data.notice) {
    <div class="modal-notice-container">
      <span class="modal-notice">
        {{ data.notice }}
      </span>
    </div>
  }
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onPrevious()">{{ currentSectionIndex === 0 ? 'Cancel' : 'Previous' }}</button>
  <span [omTooltip]="pendingPromises.count > 0 ? 'Please wait while the form updates' : ''">
    <button
      mat-button
      (click)="onNext()"
      [disabled]="pendingPromises.count > 0"
      [class.is-warning]="data.status === 'negative'"
    >
      {{
        finalSectionIndex && finalSectionIndex !== currentSectionIndex
          ? 'Continue'
          : data.submit_text || data.text || 'Submit'
      }}
      @if (isLoading) {
        <mat-spinner></mat-spinner>
      }
    </button>
  </span>
</div>
