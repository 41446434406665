import { Injectable } from '@angular/core';
import { DocumentActionItem, DocumentInfo, DocumentListItem, FileLabels } from './document-list.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentListService {
  createDocumentList(documents: DocumentInfo[]): DocumentListItem[] {
    const documentListItems: DocumentListItem[] = [];

    documents.forEach(docInfo => {
      const docListItem: DocumentListItem = {
        label: docInfo.name,
        url: docInfo.url,
        actions: [],
      };

      let docListItemActions: DocumentActionItem[] = [];

      const documentAction: DocumentActionItem = {
        type: docInfo.file_extension ?? '',
        label: FileLabels[docInfo.file_extension as keyof typeof FileLabels] ?? '',
        url: docInfo.url_document ?? '',
        ...(!!docInfo.modal_document
          ? {
              modal: {
                ...docInfo.modal_document,
                right_action: { ...docInfo.modal_document.right_action, url: docInfo.url_document ?? '' },
              },
            }
          : {}),
      };

      docListItemActions.push(documentAction);

      const addPdfAction = (url: string, note?: string) => {
        const pdfAction: DocumentActionItem = {
          type: 'pdf',
          label: FileLabels.pdf,
          url,
          ...(note ? { note } : {}),
          ...(!!docInfo.modal_document
            ? {
                modal: {
                  ...docInfo.modal_document,
                  right_action: {
                    ...docInfo.modal_document.right_action,
                    url,
                  },
                },
              }
            : {}),
        };
        docListItemActions.push(pdfAction);
      };

      const signedOrUnsignedPdfs = [
        { url: docInfo.url_unsigned_pdf, note: 'Unsigned' },
        { url: docInfo.url_signed_pdf, note: 'Signed' },
      ].filter(pdf => pdf.url);

      if (signedOrUnsignedPdfs.length) {
        docListItemActions = docListItemActions.filter(action => action.type !== 'pdf');

        signedOrUnsignedPdfs.forEach(({ url, note }) => {
          if (url) {
            addPdfAction(url, note);
          }
        });
      } else if (docInfo.file_extension !== 'pdf' && docInfo.url_pdf) {
        addPdfAction(docInfo.url_pdf);
      }

      docListItem.actions = docListItemActions;

      documentListItems.push(docListItem);
    });

    return documentListItems;
  }
}
