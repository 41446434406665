export enum InlineStyle {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
  TextSmall = 'small',
  TextMedium = 'medium',
  TextLarge = 'large',
}

export enum TextAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Justify = 'justify',
}
