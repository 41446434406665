<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  (menuClosed)="onMenuClosed()"
  (click)="onSelectDocument()"
  class="icon-action"
>
  <span class="icon"><mat-icon svgIcon="file_download"></mat-icon></span>
  <mat-menu #menu>
    @for (action of documentDownloadItem.actions; track action) {
      @if (!action.modal) {
        <a [href]="action.url" download mat-menu-item class="om-document-download-button__menu-item">
          <ng-container *ngTemplateOutlet="linkContentTemplate"></ng-container>
        </a>
      } @else {
        <a (click)="onDownloadModal(action.modal)" mat-menu-item class="om-document-download-button__menu-item">
          <ng-container *ngTemplateOutlet="linkContentTemplate"></ng-container>
        </a>
      }
      <ng-template #linkContentTemplate>
        <mat-icon svgIcon="{{ [documentIcons] | pluck: action.type }}"></mat-icon>
        <span class="om-document-download-button__menu-item-label">{{ action.label }}</span>
        @if (action.note) {
          <span class="om-document-download-button__menu-item-note">{{ action.note }}</span>
        }
      </ng-template>
    }
  </mat-menu>
</button>
