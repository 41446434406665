<img class="is-transparent" [alt]="iconAltText" [src]="icon" />

@if (title) {
  <p>
    {{ title }}
    @if (subtitle) {
      <em><br />{{ subtitle }}</em>
    }
  </p>
}
@if (type === PlaceholderType.file) {
  <p>Please <a [href]="url">download</a> the file to view it</p>
} @else {
  <p>{{ message }}</p>
}
