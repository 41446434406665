import { NgModule } from '@angular/core';
import { ROUTER_STATE_KEY } from '@morpho/core';
import { environment } from '@morpho/environment';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PricingCompletionEffects } from '../features/syndicate-pricer/state/pricing-completion/pricing-completion.effects';
import {
  PRICING_COMPLETION_STATE_KEY,
  pricingCompletionReducer,
} from '../features/syndicate-pricer/state/pricing-completion/pricing-completion.reducer';
import { RatingsEffects } from './patterns/ratings/state/ratings.effects';
import { RATINGS_STATE_KEY, ratingsReducer } from './patterns/ratings/state/ratings.reducer';
import { CustomRouterStateSerializer } from './state/app-route-serializer';
import { AuthenticatedUserEffects } from './state/authenticated-user/authenticated-user.effects';
import {
  AUTHENTICATED_USER_STATE_KEY,
  authenticatedUserReducer,
} from './state/authenticated-user/authenticated-user.reducer';
import { ConstantsEffects } from './state/constants/constants.effects';
import { CONSTANTS_STATE_KEY, constantsReducer } from './state/constants/constants.reducer';
import { CurveAnalysisEffects } from './state/curve-analysis/curve-analysis.effects';
import { CURVE_ANALYSIS_STATE_KEY, curveAnalysisReducer } from './state/curve-analysis/curve-analysis.reducer';
import { DisplayEffects } from './state/display/display.effects';
import { DISPLAY_STATE_KEY, displayReducer } from './state/display/display.reducer';
import { NotificationsEffects } from './state/notifications.effects';
import { NOTIFICATIONS_STATE_KEY, notificationsContainerReducer } from './state/notifications.reducer';
import { PermissionEffects } from './state/permission/permission.effects';
import { PERMISSION_STATE_KEY, permissionReducer } from './state/permission/permission.reducer';

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),

    StoreModule.forFeature(CONSTANTS_STATE_KEY, constantsReducer),
    EffectsModule.forFeature([ConstantsEffects]),

    StoreModule.forFeature(DISPLAY_STATE_KEY, displayReducer),
    EffectsModule.forFeature([DisplayEffects]),

    StoreModule.forFeature(NOTIFICATIONS_STATE_KEY, notificationsContainerReducer),
    EffectsModule.forFeature([NotificationsEffects]),

    StoreModule.forFeature(PERMISSION_STATE_KEY, permissionReducer),
    EffectsModule.forFeature([PermissionEffects]),

    StoreModule.forFeature(ROUTER_STATE_KEY, routerReducer),
    StoreRouterConnectingModule.forRoot({
      stateKey: ROUTER_STATE_KEY,
    }),

    StoreModule.forFeature(AUTHENTICATED_USER_STATE_KEY, authenticatedUserReducer),
    EffectsModule.forFeature([AuthenticatedUserEffects]),

    StoreModule.forFeature(CURVE_ANALYSIS_STATE_KEY, curveAnalysisReducer),
    EffectsModule.forFeature([CurveAnalysisEffects]),

    StoreModule.forFeature(PRICING_COMPLETION_STATE_KEY, pricingCompletionReducer),
    EffectsModule.forFeature([PricingCompletionEffects]),

    StoreModule.forFeature(RATINGS_STATE_KEY, ratingsReducer),
    EffectsModule.forFeature([RatingsEffects]),

    StoreDevtoolsModule.instrument({
      name: 'Origin Markets: Morpho',
      maxAge: environment.deployed ? 2 : 100, // Retains last n states
      // logOnly: environment.deployed, // Restrict extension to log-only mode
    }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
  ],
})
export class NgrxModule {}
