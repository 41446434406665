import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { KeyCode } from '@morpho/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface SelectCellOption {
  cellClass?: string;
  cellLabel?: string;
  description?: string;
  label: string;
  value: string;
}

export interface SelectCellEditorParams extends ICellRendererParams {
  values: SelectCellOption[];
  isMultipleSelectionAllowed: boolean;
  isRadioVisible: boolean;
  emptyLabel: string;
  filterCustomValues?: boolean;
  showSelectedValueWhenOpen?: boolean;
}

@Component({
  standalone: false,
  selector: 'om-select-cell-editor',
  templateUrl: './select-cell-editor.component.html',
})
export class SelectCellEditorComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('matSelect') matSelect: MatSelect;
  private stopEditing: () => void;
  private onKeyDown: (event: KeyboardEvent) => void;

  selectedOptions: string | string[];
  options: SelectCellOption[];
  isMultiple: boolean;
  isRadioVisible: boolean;
  useExtendedLayout: boolean;
  emptyLabel: string;
  isIssuerEntity: boolean;
  issuerEntityOptions: any[];
  showSelectedValueWhenOpen: boolean;
  optionsFilterCallback: () => any;

  agInit(params: any): void {
    this.selectedOptions = params.value;
    this.isMultiple = params.isMultipleSelectionAllowed;
    this.isIssuerEntity = params.isIssuerEntity;
    this.showSelectedValueWhenOpen = params.showSelectedValueWhenOpen;

    if (this.isMultiple && (params.values ?? []).length) {
      const field = params.colDef?.field;
      if (field && params.data.sources && field in params.data.sources) {
        this.options = params.values.filter(
          (val: any) =>
            !Object.entries(params.data.sources[field]).some(
              ([sourceName, value]: [key: string, value: any[]]) =>
                sourceName !== 'custom' && value.includes(val.value),
            ),
        );
      }
    }

    if (this.isIssuerEntity) {
      this.options = params.issuerEntityOptions[params.data?.issuing_entity?.issuer?.id]?.entities.map(
        (entity: any) => {
          return {
            value: entity.id,
            label: entity.name,
          };
        },
      );
    } else {
      this.options ??= params.values;
    }
    this.isRadioVisible = params.isRadioVisible;
    this.useExtendedLayout = this.options?.some(option => option.description);
    this.emptyLabel = params.emptyLabel ? (params.emptyLabel === true ? '(empty)' : params.emptyLabel) : null;
    this.stopEditing = params.stopEditing;
    this.onKeyDown = params.onKeyDown;
  }

  refresh(): boolean {
    return false;
  }

  ngAfterViewInit() {
    this.matSelect.open();
  }

  getValue() {
    return this.selectedOptions;
  }

  isPopup(): boolean {
    return false;
  }

  onOpenChange() {
    if (this.matSelect.panelOpen) {
      this.matSelect.focus();
    } else {
      this.stopEditing();
    }
  }

  onKeydown(event: KeyboardEvent) {
    if ([KeyCode.Left, KeyCode.Right].includes(event.key as KeyCode)) {
      this.stopEditing();
      this.onKeyDown(event);
    }
  }
}
