import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  BACKEND_DATE_FORMAT,
  DATE_FORMAT,
  DATE_INPUT_FORMATS,
  DEFAULT_DATE_PLACEHOLDER,
  KeyCode,
  MaturityType,
} from '@morpho/core';
import * as moment from 'moment-timezone';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

type MaturityInputValue = string | undefined;

enum MaturityErrorType {
  InvalidCallable = 'callable',
  InvalidPerpetual = 'perpetual',
  InvalidTenor = 'tenor',
  InvalidTenorRange = 'tenor_range',
  InvalidDate = 'date',
  InvalidMaturity = 'maturity',
}

@Component({
  standalone: false,
  selector: 'om-maturity-input',
  templateUrl: './maturity-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => MaturityInputComponent)),
})
export class MaturityInputComponent extends CustomFormFieldControlComponent {
  @ViewChild('picker', { read: undefined, static: false }) picker: MatDatepicker<moment.Moment>;
  @ViewChild('childInput') childInput: ElementRef<HTMLInputElement>;

  readonly minDate = moment().add(1, 'y').format(BACKEND_DATE_FORMAT);
  readonly maturityPlaceholder = `10Y, 30NC5, ${DEFAULT_DATE_PLACEHOLDER}`;

  maturityInputs: string[] = [];

  @Input()
  get value(): string {
    const val = this.maturityInputs
      .map(maturity => {
        const date = moment(maturity, DATE_FORMAT, true);
        if (date.isValid()) {
          return date.format(BACKEND_DATE_FORMAT);
        }
        return maturity;
      })
      .join(',');

    return val;
  }
  set value(val: string | null) {
    if (val || val === '') {
      const maturities = val ? val.split(',') : [];
      this.maturityInputs = maturities.map(maturity => {
        if (moment(maturity, BACKEND_DATE_FORMAT, true).isValid()) {
          return moment(maturity).format(DATE_FORMAT);
        }
        return maturity;
      });
      this.emitChanges();
    }
  }
  maturityValue = '';
  dateValue: string;

  openDatePicker() {
    this.picker.opened ? this.picker.close() : this.picker.open();
  }

  removeChip(chip: string) {
    this.maturityInputs = this.maturityInputs.filter(maturity => maturity !== chip);
    this.emitChanges();
  }

  onDateValueChange(event: moment.MomentInput): void {
    const dateMaturity = moment(event).format(DATE_FORMAT);
    this.updateMaturityInputs(dateMaturity);
  }

  onKeyDown(event: any) {
    const maturityValue = event.target?.value?.trim();
    this.maturityValue = maturityValue;

    if (event.key === KeyCode.Enter || event.key === KeyCode.Comma) {
      event.stopPropagation();
      event.preventDefault();
      this.checkMaturityValidity(this.maturityValue);
    } else {
      setTimeout(() => {
        this._value = maturityValue;
        this.emitChanges();
      }, 1);
    }
  }

  onFocusout() {
    this.checkMaturityValidity(this.maturityValue);
  }

  onFocusin() {
    this.childInput.nativeElement.focus();
  }

  private handleInvalidMaturity(value: string, errorType: MaturityErrorType) {
    let errorMessage;

    if (!value) {
      return;
    }

    switch (errorType) {
      case MaturityErrorType.InvalidTenor:
        errorMessage = `${value} is not a valid tenor`;
        break;
      case MaturityErrorType.InvalidTenorRange:
        errorMessage = `${value} is not a valid tenor range`;
        break;
      case MaturityErrorType.InvalidDate:
        errorMessage = `${value} is not a valid maturity date as it is less than 1 year in the future`;
        break;
      case MaturityErrorType.InvalidCallable:
      case MaturityErrorType.InvalidPerpetual:
        errorMessage = `${value} is not a valid callable structure`;
        break;
      default:
        errorMessage = `${value} is not a valid maturity`;
    }

    this.popupService.error(errorMessage);
  }

  private checkMaturityValidity(maturity: string): void {
    const maturityType = this.levelsUtilService.getMaturityTypeFromMaturity(maturity);

    switch (maturityType) {
      case MaturityType.Tenor:
        this.validateTenor(maturity);
        break;
      case MaturityType.CallableStructure:
        this.validateCallableStructure(maturity);
        break;
      case MaturityType.PerpetualStructure:
        this.validatePerpetualStructure(maturity);
        break;
      case MaturityType.Date:
        this.validateDate(maturity);
        break;
      case MaturityType.None:
      default:
        const maturityMoment = moment(maturity, DATE_INPUT_FORMATS, true);
        if (maturityMoment.isValid()) {
          this.validateDate(maturityMoment.format(BACKEND_DATE_FORMAT));
        } else {
          this.handleInvalidMaturity(maturity, MaturityErrorType.InvalidMaturity);
        }
        break;
    }
  }

  private updateMaturityInputs(maturity: string) {
    this.maturityValue = '';
    if (!this.maturityInputs.includes(maturity)) {
      this.maturityInputs.push(maturity);
      this.maturityInputs = this.levelsUtilService.sortMaturities(this.maturityInputs);
    }
    this.emitChanges();
  }

  private validateTenor(tenor: string) {
    let tenorArray: string[] = [];
    const tenorUpdated = tenor.toLowerCase().replaceAll('y', '');
    if (tenorUpdated.includes('-')) {
      const tenorRangeArray = tenorUpdated.split('-').map(tenor => tenor.trim());
      const rangeLength = Number(tenorRangeArray[1]) - Number(tenorRangeArray[0]);
      if (rangeLength <= 0) {
        this.handleInvalidMaturity(tenor, MaturityErrorType.InvalidTenorRange);
        return;
      }
      tenorArray = Array.from({ length: rangeLength + 1 }, (_, i) => `${i + Number(tenorRangeArray[0])}Y`);
    } else {
      if (tenor.match(new RegExp(/^\d+$/gm))) {
        this.updateMaturityInputs(`${tenor}y`.toUpperCase());
        return;
      }

      if (tenor.match(new RegExp(/\d+Y\d+M/gim))) {
        const month = tenor.toLowerCase().split('y')[1].replace('m', '');
        if (Number(month) >= 12) {
          this.handleInvalidMaturity(tenor, MaturityErrorType.InvalidTenor);
          return;
        }
        this.updateMaturityInputs(tenor.toUpperCase());
        return;
      }

      if (tenor.toLowerCase().includes('m')) {
        const tenorUpdated = tenor.toLowerCase().replaceAll('m', '');
        if (Number(tenorUpdated) < 12) {
          this.handleInvalidMaturity(tenor, MaturityErrorType.InvalidTenor);
          return;
        }
      }

      if (tenor.toLowerCase().includes('w')) {
        this.handleInvalidMaturity(tenor, MaturityErrorType.InvalidTenor);
        return;
      }
      tenorArray = [tenor.toUpperCase()];
    }
    tenorArray.forEach(tenorString => {
      this.updateMaturityInputs(tenorString);
    });
  }

  private validateCallableStructure(callableStructure: string) {
    const callableArray = callableStructure.toLowerCase().split('nc');
    const maxMaturity = callableArray[0];
    if (callableArray[1].includes('+')) {
      const callableStructureArray = callableArray[1].split('+');
      const initialPeriod = callableStructureArray[0];
      const recurringPeriod = callableStructureArray[1];
      const sum = Number(initialPeriod) + Number(recurringPeriod);
      if (sum <= Number(maxMaturity)) {
        this.updateMaturityInputs(callableStructure.toUpperCase());
      } else {
        this.handleInvalidMaturity(callableStructure, MaturityErrorType.InvalidCallable);
      }
    } else {
      if (Number(callableArray[1]) <= Number(maxMaturity)) {
        this.updateMaturityInputs(callableStructure.toUpperCase());
      } else {
        this.handleInvalidMaturity(callableStructure, MaturityErrorType.InvalidCallable);
      }
    }
  }

  private validatePerpetualStructure(perpetualStructure: string) {
    const numbers = perpetualStructure.match(/\d+/g);
    if (numbers && Number(numbers[0]) >= Number(numbers[1] ?? 0)) {
      this.updateMaturityInputs(`PerpNC${perpetualStructure.toLowerCase().split('nc')[1]}`);
    } else {
      this.handleInvalidMaturity(perpetualStructure, MaturityErrorType.InvalidPerpetual);
    }
  }

  private validateDate(date: string) {
    const dateMaturity = moment(date, BACKEND_DATE_FORMAT).format(DATE_FORMAT);
    const diff = moment(dateMaturity).diff(moment(new Date()), 'years');
    if (diff >= 1) {
      this.updateMaturityInputs(dateMaturity);
    } else {
      this.handleInvalidMaturity(dateMaturity, MaturityErrorType.InvalidDate);
    }
  }
}
