export enum UploadedDocumentState {
  pending = 1,
  complete = 2,
  error = 3,
}

export type UploadedDocumentType = {
  FINAL_TERMS: 'Final Terms';
  TERMSHEET: 'Termsheet';
};

export interface UploadedDocumentInstance {
  id: number;
  pdf: string;
  state: UploadedDocumentState;
  uploaded_by: number;
  document_type: UploadedDocumentType;
  is_test: boolean;
}
