<mat-select
  #matSelect
  [(ngModel)]="selectedOptions"
  [multiple]="isMultiple"
  (openedChange)="onOpenChange()"
  panelWidth="null"
  (keydown)="onKeydown($event)"
  [ngClass]="{ 'is-selected-value-hidden': showSelectedValueWhenOpen }"
>
  @if (emptyLabel && !isMultiple) {
    <mat-option value="">{{ emptyLabel }}</mat-option>
    <mat-divider></mat-divider>
  }
  @for (option of options; track $index) {
    <mat-option [value]="option.value">
      @if (isRadioVisible && !isMultiple) {
        <mat-radio-group [(ngModel)]="selectedOptions">
          <mat-radio-button [value]="option.value">
            <ng-template [ngTemplateOutlet]="simpleTemplate"></ng-template>
          </mat-radio-button>
        </mat-radio-group>
      } @else {
        <ng-template [ngTemplateOutlet]="simpleTemplate"></ng-template>
      }
    </mat-option>
    <ng-template #simpleTemplate>
      <span [class.select-cell-option-label]="useExtendedLayout">{{ option.label }}</span>
      @if (useExtendedLayout) {
        <span [class.select-cell-option-description]="useExtendedLayout">{{ option.description }}</span>
      }
    </ng-template>
  }
</mat-select>
