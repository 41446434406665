import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AgGridPanel } from '@morpho/ag-grid-panel';
import { QuoteType } from '@morpho/core';
import { Store } from '@ngrx/store';
import { AgGridAngular } from 'ag-grid-angular';
import { ModelUpdatedEvent, ToolPanelVisibleChangedEvent } from 'ag-grid-community';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { PricingCompletionAction } from '../../../features/syndicate-pricer/state/pricing-completion/pricing-completion.actions';
import { PricingCompletionSelector } from '../../../features/syndicate-pricer/state/pricing-completion/pricing-completion.selectors';

@Component({
  standalone: false,
  selector: 'om-ag-grid-toolbar',
  templateUrl: './ag-grid-toolbar.component.html',
  styleUrls: ['./ag-grid-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridToolbarComponent implements OnChanges, OnDestroy {
  readonly AgGridPanel = AgGridPanel;

  @Input() agGrid: AgGridAngular;

  @Input() tableName: string;
  @Input() filters: string[];
  private readonly ngOnDestroy$ = new Subject<void>();

  readonly isGraphVisible$: Observable<boolean> = this.store.select(PricingCompletionSelector.isGraphVisible).pipe(
    map(isGraphVisible => !!isGraphVisible),
    takeUntil(this.ngOnDestroy$),
  );

  @Input() quoteType: string;
  @Output() quoteTypeChange = new EventEmitter<QuoteType>();

  readonly quoteTypeFormControl = new FormControl();
  readonly quoteTypeOptions = [
    { value: QuoteType.Bid, label: 'BID' },
    { value: QuoteType.Mid, label: 'MID' },
    { value: QuoteType.Ask, label: 'ASK' },
  ];

  activePanelKey: AgGridPanel = AgGridPanel.None;

  setActiveButton = (event: ModelUpdatedEvent | ToolPanelVisibleChangedEvent) => {
    const openedToolPanel = event.api.getOpenedToolPanel();
    this.activePanelKey = <AgGridPanel>openedToolPanel ?? AgGridPanel.None;
  };

  constructor(private store: Store<any>) {
    this.quoteTypeFormControl.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(quoteType => {
      this.quoteType = quoteType;
      this.quoteTypeChange.emit(quoteType);
    });
  }

  toggleSidePanel(key: string) {
    const openedToolBar = this.agGrid.api.getOpenedToolPanel();
    if (openedToolBar === key) {
      this.agGrid.api.openToolPanel(AgGridPanel.None);
    } else {
      this.agGrid.api.openToolPanel(<AgGridPanel>key);
    }
  }

  toggleGraph() {
    this.store.dispatch(PricingCompletionAction.toggleGraphVisibility());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.quoteType && changes.quoteType.currentValue !== this.quoteTypeFormControl.value) {
      this.quoteTypeFormControl.setValue(changes.quoteType.currentValue);
    }
    if (changes.agGrid?.isFirstChange()) {
      setTimeout(() => {
        if (!this.agGrid.api.isDestroyed()) {
          this.agGrid.api.addEventListener('toolPanelVisibleChanged', this.setActiveButton);
          this.agGrid.api.addEventListener('modelUpdated', this.setActiveButton);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
    this.agGrid.api.removeEventListener('toolPanelVisibleChanged', this.setActiveButton);
    this.agGrid.api.removeEventListener('modelUpdated', this.setActiveButton);
  }
}
