import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthenticatedUserState } from 'apps/bankangle/src/app/core/state/authenticated-user/authenticated-user.model';
import { AuthenticatedUserSelector } from 'apps/bankangle/src/app/core/state/authenticated-user/authenticated-user.selectors';
import { filter, take } from 'rxjs/operators';

export enum DataSource {
  CurveData = 'curve_data',
  Origin = 'origin',
  Custom = 'custom',
}

@Injectable({
  providedIn: 'root',
})
export class ClientsDisplayService {
  companyName: string | undefined;
  readonly defaultCompanyName = 'your company';

  getSourceLabel(source: DataSource, colId?: string): string {
    const headerMap: { [key in DataSource]: string } = {
      [DataSource.Origin]: 'Can issue in',
      [DataSource.CurveData]: 'Looking to issue in',
      [DataSource.Custom]: `Data added by ${this.companyName || this.defaultCompanyName}`,
    };
    if (
      colId &&
      source === 'origin' &&
      ['blackin', 'blackout', 'fitch_rating', 'moodys_rating', 'sp_rating'].includes(colId)
    ) {
      return 'Data on Origin';
    }
    return headerMap[source] ?? source;
  }

  constructor(private store: Store<AuthenticatedUserState>) {
    this.store
      .select(AuthenticatedUserSelector.companyName)
      .pipe(
        filter(companyName => !!companyName),
        take(1),
      )
      .subscribe(companyName => (this.companyName = companyName));
  }
}
