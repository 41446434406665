<div class="om-ag-grid-sidebar-wrapper">
  <mat-toolbar class="sidebar-header"> Filters </mat-toolbar>
  @if (columnConfigs) {
    <mat-accordion>
      @for (column of columnConfigs; track column) {
        @switch (column.type) {
          @case (filterType.SelectMultiple) {
            <om-select-multiple-filter
              [columnConfig]="column"
              [model]="model"
              (sendfilters)="acceptFilters($event)"
            ></om-select-multiple-filter>
          }
          @case (filterType.SizeAndCurrency) {
            <om-size-and-currency-filter
              [columnConfig]="column"
              [model]="model"
              (sendfilters)="acceptFilters($event)"
            ></om-size-and-currency-filter>
          }
          @case (filterType.Date) {
            <om-select-date-filter
              [columnConfig]="column"
              [model]="model"
              (sendfilters)="acceptFilters($event)"
            ></om-select-date-filter>
          }
          @case (filterType.SelectSingle) {
            <om-select-single-filter
              [columnConfig]="column"
              [model]="model"
              (sendfilters)="acceptFilters($event)"
            ></om-select-single-filter>
          }
        }
      }
    </mat-accordion>
  }
  <footer>
    <button mat-button class="is-strong is-chip is-accent" (click)="submitFilter()">Apply</button>
    <button mat-flat-button class="is-strong is-chip is-accent" (click)="resetFilter()">Reset To Default</button>
  </footer>
</div>
