import { Component, forwardRef, Input } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

@Component({
  standalone: false,
  selector: 'om-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: getCustomFormFieldProviders(forwardRef(() => CheckboxInputComponent)),
})
export class CheckboxInputComponent extends CustomFormFieldControlComponent {
  checkBoxOptions: any;

  @Input()
  get options(): ValueAndLabel[] {
    return this._options;
  }
  set options(opts: ValueAndLabel[]) {
    if (!opts?.length || this._options) {
      return;
    }
    this._options = opts || [];
    this.init();
  }
  private _options: ValueAndLabel[];

  @Input()
  get value(): any | null {
    return (
      this.checkBoxOptions?.reduce((accumulator: any[], currentValue: any) => {
        if (currentValue.checked) {
          accumulator.push(currentValue.value);
        }
        return accumulator;
      }, []) || undefined
    );
  }
  set value(val: any | null) {
    this._value = val || [];
    this.init();
  }
  _value: any | null;

  private init() {
    if (!this.options?.length) {
      return;
    }
    this.checkBoxOptions = this.options.map((option: ValueAndLabel) => ({
      ...option,
      checked: this._value?.includes(option.value),
    }));
    this.emitChanges();
  }

  onChildChange() {
    this.emitChanges();
  }
}
