<mat-button-toggle-group [(ngModel)]="value" (ngModelChange)="emitChanges()" [disabled]="disabled">
  @for (option of options; track option) {
    <mat-button-toggle [value]="option.value">
      @if (option.icon) {
        <mat-icon [svgIcon]="option.icon"></mat-icon>
      }
      {{ option.label }}
    </mat-button-toggle>
    @if (option.isBetaFlag) {
      <div class="beta-flag"></div>
      <div class="beta-flag-text">Beta</div>
    }
  }
</mat-button-toggle-group>
