import { Component, Input, OnChanges } from '@angular/core';
import {
  AutocompleteInputConfig,
  ButtonToggleInputConfig,
  DataTableInputConfig,
  DateInputConfig,
  FormInputConfig,
  FormUtilService,
  RadioInputConfig,
  SelectInputConfig,
} from '@morpho/form';

@Component({
  standalone: false,
  selector: 'om-form-value-viewer',
  templateUrl: './form-value-viewer.component.html',
  styleUrls: ['./form-value-viewer.component.scss'],
})
export class FormValueViewerComponent implements OnChanges {
  @Input() fieldConfig: FormInputConfig;
  @Input() value: any;

  displayValue: string;

  asDatatable(config: FormInputConfig): DataTableInputConfig {
    return config as DataTableInputConfig;
  }

  constructor(private utilService: FormUtilService) {}

  ngOnChanges(): void {
    this.displayValue = this.fieldConfig ? this.getFormattedValue() : '';
  }

  private mapValue(value: any) {
    switch (value) {
      case true:
        return 'Yes';
      case false:
        return 'No';
      case null:
        return ' - ';
      default:
        return value;
    }
  }

  private formatSimpleValue(value: any, type?: string): string {
    const mappedValue = this.mapValue(value);
    if (mappedValue !== value) {
      return mappedValue;
    }

    if (!type) {
      type = (this.fieldConfig as any).type;
    }

    switch (type) {
      case 'number':
        return this.utilService.applyCommasToNumber(value);
      case 'date':
        const dateType = (this.fieldConfig as DateInputConfig).hideYear ? 'date-no-year' : 'date';
        return this.utilService.convertFromBackendDateStringFormat(value, dateType);
      case 'date-no-year':
        return this.utilService.convertFromBackendDateStringFormat(value, 'date-no-year');
      case 'datetime':
        return this.utilService.convertFromBackendDateStringFormat(value, 'datetime');
      case 'number':
        return this.utilService.applyCommasToNumber(value);
      default:
        return value;
    }
  }

  private getFormattedValue() {
    if (!this.utilService.formInputHasValue(this.value)) {
      return '';
    }

    switch (this.fieldConfig.element) {
      case 'primitive':
        return this.formatSimpleValue(this.value);
      case 'date':
      case 'datetime':
        return this.formatSimpleValue(this.value, this.fieldConfig.element);
      case 'list':
      case 'table':
        return this.value.map((val: any) => this.formatSimpleValue(val)).join(', ');
      case 'autocomplete':
      case 'select':
      case 'button_toggle':
      case 'radio':
        const fieldConfig = this.fieldConfig as
          | ButtonToggleInputConfig
          | RadioInputConfig
          | AutocompleteInputConfig
          | SelectInputConfig;

        if (Array.isArray(this.value)) {
          return this.value
            .map((val: any) =>
              this.mapValue(this.utilService.getAttributeFromOptions(val, 'value', fieldConfig.options, 'label')),
            )
            .join(', ');
        }
        return this.mapValue(
          this.utilService.getAttributeFromOptions(this.value, 'value', fieldConfig.options, 'label'),
        );
      default:
        return this.mapValue(this.value);
    }
  }
}
