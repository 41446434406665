<div class="om-ag-grid-sidebar-wrapper">
  <mat-toolbar> Settings </mat-toolbar>
  <section>
    @if (selectedQuoteType$ | async; as selectedQuoteType) {
      <h3>Quote Type</h3>
      <p>
        The quote type selected will be used for yields, spreads, prices and any related calculations and
        interpolations.
      </p>
      <mat-radio-group (change)="onChangeQuoteType($event.value)">
        @for (quoteType of quoteTypes; track quoteType) {
          <mat-radio-button [value]="quoteType.value" [checked]="quoteType.value === selectedQuoteType">{{
            quoteType.label
          }}</mat-radio-button>
        }
      </mat-radio-group>
    }

    <h3>Show/Hide Columns</h3>
    <p>Select which columns you want to see on the secondary bonds table.</p>
    <mat-checkbox
      [checked]="allOptionsSelected"
      [indeterminate]="indeterminate"
      (change)="toggleAllColumns($event.checked)"
    >
      All
    </mat-checkbox>
    @for (columnDef of columnDefs; track columnDef) {
      <mat-checkbox [checked]="!columnDef.hide" [value]="columnDef" (change)="toggleVisibility($event)">{{
        columnDef.headerName
      }}</mat-checkbox>
    }
  </section>
</div>
