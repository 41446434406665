import { Injectable } from '@angular/core';
import { CoreApiService } from '@morpho/core';
import { ValidateFieldConfig } from '@morpho/form';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormApiService {
  constructor(private coreApiService: CoreApiService) {}

  generateOptionsApiEndpoint(path: string) {
    return `${path}options/`;
  }

  getValidationErrors(details: { body: Record<string, any>; config: ValidateFieldConfig }[]): Observable<string> {
    const requests: Observable<any>[] = details.map(detail => {
      return this.coreApiService.callApi(detail.config.url as string, detail.body, detail.config.method);
    });

    return forkJoin(requests).pipe(
      map(responses => {
        let error = '';
        responses.forEach((res: any) => {
          if (!res.is_valid) {
            error += (error ? '. ' : '') + res.description;
          }
        });
        return error;
      }),
    );
  }
}
