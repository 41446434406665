export enum ActivityCheckpoint {
  VIEW_ISSUER_PROFILE = 'issuer-profile-viewed',
  VIEW_ISSUER_LIST = 'issuer-list-viewed',
  VIEWED_LEVEL_INPUTS = 'level-inputs-viewed',
  VIEWED_CURVE_ANALYSIS = 'curve-analysis-viewed',
  CURVE_ANALYSIS_ISSUER_ADDED = 'curve-analysis-issuer-added',
  CURVE_ANALYSIS_EXPORTED = 'curve-analysis-exported',
  CURVE_ANALYSIS_FUNDING_BASIS_SELECTED = 'curve-analysis-funding-bases-selected',
  CURVE_ANALYSIS_LOADED_SAVED_SEARCH = 'curve-analysis-loaded-saved-search',
  XCCY_PRICER_PAGE_LOADED = 'xccy-pricer-page-loaded',
}

export interface IssuerProfileViewActivity {
  issuer: number;
  tab: string;
  is_navigation_between_tabs: boolean;
}

export interface LevelInputsViewActivity {
  issuer: number;
  redemption_type: 'bullet' | 'callable';
}

export interface ActivityData {
  [property: string]: any;
}
