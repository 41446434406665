import { detect } from 'detect-browser';

export const ONE_THOUSAND = 1000;
export const ONE_MILLION = 1000000;
export const ONE_BILLION = 1000000000;
export const ONE_TRILLION = 1000000000000;
export const ONE_QUADRILLION = 1000000000000000;

export const WEEKS_IN_YEAR = 52.1428571;

export enum KeyCode {
  Backspace = 'Backspace',
  Comma = ',',
  Delete = 'Delete',
  Enter = 'Enter',
  Escape = 'Escape',
  FullStop = '.',
  Tab = 'Tab',
  Left = 'ArrowLeft',
  Up = 'ArrowUp',
  Right = 'ArrowRight',
  Down = 'ArrowDown',
  B = 'KeyB',
  C = 'KeyC',
  F = 'KeyF',
  I = 'KeyI',
  U = 'KeyU',
  V = 'KeyV',
  X = 'KeyX',
}
export function ctrlKey(event: KeyboardEvent): boolean {
  return event.ctrlKey || event.metaKey;
}
export function shiftKey(event: KeyboardEvent): boolean {
  return event.shiftKey;
}

const browser = detect();
export const browserSupport = {
  webkitScrollbar: isWebkitScrollbarSupported(),
};

function isWebkitScrollbarSupported() {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
  return (
    browser &&
    (['chrome', 'safari', 'edge-chromium', 'opera'].includes(browser.name) ||
      (browser.name === 'edge' && parseFloat(browser.version) >= 79))
  );
}
