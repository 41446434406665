@if (!readonly && !(editorType === EditorType.Mentions)) {
  <mat-toolbar class="mat-toolbar--is-menu om-can-minimise-richtext" (mousedown)="$event.preventDefault()">
    <section class="om-style-display-large">
      <ng-container *ngTemplateOutlet="inlineStyleTemplate"></ng-container>
    </section>
    <section class="om-style-display-small">
      <button mat-icon-button class="is-extended has-menu" [matMenuTriggerFor]="inlineStyle">
        <mat-icon svgIcon="text_format"></mat-icon>
      </button>
    </section>
    <section class="om-display-large">
      <ng-container *ngTemplateOutlet="alignmentTemplate"></ng-container>
    </section>
    <section class="om-display-small">
      <button mat-icon-button class="is-extended has-menu" [matMenuTriggerFor]="align">
        <mat-icon svgIcon="align_left"></mat-icon>
      </button>
    </section>
    <section class="om-display-large">
      <button mat-icon-button (mousedown)="indent($event)">
        <mat-icon svgIcon="indent"></mat-icon>
      </button>
      <button mat-icon-button (mousedown)="outdent($event)">
        <mat-icon svgIcon="outdent"></mat-icon>
      </button>
    </section>
    <section class="om-display-small">
      <button mat-icon-button class="is-extended has-menu" [matMenuTriggerFor]="lists">
        <mat-icon svgIcon="unordered_list"></mat-icon>
      </button>
    </section>
    <section class="om-display-large">
      <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
      <button mat-icon-button (mousedown)="createTable($event)">
        <mat-icon svgIcon="table"></mat-icon>
      </button>
      <button
        #menuBtnHyperlink
        mat-icon-button
        [class.is-active]="activeHyperlink"
        [matMenuTriggerFor]="hyperlink"
        [matMenuTriggerData]="{ activeSelection }"
      >
        <mat-icon svgIcon="hyperlink"></mat-icon>
      </button>
    </section>
    <section>
      <button mat-icon-button (mousedown)="undo($event)">
        <mat-icon svgIcon="undo"></mat-icon>
      </button>
      <button mat-icon-button (mousedown)="redo($event)">
        <mat-icon svgIcon="redo"></mat-icon>
      </button>
    </section>
    <section class="om-display-small">
      <button mat-icon-button [matMenuTriggerFor]="overflow">
        <mat-icon svgIcon="more"></mat-icon>
      </button>
    </section>
  </mat-toolbar>
}

<!-- menu for inline style - small -->
<mat-menu #inlineStyle class="om-rich-text-editor__menu">
  <mat-toolbar class="mat-toolbar--is-menu">
    <section>
      <ng-container *ngTemplateOutlet="inlineStyleTemplate"></ng-container>
    </section>
  </mat-toolbar>
</mat-menu>

<!-- menu for align - small -->
<mat-menu #align class="om-rich-text-editor__menu">
  <mat-toolbar class="mat-toolbar--is-menu">
    <section>
      <ng-container *ngTemplateOutlet="alignmentTemplate"></ng-container>
    </section>
  </mat-toolbar>
</mat-menu>

<!-- menu for lists - small -->
<mat-menu #lists class="om-rich-text-editor__menu">
  <mat-toolbar class="mat-toolbar--is-menu">
    <section>
      <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
    </section>
  </mat-toolbar>
</mat-menu>

<!-- menu for overflow - small -->
<mat-menu #overflow class="om-rich-text-editor__menu">
  <mat-toolbar class="mat-toolbar--is-menu">
    <section>
      <button mat-icon-button (mousedown)="indent($event)">
        <mat-icon svgIcon="indent"></mat-icon>
      </button>
      <button mat-icon-button (mousedown)="outdent($event)">
        <mat-icon svgIcon="outdent"></mat-icon>
      </button>
    </section>
    <section>
      <button mat-icon-button (mousedown)="createTable($event)">
        <mat-icon svgIcon="table"></mat-icon>
      </button>

      <button
        #menuBtnHyperlink
        mat-icon-button
        [class.is-active]="activeHyperlink"
        [matMenuTriggerFor]="hyperlink"
        [matMenuTriggerData]="{ activeSelection }"
        (click)="$event.stopPropagation()"
      >
        <mat-icon svgIcon="hyperlink"></mat-icon>
      </button>
    </section>
  </mat-toolbar>
</mat-menu>

<!-- menu for hyperlink -->
<mat-menu #hyperlink="matMenu" [class]="'om-hyperlink-menu'" xPosition="before">
  <ng-template matMenuContent let-activeSelection="activeSelection">
    <om-hyperlink-editor
      [activeSelection]="activeSelection"
      [editor]="editor"
      (close)="closeHyperlinkEditor()"
      (mousedown)="$event.stopPropagation()"
    ></om-hyperlink-editor>
  </ng-template>
</mat-menu>

<!-- ** MENTIONS START HERE ** -->
<!-- trigger for mentions lookup  -->
<div #triggerWrapper class="om-mentions-trigger-wrapper">
  @if (editorType === EditorType.Mentions) {
    <span #triggerForMentionsLookup [matMenuTriggerFor]="mentionsLookup"></span>
  }
</div>

<!-- menu for mentions lookup  -->
<mat-menu #mentionsLookup="matMenu" [class]="'om-mentions-lookup-panel'" yPosition="below">
  @for (mention of filteredMentionsOptions; track mention) {
    <div>
      <h3>{{ mention.divider_label }}</h3>
      @for (option of mention.options; track option) {
        <button mat-menu-item (click)="selectMention(option)">
          <ul>
            <li>{{ option.label }}</li>
            <li>{{ option.description }}</li>
          </ul>
        </button>
      }
    </div>
  }
</mat-menu>

<ng-template #mentionTemplate let-context="context" let-viewContext="viewContext">
  <span
    slateElement
    [context]="context"
    [viewContext]="viewContext"
    class="om-mentions-tag"
    [ngClass]="{ 'is-user': context.element.user?.name === userFullName }"
    [omTooltip]="context.element | mentionsInfo: true"
    [omTooltipProperties]="tooltipProperties"
  >
    &#64;{{ context.element | mentionsInfo }}
  </span>
</ng-template>
<!-- ** MENTIONS END HERE ** -->

<slate-editable
  [editor]="editor"
  [keydown]="keydown"
  [(ngModel)]="richTextValue"
  [readonly]="readonly"
  (ngModelChange)="onValueChange($event)"
  [renderElement]="renderElement"
  [renderText]="renderText"
  (mousedown)="checkActiveProperties()"
>
  <ng-template #paragraphTemplate let-context="context" let-viewContext="viewContext">
    <p
      slateElement
      [context]="context"
      [viewContext]="viewContext"
      [style.padding-left]="context.element.indentation ? 40 * context.element.indentation + 'px' : undefined"
      [style.text-align]="context.element.text_align"
    ></p>
  </ng-template>

  <ng-template #hyperlinkTemplate let-context="context" let-viewContext="viewContext">
    <a slateElement [context]="context" [viewContext]="viewContext" [href]="context.element.url"></a>
  </ng-template>

  <!-- list start -->
  <ng-template #orderedListTemplate let-context="context" let-viewContext="viewContext">
    <ol slateElement [context]="context" [viewContext]="viewContext"></ol>
  </ng-template>

  <ng-template #unorderedListTemplate let-context="context" let-viewContext="viewContext">
    <ul slateElement [context]="context" [viewContext]="viewContext"></ul>
  </ng-template>

  <ng-template #listItemTemplate let-context="context" let-viewContext="viewContext">
    <li slateElement [context]="context" [viewContext]="viewContext"></li>
  </ng-template>
  <!-- list end -->

  <!-- table start -->
  <ng-template #tableTemplate let-context="context" let-viewContext="viewContext">
    <table slateElement [context]="context" [viewContext]="viewContext" class="om-richtext-table"></table>
  </ng-template>

  <ng-template #tableRowTemplate let-context="context" let-viewContext="viewContext">
    <tr slateElement [context]="context" [viewContext]="viewContext"></tr>
  </ng-template>

  <!-- todo remove -->
  <ng-template #tableHeaderTemplate let-context="context" let-viewContext="viewContext">
    <th
      slateElement
      [context]="context"
      [viewContext]="viewContext"
      [class]="tableCellClass"
      (mouseenter)="onTableCellMouseEnter($event)"
      (mouseleave)="onTableCellMouseLeave($event)"
    >
      <ng-container
        *ngTemplateOutlet="
          tableEditControlsTemplate;
          context: {
            slateContext: context
          }
        "
      ></ng-container>
    </th>
  </ng-template>

  <ng-template #tableDataTemplate let-context="context" let-viewContext="viewContext">
    <td
      slateElement
      [context]="context"
      [viewContext]="viewContext"
      [class]="tableCellClass"
      [class.is-selected-table-cell]="selectedTableCellKeyMap[context.attributes['data-slate-key']]"
      (mouseenter)="onTableCellMouseEnter($event)"
      (mouseleave)="onTableCellMouseLeave($event)"
    >
      <ng-container
        *ngTemplateOutlet="
          tableEditControlsTemplate;
          context: {
            slateContext: context
          }
        "
      ></ng-container>
    </td>
  </ng-template>
  <!-- table end -->
</slate-editable>

<ng-template #tableEditControlsTemplate let-slateContext="slateContext">
  @if (!readonly) {
    <ng-container
      *ngTemplateOutlet="
        tableEditControlTemplate;
        context: {
          position: 'column',
          slateContext: slateContext,
          actions: editActionsColumn
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        tableEditControlTemplate;
        context: {
          position: 'row',
          slateContext: slateContext,
          actions: editActionsRow
        }
      "
    ></ng-container>
  }
</ng-template>

<ng-template #tableEditControlTemplate let-position="position" let-slateContext="slateContext" let-actions="actions">
  <om-table-edit-control
    class="om-richtext-table-edit-control om-richtext-table-edit-control--{{ position }}"
    [alignment]="position"
    [slateId]="slateContext.attributes['data-slate-key']"
    [actions]="actions"
  ></om-table-edit-control>
</ng-template>

<ng-template #listTemplate>
  @for (option of listOptions; track option) {
    <button
      mat-icon-button
      (mousedown)="toggleList($event, option.value)"
      [class.is-active]="activeBlockProperties.has(option.value)"
    >
      <mat-icon [svgIcon]="option.icon"></mat-icon>
    </button>
  }
</ng-template>

<ng-template #alignmentTemplate>
  @for (option of alignmentOptions; track option) {
    <button
      mat-icon-button
      (mousedown)="setTextAlign($event, option.value)"
      [class.is-active]="textAlignedValue === option.value"
    >
      <mat-icon [svgIcon]="option.icon"></mat-icon>
    </button>
  }
</ng-template>

<ng-template #inlineStyleTemplate>
  @for (option of inlineStyleOptions; track option) {
    <button
      mat-icon-button
      [class.is-active]="activeInlineProperties[option.value]"
      (mousedown)="toggleInlineStyle($event, option.value)"
    >
      <mat-icon [svgIcon]="option.icon"></mat-icon>
    </button>
  }
</ng-template>
