import { Component } from '@angular/core';
import { BaseCellRendererConfig } from '@morpho/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface NameAndImage {
  name: string;
  image: string;
}

export interface SquareLogoCellRendererConfig extends BaseCellRendererConfig {
  omShowImage?: boolean;
  omGetNameAndImage: (params: SquareLogoCellRendererParams) => NameAndImage | null;
}

export interface SquareLogoCellRendererParams extends ICellRendererParams, SquareLogoCellRendererConfig {}

@Component({
  standalone: false,
  selector: 'om-square-logo-cell-renderer',
  templateUrl: './square-logo-cell-renderer.component.html',
})
export class SquareLogoCellRendererComponent implements ICellRendererAngularComp {
  isImageShown: boolean;
  squareLogo: NameAndImage | null;

  agInit(params: SquareLogoCellRendererParams): void {
    this.refresh(params);
  }

  refresh(params: SquareLogoCellRendererParams) {
    this.isImageShown = !!params.omShowImage;
    this.squareLogo = params.omGetNameAndImage(params);
    return false;
  }
}
