import { createAction, props } from '@ngrx/store';
import { BulkRatingsResponse } from './ratings.model';

const RATINGS = '[Ratings]';

const SET_RATINGS_FOR_ISSUING_ENTITIES = `${RATINGS} Set ratings for issuing entities`;

export const RatingsEffect = {};

export const RatingsAction = {
  setRatingsForIssuingEntities: createAction(
    SET_RATINGS_FOR_ISSUING_ENTITIES,
    props<{ params: { ratings: BulkRatingsResponse['results'] } }>(),
  ),
};
