import { Editor } from 'slate';
import { ElementType } from '../../constants/rich-text.constants';

export const withHyperlinks = (editor: Editor) => {
  const { isInline } = editor;

  editor.isInline = element => {
    return element.type === ElementType.Hyperlink ? true : isInline(element);
  };

  return editor;
};
