export interface PdfTextLayerRenderedEvent {
  numTextDivs?: number;
  pageNumber?: number;
  source: any;
}

export enum PdfZoomOptions {
  In = 'in',
  Out = 'out',
}

export enum PdfZoomScaleOptions {
  pageWidth = 'page-width',
  pageHeight = 'page-height',
}
