import { APP_BASE_HREF } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
  withXsrfConfiguration,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService, NetworkMonitoringInterceptor } from '@morpho/core';
import { environment } from '@morpho/environment';
import { TimeagoModule } from 'ngx-timeago';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { BankangleCoreModule } from './core/core.module';
import { AuthInterceptor } from './core/middleware/auth.interceptor';
import { CachingInterceptor } from './core/middleware/caching.interceptor';
import { NgrxModule } from './core/ngrx.module';
import { AppConfigService } from './core/services/app-config.service';
import { CustomErrorHandler } from './core/services/custom-error-handler.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    BankangleCoreModule,
    NgrxModule,
    SharedModule,
    TimeagoModule.forRoot(),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: environment.appBaseHref },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkMonitoringInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => () => appConfigService.init(),
      deps: [AppConfigService, AuthService],
      multi: true,
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withXsrfConfiguration({
        cookieName: 'csrftoken',
        headerName: 'X-CSRF-TOKEN',
      }),
    ),
  ],
})
export class AppModule {
  constructor(appService: AppService) {}
}
