import { NgModule } from '@angular/core';
import { CoreModule } from '@morpho/core';
import { FormModule } from '@morpho/form';
import { DynamicFormFieldComponent } from './components/dynamic-form-field/dynamic-form-field.component';
import { DynamicFormFlexibleEditComponent } from './components/dynamic-form-flexible-edit/dynamic-form-flexible-edit.component';
import { DynamicFormModalComponent } from './components/dynamic-form-modal/dynamic-form-modal.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { DynamicFormInputDirective } from './directives/dynamic-form-input.directive';
import { ElementTypeFromValuePipe } from './pipes/element-type-from-value.pipe';
import { IsContentTagPipe } from './pipes/is-content-tag.pipe';

const declarations = [
  DynamicFormComponent,
  DynamicFormFieldComponent,
  DynamicFormInputDirective,
  DynamicFormModalComponent,
  DynamicFormFlexibleEditComponent,
  // pipes
  ElementTypeFromValuePipe,
  IsContentTagPipe,
];

@NgModule({
  providers: [ElementTypeFromValuePipe],
  declarations: declarations,
  imports: [CoreModule, FormModule],
  exports: [...declarations],
})
export class DynamicFormModule {}
