import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { StickySidebarConfig } from './sticky-toolbars.model';

@Directive({
  standalone: false,
  selector: '[omStickyToolbars]',
})
export class StickyToolbarsDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input('omStickyToolbars') config: StickySidebarConfig | undefined;

  private headerSelectors: string[] | undefined;
  private footerSelectors: string[] | undefined;

  private contentContainer: HTMLElement;
  private scrollableElement: HTMLElement | null;

  private intersectionObserver: IntersectionObserver;

  private checkStickyToolbars = () => {
    if (!this.scrollableElement) {
      return;
    }

    const contentRect = this.contentContainer.getBoundingClientRect();
    const scrollableRect = this.scrollableElement.getBoundingClientRect();

    /**
     * Sticky Header
     */
    const top = scrollableRect.y + (this.config?.headerOffset ?? 0);
    const isHeaderSticky = contentRect.y < top;
    const left = contentRect.left;
    let headerToolbarHeight = 0;

    this.headerSelectors?.forEach(selector => {
      const element = this.getContentElement(selector);
      if (!element) {
        return;
      }
      if (isHeaderSticky) {
        this.applyStickyStyling(element, top + headerToolbarHeight, left);
        headerToolbarHeight += element.offsetHeight;
      } else {
        this.removeStickyStyling(element);
      }
    });

    this.contentContainer.style.paddingTop = headerToolbarHeight ? `${headerToolbarHeight}px` : '';

    /**
     * Sticky Footer
     */
    const bottom = scrollableRect.bottom - (this.config?.footerOffset ?? 0);
    const isFooterSticky = contentRect.bottom > bottom;
    let footerToolbarHeight = 0;

    this.footerSelectors?.forEach(selector => {
      const element = this.getContentElement(selector, true);
      if (!element) {
        return;
      }
      if (isFooterSticky) {
        footerToolbarHeight += element.offsetHeight;
        this.applyStickyStyling(element, bottom - footerToolbarHeight, left);
      } else {
        this.removeStickyStyling(element);
      }
    });

    this.contentContainer.style.paddingBottom = footerToolbarHeight ? `${footerToolbarHeight}px` : '';
  };

  private checkStickyToolbarsWithDebounce = this.utilService.debounce(() => {
    this.checkStickyToolbars();
  }, 250);

  constructor(
    elementRef: ElementRef,
    private utilService: UtilService,
  ) {
    this.contentContainer = elementRef.nativeElement;
  }

  private applyStickyStyling(element: HTMLElement, top: number, left: number) {
    if (!this.contentContainer.clientWidth) {
      return;
    }
    element.style.setProperty('position', 'fixed', 'important');
    element.style.top = `${top}px`;
    element.style.left = `${left}px`;
    element.style.width = `${this.contentContainer.clientWidth}px`;
    element.style.zIndex = 'var(--z-index-layer-1)';
    element.onwheel = event => {
      element.style.pointerEvents = 'none';
      setTimeout(() => {
        element.style.pointerEvents = '';
      }, 100);
      event.preventDefault();
    };
  }

  private removeStickyStyling(element: HTMLElement) {
    element.style.position = '';
    element.style.top = '';
    element.style.left = '';
    element.style.width = '';
    element.style.zIndex = '';
    element.onwheel = () => {};
  }

  private updateStickyElementsAndSelectors() {
    this.contentContainer.style.clipPath = 'inset(0 0 0 0)';

    if (this.config?.scrollableContainerDepth) {
      this.scrollableElement = this.getScrollableElementByDepth(
        this.contentContainer,
        this.config.scrollableContainerDepth,
      );
    } else {
      this.scrollableElement = this.utilService.getScrollableElement(this.contentContainer);
    }

    if (this.contentContainer.nodeName === 'AG-GRID-ANGULAR') {
      this.headerSelectors = ['.ag-column-drop-wrapper', '.ag-header'];
      this.footerSelectors = ['.ag-body-horizontal-scroll'];
      const agRoot = this.getContentElement('.ag-root');
      if (!agRoot) {
        console.error('omStickyToolbars Directive: cannot find ag-root');
        return;
      }
      this.contentContainer = agRoot;
    }

    this.headerSelectors = [...new Set([...(this.headerSelectors ?? []), ...(this.config?.headerSelectors ?? [])])];
    this.footerSelectors = [
      ...new Set([...(this.footerSelectors ?? []), ...(this.config?.footerSelectors ?? [])]),
    ].reverse();

    this.scrollableElement?.addEventListener('scroll', this.checkStickyToolbars);
    window.addEventListener('resize', this.checkStickyToolbars);

    this.intersectionObserver = new IntersectionObserver(() => this.checkStickyToolbarsWithDebounce());
    this.intersectionObserver.observe(this.contentContainer);
  }

  ngAfterViewInit() {
    this.updateStickyElementsAndSelectors();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.config.isFirstChange()) {
      this.updateStickyElementsAndSelectors();
    }
  }

  private getContentElement(selector: string, last = false): HTMLElement | null {
    if (last) {
      return ([...this.contentContainer.querySelectorAll(selector)].pop() as HTMLElement) ?? null;
    }
    return this.contentContainer.querySelector(selector);
  }

  private getScrollableElementByDepth(element: HTMLElement, depth: number): HTMLElement | null {
    let currentDepth = 0;
    let parent = element.parentElement;

    while (++currentDepth < depth && parent) {
      parent = parent.parentElement;
    }

    return parent;
  }

  ngOnDestroy() {
    this.scrollableElement?.removeEventListener('scroll', this.checkStickyToolbars);
    this.intersectionObserver?.disconnect();
  }
}
