import { BooleanPopupParams } from '@morpho/dialog';

export interface DocumentInfo {
  name: string;
  type: string;
  id: number;
  url: string | null;
  url_document: string | null;
  url_annotated: string | null;
  file_extension: string | null;
  url_pdf: string | null;
  url_signed_pdf: string | null;
  url_unsigned_pdf: string | null;
  isDirectLink?: boolean;
  modal_document?: BooleanPopupParams | null;
}

export interface DocumentListItem {
  label: string;
  actions: DocumentActionItem[];
  url: string | null;
  modalConfig?: BooleanPopupParams;
}

export interface DocumentActionItem {
  label: string;
  type: string;
  url: string;
  note?: string;
  modal?: BooleanPopupParams;
}

export const FileLabels = {
  docx: 'Word',
  pdf: 'PDF',
  csv: 'CSV',
  xlsm: 'Excel',
};
