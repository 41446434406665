<om-rich-text-editor
  [editorType]="editorType"
  [mentionsOptions]="mentionsOptions"
  [readonly]="readonly"
  [userFullName]="userFullName"
  (modelChange)="onRichTextChange($event)"
  [(model)]="richTextValue"
>
</om-rich-text-editor>
@if (!readonly && templateOptions) {
  <div class="rich-text-input-footer" #templateSelect>
    <div class="rich-text-input-footer__controls-left">
      <om-select-input
        [placeholder]="placeholderText + (isEdited ? editedLabel : '')"
        [(ngModel)]="selectedTemplate"
        (ngModelChange)="onSelectedTemplateChange($event)"
        [options]="templateOptions"
      >
      </om-select-input>
    </div>
  </div>
}
