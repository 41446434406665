import { Injectable } from '@angular/core';
import { ApiService, PaginatedResponse, UtilService } from '@morpho/core';
import { Observable, ObservableInput, catchError, defaultIfEmpty, filter, forkJoin, map, of, switchMap } from 'rxjs';
import { IssuerApiService } from '../issuer/issuer-api.service';
import { BulkRatingsResponse, EntityRatings, VeritasEntity } from './state/ratings.model';

const MAX_BULK_SIZE = 200;

@Injectable({
  providedIn: 'root',
})
export class RatingsApiService {
  constructor(
    private apiService: ApiService,
    private issuerApiService: IssuerApiService,
    private utilService: UtilService,
  ) {}

  getRatings(veritasId: number): Observable<EntityRatings> {
    const endpoint = `ratings/issuing-entities/${veritasId}/ratings/`;
    return this.apiService.get(endpoint);
  }

  getBulkRatings(veritasIds: number[]): Observable<BulkRatingsResponse['results']> {
    const endpoint = `ratings/issuing-entity-ratings/`;
    const veritasIds$ = veritasIds.length > 10 ? this.getAllVeritasIds() : of(veritasIds);

    return veritasIds$.pipe(
      switchMap(ids => {
        const paginatedVeritasIds = this.utilService.spliceArrayIntoChunks<number>(ids, MAX_BULK_SIZE);
        const subscriptions: ObservableInput<BulkRatingsResponse>[] = paginatedVeritasIds.map(page => {
          page = page.filter(value => value);
          const params = {
            ids: page,
            limit: MAX_BULK_SIZE,
          };
          return this.apiService.get(endpoint, params).pipe(
            catchError(() => of(undefined)),
            filter(response => !!response),
          );
        });
        return forkJoin(subscriptions).pipe(
          defaultIfEmpty([[]]),
          map((paginatedRatings: BulkRatingsResponse[]) =>
            paginatedRatings.reduce((accumulator, currentPage) => {
              if (!currentPage?.results) {
                return accumulator;
              }
              return [...accumulator, ...currentPage.results];
            }, []),
          ),
        );
      }),
    );
  }

  postRatings(veritasId: number, ratings: Partial<EntityRatings>) {
    const endpoint = `ratings/issuing-entities/${veritasId}/ratings/`;
    return this.apiService.post(endpoint, ratings);
  }

  patchEntity(entityId: number, body: Partial<VeritasEntity>): Observable<VeritasEntity> {
    return this.apiService.patch(`ratings/issuing-entities/${entityId}/`, body);
  }

  createVeritasEntity(payload: { [key: string]: any }) {
    const endpoint = 'ratings/issuing-entities/';
    return this.apiService.post(endpoint, payload);
  }

  mergeVeritasEntities(entityId1: number, entityId2: number): Observable<VeritasEntity> {
    const endpoint = `ratings/issuing-entities/${entityId1}/merge/`;
    return this.apiService.patch(endpoint, { issuing_entity_id: `/issuing-entities/${entityId2}/` });
  }

  getVeritasEntity(entityId: number): Observable<VeritasEntity> {
    const endpoint = `ratings/issuing-entities/${entityId}/`;
    return this.apiService.get(endpoint);
  }
  getVeritasEntitiesPaginated(params: any): Observable<PaginatedResponse<VeritasEntity>> {
    const endpoint = 'ratings/issuing-entities/';
    return this.apiService.get(endpoint, params);
  }

  private getAllVeritasIds(): Observable<number[]> {
    return this.issuerApiService.issuingEntities$.pipe(
      map(entities => entities.map((entity: any) => entity.veritas_issuing_entity_id).filter((id: number) => !!id)),
      map(ids => Array.from(new Set<number>(ids))),
    );
  }
}
