import { NgModule } from '@angular/core';
import { CoreModule } from '@morpho/core';
import { QRCodeModule } from 'angularx-qrcode';
import { TwoFactorAuthenticationComponent } from './components/two-factor-authentication/two-factor-authentication.component';

@NgModule({
  declarations: [TwoFactorAuthenticationComponent],
  imports: [CoreModule, QRCodeModule],
})
export class AuthModule {}
