export const BULLET_LEVELS = 'Bullet Levels';
export const CALLABLE_LEVELS = 'Callable Levels';
export const CUSTOM_LEVELS = 'Custom Levels';
export const CUSTOM_BULLET_LEVELS = 'Custom Bullet Levels';
export const CUSTOM_CALLABLE_LEVELS = 'Custom Callable Levels';
export const BULLET_LEVELS_INPUT = 'My Bullet Levels';
export const CALLABLE_LEVELS_INPUT = 'My Callable Levels';
export const EMAIL_VIEWER = 'Emails / Inbox';

export const action = {
  CREATE_FINAL_TERMS: 'Create Final Terms',
  CREATE_TRADE: 'Create Trade',
  REUSE_TRADE: 'Reuse Termsheet',
};

export const page = {
  HOME: 'Home',
  PROFILE: 'View Profile',
  DEALER_DASHBOARD: 'Dashboard',
  ISSUER_DASHBOARD: 'Dashboard',
  MARKET_INSIGHTS: 'Market Insights',
  DEALER_PROFILE: 'Dealer Profile', // todo change so title is the dealer name
  ISSUER_PROFILE: 'Issuer Profile', // todo change so title is the issuer name

  FUNDING_LEVELS: 'Marketplace',
  CURVE_ANALYSIS: 'Curve Analysis',
  BULLET_LEVELS: 'Bullet Levels',
  BULLET_LEVELS_INPUT: 'My Bullet Levels',
  BULLET_LEVELS_ISSUER: 'Peer Bullet Levels',
  CALLABLE_LEVELS: 'Callable Levels',
  CALLABLE_LEVELS_INPUT: 'My Callable Levels',
  CALLABLE_LEVELS_ISSUER: 'Peer Callable Levels',
  CALLABLE_SEARCH: 'Callable Search',
  CUSTOM_LEVELS: 'Custom Levels',
  CUSTOM_BULLET_LEVELS: 'Custom Bullet Levels',
  CUSTOM_CALLABLE_LEVELS: 'Custom Callable Levels',
  SECONDARY_SCREENER: 'Secondary Curves',
  REPACK_SCREENER: 'Bond Database',
  SYNDICATE_PRICER: 'New Issue Pricer',
  PRICER_DEBUG: 'XCCY Pricer',

  TEMPLATES: 'Templates',

  DOCUMENT_EXTRACTOR: 'Document Extractor',
  DOCUMENT_GENERATOR: 'Document Generator',
  DOCUMENT_COMPARISON: 'Document Comparison',

  DOCUMENTATION_SECTION: 'Documentation',
  TRADE_PAGES: 'My Trades',
  TRADE_BLOTTER: 'Trade Blotter',
  TRADE_DETAILS: 'Trade Timeline',

  CUSTOM_SECTION: 'Custom Pages',
  TRADE_IDS_MANAGER: 'Trade Identifiers Manager',

  CLIENTS: 'Network',
  DEALER_PAGES: 'Dealers',
  DEALERS: 'Dealer List',
  ISSUER_PAGES: 'Issuers',
  ISSUERS: 'Issuer List',
  USER_PAGES: 'Users',
  USERS: 'User List',
  FAVOURITE_LIST: 'Favourite List',

  NOTIFICATIONS: 'Notifications',
  SETTINGS: 'My Settings',
  SETTINGS_EMAIL: 'My Email Settings',
  SUPPORT_DASHBOARD: 'User Dashboard',
  PASSWORD_CHANGE: 'Change Password',
  STAFF_IMPORT_DASHBOARD: 'Staff Import Dashboard',
  EMAIL_PAGES: 'Emails',
};

export const iconTooltips: { [key: string]: string } = {
  from_termsheet: 'This is a termsheet field.\nClick to lock/unlock',
};

export const iconReadOnlyTooltips: { [key: string]: string } = {
  from_termsheet: 'This is a locked termsheet field',
};
