import { OverlayRef } from '@angular/cdk/overlay';

export class CarouselModalOverlayRef {
  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
  }

  isOpen() {
    return this.overlayRef.hasAttached();
  }
}
