<om-primitive-input
  #primitiveInput
  [formControl]="formControl"
  [type]="params.field.type"
  [decimalPlaces]="decimalPlaces"
  [isCellEditor]="true"
  [allowedKeyRegex]="allowedKeyRegex"
  [min]="min"
  [max]="max"
  [maxlength]="maxLength"
  (focusout)="onFocusout()"
></om-primitive-input>
