<ng-container>
  <mat-form-field>
    @switch (type) {
      @case ('date-no-year') {
        <om-date-input
          hideYear
          [hideClearDate]="isEdit"
          [placeholder]="context.placeholder"
          [disabled]="context.disabled"
          [required]="context.required"
          [max]="context.max"
          [min]="context.min"
          [(ngModel)]="item"
          (ngModelChange)="onValueChange($event)"
        >
        </om-date-input>
      }
      @case ('date') {
        <om-date-input
          [hideClearDate]="isEdit"
          [placeholder]="context.placeholder"
          [disabled]="context.disabled"
          [required]="context.required"
          [max]="context.max"
          [min]="context.min"
          [(ngModel)]="item"
          (ngModelChange)="onValueChange($event)"
        >
        </om-date-input>
      }
      @case ('select') {
        <om-select-input
          [ngClass]="{ 'is-caret-hidden': isEdit }"
          [disabled]="context.disabled"
          [placeholder]="context.placeholder"
          [required]="context.required"
          [options]="context.options"
          [(ngModel)]="item"
          (ngModelChange)="onValueChange($event)"
        >
        </om-select-input>
      }
      @default {
        <om-primitive-input
          [disabled]="context.disabled"
          [placeholder]="context.placeholder"
          [required]="context.required"
          [type]="type"
          [(ngModel)]="item"
          (ngModelChange)="onValueChange($event)"
        >
        </om-primitive-input>
      }
    }
    @if (suffixIconButton && !hideButton) {
      <button
        mat-icon-button
        matSuffix
        [disabled]="isButtonDisabled"
        [ngClass]="suffixIconButtonClass"
        (click)="onClickSuffixButton()"
      >
        <mat-icon [svgIcon]="suffixIconButton"></mat-icon>
      </button>
    }
  </mat-form-field>
</ng-container>
