import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DIALOG_WIDTH_DOCUMENT_EXTRACTOR, PopupService, RoutingService, UtilService } from '@morpho/core';
import { DialogService } from '@morpho/dialog';
import { CreateTradeModalToggleButton, DynamicFormModalParams } from '@morpho/dynamic-form';
import { action } from 'apps/bankangle/src/app/constants/wording';
import { TradeWorkingGroupModalComponent } from 'apps/bankangle/src/app/features/trades/components/trade-working-group-modal/trade-working-group-modal.component';
import { TradeEntryPoint } from 'apps/bankangle/src/app/features/trades/trades.constants';
import { CreateTradeModalConfig } from 'apps/bankangle/src/app/models/trade';
import { Observable } from 'rxjs';
import { DocumentExtractorUploadModalComponent } from '../../features/document-extractor/components/document-extractor-upload-modal/document-extractor-upload-modal.component';
import { TradeBlotterTrade } from '../../features/trades/models/trade-blotter-trade';
import { TradesApiService } from '../../features/trades/services/trades-api.service';

export enum EntryPoint {
  Indicative = 'indicative_termsheet',
  Final = 'final_termsheet',
  PostTrade = 'post_trade_legal',
}
@Injectable({
  providedIn: 'root',
})
export class TradesDialogService {
  constructor(
    private dialogService: DialogService,
    private popupService: PopupService,
    private routingService: RoutingService,
    private tradesApiService: TradesApiService,
    private utilService: UtilService,
  ) {}

  tradeWorkingGroupModal(): Observable<any> {
    const config: MatDialogConfig = {
      panelClass: ['is-modal', 'is-modal-fixed-height'],
      height: '620px',
    };

    return this.dialogService.openInModal(TradeWorkingGroupModalComponent, config);
  }

  editSwapTermsheetModal(tradeId: number, action: string) {
    action = action[0].toUpperCase() + action.slice(1);
    const params: DynamicFormModalParams = {
      title: `${action} Swap Termsheet`,
      url: `transactions/trade/${tradeId}/swap-termsheet/`,
      optionsUrl: `transactions/trade/${tradeId}/swap-termsheet/options/`,
      text: action,
    };
    return this.dialogService.dynamicFormModal(params);
  }

  private goToDocumentGenerator(id?: number) {
    if (id) {
      this.routingService.routeTo(`/trades/${id}/document-generator/documents`);
    }
  }

  private goToTradeTimeline(id?: number) {
    if (id) {
      this.routingService.routeTo(`/trades/${id}`);
    }
  }

  createTradeModal(createTradeModalConfig?: CreateTradeModalConfig, dialogConfig?: MatDialogConfig) {
    const params: DynamicFormModalParams = this.buildModalParams(createTradeModalConfig);

    this.dialogService.dynamicFormModal(params, dialogConfig).subscribe(event => {
      const entryPoint = event?.formValue?.entry_point;
      if (entryPoint && [EntryPoint.Indicative, EntryPoint.Final].includes(entryPoint)) {
        this.goToDocumentGenerator(event?.apiResponse?.id);
      } else {
        this.goToTradeTimeline(event?.apiResponse?.id);
      }
    });
  }

  private buildModalParams(config?: CreateTradeModalConfig): DynamicFormModalParams {
    if (config?.shortcut) {
      return {
        title: config.shortcut.label,
        text: 'Create',
        supertitle: 'Use Prefilled Termsheet:',
        url: config.shortcut.url,
        optionsUrl: config.shortcut.options_url,
      };
    }

    const optionsUrlQueryParams = this.buildOptionsUrlQueryParams(config);

    const url = 'transactions/trade/';
    const optionsUrl = 'transactions/trade/options/';
    const uploadOptionsUrl = 'transactions/trade-onboard/options';

    const canCreateBothFlows = config?.canCreateFinalTerms && config.canCreateTermsheet;

    const draftToggleButton = this.createDraftToggleButton(url, optionsUrl, optionsUrlQueryParams, config);
    const uploadToggleButton = this.createUploadToggleButton(uploadOptionsUrl, config);

    return {
      title: action.CREATE_TRADE,
      text: 'Create',
      toggle_buttons: {
        initial_value: canCreateBothFlows || config?.canCreateTermsheet ? 'draft' : 'upload',
        options: [
          {
            value: 'draft',
            label: draftToggleButton.text,
            icon: 'draft_termsheet',
          },
          {
            value: 'upload',
            label: uploadToggleButton.text,
            icon: 'file_upload',
            isBetaFlag: true,
          },
        ],
        config: { draft: draftToggleButton, upload: uploadToggleButton },
      },
    };
  }

  private buildOptionsUrlQueryParams(config?: CreateTradeModalConfig): Record<string, string> {
    const params: Record<string, string> = {};

    if (config?.issuerId) {
      params.issuer_id = config.issuerId.toString();
    }
    return params;
  }

  private documentExtractorUploadModal(data?: Record<any, any>) {
    const config: MatDialogConfig = {
      panelClass: 'is-modal',
      data: data ?? {},
      width: '100%',
      maxWidth: DIALOG_WIDTH_DOCUMENT_EXTRACTOR,
    };
    return this.dialogService.openInModal(DocumentExtractorUploadModalComponent, config);
  }

  private createDraftToggleButton(
    url: string,
    optionsUrl: string,
    optionsUrlQueryParams: Record<string, string>,
    config?: CreateTradeModalConfig,
  ): CreateTradeModalToggleButton {
    const canCreateBothFlows = config?.canCreateFinalTerms && config.canCreateTermsheet;

    return {
      text: `Draft ${canCreateBothFlows ? 'Document' : config?.canCreateTermsheet ? 'Termsheet' : 'Final Terms'}`,
      url,
      optionsUrl: this.appendQueryParamsToUrl(optionsUrl, optionsUrlQueryParams),
    };
  }

  private createUploadToggleButton(optionsUrl: string, config?: CreateTradeModalConfig): CreateTradeModalToggleButton {
    const canCreateBothFlows = config?.canCreateFinalTerms && config.canCreateTermsheet;

    return {
      text: `Upload ${canCreateBothFlows || config?.canCreateFinalTerms ? 'Document' : 'Termsheet'}`,
      submitAction: data => this.documentExtractorUploadModal(data),
      optionsUrl,
    };
  }

  private appendQueryParamsToUrl(path: string, queryParams: Record<string, string>): string {
    const queryParamsString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return queryParamsString ? `${path}?${queryParamsString}` : path;
  }

  reuseTradeModal(trade?: TradeBlotterTrade): void {
    if (trade) {
      this._reuseTradeModal([trade]);
    }
  }

  private _reuseTradeModal(trades: TradeBlotterTrade[]) {
    const tradeOptions = trades.map((option: TradeBlotterTrade) => ({
      value: option.id,
      label: option.description,
      description: this.utilService.convertFromBackendDateStringFormat(option.last_updated, 'datetime'),
      src: option.issuer.square_logo,
    }));

    const params: DynamicFormModalParams = {
      title: action.REUSE_TRADE,
      text: 'Reuse',
      options: [
        {
          element: 'autocomplete',
          name: 'reuseTradeId',
          label: 'Termsheet',
          initial: tradeOptions.length === 1 ? tradeOptions[0].value : undefined,
          options: tradeOptions,
          required: true,
        },
        {
          element: 'button_toggle',
          name: 'isTest',
          label: 'Trade Status',
          help: 'A test trade will have all the functionalities of live trades. However, all data sent to third parties is redirected to a dedicated testing environments and any received data is fake and created for the purpose of this test trade.',
          options: [
            { label: 'Live', value: false },
            { label: 'Test', value: true },
          ],
          required: true,
        },
        {
          element: 'button_toggle',
          name: 'entryPoint',
          label: 'Starting Stage',
          options: [
            { label: 'Indicative', value: TradeEntryPoint.IndicativeTermsheet },
            { label: 'Final', value: TradeEntryPoint.FinalTermsheet },
          ],
          required: true,
        },
      ],
    };

    this.dialogService.dynamicFormModal(params).subscribe(event => {
      const reuseTradeId = event?.formValue?.reuseTradeId;
      const isTest = event?.formValue?.isTest;
      const entryPoint: TradeEntryPoint = event?.formValue?.entryPoint;
      if (reuseTradeId) {
        this.tradesApiService.reuseTrade(reuseTradeId, isTest, entryPoint).subscribe(
          response => {
            this.goToDocumentGenerator(response?.id);
          },
          error => {
            const errorMessage = error.detail || 'Unable to reuse termsheet. Please try again later.';
            this.popupService.error(errorMessage);
          },
        );
      }
    });
  }
}
