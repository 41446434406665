import { createAction, props } from '@ngrx/store';
import { LegendSelection } from '@shared-echarts/models';
import { Curve, CurvesRequestOptions } from 'apps/bankangle/src/app/models/curve.model';
import { RegressionConfig, RegressionObject } from '../../../models/regression.model';
import { PricingInfo } from '../../patterns/pricing/pricing.model';
import { CurveAnalysisLoadingStage } from './curve-analysis.model';

const CURVE_ANALYSIS = '[Curve Analysis]';
const GET_CURVES = `${CURVE_ANALYSIS} Get curves`;
const SET_PRICING_INFO_LIST = `${CURVE_ANALYSIS} Set pricing info list`;
const PRICE_CURVES = `${CURVE_ANALYSIS} Price curves`;
const ADD_RATINGS_TO_CURVES = `${CURVE_ANALYSIS} Add ratings to curves`;
const SET_RAW_CURVES = `${CURVE_ANALYSIS} Set raw curves`;
const SET_PRICED_CURVES = `${CURVE_ANALYSIS} Set priced curves`;

const SET_INITIAL_CURVES_TO_DISPLAY = `${CURVE_ANALYSIS} Set initial curves to display`;
const SET_CURVES_TO_DISPLAY = `${CURVE_ANALYSIS} Set curves to display`;
const SET_BONDS_TO_DISPLAY = `${CURVE_ANALYSIS} Set bonds to display`;
const SET_LEGEND_SELECTION = `${CURVE_ANALYSIS} Set legend selection`;
const SET_SELECTED_ISSUERS = `${CURVE_ANALYSIS} Set selected issuers`;
const SET_PRICER_VALUE = `${CURVE_ANALYSIS} Set pricer value`;

const COMPUTE_CURVE_REGRESSIONS = `${CURVE_ANALYSIS} Compute curve regressions`;
const SET_CURVE_REGRESSIONS = `${CURVE_ANALYSIS} Set curve regressions`;

const SET_COLOR_CONFIG = `${CURVE_ANALYSIS} Set color config`;

const UPDATE_LOADING_STATE = `${CURVE_ANALYSIS} Update loading state`;

const RESET_STATE = `${CURVE_ANALYSIS} Reset state`;
const RESET_ANALYSIS = `${CURVE_ANALYSIS} Reset analysis`;

export const CurveAnalysisEffect = {
  GET_CURVES,
  PRICE_CURVES,
  ADD_RATINGS_TO_CURVES,
  SET_INITIAL_CURVES_TO_DISPLAY,
  COMPUTE_CURVE_REGRESSIONS,
};

export const CurveAnalysisAction = {
  getCurves: createAction(GET_CURVES, props<{ params: CurvesRequestOptions }>()),
  setPricingInfoList: createAction(SET_PRICING_INFO_LIST, props<{ params: { pricingInfoList: PricingInfo[] } }>()),
  priceCurves: createAction(PRICE_CURVES),
  addRatingsToCurves: createAction(ADD_RATINGS_TO_CURVES),

  setRawCurves: createAction(SET_RAW_CURVES, props<{ params: { curves: Curve[] } }>()),
  setPricedCurves: createAction(SET_PRICED_CURVES, props<{ params: { curves: Curve[] } }>()),
  setInitialCurvesToDisplay: createAction(SET_INITIAL_CURVES_TO_DISPLAY),
  setCurvesToDisplay: createAction(SET_CURVES_TO_DISPLAY, props<{ params: { curveIds: string[] } }>()),
  setBondsToDisplay: createAction(SET_BONDS_TO_DISPLAY, props<{ params: Record<string, boolean> }>()),
  setLegendSelection: createAction(SET_LEGEND_SELECTION, props<{ params: { selection: LegendSelection } }>()),

  updateLoadingState: createAction(
    UPDATE_LOADING_STATE,
    props<{ params: { stage: CurveAnalysisLoadingStage; isLoading: boolean } }>(),
  ),

  setSelectedIssuers: createAction(SET_SELECTED_ISSUERS, props<{ params: { issuers: number[] } }>()),
  setPricerValue: createAction(SET_PRICER_VALUE, props<{ params: { value: string } }>()),

  computeCurveRegressions: createAction(COMPUTE_CURVE_REGRESSIONS, props<{ params: RegressionConfig }>()),
  setCurveRegressions: createAction(
    SET_CURVE_REGRESSIONS,
    props<{ params: { config: RegressionConfig; curveRegressions: Record<string, RegressionObject[]> } }>(),
  ),

  setColorConfig: createAction(SET_COLOR_CONFIG, props<{ params: Record<string, number> }>()),

  resetState: createAction(RESET_STATE),

  resetAnalysis: createAction(RESET_ANALYSIS),
};
