import { Injectable } from '@angular/core';
import { LevelsUtilService, UtilService } from '@morpho/core';
import * as ecStat from 'echarts-stat';
import { sortByTenor } from '../../../constants/sortComparators';
import { Curve, CurveBond, CurveLevel, CurveType } from '../../../models/curve.model';
import {
  DEFAULT_REGRESSION_CONFIG,
  LinearLogarithmicExpression,
  PolynomialExpression,
  RegressionConfig,
  RegressionData,
  RegressionMode,
  RegressionObject,
  ecStatRegressionResult,
} from '../../../models/regression.model';

@Injectable({
  providedIn: 'root',
})
export class RegressionService {
  constructor(
    private levelsUtilService: LevelsUtilService,
    private utilService: UtilService,
  ) {}

  /**
   * Given an x and regression object return y value
   * @param  {number} x - x value to input
   * @param  {RegressionObject} regression - object defining regression equation
   * @return {number | null} - the y value computed by the equation or null error case
   */
  applyRegressionEquation(x: number, regression: RegressionObject): number | null {
    let spread: number | null;
    let expression = regression.expression;

    switch (regression.mode) {
      case RegressionMode.Polynomial:
        expression = expression as PolynomialExpression;
        spread = expression.length
          ? expression.reduce((acc, coefficients, index) => acc + coefficients * Math.pow(x, index), 0)
          : null;
        return spread;
      case RegressionMode.Linear:
        expression = expression as LinearLogarithmicExpression;
        spread = expression.gradient === null ? null : expression.gradient * x + expression.intercept;
        return spread;
      case RegressionMode.Logarithmic:
        expression = expression as LinearLogarithmicExpression;
        spread = expression.gradient === null ? null : expression.gradient * Math.log(x) + expression.intercept;
        return spread;
      default:
        return null;
    }
  }

  /**
   * Computes the regression object for a given dataset
   * @param  {RegressionData} data - An array of data points which we can apply linear regression to
   * @param  {RegressionConfig} options - Configuration settings for the type of regression applied
   * @return {RegressionObject} - The regression functions results, data points, mode used to create
   * and the expression itself
   */
  regression(data: RegressionData, options: RegressionConfig = DEFAULT_REGRESSION_CONFIG): RegressionObject {
    if (options.mode === RegressionMode.Interpolated || data.length === 0) {
      return { data, mode: options.mode };
    }

    const regressionObjects: ecStatRegressionResult = ecStat.regression(
      options.mode,
      data,
      options.order ?? DEFAULT_REGRESSION_CONFIG.order,
    ) as any as ecStatRegressionResult;

    return {
      data: regressionObjects.points,
      mode: options.mode,
      expression: regressionObjects.parameter,
    };
  }

  /**
   * Generates a dataset for a given curve, converts the levels/bonds into a standardized dataset
   * which we can use to plot or apply regression to
   * @param  {Curve} curve - The curve we want to create the dataset from
   * @return {RegressionObject[]} - Standardized form of the data
   */
  generateCurveDataset(curve: Curve, selectedBonds: Record<string, boolean>): RegressionData[] {
    return curve.curve_type === CurveType.SECONDARY
      ? this.generateSecondaryDataSet(curve, selectedBonds)
      : this.generatePrimaryDataSet(curve);
  }

  private parseSize(size: string): number {
    const sizeExponent = size.includes('bn')
      ? 1000 * 1000 * 1000
      : size.includes('mm')
        ? 1000 * 1000
        : size.includes('k')
          ? 1000
          : 1;
    return sizeExponent * (this.utilService.parseNumber(size) ?? 0);
  }

  private generateCurveDataPoint(
    tenor: string,
    source: CurveLevel | CurveBond,
    index: number,
    size = '0',
  ): [number, number, number] | null {
    const parsedTenor: number | null = this.levelsUtilService.getValueFromTenor(tenor);
    const parsedLevel: number | null = source.priced?.[index]
      ? this.levelsUtilService.getValueFromBasis(source.priced[index].spread, source.priced[index].funding_basis)
      : null;
    const parsedSize = this.parseSize(size);

    if (parsedTenor === null || parsedLevel === null || parsedSize === null) {
      return null;
    }

    return [parsedTenor, parsedLevel, parsedSize];
  }

  private generatePrimaryDataSet(curve: Curve): RegressionData[] {
    const data: RegressionData[] = [];
    for (let i = 0; i < 2; i++) {
      const fundingBasisData: RegressionData = [];
      const orderedMaturities = Object.entries(curve.levels).sort(([tenorA], [tenorB]) => sortByTenor(tenorA, tenorB));

      orderedMaturities.forEach(([tenor, level]) => {
        const dataPoint = this.generateCurveDataPoint(tenor, level, i);
        if (dataPoint) {
          fundingBasisData.push(dataPoint);
        }
      });
      if (fundingBasisData.length) {
        data.push(fundingBasisData);
      }
    }
    return data;
  }

  private generateSecondaryDataSet(curve: Curve, selectedBonds: Record<string, boolean>): RegressionData[] {
    const data: RegressionData[] = [];
    for (let i = 0; i < 2; i++) {
      const fundingBasisData: RegressionData = [];
      curve.bonds.forEach(bond => {
        if (!selectedBonds[bond.isin]) {
          return;
        }
        const dataPoint = this.generateCurveDataPoint(bond.tenor, bond, i, bond.size);
        if (dataPoint) {
          fundingBasisData.push(dataPoint);
        }
      });
      if (fundingBasisData.length) {
        data.push(fundingBasisData);
      }
    }
    return data;
  }
}
