import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { KeyCode, RecipientItemData, ValueAndLabel } from '@morpho/core';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { AutocompleteInputConfig, OptionGroup } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

const MAX_OPTIONS_RENDERED = 300;

@Component({
  standalone: false,
  selector: 'om-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => AutocompleteInputComponent)),
})
export class AutocompleteInputComponent
  extends CustomFormFieldControlComponent
  implements OnInit, AfterViewInit, OnChanges
{
  readonly LAST_SELECTED_CHIP_ID = 'lastSelectedChip';
  visible = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  inputCtrl = new FormControl();
  filteredOptions$: Observable<ValueAndLabel[]>;
  filteredOptionGroups$: Observable<OptionGroup[]>;
  chipsList: ValueAndLabel[] = [];
  filteredSuggestions: ValueAndLabel[] = [];
  flattenedOptionsList: ValueAndLabel[] = [];
  hasNestedOptions = false;
  initialHeight: number | undefined;
  offsetParent: HTMLElement | undefined;
  currentHeight: number | undefined;
  allOptionGroupsEmpty = false;
  linkedSelectionValues: any[] = [];
  disabledMap: Record<string, boolean> = {};

  @ViewChild('childInput') childInput: ElementRef<HTMLInputElement>;
  @ViewChild('formField') formField: MatFormField;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger;

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (!this.childInput.nativeElement.value.length && this.suggestions?.length && event.key === KeyCode.Escape) {
      document.getElementById(this.LAST_SELECTED_CHIP_ID)?.focus();
    }
  }

  @HostListener('document:wheel', ['$event'])
  onScroll(event: WheelEvent) {
    if (
      !event.composedPath().some((element: HTMLElement) => element.classList?.contains('mat-mdc-autocomplete-panel'))
    ) {
      if (this.trigger.panelOpen) {
        event.stopPropagation();
        this.trigger.closePanel();
      }
    }
  }

  @Input() fieldConfig: AutocompleteInputConfig;

  private isInitialised = false;

  @Input()
  get value(): any | null {
    return this._value;
  }
  set value(val: any | null) {
    if (!this.isInitialised || JSON.stringify(val) !== JSON.stringify(this.value)) {
      this._value = val;
      this.setChildInputValue(this._value);
      this.handleChipChanges();
      this.emitChanges(!this.isInitialised);
      this.validateValueAgainstOptions();
      if (this.linkedSelection && !this.isInitialised) {
        this.setInitialLinkedSelectionValue();
      }
      this.isInitialised = true;
    }
  }
  _value: any | null;

  @Input()
  get options(): ValueAndLabel[] | OptionGroup[] {
    return this._options;
  }
  set options(opts: ValueAndLabel[] | OptionGroup[]) {
    this._options = opts;
    if (this.value !== undefined) {
      this.setChildInputValue(this.value);
    }
    this.handleChipChanges();
    this.validateValueAgainstOptions();
    this.allOptionGroupsEmpty = this.checkAllOptionGroupsAreEmpty();
  }
  private _options: ValueAndLabel[] | OptionGroup[] = [];

  @Input()
  get suggestions(): ValueAndLabel[] {
    return this._suggestions;
  }
  set suggestions(suggestions: ValueAndLabel[]) {
    if (Array.isArray(suggestions)) {
      this._suggestions = [...suggestions];
      this.filteredSuggestions = [...suggestions];
    }
  }
  private _suggestions: ValueAndLabel[];

  @Input()
  get linkedSelection(): boolean | null {
    return this._linkedSelection;
  }
  set linkedSelection(linkedSelection: boolean | null) {
    this._linkedSelection = coerceBooleanProperty(linkedSelection);
  }
  private _linkedSelection: boolean | null;

  @Input()
  get multiple(): boolean | null {
    return this._multiple;
  }
  set multiple(multiple: boolean | null) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  private _multiple: boolean | null;

  @Input()
  get suffixButton(): boolean | null {
    return this._suffixButton;
  }
  set suffixButton(suffixButton: boolean | null) {
    this._suffixButton = coerceBooleanProperty(suffixButton);
  }
  private _suffixButton: boolean | null;

  @Input()
  get relaxed(): boolean | null {
    return this._relaxed;
  }
  set relaxed(relaxed: boolean | null) {
    this._relaxed = coerceBooleanProperty(relaxed);
  }
  private _relaxed: boolean | null;

  @Input()
  get disableAutoSelect(): boolean | null {
    return this._disableAutoSelect;
  }
  set disableAutoSelect(disableAutoSelect: boolean | null) {
    this._disableAutoSelect = coerceBooleanProperty(disableAutoSelect);
  }
  private _disableAutoSelect: boolean | null;

  @Input()
  get messageTitle(): string | null {
    return this._messageTitle;
  }
  set messageTitle(messageTitle: string | null) {
    this._messageTitle = messageTitle;
  }
  private _messageTitle: string | null;

  @Input()
  get messageDetails(): string | null {
    return this._messageDetails;
  }
  set messageDetails(messageDetails: string | null) {
    this._messageDetails = messageDetails;
  }
  private _messageDetails: string | null;

  @Input()
  get allowValueNotInOptions(): boolean {
    return this._allowValueNotInOptions;
  }
  set allowValueNotInOptions(allowValueNotInOptions: boolean | string) {
    this._allowValueNotInOptions = coerceBooleanProperty(allowValueNotInOptions);
  }
  private _allowValueNotInOptions = false;

  @Input()
  prefixIcon: string;

  @Input()
  suffixIcon: string;

  @Input()
  classList: string | string[];

  @Input()
  get isFilterIgnored(): boolean {
    return this._isFilterIgnored;
  }
  set isFilterIgnored(isFilterIgnored: boolean | string) {
    this._isFilterIgnored = coerceBooleanProperty(isFilterIgnored);
  }
  private _isFilterIgnored = false;

  @Output() filterTextChanged = new EventEmitter<string>();

  private optionFilter(value: string, options: ValueAndLabel[]): ValueAndLabel[] {
    if (typeof value !== 'string' || !options?.length) {
      return [];
    }
    const filterValue = this.utilService.sanitiseStringValue(value.toString().toLowerCase().trim());

    return (options as ValueAndLabel[]).filter(option => this.findSearchValueInOption(option, filterValue));
  }

  private findSearchValueInOption(option: ValueAndLabel, searchValue: string): boolean {
    const cleanLabel = this.utilService.sanitiseStringValue(option.label);
    const cleanDescription = option.description ? this.utilService.sanitiseStringValue(option.description) : '';
    const cleanValue = this.utilService.sanitiseStringValue(String(option.value));
    const cleanSearchValue = this.utilService.sanitiseStringValue(searchValue);
    return (
      cleanLabel.includes(cleanSearchValue) ||
      cleanValue.includes(cleanSearchValue) ||
      cleanDescription.includes(cleanSearchValue) ||
      this.findSearchValueInArray(cleanSearchValue, option.search)
    );
  }

  private findSearchValueInArray(searchVal: string, arr: string[] | undefined): boolean {
    if (!arr) {
      return false;
    }
    return arr.reduce((acc, val) => {
      if (acc) {
        return true;
      }
      if (this.utilService.sanitiseStringValue(val).includes(searchVal)) {
        return true;
      }
      return false;
    }, false);
  }

  private groupFilter(value: string, options: OptionGroup[]): OptionGroup[] {
    return options
      .map(group => ({
        divider_label: group.divider_label,
        options: this.optionFilter(value, group.options),
        enable_select_all: group.enable_select_all,
        filtered: true,
      }))
      .filter(group => group.options.length);
  }

  ngOnInit(): void {
    const emitFilterTextChange = this.utilService.debounce((value: string) => {
      this.filterTextChanged.emit(value);
    }, 500);

    this.inputCtrl.valueChanges.subscribe(value => {
      emitFilterTextChange(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.handleChipChanges();
      if (JSON.stringify(changes.options.currentValue) !== JSON.stringify(changes.options.previousValue)) {
        this.handleOptionsChanges();
      }
    }
  }

  ngAfterViewInit(): void {
    this.setChildInputValue(this.value);
  }

  handleChipChanges() {
    if (!this._options) {
      return;
    }
    this.hasNestedOptions = !!(this._options[0] as OptionGroup)?.divider_label;

    this.flattenedOptionsList = this.utilService.flattenOptions(this._options);
    this.setChipsList();
  }

  handleOptionsChanges() {
    if (this.hasNestedOptions) {
      if (!this.isFilterIgnored) {
        this.filteredOptionGroups$ = this.inputCtrl.valueChanges.pipe(
          startWith(null),
          debounceTime(250),
          map((searchValue: string | null) => {
            return searchValue
              ? this.groupFilter(searchValue, this._options as OptionGroup[]).splice(0, MAX_OPTIONS_RENDERED)
              : (this._options as OptionGroup[])?.slice(0, MAX_OPTIONS_RENDERED);
          }),
        );
      } else {
        this.filteredOptionGroups$ = of(this._options as OptionGroup[]);
      }
    } else {
      if (!this.isFilterIgnored) {
        this.filteredOptions$ = this.inputCtrl.valueChanges.pipe(
          startWith(null),
          debounceTime(250),
          map((searchValue: string | null) => {
            return searchValue
              ? this.optionFilter(searchValue, this._options as ValueAndLabel[]).splice(0, MAX_OPTIONS_RENDERED)
              : (this._options as ValueAndLabel[])?.slice(0, MAX_OPTIONS_RENDERED);
          }),
        );
      } else {
        this.filteredOptions$ = of(this._options as ValueAndLabel[]);
      }
    }
  }

  setChildInputValue(val?: any): void {
    if ((val || val === 0 || val === false) && !this._multiple) {
      const selectedOption = this.utilService.getAttributeFromOptions(val, 'value', this.flattenedOptionsList);
      setTimeout(() => {
        this.childInput.nativeElement.value = selectedOption
          ? (selectedOption as ValueAndLabel).label
          : this.relaxed
            ? val
            : '';
      });
    } else {
      setTimeout(() => {
        if (this.fieldConfig?.required && this.flattenedOptionsList.length === 1) {
          this.addSelectedOption(this.flattenedOptionsList[0]);
        } else {
          this.childInput.nativeElement.value = this.inputCtrl.value;
        }
      });
    }
  }

  add(event: { value: string }): void {
    const selectedOption = this.utilService.getAttributeFromOptions(event.value, 'label', this.flattenedOptionsList);
    let resultLength;
    let topOption;
    if (this.hasNestedOptions) {
      resultLength = this.groupFilter(event.value, this._options as OptionGroup[]).reduce(
        (acc: number, currentValue: OptionGroup) => acc + currentValue.options.length,
        0,
      );
      topOption = this.groupFilter(event.value, this._options as OptionGroup[])[0]?.options[0];
    } else {
      resultLength = this.optionFilter(event.value, this._options as ValueAndLabel[]).length;
      topOption = this.optionFilter(event.value, this._options as ValueAndLabel[])[0];
    }

    if (selectedOption && !this._multiple) {
      this.addSelectedOption(selectedOption as ValueAndLabel);
      return;
    }

    // if we allow custom values, at this point, just add what has been entered
    if (event.value && this._relaxed) {
      this.addCustomOption(event.value);
      return;
    }

    // There is an inputted value - but only one value in the filter results
    if (resultLength === 1 && event.value && !this._multiple) {
      // add the only option
      this.addSelectedOption(topOption as ValueAndLabel);
      return;
    }

    if (this._multiple) {
      // clear out the visible control
      this.childInput.nativeElement.value = '';
      this.inputCtrl.setValue('');
    } else {
      this.setFieldValueToNull();
    }
  }

  onBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (
      !target ||
      (target.nodeName !== 'MAT-OPTION' &&
        !target.classList.contains('mdc-checkbox__native-control') &&
        !this.disableAutoSelect)
    ) {
      const matChipEvent = {
        value: this.childInput.nativeElement.value,
      };
      this.add(matChipEvent);
    }
  }

  onClickFormField() {
    this.initialHeight = this.formField._elementRef.nativeElement.clientHeight;
    this.offsetParent = this.formField._elementRef.nativeElement.offsetParent.offsetParent.offsetParent;
  }

  onClick(event: MouseEvent) {
    if (!this.trigger.panelOpen) {
      this.trigger.openPanel();
    }
  }

  onClickChipList() {
    this.childInput.nativeElement.focus();
  }

  onClickChip(event: MouseEvent) {
    event.stopImmediatePropagation();
    if (!this.trigger.panelOpen) {
      this.trigger.openPanel();
    } else {
      this.trigger.closePanel();
    }
  }

  isCheckedOptionGroup(optionGroup: OptionGroup): boolean {
    if (!this.value) {
      return false;
    }
    return optionGroup.options.map(option => option.value).every(value => this.value.includes(value));
  }

  isIndeterminateOptionGroup(optionGroup: OptionGroup): boolean {
    if (!this.value || this.isCheckedOptionGroup(optionGroup)) {
      return false;
    }

    return optionGroup.options.map(option => option.value).some(value => this.value.includes(value));
  }

  onClickCheckbox(event: MouseEvent) {
    event.stopPropagation();
    this.childInput.nativeElement.focus();
  }

  removeSingleValue(event: MouseEvent) {
    event.stopPropagation();
    this.value = null;
  }

  remove(value: ValueAndLabel): void {
    if (this.disabled) {
      return;
    }
    this.value = [...(this.value ?? [])].filter((option: any) => option !== value.value);
    this.setChipsList();
    this.resetSuggestions();
    if (this.linkedSelection) {
      const indexOfValue = this.linkedSelectionValues.indexOf(value.value);
      if (indexOfValue !== -1) {
        this.linkedSelectionValues.splice(indexOfValue, 1);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addSelectedOption(event.option.value);
    this.closePanel();
  }

  toggleOption(option: ValueAndLabel, selected: boolean) {
    if (selected) {
      this.addSelectedOption(option);
    } else {
      this.remove(option);
    }
    this._setAutocompletePanelMargin();
  }

  toggleLinkedSelectionOption(option: ValueAndLabel, selected: boolean) {
    if (selected) {
      this.addSelectedLinkedSelectionOption(option);
      this.selectChildrenCheckboxes(option);
    } else {
      this.remove(option);
      this.deselectChildrenCheckboxes(option);
    }
    this._setAutocompletePanelMargin();
  }

  toggleOptionGroup(optionGroup: OptionGroup, selected: boolean) {
    optionGroup.options.forEach(option => this.toggleOption(option, selected));
  }

  private _setAutocompletePanelMargin = this.utilService.debounce(() => {
    this.currentHeight = this.formField._elementRef.nativeElement.clientHeight;
    const autocomplete: HTMLElement = this.matAutocomplete.panel?.nativeElement;
    if (this.initialHeight && this.offsetParent && this.currentHeight) {
      const denominator = this.offsetParent.nodeName !== 'DIV' ? 1 : 2;
      const height = Math.round(Math.abs(this.initialHeight - this.currentHeight) / denominator);
      if (this.initialHeight <= this.currentHeight) {
        autocomplete.style.marginTop = `${height}px`;
      } else {
        autocomplete.style.marginTop = `-${height}px`;
      }
    }
  });

  private setChipsList(): void {
    if (!this._multiple) {
      return;
    }
    if (this.value?.length) {
      this.chipsList = this.flattenedOptionsList.filter(option => this.value.includes(option.value));
      this.chipsList.sort((a, b) => this.value.indexOf(a.value) - this.value.indexOf(b.value));
    } else {
      this.chipsList = [];
    }
  }

  private closePanel() {
    if (this.trigger.panelOpen) {
      this.trigger.closePanel();
    }
  }

  private addCustomOption(value: any) {
    const customOption: ValueAndLabel = {
      value,
      label: value.toString(),
    };
    (this.options ?? []).push(customOption as ValueAndLabel & OptionGroup);
    this.addSelectedOption(customOption);
    this.handleChipChanges();
    this.closePanel();
    this.childInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
  }

  private addSelectedLinkedSelectionOption(selectedOption: ValueAndLabel, isInitial?: boolean) {
    this.linkedSelectionValues.push(selectedOption.value);

    if (!isInitial) {
      const parentInOptions = this.utilService
        .flattenOptions(this.options)
        .some(
          parent =>
            parent.childrenValues?.includes(selectedOption.value) && this.linkedSelectionValues.includes(parent.value),
        );

      const childrenInOptions = selectedOption.childrenValues?.some(child =>
        this.linkedSelectionValues.includes(child),
      );

      if (!parentInOptions) {
        this.chipsList.push(selectedOption);
        this.value = [...(this.value ?? []), selectedOption.value];
        this.childInput.nativeElement.value = this.inputCtrl.value;
      }

      if (childrenInOptions) {
        selectedOption.childrenValues?.forEach(child => {
          this.value = [...(this.value ?? [])].filter((option: any) => option !== child);
          this.setChipsList();
        });
      }
    }
  }

  private addSelectedOption(selectedOption: ValueAndLabel) {
    if (this._multiple) {
      if (!this.utilService.getAttributeFromOptions(selectedOption.value, 'value', this.chipsList)) {
        this.chipsList.push(selectedOption);
        this.value = [...(this.value ?? []), selectedOption.value];
        this.childInput.nativeElement.value = this.inputCtrl.value;
      } else {
        this.childInput.nativeElement.value = '';
        this.inputCtrl.setValue(null);
      }
      this.removeSuggestion(selectedOption);
    } else {
      this.childInput.nativeElement.value = selectedOption.label;
      this.value = selectedOption.value;
      this.inputCtrl.setValue(null);
    }
  }

  private setFieldValueToNull() {
    this.childInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.value = null;

    if (this.trigger.panelOpen) {
      this.trigger.closePanel();
    }
  }

  private removeSuggestion(selectedOption: ValueAndLabel) {
    if (this.filteredSuggestions?.length) {
      const index = this.filteredSuggestions.findIndex((val: ValueAndLabel) => val.value === selectedOption.value);
      if (index >= 0) {
        this.filteredSuggestions.splice(index, 1);
      } else {
        this.filteredSuggestions.pop();
      }
    }
  }

  private resetSuggestions() {
    this.filteredSuggestions = (this.suggestions ??= []).filter(
      suggestion => (this.value ?? []).indexOf(suggestion.value) === -1,
    );
  }

  private validateValueAgainstOptions() {
    if (this.relaxed || this.allowValueNotInOptions || !this.options?.length) {
      return;
    }
    const value = this.value;
    const optionValues = this.flattenedOptionsList.map(option => option.value);
    if (Array.isArray(value) && this.multiple) {
      const validatedValue = value.filter((val: any) => optionValues.includes(val));
      if (!this.utilService.areArraysEquivalent(validatedValue, this.value)) {
        setTimeout(() => {
          this.value = validatedValue;
        });
      }
    }
    if (!Array.isArray(value) && !optionValues.includes(value)) {
      this.setChildInputValue();
    }
  }

  private checkAllOptionGroupsAreEmpty(): boolean {
    if (this.hasNestedOptions) {
      return (this._options as OptionGroup[])
        .map(optionGroup => optionGroup.options)
        .every(options => options && !options.length);
    }
    return false;
  }

  private selectChildrenCheckboxes(option: ValueAndLabel, isInitial?: boolean) {
    option.childrenValues?.forEach((childValue: RecipientItemData) => {
      const childOption = this.utilService
        .flattenOptions(this.options)
        .find(option => JSON.stringify(option.value) === JSON.stringify(childValue));

      if (childOption) {
        const isChildChecked = this.linkedSelectionValues.includes(childOption.value);
        if (!isChildChecked) {
          this.addSelectedLinkedSelectionOption(childOption, isInitial);
          this.selectChildrenCheckboxes(childOption, isInitial);
        }
        this.disabledMap[childOption.label] = true;
      }
    });
  }

  private setInitialLinkedSelectionValue() {
    this._value?.forEach((value: string) => {
      const option = this.utilService.flattenOptions(this.options).find(option => option.value === value);
      if (option) {
        this.addSelectedLinkedSelectionOption(option, true);
        this.selectChildrenCheckboxes(option, true);
      }
    });
  }

  deselectChildrenCheckboxes(option: ValueAndLabel) {
    option.childrenValues?.forEach(childValue => {
      const childOption = this.utilService.flattenOptions(this.options).find(option => option.value === childValue);

      if (childOption) {
        this.remove(childOption);
        this.deselectChildrenCheckboxes(childOption);
        setTimeout(() => {
          this.disabledMap[childOption.label] = false;
        }, 100);
      }
    });
  }

  onAddSuggestion(suggestion: ValueAndLabel): void {
    if (this.disabled) {
      return;
    }
    this.addSelectedOption(suggestion);
  }
}
