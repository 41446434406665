<nav>
  <button mat-button class="is-secondary" (click)="previousSlide()">
    <mat-icon svgIcon="chevron_left"></mat-icon><span>previous</span>
  </button>

  @if (tabGroup) {
    <p>
      <b>{{ tabGroup?.selectedIndex + 1 }}</b
      >/{{ tabGroup?._tabs.length }}
    </p>
  }

  <button mat-button class="is-secondary" (click)="nextSlide()">
    <span>next</span><mat-icon iconPositionEnd svgIcon="chevron_right"></mat-icon>
  </button>
</nav>
