import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IoService, PopupService } from '@morpho/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { mergeMap, Observable, of, tap } from 'rxjs';
import { OTPDevice } from '../../models/two-factor-authentication.models';
import { AuthenticationApiService } from '../../services/authentication-api.service';

enum TwoFactorAuthenticationState {
  Disable,
  Setup,
  Enable,
}

@Component({
  standalone: false,
  selector: 'om-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
})
export class TwoFactorAuthenticationComponent implements OnInit {
  readonly TwoFactorAuthenticationState = TwoFactorAuthenticationState;
  readonly otp: FormControl = new FormControl();
  readonly newDeviceControl: FormControl = new FormControl();

  isLoading = true;
  hasUnconfirmedDevices = false;
  newDevice: OTPDevice;
  devices: OTPDevice[] = [];
  state: TwoFactorAuthenticationState;

  constructor(
    private authenticationApiService: AuthenticationApiService,
    private ioService: IoService,
    private dialogService: DialogService,
    private popupService: PopupService,
  ) {}

  ngOnInit(): void {
    this.authenticationApiService.getDevices().subscribe(devices => {
      this.devices = devices;
      this.setInitState();
    });
  }

  private setInitState(): void {
    this.state = this.devices.some(device => device.confirmed)
      ? TwoFactorAuthenticationState.Enable
      : TwoFactorAuthenticationState.Disable;
    this.hasUnconfirmedDevices = this.devices.some(device => !device.confirmed);
    this.isLoading = false;
  }

  enableDevice(): void {
    if (this.otp.value.length) {
      this.isLoading = true;
      this.authenticationApiService.confirmDevice(this.newDevice.id, this.otp.value).subscribe(response => {
        this.otp.reset();
        if (response) {
          this.popupService.message('The device has been successfully setup');
          this.devices = this.devices.map(device =>
            device.id === this.newDevice.id ? { ...this.newDevice, confirmed: true } : device,
          );
          this.setInitState();
        } else {
          this.popupService.error('Failed to setup device, please try again');
          this.isLoading = false;
        }
      });
    }
  }

  onDeviceSetup(device: OTPDevice): void {
    (device.url ? of(device) : this.authenticationApiService.getActivationQRCode(device.id)).subscribe(newDevice => {
      this.newDevice = newDevice;
      this.state = TwoFactorAuthenticationState.Setup;
      this.isLoading = false;
    });
  }

  onCreateDevice(): void {
    const newDeviceName: string | undefined = this.newDeviceControl.value;
    if (newDeviceName?.length) {
      this.isLoading = true;
      this.authenticationApiService.createDevice(newDeviceName).subscribe(response => {
        if (!response) {
          return;
        }
        const newDevice: OTPDevice = { id: response.id, name: newDeviceName, confirmed: false, url: response.url };
        this.devices.push(newDevice);
        this.newDeviceControl.reset();
        this.onDeviceSetup(newDevice);
      });
    }
  }

  onCopyQRCode(): void {
    this.ioService.copy(this.newDevice.url ?? '');
  }

  onRemoveDevice(id: number): void {
    const removeApiCall: Observable<boolean> = this.authenticationApiService.removeDevice(id).pipe(
      tap(success => {
        if (success) {
          this.popupService.message('Successfully removed device');
          this.devices = this.devices.filter(device => device.id !== id);
          this.setInitState();
        } else {
          this.popupService.error('Failed to remove device, please try again');
          this.isLoading = false;
        }
      }),
    );
    const formConfig: BooleanPopupParams = {
      title: 'Remove Device',
      message: 'Are you sure you want to remove this device?',
      right_action: {
        text: 'Remove',
        class: 'is-warning',
      },
    };

    this.dialogService
      .booleanPopup(formConfig)
      .pipe(
        mergeMap(response => {
          if (response) {
            this.isLoading = true;
            return removeApiCall;
          }
          return of();
        }),
      )
      .subscribe();
  }
}
