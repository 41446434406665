import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { TrackByService } from '../../services/track-by.service';

export interface ActionMenuEvent {
  action: string;
  section?: string;
  data?: any;
}

export interface ActionMenuSection {
  name: string;
  label?: string;
  actions: ActionMenuItem[];
}

export interface ActionMenuSections {
  main: ActionMenuItem[];
  list: ActionMenuItem[];
}

export interface ActionMenuItem {
  name: string;
  label: string;
  icon?: string;
  class?: string;
  hidden?: boolean;
  url?: string;
  tooltip?: string;
  disabled?: boolean;
}

export interface ActionMenuConfig {
  icon?: string;
  openIcon?: string;
  badgeCount?: number;
  badgeResolved?: boolean;
  class?: 'badge-is-alert' | 'badge-is-warning';
  sections?: ActionMenuSection[];
  actions?: ActionMenuItem[];
  data?: any;
  forceDropdown?: boolean;
}

@Component({
  standalone: false,
  selector: 'om-action-menu',
  templateUrl: './action-menu.component.html',
})
export class ActionMenuComponent implements OnChanges {
  @ViewChild('actionMenu', { static: false }) actionMenu: MatMenu;
  @ViewChild('actionMenuTrigger', { static: false }) actionMenuTrigger: MatMenuTrigger;

  @Input() config: ActionMenuConfig;
  @Input() dataToEmit: any;

  @Output() selected: EventEmitter<ActionMenuEvent> = new EventEmitter();
  @Output() opened: EventEmitter<void> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();

  badgeText: string | undefined;
  badgeClass = '';

  private isOpen = false;

  constructor(public trackBy: TrackByService) {}

  onClick(action: string, section?: string) {
    this.selected.emit({ action, section, data: this.dataToEmit });
  }

  toggle() {
    setTimeout(() => {
      if (!this.actionMenuTrigger) {
        return;
      }

      if (this.isOpen) {
        this.actionMenuTrigger.closeMenu();
      } else {
        this.actionMenuTrigger.openMenu();
      }
    });
  }

  open() {
    setTimeout(() => {
      if (!this.actionMenuTrigger && this.isOpen) {
        return;
      }
      this.actionMenuTrigger.openMenu();
    });
  }

  onMenuOpened() {
    this.opened.emit();
    this.isOpen = true;
  }

  onMenuClosed() {
    this.closed.emit();
    this.isOpen = false;
  }

  ngOnChanges() {
    if (!this.config) {
      this.badgeText = undefined;
      this.badgeClass = '';
      return;
    }
    if (this.config.badgeResolved) {
      this.badgeText = '-';
      this.badgeClass = 'badge-is-resolved ';
    } else if (this.config.badgeCount) {
      this.badgeText = this.config.badgeCount.toString();
      this.badgeClass = '';
    } else {
      this.badgeText = undefined;
      this.badgeClass = '';
    }
  }
}
