import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  DIALOG_MAX_HEIGHT_CONSTRAINED,
  DIALOG_WIDTH_LARGE,
  DIALOG_WIDTH_MEDIUM,
  DIALOG_WIDTH_SMALL,
  DialogHeightMode,
  DialogSize,
  UNSET,
} from '@morpho/core';
import { DynamicFormModalCloseEvent, DynamicFormModalComponent, DynamicFormModalParams } from '@morpho/dynamic-form';
import { map, Observable, take, tap } from 'rxjs';
import { BooleanPopupComponent } from '../components/boolean-popup/boolean-popup.component';
import { CarouselModalOverlayRef } from '../components/carousel-modal/carousel-modal-ref';
import { CarouselModalComponent } from '../components/carousel-modal/carousel-modal.component';
import {
  CAROUSEL_MODAL_DATA,
  CAROUSEL_MODAL_SLIDE_COMPONENT,
} from '../components/carousel-modal/carousel-modal.tokens';
import { BooleanPopupParams, statusClassMap } from '../models/dialog.model';

interface CarouselModalDialogConfig {
  hasBackdrop?: boolean;
  backdropClass?: string;
  slides$?: Observable<any[]>;
  slideComponent?: any;
}
const CAROUSEL_MODAL_DEFAULT_CONFIG: CarouselModalDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-dark-backdrop',
};

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  openOverlayRef: CarouselModalOverlayRef;

  constructor(
    private matDialog: MatDialog,
    private injector: Injector,
    private overlay: Overlay,
  ) {}

  private openPopup(params: BooleanPopupParams, isModal = false): Observable<any> {
    const panelClass = this.getPanelClass(isModal, params.status);

    return this.openInModal(BooleanPopupComponent, {
      data: params,
      width: this.getDialogWidth(params?.dialog_size),
      maxHeight: this.getDialogMaxHeight(params?.dialog_height_mode),
      ...(panelClass && { panelClass }),
      ...(params?.dialog_height && { height: params.dialog_height }),
    });
  }

  simplePopup(params: BooleanPopupParams): Observable<any> {
    return this.openPopup({ ...params, type: 'simple_modal' }, true);
  }

  booleanPopup(params: BooleanPopupParams): Observable<any> {
    return this.openPopup({ ...params, type: 'boolean_modal' });
  }

  openInModal(component: ComponentType<any>, config: MatDialogConfig): Observable<any> {
    return this.matDialog.open(component, config).afterClosed();
  }

  dynamicFormModal(params: DynamicFormModalParams, config?: MatDialogConfig): Observable<DynamicFormModalCloseEvent> {
    let dialogHeight: string;
    const panelClass = this.getPanelClass(true, params.status);

    const dialogConfig: MatDialogConfig = {
      data: params,
      width: this.getDialogWidth(params?.dialog_size),
      maxHeight: this.getDialogMaxHeight(params?.dialog_height_mode),
      ...(panelClass && { panelClass }),
      ...config,
    };

    const dialogRef = this.matDialog.open(DynamicFormModalComponent, dialogConfig);
    dialogRef
      .beforeClosed()
      .pipe(
        take(1),
        tap(() => {
          const modalElement = document.querySelector('.mat-mdc-dialog-container');
          if (modalElement) {
            dialogHeight = `${modalElement.clientHeight}px`;
          }
        }),
      )
      .subscribe();

    return dialogRef
      .afterClosed()
      .pipe(map(response => (response != undefined ? { ...response, dialogHeight } : response)));
  }

  openModalCarousel(config: CarouselModalDialogConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...CAROUSEL_MODAL_DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new CarouselModalOverlayRef(overlayRef);

    this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    this.openOverlayRef = dialogRef;

    return dialogRef;
  }

  private createOverlay(config: CarouselModalDialogConfig) {
    // Returns an OverlayConfig
    const overlayConfig = this.getOverlayConfig(config);
    // Returns an OverlayRef
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: CarouselModalDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }

  private attachDialogContainer(
    overlayRef: OverlayRef,
    config: CarouselModalDialogConfig,
    dialogRef: CarouselModalOverlayRef,
  ) {
    const injector = this.createInjector(config, dialogRef);

    const containerPortal = new ComponentPortal(CarouselModalComponent, null, injector);
    const containerRef: ComponentRef<CarouselModalComponent> = overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(config: CarouselModalDialogConfig, dialogRef: CarouselModalOverlayRef): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        { provide: CarouselModalOverlayRef, useValue: dialogRef },
        { provide: CAROUSEL_MODAL_DATA, useValue: config.slides$ },
        { provide: CAROUSEL_MODAL_SLIDE_COMPONENT, useValue: config.slideComponent },
      ],
    });
  }

  private getDialogWidth(dialogSize: DialogSize | undefined) {
    switch (dialogSize) {
      case DialogSize.Large:
        return DIALOG_WIDTH_LARGE;
      case DialogSize.Medium:
        return DIALOG_WIDTH_MEDIUM;
      case DialogSize.Small:
      default:
        return DIALOG_WIDTH_SMALL;
    }
  }

  private getDialogMaxHeight(dialogHeightMode: DialogHeightMode | undefined) {
    switch (dialogHeightMode) {
      case DialogHeightMode.Constrained:
        return DIALOG_MAX_HEIGHT_CONSTRAINED;
      default:
        return UNSET;
    }
  }

  private getPanelClass(isModal?: boolean, status?: string): string | string[] | undefined {
    const modalClass = isModal ? 'is-modal' : '';
    const statusClass = status ? statusClassMap[status] : '';

    const classes = [modalClass, statusClass].filter(className => className !== '');

    return classes.length > 1 ? classes : classes[0];
  }
}
