import { BasisType, QuoteType, SecondaryBondsGrouping, ValueAndLabel } from '@morpho/core';
import { createAction, props } from '@ngrx/store';
import { LegendSelection } from '@shared-echarts/models';
import { PricingBreakDownType } from '../../constants/breakdown';
import { GovieBond, SecondaryBond } from '../../models/comparable.model';
import { PricingInboxGroup, PricingInboxRequest } from '../../models/pricing-inbox.model';
import {
  AdditionalCurve,
  ComparablesInformation,
  CurveLevel,
  PricingRequestParams,
  PricingRequestResponse,
  PricingType,
  UpdateSecondaryLevelsParams,
} from '../../models/pricing-request.model';
import { SecondaryBondFilters, SecondaryBondsBody } from '../../models/secondaries.model';
import { RowPricingParams } from '../../services/pricing-completion.service';
import {
  Maturities,
  NewIssueRowDef,
  PricingCompletionPageSettings,
  PricingTabDataMap,
  SetSecondaryBondRegressionPayload,
  SharedTabDataMap,
  SubmitPricingDetails,
} from './pricing-completion.model';

const PRICING_COMPLETION = '[Pricing Completion]';
const ON_VISIT_PRICING_TAB = `${PRICING_COMPLETION} visit pricing tab`;

const ADD_PROCESS_TO_LOADING_STACK = `${PRICING_COMPLETION} Add process to loading stack`;
const REMOVE_PROCESS_FROM_LOADING_STACK = `${PRICING_COMPLETION} Remove process from loading stack`;

const TOGGLE_EXPORT_LOADING_STATUS = `${PRICING_COMPLETION} Toggle Export Loading Status`;
const UPDATE_PRICING_DATA_FOR_TAB = `${PRICING_COMPLETION} Set New Pricing Data For Tab`;

const GET_PRICING_INBOX_LIST = `${PRICING_COMPLETION} Get Pricing Requests`;
const GET_PRICING_INBOX_GROUPS = `${PRICING_COMPLETION} Get Pricing Requests`;
const GET_PRICING_INBOX_GROUPS_SUCCESS = `${GET_PRICING_INBOX_GROUPS} SUCCESS`;
const ADD_PRICING_INBOX_GROUP = `${PRICING_COMPLETION} Add Group`;
const ADD_PRICING_INBOX_GROUP_SUCCESS = `${ADD_PRICING_INBOX_GROUP} SUCCESS`;
const EDIT_PRICING_INBOX_GROUP = `${PRICING_COMPLETION} Edit Group`;
const EDIT_PRICING_INBOX_GROUP_SUCCESS = `${EDIT_PRICING_INBOX_GROUP} SUCCESS`;
const DELETE_PRICING_INBOX_GROUP = `${PRICING_COMPLETION} Delete Group`;
const DELETE_PRICING_INBOX_GROUP_SUCCESS = `${DELETE_PRICING_INBOX_GROUP} SUCCESS`;

const SET_SELECTED_PRICING_REQUEST = `${PRICING_COMPLETION} Set Selected Pricing Request`;

const SET_SELECTED_TAB = `${PRICING_COMPLETION} Set Selected Tab`;
const SET_TABS = `${PRICING_COMPLETION} Set Tabs`;
const ADD_TAB = `${PRICING_COMPLETION} Add New Tab`;

const TOGGLE_GRAPH_VISIBILITY = `${PRICING_COMPLETION} Toggle Graph Visibility`;
const REDRAW_GRAPH = `${PRICING_COMPLETION} Redraw Graph`;
const REFRESH_GRID = `${PRICING_COMPLETION} Refresh Grid`;

const SET_PRICING_TAB_DATA = `${PRICING_COMPLETION} Set Pricing Tab Data`;
const GET_COMBINED_FILTERS = `${PRICING_COMPLETION} Get Combined Filters`;
const SET_COMBINED_FILTERS = `${PRICING_COMPLETION} Set Combined Filters`;

const GET_HISTORICAL_PRICING_REQUEST_DATA = `${PRICING_COMPLETION} Get Historical Pricing Request Data`;
const GET_HISTORICAL_PRICING_REQUEST_DATA_SUCCESS = `${GET_HISTORICAL_PRICING_REQUEST_DATA} SUCCESS`;

const GET_HISTORICAL_PRICING_LIST = `${PRICING_COMPLETION} Get Historical Pricing List`;
const GET_HISTORICAL_PRICING_LIST_SUCCESS = `${GET_HISTORICAL_PRICING_LIST} SUCCESS`;

const SET_PRICING_BASIS_FOR_TAB = `${PRICING_COMPLETION} Set Pricing Basis For Tab`;
const SET_PRICING_BASIS_FOR_TAB_SUCCESS = `${SET_PRICING_BASIS_FOR_TAB} Success`;

const SET_OVERVIEW_SWAP_BASIS = `${PRICING_COMPLETION} Set Overview Swap Basis`;
const SET_PRICING_REQUEST_TYPE = `${PRICING_COMPLETION} Set Pricing Request Type`;

const REMOVE_PRICING_REQUESTS = `${PRICING_COMPLETION} Remove Pricing Requests`;
const REMOVE_PRICING_REQUESTS_SUCCESS = `${REMOVE_PRICING_REQUESTS} SUCCESS`;

const ADD_PRICING_TABS = `${PRICING_COMPLETION} Add Pricing Tabs`;

const RESET_PRICING = `${PRICING_COMPLETION} Reset Pricing`;
const DISCARD_PRICING_CHANGES = `${PRICING_COMPLETION} Discard Pricing Changes`;

const SET_QUOTE_TYPE = `${PRICING_COMPLETION} Set Quote Type`;

const SET_PRICING_BREAKDOWN = `${PRICING_COMPLETION} Set Breakdown Type`;
const SET_PRICING_BREAKDOWN_SUCCESS = `${SET_PRICING_BREAKDOWN} Success`;

const SET_SHOW_SWAP_REFERENCES = `${PRICING_COMPLETION} Set Show Swap Reference Curves`;
const SET_SHOW_LATEST_HISTORICAL = `${PRICING_COMPLETION} Set Show Latest Historical`;

const SET_COMPARABLES = `${PRICING_COMPLETION} Set Comparables`;
const SET_SECONDARY_BONDS_FILTER = `${PRICING_COMPLETION} Set Secondary Bonds Filter`;
const GET_SECONDARY_BONDS = `${PRICING_COMPLETION} Get Secondary Bonds`;
const GET_SECONDARY_BONDS_SUCCESS = `${GET_SECONDARY_BONDS} Success`;
const PRICE_SECONDARY_BONDS = `${PRICING_COMPLETION} Price Secondary Bonds`;
const PRICE_SECONDARY_BONDS_SUCCESS = `${PRICE_SECONDARY_BONDS} Success`;

const GET_SECONDARY_BONDS_PRICED = `${GET_SECONDARY_BONDS} Priced`;
const GET_SECONDARY_BONDS_PRICED_SUCCESS = `${GET_SECONDARY_BONDS_PRICED} Success`;

const SET_SECONDARY_BONDS_REGRESSION = `${PRICING_COMPLETION} Set Secondary Bonds Regression`;

const ADD_SECONDARY_BONDS = `${PRICING_COMPLETION} Add Secondary Bonds`;

const SET_SECONDARY_BONDS_OVERRIDE = `${PRICING_COMPLETION} Set Secondary Bonds Override`;

const SET_SECONDARY_BONDS_GROUPING = `${PRICING_COMPLETION} Set Secondary Bonds Grouping`;

const GET_GOVIE_BONDS = `${PRICING_COMPLETION} Get Govie Bonds`;
const GET_GOVIE_BONDS_SUCCESS = `${GET_GOVIE_BONDS} Success`;

const GET_HISTORICAL_SECONDARY_BONDS = `${PRICING_COMPLETION} Get Historical Secondary Bonds`;
const GET_HISTORICAL_SECONDARY_BONDS_PRICED = `${GET_HISTORICAL_SECONDARY_BONDS} Priced`;
const GET_HISTORICAL_SECONDARY_BONDS_PRICED_SUCCESS = `${GET_HISTORICAL_SECONDARY_BONDS_PRICED} Success`;

const GET_PRICED_REQUEST = `${PRICING_COMPLETION} Get Priced Request`;
const GET_PRICED_REQUEST_SUCCESS = `${GET_PRICED_REQUEST} Success`;
const SET_PRICED_REQUEST_DATA = `${PRICING_COMPLETION} Set Priced Request Data`;

const SET_OPEN_PRICED_REQUEST_DATA = `${PRICING_COMPLETION} Set Open Pricing Request Data`;

const UPDATE_SELECTED_SWAPS = `${PRICING_COMPLETION} Update Selected Swaps`;

const UPDATE_CUSTOM_CURVES = `${PRICING_COMPLETION} Update Custom Curves`;

const UPDATE_SELECTED_HISTORICAL_DATES = `${PRICING_COMPLETION} Update Selected Historicals`;

const CREATE_PRICING = `${PRICING_COMPLETION} Create pricing`;
const SAVE_PRICING = `${PRICING_COMPLETION} Save pricing`;
const SAVE_PRICING_SUCCESS = `${SAVE_PRICING} Success`;
const SUBMIT_PRICING = `${PRICING_COMPLETION} Submit pricing`;
const UPDATE_SAVING_STATUS = `${PRICING_COMPLETION} Update saving status`;

const REPRICE_MATURITIES = `${PRICING_COMPLETION} Reprice Maturities`;
const REPRICE_MATURITIES_SUCCESS = `${REPRICE_MATURITIES} Success`;
const REPRICE_MATURITIES_FAIL = `${REPRICE_MATURITIES} Fail`;

const GET_PAGE_SETTINGS = `[Syndicate Pricer] Get Page settings`;
const SET_PAGE_SETTINGS = `[Syndicate Pricer] Set Page settings`;

const SAVE_PAGE_SETTINGS = `[Syndicate Pricer] Save Page settings`;
const SAVE_PAGE_SETTINGS_SUCCESS = `${SAVE_PAGE_SETTINGS} Success`;

const SET_ISSUING_ENTITIES = `[Syndicate Pricer] Set Issuing Entities`;

const SET_SELECTED_BONDS = `${PRICING_COMPLETION} Set bonds to display`;
const SET_SELECTED_BONDS_SUCCESS = `${SET_SELECTED_BONDS} Success`;

const UPDATE_INCLUDED_ISINS = `${PRICING_COMPLETION} Update included isins`;

const SET_LEGEND_SELECTION = `${PRICING_COMPLETION} Set legend selection`;

const SET_SECONDARY_BONDS_DATES = `${PRICING_COMPLETION} Set secondary bonds dates`;
const CLEAR_SECONDARY_BONDS_PRICING_DATA = `${PRICING_COMPLETION} Clear secondary bonds data`;

const UPDATE_TAB_MATURITIES = `${PRICING_COMPLETION} Update Tab Maturities`;

const CLEAR_INBOX = `${PRICING_COMPLETION} Clear pricing inbox`;

const UPDATE_SECONDARY_BOND_LEVELS = `${PRICING_COMPLETION} Update Secondary Levels`;

const UPDATE_ADDITIONAL_CURVES = `${PRICING_COMPLETION} Update Additional Curves`;

export type RemovePricingRequestsParams = {
  requests: (PricingRequestResponse | PricingInboxRequest)[];
  label: string;
  tabName?: string;
};

export const PricingCompletionEffect = {
  ON_VISIT_PRICING_TAB,

  SET_PRICING_TAB_DATA,
  SET_SELECTED_TAB,
  SET_PRICING_BASIS_FOR_TAB,
  SET_PRICING_BASIS_FOR_TAB_SUCCESS,
  SET_OVERVIEW_SWAP_BASIS,
  GET_PRICING_INBOX_LIST,
  ADD_PRICING_INBOX_GROUP,
  EDIT_PRICING_INBOX_GROUP,
  DELETE_PRICING_INBOX_GROUP,

  REMOVE_PRICING_REQUESTS,
  REMOVE_PRICING_REQUESTS_SUCCESS,

  SET_QUOTE_TYPE,
  GET_SECONDARY_BONDS,
  GET_SECONDARY_BONDS_SUCCESS,
  PRICE_SECONDARY_BONDS,
  PRICE_SECONDARY_BONDS_SUCCESS,
  GET_SECONDARY_BONDS_PRICED, // todo remove
  GET_SECONDARY_BONDS_PRICED_SUCCESS,
  SET_SECONDARY_BONDS_REGRESSION,
  GET_COMBINED_FILTERS,
  SET_COMBINED_FILTERS,
  SET_SECONDARY_BONDS_DATES,
  SET_SECONDARY_BONDS_OVERRIDE,

  CREATE_PRICING,
  GET_PRICED_REQUEST,
  SET_PRICED_REQUEST_DATA,
  SET_OPEN_PRICED_REQUEST_DATA,
  GET_HISTORICAL_PRICING_REQUEST_DATA,
  GET_HISTORICAL_PRICING_REQUEST_DATA_SUCCESS,
  GET_HISTORICAL_PRICING_LIST,
  GET_HISTORICAL_PRICING_LIST_SUCCESS,
  GET_GOVIE_BONDS,
  GET_GOVIE_BONDS_SUCCESS,
  GET_HISTORICAL_SECONDARY_BONDS,
  GET_HISTORICAL_SECONDARY_BONDS_PRICED,
  GET_HISTORICAL_SECONDARY_BONDS_PRICED_SUCCESS,
  SET_PRICING_BREAKDOWN,
  SET_PRICING_BREAKDOWN_SUCCESS,
  SET_SHOW_SWAP_REFERENCES,
  SAVE_PRICING,
  SAVE_PRICING_SUCCESS,
  SUBMIT_PRICING,
  REPRICE_MATURITIES,
  REPRICE_MATURITIES_SUCCESS,
  UPDATE_TAB_MATURITIES,
  GET_PAGE_SETTINGS,
  SAVE_PAGE_SETTINGS,
  SET_SELECTED_BONDS,
  SET_SELECTED_BONDS_SUCCESS,
  UPDATE_INCLUDED_ISINS,
  CLEAR_SECONDARY_BONDS_PRICING_DATA,

  UPDATE_PRICING_DATA_FOR_TAB,
  UPDATE_CUSTOM_CURVES,
  UPDATE_SELECTED_SWAPS,
  UPDATE_SELECTED_HISTORICAL_DATES,
  SET_SHOW_LATEST_HISTORICAL,
  UPDATE_SECONDARY_BOND_LEVELS,
  UPDATE_ADDITIONAL_CURVES,
};

export const PricingCompletionAction = {
  onVisitPricingTab: createAction(ON_VISIT_PRICING_TAB),

  addProcessToLoadingStack: createAction(ADD_PROCESS_TO_LOADING_STACK),
  removeProcessFromLoadingStack: createAction(REMOVE_PROCESS_FROM_LOADING_STACK),
  setTabs: createAction(SET_TABS, props<{ params: { tabs: string[] } }>()),
  setNewTabs: createAction(ADD_TAB, props<{ params: { tabs: string[] } }>()),
  setSelectedTab: createAction(SET_SELECTED_TAB, props<{ params: string }>()),
  updatePricingTabData: createAction(
    SET_PRICING_TAB_DATA,
    props<{ params: { pricingTabDataMap: PricingTabDataMap; sharedTabDataMap: SharedTabDataMap } }>(),
  ),

  toggleGraphVisibility: createAction(TOGGLE_GRAPH_VISIBILITY),

  toggleExportLoadingStatus: createAction(TOGGLE_EXPORT_LOADING_STATUS),

  resetPricing: createAction(RESET_PRICING),

  discardPricingChanges: createAction(DISCARD_PRICING_CHANGES),

  createPricing: createAction(
    CREATE_PRICING,
    props<{ params: { pricingRequests: Partial<PricingRequestResponse>[] } }>(),
  ),

  savePricing: createAction(SAVE_PRICING),
  savePricingSuccess: createAction(
    SAVE_PRICING_SUCCESS,
    props<{ params: { savedPricingRequestsMap: Record<string, PricingRequestResponse[]> } }>(),
  ),

  submitPricing: createAction(SUBMIT_PRICING, props<{ params: SubmitPricingDetails }>()),

  updateSavingStatus: createAction(UPDATE_SAVING_STATUS, props<{ params: { isSaving?: boolean } }>()),

  getPageSettings: createAction(GET_PAGE_SETTINGS),
  setPageSettings: createAction(
    SET_PAGE_SETTINGS,
    props<{ params: { id?: number; pageSettings: PricingCompletionPageSettings } }>(),
  ),

  savePageSettings: createAction(
    SAVE_PAGE_SETTINGS,
    props<{ params: { pageSettings: PricingCompletionPageSettings } }>(),
  ),
  savePageSettingsSuccess: createAction(SAVE_PAGE_SETTINGS_SUCCESS, props<{ params: { savedViewId: number } }>()),

  setIssuingEntities: createAction(
    SET_ISSUING_ENTITIES,
    props<{ params: { issuingEntities: Record<number, string> } }>(),
  ),

  /***********************************************************************************************
   *                                     PRICING INBOX                                           *
   ***********************************************************************************************/

  getPricingInboxGroups: createAction(GET_PRICING_INBOX_GROUPS),
  getPricingInboxGroupsSuccess: createAction(
    GET_PRICING_INBOX_GROUPS_SUCCESS,
    props<{ params: { pricingInboxGroups: PricingInboxGroup[] } }>(),
  ),

  addPricingInboxGroup: createAction(ADD_PRICING_INBOX_GROUP),
  addPricingInboxGroupSuccess: createAction(ADD_PRICING_INBOX_GROUP_SUCCESS),

  editPricingInboxGroup: createAction(EDIT_PRICING_INBOX_GROUP, props<{ params: { groupId: number } }>()),
  editPricingInboxGroupSuccess: createAction(EDIT_PRICING_INBOX_GROUP_SUCCESS),

  deletePricingInboxGroup: createAction(DELETE_PRICING_INBOX_GROUP, props<{ params: { groupId: number } }>()),
  deletePricingInboxGroupSuccess: createAction(DELETE_PRICING_INBOX_GROUP_SUCCESS),

  setSelectedPricingRequest: createAction(
    SET_SELECTED_PRICING_REQUEST,
    props<{ params: { pricingRequest: PricingRequestResponse | Partial<PricingRequestResponse> } }>(),
  ),

  setOpenPricedRequestData: createAction(SET_OPEN_PRICED_REQUEST_DATA),

  clearPricingInbox: createAction(CLEAR_INBOX),

  /***********************************************************************************************
   *                                       PRICING TAB                                           *
   ***********************************************************************************************/

  setPricingType: createAction(SET_PRICING_REQUEST_TYPE, props<{ params: { pricingType: PricingType } }>()),
  redrawGraph: createAction(REDRAW_GRAPH),
  refreshGrid: createAction(REFRESH_GRID),

  getHistoricalPricingRequestData: createAction(
    GET_HISTORICAL_PRICING_REQUEST_DATA,
    props<{ params: { tab: string; pricingRequestParams: PricingRequestParams } }>(),
  ),
  getHistoricalPricingRequestDataSuccess: createAction(
    GET_HISTORICAL_PRICING_REQUEST_DATA_SUCCESS,
    props<{
      params: { tab: string; historicalPricingRequestsData: Record<string, Partial<PricingRequestResponse>> };
    }>(),
  ),

  getHistoricalPricingList: createAction(
    GET_HISTORICAL_PRICING_LIST,
    props<{ params: { tab: string; issuingEntityId: string | number; pricingRequestId: number } }>(),
  ),
  getHistoricalPricingListSuccess: createAction(
    GET_HISTORICAL_PRICING_LIST_SUCCESS,
    props<{ params: { tab: string; issuingEntityId: string; historicalDates: string[] } }>(),
  ),

  getHistoricalSecondaryBonds: createAction(
    GET_HISTORICAL_SECONDARY_BONDS,
    props<{
      params: {
        tab: string;
        historicalSecondaryBondsDates: string[];
        overrideSecondaries?: boolean;
      };
    }>(),
  ),
  getHistoricalSecondaryBondsPriced: createAction(
    GET_HISTORICAL_SECONDARY_BONDS_PRICED,
    props<{
      params: {
        tab: string;
        historicalSecondaryBondsDates: string[];
        historicalSecondaryBonds: SecondaryBond[][];
        overrideSecondaries?: boolean;
      };
    }>(),
  ),
  getHistoricalSecondaryBondsPricedSuccess: createAction(
    GET_HISTORICAL_SECONDARY_BONDS_PRICED_SUCCESS,
    props<{
      params: {
        tab: string;
        secondaryPricedKeys: Record<string, boolean>;
      };
    }>(),
  ),

  getPricedRequest: createAction(GET_PRICED_REQUEST, props<{ params: { issuingEntityIds: number[] } }>()),
  getPricedRequestSuccess: createAction(GET_PRICED_REQUEST_SUCCESS),

  setPricedRequestData: createAction(SET_PRICED_REQUEST_DATA),

  setPricingBasisForTab: createAction(
    SET_PRICING_BASIS_FOR_TAB,
    props<{ params: { tab: string; pricingBasis: string } }>(),
  ),

  setPricingBasisForTabSuccess: createAction(
    SET_PRICING_BASIS_FOR_TAB_SUCCESS,
    props<{
      params: {
        tab: string;
        pricingBasis: string;
        basisType: BasisType;
        initialBenchmarkSelection?: Maturities;
        benchmarkYields?: Maturities;
      };
    }>(),
  ),

  setOverviewSwapBasis: createAction(SET_OVERVIEW_SWAP_BASIS, props<{ params: { swapBasis: string } }>()),

  addPricingTabs: createAction(ADD_PRICING_TABS, props<{ params: { pricingTabs: ValueAndLabel[] } }>()),
  removePricingRequests: createAction(REMOVE_PRICING_REQUESTS, props<{ params: RemovePricingRequestsParams }>()),
  removePricingRequestsSuccess: createAction(
    REMOVE_PRICING_REQUESTS_SUCCESS,
    props<{ params: { tabName?: string; requestIds: number[] } }>(),
  ),

  updatePricingDataForTab: createAction(
    UPDATE_PRICING_DATA_FOR_TAB,
    props<{
      params: {
        tab: string;
        data: NewIssueRowDef;
      };
    }>(),
  ),

  setQuoteType: createAction(SET_QUOTE_TYPE, props<{ params: { tabName: string; quoteType: QuoteType } }>()),

  setPricingBreakdown: createAction(
    SET_PRICING_BREAKDOWN,
    props<{ params: { tabName: string; pricingBreakdown: PricingBreakDownType } }>(),
  ),

  setComparables: createAction(
    SET_COMPARABLES,
    props<{
      params: ComparablesInformation;
    }>(),
  ),

  setPricingBreakdownSuccess: createAction(
    SET_PRICING_BREAKDOWN_SUCCESS,
    props<{ params: { tabName: string; pricingBreakdown: PricingBreakDownType; levels: CurveLevel[] } }>(),
  ),

  setShowSwapReferenceCurves: createAction(
    SET_SHOW_SWAP_REFERENCES,
    props<{ params: { tabName: string; showSwapReferenceCurves: boolean } }>(),
  ),

  setShowLatestHistorical: createAction(
    SET_SHOW_LATEST_HISTORICAL,
    props<{ params: { tab: string; showLatestHistorical: boolean } }>(),
  ),

  getSecondaryBonds: createAction(
    GET_SECONDARY_BONDS,
    props<{
      params: {
        tabName: string;
        individualComparables?: ComparablesInformation;
        initialiseBondsSelected?: boolean;
      };
    }>(),
  ),
  getSecondaryBondsSuccess: createAction(
    GET_SECONDARY_BONDS_SUCCESS,
    props<{ params: { tabName: string; bonds: SecondaryBond[]; initialiseBondsSelected?: boolean } }>(),
  ),

  priceSecondaryBonds: createAction(
    PRICE_SECONDARY_BONDS,
    props<{
      params: { tabName: string; bonds?: SecondaryBond[]; repriceBonds?: boolean; pricePricingBasis?: boolean };
    }>(),
  ),

  getCombinedFilters: createAction(GET_COMBINED_FILTERS, props<{ params: { tabName: string } }>()),

  setCombinedFilters: createAction(
    SET_COMBINED_FILTERS,
    props<{ params: { tabName: string; filters: SecondaryBondFilters } }>(),
  ),

  setSecondaryBondsFilter: createAction(
    SET_SECONDARY_BONDS_FILTER,
    props<{ params: { tabName: string; filter: Partial<SecondaryBondsBody> } }>(),
  ),

  getSecondaryBondsPriced: createAction(
    GET_SECONDARY_BONDS_PRICED,
    props<{ params: { tab: string; bonds: SecondaryBond[] } }>(),
  ),
  getSecondaryBondsPricedSuccess: createAction(
    GET_SECONDARY_BONDS_PRICED_SUCCESS,
    props<{ params: { tab: string; bonds: SecondaryBond[] } }>(),
  ),

  setSecondaryBondsRegression: createAction(
    SET_SECONDARY_BONDS_REGRESSION,
    props<{
      params: SetSecondaryBondRegressionPayload;
    }>(),
  ),

  addSecondaryBonds: createAction(ADD_SECONDARY_BONDS, props<{ params: { bonds: SecondaryBond[] } }>()),

  getGovieBonds: createAction(GET_GOVIE_BONDS, props<{ params: { pricingBasis: string } }>()),
  getGovieBondsSuccess: createAction(
    GET_GOVIE_BONDS_SUCCESS,
    props<{
      params: {
        pricingBasis: string;
        bonds: GovieBond[];
      };
    }>(),
  ),

  setSecondaryBondsGrouping: createAction(
    SET_SECONDARY_BONDS_GROUPING,
    props<{ params: { tabName: string; secondaryBondsGrouping: SecondaryBondsGrouping } }>(),
  ),

  repriceMaturities: createAction(
    REPRICE_MATURITIES,
    props<{
      params: {
        tab: string;
        issuingEntityId: string;
        maturities?: string[];
        rowsToPrice?: RowPricingParams[];
      };
    }>(),
  ),
  repriceMaturitiesSuccess: createAction(
    REPRICE_MATURITIES_SUCCESS,
    props<{
      params: { tab: string; maturities: Maturities; rowId: string; hasGoviePricingBasis?: boolean };
    }>(),
  ),
  repriceMaturitiesFail: createAction(REPRICE_MATURITIES_FAIL),

  setSelectedBonds: createAction(SET_SELECTED_BONDS, props<{ params: { tab: string; selectedBonds: Set<string> } }>()),
  setSelectedBondsSuccess: createAction(SET_SELECTED_BONDS_SUCCESS, props<{ params: UpdateSecondaryLevelsParams }>()),

  updateIncludedIsins: createAction(
    UPDATE_INCLUDED_ISINS,
    props<{ params: { tab: string; includedIsins: Set<string> } }>(),
  ),

  setLegendSelection: createAction(
    SET_LEGEND_SELECTION,
    props<{ params: { tab: string; selection: LegendSelection } }>(),
  ),

  updateTabMaturities: createAction(UPDATE_TAB_MATURITIES, props<{ params: { tab: string; maturities: string[] } }>()),

  updateSelectedSwaps: createAction(
    UPDATE_SELECTED_SWAPS,
    props<{ params: { tab: string; swapBases: string[]; showSwapReferenceCurves?: boolean } }>(),
  ),

  updateAdditionalCurves: createAction(
    UPDATE_ADDITIONAL_CURVES,
    props<{
      params: {
        tab: string;
        additionalCurveMap: Record<string, AdditionalCurve[]>;
        issuingEntityIds?: string[];
        addOntoExisting?: boolean;
      };
    }>(),
  ),

  updateCustomCurves: createAction(
    UPDATE_CUSTOM_CURVES,
    props<{ params: { tab: string; customRowNames: string[] } }>(),
  ),

  updateSelectedHistoricalDates: createAction(
    UPDATE_SELECTED_HISTORICAL_DATES,
    props<{ params: { tab: string; selectedDates: string[]; issuingEntityIds?: string[] } }>(),
  ),

  updateSecondaryBondDates: createAction(
    SET_SECONDARY_BONDS_DATES,
    props<{ params: { tab: string; selectedDates: string[] } }>(),
  ),

  clearSecondaryBondsData: createAction(CLEAR_SECONDARY_BONDS_PRICING_DATA, props<{ params?: { tabName: string } }>()),

  updateSecondaryLevels: createAction(
    UPDATE_SECONDARY_BOND_LEVELS,
    props<{
      params: UpdateSecondaryLevelsParams;
    }>(),
  ),

  setSecondaryBondsOverride: createAction(
    SET_SECONDARY_BONDS_OVERRIDE,
    props<{ params: UpdateSecondaryLevelsParams }>(),
  ),
};
