import { DealerOverview } from '../../../models/dealer';
import { RatingsMap } from '../ratings/state/ratings.model';

export interface IssuerMetadata {
  grid_comment?: string;
  enabled_tenors: string[];
}

export enum IssuerType {
  Corporate = 'corporate',
  FIG = 'fig',
  SSA = 'ssa',
}

export interface PartialIssuer {
  company_name: string;
  country: string;
  entities: IssuerEntity[];
  id: number;
  issuer_type: IssuerType;
  legal_name: string;
  sector: string;
  short_name: string;
}

export interface IssuerEntity {
  id: number;
  name: string;
  square_logo?: string;
}

export interface IssuingEntity {
  id: number;
  name: string;
  lei?: string;
  country?: string;
  issuer?: any;
}

export interface IssuerEntitiesMap {
  [id: number]: { company_name: string; entities: IssuerEntity[] };
}

export interface IssuerOverview {
  id: number;
  company_name: string;
  short_name: string;
  logo?: string;
  square_logo: string;
  website?: string;
}

export interface IssuerOnIssuerList extends RatingsMap {
  access_request:
    | {
        requested_by: {
          id: number;
          first_name: string;
          last_name: string;
        };
        timestamp: string;
      }
    | boolean
    | null;
  blackin: string | null;
  blackout: string | null;
  blackout_comments: string[];
  bloomberg_ticker: string;
  can_announce?: boolean;
  collateral_currency: string;
  country: string;
  currencies: string[];
  entities: string[];
  funding_bases: string[];
  has_access: boolean;
  in_blackout: boolean;
  is_custom_issuer: boolean;
  is_favourite: boolean;
  is_hidden: boolean;
  is_priority: boolean;
  issuance_themes: string[];
  issuer: IssuerOverview;
  issuer_type: IssuerType;
  kyc_info: {
    id: number;
    created_by: {
      id: number;
      first_name: string;
      last_name: string;
    };
    bank: DealerOverview;
    issuer: IssuerOverview;
    timestamp: string;
  } | null;
  programmes: string[];
  sector: string;
  seniorities: string[];
  structures: string[];
  trade_status: string;
  custom_data: { [key: string]: any };
  sources: { [key: string]: { [key: string]: string | string[] } };
  veritas_issuing_entity_ids: number[];
}

export interface IssuerComment {
  author: {
    first_name: string;
    last_name: string;
    id: number;
  };
  can_delete: boolean;
  comments: [];
  content: string;
  created: string;
  id: number;
  is_pinned: boolean;
  is_public: boolean;
  target_issuer: { id: number; company_name: string; url: string };
}

export interface SavedIssuerList {
  id: number;
  issuers: any[];
  name: 'favourites';
}
