<h1>
  <img class="is-transparent" alt="" [src]="logoUrl" />
</h1>
<div>
  <h2>Account not active yet</h2>
  <span> Your organisation's Origin account is not active yet.</span>
  <span>
    Please contact <a href="mailto:support@originmarkets.com" class="is-accent">support&#64;originmarkets.com</a>
  </span>
  <div>
    <button mat-button (click)="goBackToLoginPage()">Go back to login</button>
    <button mat-button (click)="tryToLogin()">Try again</button>
  </div>
</div>
