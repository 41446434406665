import { Component, forwardRef, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { TableInputConfig } from '../../models/form.model';
import { BasicInputContainerComponent } from '../basic-input-container/basic-input-container.component';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

type InputType = TableInputConfig['type'];

type ValueType = string | number | moment.Moment | undefined;

export interface TableValueAndLabel {
  value: ValueType;
  label: string;
}

/*
  If labels are not provided, then size must be provided with an optional labelPrefix.

  Labels correspond to the values_ based by index, irrespective of the (value of the) new labels.

  Todo: integrate om-date-input for when input type is date.
*/

@Component({
  standalone: false,
  selector: 'om-table-input',
  templateUrl: './table-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => TableInputComponent)),
})
export class TableInputComponent extends CustomFormFieldControlComponent implements OnInit, OnChanges {
  readonly context = this;
  tableData: TableValueAndLabel[];
  tableValue: any[];
  columnsToDisplay = ['labels', 'inputs'];
  labelHeader = '';
  inputHeader = '';

  @Input() type: InputType;
  @Input() options: ValueAndLabel[];

  @Input() labels: string[];
  @Input() labelPrefix: string;
  @Input() size: number;

  @ViewChildren(BasicInputContainerComponent) basicInputContainers: QueryList<BasicInputContainerComponent>;

  @Input()
  get value(): any[] | null {
    return this._value;
  }
  set value(value: any[] | null) {
    this._value = value ?? null;
    if (value) {
      this.tableValue = value;
    }
    this.setTableData();
    // emitChanges is handled in setTableData
  }
  _value: any[] | null;

  private localValueHistory: any = {};

  onInputChange(change: ValueType, index: number): void {
    if (!this.tableValue || this.tableValue[index] === change) {
      return;
    }
    this.tableValue[index] = change;
    this.localValueHistory[index] = change;
    this.checkValue(false);
  }

  private setTableData(isInitial = false) {
    const arrayLength = this.labels?.length || this.size;
    if (!arrayLength) {
      this.tableData = [];
      this.checkValue(isInitial);
      return;
    }
    const value = new Array(arrayLength);
    for (let i = 0; i < value.length; i += 1) {
      value[i] = this.tableValue?.[i] ?? this.localValueHistory[i] ?? null;
      this.localValueHistory[i.toString()] = value[i];
    }

    this.tableValue = value;
    this.checkValue(isInitial);

    if (this.labels) {
      const localTableData = this.labels.map((label, index) => ({
        label,
        value: this.tableValue && this.tableValue[index],
      }));
      this.tableData = localTableData;
    } else {
      const localTableData = new Array(arrayLength);
      for (let i = 0; i < localTableData.length; i += 1) {
        localTableData[i] = {
          label: (this.labelPrefix ? `${this.labelPrefix} ` : '') + (i + 1),
          value: this.tableValue[i],
        };
      }
      this.tableData = localTableData;
    }
  }

  private checkValue(isInitial = false) {
    const value = this.tableValue?.some(val => val !== null) ? this.tableValue : null;

    if (JSON.stringify(this._value) !== JSON.stringify(value)) {
      this._value = value;
      this.emitChanges(isInitial);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.tableData) {
      return;
    }
    if (changes.size) {
      if (changes.size.previousValue > changes.size.currentValue) {
        this.tableData = this.tableData.slice(0, this.size);
      }
    }
    if (changes.labels || changes.labelPrefix || changes.size) {
      this.setTableData();
    }
  }

  public focusInput(index: number): void {
    [...this.basicInputContainers][index]?.focus();
  }

  ngOnInit() {
    this.setTableData(true);
  }
}
