import { Component } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  readonly location = window.location;
}
