import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { AgGridSidebarComponentBase } from '../../ag-grid-panels-base';
import { MultipleSelectOption, SelectFilterConfig, SelectFilterOption } from '../../models/ag-grid-panel.model';

@Component({
  standalone: false,
  selector: 'om-select-multiple-filter',
  templateUrl: './select-multiple-filter.component.html',
  styleUrls: ['./select-multiple-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectMultipleFilterComponent extends AgGridSidebarComponentBase implements OnChanges {
  private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

  @Input() columnConfig: SelectFilterConfig;
  @Input() model: Record<string, any>;
  @Output() sendfilters = new EventEmitter<{ id: string; label: string; filters: any[] }>();

  displayOptions: MultipleSelectOption[] = [];
  hiddenOptions: MultipleSelectOption[] = [];

  private options: SelectFilterOption[];
  filteredOptions: MultipleSelectOption[] = [];

  isSelectAllHidden: boolean;
  isSelectAllChecked: boolean;
  isSelectAllIndeterminate: boolean;

  private applySelectedValues(selectedValues?: any[]) {
    const values = selectedValues ?? [];
    this.options = this.options.map(option => {
      return {
        ...option,
        selected: values.includes(option.value),
      };
    });
    this.applyMiniFilter();
  }

  applyMiniFilter(searchTerm?: string) {
    this.isSelectAllHidden = !!searchTerm;

    if (searchTerm) {
      this.filteredOptions = this.options.filter(option => {
        return option.label.toLowerCase().includes(searchTerm.toLowerCase());
      });
    } else {
      this.filteredOptions = this.options;
    }
  }

  onSelectOption(selectedOption: SelectFilterOption, selected: boolean) {
    const option = this.options.find(opt => opt.value === selectedOption.value);
    if (option) {
      option.selected = selected;
      this.updateModel();
    }
  }

  onSelectAll(selected: boolean) {
    this.options = this.options.map(opt => {
      return {
        ...opt,
        selected,
      };
    });
    this.applyMiniFilter();
    this.updateModel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.model) {
      return;
    }
    if (changes.model) {
      this.options = this.columnConfig.options ?? [];
      const filterValues = this.model[this.columnConfig.id] ? this.model[this.columnConfig.id] : [];
      this.applySelectedValues(filterValues);
      this.updateModel();
    }
  }

  private getSelectedOptions() {
    return this.options.filter(opt => opt.selected);
  }

  reset() {
    this.applySelectedValues(this.columnConfig.default);
    this.updateModel();
  }

  private updateModel() {
    const selectedOptions = this.getSelectedOptions();
    const selectedValues = selectedOptions.map(option => option.value);

    this.isSelectAllChecked = selectedOptions.length === this.options.length;
    this.isSelectAllIndeterminate = !!(selectedOptions.length && selectedOptions.length !== this.options.length);

    const previewSize = this.columnConfig.previewSize ?? 1;
    this.displayOptions = selectedOptions.slice(0, previewSize);
    this.hiddenOptions = selectedOptions.slice(previewSize);

    this.sendfilters.emit({
      id: this.columnConfig.id,
      label: this.columnConfig.label ?? '',
      filters: selectedValues,
    });
  }
}
