import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { ListInputConfig } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

/*
  TODO list:
    Datepicker to handle stuff like toggling on click, formatting, etc

    Styling:
             - undo some of the fixed padding/margins once all the om-children input
                elements are finalised
             - needed tweaks..

*/
type ListInputType = ListInputConfig['type'] | null;
type ListInputValue = number | string | undefined;

@Component({
  standalone: false,
  selector: 'om-list-input',
  templateUrl: './list-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => ListInputComponent)),
})
export class ListInputComponent extends CustomFormFieldControlComponent implements OnChanges {
  readonly context = this;

  @Input()
  get value(): ListInputValue[] {
    return this._value;
  }
  set value(val: ListInputValue[]) {
    if (JSON.stringify(val) !== JSON.stringify(this._value)) {
      this._value = val;
      this.localValue = JSON.parse(JSON.stringify(val ?? null));
      this.emitChanges();
    }
  }

  _value: ListInputValue[] = [];
  childValue: string | number | null = null;
  valueLabels: string[];
  localValue: ListInputValue[];

  @Input()
  get type(): ListInputType {
    return this._type;
  }
  set type(type: ListInputType) {
    if (['date', 'date-no-year'].includes(type ?? '')) {
      this.placeholder = 'Click to add date';
    } else {
      this.placeholder = 'Enter each as separate entries';
    }

    this._type = type;
  }
  _type: ListInputType;

  @Input()
  get options() {
    return this._options;
  }
  set options(options: ValueAndLabel[]) {
    this._options = options;
  }
  _options: ValueAndLabel[];

  @Input() max: string;
  @Input() min: string;

  @Output() onControlValueChanged = new EventEmitter<ListInputValue>();

  onControlValueChange(change: ListInputValue): void {
    this.onControlValueChanged.emit(change);
  }

  onInputChange(change: ListInputValue, index: number): void {
    const tempValue = [...this._value];

    if (!tempValue || tempValue[index] === change) {
      return;
    }

    tempValue[index] = change;
    this._value = [...tempValue];
    this.emitChanges();
  }

  private _addItem = this.utilService.debounce(item => {
    this.value = this._value?.length ? [...this._value, item] : [item];
    setTimeout(() => {
      this.childValue = null;
    });
    if (this._type !== 'select') {
      return;
    }
    const label = this.getLabelForValue(item);
    if (!this.valueLabels) {
      this.valueLabels = [];
    }
    this.valueLabels.push(label);
  }, 150);

  addItem(change: ListInputType) {
    if (!this.utilService.formInputHasValue(change)) {
      return;
    }
    this._addItem(change);
  }

  getLabelForValue(value: ListInputValue): string {
    for (const option of this._options) {
      if (option.value === value) {
        return option.label;
      }
    }
    return String(value); // if proper value/options passed will never happen
  }

  removeItem(index: number) {
    const tempValue = [...this._value];
    tempValue.splice(index, 1);
    this.value = [...tempValue];

    if (this._type === 'select') {
      this.valueLabels.splice(index, 1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.value && this._options) || (changes.options?.currentValue && this._value)) {
      this.valueLabels = this._value.map((value: ListInputValue) => this.getLabelForValue(value));
    }
  }
}
