import { Component, EventEmitter, Input, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AgGridSidebarComponentBase } from '../../ag-grid-panels-base';
import { SizeFilterConfig } from '../../models/ag-grid-panel.model';

@Component({
  standalone: false,
  selector: 'om-size-and-currency-filter',
  templateUrl: './size-and-currency-filter.component.html',
  styleUrls: ['./size-and-currency-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SizeAndCurrencyFilterComponent extends AgGridSidebarComponentBase {
  @Input() columnConfig: SizeFilterConfig;
  @Input() model: Record<string, any>;
  @Output() sendfilters = new EventEmitter<{ id: string; label: string; filters: string }>();

  selectedOption: string;

  operatorOptions = [
    { value: '<', label: 'Less Than' },
    { value: '>', label: 'Greater Than' },
    { value: '-', label: 'Range Between' },
  ];

  sizeOperator = '<';
  sizeLower: string;
  sizeUpper: string;
  sizeCurrency: string;
  chipDisplay: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.model) {
      return;
    }
    if (changes.model) {
      const value = this.model[this.columnConfig.id] ?? this.columnConfig.default;
      if (value) {
        const currentValueArray = value.split(' ');
        if (currentValueArray.includes('-')) {
          this.sizeLower = currentValueArray[0];
          this.sizeOperator = currentValueArray[1];
          this.sizeUpper = currentValueArray[2];
          this.sizeCurrency = currentValueArray[3];
        } else {
          this.sizeOperator = currentValueArray[0];
          this.sizeLower = currentValueArray[1];
          this.sizeCurrency = currentValueArray[2];
        }
        this.updateSize();
      }
    }
  }

  selectSizeOperator() {
    if (this.sizeOperator !== '-') {
      this.sizeUpper = '';
    }
    this.chipDisplay = '';
    this.updateSize();
  }

  selectSizeCurrency() {
    this.updateSize();
  }

  updateSizeUpper(value: string) {
    this.sizeUpper = value;
    this.updateSize();
  }

  updateSize() {
    let option = '';
    if (this.sizeUpper) {
      const [minValue, maxValue] =
        this.sizeLower < this.sizeUpper ? [this.sizeLower, this.sizeUpper] : [this.sizeUpper, this.sizeLower];
      const currency = this.sizeCurrency;
      if (minValue && maxValue && currency) {
        option = `${minValue} - ${maxValue} ${currency}`;
        this.chipDisplay = `${minValue} ${currency} to ${maxValue} ${currency}`;
      }
    } else {
      const type = this.sizeOperator;
      const value = this.sizeLower;
      const currency = this.sizeCurrency;
      if (value && currency && type !== '-') {
        option = `${type} ${value} ${currency}`;
        this.chipDisplay = `${type} ${value} ${currency}`;
      }
    }

    this.selectedOption = option;
    this.sendFilterSize();
  }

  reset() {
    this.selectedOption = '';
    this.chipDisplay = '';
    this.sizeLower = '';
    this.sizeUpper = '';
    this.sizeOperator = this.columnConfig?.default.split(' ')[0] ?? '<';
    this.sizeCurrency = this.columnConfig?.default.split(' ')[this.columnConfig.default.includes('-') ? 3 : 2] ?? '';
    this.sendFilterSize();
  }

  sendFilterSize() {
    this.sendfilters.emit({ id: this.columnConfig.id, label: 'Size', filters: this.selectedOption });
  }
}
