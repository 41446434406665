<mat-form-field>
  <span [ngClass]="{ 'om-disabled': disabled, 'om-placeholder': !value }">
    <p>{{ displayValue }}</p>
  </span>

  <mat-date-range-input [rangePicker]="rangePicker" [min]="min" [max]="max" [disabled]="disabled" [required]="required">
    <input matStartDate [(ngModel)]="startDate" />
    <input matEndDate [(ngModel)]="endDate" (ngModelChange)="onValueChange()" />
  </mat-date-range-input>
  <button mat-icon-button matSuffix>
    <mat-icon svgIcon="calendar"></mat-icon>
  </button>
  <mat-date-range-picker
    #rangePicker
    [startView]="datePickerStartView"
    [panelClass]="datePickerClass"
  ></mat-date-range-picker>
</mat-form-field>
