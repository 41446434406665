<mat-tab-group
  #tabGroup
  [selectedIndex]="currentIndex"
  class="is-inside-container"
  (animationDone)="onTabAnimationDone()"
>
  @for (slide of slides; track slide) {
    <mat-tab>
      <ng-template matTabContent>
        <om-carousel-slide [slideData]="slide" [slideComponent]="slideComponent"></om-carousel-slide>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>

<om-tab-control [tabGroup]="tabGroup"></om-tab-control>
