import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptyStateComponent {
  @Input() icon: string;
  @Input() title: string;
  @Input() message: string;
}
