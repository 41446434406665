import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TrackByService, UtilService } from '@morpho/core';

export interface FlexibleButton {
  name: string;
}

@Component({
  standalone: false,
  selector: 'om-flexible-button-list',
  templateUrl: './flexible-button-list.component.html',
})
export class FlexibleButtonListComponent implements OnChanges {
  @Input('buttonListData')
  set buttonListData(buttonListData: FlexibleButton[]) {
    this.displayedButtonList = [];
    this._buttonListData = buttonListData;
    this.checkLength(0);
  }
  get buttonListData(): FlexibleButton[] {
    return this._buttonListData;
  }
  private _buttonListData: FlexibleButton[];

  @Input() selected: string;

  @Output() selectedChange = new EventEmitter<FlexibleButton>();

  @ViewChild('buttonListElement') buttonListElement: ElementRef;

  @HostListener('window:resize')
  onResize() {
    this.utilService.debounce(this.reCheck.bind(this), 500);
  }

  displayedButtonList: FlexibleButton[] = [];
  menuList: FlexibleButton[] = [];

  isRedrawing = false;
  isMoreSelected = false;

  constructor(
    public trackBy: TrackByService,
    private utilService: UtilService,
    private elementRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {
      this.selected = changes.selected.currentValue;
    }
  }

  selectValue(selectedView: FlexibleButton): void {
    this.selected = selectedView.name;
    this.selectedChange.emit(selectedView);
    this.checkMoreSelected();
  }

  private checkLength(idx: number): void {
    if (this.buttonListData.length < idx + 1) {
      this.setMoreList();
      this.isRedrawing = false;
      return;
    }
    this.isRedrawing = true;
    const item = this.buttonListData[idx];
    this.displayedButtonList.push(item);

    setTimeout(() => {
      const availableWidth = this.elementRef.nativeElement.offsetWidth;
      const listWidth = this.buttonListElement.nativeElement.offsetWidth;
      const padding = 30;

      if (listWidth + padding >= availableWidth) {
        this.displayedButtonList = this.displayedButtonList.slice(0, -2);

        if (!this.menuList.length) {
          this.setMoreList();
          this.reCheck();
        } else {
          this.setMoreList();
          this.isRedrawing = false;
          return;
        }
      } else {
        this.checkLength(idx + 1);
      }
    });
  }

  private reCheck(): void {
    this.displayedButtonList = [];
    this.checkLength(0);
  }

  private setMoreList(): void {
    const displayViews = [...this.displayedButtonList];
    const allViews = [...this.buttonListData];

    if (allViews.length === displayViews.length) {
      this.menuList = [];
    } else {
      const selectedKeys = this.displayedButtonList.map((val: FlexibleButton) => val.name);
      this.menuList = allViews.filter((view: FlexibleButton) => !selectedKeys.includes(view.name));
    }
    this.checkMoreSelected();
  }

  private checkMoreSelected(): void {
    if (!this.selected) {
      return;
    }
    this.isMoreSelected = this.menuList.some((val: FlexibleButton) => val.name === this.selected);
  }
}
