import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmCache } from '../data-structures/cache';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private limit = 50;
  private cache: OmCache<string, Observable<any>> = new OmCache(this.limit);

  setItem(key: string, item: Observable<any>): void {
    this.cache.add(key, item);
  }
  getItem(key: string): Observable<any> | undefined {
    return this.cache.get(key);
  }
}
