<mat-nav-list>
  @if (position === 'open') {
    <button mat-list-item (click)="toggleSidebar()">
      <mat-icon mat-list-icon svgIcon="chevron_left"></mat-icon>
      Collapse Sidebar
    </button>
  } @else {
    <button mat-list-item (click)="toggleSidebar()">
      <mat-icon mat-list-icon svgIcon="chevron_right"></mat-icon>
    </button>
  }
</mat-nav-list>
