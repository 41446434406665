import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgGridPanelModule } from '@morpho/ag-grid-panel';
import { AuthModule } from '@morpho/auth';
import { CoreModule } from '@morpho/core';
import { DialogModule } from '@morpho/dialog';
import { DynamicFormModule } from '@morpho/dynamic-form';
import { FormModule } from '@morpho/form';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TimeagoModule } from 'ngx-timeago';
import { DocumentDownloadButtonComponent } from '../core/patterns/document-list/document-download-button/document-download-button.component';
import { DocumentDownloadPanelComponent } from '../core/patterns/document-list/document-download-panel/document-download-panel.component';
import { DocumentListComponent } from '../core/patterns/document-list/document-list/document-list.component';
import { PricerComponent } from '../core/patterns/pricing/pricer/pricer.component';
import { ActivityCardCollapseWrapper } from './components/activity-card-collapse-wrapper/activity-card-collapse-wrapper.component';
import { ActivityCardComponent } from './components/activity-card/activity-card.component';
import { AgGridToolbarComponent } from './components/ag-grid-toolbar/ag-grid-toolbar.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ContactsTableComponent } from './components/contacts-table/contacts-table.component';
import { DisplayArrayComponent } from './components/display-array/display-array.component';
import { DocumentVaultCardComponent } from './components/document-vault-card/document-vault-card.component';
import { DynamicActionComponent } from './components/dynamic-action/dynamic-action.component';
import { ExpandButtonComponent } from './components/expand-button/expand-button.component';
import { FlexibleButtonListComponent } from './components/flexible-button-list/flexible-button-list.component';
import { FormValueViewerComponent } from './components/form-value-viewer/form-value-viewer.component';
import { GridSearchComponent } from './components/grid-search/grid-search.component';
import { HelpCenterComponent } from './components/help-center/help-center.component';
import { IssuerSelectorComponent } from './components/issuer-selector/issuer-selector.component';
import { LabelValueTableComponent } from './components/label-value-table/label-value-table.component';
import { NarrowCardComponent } from './components/narrow-card/narrow-card.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { PagePlaceholderComponent } from './components/page-placeholder/page-placeholder.component';
import { PopoverComponent } from './components/popover/popover.component';
import { RichtextValueViewerComponent } from './components/richtext-value-viewer/richtext-value-viewer.component';
import { SavedViewsComponent } from './components/saved-views/saved-views.component';
import { ShareButtonComponent } from './components/share-button/share-button.component';
import { TextBlockComponent } from './components/text-block/text-block.component';
import { MappingFromConstantsPipe } from './pipes/mapping-from-constants.pipe';
import { RouterPipe } from './pipes/router.pipe';

const declarations = [
  // components
  ActivityCardComponent,
  ActivityCardCollapseWrapper,
  CalendarComponent,
  ContactsTableComponent,
  DisplayArrayComponent,
  DocumentVaultCardComponent,
  DocumentDownloadPanelComponent,
  DocumentListComponent,
  DocumentDownloadButtonComponent,
  DynamicActionComponent,
  ExpandButtonComponent,
  FlexibleButtonListComponent,
  FormValueViewerComponent,
  RichtextValueViewerComponent,
  GridSearchComponent,
  IssuerSelectorComponent,
  LabelValueTableComponent,
  NarrowCardComponent,
  NotificationCardComponent,
  NotificationModalComponent,
  PagePlaceholderComponent,
  PopoverComponent,
  PricerComponent,
  SavedViewsComponent,
  ShareButtonComponent,
  TextBlockComponent,
  AgGridToolbarComponent,
  HelpCenterComponent,

  // pipes
  MappingFromConstantsPipe,
  RouterPipe,
];

const modules = [
  AuthModule,
  CoreModule,
  FormModule,
  DynamicFormModule,
  AgGridPanelModule,
  DialogModule,
  PdfViewerModule,
  RouterModule,
  TimeagoModule,
];

@NgModule({
  declarations,
  imports: modules,
  exports: [...modules, ...declarations],
})
export class SharedModule {}
