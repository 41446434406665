import { KeyCode, shiftKey } from '@morpho/core';
import { BasePoint, BaseRange, Editor, Element, Node, NodeEntry, Range, Transforms } from 'slate';
import { AngularEditor } from 'slate-angular';
import { ElementType } from '../../constants/rich-text.constants';
import { RichTextTable, RichTextTableCell, RichTextTableRow } from '../../models/rich-text.model';
import { EditorHelper } from '../editor-helper';
import { EMPTY_TABLE_DATA_CELL, TableEditor } from './table-commands';

export const withTables = (editor: Editor) => {
  const {
    deleteBackward,
    deleteForward,
    deleteFragment,
    getFragment,
    mergeNodes,
    normalizeNode,
    onKeyDown,
    onChange,
    setFragmentData,
  } = editor;

  editor.onChange = event => {
    if (event?.operation?.type === 'set_selection' && editor.container) {
      let selectedTableCellNodeEntries: NodeEntry[] = [];
      const selectedTableDetails = TableEditor.getSelectedTableDetails(editor);
      if (selectedTableDetails.tableSelection) {
        selectedTableCellNodeEntries = selectedTableDetails.tableSelection.selectedCellPaths.map(path => {
          return Editor.node(editor, path);
        });
      } else if (selectedTableDetails.regularSelection) {
        selectedTableCellNodeEntries =
          EditorHelper.getNodes(editor, ElementType.TableDataCell, {
            at: selectedTableDetails.regularSelection,
          }) ?? [];
      }

      if (selectedTableCellNodeEntries.length) {
        editor.container.selectedTableCellKeyMap = selectedTableCellNodeEntries.reduce(
          (map: Record<string, boolean>, [cellNode]) => {
            const key = AngularEditor.findKey(editor as any as AngularEditor, cellNode);
            map[key.id] = true;
            return map;
          },
          {},
        );
        editor.container.hasTableSelection = true;
      } else {
        editor.container.selectedTableCellKeyMap = {};
        editor.container.hasTableSelection = false;
      }
    }
    onChange(event);
  };

  editor.deleteBackward = unit => {
    return blockDeleteCell('backward', editor, unit, deleteBackward);
  };
  editor.deleteForward = unit => {
    return blockDeleteCell('forward', editor, unit, deleteForward);
  };

  editor.mergeNodes = options => {
    if (options?.hanging && options.at) {
      const pointBefore = Editor.before(editor, options.at);
      const nodeEntryBefore = EditorHelper.getBlock(editor, { at: pointBefore });
      if (nodeEntryBefore?.[0]?.type === ElementType.TableDataCell) {
        const nodeEntryCurrent = EditorHelper.getBlock(editor, { at: options.at });
        if (JSON.stringify(nodeEntryBefore[1]) !== JSON.stringify(nodeEntryCurrent?.[1])) {
          return;
        }
      }
    }
    return mergeNodes(options);
  };

  editor.deleteFragment = () => {
    const selectedDetails = TableEditor.getSelectedTableDetails(editor);
    if (selectedDetails.tableSelection) {
      editor.withoutNormalizing(() => {
        selectedDetails.tableSelection?.selectedCellPaths.forEach(path => {
          Transforms.removeNodes(editor, { at: path });
          Transforms.insertNodes(editor, EMPTY_TABLE_DATA_CELL, { at: path });
        });
      });
      const [, textPath] = Editor.first(editor, <number[]>selectedDetails.tableSelection.selectedCellPaths.pop());
      const newFocus: BasePoint = { path: textPath, offset: 0 };
      editor.setSelection({ focus: newFocus, anchor: newFocus });
      return;
    }
    if (selectedDetails.regularSelection) {
      editor.select(selectedDetails.regularSelection);
    }
    return deleteFragment();
  };

  editor.getFragment = () => {
    const selectedDetails = TableEditor.getSelectedTableDetails(editor);

    if (selectedDetails.tableSelection) {
      const node = EditorHelper.getNode(editor, ElementType.Table);
      const table = JSON.parse(JSON.stringify(node[0])) as RichTextTable;
      const { minX, maxX, minY, maxY } = selectedDetails.tableSelection;
      table.children = table.children.reduce((rows: RichTextTableRow[], row, rowIndex) => {
        if (!(rowIndex < minX || rowIndex > maxX)) {
          row.children = row.children.filter((col, colIndex) => {
            return !(colIndex < minY || colIndex > maxY);
          });
          rows.push(row);
        }
        return rows;
      }, []);

      return [table];
    }
    if (selectedDetails.regularSelection) {
      editor.select(selectedDetails.regularSelection);
    }
    return getFragment();
  };

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (
      Element.isElement(node) &&
      ([ElementType.Table, ElementType.TableRow] as string[]).includes(node.type) &&
      (!node.children.length || !Element.isElement(node.children[0]))
    ) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }

    if (Element.isElement(node) && node.type === ElementType.Table) {
      Editor.withoutNormalizing(editor, () => {
        const numberOfColumns = node.children.reduce((numberOfColumns, row: RichTextTableRow) => {
          return Math.max(numberOfColumns, row.children.length);
        }, 0);
        node.children.forEach((row: RichTextTableRow, rowIndex: number) => {
          for (let i = row.children.length; i < numberOfColumns; i += 1) {
            const cellPath = [...path, rowIndex, i];
            EditorHelper.insertNestedBlock(editor, EMPTY_TABLE_DATA_CELL, cellPath);
          }
        });
      });
    }

    normalizeNode(entry);
  };

  editor.onKeyDown = (editor: Editor, event: KeyboardEvent) => {
    const path = Editor.path(editor, editor.selection as any);
    const block = EditorHelper.getBlock(editor);
    const isTableDataCell = block?.[0].type === ElementType.TableDataCell;

    switch (event.key) {
      case KeyCode.Up:
        break;
      case KeyCode.Down:
        break;
      case KeyCode.Tab:
        if (isTableDataCell && onTableDataCellTab(editor, event)) {
          event.preventDefault();
          return;
        }
        break;
      case KeyCode.Enter:
      default:
        break;
    }

    onKeyDown?.(editor, event);
  };

  return editor;
};

function blockDeleteCell(direction: 'forward' | 'backward', editor: Editor, unit: string, fallback: Function) {
  const isParagraphAtEdge = (cellNode: Node, selection: BaseRange) => {
    const paragraphPosition = selection.anchor.path.slice(-2, -1).pop();
    const expectedPosition = direction == 'backward' ? 0 : (cellNode as RichTextTableCell).children.length - 1;
    return paragraphPosition === expectedPosition;
  };
  const [isPointAtParagraphEdge, getNextPoint] =
    direction == 'backward'
      ? [EditorHelper.isPointAtTheStart, Editor.before]
      : [EditorHelper.isPointAtTheEnd, Editor.after];

  const { selection } = editor;
  if (selection && Range.isCollapsed(selection)) {
    const [cellNode, cellPath] = EditorHelper.getNode(editor, ElementType.TableDataCell);
    if (cellNode) {
      if (
        isParagraphAtEdge(cellNode, selection) &&
        isPointAtParagraphEdge(editor, [cellNode, cellPath], selection.anchor)
      ) {
        return;
      }
    } else {
      const nextNodePoint = getNextPoint(editor, selection);
      const [nextNode] = EditorHelper.getNode(editor, ElementType.TableDataCell, { at: nextNodePoint?.path });
      if (nextNode) {
        return;
      }
    }
  }

  fallback(unit);
}

function onTableDataCellTab(editor: Editor, event: KeyboardEvent) {
  const { selection } = editor;
  if (selection) {
    const [getSibling, getPoint] = shiftKey(event)
      ? [EditorHelper.previousSibling, Editor.end]
      : [EditorHelper.nextSibling, Editor.start];

    const [, nodePath] = EditorHelper.getNode(editor, ElementType.TableDataCell);
    const [, nextCellNodePath] = getSibling(editor, nodePath);
    if (nextCellNodePath) {
      Transforms.select(editor, nextCellNodePath);
      return true;
    }

    const [, nextRowNodePath] = getSibling(editor, nodePath.slice(0, -1));
    if (nextRowNodePath) {
      const path = getPoint(editor, nextRowNodePath).path;
      Transforms.select(editor, path);
      return true;
    }

    const [, nextBlockNodePath] = getSibling(editor, nodePath.slice(0, -2));
    if (nextBlockNodePath) {
      const point = getPoint(editor, nextBlockNodePath);
      Transforms.select(editor, point);
      return true;
    }

    return true;
  }
  return false;
}
