@if (!isActive) {
  <button mat-icon-button class="is-chip" omTooltip="Search the inputs" (click)="showSearchField()">
    <mat-icon svgIcon="search"></mat-icon>
  </button>
} @else if (isActive) {
  <div class="om-multi-action">
    <mat-form-field (focusin)="onFocusIn()" (focusout)="onFocusOut()" [ngClass]="fieldClass">
      <mat-icon matPrefix svgIcon="search"></mat-icon>
      <input
        matInput
        #input
        [formControl]="searchControl"
        autocomplete="off"
        [placeholder]="placeholder"
        id="search"
        type="search"
      />
      <button
        mat-icon-button
        matSuffix
        class="is-flat"
        (click)="clearForm()"
        [style.visibility]="searchControl.value ? 'visible' : 'hidden'"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </mat-form-field>
  </div>
}
