<div class="navigation-popup">
  @if (hyperlinkViewState === HyperlinkViewState.Edit) {
    <input [(ngModel)]="url" type="text" placeholder="Enter URL here..." (click)="$event.stopPropagation()" />
  } @else {
    <div class="navigation-popup__link">
      <a [href]="url" target="_blank" class="om-hide-external-link-icon">{{ url }}</a>
    </div>
  }

  <ul class="om-navigation-button-list">
    @for (action of hyperlinkViewState === HyperlinkViewState.Edit ? editActions : viewActions; track action) {
      <li>
        <button class="om-navigation-button" (click)="action.action(); $event.stopPropagation()">
          <mat-icon [svgIcon]="action.icon"></mat-icon>
        </button>
      </li>
    }
  </ul>
</div>
