@if (buttons.length === 1) {
  <button mat-button [ngClass]="buttonClass" (click)="onShare(buttons[0])">
    <mat-icon [svgIcon]="buttons[0].icon"></mat-icon>
    <span>{{ buttons[0].label }}</span>
  </button>
} @else {
  <button mat-button [ngClass]="buttonClass" [matMenuTriggerFor]="shareMenu">
    <span>Share</span>
    @if (isLoading) {
      <mat-spinner diameter="12"></mat-spinner>
    }
    <mat-icon iconPositionEnd [style.visibility]="isLoading ? 'hidden' : 'visible'" svgIcon="chevron_down"></mat-icon>
  </button>
  <mat-menu #shareMenu="matMenu">
    @for (option of buttons; track option) {
      <button mat-menu-item (click)="onShare(option)">
        <mat-icon [svgIcon]="option.icon"></mat-icon>
        <span>{{ option.label }}</span>
      </button>
    }
  </mat-menu>
}
