@if (rows.length) {
  <table class="is-new-style" [formGroup]="maturitiesForm">
    <thead>
      <tr>
        <th>Pricing Tab</th>
        <th>Maturity</th>
        <th class="is-icon-column"></th>
      </tr>
    </thead>
    <tbody>
      @for (row of rows; track row) {
        <tr>
          <td>{{ row.label }}</td>
          <td>
            <mat-form-field class="is-inline">
              <om-maturity-input [formControlName]="row.value"></om-maturity-input>
            </mat-form-field>
          </td>
          <td class="is-icon-column">
            <button mat-icon-button class="is-warning" [disabled]="rows.length === 1" (click)="deleteRow(row.value)">
              <mat-icon svgIcon="delete"></mat-icon>
            </button>
          </td>
        </tr>
      }
    </tbody>
  </table>
}
@for (warningMessage of warningMessages; track warningMessage) {
  <span class="mat-hint is-alert">{{ warningMessage }}</span>
}
