import {
  DialogHeightMode,
  DialogSize,
  DynamicActionBase,
  DynamicHttpAction,
  DynamicRedirectAction,
  DynamicRequestAction,
  HttpMethod,
} from '@morpho/core';

export type StatusClass = 'is-accent' | 'is-warning' | 'is-alert';

export interface ButtonAction {
  class?: StatusClass;
  disabled?: boolean;
  method?: HttpMethod;
  request_data?: any;
  request_options?: any;
  status?: 'negative' | 'neutral' | 'positive' | 'alert';
  success_url?: string;
  success_message?: string;
  error_message?: string;
  text?: string;
  type?: 'request';
  url?: string;
}

export interface BooleanPopupParams extends DynamicActionBase {
  disabled?: boolean;
  events?: {
    actors: any;
    datetime: string;
    description: string;
    type: 'event';
  }[];
  status?: 'negative' | 'positive' | 'neutral';
  icon?: string;
  action?: ButtonAction;
  left_action?: ButtonAction;
  right_action?: ButtonAction;
  strong_message?: string;
  message: string;
  additionalInfo?: string;
  text?: string;
  title: string;
  type?: 'simple_modal' | 'boolean_modal';
  dialog_size?: DialogSize;
  dialog_height_mode?: DialogHeightMode;
  dialog_height?: string;
  info?: {
    type: 'info';
    description?: string;
    actions?: (DynamicRequestAction | DynamicRedirectAction | DynamicHttpAction)[];
  };
}

export const statusClassMap: Record<string, StatusClass> = {
  alert: 'is-alert',
  negative: 'is-warning',
  positive: 'is-accent',
};
