import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DynamicFormService } from '../services/dynamic-form.service';

@Pipe({
  standalone: false,
  name: 'isContentTag',
})
@Injectable({ providedIn: 'root' })
export class IsContentTagPipe implements PipeTransform {
  constructor(private dynamicFormService: DynamicFormService) {}

  transform(fieldName: string): boolean {
    return this.dynamicFormService.isContentTag(fieldName);
  }
}
