import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ButtonCellRendererParams extends ICellRendererParams {
  icon?: string;
  label: string;
  onClick: (params: ButtonCellRendererParams, event: MouseEvent) => void;
  isVisible?: (params: ButtonCellRendererParams) => boolean;
}

@Component({
  standalone: false,
  selector: 'om-button-cell-renderer',
  templateUrl: './button-cell-renderer.component.html',
  styleUrls: ['./button-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonCellRendererComponent implements ICellRendererAngularComp {
  params: ButtonCellRendererParams;
  isVisible: boolean;

  agInit(params: ButtonCellRendererParams): void {
    this.params = params;
    this.isVisible = this.params.isVisible ? this.params.isVisible(params) : true;
  }

  refresh(): boolean {
    return false;
  }

  onClick(event: MouseEvent) {
    this.params.onClick(this.params, event);
  }
}
