import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyCode } from '@morpho/core';
import { PrimitiveInputComponent } from '@morpho/form';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ColDef, ColGroupDef, ICellEditorParams } from 'ag-grid-community';

export interface TextFieldConfig extends BaseFieldConfig {
  type: 'text';
  maxLength?: number;
  allowedKeyRegex?: RegExp;
}

export interface NumberFieldConfig extends BaseFieldConfig {
  type: 'number';
  min?: number;
  max?: number;
  decimalPlaces?: number;
}

export interface BaseFieldConfig {
  type: string;
}

export type CellFieldConfig = TextFieldConfig | NumberFieldConfig;

export interface PrimitiveFieldParams {
  field: CellFieldConfig;
  handleKeyDownFunc?: Function;
}

export interface PrimitiveCellEditorParams extends ICellEditorParams, PrimitiveFieldParams {}

@Component({
  standalone: false,
  selector: 'om-primitive-cell-editor',
  templateUrl: './primitive-cell-editor.component.html',
  styleUrls: ['./primitive-cell-editor.component.html'],
})
export class PrimitiveCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('primitiveInput') primitiveInputComponent: PrimitiveInputComponent;
  min: number;
  max: number;
  maxLength: number;
  allowedKeyRegex: RegExp;
  handleKeyDownFunc?: Function;
  decimalPlaces?: number;

  isMouseDown = false;
  params: PrimitiveCellEditorParams;
  formControl = new FormControl();

  @HostListener('mousedown', ['$event'])
  handleMouseDown(event: KeyboardEvent) {
    if (event) {
      this.isMouseDown = true;
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (this.handleKeyDownFunc) {
      this.handleKeyDownFunc(event, this.params, this.getValue());
    } else {
      const arrowKeys = [KeyCode.Up, KeyCode.Down, KeyCode.Left, KeyCode.Right];
      if (this.isMouseDown && arrowKeys.includes(event.key as KeyCode)) {
        event.stopPropagation();
      }

      if (event.key === KeyCode.Enter) {
        this.params.api.stopEditing();
        const columnId = this.params.column.getColId() ?? '';
        const flattenedColumns = this.params.api.getColumnDefs()?.reduce((acc: ColDef[], curr: ColGroupDef) => {
          if (curr.groupId) {
            acc.push(...curr.children);
          } else {
            acc.push(curr);
          }
          return acc;
        }, []);
        const columns: string[] = (flattenedColumns ?? []).map((column: ColDef) => column.colId ?? '');
        const columnToTheRight = columns[columns?.indexOf(columnId) + 1];
        if (columnToTheRight && this.params.node.rowIndex) {
          this.params.api.tabToNextCell();
          this.params.api.startEditingCell({ rowIndex: this.params.node.rowIndex, colKey: columnToTheRight });
        }
      }
    }
  }

  agInit(params: PrimitiveCellEditorParams) {
    this.params = params;
    if (params.field.type === 'number') {
      if (params.field.min) {
        this.min = params.field.min;
      }
      if (params.field.max) {
        this.max = params.field.max;
      }

      if (params.field.decimalPlaces) {
        this.decimalPlaces = params.field.decimalPlaces;
      }
    } else {
      if (params.field.maxLength) {
        this.maxLength = params.field.maxLength;
      }

      if (params.field.allowedKeyRegex) {
        this.allowedKeyRegex = params.field.allowedKeyRegex;
      }
    }
    if (params.handleKeyDownFunc) {
      this.handleKeyDownFunc = params.handleKeyDownFunc;
    }
    this.setInitialState(params);
  }

  ngAfterViewInit() {
    this.focusOnInput();
  }

  onFocusout() {
    this.params.api.stopEditing();
  }

  private focusOnInput() {
    setTimeout(() => {
      if (this.primitiveInputComponent) {
        this.primitiveInputComponent.focus();
      } else {
        this.focusOnInput();
      }
    }, 20);
  }

  private setInitialState(params: ICellEditorParams) {
    let startValue;

    if (params.eventKey === KeyCode.Backspace || params.eventKey === KeyCode.Delete) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (params.eventKey?.length === 1) {
      // if a letter was pressed, we start with the letter
      startValue = params.eventKey;
    } else {
      // otherwise we start with the current value
      startValue = !params.value || params.value === '-' ? '' : params.value;
    }

    this.formControl.setValue(startValue);
  }

  refresh(): boolean {
    return false;
  }

  getValue(): number | string {
    let value = this.formControl.value;
    switch (this.params.field.type) {
      case 'number':
        if (this.max && this.max < value) {
          value = this.max;
        }

        if (this.min && this.min > value) {
          value = this.min;
        }

        if (this.decimalPlaces && (value || value === 0)) {
          value = (+value).toFixed(this.decimalPlaces);
        }
        break;
      case 'text':
        if (this.maxLength) {
          value = value.slice(0, this.maxLength);
        }
    }

    return value;
  }
}
