import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  standalone: false,
  selector: 'om-users-icons-cell-renderer',
  templateUrl: './users-icons-cell-renderer.component.html',
  styleUrls: ['./users-icons-cell-renderer.component.scss'],
})
export class UsersIconsCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
