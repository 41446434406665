import { Injectable } from '@angular/core';
import { Form } from '@angular/forms';
import { PricingUnits, TENOR_REGEX_PATTERN } from '@morpho/core';
import { FormInputConfig } from '@morpho/form';
import { RichText } from '@morpho/rich-text';
import * as moment from 'moment';
import * as hash from 'object-hash';
import {
  BACKEND_DATETIME_FORMAT,
  BACKEND_DATETIME_PRECISE_FORMAT,
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_NO_YEAR_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  DATE_NO_YEAR_FORMAT,
} from '../constants/display';
import {
  FRACTION_REGEX_PATTERN,
  fractionUnicode,
  slashUnicode,
  subscriptNumbers,
  superscriptNumbers,
} from '../constants/fraction-unicode';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  applyCommasToNumber(numberValue: number): string {
    if (!numberValue && numberValue !== 0) {
      return '';
    }
    const isNumberNegativeZero = Object.is(Math.sign(numberValue), -0);

    let numberString = numberValue.toString();
    if (isNumberNegativeZero && numberString.charAt(0) !== '-') {
      numberString = `-${numberString}`;
    }

    const commafy = (num: string) => {
      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    if (numberString.includes('.')) {
      const split = numberString.split('.');
      return `${commafy(split[0])}.${split[1]}`;
    }
    return commafy(numberString);
  }

  convertArrayToMap(array: any[], key?: string): { [key: string]: any } {
    return array.reduce((map, item, index) => {
      const k = key ? item[key] : index;
      map[k] = item;
      return map;
    }, {});
  }

  convertApiEndpoint(url: string): string {
    return url.replace('/api/', '');
  }

  /**
   * Returns a function, that, as long as it continues to be invoked, will not
   * be triggered. The function will be called after it stops being called for
   * `wait` milliseconds.
   * https://gist.github.com/treyhuffine/2ced8b8c503e5246e2fd258ddbd21b8c
   */
  debounce(func: (...args: any) => any, wait = 250) {
    let timeout: NodeJS.Timeout;

    return (...args: any) => {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  convertMomentToString(moment: moment.Moment, format: string) {
    return moment.format(format);
  }

  currentTime() {
    return moment();
  }

  convertFromBackendDateStringFormat(
    dateString: string,
    type: 'date' | 'datetime' | 'date-no-year' | 'precise',
    displayType?: 'date' | 'datetime' | 'date-no-year' | 'be-date',
  ): string {
    if (!dateString) {
      return '';
    }
    let backendFormat = BACKEND_DATE_FORMAT;
    let displayFormat = DATE_FORMAT;

    if (type === 'datetime') {
      backendFormat = BACKEND_DATETIME_FORMAT;
      displayFormat = DATETIME_FORMAT;
    } else if (type === 'precise') {
      backendFormat = BACKEND_DATETIME_PRECISE_FORMAT;
      displayFormat = DATETIME_FORMAT;
    } else if (type === 'date-no-year') {
      dateString = `2000-${dateString}`;
      backendFormat = `YYYY-${BACKEND_DATE_NO_YEAR_FORMAT}`;
      displayFormat = DATE_NO_YEAR_FORMAT;
    }

    if (displayType) {
      switch (displayType) {
        case 'date':
          displayFormat = DATE_FORMAT;
          break;
        case 'datetime':
          displayFormat = DATETIME_FORMAT;
          break;
        case 'date-no-year':
          displayFormat = DATE_NO_YEAR_FORMAT;
          break;
        case 'be-date':
          displayFormat = BACKEND_DATE_FORMAT;
          break;
        default:
          break;
      }
    }
    return moment(dateString, backendFormat).format(displayFormat);
  }

  bytesToDisplayString(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  formInputHasValue(inputValue: any, fieldConfig?: FormInputConfig): boolean {
    if (!inputValue && inputValue !== 0 && inputValue !== false) {
      return false;
    }

    if (typeof inputValue === 'object') {
      if (fieldConfig?.element === 'table') {
        return Array.isArray(inputValue) && inputValue.length
          ? inputValue.every(item => this.formInputHasValue(item))
          : false;
      }
      return Array.isArray(inputValue)
        ? !!inputValue.filter(item => this.formInputHasValue(item)).length
        : JSON.stringify(inputValue) !== '{}';
    }

    return true;
  }

  formToFormData(form: Form): FormData {
    const formData = new FormData();

    for (const [name, value] of Object.entries(form)) {
      if (!value) {
        continue;
      }
      if (Array.isArray(value)) {
        for (const val of value) {
          formData.append(name, val);
        }
      } else {
        formData.append(name, value);
      }
    }
    return formData;
  }

  getArrayDiff(array1: string[], array2: string[]): { added?: string[]; removed?: string[] } | null {
    const addedSet = new Set(array2);

    const removed = [...new Set(array1)].filter(field => {
      return !addedSet.delete(field);
    });

    const added = [...addedSet];

    const diff = {
      ...(added.length ? { added } : null),
      ...(removed.length ? { removed } : null),
    };

    return Object.keys(diff).length ? diff : null;
  }

  getArraysIntersection(arr1: any[], arr2: any[]): any[] {
    return arr1.filter(item => arr2.includes(item));
  }

  getInitialsFromFullName(fullName: string): string {
    let initials: string[] = [];
    const allNames = fullName.split(' ');
    if (allNames.length) {
      initials = [allNames[0].charAt(0)];
    }
    if (allNames && allNames.length > 1) {
      initials.push(allNames[allNames.length - 1].charAt(0));
    }
    return initials.map(initial => initial.toUpperCase()).join('');
  }

  getFirstScrollableAncestor(element: HTMLElement) {
    let style = getComputedStyle(element);
    const excludeStaticParent = style.position === 'absolute';
    const overflowRegex = /(auto|scroll)/;

    if (style.position === 'fixed') {
      return document.body;
    }

    for (let parent: HTMLElement | null = element; !!parent; parent = parent.parentElement) {
      style = getComputedStyle(parent);
      if (excludeStaticParent && style.position === 'static') {
        continue;
      }
      if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
        return parent;
      }
    }
    return document.body;
  }

  hash(object: any): string {
    return hash(object);
  }

  isMidSwap(basis?: string): boolean {
    return !!basis?.startsWith('MS_');
  }

  logError(error: string) {
    setTimeout(() => {
      throw new Error(`Custom:${error}`);
    });
  }

  nthIndex(text: string, char: string, n: number): number {
    if (!text.includes(char)) {
      return -1;
    }

    const parts = text.split(char);
    if (parts.length < n) {
      return -1;
    }

    let result = 0;
    for (let i = 0; i < n; i++) {
      result += parts[i].length;
    }
    result += (n - 1) * char.length;
    return result;
  }

  /**
   *
   * @param number
   * @returns a number without a floating point error
   */
  parseNumber(number: number | string | null | undefined): number | null {
    // https://newbedev.com/which-is-better-number-x-or-parsefloat-x
    if (typeof number === 'string') {
      number = number.replace(/[^\d.-]/g, ''); // strip non number - except for '.' & '-'
    }
    if (number == null) {
      return null;
    }
    if (typeof number !== 'number') {
      number = parseFloat(number);
    }
    return isNaN(number) ? null : Number(number.toPrecision(12));
  }

  percent(numerator: number, denominator: number): number {
    return Math.floor((100 * numerator) / denominator);
  }

  processBackendResource(url: string | null | undefined): string {
    if (!url) {
      return '';
    }
    return url;
  }

  sanitiseStringValue(stringValue: string | undefined): string {
    return stringValue ? this.stripAccentsFromString(stringValue.toString().toLowerCase().trim()) : '';
  }

  // todo: move to sortComp
  generateDateStringComparatorFromFormat(format?: string) {
    return (date1: string, date2: string) => {
      if (date1 && date2) {
        const timestamp1 = (format ? moment(date1, format) : moment(date1)).unix();
        const timestamp2 = (format ? moment(date2, format) : moment(date2)).unix();
        return timestamp1 - timestamp2;
      }

      if (!date1) {
        return -1;
      }
      if (!date2) {
        return 1;
      }
      return 0;
    };
  }

  sortComparatorForDateString(date1: string, date2: string): number {
    return this.generateDateStringComparatorFromFormat()(date1, date2);
  }

  sortComparatorForString(a: string, b: string): number {
    return a.localeCompare(b, 'en', { sensitivity: 'base' });
  }

  stringToDate(date: string): Date {
    // convert date string 'dd-mmm-yyyy' to browser agnostic 'dd/mmm/yyyy'
    // else Firefox gets confused
    return new Date(date.replace(/-/g, '/'));
  }

  stripAccentsFromString(str: string): string {
    // normalize()ing to NFD Unicode normal form decomposes combined graphemes into the combination of simple ones.
    // then use a regex character class to globally get rid of the accents,
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  // convert a noun to posessive form
  // e.g. Vasakronan -> Vasakronan's, BNP Paribas -> BNP Paribas'
  toPossessive(noun: string): string {
    if (!noun.length) {
      return noun;
    }
    return noun.endsWith('s') ? `${noun}'` : `${noun}'s`;
  }

  trimWhitespaceAndNewlines(str: string): string {
    return str.replace(/^\s+|\s+$/g, '');
  }

  caseInsensitiveSortComparator(a: string, b: string) {
    return a.localeCompare(b, undefined, { sensitivity: 'base' });
  }

  toTitleCase(str: string): string {
    return str
      .toLowerCase()
      .split(' ')
      .map((word: string) => word[0].toUpperCase() + word.slice(1))
      .join(' ');
  }

  highlightScrollToElement(element: HTMLElement | Element | null | undefined) {
    // TODO: Option to highlight ALL elemenets with that class?
    if (!element) {
      return;
    }
    element.classList.add('om-highlight-pulse');
    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
    setTimeout(() => {
      element.classList.remove('om-highlight-pulse');
    }, 1000);
  }

  shuffle<T>(array: T[]): T[] {
    let swapIndex: number;
    let currentValue: T;
    let swapValue: T;
    for (let i = array.length - 1; i >= 0; i--) {
      swapIndex = Math.floor(Math.random() * (i + 1));

      currentValue = array[i];
      swapValue = array[swapIndex];

      array[i] = swapValue;
      array[swapIndex] = currentValue;
    }
    return array;
  }

  arrayToObject(array: any[], key?: string) {
    if (!array) {
      return {};
    }
    return array.reduce((accumulator, current, i) => {
      accumulator[key ? current[key] : i] = current;
      return accumulator;
    }, {});
  }

  sentenceCaseString(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  areArraysEquivalent(arrayA: any[], arrayB: any[]): boolean {
    //* Note: This check ignores element order (so [1,2,3] === [3,2,1])
    return JSON.stringify([...arrayA].sort()) === JSON.stringify([...arrayB].sort());
  }

  areSetsEquivalent(setA: Set<any>, setB: Set<any>) {
    return this.areArraysEquivalent([...setA], [...setB]);
  }

  areValuesEqual(valueA: any, valueB: any, ignoreOrder = false, ignoreWhitespace = true): boolean {
    if (valueA && valueB) {
      if (typeof valueA === 'string' && ignoreWhitespace) {
        valueA = valueA.trim();
      }
      if (typeof valueB === 'string' && ignoreWhitespace) {
        valueB = valueB.trim();
      }
      if (Array.isArray(valueA) && Array.isArray(valueB) && ignoreOrder) {
        return this.areArraysEquivalent(valueA, valueB);
      }
      const valueAString = JSON.stringify(valueA).trim();
      const valueBString = JSON.stringify(valueB).trim();

      if (valueAString.startsWith('{') && valueBString.startsWith('{')) {
        return this.areObjectsEquivalent(valueA, valueB);
      }

      return valueAString === valueBString;
    }
    return valueA === valueB;
  }

  isTenorString(value: any): boolean {
    return typeof value === 'string' ? !!value?.match(TENOR_REGEX_PATTERN) : false;
  }

  areObjectsEquivalent(objectA: any, objectB: any): boolean {
    if (objectA === objectB) {
      return true;
    }

    if (typeof objectA !== 'object' || typeof objectB !== 'object' || objectA === null || objectB === null) {
      return false;
    }
    const keysA = Object.keys(objectA);
    const keysB = Object.keys(objectB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!keysB.includes(key)) {
        return false;
      }

      if (typeof objectA[key] === 'function' || typeof objectB[key] === 'function') {
        if (objectA[key].toString() !== objectB[key].toString()) {
          return false;
        }
      } else if (!this.areObjectsEquivalent(objectA[key], objectB[key])) {
        return false;
      }
    }

    return true;
  }

  isValidIP(ip: string): boolean {
    return /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(ip);
  }

  /**
   * Converts a 32-bit IP address into a number
   * @param  {string} ip - The Internet IP address as a string. For example 11.239.161.154
   * @return {number | null} - A proper address representation in long integer. For example 200253850
   */
  ipToNumber(ip: string): number | null {
    const parts = ip.match(/^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/);

    if (!parts) {
      return null;
    }

    return (
      parseInt(parts[1]) * 16777216 + parseInt(parts[2]) * 65536 + parseInt(parts[3]) * 256 + parseInt(parts[4]) * 1
    );
  }

  /**
   * Number to 32-bit IP address algorithm from https://github.com/legend80s/long2ip/blob/master/index.js
   * @param  {number} num - A proper address representation in long integer. For example 200253850
   * @return {string | null} - The Internet IP address as a string. For example 11.239.161.154
   */
  numberToIP(num: number): string | null {
    const MAX_IP_IN_LONG = 4294967295; // 255.255.255.255
    const MIN_IP_IN_NUM = 0; // 0.0.0.0

    if (typeof num !== 'number' || num > MAX_IP_IN_LONG || num < MIN_IP_IN_NUM) {
      return null;
    }

    return [num >>> 24, (num >>> 16) & 0xff, (num >>> 8) & 0xff, num & 0xff].join('.');
  }

  // NOTE this does not work for 64 bit
  // Function expects no host bits are set
  getCIDRRange(cidr: string): [string, string] | null {
    if (!cidr) {
      return null;
    }

    const cidrSplit = cidr.split('/');
    const ipComponent = cidrSplit[0];
    const range = parseInt(cidrSplit[1]);

    if (cidrSplit.length !== 2 || !this.isValidIP(cidrSplit[0]) || Number.isNaN(range) || range < 0 || range > 32) {
      return null;
    }

    // if single IP
    if (range === 32) {
      return [ipComponent, ipComponent];
    }

    const numericStart = this.ipToNumber(ipComponent) ?? 0;
    const endIP = this.numberToIP(numericStart + Math.pow(2, 32 - range) - 1);
    if (!endIP) {
      return null;
    }
    return [ipComponent, endIP];
  }

  /**
   * Range function exactly like python's: https://docs.python.org/3/library/functions.html#func-range
   * (but without the step)
   * Generates a sequence of numbers of given range
   * @param  {number} a - If b is undefined then the size of the sequence starting at 0 else start of sequence
   * @param  {number} b - If defined, end of sequence, not inclusive
   * @return {number[]} - The sequence generated e.g. (1, 3) => [1, 2]
   */
  range(a: number, b = 0): number[] {
    if (b === 0) {
      return [...Array(a).keys()];
    }
    const size = b - a;
    if (size > 0) {
      return [...Array(size).keys()].map(i => i + a);
    }
    return [];
  }

  /**
   * Finds possible permutation of elements in arrays of an array.
   * @param  { T[][]} array - The original array that contains array of strings, numbers or anything
   * @return { T[][]} - The array generated e.g. ([['a', 'b'], ['c', 'd'], ['e', 'f]) => ['a c e', 'a c f', 'a d e', 'a d f', 'b c e', 'b c f', 'b d e', 'b d f']
   */

  getPossiblePermutations<T>(array: T[][]): T[][] {
    if (!array.length) {
      return [];
    }
    if (array.length === 1) {
      return array[0].map(value => [value]);
    }
    const heads: T[] = array[0];
    const tails: T[][] = this.getPossiblePermutations(array.slice(1));
    const permutations: T[][] = [];
    heads.forEach(head => {
      tails.forEach(tail => {
        permutations.push([head, ...tail]);
      });
    });
    return permutations;
  }
  /**
   * Splits an array into smaller chunks
   * @param  {T[]} a - The original array to split
   * @param  {number} b - Desired length of the new chunks
   * @return {T[T[]]} - The sequence generated e.g. ([1, 2, 3, 4], 2) => [[1, 2], [3, 4]]
   */
  spliceArrayIntoChunks<T>(arr: T[], chunkSize: number): T[][] {
    const res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  }

  /**
   * Converts a given float into fraction format a/b, returned {numerator: a, denominator: b}
   * Based on https://en.wikipedia.org/wiki/Euclidean_algorithm
   * @param {number} x - float to convert
   * @returns {{ numerator: number; denominator: number }} - Object describing the fraction
   */
  convertFloatToFraction(x: number): { numerator: number; denominator: number } | null {
    const getGreatestCommonDivisor = (a: number, b: number): number => {
      return b ? getGreatestCommonDivisor(b, a % b) : a;
    };
    const fractionalComponent = x.toString().split('.')[1];
    if (!fractionalComponent) {
      return null;
    }
    const numerator = parseInt(fractionalComponent);
    const denominator = Math.pow(10, fractionalComponent.length);
    const greatestCommonDivisor = getGreatestCommonDivisor(numerator, denominator);
    return { numerator: numerator / greatestCommonDivisor, denominator: denominator / greatestCommonDivisor };
  }

  sanitizeForHtml(x: string | number | undefined): string {
    return x?.toString().replace('<', '&#60;').replace('>', '&#62;') ?? '';
  }

  generateRegexForRestrictionWordLists(lists: string[]): string {
    return `^(?!^\\b(?:${lists.join('|')})\\b\\s*$)`;
  }

  agGridDateFilterComparator(filterLocalDateAtMidnight: Date, cellValue: any): number {
    if (!cellValue || typeof cellValue !== 'string') {
      return 0;
    }

    const cellDate = new Date(new Date(cellValue).setHours(0, 0, 0, 0));

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }

  getFilenameFromHeaderContentDisposition(contentDisposition: string | null): string {
    if (!contentDisposition || !contentDisposition.includes('filename')) {
      return '';
    }
    return contentDisposition
      .split(';')[1]
      .split(/filename(.*)/s)[1]
      .split('=')[1]
      .replace(/"/g, '')
      .trim();
  }

  /**
   * Checks if a string is valid JSON
   * @param  {string} str - The string to be checked
   * @return {[boolean, any]} - An array containing: 0:the result of the check, 1:the parsed JSON object (if successful) - or original string (if not)
   */
  validateJSON(str: string): [boolean, any] {
    try {
      const result = JSON.parse(str);
      return [true, result];
    } catch (error) {
      return [false, str];
    }
  }

  getValuesFromRange(input: string): string[] | null {
    const numbers = input.match(/\d+(\.\d+)?(a)?/g);
    if (numbers?.length) {
      let lowerNumber = numbers[0];
      let upperNumber = numbers[1];

      if (input.startsWith('-')) {
        lowerNumber = `-${lowerNumber}`;
      }

      if (numbers.length === 1) {
        return [lowerNumber];
      }

      if (/--|- -/.test(input)) {
        upperNumber = `-${upperNumber}`;
      }
      return [lowerNumber, upperNumber];
    } else {
      return null;
    }
  }

  getRangeFromValues(values: string[]): string {
    if (!values || !values.length) {
      return '';
    }
    const filteredValues = values.filter(value => !!value);
    if (!filteredValues.length) {
      return '';
    }
    if (filteredValues.length === 1) {
      return filteredValues[0];
    } else {
      filteredValues.sort((firstValue, secondValue) => {
        return Number(firstValue.replace(PricingUnits.Area, '')) - Number(secondValue.replace(PricingUnits.Area, ''));
      });
      const [smallestValue, largestValue] = [filteredValues[0], filteredValues[filteredValues.length - 1]];
      return smallestValue === largestValue ? `${smallestValue}` : `${smallestValue} - ${largestValue}`;
    }
  }

  calculatePricing(values: [string, string] | string[], isMinus?: boolean): string {
    const hasArea: boolean = JSON.stringify(values).includes(PricingUnits.Area);
    const numValues: number[] = [
      ...values.map(value =>
        value && Number(value.replaceAll(PricingUnits.Area, '')) ? Number(value.replaceAll(PricingUnits.Area, '')) : 0,
      ),
    ];
    let sumOfValues = String(numValues[0] + numValues[1] * (isMinus ? -1 : 1));
    if (sumOfValues.includes('.') && sumOfValues.split('.')[1].length > 5) {
      sumOfValues = String(this.roundValue(Number(sumOfValues)));
    }
    return `${sumOfValues}${hasArea ? PricingUnits.Area : ''}`;
  }

  getValueAggregate(values: string[], isMinus = false): string {
    if (!values.length) {
      return '';
    }
    if (values.length === 1) {
      return values[0];
    }
    const firstMaturityArray: string[] = [];
    const secondMaturityArray: string[] = [];
    values.forEach(value => {
      const rangeMaturity = this.getValuesFromRange(value);
      if (rangeMaturity?.length === 2) {
        firstMaturityArray.push(rangeMaturity[0]);
        secondMaturityArray.push(rangeMaturity[1]);
      } else {
        firstMaturityArray.push(value);
        secondMaturityArray.push(value);
      }
    });
    const firstRangePricing = this.calculatePricing(firstMaturityArray, isMinus);
    const secondRangePricing = this.calculatePricing(secondMaturityArray, isMinus);
    return this.getRangeFromValues([firstRangePricing, secondRangePricing]);
  }

  private hasRichTextMentions(richTextValue: any[]): boolean {
    for (const value of richTextValue) {
      if (value.type === 'mention') {
        return true;
      }

      if (value.children) {
        return this.hasRichTextMentions(value.children);
      }
    }
    return false;
  }

  private getRichTextContentText(richTextValue: any[]): string {
    return richTextValue
      .map(value => {
        if (value.children) {
          return this.getRichTextContentText(value.children);
        }
        return value.text ?? '';
      })
      .join('');
  }

  isRichTextValueEmpty(richTextValue?: RichText | null): boolean {
    if (richTextValue) {
      if (this.hasRichTextMentions(richTextValue)) {
        return false;
      }
      return !this.getRichTextContentText(richTextValue).length;
    }
    return true;
  }

  formatFractionsInString(value: string): string {
    return value.replace(FRACTION_REGEX_PATTERN, this.convertFractionToUnicode);
  }

  private convertFractionToUnicode(fraction: string) {
    if (!fraction.match(FRACTION_REGEX_PATTERN)) {
      return fraction;
    }

    if (fractionUnicode[fraction]) {
      return fractionUnicode[fraction];
    }

    let formattedFraction = '';
    const splitFraction = fraction.split('/');

    [...splitFraction[0]].forEach(number => (formattedFraction += superscriptNumbers[number]));
    formattedFraction += slashUnicode;
    [...splitFraction[1]].forEach(number => (formattedFraction += subscriptNumbers[number]));

    return formattedFraction;
  }

  roundValue(value: number): number {
    return parseFloat(value.toFixed(5));
  }

  getScrollableElement(element: HTMLElement): HTMLElement | null {
    if (element.nodeName === 'MAIN') {
      return element;
    }
    return element.parentElement ? this.getScrollableElement(element.parentElement) : null;
  }

  isFixed(basis: string): boolean {
    return basis.toLowerCase().includes('fixed');
  }
}
