import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@morpho/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { IoService, TabEventName } from '../../elements/services/io.service';
import { ApiService } from '../api/api.service';

export interface AuthError {
  name: string;
  message: string;
  status: number;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly resetUrl = '/reset';

  private authenticatedSubject: ReplaySubject<boolean> = new ReplaySubject();
  get authenticated$(): Observable<boolean> {
    return this.authenticatedSubject.asObservable();
  }

  private _errorsSubject: ReplaySubject<HttpErrorResponse> = new ReplaySubject();
  get errors$(): Observable<HttpErrorResponse> {
    return this._errorsSubject.asObservable();
  }

  constructor(
    private apiService: ApiService,
    private ioService: IoService,
  ) {
    if (this.isResettingSession()) {
      this.resetSession();
      return;
    }

    this.ioService.monitorTabEvent(TabEventName.Logout).subscribe(() => {
      this.goToLoginPage(true);
    });
  }

  goToApp() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const url = this.getSafeRedirectUrl(params.redirect);
    if (url) {
      window.location.href = url;
    } else {
      window.location.href = window.location.origin + `${environment.appBaseHref}/`.replace('//', '/');
    }
  }

  private getSafeRedirectUrl(url?: string): string | null {
    if (!url) {
      return null;
    }
    const decodedUrl = decodeURIComponent(url);
    const isSafe = /^[a-zA-Z0-9-_/?&=]+$/.test(decodedUrl);

    return isSafe && decodedUrl.startsWith('/') ? decodedUrl : null;
  }

  setAuthorized() {
    this.authenticatedSubject.next(true);
    this.authenticatedSubject.complete();
  }
  notifyError(error: any) {
    this._errorsSubject.next(error);
  }

  private isResettingSession(): boolean {
    const resetPath = `${environment.appBaseHref === '/' ? '' : environment.appBaseHref}${this.resetUrl}`;
    return window.location.pathname === resetPath;
  }

  goToLoginPage(isLogout = false) {
    let path = `${environment.appBaseHref}/login`.replace('//', '/');
    if (!isLogout) {
      const href = `${window.location.href.slice(window.location.origin.length)}`;
      if (window.location.href.includes('action=contact')) {
        path += href;
      } else if (href !== '/') {
        path += `?redirect=${href}`;
      }
    }
    window.location.href = path;
  }

  private goToBasePage() {
    window.location.href = window.location.origin + environment.appBaseHref;
  }

  refreshLogin() {
    this.goToBasePage();
  }

  async logout() {
    const endpoint = 'auth/logout/';
    this.apiService.post(endpoint).subscribe(() => {
      this.ioService.emitTabEvent(TabEventName.Logout);
      this.goToLoginPage(true);
    });
  }

  resetSession() {
    this.ioService.clearCookies();
    this.clearStorage();
    this.logout();
  }

  private logoutBankangle(): Promise<void> {
    return new Promise(resolve => {
      const iframe = document.createElement('iframe');
      iframe.src = `/auth/logout/`;
      iframe.onload = () => {
        resolve();
      };
      document.body.appendChild(iframe);
    });
  }

  private deleteTokens(): Promise<void> {
    return new Promise(async resolve => {
      await this.logoutBankangle();
      resolve();
    });
  }

  private clearStorage() {
    localStorage.clear();
  }
}
