import { Component, HostBinding, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreApiService, DATE_FORMAT, IoService, PopupService, RoutingService, UtilService } from '@morpho/core';
import { ElementValueType } from '@morpho/dynamic-form';
import * as moment from 'moment';
import { BooleanPopupParams, ButtonAction, statusClassMap } from '../../models/dialog.model';

@Component({
  standalone: false,
  selector: 'om-boolean-popup',
  templateUrl: './boolean-popup.component.html',
  styleUrls: ['./boolean-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BooleanPopupComponent {
  @HostBinding('class.is-loading') isLoading = false;
  readonly elementValueType = ElementValueType;

  data: BooleanPopupParams;
  isBooleanPopup = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: BooleanPopupParams,
    public dialogRef: MatDialogRef<BooleanPopupComponent>,
    private coreApiService: CoreApiService,
    private ioService: IoService,
    private popupService: PopupService,
    private routingService: RoutingService,
    private utilService: UtilService,
  ) {
    this.data = JSON.parse(JSON.stringify(data));

    if (this.data.events) {
      this.data.events = this.data.events?.map(event => {
        if (event.datetime) {
          event.description += ` (as of ${moment(event.datetime).format(DATE_FORMAT)})`;
        }
        return event;
      });
    }

    if (this.data.action?.status) {
      this.data.action.class = statusClassMap[this.data.action.status];
    }
    if (this.data.left_action?.status) {
      this.data.left_action.class = statusClassMap[this.data.left_action.status];
    }
    if (this.data.right_action?.status) {
      this.data.right_action.class = statusClassMap[this.data.right_action.status];
    }

    this.isBooleanPopup = this.data.type === 'boolean_modal';
  }

  onAction(action: ButtonAction | undefined, isConfirmButton = false) {
    if (!(action?.method && action?.url)) {
      this.dialogRef.close(isConfirmButton);
      return;
    }

    this.isLoading = true;

    const requestOptions = {
      ...action.request_options,
      observe: 'response',
    };

    this.coreApiService.callApi(action.url, action.request_data, action.method, requestOptions).subscribe({
      next: response => {
        if ((response?.body as Blob)?.size) {
          const fileName = this.utilService.getFilenameFromHeaderContentDisposition(
            response.headers.get('content-disposition'),
          );

          this.ioService.downloadFile(response.body, fileName);
        }

        if (action.success_url) {
          this.routingService.routeTo(action.success_url);
        }
        this.isLoading = false;
        this.dialogRef.close(isConfirmButton);
        if (action.success_message) {
          this.popupService.message(action.success_message);
        }
      },
      error: async error => {
        this.isLoading = false;
        action.error_message ? this.popupService.error(action.error_message) : this.popupService.apiError(error);
      },
    });
  }
}
