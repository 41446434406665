import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopupService, UtilService } from '@morpho/core';
import { DialogService } from '@morpho/dialog';
import { DynamicFormModalParams } from '@morpho/dynamic-form';
import { FormInputConfig } from '@morpho/form';
import { CoreApiService } from 'apps/bankangle/src/app/core/services/core-api.service';
import { SavedView, SavedViewIdentifier } from 'apps/bankangle/src/app/models/saved-view';
import { EMPTY, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { StateService } from '../../../core/services/state.service';
import { DealerColleaguesResponse } from '../../../features/dealers/model/dealers.model';
import { FlexibleButton } from '../flexible-button-list/flexible-button-list.component';

@Component({
  standalone: false,
  selector: 'om-saved-views',
  templateUrl: './saved-views.component.html',
})
export class SavedViewsComponent {
  @Input()
  get identifier(): SavedViewIdentifier {
    return this._identifier;
  }
  set identifier(identifier: SavedViewIdentifier) {
    if (!identifier) {
      return;
    }
    this._identifier = identifier;
    this.getSavedViews();
  }
  private _identifier: SavedViewIdentifier;

  @Input()
  get selectedSavedView(): SavedView {
    return this._selectedSavedView;
  }
  set selectedSavedView(savedView: SavedView) {
    this._selectedSavedView = savedView;
    this.hashView('modified');
  }
  private _selectedSavedView: SavedView;

  @Input() name = 'View';

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(readonly: boolean) {
    this._readonly = coerceBooleanProperty(readonly);
  }
  private _readonly: boolean;

  @Output() selectView: EventEmitter<SavedView> = new EventEmitter();
  @Output() newView: EventEmitter<void> = new EventEmitter();
  @Output() deleteView: EventEmitter<void> = new EventEmitter();
  @Output() viewsLoaded: EventEmitter<SavedView[]> = new EventEmitter();

  isDealer$: Observable<boolean | undefined> = this.stateService.get.authenticatedUser$.pipe(
    map(user => user.isDealer),
  );

  savedViews: SavedView[] = [];

  selectedViewOriginalHash: string;
  selectedViewModifiedHash: string;

  savedViewsButtonList: FlexibleButton[] = [];

  constructor(
    private coreApiService: CoreApiService,
    private dialogService: DialogService,
    private popupService: PopupService,
    private stateService: StateService,
    private utilService: UtilService,
  ) {}

  private getSavedViews() {
    this.coreApiService.getSavedViews(this.identifier).subscribe(savedViews => {
      this.savedViews = savedViews;
      this.updateSavedViewsButtonList();
      this.viewsLoaded.emit(this.savedViews);
    });
  }

  onNewView(): void {
    this.newView.emit();
  }

  onSelectView(view: SavedView, emitChanges = true) {
    this.selectedSavedView = view;
    this.hashView();
    if (emitChanges) {
      this.selectView.emit(view);
    }
  }

  private hashView(toSave?: 'original' | 'modified', view?: SavedView) {
    const hash = this.utilService.hash(view ?? this.selectedSavedView ?? {});
    if (!toSave || toSave === 'original') {
      this.selectedViewOriginalHash = hash;
    }
    if (!toSave || toSave === 'modified') {
      this.selectedViewModifiedHash = hash;
    }
  }

  onSaveView(): void {
    if (!this.selectedSavedView) {
      return;
    }

    if (!this.selectedSavedView.name) {
      this.getViewName().subscribe(name => {
        if (name) {
          const view: SavedView = { ...this.selectedSavedView, name };
          this.updateSavedView(view);
        }
      });
    } else {
      this.updateSavedView(this.selectedSavedView);
    }
  }

  onSaveAsNewView(): void {
    if (!this.selectedSavedView) {
      return;
    }

    this.getViewName().subscribe(name => {
      if (name) {
        const view: SavedView = { ...this.selectedSavedView, name, id: undefined };
        this.updateSavedView(view);
      }
    });
  }

  onShareView(): void {
    if (this.selectedSavedView.id === undefined) {
      return;
    }
    this.coreApiService
      .getDealerColleagues(this.selectedSavedView.id)
      .pipe(
        mergeMap((colleagues: DealerColleaguesResponse[][]) => {
          if (!colleagues.length) {
            this.popupService.error(`No users available to share view with`);
            return EMPTY;
          }
          const formOptions: FormInputConfig[] = colleagues.reduce((mapped, team) => {
            if (!team.length) {
              return mapped;
            }
            const teamConfig: FormInputConfig = {
              name: team[0].team,
              label: team[0].team,
              element: 'autocomplete',
              options: team.map(colleague => ({
                value: colleague.id,
                label: colleague.name,
              })),
              multiple: true,
            };
            return [...mapped, teamConfig];
          }, [] as FormInputConfig[]);
          const params: DynamicFormModalParams = {
            title: 'Share View',
            options: formOptions,
            text: 'Share',
          };

          return this.dialogService.dynamicFormModal(params).pipe(
            mergeMap(formResponse => {
              const sharedWith: number[] = (Object.values(formResponse?.formValue ?? {}) as number[][]).reduce(
                (prev, current) => prev.concat(current),
                [],
              );
              return sharedWith.length && this.selectedSavedView.id !== undefined
                ? this.coreApiService.shareSavedView(this.selectedSavedView.id, sharedWith)
                : of();
            }),
          );
        }),
      )
      .subscribe(apiResponse => {
        if (apiResponse) {
          this.popupService.message(apiResponse);
        }
      });
  }

  onRenameView(): void {
    this.getViewName(this.selectedSavedView.name).subscribe(name => {
      if (name) {
        const view: SavedView = { id: this.selectedSavedView.id, name } as SavedView;
        this.coreApiService.updateSavedView(view).subscribe(updatedSavedView => {
          this.savedViews = this.savedViews.map(savedView =>
            savedView.id === updatedSavedView.id ? { ...savedView, name: updatedSavedView.name } : savedView,
          );
          this.updateSavedViewsButtonList();
          this.popupService.message('View renamed successfully');
        });
      }
    });
  }

  onDeleteView(): void {
    if (!this.selectedSavedView?.id) {
      return;
    }
    this.coreApiService.deleteSavedView(this.selectedSavedView.id).subscribe(() => {
      this.savedViews = this.savedViews.filter(savedView => savedView.id !== this.selectedSavedView?.id);
      this.updateSavedViewsButtonList();
      this.deleteView.emit();
      this.popupService.message('View deleted successfully');
    });
  }

  onSaveViewSelectedChange(selectedButton: FlexibleButton) {
    const selectedSavedView = this.savedViews.find(savedView => savedView.name == selectedButton.name);
    if (selectedSavedView) {
      this.selectedSavedView = selectedSavedView;
      this.selectView.emit(this.selectedSavedView);
    }
  }

  private updateSavedView(view: SavedView) {
    if (view.id) {
      this.coreApiService.updateSavedView(view).subscribe(updatedSavedView => {
        this.savedViews = this.savedViews.map(savedView =>
          savedView.id === updatedSavedView.id ? updatedSavedView : savedView,
        );
        this.updateSavedViewsButtonList();
        this.hashView('original', updatedSavedView);
        this.popupService.message('View updated successfully');
      });
    } else {
      this.coreApiService.addSavedView(view).subscribe(savedView => {
        this.savedViews.push(savedView);
        this.onSelectView(savedView, false);
        this.hashView('original', savedView);
        this.updateSavedViewsButtonList();
        this.popupService.message('View saved successfully');
      });
    }
  }

  getViewName(name?: string): Observable<string | null> {
    const params: DynamicFormModalParams = {
      title: name ? 'Rename View' : 'Save View',
      options: [
        {
          element: 'primitive',
          type: 'text',
          initial: name,
          name: 'name',
          label: 'Name',
          required: true,
        },
      ],
      text: 'Save',
    };

    return this.dialogService.dynamicFormModal(params).pipe(map(response => response?.formValue?.name || null));
  }

  updateSavedViewsButtonList() {
    this.savedViewsButtonList = this.savedViews.map(savedView => {
      const button: FlexibleButton = {
        name: savedView.name || '',
      };
      return button;
    });
  }
}
