import { Component, EventEmitter, HostListener, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ListInputConfig, TableInputConfig } from '../../models/form.model';
import { DateInputComponent } from '../date-input/date-input.component';
import { PrimitiveInputComponent } from '../primitive-input/primitive-input.component';
import { SelectInputComponent } from '../select-input/select-input.component';

type ListInputValue = number | string | undefined;
type ListInputType = ListInputConfig['type'] | null;
type TableInputType = TableInputConfig['type'];
type TableInputValue = string | number | moment.Moment | undefined;

@Component({
  standalone: false,
  selector: 'om-basic-input-container',
  templateUrl: './basic-input-container.component.html',
})
export class BasicInputContainerComponent {
  @Input() type: ListInputType | TableInputType;
  @Input() isEdit: boolean;
  @Input() isTable: boolean;
  @Input() isButtonDisabled: boolean;
  @Input() hideButton: boolean;
  @Input() context: any;
  @Input() suffixIconButton: string;
  @Input() suffixIconButtonClass: string;

  @Input() item: ListInputValue | TableInputValue;

  @Output() controlValueChange = new EventEmitter<ListInputValue | TableInputValue>();
  @Output() itemChange = new EventEmitter<ListInputValue | TableInputValue>();

  @Output()
  onSubmit = new EventEmitter<ListInputValue | TableInputValue>();

  @Output()
  onClick = new EventEmitter<ListInputValue | TableInputValue>();

  @ViewChildren(PrimitiveInputComponent) primitiveInputs: QueryList<PrimitiveInputComponent>;
  @ViewChildren(DateInputComponent) dateInputs: QueryList<DateInputComponent>;
  @ViewChildren(SelectInputComponent) selectInputs: QueryList<SelectInputComponent>;

  onValueChange(value: any): void {
    this.item = value;
    this.controlValueChange.emit(this.item);
    this.itemChange.emit(this.item);
    if (this.type === 'date' || this.type === 'date-no-year' || this.type === 'select') {
      this.onSubmit.emit(this.item);
    }
  }

  onClickSuffixButton(): void {
    this.onClick.emit();
  }

  @HostListener('keydown.enter', ['$event'])
  onEnterPressed(event: KeyboardEvent) {
    this.onSubmit.emit(this.item);
  }

  @HostListener('focusout') outFocus() {
    this.onSubmit.emit(this.item);
  }

  public focus(): void {
    [this.primitiveInputs, this.selectInputs, this.dateInputs].some(inputs => {
      const found = [...inputs][0];
      found?.focus();
      return !!found;
    });
  }
}
