import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UtilService } from '../../elements/services/util.service';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { callApiUrlsToCache } from './contants';
import { HttpMethod } from './http.enum';
import { HttpRequestCache } from './request-cache-decorator';

@Injectable({
  providedIn: 'root',
})
export class CoreApiService {
  constructor(
    private apiService: ApiService,
    private utilService: UtilService,
    private cacheService: CacheService,
  ) {}
  private readonly refreshSubject = new Subject<void>();

  @HttpRequestCache<CoreApiService>(function () {
    return {
      storage: this.cacheService,
      refreshSubject: this.refreshSubject,
      shouldCache: (...args: any[]) => {
        const [path, body] = args;
        return callApiUrlsToCache.some(regex => path.match(regex));
      },
    };
  })
  callApi(path: string, body: any, method?: HttpMethod | undefined, options?: any): Observable<any> {
    path = this.utilService.convertApiEndpoint(path);
    method = (method?.toLocaleLowerCase() as HttpMethod) || HttpMethod.POST;
    return this.apiService[method]
      ? this.apiService[method](path, body, options)
      : this.apiService.post(path, body, options);
  }

  @HttpRequestCache<CoreApiService>(function () {
    return {
      storage: this.cacheService,
      refreshSubject: this.refreshSubject,
      shouldCache: () => true,
    };
  })
  stringify(value: any): Observable<string> {
    return this.apiService.post('json/stringify/', { value });
  }

  refreshData(): void {
    this.refreshSubject.next();
  }
}
