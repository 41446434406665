import { Injectable } from '@angular/core';
import { ApiService, NotificationsContainer, UtilService } from '@morpho/core';
import { PaginatedNotificationsContainer } from 'apps/bankangle/src/app/features/notifications/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  constructor(
    private apiService: ApiService,
    private utilService: UtilService,
  ) {}

  getNotifications(): Observable<NotificationsContainer> {
    const endpoint = 'notifications/notifications/';
    return this.apiService.get(endpoint).pipe(
      map((response: NotificationsContainer) => {
        response.auth_notifications = response.auth_notifications.map(notification => {
          return {
            ...notification,
            accept_url: notification.accept_url ? this.utilService.convertApiEndpoint(notification.accept_url) : null,
            decline_url: notification.decline_url
              ? this.utilService.convertApiEndpoint(notification.decline_url)
              : null,
          };
        });
        return response;
      }),
    );
  }

  getAllNotifications(limit: number, offset: number): Observable<PaginatedNotificationsContainer> {
    const endpoint = 'notifications/all-notifications/';
    const params = {
      limit,
      offset,
    };
    return this.apiService.get(endpoint, params);
  }

  markNotificationAsRead(notificationId: number): Observable<Response> {
    const endpoint = `notifications/notifications/${notificationId}/mark_as_read/`;
    return this.apiService.post(endpoint);
  }

  markStandardNotificationsAsRead() {
    const endpoint = 'notifications/notifications/mark_all_as_read/';
    const body = {
      only_non_authorization_related_message: true,
    };
    return this.apiService.post(endpoint, body);
  }

  markStandardNotificationsAsSeen(): Observable<Response> {
    const endpoint = 'notifications/notifications/mark_all_as_seen/';
    return this.apiService.post(endpoint);
  }

  markAuthNotificationsAsRead() {
    const endpoint = 'notifications/notifications/mark_all_as_read/';
    const body = {
      only_authorization_related_message: true,
    };
    return this.apiService.post(endpoint, body);
  }

  respondToAuthRequest(endpoint: string): Observable<Response> {
    return this.apiService.post(endpoint);
  }
}
