import { Injectable } from '@angular/core';
import { PopupService } from '@morpho/core';
import { DynamicFormModel, DynamicFormService } from '@morpho/dynamic-form';
import { ERROR_ON_FORM, FormInputConfig } from '@morpho/form';
import { Observable, interval, mergeMap, of, switchMap, takeWhile, tap } from 'rxjs';
import { UploadedDocumentInstance } from '../document-extractor.model';
import { DocumentExtractorApiService } from './document-extractor-api.service';

export interface PollConfig {
  interval: number;
  maxTry: number;
  onMaxTry: Function;
}
@Injectable({
  providedIn: 'root',
})
export class DocumentExtractorService {
  constructor(
    private documentExtractorApiService: DocumentExtractorApiService,
    private dynamicFormService: DynamicFormService,
    private popupService: PopupService,
  ) {}

  pollForDocumentReady(id: number, config: PollConfig): Observable<UploadedDocumentInstance> {
    const pollInterval$ = interval(config.interval);

    return pollInterval$.pipe(
      tap((n: number) => {
        if (n === config.maxTry) {
          config.onMaxTry();
        }
      }),
      takeWhile((n: number) => {
        return n < config.maxTry;
      }),
      mergeMap(() => this.documentExtractorApiService.getUploadDocument(id)),
    );
  }

  submitForm(id: number, formModel: DynamicFormModel): Observable<{ [key: string]: any } | null> {
    if (!formModel?.form.valid) {
      this.popupService.error(ERROR_ON_FORM);
      return of(null);
    }
    return this.dynamicFormService.getValueToSubmit(formModel).pipe(
      switchMap(postValues => {
        return this.documentExtractorApiService.createTrade(id, postValues);
      }),
    );
  }

  orderFormInputs(formModel: DynamicFormModel): DynamicFormModel {
    return {
      ...formModel,
      sectionsForDisplay: formModel?.sectionsForDisplay.map(section => {
        return {
          ...section,
          fieldNames: section.fieldNames
            .map(fieldName => {
              const fieldConfig = formModel?.fieldMapping[fieldName];
              if (fieldConfig.low_confidence) {
                return { fieldName, importance: 1 };
              } else if (fieldConfig.required) {
                if (
                  fieldConfig.initial == null &&
                  (!fieldConfig['options' as keyof FormInputConfig]?.length ||
                    fieldConfig['options' as keyof FormInputConfig].length > 1)
                ) {
                  return { fieldName, importance: 0 };
                }
                return { fieldName, importance: 2 };
              }
              return { fieldName, importance: 2 };
            })
            .sort((a, b) => a.importance - b.importance)
            .map(sorted => sorted.fieldName),
        };
      }),
    };
  }

  orderFormInputsBasedOnInitialOrdering(
    formModel: DynamicFormModel,
    initialSectionOrdering: Record<string, string[]>,
  ): DynamicFormModel {
    return {
      ...formModel,
      sectionsForDisplay: formModel.sectionsForDisplay.map(section => {
        let fieldRef: string;
        const unorderedFields: string[] = [];
        const groups: Record<string, string[]> = {};
        const orderRef = initialSectionOrdering[section.name];

        section.fieldNames.forEach(field => {
          if (orderRef.includes(field)) {
            fieldRef = field;
            groups[fieldRef] = [field];
          } else if (fieldRef) {
            groups[fieldRef].push(field);
          } else {
            unorderedFields.push(field);
          }
        });

        const orderedFields = orderRef.filter(field => groups[field]).flatMap(field => groups[field]);

        return {
          ...section,
          fieldNames: [...unorderedFields, ...orderedFields],
        };
      }),
    };
  }

  setLowConfidenceFields(formModel: DynamicFormModel): DynamicFormModel {
    const newFieldMapping = formModel.sectionsForDisplay.reduce(
      (acc, section) => {
        section.fieldNames.forEach(fieldName => {
          acc[fieldName] = {
            ...formModel.fieldMapping[fieldName],
            low_confidence: this.determineLowConfidenceForField(formModel.fieldMapping[fieldName]),
          };
        });
        return acc;
      },
      { ...formModel.fieldMapping },
    );
    return { ...formModel, fieldMapping: newFieldMapping };
  }

  determineLowConfidenceForField(fieldConfig: any): boolean {
    if (fieldConfig.low_confidence) {
      return (fieldConfig.required && fieldConfig.initial) || !fieldConfig.required;
    }
    return !fieldConfig.initial && fieldConfig.options?.length === 1;
  }
}
