import { Pipe, PipeTransform } from '@angular/core';
import { getCdnUrl } from '../../helper.functions';

@Pipe({
  standalone: false,
  name: 'countryFlag',
})
export class CountryFlagPipe implements PipeTransform {
  transform(countryCode: string): string {
    return getCdnUrl(`assets/img/flags/${countryCode?.toLowerCase()}.svg`);
  }
}
