import { AgGridPanel, ColumnSidebarConfig, FilterSidebarConfig, GroupSidebarConfig } from './ag-grid-panels.model';

export const agGridColumnSidebar: ColumnSidebarConfig = {
  id: AgGridPanel.ColumnSideBar,
  labelDefault: 'Settings',
  labelKey: 'column',
  iconKey: 'menu',
  toolPanel: 'omColumnSidebar',
};

export const agGridFilterSidebar: Partial<FilterSidebarConfig> = {
  id: AgGridPanel.FilterSideBar,
  labelDefault: 'Filter By',
  labelKey: 'filter',
  iconKey: 'menu',
  toolPanel: 'omFilterSidebar',
};

export const agGridGroupSidebar: Partial<GroupSidebarConfig> = {
  id: AgGridPanel.GroupSideBar,
  labelDefault: 'Group By',
  labelKey: 'group',
  iconKey: 'menu',
  toolPanel: 'omGroupSidebar',
};
