import { Editor, Path, Transforms } from 'slate';
import { EMPTY_TEXT, ElementType } from '../../constants/rich-text.constants';
import { RichTextEquation } from '../../models/rich-text.model';

function createEquationElement(content: string): RichTextEquation {
  const equationElement: RichTextEquation = {
    type: ElementType.Equation,
    content,
    children: [EMPTY_TEXT],
  };

  return equationElement;
}

//* Note: equation input to be LaTeX format
function cleanEquationContent(equation?: string | null): string {
  if (!equation) {
    return '';
  }
  // replace symbols
  // add whitespace to the end of commands so that following chars don't interfere
  //   e.g. (≠a) should be (\neq a), not (\neqa)

  return (equation ?? '')
    .replace(/[\r\n\t\s]/g, '')
    .replaceAll('≠', '\\neq ')
    .replaceAll('×', '\\times ')
    .replaceAll('*', '\\times ')
    .replaceAll('÷', '\\div ')
    .replaceAll('/', '\\div ')
    .replaceAll('±', '\\pm ')
    .replaceAll('∂', '\\partial ')
    .replaceAll('∞', '\\infty ');
}

function insertEquation(editor: Editor, content: string) {
  const equationElement = createEquationElement(content);
  Transforms.insertNodes(editor, equationElement);
}

function updateEquation(editor: Editor, content: string, path: Path) {
  const props: Partial<RichTextEquation> = { content };
  Transforms.setNodes(editor, props, { at: path });
}

export const EquationEditor = {
  cleanEquationContent,
  createEquationElement,
  insertEquation,
  updateEquation,
};
