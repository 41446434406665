import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  standalone: false,
  selector: 'om-tab-control',
  templateUrl: './tab-control.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TabControlComponent {
  @Input() tabGroup: MatTabGroup;

  previousSlide() {
    if (!this.tabGroup.selectedIndex && this.tabGroup.selectedIndex !== 0) {
      return;
    }

    if (this.tabGroup.selectedIndex === 0) {
      this.tabGroup.selectedIndex = this.tabGroup._tabs.length - 1;
    } else {
      this.tabGroup.selectedIndex = this.tabGroup.selectedIndex - 1;
    }
  }

  nextSlide() {
    if (!this.tabGroup.selectedIndex && this.tabGroup.selectedIndex !== 0) {
      return;
    }
    if (this.tabGroup.selectedIndex === this.tabGroup._tabs.length - 1) {
      this.tabGroup.selectedIndex = 0;
    } else {
      this.tabGroup.selectedIndex = this.tabGroup.selectedIndex + 1;
    }
  }
}
