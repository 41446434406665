import {
  CALLABLE_POSTFIX,
  ChartLegendConfig,
  PRICING_POSTFIX,
  PRIMARY_POSTFIX,
  SECONDARY_POSTFIX,
} from '@shared-echarts/models';
import { Curve, CurveType } from 'apps/bankangle/src/app/models/curve.model';
import { EChartsOption } from 'echarts';
import { SyndicatePricerChartItem } from '../../features/syndicate-pricer/models/comparable.model';
import { NewIssueCurveType } from '../../features/syndicate-pricer/models/pricing-request.model';

export const MAXIMUM_CHART_ITEMS = 10;

export const baseChartOption: EChartsOption = {
  legend: {
    show: false,
  },
  grid: {
    right: 50,
    left: 80,
    top: 50,
    bottom: 70,
  },
  xAxis: {
    nameLocation: 'middle',
    nameGap: 35,
  },
  yAxis: {
    nameLocation: 'middle',
    nameGap: 45,
  },
  tooltip: {
    trigger: 'axis',
    confine: true,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
};

export const curveAnalysisLegendConfig: ChartLegendConfig<Curve> = {
  items: [],
  itemKey: 'identifier',
  itemType: 'curve',
  initialLegendSelection: {},
  getItemName: (curve: Curve) => `${curve.issuing_entity.issuer.company_name} - ${curve.identifier}`,
  getItemLayers: (curve: Curve) => ['', curve.curve_type === CurveType.SECONDARY ? SECONDARY_POSTFIX : PRIMARY_POSTFIX],
};

export const syndicatePricerLegendConfig: ChartLegendConfig<SyndicatePricerChartItem> = {
  items: [],
  itemKey: 'id',
  itemType: 'syndicatePricer',
  initialLegendSelection: {},
  getItemName: (item: SyndicatePricerChartItem) => item.id,
  getItemLayers: (item: SyndicatePricerChartItem) => [
    '',
    ...(item.data.type === NewIssueCurveType.Spread ? [PRICING_POSTFIX, CALLABLE_POSTFIX] : [SECONDARY_POSTFIX]),
  ],
};
