import { NotificationsContainer } from '@morpho/core';
import { createAction, props } from '@ngrx/store';

const _GET_NOTIFICATIONS = '[Notifications] Get Notifications';
const GET_NOTIFICATIONS = _GET_NOTIFICATIONS;
const GET_NOTIFICATIONS_SUCCESS = `${_GET_NOTIFICATIONS} SUCCESS`;
const GET_NOTIFICATIONS_FAIL = `${_GET_NOTIFICATIONS} FAIL`;

const _MARK_NOTIFICATION_AS_READ = '[Notifications] Mark Notification as read';
const MARK_NOTIFICATION_AS_READ = _MARK_NOTIFICATION_AS_READ;
const MARK_NOTIFICATION_AS_READ_SUCCESS = `${_MARK_NOTIFICATION_AS_READ} SUCCESS`;
const MARK_NOTIFICATION_AS_READ_FAIL = `${_MARK_NOTIFICATION_AS_READ} FAIL`;

const _MARK_STANDARD_NOTIFICATIONS_AS_READ = '[Notifications] Mark Standard Notifications as read';
const MARK_STANDARD_NOTIFICATIONS_AS_READ = _MARK_STANDARD_NOTIFICATIONS_AS_READ;
const MARK_STANDARD_NOTIFICATIONS_AS_READ_SUCCESS = `${_MARK_STANDARD_NOTIFICATIONS_AS_READ} SUCCESS`;
const MARK_STANDARD_NOTIFICATIONS_AS_READ_FAIL = `${_MARK_STANDARD_NOTIFICATIONS_AS_READ} FAIL`;

const _MARK_STANDARD_NOTIFICATIONS_AS_SEEN = '[Notifications] Mark Standard Notifications as seen';
const MARK_STANDARD_NOTIFICATIONS_AS_SEEN = _MARK_STANDARD_NOTIFICATIONS_AS_SEEN;
const MARK_STANDARD_NOTIFICATIONS_AS_SEEN_SUCCESS = `${_MARK_STANDARD_NOTIFICATIONS_AS_SEEN} SUCCESS`;
const MARK_STANDARD_NOTIFICATIONS_AS_SEEN_FAIL = `${_MARK_STANDARD_NOTIFICATIONS_AS_SEEN} FAIL`;

const _MARK_AUTH_NOTIFICATIONS_AS_READ = '[Notifications] Mark Auth Notifications as read';
const MARK_AUTH_NOTIFICATIONS_AS_READ = _MARK_AUTH_NOTIFICATIONS_AS_READ;
const MARK_AUTH_NOTIFICATIONS_AS_READ_SUCCESS = `${_MARK_AUTH_NOTIFICATIONS_AS_READ} SUCCESS`;
const MARK_AUTH_NOTIFICATIONS_AS_READ_FAIL = `${_MARK_AUTH_NOTIFICATIONS_AS_READ} FAIL`;

const _RESPOND_TO_AUTH_REQUEST = '[Notifications] Respond to auth request';
const RESPOND_TO_AUTH_REQUEST = _RESPOND_TO_AUTH_REQUEST;
const RESPOND_TO_AUTH_REQUEST_SUCCESS = `${_RESPOND_TO_AUTH_REQUEST} SUCCESS`;
const RESPOND_TO_AUTH_REQUEST_FAIL = `${_RESPOND_TO_AUTH_REQUEST} FAIL`;

export const NotificationsEffect = {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  MARK_STANDARD_NOTIFICATIONS_AS_READ,
  MARK_STANDARD_NOTIFICATIONS_AS_SEEN,
  MARK_AUTH_NOTIFICATIONS_AS_READ,
  RESPOND_TO_AUTH_REQUEST,
};

export const NotificationsAction = {
  getNotifications: createAction(GET_NOTIFICATIONS),
  getNotificationsSuccess: createAction(GET_NOTIFICATIONS_SUCCESS, props<{ params: NotificationsContainer }>()),
  getNotificationsFail: createAction(GET_NOTIFICATIONS_FAIL),

  markNotificationAsRead: createAction(MARK_NOTIFICATION_AS_READ, props<{ params: { notificationId: number } }>()),
  markNotificationAsReadSuccess: createAction(
    MARK_NOTIFICATION_AS_READ_SUCCESS,
    props<{ params: { notificationId: number } }>(),
  ),
  markNotificationAsReadFail: createAction(MARK_NOTIFICATION_AS_READ_FAIL),

  markStandardNotificationsAsRead: createAction(MARK_STANDARD_NOTIFICATIONS_AS_READ),
  markStandardNotificationsAsReadSuccess: createAction(MARK_STANDARD_NOTIFICATIONS_AS_READ_SUCCESS),
  markStandardNotificationsAsReadFail: createAction(MARK_STANDARD_NOTIFICATIONS_AS_READ_FAIL),

  markStandardNotificationsAsSeen: createAction(MARK_STANDARD_NOTIFICATIONS_AS_SEEN),
  markStandardNotificationsAsSeenSuccess: createAction(MARK_STANDARD_NOTIFICATIONS_AS_SEEN_SUCCESS),
  markStandardNotificationsAsSeenFail: createAction(MARK_STANDARD_NOTIFICATIONS_AS_SEEN_FAIL),

  markAuthNotificationsAsRead: createAction(MARK_AUTH_NOTIFICATIONS_AS_READ),
  markAuthNotificationsAsReadSuccess: createAction(MARK_AUTH_NOTIFICATIONS_AS_READ_SUCCESS),
  markAuthNotificationsAsReadFail: createAction(MARK_AUTH_NOTIFICATIONS_AS_READ_FAIL),

  respondToAuthRequest: createAction(
    RESPOND_TO_AUTH_REQUEST,
    props<{ params: { response_url: string; notificationId: number } }>(),
  ),
  respondToAuthRequestSuccess: createAction(
    RESPOND_TO_AUTH_REQUEST_SUCCESS,
    props<{ params: { notificationId: number } }>(),
  ),
  respondToAuthRequestFail: createAction(RESPOND_TO_AUTH_REQUEST_FAIL),
};
