<div
  class="table-edit-controller"
  [class.is-hovered]="isHovered"
  [class.table-edit-controller--is-horizontal]="alignment === 'column'"
  [class.table-edit-controller--is-vertical]="alignment === 'row'"
  [id]="elementId"
>
  <button class="table-edit-controller__control">
    <div class="control__handle">
      @for (index of [0, 1, 2, 3, 4, 5]; track index) {
        <span> </span>
      }
    </div>
  </button>

  <div class="table-edit-controller__actions-container">
    @for (action of actions; track action) {
      <ul class="table-edit-controller__actions">
        <li class="table-edit-controller__action">
          <button class="mat-mdc-menu-item" (click)="action.action(elementId)">{{ action.label }}</button>
        </li>
      </ul>
    }
  </div>
</div>
