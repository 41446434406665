import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  standalone: false,
  selector: 'om-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnInit, OnDestroy {
  private ngOnDestroy$ = new Subject<void>();
  private isCarouselPaused = false;
  currentIndex = 0;

  @Input() slides: any[];
  @Input() slideComponent: any;
  @Input() speed = 5000;

  @HostListener('mouseenter')
  onMouseEnter() {
    this.isCarouselPaused = true;
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isCarouselPaused = false;
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.speed) {
      interval(this.speed)
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(() => {
          if (!this.isCarouselPaused) {
            this.nextSlide();
          }
        });
    }
  }

  setCurrentSlideIndex(index: number) {
    this.currentIndex = index;
  }

  isCurrentSlideIndex(index: number) {
    return this.currentIndex === index;
  }

  previousSlide() {
    if (!this.slides.length) {
      return;
    }
    this.currentIndex = this.currentIndex > 0 ? --this.currentIndex : this.slides.length - 1;
  }

  nextSlide() {
    this.currentIndex = this.currentIndex < this.slides.length - 1 ? ++this.currentIndex : 0;
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public onTabAnimationDone(): void {
    // An Angular bug can cause tab content to get duplicated when toggling between lazy-loaded tabs
    // so manually check for, and remove, duplicated tab content
    const inactiveTabs = this.elementRef.nativeElement.querySelectorAll(
      '.mat-tab-body-active .mat-tab-body-content om-carousel-slide:not(:first-child)',
    );

    inactiveTabs.forEach((tab: any) => {
      tab.remove();
    });
  }
}
