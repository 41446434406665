<h2 mat-dialog-title>
  Manage Comparables
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h2>

@if (isInitialized$ | async) {
  <div mat-dialog-content>
    <p>
      You can add and remove comparable <strong>Issuer Curves</strong> and <strong>Individual Bonds</strong> to your
      pricing grid from the dropdowns below.
    </p>
    <p>
      These comparables will apply to all tabs on this worksheet so be sure to select all curves. You can then filter
      for currency, seniority, etc. from the table itself.
    </p>
    <form [formGroup]="form">
      <h3>Issuer Curves</h3>
      <mat-form-field>
        <mat-label>Select Issuer(s)</mat-label>
        <om-autocomplete-input
          formControlName="issuers"
          [placeholder]="placeholder"
          multiple
          [options]="issuersOptions$ | async"
        ></om-autocomplete-input>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Select Curve(s)</mat-label>
        <om-autocomplete-input
          formControlName="curves"
          [placeholder]="placeholder"
          multiple
          [options]="curveOptions$ | async"
        ></om-autocomplete-input>
      </mat-form-field>
      <h3>Individual Bonds</h3>
      <mat-form-field>
        <mat-label>Select ISIN(s)</mat-label>
        <om-autocomplete-input
          formControlName="isins"
          [placeholder]="placeholder"
          multiple
          [options]="isinOptions$ | async"
        ></om-autocomplete-input>
      </mat-form-field>
    </form>
  </div>
} @else {
  <div mat-dialog-content>
    <om-loading></om-loading>
  </div>
}

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="onSave()">Save</button>
</div>
