import { Injectable } from '@angular/core';
import {
  DATETIME_FORMAT,
  DEFAULT_QUOTE_TYPE,
  distinctUntilChangedJson,
  distinctUntilChangedSet,
  QuoteType,
  shareLastEmitted,
} from '@morpho/core';
import { Actions, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import * as moment from 'moment-timezone';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { sortByAlphabeticalInverse } from '../../../constants/sortComparators';
import { StateService } from '../../../core/services/state.service';
import { BondPricing, SecondaryBond } from '../models/comparable.model';
import { AdditionalCurve, NewIssueCurveType, SecondaryLevels } from '../models/pricing-request.model';
import { PricingCompletionEffect } from '../state/pricing-completion/pricing-completion.actions';
import { IssuerPricingDataMap, SharedTabData } from '../state/pricing-completion/pricing-completion.model';
import { PricingCompletionSelector } from '../state/pricing-completion/pricing-completion.selectors';
import {
  getAdditionalPricingCurvesByType,
  getHistoricalSecondaryBondDates,
  getSecondaryBondsPricingMap,
  getSecondaryLevels,
  getSelectedBonds,
  updateAdditionalCurvesEffectCalled,
} from '../syndicate-pricer.functions';
import { SyndicatePricerUtilService } from './syndicate-pricer-util.service';

@Injectable({
  providedIn: 'root',
})
export class SyndicatePricerSelectorService {
  readonly currentPricingTabData$: Observable<IssuerPricingDataMap> = this.store
    .select(PricingCompletionSelector.currentPricingTabData)
    .pipe(
      filter(pricingTabData => !!pricingTabData),
      map(pricingTabData => pricingTabData ?? {}),
    );

  readonly currentSharedTabData$: Observable<SharedTabData> = this.store
    .select(PricingCompletionSelector.currentSharedTabData)
    .pipe(
      filter(sharedTabData => !!sharedTabData),
      map(sharedTabData => sharedTabData ?? { tempPricingMap: {}, pricedKeys: {} }),
    );

  readonly pricingTabDataMap$ = this.store.select(PricingCompletionSelector.pricingTabDataMap).pipe(
    filter(map => !!map),
    take(1),
  );

  readonly sharedTabDataMap$ = this.store.select(PricingCompletionSelector.sharedTabDataMap).pipe(
    filter(map => !!map),
    take(1),
  );

  readonly updatedMessage$ = this.actions$.pipe(
    ofType(PricingCompletionEffect.SAVE_PRICING_SUCCESS),
    startWith(null),
    debounceTime(0),
    switchMap(() => this.pricingTabDataMap$),
    concatLatestFrom(() => this.store.select(PricingCompletionSelector.isInputMode)),
    map(([pricingTabDataMap, isInputMode]) => {
      let updatedAt = '';
      let updatedBy = '';

      const pricingRequests = Object.values(pricingTabDataMap ?? {})
        .map(issuerPricingMap => Object.values(issuerPricingMap).map(pricingMap => pricingMap.savedPricingRequest))
        .flat();

      pricingRequests.forEach(pricingRequest => {
        if (!updatedAt || updatedAt < (pricingRequest.pricing.updated_at ?? '')) {
          updatedAt = pricingRequest.pricing.updated_at ?? '';
          updatedBy = [pricingRequest.pricing.updated_by?.first_name, pricingRequest.pricing.updated_by?.last_name]
            .filter(name => !!name)
            .join(' ');
        }
      });

      if (!updatedAt) {
        return '';
      }
      return [
        isInputMode ? 'Last saved by' : 'Submitted by',
        updatedBy,
        'on',
        moment(updatedAt).format(DATETIME_FORMAT),
      ].join(' ');
    }),
    shareLastEmitted(),
  );

  readonly pricingPageTitle$ = this.stateService.get.router$.pipe(
    mergeMap(router => {
      if (router.queryParams.group) {
        return of(router.queryParams.group);
      }

      return this.store.select(PricingCompletionSelector.issuingEntities).pipe(
        filter(issuingEntities => !!issuingEntities),
        map(issuingEntities => {
          return Object.values(issuingEntities ?? {})[0];
        }),
      );
    }),
  );

  readonly secondaryBonds$: Observable<SecondaryBond[] | undefined> = this.actions$.pipe(
    ofType(PricingCompletionEffect.GET_SECONDARY_BONDS_SUCCESS),
    startWith(null),
    switchMap(() => this.currentSharedTabData$.pipe(take(1))),
    map(sharedTabData => sharedTabData.secondaryBonds),
    shareLastEmitted(),
  );

  readonly availableHistoricalDates$: Observable<Record<string, string[]>> = this.actions$.pipe(
    ofType(PricingCompletionEffect.GET_HISTORICAL_PRICING_LIST_SUCCESS),
    startWith(null),
    switchMap(() => this.currentSharedTabData$.pipe(take(1))),
    map(sharedTabData => sharedTabData?.availableHistoricalDates ?? {}),
    filter(historicalDates => !!historicalDates),
    shareLastEmitted(),
  );

  readonly pricingMap$: Observable<Record<string, BondPricing>> = this.actions$.pipe(
    ofType(PricingCompletionEffect.REPRICE_MATURITIES_SUCCESS, PricingCompletionEffect.SET_SELECTED_TAB),
    startWith(null),
    switchMap(() => this.currentSharedTabData$.pipe(take(1))),
    map(sharedTabData => sharedTabData.tempPricingMap),
    distinctUntilChangedJson(),
    shareLastEmitted(),
  );

  readonly secondaryLevels$: Observable<SecondaryLevels | undefined> = this.actions$.pipe(
    ofType(
      PricingCompletionEffect.UPDATE_SECONDARY_BOND_LEVELS,
      PricingCompletionEffect.SET_SECONDARY_BONDS_OVERRIDE,
      PricingCompletionEffect.SET_SELECTED_BONDS_SUCCESS,
      PricingCompletionEffect.SET_SELECTED_TAB,
    ),
    startWith(null),
    switchMap(() => this.currentPricingTabData$.pipe(take(1))),
    map(pricingTabData => getSecondaryLevels(pricingTabData)),
    distinctUntilChangedJson(),
    shareLastEmitted(),
  );

  readonly unsavedSecondaryLevels$: Observable<SecondaryLevels> = this.actions$.pipe(
    ofType(
      PricingCompletionEffect.UPDATE_SECONDARY_BOND_LEVELS,
      PricingCompletionEffect.SET_SELECTED_BONDS_SUCCESS,
      PricingCompletionEffect.SET_SELECTED_TAB,
    ),
    startWith(null),
    switchMap(() => this.currentSharedTabData$.pipe(take(1))),
    map(sharedTabData => sharedTabData?.unsavedSecondaryLevels ?? {}),
    distinctUntilChangedJson(),
    shareLastEmitted(),
  );

  readonly secondaryBondsPricingMap$: Observable<SecondaryLevels> = this.actions$.pipe(
    ofType(
      PricingCompletionEffect.UPDATE_SECONDARY_BOND_LEVELS,
      PricingCompletionEffect.SET_SECONDARY_BONDS_OVERRIDE,
      PricingCompletionEffect.SET_SELECTED_TAB,
    ),
    debounceTime(0),
    switchMap(() => combineLatest([this.currentPricingTabData$, this.currentSharedTabData$]).pipe(take(1))),
    map(([pricingTabData, sharedTabData]) => {
      return getSecondaryBondsPricingMap(pricingTabData, sharedTabData);
    }),
    distinctUntilChangedJson(),
    shareLastEmitted(),
  );

  readonly selectedBonds$: Observable<Set<string> | null | undefined> = this.actions$.pipe(
    ofType(
      PricingCompletionEffect.SET_SELECTED_TAB,
      PricingCompletionEffect.GET_SECONDARY_BONDS_SUCCESS,
      PricingCompletionEffect.UPDATE_SECONDARY_BOND_LEVELS,
      PricingCompletionEffect.SET_PRICED_REQUEST_DATA,
      PricingCompletionEffect.SET_SELECTED_BONDS_SUCCESS,
      PricingCompletionEffect.UPDATE_INCLUDED_ISINS,
    ),
    startWith(null),
    switchMap(() => this.currentPricingTabData$.pipe(take(1))),
    map(pricingTabData => getSelectedBonds(pricingTabData)),
    distinctUntilChangedSet(),
    shareLastEmitted(),
  );

  readonly selectedSecondaryBondsDates$: Observable<string[]> = merge(
    this.actions$.pipe(
      ofType(
        PricingCompletionEffect.SET_SECONDARY_BONDS_DATES,
        PricingCompletionEffect.GET_HISTORICAL_PRICING_LIST_SUCCESS,
        PricingCompletionEffect.SET_SHOW_LATEST_HISTORICAL,
        PricingCompletionEffect.SET_PRICED_REQUEST_DATA,
        PricingCompletionEffect.SET_SELECTED_TAB,
      ),
    ),
    updateAdditionalCurvesEffectCalled(this.actions$, NewIssueCurveType.Historical),
  ).pipe(
    startWith(null),
    switchMap(() =>
      combineLatest([
        this.currentPricingTabData$,
        this.currentSharedTabData$,
        this.store.select(PricingCompletionSelector.isViewMode),
      ]).pipe(take(1)),
    ),
    map(([pricingTabData, sharedTabData, isViewMode]: [IssuerPricingDataMap, SharedTabData, boolean]) => {
      return this.syndicatePricerUtilService.getSelectedHistoricalSecondaryBondDates(
        pricingTabData,
        sharedTabData,
        isViewMode,
      );
    }),
    distinctUntilChangedJson(),
  );

  readonly savedSecondaryBondDates$: Observable<string[]> = this.actions$.pipe(
    ofType(
      PricingCompletionEffect.SET_SECONDARY_BONDS_DATES,
      PricingCompletionEffect.SET_PRICED_REQUEST_DATA,
      PricingCompletionEffect.SET_SELECTED_TAB,
    ),
    startWith(null),
    switchMap(() => this.currentPricingTabData$.pipe(take(1))),
    map(pricingTabData => {
      return getHistoricalSecondaryBondDates(pricingTabData).sort(sortByAlphabeticalInverse);
    }),
    distinctUntilChangedJson(),
  );

  readonly secondaryPricedKeys$: Observable<Record<string, boolean>> = this.actions$.pipe(
    ofType(
      PricingCompletionEffect.GET_HISTORICAL_SECONDARY_BONDS_PRICED_SUCCESS,
      PricingCompletionEffect.SET_PRICED_REQUEST_DATA,
      PricingCompletionEffect.SET_SELECTED_TAB,
    ),
    startWith(null),
    switchMap(() => this.currentSharedTabData$.pipe(take(1))),
    map(sharedTabData => sharedTabData.secondaryPricedKeys ?? {}),
  );

  readonly quotationType$ = this.actions$.pipe(
    ofType(PricingCompletionEffect.SET_SELECTED_TAB, PricingCompletionEffect.SET_QUOTE_TYPE),
    startWith(null),
    debounceTime(0),
    switchMap(() => this.currentPricingTabData$.pipe(take(1))),
    map(pricingTabData => {
      let quoteType: QuoteType | undefined;
      Object.values(pricingTabData).forEach(issuerPricingData => {
        if (!quoteType) {
          quoteType = issuerPricingData.pricingRequest.pricing.quote_type;
        }
      });
      return quoteType ?? DEFAULT_QUOTE_TYPE;
    }),
    shareLastEmitted(),
  );

  readonly customCurves$: Observable<AdditionalCurve[]> = this.actions$.pipe(
    ofType(PricingCompletionEffect.SET_SELECTED_TAB, PricingCompletionEffect.UPDATE_ADDITIONAL_CURVES),
    startWith(null),
    switchMap(() => this.currentPricingTabData$.pipe(take(1))),
    map(pricingTabData => getAdditionalPricingCurvesByType(NewIssueCurveType.Custom, pricingTabData)),
    distinctUntilChangedJson(),
    shareLastEmitted(),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
    private stateService: StateService,
  ) {
    const syndicatePricerCustomSelectors = Object.keys(this)
      .filter(key => key.endsWith('$'))
      .map(key => this[key as keyof SyndicatePricerSelectorService] as Observable<any>);

    this.store
      .select(PricingCompletionSelector.mode)
      .pipe(
        map(mode => !!mode),
        distinctUntilChanged(),
        switchMap(hasMode => {
          if (hasMode) {
            return merge(...syndicatePricerCustomSelectors);
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }
}
