@if (!isLoading) {
  <form>
    @for (section of sectionsForDisplay; track trackBy.name($index, section)) {
      @if (sectionsForDisplay.length > 1 || isFilterActive) {
        <mat-accordion #accordion="matAccordion" [multi]="isFilterActive">
          <mat-expansion-panel
            [id]="sectionIdPrefix + section.name"
            (closed)="panelClosed(section)"
            (opened)="panelOpened(section)"
            [expanded]="openSection?.name === section.name || isFilterActive"
            (afterExpand)="panelAfterExpand(section)"
          >
            <mat-expansion-panel-header>
              <div class="mat-panel-prefix">
                @if (section.errorCount) {
                  <mat-icon svgIcon="warning" class="is-warning"></mat-icon>
                } @else if (section.unresolvedComments) {
                  <mat-icon [matBadge]="section.unresolvedComments" class="badge-is-alert" svgIcon="comment"></mat-icon>
                } @else {
                  <om-progress-indicator
                    showFullProgressCircle
                    diameter="22"
                    [progress]="section.progress"
                  ></om-progress-indicator>
                }
              </div>
              <mat-panel-title>{{ section.label }}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              @if (isFilterActive || expandedSections[section.name]) {
                @for (fieldName of section.fieldNames; track fieldName) {
                  @if (!model.fieldMapping[fieldName].content) {
                    <ng-container
                      *ngTemplateOutlet="formFieldTemplate; context: { fieldName: fieldName }"
                    ></ng-container>
                  }
                }
              } @else {
                <om-loading></om-loading>
              }
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
      } @else {
        <div>
          @for (fieldName of sectionsForDisplay[0].fieldNames; track fieldName) {
            <ng-container *ngTemplateOutlet="formFieldTemplate; context: { fieldName: fieldName }"></ng-container>
          }
        </div>
      }
      <ng-template #formFieldTemplate let-fieldName="fieldName">
        <om-dynamic-form-field
          [id]="fieldIdPrefix + fieldName"
          [ngClass]="{ 'is-locked': model.fieldMapping[fieldName].locked }"
          [model]="model"
          [form]="model.form"
          [formConfig]="formConfig"
          [fieldConfig]="model.fieldMapping[fieldName]"
          [prefixActions]="prefixActionsMapping[fieldName]"
          [suffixActions]="suffixActionsMapping[fieldName]"
          (actionSelected)="onFieldActionSelected($event)"
          (inputChange)="onInput($event)"
        ></om-dynamic-form-field>
      </ng-template>
    } @empty {
      <om-empty-search [searchTerm]="searchTerm"></om-empty-search>
    }
  </form>
} @else {
  <om-loading message="Please wait a moment while we load the form"></om-loading>
}
