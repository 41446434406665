import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { urlValidationRegex } from '../constants/inputs';
import {
  DateInputConfig,
  DateRangeInputConfig,
  DatetimeInputConfig,
  FormConfig,
  FormInputConfig,
  FormValidator,
  ListInputConfig,
  PrimitiveInputConfig,
  TextareaInputConfig,
} from '../models/form.model';
import { FormUtilService } from './form-util.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private utilService: FormUtilService) {}

  createValidators(fieldConfig: FormInputConfig, formConfig?: FormConfig): FormValidator[] {
    const validation = [];
    if (fieldConfig.required && !formConfig?.isPartialSaveAllowed) {
      validation.push(Validators.required);
    }

    if ('decimalPlaces' in fieldConfig) {
      validation.push(this.decimalPlaces((fieldConfig as PrimitiveInputConfig).decimalPlaces, fieldConfig.element));
    }

    if (fieldConfig.regex) {
      switch (fieldConfig.element) {
        case 'list':
          validation.push(this.patternValidatorForLists(fieldConfig));
          break;
        default:
          validation.push(Validators.pattern(fieldConfig.regex));
      }
    }

    if ('min' in fieldConfig && (fieldConfig.min || fieldConfig.min === 0)) {
      switch (fieldConfig.element) {
        case 'date':
        case 'datetime':
        case 'date_range':
          validation.push(this.dateMin(fieldConfig.min.toString(), fieldConfig));
          break;
        case 'list':
          break;
        default:
          validation.push(this.genericMin(fieldConfig.min));
      }
    }

    if ('max' in fieldConfig && (fieldConfig.max || fieldConfig.max === 0)) {
      switch (fieldConfig.element) {
        case 'date':
        case 'datetime':
        case 'date_range':
          validation.push(this.dateMax(fieldConfig.max.toString(), fieldConfig));
          break;
        default:
          validation.push(this.genericMax(fieldConfig.max));
      }
    }

    if ('type' in fieldConfig) {
      switch (fieldConfig.type) {
        case 'email':
          validation.push(Validators.email);
          break;
        case 'url':
          validation.push(Validators.pattern(urlValidationRegex));
          break;
        default:
          break;
      }
    }

    const maxlength = (fieldConfig as PrimitiveInputConfig | TextareaInputConfig).maxlength;
    if (maxlength) {
      validation.push(Validators.maxLength(maxlength));
    }

    return validation;
  }

  // mb provide this is an optional callback to below funcs
  private noValue(value: any) {
    return value === null || value === undefined || value === '';
  }

  genericMin(min: any, comparator?: (lhs: typeof min, rhs: typeof min) => boolean) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.noValue(control.value)) {
        return null;
      }
      const belowMin = comparator ? comparator(control.value, min) : control.value < min;
      return belowMin ? { min: { value: control.value } } : null;
    };
  }

  genericMax(max: any, comparator?: (lhs: typeof max, rhs: typeof max) => boolean) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.noValue(control.value)) {
        return null;
      }
      const aboveMax = comparator ? comparator(control.value, max) : control.value > max;
      return aboveMax ? { max: { value: control.value } } : null;
    };
  }

  // could dry all these quite a bit
  dateMin(min: string, fieldInputConfig: DatetimeInputConfig | DateInputConfig | DateRangeInputConfig) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.noValue(control.value) || (fieldInputConfig.element === 'date_range' && !control.value?.start)) {
        return null;
      }
      const value = fieldInputConfig.element === 'date_range' ? control.value.start : control.value;
      const momentMin = this.utilService.stringToMoment(min, fieldInputConfig);
      const momentValue = this.utilService.stringToMoment(value, fieldInputConfig);
      const belowMin = momentValue.isBefore(momentMin);
      return belowMin ? { max: { value: control.value } } : null;
    };
  }

  dateMax(max: string, fieldInputConfig: DatetimeInputConfig | DateInputConfig | DateRangeInputConfig) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.noValue(control.value) || (fieldInputConfig.element === 'date_range' && !control.value?.end)) {
        return null;
      }
      const value = fieldInputConfig.element === 'date_range' ? control.value.end : control.value;
      const momentMax = this.utilService.stringToMoment(max, fieldInputConfig);
      const momentValue = this.utilService.stringToMoment(value, fieldInputConfig);
      const afterMax = momentValue.isAfter(momentMax);
      return afterMax ? { max: { value: control.value } } : null;
    };
  }

  private checkIfTooManyDp(value: any, dp: number): boolean {
    return value.toString().split('.')?.[1]?.length > dp;
  }

  decimalPlaces(dp: number | undefined, element: string) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (dp === undefined || this.noValue(control.value)) {
        return null;
      }

      let tooManyDp = false;
      if (element === 'list') {
        tooManyDp = control.value.some((item: string) => this.checkIfTooManyDp(item, dp));
      } else {
        tooManyDp = this.checkIfTooManyDp(control.value, dp);
      }

      return tooManyDp
        ? {
            decimalPlaces: { value: control.value },
            customMessage: `This field only accepts up to ${dp} decimal places`,
          }
        : null;
    };
  }

  patternValidatorForLists(fieldConfig: ListInputConfig): FormValidator {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || !fieldConfig.regex) {
        return null;
      }
      const error = control.value.some((item: string) =>
        fieldConfig.regex ? !item.toString().match(fieldConfig.regex)?.length : false,
      );

      return error ? { pattern: { value: control.value, message: fieldConfig.regex_message } } : null;
    };
  }
}
