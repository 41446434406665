export enum DealerTeam {
  Mtn = 'mtn',
  Syndicate = 'syndicate',
  Dcm = 'dcm',
  FlowSales = 'flow_sales',
  StructuredSales = 'structured_sales',
  Structuring = 'structuring',
  Support = 'support',
  Trading = 'trading',
  Legal = 'legal',
  Operations = 'operations',
}

export enum IssuerTeam {
  Funding = 'funding',
  Legal = 'legal',
  Operations = 'operations',
  Support = 'support',
  InvestorRelations = 'investor_relations',
}

export enum LawFirmTeam {
  Solicitor = 'solicitor',
}

export type TeamName = DealerTeam | IssuerTeam | LawFirmTeam;
