import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SecondaryBondsGrouping, ValueAndLabel } from '@morpho/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { ColDef, IToolPanelParams } from 'ag-grid-community';
import { AgGridPanel, GroupSidebarConfig } from '../../ag-grid-panels.model';
import { AgGridPanelsService } from '../../services/ag-grid-panels.service';

@Component({
  standalone: false,
  selector: 'om-group-sidebar-panel',
  templateUrl: './group-sidebar-panel.component.html',
  styleUrls: ['./group-sidebar-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GroupSidebarPanelComponent implements IToolPanelAngularComp, OnDestroy {
  private params: IToolPanelParams;
  private config: GroupSidebarConfig;

  private readonly eventListeners = [
    {
      type: 'modelUpdated',
      function: () => this.onModelUpdated(),
    },
  ];

  private readonly noneOption: ValueAndLabel = { value: SecondaryBondsGrouping.None, label: 'None' };
  options: ValueAndLabel[] = [];
  value: string;

  constructor(private agGridPanelsService: AgGridPanelsService) {}

  agInit(params: IToolPanelParams): void {
    setTimeout(() => this.refresh(params));
  }

  refresh(params: IToolPanelParams): boolean | void {
    this.params = params;
    this.config = this.agGridPanelsService.getPanelConfig(params, AgGridPanel.GroupSideBar);

    this.eventListeners.forEach(listener => {
      this.params.api.addEventListener(listener.type, listener.function);
    });
  }

  private onModelUpdated(): void {
    const rowGroup = this.params.api.getRowGroupColumns();
    this.value = <string>rowGroup[0]?.getColDef().colId ?? this.noneOption.value;

    const options: ValueAndLabel[] = [];
    this.params.api.getColumnDefs()?.forEach((colDef: ColDef) => {
      if (this.config.columnIds.includes(colDef.colId ?? '')) {
        options.push({
          value: colDef.colId ?? this.noneOption.value,
          label: colDef.headerName ?? colDef.colId ?? this.noneOption.label,
        });
      }
    });
    options.push(this.noneOption);

    this.options = options;
  }

  onValueChange() {
    const colId = this.value;
    this.params.api.setRowGroupColumns(colId ? [colId] : []);
    this.params.context.setSecondaryBondGrouping(colId);
  }

  ngOnDestroy() {
    this.eventListeners.forEach(listener => {
      this.params.api.removeEventListener(listener.type, listener.function);
    });
  }
}
