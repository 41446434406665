<div
  #collapseContainer
  id="collapse-container"
  [ngClass]="{ 'is-overflowing': isOverflowing, 'is-expanded': isExpanded }"
  [style.--preview-height]="previewHeight"
>
  <ng-content></ng-content>
</div>
@if (isOverflowing) {
  <button mat-button class="is-text" (click)="toggleExpanded()">
    <span class="see-more-btn-text">{{ isExpanded ? 'See Less' : 'See More' }}</span>
  </button>
}
