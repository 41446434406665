import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import {
  ActionMenuConfig,
  ActionMenuEvent,
  CustomRouterState,
  HttpMethod,
  RouterSelector,
  UtilService,
} from '@morpho/core';
import { FormConfig, FormInputConfig, FormPrefixes } from '@morpho/form';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DynamicFormModel, EditType, FlexibleEditInput } from '../../models/dynamic-form.model';
import { DynamicFormService } from '../../services/dynamic-form.service';

interface FlexEditActionMapping {
  [key: string]: ActionMenuConfig;
}
@Component({
  standalone: false,
  selector: 'om-dynamic-form-flexible-edit',
  templateUrl: './dynamic-form-flexible-edit.component.html',
  styleUrls: ['./dynamic-form-flexible-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormFlexibleEditComponent implements OnDestroy, OnInit {
  readonly EditType = EditType;

  private ngOnDestroy$ = new Subject<void>();
  @ViewChild('accordion') accordion: MatAccordion;

  @Input()
  get formConfig(): FormConfig {
    return this._formConfig;
  }
  set formConfig(formConfig: FormConfig) {
    this._formConfig = {
      ...formConfig,
      showFormFieldPrefix: false,
      idPrefix: FormPrefixes.DocumentGeneratorFlexibleEdit,
    };
  }
  private _formConfig: FormConfig;

  @Input() options: FormInputConfig[];
  @Input() optionsFromBackend: any[];

  @Input() url: string;
  @Input() method: HttpMethod;

  @Input()
  get model(): DynamicFormModel {
    return this._model;
  }
  set model(newModel: DynamicFormModel) {
    this._model = newModel;
    this.modelChange.emit(this.model);
  }
  private _model: DynamicFormModel;

  @Input()
  get flexEditActionMapping(): FlexEditActionMapping {
    return this._flexEditActionMapping;
  }
  set flexEditActionMapping(flexEditActionMapping: FlexEditActionMapping) {
    this._flexEditActionMapping = flexEditActionMapping;
    this.setActionMapping();
  }
  private _flexEditActionMapping: FlexEditActionMapping = {};

  @Input()
  get findActionMapping(): { [key: string]: ActionMenuConfig } {
    return this._findActionMapping;
  }
  set findActionMapping(findActionMapping: { [key: string]: ActionMenuConfig }) {
    this._findActionMapping = findActionMapping;
  }
  private _findActionMapping: { [key: string]: ActionMenuConfig };

  @Output() modelChange: EventEmitter<DynamicFormModel> = new EventEmitter();
  @Output() actionSelected: EventEmitter<ActionMenuEvent> = new EventEmitter();

  @Input() editedInputs$: Observable<FlexibleEditInput[]>;

  routerState$: Observable<CustomRouterState> = this.store
    .select(RouterSelector.state)
    .pipe(takeUntil(this.ngOnDestroy$));

  fieldIdPrefix: string;

  impactedInputsAreOpen: boolean;

  directEditInputs: FlexibleEditInput[] = [];
  impactedEditInputs: FlexibleEditInput[] = [];

  dynamicActionMapping: FlexEditActionMapping;

  constructor(
    private dynamicFormService: DynamicFormService,
    private store: Store,
    private utilService: UtilService,
  ) {}

  ngOnInit() {
    this.editedInputs$
      .pipe(
        debounceTime(0),
        takeUntil(this.ngOnDestroy$),
        tap(() => {
          const directEditInputs: FlexibleEditInput[] = [];
          const impactedEditInputs: FlexibleEditInput[] = [];
          this.model.editedInputs?.forEach(editedInput => {
            const input = {
              ...editedInput,
              hideContent: this.model.fieldMapping[editedInput.fieldName].element === 'richtext',
            };

            if (input.isImpacted) {
              impactedEditInputs.push(input);
            } else {
              directEditInputs.push(input);
            }
          });

          const getLastInputName = (inputs: FlexibleEditInput[]) => {
            return inputs?.[inputs.length - 1]?.fieldName;
          };

          const hasInputBeenAdded =
            !this.directEditInputs?.length ||
            getLastInputName(this.directEditInputs) !== getLastInputName(directEditInputs);
          this.directEditInputs = this.setEditedInputs(this.directEditInputs, directEditInputs);

          if (!this.impactedEditInputs?.length && impactedEditInputs.length && this.impactedInputsAreOpen !== false) {
            this.impactedInputsAreOpen = false; //* Show opening animation if previously open
            setTimeout(() => {
              this.impactedInputsAreOpen = true;
            });
          }
          this.impactedEditInputs = this.setEditedInputs(this.impactedEditInputs, impactedEditInputs);

          if (hasInputBeenAdded) {
            this.scrollLastFormField();
          }
        }),
      )
      .subscribe();

    this.fieldIdPrefix = this.dynamicFormService.getFieldIdPrefix(this.formConfig?.idPrefix);

    this.modelChange.emit(this.model);
  }

  onActionSelected(event: ActionMenuEvent) {
    this.actionSelected.emit(event);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formConfig) {
      this.fieldIdPrefix = this.dynamicFormService.getFieldIdPrefix(this.formConfig?.idPrefix);
    }
  }

  onInput(event: any) {
    const name = event.name;
    this.dynamicFormService.onUserInput(name, this.model, this.formConfig, true).then(() => {
      this.modelChange.emit(this.model);
    });
  }

  onDeleteAction(fieldId: string) {
    this.dynamicFormService.resetFlexibleEditField(fieldId, this.model);
    this.modelChange.emit(this.model);
  }

  private setEditedInputs(currentInputs: FlexibleEditInput[], newInputs: FlexibleEditInput[]): FlexibleEditInput[] {
    if (this.utilService.areArraysEquivalent(currentInputs, newInputs)) {
      return currentInputs;
    }

    const newInputsMap = Object.fromEntries(newInputs.map(input => [input.fieldName, input]));
    const currentInputsMap = Object.fromEntries(currentInputs.map(input => [input.fieldName, input]));

    return Object.keys(newInputsMap).map(fieldName => {
      const currentInput = currentInputsMap[fieldName];
      const newInput = newInputsMap[fieldName];
      return currentInput && currentInput.type === newInput.type ? currentInput : newInput;
    });
  }

  private setActionMapping() {
    this.dynamicActionMapping = JSON.parse(JSON.stringify(this.flexEditActionMapping));

    this.impactedEditInputs?.forEach(input => {
      if (this.model.fieldMapping[input.fieldName].locked || input.type === EditType.Added) {
        this.dynamicActionMapping[input.fieldName] = {
          ...this.dynamicActionMapping[input.fieldName],
          actions: this.dynamicActionMapping[input.fieldName].actions?.filter(action => action.name !== 'discard-edit'),
        };
      }
    });
  }

  private scrollLastFormField() {
    if (!this.directEditInputs?.length) {
      return;
    }
    const lastFieldId = this.directEditInputs.slice(-1)[0].fieldName;
    const elementId = this.fieldIdPrefix + lastFieldId;
    setTimeout(() => {
      const input = document.getElementById(elementId);
      if (!input) {
        return;
      }
      input?.scrollIntoView({ block: 'end' });
    });
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
