@switch (fieldConfig?.element) {
  @case ('data_table') {
    @if (asDatatable(fieldConfig); as config) {
      <om-data-table-input
        disabled
        [columns]="config.columns"
        [labels]="config.labels"
        [labelPrefix]="config.labelPrefix"
        [labelHeader]="config.labelHeader"
        [value]="value"
      >
      </om-data-table-input>
    }
  }
  @case ('richtext') {
    <om-richtext-value-viewer [value]="value"></om-richtext-value-viewer>
  }
  @default {
    <span>{{ displayValue }}</span>
  }
}
