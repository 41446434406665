import * as moment from 'moment';
import { browserSupport } from './system';
import { ChartColorsDefault, ColorThemeDefault } from './theme-default';

// Color theme accessible in typescript
export const ColorTheme = ColorThemeDefault;
export const ChartTheme = ChartColorsDefault;

// use moment format
export const DATE_FORMAT = 'DD-MMM-YYYY';
export const DATE_NO_YEAR_FORMAT = 'DD-MMM';
export const TIME_FORMAT = 'HH:mm';
export const DATETIME_FORMAT = 'DD-MMM-YYYY | HH:mm';
export const DATETIME_EXPORT_FORMAT = 'DD-MMM-YYYY HH:mm';
export const BASE_LEAP_YEAR = '2000';
export const DATE_INPUT_FORMATS = [
  'D-M-YY',
  'D-M-YYYY',
  'D-MM-YY',
  'D-MM-YYYY',
  'DD-M-YY',
  'DD-M-YYYY',
  'DD-MM-YY',
  'DD-MM-YYYY',
  'D-MMM-YY',
  'D-MMM-YYYY',
  'DD-MMM-YYYY',
  'DD-MMMM-YYYY',
].reduce((formats, currentFormat) => {
  return [...formats, currentFormat, currentFormat.replaceAll('-', '/'), currentFormat.replaceAll('-', ' ')];
}, []);

// funding levels api 'DD-MMM-YYYY', tsg api 'YYYY-MM-DD'
export const BACKEND_DISPLAY_FORMAT = 'DD-MMM-YYYY';
export const BACKEND_DATE_FORMATS = ['DD-MMM-YYYY', 'YYYY-MM-DD'];
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const BACKEND_DATE_NO_YEAR_FORMAT = 'MM-DD';
export const BACKEND_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const BACKEND_DATETIME_PRECISE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]';

export const DEFAULT_DATE_PLACEHOLDER = moment('2025-01-01', 'YYYY-MM-DD').format(DATE_FORMAT);

export const smallScreenBreakPoint = 1100; // also change $small-screen-breakpoint
export const largeScreenBreakPoint = 1920;
export const scrollbarWidth = browserSupport.webkitScrollbar ? 8 : undefined; // also change `$scrollbar-width`

export const BANNER_LEGACY_TRADE = 'This is a trade created by our old termsheet generator and is read-only';
export const BANNER_TEST_TRADE = 'This trade is in test mode with limited functionality';
export const BANNER_ARCHIVED_TRADE = 'This trade has been archived';

export const GENERIC_AUTOCOMPLETE_PLACEHOLDER = 'Start typing for suggestions';
export const DEFAULT_ERROR_MESSAGE = 'Something went wrong, please try again later.';

export const FIXED_PRECISION = 3;
export const FLOATING_PRECISION = 0;

export const linearProgressIndicatorTransitionTime = 2000; // also change $linear-progress-indicator-transition-time

export const DIALOG_WIDTH_SMALL = '400px';
export const DIALOG_WIDTH_MEDIUM = '688px';
export const DIALOG_WIDTH_LARGE = '920px';
export const DIALOG_WIDTH_DOCUMENT_EXTRACTOR = '506px';
export const DIALOG_MAX_HEIGHT_CONSTRAINED = '896px';

export const UNSET = 'unset';

export enum DateType {
  Date = 'date',
  DateTime = 'datetime,',
  DateNoYear = 'date-no-year',
}

export enum DialogSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum DialogHeightMode {
  Unset = 'unset',
  Constrained = 'constrained',
}
