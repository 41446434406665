<mat-spinner
  class="om-progress-indicator-background"
  mode="determinate"
  [diameter]="diameter"
  value="100"
></mat-spinner>
<mat-spinner
  class="om-progress-indicator-foreground"
  mode="determinate"
  [diameter]="diameter"
  [value]="showFullProgressCircle ? '100' : progress"
></mat-spinner>
@if (isComplete) {
  <mat-icon svgIcon="check"></mat-icon>
} @else {
  <div class="om-progress-indicator-value-container">
    @if (showNumber) {
      <span>{{ progress }}%</span>
    }
  </div>
}
