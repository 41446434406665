import { Component, ViewEncapsulation } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  standalone: false,
  selector: 'om-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent implements ITooltipAngularComp {
  params: { tooltipContent: string } & ITooltipParams;
  tooltipContent: string;

  agInit(params: { tooltipContent: string } & ITooltipParams): void {
    this.params = params;
    this.tooltipContent = this.params.tooltipContent || '';
  }
}
