import { PartialIssuer } from '../core/patterns/issuer/issuer.model';
import { PricedLevel } from '../core/patterns/pricing/pricing.model';
import { RatingsMap } from '../core/patterns/ratings/state/ratings.model';
import { RegressionObject } from './regression.model';

interface CurveIssuingEntity {
  id: number;
  name: string;
  lei: string;
  country: string;
  issuer: PartialIssuer;
}

export enum CurveType {
  ALL = 'all',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CUSTOM = 'custom',
  PRICING = 'pricing',
}

export interface Curve {
  bloomberg_ticker: string;
  bonds: CurveBond[];
  checked: boolean;
  collateral_currency: string;
  collateral_type: string;
  comment: string;
  currencies: string[];
  curve_name: string;
  curve_type: string; // source
  coupon_types: string[];
  day_count: string; // daycount in Level Inputs
  distribution: string;
  documentations: string[];
  fitch_lt_rating: number;
  fitch_st_rating: number;
  funding_basis: string;
  id: number;
  identifier: string;
  includes_multiple_funding_bases?: boolean;
  issuance_themes?: string[];
  issuing_entity: CurveIssuingEntity;
  last_updated: string | null;
  levels: Record<string, CurveLevel>;
  max_size_millions: string; //number in level inputs
  min_size_millions: string; //number in level inputs
  moodys_lt_rating: number;
  moodys_st_rating: number;
  name: string;
  payment_frequency: number; // payment_freq in Level Inputs
  regression?: RegressionObject[];
  seniority: string;
  sp_lt_rating: number;
  sp_st_rating: number;
  structures: string[]; // number[] in Level Inputs
  temporary_pricing_identifier?: string; // used in this service only
  updated_by: UserInfo | null;
  veritas_issuing_entity_id?: number;
}

interface UserInfo {
  first_name: string;
  last_name: string;
  id: number;
}

export interface CurveBond {
  coupon: string | null;
  funding_basis: string;
  isin: string;
  level: string;
  issue_date: string;
  maturity_date: string;
  size: string;
  spread: string;
  tenor: string;
  priced?: PricedLevel[];
  mid_price: string;
  mid_ytm: string | null;
  mid_discount_margin: string | null;
  mid_g_spread: string | null;
  mid_z_spread: string | null;
}
export interface CurveLevel {
  spread: string;
  new_issue_premium?: string;
  is_re?: boolean;
  tooltip?: string;
  total?: string;
  coloring?: string;
  is_greyed_out?: boolean;
  is_target_basis_met?: boolean;
  priced?: PricedLevel[];
}

export interface CurvesRequestOptions {
  curveTypes: CurveType[];
  issuerIds?: (number | string)[];
  includeEmpty?: boolean;
}
export interface CurvesResponse {
  curves: Curve[];
  tenors: string[];
}

// todo: where is the best place to put this...?
// todo: 'Curve' or 'Level'? (the age-old question...)
export interface CallableCurve extends RatingsMap {
  blackin?: any;
  blackout?: any;
  bloomberg_ticker: string;
  collateral_type: string;
  coupon_type: string;
  currencies: string[];
  documentations: string[];
  entities: string;
  funding_basis: string;
  funding_schedules: FundingSchedules;
  id: number;
  identifier: string;
  issuance_themes: any[];
  issuer: CurveIssuerInfo;
  max_size_millions?: any;
  min_size_millions: number;
  notes: string;
  product_type: string;
  swap_type: string;
  updated_by: IssuerInfo;
  updated_timestamp: string;
  veritas_issuing_entity_id?: number;
  issuing_entity: CurveIssuingEntity;
}

// todo: There are a lot of places where a version of this is used...
// todo: Consolidate them...?
export interface CurveIssuerInfo {
  company_name: string;
  country_name?: string;
  country?: string;
  id: number;
  issuer_type?: string;
}

interface IssuerInfo {
  first_name: string;
  id: number;
  last_name: string;
}

interface FundingSchedules {
  [key: string]: string;
}

export interface CallableStructure {
  created_by?: string;
  id?: number;
  name?: string;
  order?: number;
}
