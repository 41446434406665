import { CustomRouterState, RouterSelector } from '@morpho/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DealerTeam, IssuerTeam, TeamName } from 'apps/bankangle/src/app/constants/teams';
import { AuthenticatedUserState } from '../authenticated-user/authenticated-user.model';
import { AuthenticatedUserSelector } from '../authenticated-user/authenticated-user.selectors';
import { LawFirmTeam } from './../../../constants/teams';
import { PermissionState } from './permission.model';
import { PERMISSION_STATE_KEY } from './permission.reducer';

const permissionState = createFeatureSelector<PermissionState>(PERMISSION_STATE_KEY);
const authenticatedUserState = AuthenticatedUserSelector.state;
const routerState = RouterSelector.state;

const isDealerLegal = (user: AuthenticatedUserState): boolean => !!user.isDealer && DealerTeam.Legal === user.team_name;

const isDealerOperations = (user: AuthenticatedUserState): boolean =>
  !!user.isDealer && DealerTeam.Operations === user.team_name;

const isDealerProfileDealer = (user: AuthenticatedUserState, router: CustomRouterState): boolean =>
  !!(user.isDealer && user.bank_id?.toString() === router.urlParams?.dealerId);

const isIssuerProfileIssuer = (user: AuthenticatedUserState, router: CustomRouterState): boolean =>
  !!(user.isIssuer && user.issuer_id?.toString() === router.urlParams?.issuerId);

const canEditIssuerData = (user: AuthenticatedUserState, router: CustomRouterState): boolean =>
  user.isStaff ||
  (isIssuerProfileIssuer(user, router) &&
    !(user.team_name && [IssuerTeam.Operations, IssuerTeam.Support].includes(user.team_name as IssuerTeam)));

const canOverrideIssuanceInformationData = (user: AuthenticatedUserState, router: CustomRouterState): boolean =>
  isIssuerProfileIssuer(user, router) && [IssuerTeam.Funding, IssuerTeam.Legal].includes(user.team_name as IssuerTeam);

const canViewDealerData = (user: AuthenticatedUserState, router: CustomRouterState): boolean =>
  user.isStaff || user.isLawyer || user.isIssuer || isDealerProfileDealer(user, router);

const isIssuerSupport = (user: AuthenticatedUserState): boolean =>
  !!user.isIssuer && [IssuerTeam.Support].includes(user.team_name as IssuerTeam);

const canAccessLevels = (user: AuthenticatedUserState): boolean =>
  user.isStaff || canDealerAccessLevels(user) || canIssuerAccessLevels(user) || canLawFirmAccessLevels(user);

const canDealerAccessLevels = (user: AuthenticatedUserState): boolean =>
  !!user.isDealer &&
  ![DealerTeam.Legal, DealerTeam.Operations, DealerTeam.Support].includes(user.team_name as DealerTeam);

const canIssuerAccessLevels = (user: AuthenticatedUserState): boolean =>
  !!user.isIssuer &&
  ![IssuerTeam.Legal, IssuerTeam.Operations, IssuerTeam.Support, IssuerTeam.InvestorRelations].includes(
    user.team_name as IssuerTeam,
  );

const canLawFirmAccessLevels = (user: AuthenticatedUserState): boolean =>
  !!user.isLawyer && ![LawFirmTeam.Solicitor].includes(user.team_name as LawFirmTeam);

const isDealerPrivateSide = (user: AuthenticatedUserState): boolean =>
  !!user.isDealer &&
  !!user.team_name &&
  [DealerTeam.Mtn, DealerTeam.Syndicate, DealerTeam.Dcm].includes(user.team_name as DealerTeam);

export const PermissionSelector = {
  state: permissionState,

  pocSettings: createSelector(permissionState, state => state.pocSettings),
  navigation: createSelector(permissionState, state => state.navigation),

  canViewAuthNotifications: createSelector(permissionState, state => state.canViewAuthNotifications),

  canAccessCompleteDealerProfile: createSelector(
    authenticatedUserState,
    routerState,
    (user, router) => user.isStaff || isDealerProfileDealer(user, router),
  ),

  canEditDealerProfile: createSelector(
    authenticatedUserState,
    routerState,
    (user, router) =>
      user.isStaff ||
      (isDealerProfileDealer(user, router) && isDealerPrivateSide(user)) ||
      isDealerLegal(user) ||
      isDealerOperations(user),
  ),

  canAddDealerProfile: createSelector(authenticatedUserState, user => user.isStaff),
  canViewDealerList: createSelector(authenticatedUserState, user => !!(user.isStaff || user.isIssuer || user.isLawyer)),

  canViewDealerProfile: createSelector(authenticatedUserState, routerState, (user, router) =>
    canViewDealerData(user, router),
  ),

  canAccessCompleteIssuerProfile: createSelector(
    authenticatedUserState,
    routerState,
    (user, router) =>
      user.isStaff || (user.isDealer && isDealerPrivateSide(user)) || isIssuerProfileIssuer(user, router),
  ),
  canEditIssuerProfile: createSelector(authenticatedUserState, routerState, (user, router) =>
    canEditIssuerData(user, router),
  ),
  canAddIssuerProfileContacts: createSelector(
    authenticatedUserState,
    routerState,
    (user, router) => user.isStaff || user.isDealer || isIssuerProfileIssuer(user, router),
  ),

  canAccessCustomLevels: createSelector(
    authenticatedUserState,
    routerState,
    (user, router) => !!(user.isStaff || user.isDealer),
  ),
  isIssuerProfileIssuer: createSelector(authenticatedUserState, routerState, (user, router) =>
    isIssuerProfileIssuer(user, router),
  ),

  hasDashboardAccess: createSelector(permissionState, state => state.hasDashboardAccess),

  isCustomerSupportChatEnabled: createSelector(permissionState, state => state.isCustomerSupportChatEnabled),

  areToursEnabled: createSelector(permissionState, state => state.areToursEnabled),

  canUseCustomerSupportChat: createSelector(
    permissionState,
    state => !!(state.isCustomerSupportChatEnabled && state.isCustomerSupportChatSupported),
  ),

  canCreateTrade: createSelector(permissionState, state => state.canCreateTrade),
  canCreateFinalTerms: createSelector(permissionState, state => state.canCreateFinalTerms),

  canViewTradeTimeline: createSelector(authenticatedUserState, user => !isIssuerSupport(user)),

  canOverrideIssuanceInformation: createSelector(authenticatedUserState, routerState, (user, router) =>
    canOverrideIssuanceInformationData(user, router),
  ),

  tradeShortcuts: createSelector(permissionState, state =>
    state.tradeShortcuts?.length ? state.tradeShortcuts : undefined,
  ),

  canRequestIssuer: createSelector(
    authenticatedUserState,
    user =>
      user.isDealer &&
      [DealerTeam.Mtn, DealerTeam.Syndicate, DealerTeam.Dcm, DealerTeam.Structuring].includes(
        user.team_name as DealerTeam,
      ),
  ),

  canAccessEmailSettingsGeneral: createSelector(authenticatedUserState, user => user.isIssuer),
  canAccessEmailSettingsGeneralAlerts: createSelector(authenticatedUserState, user => user.isDealer),
  canAccessEmailSettingsSearchAlerts: createSelector(
    authenticatedUserState,
    user =>
      user.isDealer &&
      [
        DealerTeam.Mtn,
        DealerTeam.Syndicate,
        DealerTeam.Dcm,
        DealerTeam.FlowSales,
        DealerTeam.StructuredSales,
        DealerTeam.Structuring,
        DealerTeam.Trading,
      ].includes(user.team_name as DealerTeam),
  ),
  // User list Permissions
  canViewUserList: createSelector(
    authenticatedUserState,
    user =>
      user.isStaff || [DealerTeam.Support, IssuerTeam.Support].includes(user.team_name as DealerTeam | IssuerTeam),
  ),

  canCreateUser: createSelector(
    authenticatedUserState,
    user =>
      user.isStaff ||
      (user.isIssuer && user.team_name === IssuerTeam.Support && !user.is_sso_user) ||
      (user.isDealer && user.team_name === DealerTeam.Support && !user.is_sso_user),
  ),

  canEditUserList: createSelector(
    authenticatedUserState,
    user =>
      user.isStaff ||
      (user.isIssuer && user.team_name === IssuerTeam.Support) ||
      (user.isDealer && user.team_name === DealerTeam.Support),
  ),

  canViewIssuerList: createSelector(authenticatedUserState, user => !isIssuerSupport(user)),

  canViewLevels: createSelector(authenticatedUserState, user => canAccessLevels(user)),
  canGrantAccessToIssuerLevels: createSelector(authenticatedUserState, user => canAccessLevels(user)),
  canViewAnalytics: createSelector(authenticatedUserState, user => canAccessLevels(user)),

  canViewPricingInbox: createSelector(
    permissionState,
    authenticatedUserState,
    (permissions, user) =>
      !!(
        permissions.pocSettings?.syndicate_pricer &&
        user.isDealer &&
        user.team_name &&
        ([DealerTeam.Dcm, DealerTeam.Syndicate] as TeamName[]).includes(user.team_name)
      ),
  ),

  canRequestPricing: createSelector(
    permissionState,
    authenticatedUserState,
    (permissions, user) =>
      !!(
        permissions.pocSettings?.syndicate_pricer &&
        user.isDealer &&
        [DealerTeam.Dcm, DealerTeam.Syndicate].includes(user.team_name as DealerTeam)
      ),
  ),

  canViewTradeIdentifiersManager: createSelector(
    permissionState,
    authenticatedUserState,
    (permissions, user) =>
      !!(
        (permissions.pocSettings?.trade_id_manager &&
          ([IssuerTeam.Legal].includes(user.team_name as IssuerTeam) ||
            [DealerTeam.Legal].includes(user.team_name as DealerTeam))) ||
        user.isStaff
      ),
  ),
};
