import { NewIssueCurveType, PRICING } from '../models/pricing-request.model';

export enum PricingBreakDownType {
  NoBreakdown = 'noBreakdown',
  Total = 'total',
  NewIssue = 'newIssue',
  Yield = 'yield',
}

export const PricingBreakdownFilters: Record<PricingBreakDownType, NewIssueCurveType[]> = {
  [PricingBreakDownType.Total]: [],
  [PricingBreakDownType.NewIssue]: [],
  [PricingBreakDownType.NoBreakdown]: [NewIssueCurveType.NewIssuePremium, NewIssueCurveType.Spread],
  [PricingBreakDownType.Yield]: [NewIssueCurveType.Total, NewIssueCurveType.NewIssuePremium, NewIssueCurveType.Spread],
};

export const PricingBreakdownEditableRows: Record<PricingBreakDownType, NewIssueCurveType[]> = {
  [PricingBreakDownType.Total]: [NewIssueCurveType.NewIssuePremium, NewIssueCurveType.Spread],
  [PricingBreakDownType.NewIssue]: [NewIssueCurveType.Total, NewIssueCurveType.Spread],
  [PricingBreakDownType.NoBreakdown]: [NewIssueCurveType.Total],
  [PricingBreakDownType.Yield]: [NewIssueCurveType.Yield],
};

export const DEFAULT_ROW_ORDER = [
  NewIssueCurveType.BenchmarkInput,
  NewIssueCurveType.BenchmarkYield,
  NewIssueCurveType.ReferenceCurve,
  NewIssueCurveType.Custom,
  NewIssueCurveType.Spread,
  NewIssueCurveType.NewIssuePremium,
  NewIssueCurveType.Total,
  PRICING,
  NewIssueCurveType.Yield,
  NewIssueCurveType.Swap,
  NewIssueCurveType.OverviewSwap,
  NewIssueCurveType.Historical,
  NewIssueCurveType.Interpolated,
];

export const DEFAULT_PRICING_BREAKDOWN = PricingBreakDownType.Total;
