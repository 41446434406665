import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CarouselModalOverlayRef } from '../../../../../../dialog/src/lib/components/carousel-modal/carousel-modal-ref';
import { StandardNotification } from '../../../elements/models/notifications.model';

@Component({
  standalone: false,
  selector: 'om-modal-carousel-slide-container',
  templateUrl: './modal-carousel-slide-container.component.html',
  styleUrls: ['./modal-carousel-slide-container.component.scss'],
})
export class ModalCarouselSlideContainerComponent implements AfterViewInit {
  @Input() carouselSlideComponent: any; // slideComponent
  @Input() carouselItem: StandardNotification; // slideData

  @Input() carouselDialogRef: CarouselModalOverlayRef;
  @Output() closeSlide: EventEmitter<any> = new EventEmitter();

  @ViewChild('slideContainer', { read: ViewContainerRef }) slideContainer: any;
  componentRef: ComponentRef<any>;

  constructor(private resolver: ComponentFactoryResolver) {}

  ngAfterViewInit() {
    this.createSlideContainer();
  }

  private createSlideContainer(): void {
    if (!this.slideContainer) {
      return;
    }
    this.slideContainer.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(this.carouselSlideComponent);
    this.componentRef = this.slideContainer.createComponent(factory);

    this.componentRef.instance.carouselItem = this.carouselItem;
    this.componentRef.instance.carouselDialogRef = this.carouselDialogRef;
    this.componentRef.instance.closeSlide = this.closeSlide;
  }
}
