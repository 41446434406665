import { Component, Input } from '@angular/core';

export interface NarrowCardParams {
  image?: string;
  icon?: string;
  title: string;
  subtitle?: string;
  message: string;
  toggleable?: boolean;
}

@Component({
  standalone: false,
  selector: 'om-narrow-card',
  templateUrl: './narrow-card.component.html',
})
export class NarrowCardComponent {
  @Input() image: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() message: string;
}
