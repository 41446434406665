import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  CoreApiService,
  HttpMethod,
  LevelsUtilService,
  RoutingService,
  UtilService,
  ValueAndLabel,
} from '@morpho/core';
import {
  ComputeFieldConfig,
  ConditionalFunction,
  FormConfig,
  FormInputConfig,
  FormPrefixes,
  FormUtilService,
  InputOptions,
  OptionGroup,
  RichTextOption,
  TableInputConfig,
  ValidationService,
} from '@morpho/form';
import { RichTextService } from '@morpho/rich-text';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  DynamicFormFieldMapping,
  DynamicFormModel,
  DynamicFormSection,
  EditType,
  FlexibleEditInput,
} from '../models/dynamic-form.model';
import { DynamicFormApiService } from './dynamic-form-api.service';
import { DynamicFormTranslationService } from './dynamic-form-translation.service';

const ONE_SECTION_NAME = 'one_section';
@Injectable({
  providedIn: 'root',
})
export class DynamicFormService {
  private formFieldUpdated: BehaviorSubject<null | string> = new BehaviorSubject(null);
  formFieldUpdated$ = this.formFieldUpdated.asObservable();

  constructor(
    private coreApiService: CoreApiService,
    private dynamicFormApiService: DynamicFormApiService,
    private dynamicFormTranslationService: DynamicFormTranslationService,
    private formBuilder: FormBuilder,
    private formUtilService: FormUtilService,
    private levelsUtilService: LevelsUtilService,
    private validationService: ValidationService,
    private richTextService: RichTextService,
    private routingService: RoutingService,
    private utilService: UtilService,
  ) {}

  isTemplateTag(fieldName: string): boolean {
    return this.isContentTag(fieldName) || this.isRowTag(fieldName);
  }

  isContentTag(fieldName: string): boolean {
    return fieldName.startsWith('content_tag');
  }

  isRowTag(fieldName: string): boolean {
    return fieldName.startsWith('row_tag');
  }

  canInputBeSaved(input: FlexibleEditInput, model: DynamicFormModel, formConfig: FormConfig) {
    return (
      input.type === EditType.Changed &&
      (!formConfig.isFlexibleEdit ||
        this.isContentTag(input.fieldName) ||
        this.formUtilService.formInputHasValue(model?.form.get(input.fieldName)?.value) ||
        !model.fieldMapping[input.fieldName]?.required)
    );
  }

  getFieldId(name: string, formPrefix?: FormPrefixes) {
    return this.getFieldIdPrefix(formPrefix) + name;
  }

  getFieldIdPrefix(formPrefix?: FormPrefixes) {
    return `${formPrefix || 'form'}-field-`;
  }

  getSectionId(name: string, formPrefix?: FormPrefixes) {
    return this.getSectionIdPrefix(formPrefix) + name;
  }

  getSectionIdPrefix(formPrefix?: FormPrefixes) {
    return `${formPrefix || 'form'}-section-`;
  }

  generateFormModelFromApi(optionsPath: string, formConfig: FormConfig = {}): Observable<DynamicFormModel> {
    return this.coreApiService
      .callApi(optionsPath, null, HttpMethod.GET)
      .pipe(map(options => this.generateFormModel(options, { ...formConfig, isFromBackend: true })));
  }

  generateFormModel(formOptions: (FormInputConfig | any)[], formConfig: FormConfig = {}): DynamicFormModel {
    const dynamicForm: { [key: string]: any } = {};

    const fieldMapping: DynamicFormFieldMapping = {};
    let sections: DynamicFormSection[] = [
      {
        name: ONE_SECTION_NAME,
        label: '',
        fieldNames: [],
        subsections: [],
      },
    ];

    const fieldsWithExtraConfig: string[] = [];

    for (let fieldConfig of formOptions) {
      if (fieldConfig.element === 'section') {
        sections.push({
          name: fieldConfig.name || fieldConfig.label,
          label: fieldConfig.label,
          fieldNames: [],
          subsections: [],
        });
        continue;
      }

      if (fieldConfig.element === 'subsection') {
        sections[sections.length - 1]?.subsections?.push({
          name: fieldConfig.name || fieldConfig.label,
          label: fieldConfig.label,
          fieldNames: [],
        });
        continue;
      }

      if (formConfig.isFromBackend) {
        fieldConfig = this.dynamicFormTranslationService.translateBackendConfig(fieldConfig, formConfig);
      }

      if (
        !fieldConfig.content &&
        (fieldConfig.existsCondition || fieldConfig.computeField || fieldConfig.sizeSource || fieldConfig.validateField)
      ) {
        fieldsWithExtraConfig.push(fieldConfig.name);
      }

      fieldConfig = this.determineFieldConfigInitialValue(fieldConfig);
      fieldMapping[fieldConfig.name] = fieldConfig;
      if (this.isContentTag(fieldConfig.name)) {
        dynamicForm[fieldConfig.name] = this.createContentFormControl(fieldConfig);
      } else {
        dynamicForm[fieldConfig.name] = this.createFormControl(fieldConfig, formConfig);
      }
      sections[sections.length - 1].fieldNames.push(fieldConfig.name);
      const subsectionsLength = sections[sections.length - 1]?.subsections?.length;
      if (subsectionsLength) {
        sections[sections.length - 1]?.subsections?.[subsectionsLength - 1].fieldNames.push(fieldConfig.name);
      }
    }
    const form = this.formBuilder.group(dynamicForm);

    sections.forEach((section: DynamicFormSection) => {
      if (section.subsections?.length === 0) {
        delete section['subsections'];
      }
    });

    if (sections[0].fieldNames.length === 0) {
      sections = sections.slice(1, sections.length);
    }

    if (fieldsWithExtraConfig.length) {
      const fieldNames = Object.keys(form.getRawValue());

      for (let i = 0, len = fieldsWithExtraConfig.length; i < len; i++) {
        const fieldName = fieldsWithExtraConfig[i];
        this.createDynamicFieldFunctions(fieldMapping, form, fieldNames, fieldName);
      }
    }

    const partialSaveAllowed = formConfig.isPartialSaveAllowed || false;

    const formModel: DynamicFormModel = {
      form,
      fieldMapping,
      sections,

      savedFields: [],
      savedFormValue: {},
      sectionsForDisplay: [],
      errorCount: 0,
      progress: 0,
      isUnsaved: false,
      partialSaveAllowed,
      editedInputs: [],
    };
    this.updateFormModel(formModel, formConfig, true);

    return formModel;
  }

  updateFormModelOptions(
    formModel: DynamicFormModel,
    formOptions: (FormInputConfig | any)[],
    formConfig: FormConfig = {},
  ): void {
    const parsedFormModel = this.generateFormModel(formOptions, formConfig);

    Object.keys(formModel.form.controls).forEach(controlKey => {
      if (!parsedFormModel.form.controls[controlKey]) {
        // this field no longer exists in the new model
        delete formModel.form.controls[controlKey];
      } else {
        if (
          !this.formUtilService.areValuesEqual(
            formModel.form.controls[controlKey].value,
            parsedFormModel.form.controls[controlKey].value,
            false,
            false,
          ) &&
          formModel.form.controls[controlKey].pristine
        ) {
          formModel.form.controls[controlKey].setValue(parsedFormModel.form.controls[controlKey].value);
        }
        this.setModelFieldMapping(formModel, parsedFormModel, controlKey);
      }
    });

    Object.keys(parsedFormModel.form.controls).forEach(controlKey => {
      if (!formModel.form.controls[controlKey]) {
        // this is a new field that doesn't yet exist
        formModel.form.controls[controlKey] = parsedFormModel.form.controls[controlKey];
        formModel.form.controls[controlKey].setValue(parsedFormModel.form.controls[controlKey].value);
        this.setModelFieldMapping(formModel, parsedFormModel, controlKey);
      }
    });

    const fieldNames = Object.keys(formModel.form.getRawValue());

    Object.keys(formModel.fieldMapping).forEach(fieldName => {
      this.createDynamicFieldFunctions(formModel.fieldMapping, formModel.form, fieldNames, fieldName);
    });

    formModel.savedFields = parsedFormModel.savedFields;
    formModel.savedFormValue = parsedFormModel.savedFormValue;
    formModel.sections = parsedFormModel.sections;
    formModel.sectionsForDisplay = parsedFormModel.sectionsForDisplay;
  }

  onUserInput(
    fieldName: string,
    model: DynamicFormModel,
    formConfig: FormConfig = {},
    doNotUpdateEditedList = false,
  ): Promise<void> {
    model.form.get(fieldName)?.setValue(model.form.get(fieldName)?.value);

    return new Promise(resolve => {
      this.programmaticallyChangeFields(fieldName, model, formConfig).then(() => {
        this.getServerValidationMessages(fieldName, model.form, model.fieldMapping);
        if (!doNotUpdateEditedList && this.hasValueChanged(fieldName, model)) {
          this.addFieldToFlexibleEditList(fieldName, model);
        }
        this.updateFormModel(model, formConfig, false);
        resolve();
      });
    });
  }

  addFieldToFlexibleEditList(fieldName: string, model: DynamicFormModel, isPersistent = false): void {
    if (!model.editedInputs) {
      return;
    }

    const type =
      model.form.get(fieldName)?.value === model.savedFormValue[fieldName] ? EditType.Unchanged : EditType.Changed;

    if (
      (model.editedInputs ?? []).some((val, idx) => {
        if (fieldName !== val.fieldName) {
          return false;
        }

        // move to your inputs
        // model.editedInputs[idx].isDynamic = false;
        model.editedInputs[idx].type = type;
        return true;
      })
    ) {
      return;
    }

    // add to list
    model.editedInputs.push({
      fieldName,
      type,
      isImpacted: false,
      isPersistent,
    });
  }

  revertToOriginalFlexibleEditField(fieldName: string, model: DynamicFormModel) {
    model.form.get(fieldName)?.setValue(model.savedFormValue[fieldName]);
  }

  resetAllFlexibleEditFields(model: DynamicFormModel) {
    model.editedInputs?.forEach(field => this.resetFlexibleEditField(field.fieldName, model));
  }

  resetFlexibleEditField(fieldName: string, model: DynamicFormModel) {
    if (model.editedInputs) {
      model.editedInputs = model.editedInputs.filter(input => {
        return input.isImpacted || input.fieldName !== fieldName;
      });

      model.form.get(fieldName)?.setValue(model.savedFormValue[fieldName]);
    }
  }

  private createConfigApiBody(fields: Record<string, string>, form: FormGroup): Record<string, any> {
    const constantPrefix = 'CONSTANT:';
    const body: any = {};

    for (const [key, fieldName] of Object.entries(fields)) {
      if (fieldName.startsWith(constantPrefix)) {
        body[key] = JSON.parse(fieldName.replace(constantPrefix, ''));
      } else {
        const control = form.get(fieldName as string);
        if (control) {
          body[key] = control.value;
        }
      }
    }

    return body;
  }

  private findFieldNamesInCondition(condition: string, fieldNames: string[]) {
    const foundFields: Set<string> = new Set();
    const matches = condition.match(/\w+/g);

    // This is to avoid finding sub-string matches e.g. `size` being in `total_size`.
    // We iterate over the matches rather than the library of field names to be
    // more efficient.
    //
    // This isn't perfect, as we could find the `index` field in `size.index(0)`
    // which is a false positive. For now, being over-optimistic isn't the end
    // of the world.
    matches?.forEach(match => {
      if (fieldNames.includes(match)) {
        foundFields.add(match);
      }
    });

    return foundFields;
  }

  private createDynamicFieldFunctions(
    fieldMapping: DynamicFormFieldMapping,
    form: FormGroup<any>,
    fieldNames: string[],
    fieldName: string,
  ) {
    const fieldConfig: FormInputConfig = fieldMapping[fieldName];

    if (fieldConfig.existsCondition) {
      fieldConfig.existsFunction = this.createConditionalFunction(fieldConfig.existsCondition, fieldNames);
    }

    if (fieldConfig.computeField) {
      fieldConfig.computeField = fieldConfig.computeField.map(computeFieldConfig => {
        if (computeFieldConfig.type == 'conditions') {
          computeFieldConfig.other_conditions.forEach(conditionCase => {
            const foundFields = this.findFieldNamesInCondition(conditionCase.condition, fieldNames);
            foundFields.forEach(linkedFieldName => {
              const linkedFieldConfig = fieldMapping[linkedFieldName];
              if (linkedFieldConfig && !linkedFieldConfig.linkedComputeFields?.includes(fieldConfig.name)) {
                (linkedFieldConfig.linkedComputeFields ??= []).push(fieldConfig.name);
              }
            });
          });
        } else {
          Object.values(computeFieldConfig.fields).forEach(linkedFieldName => {
            const linkedFieldConfig = fieldMapping[linkedFieldName];
            if (linkedFieldConfig && !linkedFieldConfig.linkedComputeFields?.includes(fieldConfig.name)) {
              (linkedFieldConfig.linkedComputeFields ??= []).push(fieldConfig.name);
            }
          });
        }

        computeFieldConfig = {
          ...computeFieldConfig,
          conditionFunction: this.createConditionalFunction(computeFieldConfig.condition, fieldNames),
        };
        return computeFieldConfig;
      });
    }

    if (fieldConfig.validateField) {
      fieldConfig.validateField = fieldConfig.validateField.map(validateField => {
        if (validateField.fields) {
          Object.values(validateField.fields).forEach(linkedFieldName => {
            if (linkedFieldName === fieldConfig.name) {
              return;
            }
            const linkedFieldConfig = fieldMapping[linkedFieldName];
            if (linkedFieldConfig && !linkedFieldConfig.linkedValidateFields?.includes(fieldConfig.name)) {
              (linkedFieldConfig.linkedValidateFields ??= []).push(fieldConfig.name);
            }
          });
        }

        return validateField;
      });
    }

    const sizeSource = (fieldConfig as TableInputConfig).sizeSource;
    if (sizeSource) {
      const calculateFields = fieldMapping[sizeSource]?.calculateFields || [];
      calculateFields.push({
        fieldName: fieldConfig.name,
        attribute: 'size',
        function: (formGroup: FormGroup) => {
          const value = formGroup.get(sizeSource)?.value;
          return Array.isArray(value) ? value.length : value;
        },
      });
      fieldMapping[sizeSource].calculateFields = calculateFields;

      const value = form.get(sizeSource)?.value;
      (fieldConfig as TableInputConfig).size = Array.isArray(value) ? value.length : value;
    }
  }

  private setModelFieldMapping(
    existingFormModel: DynamicFormModel,
    newFormModel: DynamicFormModel,
    controlKey: string,
  ) {
    existingFormModel.fieldMapping[controlKey] = newFormModel.fieldMapping[controlKey];

    // Angular bug - enable/disable methods not working correctly
    // Remove timeout once it's fixed
    setTimeout(() => {
      existingFormModel.fieldMapping[controlKey].locked
        ? existingFormModel.form.controls[controlKey].disable({ emitEvent: false })
        : existingFormModel.form.controls[controlKey].enable({ emitEvent: false });
    }, 500);
  }

  private programmaticallyChangeFields(name: string, model: DynamicFormModel, formConfig?: FormConfig): Promise<void> {
    const promises: Promise<void>[] = [];
    const form = model.form;
    const fieldMapping = model.fieldMapping;

    const fieldConfig = fieldMapping[name];
    if (!fieldConfig) {
      return new Promise(resolve => resolve());
    }

    if (fieldConfig.alterFields) {
      promises.push(
        new Promise(resolve => {
          this.coreApiService.stringify(form.get(name)?.value).subscribe({
            next: value => {
              let arrayMatchValue = '';
              if (this.isListFieldValue(fieldConfig)) {
                Object.keys(fieldConfig.alterFields ?? {})
                  .filter(key => key !== 'default')
                  .forEach(key => {
                    const [valueIsJson, formValue] = this.utilService.validateJSON(value);
                    const [keyIsJson, alterFieldKeyValue] = this.utilService.validateJSON(key);
                    if (valueIsJson && keyIsJson) {
                      if (
                        Array.isArray(formValue) &&
                        Array.isArray(alterFieldKeyValue) &&
                        this.utilService.areArraysEquivalent(formValue, alterFieldKeyValue)
                      ) {
                        arrayMatchValue = key;
                      }
                    }
                  });
              }
              const changes =
                fieldConfig.alterFields &&
                (fieldConfig.alterFields[value] ||
                  (arrayMatchValue && fieldConfig.alterFields[arrayMatchValue]) ||
                  fieldConfig.alterFields.onAllValues ||
                  fieldConfig.alterFields.default);

              if (changes?.length) {
                this.programmaticallyChangeFieldWithAttribute(
                  changes,
                  form,
                  fieldMapping,
                  name,
                  model,
                  formConfig,
                ).then(() => {
                  resolve();
                });
              } else {
                resolve();
              }
            },
            error: () => {
              resolve();
            },
          });
        }),
      );
    }

    if (fieldConfig.calculateFields?.length) {
      fieldConfig.calculateFields.forEach(calculateFieldConfig => {
        if (fieldMapping[calculateFieldConfig.fieldName]) {
          (fieldMapping[calculateFieldConfig.fieldName] as any)[calculateFieldConfig.attribute] =
            calculateFieldConfig.function(form);
        }
      });
    }

    if (fieldConfig.linkedComputeFields?.length) {
      const formValues = Object.values(form.getRawValue());
      fieldConfig.linkedComputeFields.forEach(linkedFieldName => {
        const linkedFieldConfig = fieldMapping[linkedFieldName];
        linkedFieldConfig?.computeField?.forEach(change => {
          if (change.conditionFunction?.(...formValues)) {
            switch (change.type) {
              case 'api':
                promises.push(
                  //! we need model here?
                  this.programmaticallyChangeFieldWithApi(
                    linkedFieldName,
                    change,
                    form,
                    fieldMapping,
                    model,
                    formConfig,
                  ),
                );
                break;
              case 'conditions':
                promises.push(
                  this.programmaticallyChangeFieldWithConditions(
                    linkedFieldName,
                    change,
                    form,
                    fieldMapping,
                    model,
                    formValues,
                    formConfig,
                  ),
                );
                break;
              case 'value':
                const attributeChange = {
                  field: linkedFieldConfig.name,
                  value: form.get(fieldConfig.name)?.value,
                };
                if (attributeChange.value) {
                  promises.push(
                    this.programmaticallyChangeFieldWithAttribute(
                      [attributeChange],
                      form,
                      fieldMapping,
                      name,
                      model,
                      formConfig,
                    ),
                  );
                }
                break;
              case 'function':
                const computedChanges = change.compute(form.getRawValue(), formConfig);
                promises.push(
                  this.programmaticallyChangeFieldWithAttribute(
                    computedChanges,
                    form,
                    fieldMapping,
                    name,
                    model,
                    formConfig,
                  ),
                );
                break;
              default:
                break;
            }
          }
        });
      });
    }

    if (fieldConfig.linkedValidateFields?.length) {
      fieldConfig.linkedValidateFields.forEach(linkedFieldName => {
        const linkedFieldConfig = fieldMapping[linkedFieldName];
        linkedFieldConfig?.validateField?.forEach(change => {
          this.getServerValidationMessages(linkedFieldName, form, fieldMapping);
        });
      });
    }

    return new Promise(resolve => {
      if (!promises.length) {
        resolve();
        return;
      }
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  }

  private programmaticallyChangeFieldWithAttribute(
    changes: { [key: string]: any }[],
    form: FormGroup,
    fieldMapping: { [key: string]: any },
    fieldName: string,
    model: DynamicFormModel,
    formConfig?: FormConfig,
  ): Promise<void> {
    const promises: Promise<void>[] = [];

    changes.forEach(change => {
      if (!form.controls[change.field]) {
        return;
      }
      Object.keys(change).forEach(attribute => {
        const attributeName: string = this.dynamicFormTranslationService.attributeDictionary[attribute] || attribute;
        const value = change[attribute];
        switch (attributeName) {
          case 'field':
            form.controls[value]?.updateValueAndValidity({ emitEvent: false, onlySelf: true });
            break;
          case 'value':
            form.controls[change.field]?.patchValue(value, { emitEvent: false, onlySelf: true });
            form.controls[change.field]?.updateValueAndValidity();
            promises.push(this.programmaticallyChangeFields(change.field, model));
            break;
          case 'locked':
            if (value) {
              form.controls[change.field]?.disable({ emitEvent: false, onlySelf: true });
              fieldMapping[change.field].locked = true;
            } else {
              form.controls[change.field]?.enable({ emitEvent: false, onlySelf: true });
              fieldMapping[change.field].locked = false;
            }
            break;
          case 'disabled':
            if (value) {
              form.controls[change.field]?.disable();
              fieldMapping[change.field].disabled = true;
            } else {
              form.controls[change.field]?.enable();
              fieldMapping[change.field].disabled = false;
            }
            break;
          case 'visible':
            if (fieldMapping[change.field]) {
              fieldMapping[change.field].hidden = !value;
            }
            break;
          case 'options':
            if (form.controls[change.field]?.value?.length) {
              const allowedOptions = this.formUtilService.flattenOptions(fieldMapping[change.field]?.options);
              const filteredValue = form.controls[change.field]?.value?.filter?.((value: any) =>
                allowedOptions.map((option: ValueAndLabel) => option.value).includes(value),
              );

              if (filteredValue?.length < form.controls[change.field]?.value.length) {
                promises.push(
                  this.programmaticallyChangeFieldWithAttribute(
                    [{ value: filteredValue, field: change.field }],
                    form,
                    fieldMapping,
                    fieldName,
                    model,
                  ),
                );
              }
            }
            fieldMapping[change.field].options = this.dynamicFormTranslationService.convertOptionTags(value);
            break;
          case 'field_error':
            const control = form.get(change.field);
            if (value.is_valid) {
              control?.setErrors(null);
            } else {
              control?.markAsTouched();
              control?.setErrors({
                serverError: value.description[change.field],
              });
            }
            break;
          default:
            fieldMapping[change.field][attributeName] = value;
            break;
        }
        if (!['field', 'value', 'disabled', 'locked', 'visible'].includes(attributeName)) {
          const validators = this.validationService.createValidators(fieldMapping[change.field], formConfig);
          form.controls[change.field]?.setValidators(validators);
        }

        // Only validate field once api errors have been resolved
        if (!['field_error'].includes(attributeName)) {
          form.controls[change.field]?.updateValueAndValidity({ emitEvent: false, onlySelf: true });
        }
      });
    });
    return new Promise(resolve => {
      if (!promises.length) {
        resolve();
        return;
      }
      Promise.all(promises).then(() => {
        resolve();
      });
    });
  }

  private programmaticallyChangeFieldWithApi(
    fieldName: string,
    change: ComputeFieldConfig,
    form: FormGroup,
    fieldMapping: DynamicFormFieldMapping,
    model: DynamicFormModel,
    formConfig?: FormConfig,
  ): Promise<void> {
    if (change.type !== 'api') {
      return new Promise(resolve => resolve());
    }

    const body = this.createConfigApiBody(change.fields, form);

    return new Promise(resolve => {
      this.coreApiService.callApi(change.url as string, body, change.method as HttpMethod).subscribe(
        response => {
          const attributeChange: any = {
            field: fieldName,
          };
          attributeChange[change.attribute] = response;
          this.programmaticallyChangeFieldWithAttribute(
            [attributeChange],
            form,
            fieldMapping,
            fieldName,
            model,
            formConfig,
          ).then(() => {
            resolve();
          });
        },
        error => {
          resolve();
        },
      );
    });
  }

  private programmaticallyChangeFieldWithConditions(
    fieldName: string,
    change: ComputeFieldConfig,
    form: FormGroup,
    fieldMapping: DynamicFormFieldMapping,
    model: DynamicFormModel,
    formValues: any[],
    formConfig?: FormConfig,
  ): Promise<void> {
    if (change.type !== 'conditions') {
      return new Promise(resolve => resolve());
    }
    const fieldData = form.getRawValue();
    const fieldNames = Object.keys(fieldData);

    let value: any = undefined;
    for (const conditionCase of change.other_conditions) {
      const conditionFunction = this.createConditionalFunction(conditionCase.condition, fieldNames, false);
      if (conditionFunction?.(...formValues)) {
        value = conditionCase.value;
        break;
      }
    }

    if (value === undefined) {
      if (change.default === undefined) {
        return new Promise(resolve => resolve());
      }
      value = change.default;
    }

    return new Promise(resolve => {
      const attributeChange: any = {
        field: fieldName,
      };
      attributeChange[change.attribute] = value;
      this.programmaticallyChangeFieldWithAttribute(
        [attributeChange],
        form,
        fieldMapping,
        fieldName,
        model,
        formConfig,
      ).then(() => {
        resolve();
      });
    });
  }

  setApiErrorsOnFormModel(model: DynamicFormModel, errors: any): void {
    if (!errors.non_field_errors) {
      for (const fieldName of Object.keys(errors)) {
        const control = model.form.get(fieldName);
        control?.markAsTouched();
        control?.setErrors({
          serverError: errors[fieldName],
        });
      }
    }
  }

  private getServerValidationMessages(name: string, form: FormGroup, fieldMapping: DynamicFormFieldMapping) {
    const validatedField = fieldMapping[name];
    const configValidateField = validatedField?.validateField;
    if (!configValidateField) {
      return;
    }

    const requestDetails = configValidateField.map((validateConfig: any) => {
      const body = this.createConfigApiBody(validateConfig.fields, form);
      return { config: validateConfig, body };
    });

    this.dynamicFormApiService
      .getValidationErrors(requestDetails)
      .pipe(take(1))
      .subscribe(errorText => {
        validatedField.warning = errorText;
      });
  }

  checkFormValidityAndTouch(model: DynamicFormModel, sections?: DynamicFormSection[]): boolean {
    if (!model) {
      return false;
    }
    if (!sections) {
      sections = model.sectionsForDisplay;
    }

    let isFormValid = true;
    for (const section of sections) {
      for (const fieldName of section.fieldNames) {
        const field = model.form.get(fieldName);
        if (!(field?.valid || field?.disabled)) {
          isFormValid = false;
          field?.markAsTouched();
        }
      }
    }
    return isFormValid;
  }

  getValueToSubmit(model: DynamicFormModel, formConfig: FormConfig = {}): Observable<{ [key: string]: any }> {
    const formValue = model.form.getRawValue();
    const jsonData: { [key: string]: any } = {};

    const files: { fieldName: string; fieldValue: File }[] = [];

    let fieldsToSubmit: Set<string>;

    if (formConfig.isFlexibleEdit) {
      const editedFields = Object.keys(model.fieldMapping).filter(fieldName => {
        return this.hasValueChanged(fieldName, model);
      });
      fieldsToSubmit = new Set(editedFields);
    } else {
      const displayedFields = model.sectionsForDisplay.flatMap(section => section.fieldNames);
      const requiredFields = Object.entries(model.fieldMapping)
        .filter(([fieldName, fieldConfig]) => fieldConfig.required)
        .map(([fieldName, fieldConfig]) => fieldName);
      fieldsToSubmit = new Set([...displayedFields, ...requiredFields]);
    }

    for (const fieldName of [...fieldsToSubmit]) {
      const fieldConfig = model.fieldMapping[fieldName];
      let fieldValue = formValue[fieldName];

      if (this.isContentTag(fieldName)) {
        if (!fieldValue) {
          fieldValue = '';
        } else if (fieldValue === fieldConfig?.suggestion) {
          fieldValue = null;
        }
      } else {
        if (
          (!formConfig?.isPartialSaveAllowed || (formConfig?.isPartialSaveAllowed && fieldConfig.required)) &&
          formConfig?.method !== HttpMethod.PATCH &&
          !fieldValue &&
          fieldValue !== 0 &&
          fieldValue !== false
        ) {
          continue;
        }

        if (fieldConfig.element === 'file' && !fieldValue) {
          continue;
        }

        if (fieldConfig.element === 'table') {
          if (!(fieldValue as any[])?.filter(val => ![null, undefined].includes(val)).length) {
            if (fieldConfig.required) {
              continue;
            } else {
              fieldValue = null;
            }
          }
        }

        if (
          fieldValue === null &&
          formConfig?.method === HttpMethod.PATCH &&
          fieldConfig.element === 'primitive' &&
          ['text', 'email', 'url', 'textarea'].includes(fieldConfig.type)
        ) {
          fieldValue = '';
        }
      }

      if (fieldValue instanceof File) {
        files.push({ fieldName, fieldValue });
      } else {
        jsonData[fieldName] = fieldValue;
      }
    }

    const filePromises = files.map(file => {
      return new Promise<void>(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.fieldValue);
        reader.onload = () => {
          jsonData[file.fieldName] = reader.result;
          resolve();
        };
        reader.onerror = error => {
          resolve();
        };
      });
    });

    return from(
      new Promise<{ [key: string]: any }>(resolve => {
        Promise.all(filePromises).then(() => resolve(jsonData));
      }),
    );
  }

  private createFormControl(fieldConfig: FormInputConfig, formConfig: FormConfig): FormControl {
    const validators = this.validationService.createValidators(fieldConfig, formConfig);
    return this.formBuilder.control(
      { value: this.formatFormValue(fieldConfig), disabled: fieldConfig.disabled || fieldConfig.locked },
      validators,
    );
  }

  createContentFormControl(fieldConfig: FormInputConfig): FormControl {
    return this.formBuilder.control(fieldConfig.initial ?? fieldConfig.suggestion);
  }

  private determineFieldConfigInitialValue(fieldConfig: FormInputConfig): FormInputConfig {
    if (fieldConfig.element === 'autocomplete' || fieldConfig.element === 'select') {
      if (!fieldConfig.options?.length || (fieldConfig.element === 'autocomplete' && fieldConfig.relaxed)) {
        return fieldConfig;
      }

      const flattenedOptions = this.formUtilService.flattenOptions(fieldConfig.options);
      if (fieldConfig.multiple) {
        const initial = fieldConfig.initial?.filter((initialValue: any) =>
          flattenedOptions.some(option => option.value?.toString() === initialValue?.toString()),
        );
        return { ...fieldConfig, initial };
      } else {
        const isInitialValueInOptions = flattenedOptions.some(
          option => option.value?.toString() === fieldConfig.initial?.toString(),
        );
        return { ...fieldConfig, initial: isInitialValueInOptions ? fieldConfig.initial : null };
      }
    }
    return fieldConfig;
  }

  private formatFormValue(fieldConfig: FormInputConfig): any {
    if (fieldConfig.element === 'primitive' && fieldConfig.type === 'number') {
      return (fieldConfig.initial ?? false) ? Number(fieldConfig.initial) : fieldConfig.initial;
    }
    if (fieldConfig.element === 'list' && fieldConfig.type === 'number') {
      return (fieldConfig.initial ?? false) ? this.formatNumberArray(fieldConfig.initial) : fieldConfig.initial;
    }
    return fieldConfig.initial;
  }

  private formatNumberArray(value: any): any {
    if (!Array.isArray(value)) {
      return value;
    }
    return value.map(val => Number(val));
  }

  private createConditionalFunction(
    condition: string,
    fieldNames: string[],
    fallbackValue = true,
  ): ConditionalFunction {
    const funcString =
      condition === undefined
        ? 'return true;'
        : `try {
            return ${condition};
          } catch (err) {
            console.error('existsFunction', err);
            return ${fallbackValue};
          }`;

    return new Function(...fieldNames, funcString) as ConditionalFunction;
  }

  updateFormModel(model: DynamicFormModel, formConfig: FormConfig, isSaved = false): void {
    const sectionsForDisplay = this.getSectionsForDisplay(model, formConfig);

    let errorCount = 0;
    let totalFields = 0;
    let validFields = 0;

    sectionsForDisplay.forEach(section => {
      errorCount += section.errorCount || 0;
      totalFields += section.fieldNames.length;
      validFields += section.validFields || 0;
    });

    const progress = this.formUtilService.percent(validFields, totalFields);
    const currentFields = sectionsForDisplay.flatMap(section => section.fieldNames);

    model.sectionsForDisplay = sectionsForDisplay;
    model.errorCount = errorCount;
    model.progress = progress;

    if (isSaved) {
      model.isUnsaved = false;
      model.savedFields = currentFields;
      model.savedFormValue = JSON.parse(JSON.stringify(model.form.getRawValue()));
      model.editedInputs = [];
    } else {
      model.editedInputs = this.updateEditedInputs({
        editedInputs: model.editedInputs ?? [],
        currentFields,
        savedFields: model.savedFields,
        model,
      });

      model.isUnsaved = model.editedInputs.some(input => {
        return this.canInputBeSaved(input, model, formConfig);
      });
    }
  }

  private getSectionsForDisplay(model: DynamicFormModel, formConfig: FormConfig = {}): DynamicFormSection[] {
    const sectionsForDisplay: DynamicFormSection[] = [];
    const formValues = Object.values(model.form.getRawValue());

    model.sections.forEach(section => {
      const sectionForDisplay: any = {
        fieldNames: [],
        subsections: [],
        validFields: 0,
        progress: 0,
        errorCount: 0,
      };

      section.fieldNames.forEach((fieldName: string) => {
        if (formConfig.isFlexibleEdit && this.isTemplateTag(fieldName)) {
          return;
        }

        const fieldConfig = model.fieldMapping[fieldName];
        if (fieldConfig.hidden || (fieldConfig.existsFunction && !fieldConfig.existsFunction(...formValues))) {
          return;
        }

        const formField = model.form.get(fieldName);
        const value = formField?.value;

        if (
          formField?.disabled ||
          (formField?.valid && (!fieldConfig.required || this.formUtilService.formInputHasValue(value, fieldConfig)))
        ) {
          sectionForDisplay.validFields += 1;
        }
        if (formField?.dirty && formField?.errors) {
          sectionForDisplay.errorCount += 1;
        }

        sectionForDisplay.fieldNames.push(fieldName);
      });

      section.subsections?.forEach((subsection: DynamicFormSection) => {
        sectionForDisplay.subsections.push(subsection);
      });

      if (sectionForDisplay.fieldNames.length === 0) {
        return;
      }

      sectionForDisplay.progress = this.formUtilService.percent(
        sectionForDisplay.validFields,
        sectionForDisplay.fieldNames.length,
      );

      sectionsForDisplay.push({ ...section, ...sectionForDisplay });
    });

    return sectionsForDisplay;
  }

  private hasValueChanged(fieldName: string, model: DynamicFormModel): boolean {
    const currentFormValue = model.form.getRawValue();
    const savedFormValue = model.savedFormValue;
    const fieldConfig = model.fieldMapping[fieldName];
    const ignoreOrder = this.isListFieldValue(fieldConfig);
    const ignoreWhitespace = !this.isContentTag(fieldName);

    let currentValue = currentFormValue[fieldName];
    let savedValue = savedFormValue[fieldName];

    if (fieldConfig.element === 'primitive' && fieldConfig.type === 'number') {
      currentValue = currentValue != null ? Number(currentValue) : null;
      savedValue = savedValue != null ? Number(savedValue) : null;
    }

    if (
      fieldConfig.element === 'table' &&
      fieldConfig.type === 'number' &&
      Array.isArray(currentValue) &&
      Array.isArray(savedValue)
    ) {
      currentValue = currentValue.map(v => Number(v));
      savedValue = savedValue.map(v => Number(v));
    }

    if (fieldConfig.element === 'richtext') {
      const sanitisedCurrentValue = JSON.parse(JSON.stringify(currentValue));
      if (sanitisedCurrentValue?.content) {
        sanitisedCurrentValue.content = this.richTextService.sanitiseRichTextInputValue(sanitisedCurrentValue.content);
      }
      const sanitisedSavedValue = JSON.parse(JSON.stringify(savedValue));
      if (sanitisedSavedValue?.content) {
        sanitisedSavedValue.content = this.richTextService.sanitiseRichTextInputValue(sanitisedSavedValue.content);
      }

      //* Special case where the sanitisedSavedValue is `null` and the content is an empty richtext object - which should be compared as if equal
      //* (and the `title` hasn't been set - which would happen if a `None` template had been selected - which SHOULD be considered a change)
      if (
        sanitisedSavedValue === null &&
        this.formUtilService.isRichTextValueEmpty(sanitisedCurrentValue?.content) &&
        !sanitisedCurrentValue?.title
      ) {
        return false;
      }

      return !this.formUtilService.areValuesEqual(
        sanitisedCurrentValue,
        sanitisedSavedValue,
        ignoreOrder,
        ignoreWhitespace,
      );
    }

    //tenor ranges are matching date_no_year values
    if (
      fieldConfig.element !== 'date' &&
      this.utilService.isTenorString(currentValue) &&
      this.utilService.isTenorString(savedValue)
    ) {
      return !this.formUtilService.areValuesEqual(
        this.levelsUtilService.getValueFromTenor(currentValue),
        this.levelsUtilService.getValueFromTenor(savedValue),
        ignoreOrder,
        ignoreWhitespace,
      );
    }
    return !this.formUtilService.areValuesEqual(currentValue, savedValue, ignoreOrder, ignoreWhitespace);
  }

  private isListFieldValue(config: FormInputConfig): boolean {
    if (config.element === 'autocomplete' && config.multiple) {
      return true;
    }
    return false;
  }

  private updateEditedInputs(params: {
    editedInputs: FlexibleEditInput[];
    currentFields: string[];
    savedFields: string[];
    model: DynamicFormModel;
  }): FlexibleEditInput[] {
    const saved = new Set(params.savedFields);
    const current = new Set(params.currentFields);
    const added: Set<string> = new Set();
    const changed: Set<string> = new Set();

    params.currentFields.forEach(fieldName => {
      if (this.hasValueChanged(fieldName, params.model)) {
        changed.add(fieldName);
      } else if (!saved.has(fieldName)) {
        added.add(fieldName);
      }
    });

    const updatedEditedInputs: FlexibleEditInput[] = [];
    params.editedInputs.forEach(input => {
      if (this.isContentTag(input.fieldName)) {
        updatedEditedInputs.push({
          ...input,
          type: this.hasValueChanged(input.fieldName, params.model) ? EditType.Changed : EditType.Unchanged,
        });
        return;
      }

      if (!current.has(input.fieldName)) {
        // input removed from form
        return;
      }

      if (changed.delete(input.fieldName)) {
        updatedEditedInputs.push({ ...input, type: EditType.Changed });
        return;
      }

      if (added.delete(input.fieldName) && !this.isContentTag(input.fieldName)) {
        updatedEditedInputs.push({ ...input, type: EditType.Added });
        return;
      }

      if (!input.isImpacted && input.isPersistent) {
        updatedEditedInputs.push({ ...input, type: EditType.Unchanged });
        return;
      }
    });

    const pushImpactedInputs = (set: Set<string>, type: EditType) => {
      updatedEditedInputs.push(
        ...[...set]
          .filter(fieldName => {
            return !this.isTemplateTag(fieldName);
          })
          .map(fieldName => {
            return {
              fieldName,
              isImpacted: true,
              type: type,
            };
          }),
      );
    };

    pushImpactedInputs(changed, EditType.Changed);
    pushImpactedInputs(added, EditType.Added);

    return updatedEditedInputs;
  }

  highlightFormField(inputName: string, sectionsForDisplay: DynamicFormSection[], idPrefix?: FormPrefixes) {
    let sectionToOpen: DynamicFormSection | undefined;

    sectionsForDisplay.some((section: any) => {
      if (section.fieldNames.includes(inputName)) {
        sectionToOpen = section;
        return true;
      }
      return false;
    });

    if (!sectionToOpen) {
      return;
    }

    this.openSection(sectionToOpen.label);

    setTimeout(() => {
      let count = 0;
      const interval = setInterval(() => {
        const inputToHighlight = document.getElementById(this.getFieldId(inputName, idPrefix));
        this.formUtilService.highlightScrollToElement(inputToHighlight);
        count += 1;
        if (count >= 40 || inputToHighlight) {
          clearInterval(interval);
        }
      }, 250);
    });
  }

  openSection(sectionName: string) {
    this.routingService.setUrlParam('section', sectionName);
  }

  getFilteredSections(formModel: DynamicFormModel, value: string): DynamicFormSection[] {
    if (!value) {
      return formModel?.sectionsForDisplay || [];
    }

    const lowerCaseSearchValue = value.toLowerCase();

    return (
      formModel?.sectionsForDisplay
        .map(section => {
          return {
            ...section,
            fieldNames: section.fieldNames.filter(fieldName => {
              const field: any = formModel?.fieldMapping[fieldName];
              const fieldControl = formModel?.form?.controls[fieldName];

              return (
                this.containsString(field.label, lowerCaseSearchValue) ||
                this.compareFieldValue(fieldControl?.value?.toString() || '', lowerCaseSearchValue) ||
                this.isValuePresentInOptions(field.options || [], fieldControl?.value, lowerCaseSearchValue) ||
                (field.element === 'richtext' && this.isValueInRichText(fieldControl?.value, lowerCaseSearchValue))
              );
            }),
          };
        })
        .filter(section => section.fieldNames.length) || []
    );
  }

  private compareFieldValue(fieldValue: any, lowerCaseSearchValue: string): boolean {
    if (Array.isArray(fieldValue)) {
      return fieldValue.some(item => this.containsString(item.toString(), lowerCaseSearchValue));
    }
    return this.containsString(fieldValue.toString(), lowerCaseSearchValue);
  }

  private isValuePresentInOptions(options: InputOptions, fieldValue: any, lowerCaseSearchValue: string): boolean {
    return options.some((option: ValueAndLabel | OptionGroup) => {
      if ((option as OptionGroup).options) {
        return this.isValuePresentInOptions((option as OptionGroup).options, fieldValue, lowerCaseSearchValue);
      }
      return (option as ValueAndLabel).value === fieldValue
        ? this.containsString(String((option as ValueAndLabel).label), lowerCaseSearchValue)
        : false;
    });
  }

  private containsString(firstValue: string, lowerCaseSecondValue: string): boolean {
    if (!lowerCaseSecondValue) {
      return true;
    }
    if (!firstValue) {
      return false;
    }
    return firstValue.toLowerCase().trim().includes(lowerCaseSecondValue.toLowerCase().trim());
  }

  private isValueInRichText(fieldValue: RichTextOption, lowerCaseSearchValue: string): boolean {
    if (fieldValue?.content) {
      return this.richTextService.richTextToSimpleText(fieldValue.content).includes(lowerCaseSearchValue);
    }
    return false;
  }
}
