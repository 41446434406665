import { Component, Input } from '@angular/core';

export interface CalendarEvent {
  title: string;
  start: string;
  end?: string;
  class?: 'is-accent' | 'is-alert' | 'is-warning' | string;
  data?: any;
  tooltip?: string;
  url?: string;
}

@Component({
  standalone: false,
  selector: 'om-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent {
  @Input()
  set events(events: any[]) {}

  // private ngOnDestroy$ = new Subject<void>();
  // private sidebarPosition: string;
  // private tooltips: Instance<Props>[] = [];

  // @ViewChild('calendar') set calendar(calendar: FullCalendarComponent | any) {
  //   // TODO: monitor this issue https://github.com/fullcalendar/fullcalendar/issues/4976
  //   // Without this workaround the calendar is not sized correctly.
  //   // Test: go to the ui-kit, refresh, then within the app navigate to calendar.
  //   // Make sure you can see all days along the top.
  //   // Resize the page - nothing should change. If something changes, there is still an error.
  //   setTimeout(() => {
  //     calendar.calendar.updateSize();
  //   });

  //   // need to manually trigger the calendar resize when the sidebar is toggled
  //   this.stateService.stream.display$.pipe(takeUntil(this.ngOnDestroy$)).subscribe((state: DisplayState) => {
  //     const sidebarPosition = state.sidebar.position;
  //     if (state.sidebarAnimationStartTime || this.sidebarPosition === sidebarPosition) {
  //       return;
  //     }
  //     if (this.sidebarPosition) {
  //       setTimeout(() => {
  //         calendar.calendar.updateSize();
  //       });
  //     }
  //     this.sidebarPosition = sidebarPosition;
  //   });
  // }

  // calendarOptions: CalendarOptions = {
  //   initialView: 'dayGridMonth',
  //   height: 'auto',
  //   contentHeight: 'auto',
  //   expandRows: true,
  //   eventClick: this.onEventClick.bind(this),
  //   eventContent: arg => {
  //     let container;

  //     if (arg.event.extendedProps.url) {
  //       container = document.createElement('a');
  //       container.href = arg.event.extendedProps.url;
  //     } else {
  //       container = document.createElement('span');
  //     }

  //     container.innerHTML = arg.event.title;
  //     if (arg.event.extendedProps.tooltip) {
  //       this.tooltips.push(
  //         tippy(container, {
  //           ...tippyDefaultProps,
  //           content: arg.event.extendedProps.tooltip,
  //         }),
  //       );
  //     }
  //     return { domNodes: [container] };
  //   },
  // };

  // @Input()
  // set events(events: CalendarEvent[]) {
  //   this.calendarOptions.events = events?.map(event => {
  //     const calendarEvent: EventInput = {
  //       title: event.title,
  //       classNames: event.class,
  //       extendedProps: event,
  //       tooltip: event.tooltip,
  //     };
  //     if (event.end) {
  //       calendarEvent.start = event.start;
  //       calendarEvent.end = `${event.end.slice(0, 10)} 23:59`;
  //     } else {
  //       calendarEvent.date = event.start;
  //     }
  //     return calendarEvent;
  //   });
  // }

  // constructor(private stateService: StateService, private routingService: RoutingService) {}

  // private onEventClick(info: { el: HTMLElement; event: EventApi; jsEvent: MouseEvent; view: ViewApi }) {
  //   info.jsEvent.preventDefault();
  //   info.jsEvent.stopPropagation();
  //   this.routingService.routeTo(info.event.extendedProps.url, info.jsEvent);
  // }

  // ngOnDestroy() {
  //   this.ngOnDestroy$.next();
  //   this.ngOnDestroy$.complete();
  //   this.tooltips.forEach(tooltip => tooltip.destroy());
  // }
}
