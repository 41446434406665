import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValueAndLabel } from '@morpho/core';
import { Store } from '@ngrx/store';
import { PartialIssuer } from 'apps/bankangle/src/app/core/patterns/issuer/issuer.model';
import { AuthenticatedUserState } from 'apps/bankangle/src/app/core/state/authenticated-user/authenticated-user.model';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { IssuerApiService } from '../../../core/patterns/issuer/issuer-api.service';
import { IssuerUtilService } from '../../../core/patterns/issuer/issuer-util.service';

interface IssuerSimilarityScore {
  score: number;
  issuer: PartialIssuer;
}

@Component({
  standalone: false,
  selector: 'om-issuer-selector',
  templateUrl: './issuer-selector.component.html',
})
export class IssuerSelectorComponent implements OnInit, OnChanges {
  private readonly ngOnDestroy$ = new Subject<void>();

  @Input() initialIssuerIds: number[] = [];
  @Output() selectedIssuerIds = new EventEmitter<number[]>();

  issuerFormControl: FormControl;
  issuerOptions$: Observable<ValueAndLabel[]>;
  issuerSuggestions$: Observable<ValueAndLabel[]>;

  constructor(
    private issuerApiService: IssuerApiService,
    private issuerUtilService: IssuerUtilService,
    private store: Store<AuthenticatedUserState>,
  ) {}

  ngOnInit(): void {
    this.issuerFormControl = new FormControl(this.initialIssuerIds);
    this.updateSuggestions();
    this.issuerFormControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
      .subscribe(() => this.emitChanges());
  }

  private scoreIssuer(issuer: PartialIssuer, currentSelection: PartialIssuer[]): number {
    if (this.issuerFormControl.value.includes(issuer.id)) {
      return Number.POSITIVE_INFINITY;
    }
    let score = 0;
    currentSelection.forEach(selectedIssuer => {
      if (issuer.sector === selectedIssuer.sector) {
        score += 5;
      }
      if (issuer.issuer_type === selectedIssuer.issuer_type) {
        score += 3;
      }
      if (issuer.country === selectedIssuer.country) {
        score += 2;
      }
    });
    if (score && issuer.short_name.length) {
      score += 1;
    }
    return score;
  }

  private updateSuggestions(): void {
    if (!this.issuerFormControl.value.length) {
      this.issuerOptions$ = this.issuerApiService.issuerFlattenedOptions$;
    } else {
      const rankedIssuers$: Observable<IssuerSimilarityScore[]> = this.issuerApiService.issuers$.pipe(
        map((issuers: PartialIssuer[]) => {
          const currentSelection: PartialIssuer[] = this.issuerFormControl.value.map(
            (id: number) => issuers.find(issuer => issuer.id === id) as PartialIssuer,
          );
          return issuers
            .reduce((acc: IssuerSimilarityScore[], issuer: PartialIssuer) => {
              acc.push({ issuer, score: this.scoreIssuer(issuer, currentSelection) });
              return acc;
            }, [])
            .sort((a, b) =>
              a.score === b.score ? a.issuer.company_name.localeCompare(b.issuer.company_name) : b.score - a.score,
            );
        }),
      );

      this.issuerOptions$ = rankedIssuers$.pipe(map(rankedIssuers => this.rankedIssuersToOptions(rankedIssuers)));
      this.issuerSuggestions$ = rankedIssuers$.pipe(
        map(rankedIssuers => {
          return rankedIssuers
            .filter(rank => !this.issuerFormControl.value.includes(rank.issuer.id))
            .slice(0, 5)
            .filter(rank => rank.score);
        }),
        map(rankedIssuers => this.rankedIssuersToOptions(rankedIssuers)),
      );
    }
  }

  private rankedIssuersToOptions(rankedIssuers: IssuerSimilarityScore[]): ValueAndLabel[] {
    return rankedIssuers.map(rank => this.issuerUtilService.optionFromIssuer(rank.issuer));
  }

  private emitChanges(): void {
    const issuerIds = this.issuerFormControl.value;
    this.selectedIssuerIds.emit(issuerIds);
    this.updateSuggestions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialIssuerIds && !changes.initialIssuerIds.firstChange) {
      this.issuerFormControl.setValue(changes.initialIssuerIds.currentValue, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
