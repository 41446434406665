import { Injectable } from '@angular/core';
import { ApiService } from '@morpho/core';
import { Observable } from 'rxjs';
import { UploadedDocumentInstance } from '../document-extractor.model';
@Injectable({
  providedIn: 'root',
})
export class DocumentExtractorApiService {
  constructor(private apiService: ApiService) {}

  uploadDocument(formData: { [key: string]: any }): Observable<UploadedDocumentInstance> {
    const endpoint = 'transactions/trade-onboard/';
    return this.apiService.post(endpoint, formData);
  }

  acceptLowConfidence(id: number, formData: { [key: string]: any }): Observable<any> {
    const endpoint = `transactions/trade-onboard/${id}/confidence-accept/`;
    return this.apiService.post(endpoint, formData);
  }

  getUploadDocument(id: number): Observable<UploadedDocumentInstance> {
    const endpoint = `transactions/trade-onboard/${id}/`;
    return this.apiService.get(endpoint);
  }

  createTrade(id: number, formValues: { [key: string]: any }): Observable<any> {
    const endpoint = `transactions/trade-onboard/${id}/submission-data/?is_create_final_terms_flow=true`;
    return this.apiService.post(endpoint, formValues);
  }

  sendMeta(id: number, data: { [key: string]: any }): Observable<any> {
    const endpoint = `transactions/trade-onboard/${id}/submission-meta/?is_create_final_terms_flow=true`;
    return this.apiService.post(endpoint, data);
  }
}
