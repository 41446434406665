import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { HttpMethod } from '@morpho/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { ProgressStep } from '@morpho/form';
import { Subject, interval } from 'rxjs';
import { DocumentListItem } from '../../../core/patterns/document-list/document-list.model';
import { DocumentListService } from '../../../core/patterns/document-list/document-list.service';
import {
  DocUploadResult,
  OriginGeneratedDocument,
  UploadedTradeDocument,
} from '../../../features/trades/state/trades.model';

enum TradeDocumentType {
  Loading = 'loading',
  Origin = 'origin',
  Upload = 'upload',
}

@Component({
  standalone: false,
  selector: 'om-document-vault-card',
  templateUrl: './document-vault-card.component.html',
  styleUrls: ['./document-vault-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentVaultCardComponent {
  readonly tradeDocumentType = TradeDocumentType;
  private ngOnDestroy$ = new Subject<void>();

  @Input() tradeId: number;
  @Input()
  set document(document: OriginGeneratedDocument | UploadedTradeDocument) {
    this.documentType = document.hasOwnProperty('uploaded_at') ? TradeDocumentType.Upload : TradeDocumentType.Origin;
    this.name = document.name;
    this.url = document.url;

    if (this.documentType == TradeDocumentType.Origin) {
      const originDoc = document as OriginGeneratedDocument;

      this.lastUpdated = originDoc.lastUpdated ?? '';
      this.documentDownloadItem = this.documentListService.createDocumentList([originDoc])[0];
    } else if (this.documentType == TradeDocumentType.Upload) {
      const uploadedDoc = document as UploadedTradeDocument;

      this.lastUpdated = uploadedDoc.uploaded_at;
      this.uploadedDocumentDownloadUrl = uploadedDoc.document;
      this.uploadedBy = uploadedDoc.uploaded_by;
    }

    this._document = document;
  }
  get document() {
    return this._document;
  }
  private _document: OriginGeneratedDocument | UploadedTradeDocument;

  @Input()
  set loadingDocument(document: DocUploadResult) {
    this.currentProgress.progress = 5; // start progress
    this.documentType = TradeDocumentType.Loading;

    const timerValues$ = interval(1000).subscribe(n => {
      if (n < 9) {
        this.currentProgress.progress = (n + 1) * 10;
      }
    });

    this.name = document.name;
    this.uploadError = '';

    if (document.success ?? false) {
      this.currentProgress.progress = 100;
      timerValues$.unsubscribe();
    }

    if (document.success === false && document.message) {
      this.uploadError = document.message;
    }
  }

  currentProgress: ProgressStep = {
    progress: 0,
  };

  @Output() deleteError: EventEmitter<string> = new EventEmitter();

  name: string;
  documentType: string;
  lastUpdated: string;
  url: string | null;
  uploadedDocumentDownloadUrl: string | null;
  uploadedBy: string;
  documentDownloadItem: DocumentListItem | null;
  uploadError: string;

  constructor(
    private documentListService: DocumentListService,
    private dialogService: DialogService,
  ) {}

  deleteUploadedDoc(uploadedDocumentId: number): void {
    const endpoint = `transactions/trade/${this.tradeId}/uploaded-trade-documents/${uploadedDocumentId}/`;
    const deleteDialogConfig: BooleanPopupParams = {
      title: 'Are you sure you want to delete document?',
      message: 'You cannot undo once deleted.',
      additionalInfo: this.name,
      right_action: {
        url: endpoint,
        method: HttpMethod.DELETE,
        text: 'Yes',
        class: 'is-warning',
        success_message: 'Uploaded Document has been successfully deleted',
        error_message: 'Unable to delete document. Please try again later',
      },
    };
    this.dialogService.booleanPopup(deleteDialogConfig);
  }

  onDeleteError(event: string): void {
    this.deleteError.emit(event);
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
