import { Component, ViewEncapsulation } from '@angular/core';

import { Editor } from 'slate';
import { AngularEditor, BaseElementComponent, isDOMNode } from 'slate-angular';
import { RichTextEquation } from '../../models/rich-text.model';
import { EquationEditor } from '../../plugins/equation/equation-commands';

@Component({
  standalone: false,
  selector: 'om-equation-editor',
  templateUrl: './equation-editor.component.html',
  styleUrls: ['./equation-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EquationEditorComponent extends BaseElementComponent<RichTextEquation> {
  onContentChange(equation: any) {
    const nativeElement = this.nativeElement;
    const isNode = isDOMNode(nativeElement);

    if (isNode) {
      const node = AngularEditor.toSlateNode(this.editor, nativeElement);
      const path = AngularEditor.findPath(this.editor, node);

      EquationEditor.updateEquation(this.editor as unknown as Editor, equation, path);
    }
  }
}
