import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DocumentExtractorState } from './document-extractor.model';
import { DOCUMENT_EXTRACTOR_STATE_KEY } from './document-extractor.reducer';

const documentExtractorState = createFeatureSelector<DocumentExtractorState>(DOCUMENT_EXTRACTOR_STATE_KEY);

export const DocumentExtractorSelector = {
  formOptions: createSelector(documentExtractorState, state => state.formOptions),
  formProgress: createSelector(documentExtractorState, state => state.formProgress),
  isDataConfirmed: createSelector(documentExtractorState, state => state.isDataConfirmed),
  isFormSaving: createSelector(documentExtractorState, state => state.isFormSaving),
  isSelectionSidebarOpen: createSelector(documentExtractorState, state => state.isSelectionSidebarOpen),
  sidenav: createSelector(documentExtractorState, state => state.sidenav),
  sidenavPosition: createSelector(documentExtractorState, state => state.sidenav.position),
  uploadedDocument: createSelector(documentExtractorState, state => state.uploadedDocument),
  seenFields: createSelector(documentExtractorState, state => state.seenFields),
};
