export enum AgencyName {
  Moodys = 'moodys',
  Fitch = 'fitch',
  Dbrs = 'dbrs',
  SP = 'sp',
  Scope = 'scope',
}

export enum AgencyProduct {
  ShortTerm = 'st',
  LongTerm = 'lt',
  CoveredBond = 'cb',
  SeniorUnsecured = 'su',
  SeniorPreferred = 'sp',
  SeniorUnsecuredNonPreferred = 'snp',
  LowerTierTwo = 'lt2',
}

export const AGENCY_PRODUCT_MAP: Record<AgencyProduct, string> = {
  [AgencyProduct.ShortTerm]: 'short_term',
  [AgencyProduct.LongTerm]: 'long_term',
  [AgencyProduct.CoveredBond]: 'covered_bond',
  [AgencyProduct.SeniorUnsecured]: 'senior_unsecured',
  [AgencyProduct.SeniorPreferred]: 'senior_preferred',
  [AgencyProduct.SeniorUnsecuredNonPreferred]: 'senior_unsecured_non_preferred',
  [AgencyProduct.LowerTierTwo]: 'lower_tier_2',
};

export const RATING_AGENCIES = [
  {
    key: AgencyName.Moodys,
    label: 'Moody‘s',
    longTermConstantsKey: 'moodys_ratings',
    shortTermConstantsKey: 'moodys_st_ratings',
  },
  { key: AgencyName.SP, label: 'S&P', longTermConstantsKey: 'sp_ratings', shortTermConstantsKey: 'sp_st_ratings' },
  {
    key: AgencyName.Fitch,
    label: 'Fitch',
    longTermConstantsKey: 'fitch_ratings',
    shortTermConstantsKey: 'fitch_st_ratings',
  },
  {
    key: AgencyName.Scope,
    label: 'Scope',
    longTermConstantsKey: 'scope_ratings',
    shortTermConstantsKey: 'scope_st_ratings',
  },
  {
    key: AgencyName.Dbrs,
    label: 'DBRS',
    longTermConstantsKey: 'dbrs_ratings',
    shortTermConstantsKey: 'dbrs_st_ratings',
  },
];
