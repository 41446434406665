import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DynamicFormModel, DynamicFormService } from '@morpho/dynamic-form';
import { FormService } from '@morpho/form';
import { ICellRendererParams } from 'ag-grid-community';
import { StateService } from 'apps/bankangle/src/app/core/services/state.service';
import { take, tap } from 'rxjs';
import { PricingRequestService } from '../../../services/pricing-request.service';
import { MaturitiesTableComponent } from '../maturities-table/maturities-table.component';

@Component({
  standalone: false,
  selector: 'om-add-pricing-tabs-dialog',
  templateUrl: './add-pricing-tabs-dialog.component.html',
  styleUrls: ['./add-pricing-tabs-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddPricingTabsDialogComponent implements OnInit {
  @ViewChild('maturitiesTable') maturitiesTable: MaturitiesTableComponent;
  form = this.formBuilder.group({
    maturityTableField: new FormControl(),
  });
  columns: any;
  maturityTableField: any;
  params: ICellRendererParams;

  tabFormModel: DynamicFormModel | null;
  maturityFormModel: DynamicFormModel | null;
  showPricingField = false;

  dialogStep = 1;
  isLoading = false;

  @ViewChild('dynamicForm') dynamicForm: ElementRef;

  constructor(
    private pricingRequestService: PricingRequestService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    public dialogRef: MatDialogRef<AddPricingTabsDialogComponent>,
    private dynamicFormService: DynamicFormService,
    private stateService: StateService,
  ) {}

  ngOnInit(): void {
    this.form.get('maturityTableField')?.valueChanges.subscribe();

    this.stateService.get.constants$
      .pipe(
        take(1),
        tap(constants => {
          const formOptions = [...this.pricingRequestService.generatePricingTypeFields(constants)];
          this.tabFormModel = this.dynamicFormService.generateFormModel(formOptions);
        }),
      )
      .subscribe();
  }

  previous() {
    if (this.dialogStep === 1) {
      this.dialogRef.close();
    } else {
      this.dialogStep -= 1;
    }
  }

  next() {
    if (this.dialogStep === 1) {
      if (!this.tabFormModel?.form) {
        return;
      }
      if (this.tabFormModel.form.valid) {
        this.dialogStep += 1;
      } else {
        this.formService.showFormErrors(this.tabFormModel.form);
      }
    } else {
      if (this.maturitiesTable.isValid()) {
        if (Object.keys(this.maturitiesTable.maturities).length) {
          this.dialogRef.close({ maturities: this.maturitiesTable.maturities });
        } else {
          this.dialogRef.close();
        }
      }
    }
  }
}
