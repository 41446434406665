import { Action, createReducer, on } from '@ngrx/store';
import { PermissionAction } from './permission.actions';
import { PermissionState } from './permission.model';

export const PERMISSION_STATE_KEY = 'permission';

const initialState: PermissionState = {};

const reducer = createReducer(
  initialState,

  on(PermissionAction.setCalculatedPermissions, (state, action): PermissionState => {
    return {
      ...state,
      ...action.params,
    };
  }),

  on(
    PermissionAction.getTradeShortcutsSuccess,
    (state, action): PermissionState => ({
      ...state,
      tradeShortcuts: action.params,
    }),
  ),

  on(
    PermissionAction.getPocSettingsSuccess,
    (state, action): PermissionState => ({
      ...state,
      pocSettings: action.params,
    }),
  ),

  on(
    PermissionAction.setCustomerSupportChatSupported,
    (state): PermissionState => ({
      ...state,
      isCustomerSupportChatSupported: true,
    }),
  ),
);

export function permissionReducer(state: PermissionState, action: Action) {
  return reducer(state, action);
}
