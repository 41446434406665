import { Component, HostBinding, Input } from '@angular/core';
import { AuthNotification, StandardNotification } from '@morpho/core';
import { Store } from '@ngrx/store';
import { NotificationsAction } from 'apps/bankangle/src/app/core/state/notifications.actions';
import { NewsItem } from 'apps/bankangle/src/app/models/news';

@Component({
  standalone: false,
  selector: 'om-notification-card',
  templateUrl: './notification-card.component.html',
})
export class NotificationCardComponent {
  @HostBinding('class.is-notification') isNotification = false;
  @HostBinding('class.is-notification-unread') isNotificationUnread = false;

  id: number;
  title: string;
  datetime: string;
  content: string;
  routeUrl: string;
  externalUrl: string;
  acceptUrl: string;
  declineUrl: string;
  areActionsShown: boolean;

  @Input()
  set config(config: AuthNotification | NewsItem | StandardNotification) {
    if ((config as any).created) {
      config = config as StandardNotification;

      this.isNotification = true;
      this.isNotificationUnread = !config.is_read;
      this.id = config.id;
      this.title = config.title;
      this.content = config.content;
      this.datetime = config.modified;
      this.routeUrl = config.link_url || '';
    } else if ((config as any).url) {
      config = config as NewsItem;

      this.title = config.category;
      this.datetime = config.published_at;
      this.content = config.title;
      this.externalUrl = config.url;
    } else {
      config = config as AuthNotification;

      this.isNotification = true;
      this.isNotificationUnread = !config.is_read;
      this.id = config.id;
      this.title = config.title;
      this.content = config.content;
      this.datetime = config.timestamp;
      this.routeUrl = config.profile_url || '';
      this.acceptUrl = config.accept_url || '';
      this.declineUrl = config.decline_url || '';
    }

    this.areActionsShown = this.isNotificationUnread && !!(this.acceptUrl || this.declineUrl);
  }

  constructor(private store: Store<any>) {}

  markAsRead() {
    if (this.isNotificationUnread && this.id && !this.areActionsShown) {
      this.store.dispatch(NotificationsAction.markNotificationAsRead({ params: { notificationId: this.id } }));
    }
  }

  respondToRequest(url: string) {
    this.store.dispatch(
      NotificationsAction.respondToAuthRequest({ params: { response_url: url, notificationId: this.id } }),
    );
  }
}
