import { ActionMenuConfig } from '@morpho/core';
import { CountryData } from 'apps/bankangle/src/app/models';
import { DealerOverview } from 'apps/bankangle/src/app/models/dealer';

export interface DealerProfileInfo {
  address: string;
  blurb: string;
  can_edit: boolean;
  country: string;
  dealer: DealerOverview;
  global_mtn_email: string;
  has_all_logos: boolean;
  is_live: boolean;
  legal_name: string;
  swap_entities: any[];
}

export interface DealerProfileEntity {
  lei: string;
  id?: number;
  name: string;
  acronym?: string;
  country: string;
  country_data?: CountryData;
  address_line_1: string;
  address_line_2?: string;
  city: string;
  postcode?: string;
  phone_number?: string;
  fax_number?: string;
  euroclear_number?: string;
  clearstream_number?: string;
  invoicing_contact_name?: string;
  invoicing_contact_emails?: string[];
  invoicing_contact_phone?: string;
  vat_number?: string;
  dataDisplay?: { label: string; value: string }[];
}

export interface DealerProfileContact {
  id?: number;
  first_name: string;
  last_name: string;
  full_name?: string;
  email: string;
  phone?: string;
  mobile?: string;
  location?: string;
  position: string;
  access_type: string;
  contact_type: string;
  can_edit: boolean;
  can_delete: boolean;
  is_user: boolean;
  team: string;
  actionMenu?: ActionMenuConfig;
  url: string;
}
interface DealerInfo {
  first_name: string;
  last_name: string;
  id: number;
}
export interface DealerProfileFallbackProvision {
  id: number;
  name: string;
  content: string;
  currency: string;
  types: string[];
  timestamp: string;
  updated_by: DealerInfo | null;
  dataDisplay?: { label: string; value: string }[];
}

export interface DealerProfileTargetMarket {
  id: number;
  type: string;
  content: string;
  timestamp: string;
  updated_by: DealerInfo | null;
  dataDisplay?: { label: string; value: string }[];
}

export interface DealerProfileDisclaimer {
  id: number;
  name: string;
  type: string;
  programme_type: string[];
  location: string;
  entities: number[];
  content: string;
  timestamp: string;
  updated_by: DealerInfo | null;
  displayList: { label: string; value: string }[];
  dataDisplay?: { label: string; value: string }[];
}

export enum TermsheetClauseType {
  DISCLAIMER = 'disclaimer',
  FALLBACK_PROVISION = 'fallbackProvision',
  TARGET_MARKET = 'targetMarket',
}

export enum DisclaimerType {
  INVESTOR = 'investor',
  ISSUER = 'issuer',
  SWAP = 'swap',
}
export interface TermsheetClause {
  clauseType: TermsheetClauseType;
  type?: DisclaimerType;
  tabs: any[];
}
