<mat-expansion-panel
  [expanded]="!isPanelOpen || isPanelOpen[panelName]"
  class="om-section"
  [class.is-active]="panelName === activePanel"
  [class.is-alert]="isAlert"
  [ngClass]="panelName === 'inputs' ? inputsSectionClass : ''"
  [disabled]="(sidenav$ | async)?.position === 'mini'"
>
  <mat-expansion-panel-header
    class="om-section-header"
    [omTooltip]="inputsTooltip"
    omTooltipNavigation
    [omTooltipDisabled]="(sidenav$ | async)?.isTooltipDisabled"
  >
    <mat-panel-title>
      <mat-icon [svgIcon]="headerIcon"></mat-icon>
      <ng-container *ngTemplateOutlet="headerTextTemplate"></ng-container>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>

  <div #inputsTooltip>
    <mat-nav-list class="om-section">
      <mat-list-item class="om-section-header">
        <ng-container *ngTemplateOutlet="headerTextTemplate"></ng-container>
      </mat-list-item>
      <ng-container *ngTemplateOutlet="itemsTemplate"></ng-container>
    </mat-nav-list>
  </div>
</mat-expansion-panel>
