<ag-grid-angular
  class="ag-theme-alpine"
  [gridOptions]="gridOptions"
  [rowData]="rowData"
  (cellEditingStopped)="onCellEditingStopped($event)"
  (gridReady)="onGridReady($event)"
></ag-grid-angular>
@if (!disabled && (!maxSize || !value || value.length < maxSize)) {
  <div>
    <button mat-button class="is-accent is-flat" (click)="add()">
      <span>Add a new row</span>
      <mat-icon svgIcon="add" class="is-accent"></mat-icon>
    </button>
  </div>
}
