import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, forwardRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ValueAndLabel } from '@morpho/core';
import { OptionGroup } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

type SelectValue = string | number | boolean | null;
@Component({
  standalone: false,
  selector: 'om-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
  providers: getCustomFormFieldProviders(forwardRef(() => SelectInputComponent)),
  encapsulation: ViewEncapsulation.None,
})
export class SelectInputComponent extends CustomFormFieldControlComponent implements AfterViewInit {
  ngAfterViewInit(): void {
    // This gets triggered when an option is selected
    this.selectInput?.optionSelectionChanges.subscribe(() => {
      // Get the selected option by looking up the selected value
      const flattenedOptionsList = this.utilService.flattenOptions(this.options);
      const selectedOption = flattenedOptionsList.filter(option => option.value === this.value)[0];
      // Sets the value to just the label
      this.labelValue = selectedOption ? selectedOption.label : '';
    });
  }

  // Var for displaying the selected label
  labelValue = '';

  hasGroupOptions: boolean;

  childValue: SelectValue;

  groupOptions: OptionGroup[];
  flatOptions: ValueAndLabel[];

  @ViewChild('selectInput', { read: undefined, static: false }) selectInput: MatSelect;

  @Input()
  get options(): ValueAndLabel[] | OptionGroup[] {
    return this._options;
  }
  set options(options: ValueAndLabel[] | OptionGroup[]) {
    if (!options?.length) {
      return;
    }
    this.hasGroupOptions = !!(options[0] as OptionGroup).divider_label;

    if (this.hasGroupOptions) {
      this._options = (options as OptionGroup[]).slice();
      this.groupOptions = this._options;
    } else {
      this._options = (options as ValueAndLabel[]).slice();
      this.flatOptions = this._options;
    }
  }
  private _options: ValueAndLabel[] | OptionGroup[] = [];

  @Input()
  get value(): SelectValue {
    return this._value;
  }
  set value(val: SelectValue) {
    this._value = val;
    this.emitChanges(true);
    this.childValue = val;
  }
  _value: SelectValue;

  @Input()
  get multiple(): boolean | null {
    return this._multiple;
  }
  set multiple(multiple: boolean | null) {
    this._multiple = coerceBooleanProperty(multiple);
  }
  private _multiple: boolean | null;

  selected(event: MatSelectChange): void {
    this.value = event.value;
    this.emitChanges();
  }

  public focus() {
    this.selectInput?.open();
  }
}
