import { AG_GRID_LICENSE_KEY, CDN_URL, HEAP_APP_ID, SENTRY_DSN } from './environment-variables';

type ValidEnvironmentName = 'unknown' | 'auto' | 'dev' | 'qa' | 'demo2' | 'login2';

function getEnvironmentName(): ValidEnvironmentName {
  const validNames: ValidEnvironmentName[] = ['auto', 'dev', 'qa', 'demo2', 'login2'];
  const name = window.location.hostname.split('.')[0] as ValidEnvironmentName;
  return validNames.includes(name) ? (name as ValidEnvironmentName) : 'unknown';
}

const environmentName = getEnvironmentName();

export const environment = {
  deployed: true,
  name: environmentName,
  appBaseHref: '/app',
  isInIframe: window.self !== window.top,

  cdnUrl: CDN_URL[environmentName],

  baseUrl: `${window.location.origin}`,
  apiUrl: `/api/`,
  webSocketsUrl: `wss://${window.location.host}/ws/`,
};

export const license = {
  agGrid: AG_GRID_LICENSE_KEY,
  heap: HEAP_APP_ID[environmentName],
  sentry: SENTRY_DSN,
};
