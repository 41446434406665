import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, PaginatedResponse, UtilService } from '@morpho/core';
import { CommentUsersData } from '@morpho/rich-text';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateService } from '../../../core/services/state.service';
import {
  TradeActivity,
  TradeDetails,
  TradePermissions,
  TradeStage,
  TradeWorkingGroup,
  TradeWorkingGroupCompany,
  TradeWorkingGroupCompanyList,
  TradeWorkingGroupUser,
} from '../models';
import { TradeBlotterPaginatedParams, TradeBlotterTrade } from '../models/trade-blotter-trade';
import { TradeDownloadOption, UploadedTradeDocument } from '../state/trades.model';
import { TradeEntryPoint } from '../trades.constants';

@Injectable({
  providedIn: 'root',
})
export class TradesApiService {
  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private utilService: UtilService,
  ) {}

  getExtraColumns(): Observable<string[]> {
    const endpoint = 'transactions/blotter-extra-fields/';
    return this.apiService.get(endpoint);
  }

  getReportOptions(): Observable<any> {
    const endpoint = 'transactions/report/options/';
    return this.apiService.get(endpoint).pipe(
      map(
        options =>
          options.filter((option: any) => {
            return option.name === 'report_name';
          })[0].options,
      ),
    );
  }

  getReport(report_name: string, trades: number[]): Observable<HttpResponse<Blob>> {
    const endpoint = 'transactions/report/';
    const body = { report_name, trades };
    const options = { responseType: 'blob', observe: 'response' };
    return this.apiService.post(endpoint, body, options);
  }

  getTradesPaginated(paginatedParams: TradeBlotterPaginatedParams): Observable<PaginatedResponse<TradeBlotterTrade>> {
    const endpoint = 'transactions/trade/';
    const { offset, limit, filterModel, ordering, is_test, is_trashed } = paginatedParams;
    const params = {
      offset,
      ...(limit ? { limit } : {}),
      ...filterModel,
      ...ordering,
      ...(is_trashed !== undefined ? { is_trashed } : {}),
      ...(is_test !== undefined ? { is_test } : {}),
    };
    return forkJoin([this.apiService.get(endpoint, params), this.stateService.get.constants$]).pipe(
      map(([trades, constants]) => {
        const senioritiesReverseLookup = constants.arrayFor.product_types.reduce((map: any, item: any) => {
          map[item.label] = item.value;
          return map;
        }, {});

        return {
          ...trades,
          results: trades.results.map((trade: TradeBlotterTrade) => {
            trade.seniority = senioritiesReverseLookup[trade.seniority] ?? trade.seniority; // todo change backend to pass value
            if (trade.issuer.square_logo) {
              trade.issuer.square_logo = this.utilService.processBackendResource(trade.issuer.square_logo);
            }
            if (trade.dealer.square_logo) {
              trade.dealer.square_logo = this.utilService.processBackendResource(trade.dealer.square_logo);
            }
            return trade;
          }),
        };
      }),
    );
  }

  getAllTradeIdsPaginated(paginatedParams: TradeBlotterPaginatedParams): Observable<number[]> {
    const endpoint = 'transactions/trade/retrieve-ids/';
    const { offset, limit, filterModel, ordering, is_test, is_trashed } = paginatedParams;
    const params = {
      offset,
      ...(limit ? { limit } : {}),
      ...filterModel,
      ...ordering,
      ...(is_trashed !== undefined ? { is_trashed } : {}),
      ...(is_test !== undefined ? { is_test } : {}),
    };
    return this.apiService.get(endpoint, params);
  }

  getTradeDocumentCompletion(version: number): Observable<number | null> {
    const endpoint = `transactions/version/${version}/document-completion/`;
    return this.apiService.get(endpoint).pipe(map(response => response.percentage_completed));
  }

  setTradeDocumentCompletion(version: number, value: number) {
    const endpoint = `transactions/version/${version}/document-completion/`;
    const postData = { value };
    return this.apiService.patch(endpoint, postData);
  }

  getTradeDownloadOptions(tradeId: number): Observable<TradeDownloadOption[]> {
    const endpoint = `transactions/trade/${tradeId}/output-viewer/`;
    return this.apiService.get(endpoint);
  }

  getTradeActivities(tradeId: number): Observable<{ id: number; activities: TradeActivity[] }> {
    const url = `transactions/trade/${tradeId}/activities/`;
    return this.apiService.get(url).pipe(map(response => ({ id: tradeId, ...response })));
  }

  getTradeActivitiesExport(tradeId: number): Observable<HttpResponse<Blob>> {
    const url = `transactions/trade/${tradeId}/export_activities/`;
    return this.apiService.get(url, {}, { responseType: 'blob', observe: 'response' });
  }

  getTradeDetails(tradeId: number): Observable<{ id: number; details: TradeDetails; shortDescription: string }> {
    const url = `transactions/trade/${tradeId}/`;
    return this.apiService.get(url).pipe(
      map((trade: TradeDetails) => {
        const tradeDescriptionData: string = this.formatTradeDescription(trade);
        trade.issuer.square_logo = this.utilService.processBackendResource(trade.issuer.square_logo);
        return { id: tradeId, details: trade, shortDescription: tradeDescriptionData };
      }),
    );
  }

  getTradePermissions(tradeId: number): Observable<{ id: number; permissions: TradePermissions }> {
    const url = `transactions/trade/${tradeId}/permissions/`;
    return this.apiService.get(url).pipe(map(response => ({ id: tradeId, permissions: response })));
  }

  getTradeStages(tradeId: number): Observable<{ id: number; stages: TradeStage[] }> {
    const url = `transactions/trade/${tradeId}/stage/steps/`;
    return this.apiService.get(url).pipe(
      map(response => {
        return { id: tradeId, stages: response.stages };
      }),
    );
  }

  getTermsheetDocuments(tradeId: number) {
    const url = `transactions/trade/${tradeId}/documents/`;
    return this.apiService.get(url);
  }

  getUploadedTradeDocuments(tradeId: number): Observable<UploadedTradeDocument[]> {
    const url = `transactions/trade/${tradeId}/uploaded-trade-documents/`;
    return this.apiService.get(url);
  }

  uploadTradeDocuments(tradeId: number, formData: FormData) {
    const url = `transactions/trade/${tradeId}/uploaded-trade-documents/bulk-create/`;
    return this.apiService.post(url, formData);
  }

  downloadAllTradeDocuments(tradeId: number): Observable<HttpResponse<Blob>> {
    const url = `transactions/all-trade-documents/${tradeId}/serve/`;
    return this.apiService.get(url, {}, { responseType: 'blob', observe: 'response' });
  }

  reuseTrade(tradeId: number, isTest: boolean, entryPoint: TradeEntryPoint) {
    const url = `transactions/trade/${tradeId}/reuse/`;
    const body = {
      is_test: isTest,
      entry_point: entryPoint,
    };
    return this.apiService.post(url, body);
  }

  sendMessage(tradeId: number, message: string) {
    const url = `transactions/trade/${tradeId}/message/`;
    const body = {
      enquiry_id: tradeId,
      message,
    };
    return this.apiService.post(url, body);
  }

  moveTrade(trade: TradeBlotterTrade) {
    const endpoint = `transactions/trade/${trade.is_trashed ? 'restore' : 'trash'}/`;
    const params = { trade_ids: [trade.id] };

    return this.apiService.post(endpoint, params);
  }

  getTradeCommentUsers(tradeId: number): Observable<CommentUsersData[]> {
    const url = `transactions/trade/${tradeId}/comments/users/`;
    return this.apiService.get(url);
  }

  getTradeWorkingGroups(tradeId: number): Observable<TradeWorkingGroup[]> {
    const url = `transactions/working-groups/?trade_id=${tradeId}`;
    return this.apiService.get(url).pipe(
      map((response: TradeWorkingGroup[]) => {
        return [...response].map((workingGroup: TradeWorkingGroup) => this.convertWorkingGroupLogoUrls(workingGroup));
      }),
    );
  }

  addWorkingGroup(trade: number, users: number[]) {
    const url = `transactions/working-groups/`;
    const body = {
      trade: Number(trade),
      users,
    };
    return this.apiService.post(url, body).pipe(
      map((workingGroup: TradeWorkingGroup) => {
        return this.convertWorkingGroupLogoUrls(workingGroup);
      }),
    );
  }

  getWorkingGroupUsers(workingGroupId: number): Observable<TradeWorkingGroupUser[]> {
    const url = `transactions/working-groups/${workingGroupId}/company-user-list/`;
    return this.apiService.get(url).pipe(
      map(response => {
        return response.map((user: any) => {
          return { ...user, display_title: `${user.first_name} ${user.last_name}`, display_subtitle: user.team };
        });
      }),
    );
  }

  addWorkingGroupUsers(workingGroupId: number, users: number[]) {
    const url = `transactions/working-groups/${workingGroupId}/add-users/`;
    const body = {
      users,
    };
    return this.apiService
      .post(url, body)
      .pipe(map((workingGroup: TradeWorkingGroup) => this.convertWorkingGroupLogoUrls(workingGroup)));
  }

  deleteWorkingGroupUsers(workingGroupId: number, users: number[]) {
    const url = `transactions/working-groups/${workingGroupId}/remove-users/`;
    const body = {
      users,
    };
    return this.apiService
      .post(url, body)
      .pipe(map((workingGroup: TradeWorkingGroup) => this.convertWorkingGroupLogoUrls(workingGroup)));
  }

  getWorkingGroupAdditionalCompanies(tradeId: number): Observable<TradeWorkingGroupCompany[]> {
    const url = `transactions/trade/${tradeId}/additional-companies/`;
    return this.apiService.get(url).pipe(
      map((response: TradeWorkingGroupCompanyList) => {
        const additionalCompanies = [
          ['dealers', 'Dealer'],
          ['issuers', 'Issuer'],
          ['law_firms', 'Law Firm'],
        ].flatMap(([key, label]) => {
          return (response?.[key as keyof TradeWorkingGroupCompanyList] ?? []).map(
            (company: TradeWorkingGroupCompany) => {
              return {
                ...company,
                company_type_label: label,
                display_title: company.company_name,
                display_subtitle: label,
              };
            },
          );
        });
        return additionalCompanies;
      }),
    );
  }

  getCompanyUsers(companyId: number, companyType: 'banks' | 'law-firms' | 'issuers') {
    const url = `companies/${companyType}/${companyId}/trade-enabled-employees/`;
    return this.apiService.get(url).pipe(
      map(response => {
        return response.map((user: any) => {
          return { ...user, display_title: `${user.first_name} ${user.last_name}`, display_subtitle: user.team };
        });
      }),
    );
  }

  private convertWorkingGroupLogoUrls(workingGroup: TradeWorkingGroup): TradeWorkingGroup {
    return {
      ...workingGroup,
      company: {
        ...workingGroup.company,
        logo: this.utilService.processBackendResource(workingGroup.company.logo),
        square_logo: this.utilService.processBackendResource(workingGroup.company.square_logo),
      },
    };
  }

  getFormattedTradeSize(tradeDetails: TradeDetails): string {
    let formattedTradeSize;
    if ((tradeDetails.size_min || tradeDetails.size_min === 0) && tradeDetails.stage_key === 'indicative_termsheet') {
      formattedTradeSize = `[${this.utilService.applyCommasToNumber(
        tradeDetails.size_min,
      )} - ${this.utilService.applyCommasToNumber(tradeDetails.size)}]`;
    } else {
      formattedTradeSize = this.utilService.applyCommasToNumber(tradeDetails.size);
    }
    return tradeDetails.currency ? `${tradeDetails.currency} ${formattedTradeSize}` : formattedTradeSize;
  }

  private formatTradeDescription(trade: TradeDetails): string {
    const tradeDescriptionData: string[] = [
      `${trade.isin_code ?? ''}`,
      this.getFormattedTradeSize(trade),
      trade.enquiry_type === 'callable'
        ? trade.callable_structure ?? ''
        : trade.enquiry_type === 'bullet'
          ? trade.tenor ?? ''
          : '',
      `${trade.structure ?? ''}`,
      trade.product,
      trade.documentation,
    ];

    if (trade.dealer_internal_id) {
      tradeDescriptionData.unshift(trade.dealer_internal_id);
    }

    return tradeDescriptionData
      .map((item: any) => (item ?? '').trim())
      .filter((item: string) => !!item)
      .join(' | ');
  }
}
