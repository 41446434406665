import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BANNER_TEST_TRADE, NotificationType, RoutingService, StandardNotification, UtilService } from '@morpho/core';
import { CarouselModalOverlayRef } from '@morpho/dialog';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { NotificationsAction } from 'apps/bankangle/src/app/core/state/notifications.actions';
import { TradesApiService } from 'apps/bankangle/src/app/features/trades/services/trades-api.service';
import { AuthenticatedUserSelector } from '../../../core/state/authenticated-user/authenticated-user.selectors';

interface NotificationModalModel {
  title: string;
  banner: string;
  logo: string;
  details: string[];
  sender: string;
  datetime: string;
  documents: { name: string }[];
  message: string;
  linkUrl: string;

  originatorType: string;
  originatorName: string;
  action: string;
}
@Component({
  standalone: false,
  selector: 'om-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
  notification: StandardNotification;
  model: NotificationModalModel;

  @Input() slideData: StandardNotification;
  @Input() carouselDialogRef: CarouselModalOverlayRef;
  @Output() closeSlide: EventEmitter<any> = new EventEmitter();

  constructor(
    private routingService: RoutingService,
    private store: Store<any>,
    private tradesApiService: TradesApiService,
    private utilService: UtilService,
  ) {}

  ngOnInit(): void {
    if (this.slideData) {
      this.notification = this.slideData;

      this.processNotification(this.slideData);
    }
  }

  private processNotification(notification: StandardNotification) {
    this.tradesApiService
      .getTradeDetails(notification.data.trade_id as number)
      .pipe(concatLatestFrom(() => this.store.select(AuthenticatedUserSelector.isIssuer)))
      .subscribe({
        next: ([response, isIssuer]) => {
          const model: Partial<NotificationModalModel> = {};
          const tradeDetails = response.details;

          model.banner = tradeDetails.is_test ? BANNER_TEST_TRADE : '';

          const logo = isIssuer ? tradeDetails.dealer.logo : tradeDetails.issuer.logo;
          model.logo = this.utilService.processBackendResource(logo);

          model.details = [];

          const firstDetail = [this.tradesApiService.getFormattedTradeSize(tradeDetails)];

          if (notification.data.tenor) {
            firstDetail.push(notification.data.tenor);
          }
          if (notification.data.callable_structure) {
            firstDetail.push(notification.data.callable_structure);
          }
          if (notification.data.structure) {
            firstDetail.push(notification.data.structure);
          }
          model.details.push(firstDetail.join(' '));

          model.details.push(`${notification.data.product} ${notification.data.documentation}`);
          if (tradeDetails.funding_level) {
            const isTapped = tradeDetails.is_tapped ? 'Tapped ' : '';
            model.details.push(`${isTapped}${tradeDetails.funding_level}`);
          }

          switch (notification.notice_type) {
            case NotificationType.TradeWorkingGroupUpdated:
              model.action = 'View trade';
              model.title = 'New Trade';
              model.originatorType = 'Added by';
              break;

            case NotificationType.TradeReviewRequested:
              model.action = `Review ${notification.data.stage}`;
              model.title = `Review ${notification.data.stage}`;
              model.originatorType = 'Requested by';
              break;

            default:
              model.action = `Pick up ${notification.data.stage}`;
              model.title = `New ${notification.data.stage}`;
              model.originatorType = 'Shared by';
              break;
          }

          model.originatorName = notification?.data?.actor_name;
          model.datetime = this.utilService.convertFromBackendDateStringFormat(
            tradeDetails.last_modified,
            'datetime',
            'date',
          );
          model.message = notification.data.message;
          model.linkUrl = notification.link_url || '';

          this.model = { ...this.model, ...model };
        },
        error: () => {
          this.closeSlide.emit();
        },
      });

    this.tradesApiService.getTermsheetDocuments(notification.data.trade_id as number).subscribe((response: any) => {
      this.model = { ...this.model, documents: response };
    });
  }

  onPickUp() {
    if (this.model.linkUrl) {
      this.routingService.routeTo(this.model.linkUrl);
    }
    if (!this.notification.is_read && this.notification.id) {
      this.store.dispatch(
        NotificationsAction.markNotificationAsRead({ params: { notificationId: this.notification.id } }),
      );
    }
    this.carouselDialogRef.close();
  }

  onClose() {
    if (!this.notification.is_read && this.notification.id) {
      this.store.dispatch(
        NotificationsAction.markNotificationAsRead({ params: { notificationId: this.notification.id } }),
      );
    }
    this.closeSlide.emit();
  }
}
