import {
  largeScreenBreakPoint,
  sidebarLogoUrl,
  Sidenav,
  SidenavMode,
  SidenavPosition,
  smallScreenBreakPoint,
  toggleSidebarAnimationTransitionDuration,
} from '@morpho/core';
import { Action, createReducer, on } from '@ngrx/store';
import { DisplayActions } from './display.actions';
import { Banner, DisplayState } from './display.models';

const sidebarPositionStorageKey = 'omSidebarPosition';
export const DISPLAY_STATE_KEY = 'display';

const initialState: DisplayState = {
  sidebar: {
    mode: SidenavMode.Side,
    position: SidenavPosition.Mini,
    isTooltipDisabled: false,
  },
  logoUrl: sidebarLogoUrl.large,
  isSmallScreen: true,
  isLargeScreen: false,
};

const reducer = createReducer(
  initialState,

  on(DisplayActions.setWindowSize, (state, action: { params: Partial<Window> }) => {
    const isSmallScreen = (action.params.innerWidth as number) < smallScreenBreakPoint;
    const isLargeScreen = (action.params.innerWidth as number) >= largeScreenBreakPoint;

    if (isSmallScreen === state.isSmallScreen && isLargeScreen === state.isLargeScreen) {
      return state;
    }

    document.body.classList.remove('is-small-screen');
    document.body.classList.remove('is-large-screen');
    let sidebar: Sidenav;

    if (isSmallScreen) {
      document.body.classList.add('is-small-screen');
      sidebar = { ...state.sidebar, mode: SidenavMode.Side, position: SidenavPosition.Mini, isTooltipDisabled: false };
    } else {
      if (isLargeScreen) {
        document.body.classList.add('is-large-screen');
      }
      let position: any = window.localStorage.getItem(sidebarPositionStorageKey) || SidenavPosition.Open;
      if (![SidenavPosition.Open, SidenavPosition.Mini].includes(position)) {
        position = SidenavPosition.Open;
      }
      sidebar = {
        ...state.sidebar,
        mode: SidenavMode.Side,
        position,
        isTooltipDisabled: position !== SidenavPosition.Mini,
      };
    }
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, toggleSidebarAnimationTransitionDuration);
    return { ...state, ...action.params, sidebar, isSmallScreen, isLargeScreen };
  }),

  on(DisplayActions.toggleMainSidebar, (state: DisplayState, actions) => {
    const newState: DisplayState = {
      ...state,
      sidebarAnimationStartTime: Date.now(),
    };

    let newPosition: SidenavPosition;

    if (actions.params?.position) {
      if (actions.params.position === SidenavPosition.Default) {
        newPosition = window.localStorage.getItem(sidebarPositionStorageKey) as SidenavPosition;
      } else {
        newPosition = actions.params.position;
      }
    } else {
      if (state.sidebar.position === SidenavPosition.Open) {
        newPosition = SidenavPosition.Mini;
      } else {
        newPosition = SidenavPosition.Open;
      }
    }

    switch (newPosition) {
      case SidenavPosition.Open:
        newState.sidebar = {
          ...newState.sidebar,
          position: SidenavPosition.Open,
          isTooltipDisabled: true,
          mode: newState.isSmallScreen ? SidenavMode.Over : SidenavMode.Side,
        };
        newState.logoUrl = sidebarLogoUrl.large;
        break;
      case SidenavPosition.Mini:
        newState.sidebar = {
          ...newState.sidebar,
          position: SidenavPosition.Mini,
          isTooltipDisabled: false,
        };
        break;
      default:
        return state;
    }
    return newState;
  }),

  on(DisplayActions.toggleMainSidebarComplete, (state: DisplayState, actions) => {
    if (Date.now() - (state.sidebarAnimationStartTime || 0) >= toggleSidebarAnimationTransitionDuration) {
      const newState: DisplayState = { ...state };
      if (state.sidebar.position === SidenavPosition.Open) {
        newState.logoUrl = sidebarLogoUrl.large;
      } else {
        newState.logoUrl = sidebarLogoUrl.small;
        newState.sidebar = {
          ...newState.sidebar,
          mode: SidenavMode.Side,
        };
      }
      if (!state.isSmallScreen && !actions.params?.isTemporary) {
        window.localStorage.setItem(sidebarPositionStorageKey, state.sidebar.position);
      }
      delete newState.sidebarAnimationStartTime;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      });
      return newState;
    }
    return state;
  }),

  on(DisplayActions.showBanner, (state: DisplayState, action) => {
    if (!action.params) {
      return state;
    }
    const bannersToAdd = Array.isArray(action.params) ? action.params : [action.params];
    const bannerNames = bannersToAdd.map((banner: any) => banner.name);

    const bannerFilter = (banner: Banner) => !bannerNames.includes(banner.name);
    const banners = state.banners?.filter(bannerFilter) || [];
    const bannersInPage = state.bannersInPage?.filter(bannerFilter) || [];
    bannersToAdd.forEach((banner: Banner) => {
      if (banner.isInPage) {
        bannersInPage.push(banner);
      } else {
        banners.push(banner);
      }
    });

    return { ...state, banners, bannersInPage };
  }),

  on(DisplayActions.hideBanner, (state: DisplayState, action) => {
    if (!state.banners) {
      return state;
    }
    const bannersToRemove = Array.isArray(action.params) ? action.params : [action.params];
    return {
      ...state,
      banners: state.banners?.filter((banner: any) => !bannersToRemove.includes(banner.name)),
      bannersInPage: state.bannersInPage?.filter((banner: any) => !bannersToRemove.includes(banner.name)),
    };
  }),
);

export function displayReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
