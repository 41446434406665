<ul>
  @for (document of documentDownloadList; track document; let i = $index) {
    <li>
      <div class="document-download-wrapper">
        <div class="document-download" [class.document-download--is-focussed]="selectedIndex === i">
          <span class="spacer">{{ document.label }}</span>
          @if (document.url) {
            <a
              class="doc-info"
              [routerLink]="document.url | routing: 'routerLink'"
              [queryParams]="document.url | routing: 'queryParams'"
              ><ng-container *ngTemplateOutlet="docInfoTemplate"></ng-container
            ></a>
          } @else {
            <span class="doc-info doc-info--no-link"
              ><ng-container *ngTemplateOutlet="docInfoTemplate"></ng-container
            ></span>
          }
          <om-document-download-button
            class="icon-action"
            (menuClosed)="onMenuClosed()"
            (selected)="onSelectDocument(i)"
            [documentDownloadItem]="document"
          ></om-document-download-button>
        </div>
      </div>
      <ng-template #docInfoTemplate>
        {{ document.label }} <span class="icon"><mat-icon svgIcon="view"></mat-icon></span>
      </ng-template>
    </li>
  }
</ul>
