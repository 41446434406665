<mat-card appearance="outlined" class="mat-elevation-z1">
  <mat-card-header>
    @if (image || icon) {
      <ng-container mat-card-avatar>
        @if (image) {
          <img mat-card-avatar [src]="image" />
        } @else {
          <mat-icon mat-card-avatar [svgIcon]="icon"></mat-icon>
        }
      </ng-container>
    }
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  @if (message) {
    <mat-card-content>
      <p>{{ message }}</p>
    </mat-card-content>
  }
</mat-card>
