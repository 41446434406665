import { Component, ElementRef, forwardRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { OptionGroup, RichTextOption } from '@morpho/form';
import { EditorType, EMPTY_PARAGRAPH, RichText } from '@morpho/rich-text';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

const NO_TEMPLATE_LABEL = 'None';
@Component({
  standalone: false,
  selector: 'om-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => RichTextInputComponent)),
})
export class RichTextInputComponent extends CustomFormFieldControlComponent {
  @ViewChild('templateSelect') templateSelect: ElementRef;

  @Input()
  get options(): RichTextOption[] {
    return this._options;
  }
  set options(options: RichTextOption[]) {
    this._options = options;

    if (options) {
      this.templateOptions = options.map(option => {
        return {
          label: option.title,
          value: JSON.stringify(option.content),
        };
      });

      this.templateOptions = [this.resetOption, ...this.templateOptions];
    }
  }
  private _options: RichTextOption[] = [];

  @Input()
  get value(): RichTextOption | null {
    return this._value;
  }
  set value(value: RichTextOption | null) {
    if (value?.content?.length) {
      const richTextValueMutable = JSON.parse(JSON.stringify(value));
      richTextValueMutable.content = this.richTextService.sanitiseRichTextInputValue(richTextValueMutable.content);
      this._value = richTextValueMutable;
    } else if (value) {
      this._value = {
        ...value,
        content: null,
      };
    } else {
      this._value = null;
    }

    this.emitChanges();

    this.richTextValue = this._value?.content ?? [EMPTY_PARAGRAPH];
    this.placeholderText = this._value?.title || this.defaultPlaceholder;
    this.checkIsEdited();
  }
  protected _value: RichTextOption | null;

  @HostBinding('class.is-read-only')
  @Input()
  readonly = false;

  @HostBinding('class.is-full')
  public get isFull(): boolean {
    return this.editorType === EditorType.Full;
  }

  @Input() editorType = EditorType.Full;
  @Input() mentionsOptions: OptionGroup[];
  @Input() userFullName: string;

  currentTemplateTitle: string;
  defaultPlaceholder = 'Select Template';
  editedLabel = ' (Edited)';
  isEdited = false;
  placeholderText = this.defaultPlaceholder;
  richTextValue: RichText | null;
  selectedTemplate: string;
  templateOptions: ValueAndLabel[];

  resetOption: ValueAndLabel = {
    label: NO_TEMPLATE_LABEL,
    value: JSON.stringify([EMPTY_PARAGRAPH]),
  };

  EditorType = EditorType;

  onEnterPressed(event: KeyboardEvent) {}

  onSelectedTemplateChange(event: any) {
    this.currentTemplateTitle = '';

    for (const templateOption of this.templateOptions) {
      if (templateOption.value === event) {
        this.currentTemplateTitle = templateOption.label;
        break;
      }
    }

    this.value = {
      title: this.currentTemplateTitle,
      content: JSON.parse(this.selectedTemplate),
    };

    this.placeholderText = this.currentTemplateTitle;
  }

  onRichTextChange(richText: RichText) {
    if (richText) {
      const richTextValue: RichTextOption = { title: this.value?.title ?? '', content: richText };
      if (!this.utilService.areObjectsEquivalent(this.value, richTextValue)) {
        this.value = {
          ...richTextValue,
          content: this.richTextService.sanitiseRichTextInputValue(JSON.parse(JSON.stringify(richText))),
        };
      }
      this.checkIsEdited();
    } else {
      this.value = null;
    }
  }

  private checkIsEdited() {
    if (!this.templateOptions) {
      return;
    }
    for (const templateOption of this.templateOptions) {
      if (templateOption.label === this.value?.title) {
        const currentValue = this.value?.content ?? null;
        const templateValue = templateOption.value
          ? this.richTextService.sanitiseRichTextInputValue(JSON.parse(templateOption.value as string))
          : null;
        this.isEdited =
          this.value?.title !== NO_TEMPLATE_LABEL &&
          !this.utilService.areObjectsEquivalent(currentValue, templateValue);

        this.setEditedLabel(templateOption.label);
        break;
      }
    }
  }

  private setEditedLabel(labelText?: string) {
    //* Find label that is created 'internally' by material
    const label: Element = this.templateSelect?.nativeElement.getElementsByTagName('mat-select-trigger')[0];
    if (label) {
      setTimeout(() => {
        label.textContent = `${labelText}${this.isEdited ? this.editedLabel : ''}`;
      });
    }
  }
}
