import { SidenavPosition } from '@morpho/core';
import { createAction, props } from '@ngrx/store';
import { Banner } from './display.models';

const SET_WINDOW_SIZE = '[Display] Set Window Size';

const _TOGGLE_SIDEBAR = '[Display] Toggle Sidebar';
const TOGGLE_SIDEBAR = `${_TOGGLE_SIDEBAR} START`;
const TOGGLE_SIDEBAR_COMPLETE = `${_TOGGLE_SIDEBAR} END`;

const SHOW_BANNER = '[Display] Show Banner';
const HIDE_BANNER = '[Display] Hide Banner';

const RELOAD_USERPILOT = '[Display] Reload Userpilot';

export const DisplayEffect = {
  TOGGLE_SIDEBAR,
};

export type ToggleSidebarParams = { position?: SidenavPosition; isTemporary?: boolean };

export const DisplayActions = {
  toggleMainSidebar: createAction(TOGGLE_SIDEBAR, (params: ToggleSidebarParams = {}) => ({ params })),
  toggleMainSidebarComplete: createAction(TOGGLE_SIDEBAR_COMPLETE, (params: ToggleSidebarParams = {}) => ({ params })),

  setWindowSize: createAction(SET_WINDOW_SIZE, props<{ params: Partial<Window> }>()),

  showBanner: createAction(SHOW_BANNER, props<{ params: Banner | Banner[] }>()),
  hideBanner: createAction(HIDE_BANNER, props<{ params: Banner['name'] | Banner['name'][] }>()),
  reloadUserpilot: createAction(RELOAD_USERPILOT),
};
