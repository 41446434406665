import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KeyAndValue, RoutingService, linearProgressIndicatorTransitionTime } from '@morpho/core';
import { DragFileModalInputComponent, ProgressStep } from '@morpho/form';
import { Store, select } from '@ngrx/store';
import { action } from 'apps/bankangle/src/app/constants/wording';
import { Subject, Subscription, concatMap, delay, interval, of, takeUntil } from 'rxjs';
import { UploadedDocumentInstance, UploadedDocumentState } from '../../document-extractor.model';
import { DocumentExtractorApiService } from '../../services/document-extractor-api.service';
import { DocumentExtractorService, PollConfig } from '../../services/document-extractor.service';
import { DocumentExtractorSelector } from '../../state/document-extractor.selectors';

@Component({
  standalone: false,
  selector: 'om-document-extractor-upload-modal',
  templateUrl: './document-extractor-upload-modal.component.html',
})
export class DocumentExtractorUploadModalComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: DragFileModalInputComponent;
  private ngOnDestroy$ = new Subject<void>();
  file: File | null;
  hasError = false;
  isLoading = false;
  errorMessages: string[] = [];

  setProgress$ = new Subject<ProgressStep>();
  setProgressObservable$ = this.setProgress$.asObservable().pipe(concatMap(value => of(value).pipe(delay(1000))));

  progressSteps: ProgressStep[] = [
    {
      progress: 5,
      progressStep: 'Step 1 of 3',
      progressStepDescription: `We're reading the data in your file`,
    },
    {
      progress: 35,
      progressStep: 'Step 2 of 3',
      progressStepDescription: `We're extracting the relevant fields`,
      progressStepNote: '(This part takes about 5 seconds)',
    },
    {
      progress: 100,
      progressStep: 'Step 3 of 3',
      progressStepDescription: `We're preparing to send the data back to you`,
    },
  ];

  currentProgress: ProgressStep = this.progressSteps[0];
  extractedData: KeyAndValue[] | null;

  readonly uploadedDocument$ = this.store.pipe(select(DocumentExtractorSelector.uploadedDocument));
  readonly actionText = action;
  private documentReadySubscription$: Subscription;
  private timerValues$: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Record<any, any>,
    public dialogRef: MatDialogRef<DocumentExtractorUploadModalComponent>,
    private documentExtractorApiService: DocumentExtractorApiService,
    private documentExtractorService: DocumentExtractorService,
    private routingService: RoutingService,
    private store: Store<any>,
  ) {
    this.setProgressObservable$.subscribe(progress => {
      this.currentProgress = progress;
    });
  }

  ngOnInit(): void {
    this.uploadToExtractor();
  }

  private uploadToExtractor() {
    this.isLoading = true;

    this.documentExtractorApiService.uploadDocument(this.data).subscribe({
      next: (result: UploadedDocumentInstance) => {
        this.setProgress$.next({ ...this.progressSteps[0], progress: this.progressSteps[0].progress + 30 });
        this.pollForState(result.id);
      },
      error: (error: any) => {
        this.setError(true, error?.document as string[]);
      },
    });
  }

  onRetry(): void {
    this.setError(false);
    this.uploadToExtractor();
  }

  private pollForState(id: number): void {
    const pollConfig: PollConfig = {
      interval: 1000,
      maxTry: 60,
      onMaxTry: this.onMaxTries.bind(this),
    };

    this.setProgress$.next(this.progressSteps[1]);

    this.documentReadySubscription$ = this.documentExtractorService
      .pollForDocumentReady(id, pollConfig)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe({
        next: (result: UploadedDocumentInstance) => {
          switch (result.state) {
            case UploadedDocumentState.complete:
              this.timerValues$.unsubscribe();
              this.documentReadySubscription$.unsubscribe();
              this.setProgress$.next(this.progressSteps[2]);
              setTimeout(() => {
                this.dialogRef.close();
                this.routingService.routeTo(`/document-extractor?uploadId=${result.id}`);
              }, linearProgressIndicatorTransitionTime + 1000);
              break;
            case UploadedDocumentState.pending:
              if (!this.timerValues$) {
                this.timerValues$ = interval(1000).subscribe(n => {
                  let progress = this.currentProgress.progress;
                  if (n < 4) {
                    progress += 10;
                  } else {
                    progress += 2;
                  }
                  const newProgressStep = {
                    ...this.progressSteps[1],
                    progress,
                  };
                  this.setProgress$.next(newProgressStep);
                });
              }
              break;
            case UploadedDocumentState.error:
              this.setError(true);
              this.timerValues$.unsubscribe();
              this.setProgress$.next(this.progressSteps[0]);
              this.documentReadySubscription$.unsubscribe();
              break;
            default:
              break;
          }
        },
        error: () => {
          this.setError(true);
        },
      });
  }

  private onMaxTries(): void {
    this.setError(true);
  }

  private setError(hasError: boolean, errorMessages?: string[]) {
    this.isLoading = false;
    this.hasError = hasError;
    this.errorMessages = errorMessages ?? [];
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
