<div>
  <mat-icon svgIcon="search"> </mat-icon>
  <input
    matInput
    #filter
    type="search"
    autocomplete="off"
    [placeholder]="placeholder"
    [(ngModel)]="filterText"
    (ngModelChange)="applyFilter()"
    id="search"
  />
  @if (filterText) {
    <button mat-icon-button matSuffix class="is-flat" (click)="clearForm()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  }
</div>
