import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MathfieldElement } from 'mathlive';

@Component({
  standalone: false,
  selector: 'om-equation',
  templateUrl: './equation.component.html',
  styleUrls: ['./equation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EquationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('equationContainer') equationContainer: ElementRef;

  @Input() content: string;

  @Output() contentChange = new EventEmitter<string>();

  removeEquationOutListener = () => {};

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    const mfe = new MathfieldElement();

    mfe.value = this.content;
    this.renderer.appendChild(this.equationContainer.nativeElement, mfe);

    this.removeEquationOutListener = this.renderer.listen(this.equationContainer.nativeElement, 'focusout', () => {
      this.contentChange.emit(mfe.value);
    });
  }

  ngOnDestroy() {
    this.removeEquationOutListener();
  }
}
