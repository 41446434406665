import { Injectable } from '@angular/core';
import { ApiService } from '@morpho/core';
import { catchError, map, Observable, of } from 'rxjs';
import { OTPDevice, OTPDeviceResponse } from '../models/two-factor-authentication.models';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  constructor(private apiService: ApiService) {}

  getDevices(): Observable<OTPDevice[]> {
    const endpoint = 'auth/totp_device/';
    return this.apiService.get(endpoint);
  }

  createDevice(name: string): Observable<OTPDeviceResponse> {
    const endpoint = 'auth/totp_device/';
    return this.apiService.post(endpoint, { name });
  }

  getActivationQRCode(id: number): Observable<OTPDevice> {
    const endpoint = `auth/totp_device/${id}/`;
    return this.apiService.get(endpoint);
  }

  confirmDevice(id: number, token: string): Observable<boolean> {
    const endpoint = `auth/totp_device/${id}/confirm/`;
    return this.apiService.post(endpoint, { otp_token: token }).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  removeDevice(id: number): Observable<boolean> {
    const endpoint = `auth/totp_device/${id}/`;
    return this.apiService.delete(endpoint).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
