import { Component, Input } from '@angular/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { DocumentInfo } from '../document-list.model';

@Component({
  standalone: false,
  selector: 'om-document-download-panel',
  templateUrl: './document-download-panel.component.html',
})
export class DocumentDownloadPanelComponent {
  @Input() documents: DocumentInfo[];
  @Input() downloadUrl: string;
  @Input() downloadModal: BooleanPopupParams;

  constructor(private dialogService: DialogService) {}

  downloadAll() {
    this.dialogService.booleanPopup(this.downloadModal).subscribe();
  }
}
