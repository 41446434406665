<ul>
  @for (controlAction of controlActions$ | async; track controlAction.id) {
    <li>
      <button
        [id]="controlAction.id"
        mat-icon-button
        [ngClass]="controlAction.class"
        (click)="controlAction.action()"
        [omTooltip]="controlAction.tooltip"
        [disabled]="controlAction.disabled"
      >
        <mat-icon [svgIcon]="controlAction.icon"></mat-icon>
      </button>
    </li>
  }
</ul>
