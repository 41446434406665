import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import {
  ActionMenuConfig,
  ActionMenuEvent,
  CustomRouterState,
  HttpMethod,
  RouterSelector,
  RoutingService,
  TrackByService,
  UtilService,
} from '@morpho/core';
import { FormConfig, FormInputConfig, FormPrefixes } from '@morpho/form';
import { Store } from '@ngrx/store';
import { Observable, Subject, interval } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { DynamicFormModel, DynamicFormSection } from '../../models/dynamic-form.model';
import { DynamicFormApiService } from '../../services/dynamic-form-api.service';
import { DynamicFormService } from '../../services/dynamic-form.service';

@Component({
  standalone: false,
  selector: 'om-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormComponent implements OnChanges, OnDestroy, OnInit {
  private ngOnDestroy$ = new Subject<void>();
  @ViewChild('accordion') accordion: MatAccordion;

  @Input() formConfig: FormConfig;

  @Input() options: FormInputConfig[];
  @Input() optionsFromBackend: any[];

  @Input() url: string;
  @Input() method: HttpMethod;

  @Input()
  get model(): DynamicFormModel {
    return this._model;
  }
  set model(newModel: DynamicFormModel) {
    this._model = newModel;
    this.setSectionsForDisplay();
  }
  private _model: DynamicFormModel;

  @Output() modelChange: EventEmitter<DynamicFormModel> = new EventEmitter();

  @Input() prefixActionsMapping: { [key: string]: ActionMenuConfig } = {};
  @Input() suffixActionsMapping: { [key: string]: ActionMenuConfig } = {};
  @Output() fieldActionSelected: EventEmitter<ActionMenuEvent> = new EventEmitter();

  routerState$: Observable<CustomRouterState> = this.store
    .select(RouterSelector.state)
    .pipe(takeUntil(this.ngOnDestroy$));

  sectionsForDisplay: DynamicFormSection[] = [];

  openSection: DynamicFormSection | null;
  fieldIdPrefix: string;
  sectionIdPrefix: string;
  searchTerm: string;

  isFilterActive = false;
  isLoading = true;

  expandedSections: { [key: string]: boolean } = {};

  private updateUrlParams = this.utilService.debounce(() => {
    this.routingService.setUrlParam('section', this.openSection?.name);
  }, 250);

  constructor(
    private dynamicFormService: DynamicFormService,
    private dynamicFormApiService: DynamicFormApiService,
    private routingService: RoutingService,
    public trackBy: TrackByService,
    private utilService: UtilService,
    private store: Store,
  ) {
    interval(500)
      .pipe(
        takeUntil(this.ngOnDestroy$),
        map(() => JSON.stringify(this.model?.sectionsForDisplay ?? [])),
        distinctUntilChanged(),
        filter(sectionsForDisplayString => sectionsForDisplayString !== JSON.stringify(this.sectionsForDisplay ?? [])),
        tap(() => {
          this.setSectionsForDisplay();
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.routerState$.subscribe(routerState => {
      const currentSection = routerState.queryParams?.section;
      const highlightField = routerState.queryParams?.highlightInput;

      if (highlightField) {
        this.routingService.setUrlParam('highlightInput');
        setTimeout(() => {
          this.dynamicFormService.highlightFormField(
            highlightField,
            this.sectionsForDisplay,
            FormPrefixes.DocumentGeneratorMain,
          );
        });
      } else {
        if (!this.openSection || currentSection !== this.openSection?.name) {
          this.model?.sectionsForDisplay.forEach((section: DynamicFormSection) => {
            if (section.name === currentSection) {
              setTimeout(() => {
                this.openSection = section;
              });
              return;
            }
          });
        }
        if (this.formConfig?.enableSearch) {
          this.searchTerm = routerState.queryParams?.search;
          this.setSectionsForDisplay();
        }
      }
    });
    this.fieldIdPrefix = this.dynamicFormService.getFieldIdPrefix(this.formConfig?.idPrefix);
    this.sectionIdPrefix = this.dynamicFormService.getSectionIdPrefix(this.formConfig?.idPrefix);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && this.options) {
      this.setModel(this.dynamicFormService.generateFormModel(this.options, this.formConfig));
    }
    if (changes.optionsFromBackend && this.optionsFromBackend) {
      this.setModel(
        this.dynamicFormService.generateFormModel(this.optionsFromBackend, {
          isFromBackend: true,
        }),
      );
    }

    if (changes.url && !this.options && !this.optionsFromBackend) {
      const optionsUrl = this.dynamicFormApiService.generateOptionsApiEndpoint(this.url);
      this.dynamicFormService.generateFormModelFromApi(optionsUrl, this.formConfig).subscribe(model => {
        this.setModel(model);
      });
    }

    if (changes.formConfig) {
      this.fieldIdPrefix = this.dynamicFormService.getFieldIdPrefix(this.formConfig?.idPrefix);
      this.sectionIdPrefix = this.dynamicFormService.getSectionIdPrefix(this.formConfig?.idPrefix);
    }
  }

  onInput(event: any) {
    const name = event.name;
    this.dynamicFormService.onUserInput(name, this.model, this.formConfig).then(() => {
      this.setModel();
      this.modelChange.emit(this.model);
    });
  }

  onFieldActionSelected(event: ActionMenuEvent) {
    this.fieldActionSelected.emit(event);
  }

  panelClosed(sectionForDisplay: DynamicFormSection) {
    if (this.isFilterActive) {
      return;
    }
    if (this.openSection?.name === sectionForDisplay.name) {
      this.openSection = null;
    }
    this.updateUrlParams();
  }

  panelOpened(sectionForDisplay: DynamicFormSection) {
    if (this.isFilterActive) {
      return;
    }
    this.openSection = sectionForDisplay;
    this.updateUrlParams();
  }

  panelAfterExpand(sectionForDisplay: DynamicFormSection) {
    if (this.isFilterActive) {
      return;
    }
    this.expandedSections[sectionForDisplay.name] = true;

    setTimeout(() => {
      const sectionId = this.dynamicFormService.getSectionId(
        sectionForDisplay.name,
        FormPrefixes.DocumentGeneratorMain,
      );
      document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  private setModel(model?: DynamicFormModel): void {
    if (model) {
      this.model = model;
    }
    this.setSectionsForDisplay();
  }

  private setSectionsForDisplay() {
    if (!this.searchTerm) {
      if (this.isFilterActive) {
        this.accordion?.closeAll();
        this.expandedSections = {};
      }
      this.isFilterActive = false;
      this.sectionsForDisplay = this.model?.sectionsForDisplay || [];
    } else {
      if (!this.isFilterActive) {
        this.openSection = null;
        this.updateUrlParams();
      }
      this.isFilterActive = true;
      this.sectionsForDisplay = this.dynamicFormService.getFilteredSections(this.model, this.searchTerm);
    }
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
