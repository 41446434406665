import { Component } from '@angular/core';

@Component({
  standalone: false,
  template: '',
})
export class AgGridSidebarComponentBase {
  get isPanelOpen(): boolean {
    return this._isPanelOpen;
  }
  private _isPanelOpen = false;

  closePanel() {
    this._isPanelOpen = false;
  }

  openPanel() {
    this._isPanelOpen = true;
  }
}
