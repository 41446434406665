import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NotificationsAction, NotificationsEffect } from '../../core/state/notifications.actions';
import { NotificationApiService } from '../services/notification-api.service';

@Injectable()
export class NotificationsEffects {
  getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsEffect.GET_NOTIFICATIONS),
      mergeMap(() =>
        this.notificationApiService.getNotifications().pipe(
          map(response => {
            return NotificationsAction.getNotificationsSuccess({ params: response });
          }),
          catchError(() => of(NotificationsAction.getNotificationsFail())),
        ),
      ),
    ),
  );

  markNotificationAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsEffect.MARK_NOTIFICATION_AS_READ),
      mergeMap(action => {
        const notificationId = (action as any).params.notificationId;
        return this.notificationApiService.markNotificationAsRead(notificationId).pipe(
          map(() =>
            NotificationsAction.markNotificationAsReadSuccess({
              params: { notificationId },
            }),
          ),
          catchError(() => of(NotificationsAction.markNotificationAsReadFail())),
        );
      }),
    ),
  );

  markStandardNotificationsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsEffect.MARK_STANDARD_NOTIFICATIONS_AS_READ),
      mergeMap(() => {
        return this.notificationApiService.markStandardNotificationsAsRead().pipe(
          map(() => NotificationsAction.markStandardNotificationsAsReadSuccess()),
          catchError(() => of(NotificationsAction.markStandardNotificationsAsReadFail())),
        );
      }),
    ),
  );

  markStandardNotificationsAsSeen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsEffect.MARK_STANDARD_NOTIFICATIONS_AS_SEEN),
      mergeMap(() => {
        return this.notificationApiService.markStandardNotificationsAsSeen().pipe(
          map(() => NotificationsAction.markStandardNotificationsAsSeenSuccess()),
          catchError(() => of(NotificationsAction.markStandardNotificationsAsSeenFail())),
        );
      }),
    ),
  );

  markAuthNotificationsAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsEffect.MARK_AUTH_NOTIFICATIONS_AS_READ),
      mergeMap(() => {
        return this.notificationApiService.markAuthNotificationsAsRead().pipe(
          map(() => NotificationsAction.markAuthNotificationsAsReadSuccess()),
          catchError(() => of(NotificationsAction.markAuthNotificationsAsReadFail())),
        );
      }),
    ),
  );

  respondToAuthRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsEffect.RESPOND_TO_AUTH_REQUEST),
      mergeMap(action => {
        // todo remove as any
        const endpoint = (action as any).params.response_url;
        const notificationId = (action as any).params.notificationId;
        return this.notificationApiService.respondToAuthRequest(endpoint).pipe(
          map(() => NotificationsAction.respondToAuthRequestSuccess({ params: { notificationId } })),
          catchError(() => of(NotificationsAction.respondToAuthRequestFail())),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private notificationApiService: NotificationApiService,
  ) {}
}
