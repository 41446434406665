import { Component, HostListener, Input, OnInit, ViewEncapsulation } from '@angular/core';

export type TableEditAction = {
  label: string;
  action: Function;
};
@Component({
  standalone: false,
  selector: 'om-table-edit-control',
  templateUrl: './table-edit-control.component.html',
  styleUrls: ['./table-edit-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableEditControl implements OnInit {
  @HostListener('mouseenter')
  onMouseEnter() {
    setTimeout(() => {
      if (this.isOver) {
        this.isHovered = true;
      }
    }, 250);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    setTimeout(() => {
      if (this.isOut) {
        this.isHovered = false;
        this.isOver = false;
      }
    }, 250);
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.isOut = false;
    this.isOver = true;
  }
  @HostListener('mouseout')
  onMouseOut() {
    this.isOut = true;
    this.isOver = false;
  }

  @Input() actions: TableEditAction[];
  @Input() alignment: 'row' | 'column';
  @Input() slateId: string;

  elementId: string;
  isHovered = false;

  private isOver = false;
  private isOut = false;

  constructor() {}

  ngOnInit(): void {
    this.elementId = `om_table_control_${this.slateId}_${this.alignment}`;
  }
}
