import { ButtonCellRendererComponent } from './cell-renderers/button-cell-renderer/button-cell-renderer.component';
import { RowExpandCellRendererComponent } from './cell-renderers/row-expand-cell-renderer/row-expand-cell-renderer.component';
import { RowLevelActionsCellRendererComponent } from './cell-renderers/row-level-actions-cell-renderer/row-level-actions-cell-renderer.component';
import { SquareLogoCellRendererComponent } from './cell-renderers/square-logo-cell-renderer/square-logo-cell-renderer.component';
import { UsersIconsCellRendererComponent } from './cell-renderers/users-icons-cell-renderer/users-icons-cell-renderer.component';

export const agGridComponentMap = {
  // Cell renderers
  omRowExpandCellRenderer: { omRowExpandCellRenderer: RowExpandCellRendererComponent },
  omRowLevelActionsCellRenderer: { omRowLevelActionsCellRenderer: RowLevelActionsCellRendererComponent },
  omButtonCellRenderer: { omButtonCellRenderer: ButtonCellRendererComponent },
  omUsersIconsCellRenderer: { omUsersIconsCellRenderer: UsersIconsCellRendererComponent },
  omSquareLogoCellRenderer: {
    omSquareLogoCellRenderer: SquareLogoCellRendererComponent,
  },
};
