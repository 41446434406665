export const CDN_URL: Record<string, string> = {
  local: './',
  qa: './',
  auto: './',
  demo2: './',
  login2: './',
  timeline: './',
  // qa: 'https://cdn.originmarkets-dev.com/staging/morpho/',
  // auto: 'https://cdn.originmarkets-dev.com/staging/morpho/',
  // demo2: 'https://cdn.originmarkets.com/production/morpho/',
  // login2: 'https://cdn.originmarkets.com/production/morpho/',
};

export const AG_GRID_LICENSE_KEY =
  // eslint-disable-next-line max-len
  'Using_this_AG_Grid_Enterprise_key_( AG-040279 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( ORIGIN PRIMARY LIMITED )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Origin )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Origin )_need_to_be_licensed___( Origin )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 May 2024 )____[v2]_MTcxNTk4NjgwMDAwMA==fb301a706513f53b80523d80101b638c';

export const HEAP_APP_ID: { [key: string]: number } = {
  local: 3640120966,
  qa: 4256487627,
  demo2: 3352520818,
  login2: 3266927807,
};

export const USERPILOT_KEY = 'NX-3b105969';
export const USERPILOT_RESOURCE_CENTRE_KEY = 'YF2LI6SaN0';

export const USERPILOT_PROXY = {
  local: 'userpilot-qa.originmarkets-dev.com',
  qa: 'userpilot-qa.originmarkets-dev.com',
  auto: 'userpilot-auto.originmarkets-dev.com',
  timeline: 'userpilot-timeline.originmarkets-dev.com',
  demo2: 'userpilot-demo2.originmarkets.com',
  login2: 'userpilot-login2.originmarkets.com',
};

const SENTRY = {
  publicKey: '30cb1b7abd5e4529862e53279e4a9a42',
  organizationId: 'o52770',
  projectId: '5473182',
};

export const SENTRY_DSN = `https://${SENTRY.publicKey}@${SENTRY.organizationId}.ingest.sentry.io/${SENTRY.projectId}`;
