import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { icons } from 'apps/bankangle/src/app/constants/icons';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) {
    this.registerIcons();
  }

  private registerIcons() {
    for (const [iconName, url] of Object.entries(icons)) {
      this.matIconRegistry.addSvgIcon(iconName, this.domSanitizer.bypassSecurityTrustResourceUrl(url));
    }
  }
}
