import { Injectable } from '@angular/core';
import { TabToNextCellParams } from 'ag-grid-community';
import { DEFAULT_QUOTE_TYPE, QuoteType } from '../../elements/constants/levels';
import { COLUMN_PREFIX_QUOTE_TYPE } from './ag-grid.constants';

@Injectable({
  providedIn: 'root',
})
export class AgGridUtilService {
  generateQuoteTypeField(colId: string, quoteType?: QuoteType): string {
    return `${quoteType || DEFAULT_QUOTE_TYPE}_${colId}`.replace(COLUMN_PREFIX_QUOTE_TYPE, '');
  }

  changeTabDirectionToVertical(params: TabToNextCellParams) {
    const previousCell = params.previousCellPosition;
    const renderedRowCount = params.api.getDisplayedRowCount();
    const lastRowIndex = previousCell.rowIndex;

    const nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;

    return {
      rowIndex: nextRowIndex >= 0 && nextRowIndex < renderedRowCount ? nextRowIndex : previousCell.rowIndex,
      column: previousCell.column,
      rowPinned: previousCell.rowPinned,
    };
  }

  changeTabDirectionToHorizontal(params: TabToNextCellParams) {
    const columns = params.api.getColumns() ?? [];
    const lastColumnIndex =
      columns.findIndex(column => column.getColId() === params.previousCellPosition.column.getColId()) ?? 0;
    let nextColumnIndex = params.backwards ? lastColumnIndex - 1 : lastColumnIndex + 1;

    if (nextColumnIndex < 0) {
      nextColumnIndex = columns.length - 1;
    } else if (nextColumnIndex >= columns.length) {
      nextColumnIndex = 0;
    }

    return {
      rowIndex: params.previousCellPosition.rowIndex,
      column: columns[nextColumnIndex],
      rowPinned: params.previousCellPosition.rowPinned,
    };
  }
}
