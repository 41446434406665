import { MaturityType, QuoteType } from '@morpho/core';
import { IssuerEntity } from '../../../core/patterns/issuer/issuer.model';
import { PricingBreakDownType } from '../constants/breakdown';
import { Maturities } from '../state/pricing-completion/pricing-completion.model';
import { BondPricing } from './comparable.model';
import { PricingInboxItem } from './pricing-inbox.model';
import { SecondaryBondFilters } from './secondaries.model';

export const GLOBAL_FIELD = 'global_field';
export const PRICING = 'pricing';
export const REFERENCE_PRICING = 'reference_pricing';
export const HISTORICAL_PRICING = 'historical_pricing';

export const IHS = 'ihs';
export const HISTORICAL_IHS = 'ihs-historical';
export const OVERRIDE = 'override';

export const OVERVIEW_TAB = 'overview';

export const COMBINED_INTERPOLATION_LABEL = 'Combined Interpolation';

export interface PricingRequestBase {
  seniority: string;
  currency: string;
  maturities: string[];
  coupon_type: string;
  frequency: string;
  due_by: string;
  id?: number;
}

export interface PricingData {
  funding_basis: string;
  levels: CurveLevel[];
  additional_curves?: AdditionalCurve[];
  id?: number;
  included_isins?: string[];
  included_dates?: string[];
  secondary_levels?: SecondaryLevels;
  breakdown?: PricingBreakDownType;
  show_references?: boolean;
  show_latest_historical?: boolean;
  pricing_rationale?: string;
  updated_at?: string;
  updated_by?: User;
  completed_at?: string;
  completed_by?: User;
  status?: string;
  quote_type?: QuoteType;
}

export interface PricingRequestExtended extends PricingRequestBase {
  comparable_criteria: ComparableData[];
  // issuer_type: string;
  // is_completed: boolean;
  pricing: PricingData;
  comment?: string;
  comparable_isins?: string[];
  created_at?: string;
  created_by?: User;
  desks_to_share_with?: number[];
  filters?: SecondaryBondFilters;
  pricing_desk: number;
  // share_with: string;
  requesting_desk: number;
  users_to_share_with?: number[];
}
export type SecondaryLevels = Record<string, Partial<BondPricing>>;
export type CombinedPricingRequest = Partial<PricingRequestResponse & PricingInboxItem>;

export interface UpdateSecondaryLevelsParams {
  tabName: string;
  secondaryLevels?: SecondaryLevels;
  unsavedSecondaryLevels?: SecondaryLevels;
}

export interface PricingRequestDialogParams {
  pricingRequestId?: number;
  issuingEntityIds?: number[];
  pricingRequest?: CombinedPricingRequest;
}

export interface PricingRequestResponse extends PricingRequestExtended {
  issuing_entity: IssuerEntity;
}

export interface PricingRequestCreation extends PricingRequestExtended {
  issuing_entity: number;
}

export interface PricingRequestUpdate extends PricingRequestBase {
  id: number;
  // todo is this right?
  // issuing_entity_id: number;
}

export interface Maturity {
  type: MaturityType;
  value: string;
}
export interface ComparableData {
  issuing_entity_id: number;
  seniority: string;
  funding_basis: string;
  excluded_isins?: string[];
}

// todo, after endpoint migrations, use this interface in all other places
// ComparableData => ComparableCriteriaItem
// ComparableData, seniority & funding_basis are optional?
export interface ComparablesInformation {
  comparable_isins?: string[];
  comparable_criteria?: ComparableData[];
}

export interface PricingRequestParams {
  issuing_entity_id?: number[];
  include_pricing?: boolean;
  read_only?: boolean;
  as_of?: string;
}

export interface PricingRequestHistoryCurve {
  id: number;
  group_key: string;
  currency: string;
  seniority: string;
  coupon_type: string;
  funding_basis: string;
  levels: {
    key: string;
    data?: Record<string, string>;
  }[];
  additional_curves: any[]; // TODO type this
  overrides: Record<string, string> | null;
}

export interface PricingRequestHistory {
  id: number;
  curves: PricingRequestHistoryCurve[];
  pricing_rationale: string;
  created_at: string;
  completed_at: string;
  due_by: string;
}

export interface PricingRequestHistoryList {
  completed_at: string[];
}

export interface SyndicateTeam {
  id: number;
  name: string;
}

export interface RequestingDesk {
  id: number;
  name: string;
}

export interface AdditionalCurve {
  type: string;
  name?: string;
  value: any;
  data?: Maturities;
}

export interface CurveLevel {
  key: string;
  data: Record<string, string>;
}

export interface PricingType {
  currency: string;
  coupon_type: string;
  seniority: string;
}

export enum NewIssueCurveType {
  BenchmarkInput = 'benchmark',
  BenchmarkYield = 'benchmark_yield',
  Custom = 'custom',
  Govie = 'govie',
  Historical = 'historical_pricing',
  Interpolated = 'interpolated',
  NewIssuePremium = 'new_issue_premium',
  ReferenceCurve = 'reference_pricing',
  Spread = 'spread',
  Swap = 'swap_pricing',
  OverviewSwap = 'overview_swap',
  Total = 'total',
  Yield = 'yield',
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
}
