<span [ngClass]="{ 'om-disabled': disabled, 'om-placeholder': !value }">
  <p>{{ displayValue }}</p></span
>
<input
  matInput
  [matDatepicker]="datePicker"
  [disabled]="disabled"
  [min]="min"
  [max]="max"
  [placeholder]="placeholder"
  [required]="required"
  [(ngModel)]="childValue"
  (ngModelChange)="onChildValueChange($event)"
/>
@if (!childValue) {
  <button mat-icon-button matSuffix class="om-calendar-toggle">
    <mat-icon svgIcon="calendar"></mat-icon>
  </button>
} @else if (!hideClearDate && !disabled) {
  <button mat-icon-button matSuffix (click)="clearDate($event)">
    <mat-icon svgIcon="clear"></mat-icon>
  </button>
}

<mat-datepicker
  #datePicker
  [startView]="datePickerStartView"
  [panelClass]="datePickerClass"
  [dateClass]="highlightDateClass"
  (viewChanged)="setLegendVisibility($event)"
  (opened)="setLegendVisibility()"
>
</mat-datepicker>
<ng-container #legendContainer></ng-container>
<ng-template #legendElement let-message="message" let-legend="legend">
  <div class="datepicker-legend">
    @if (legend) {
      <p class="legend-item">The <span class="dot"></span> means {{ legend }}</p>
    }
    @if (message) {
      <div class="info-notification">
        <span class="icon"><mat-icon svgIcon="info"></mat-icon></span>
        <span class="text">{{ message }}</span>
      </div>
    }
  </div>
</ng-template>
