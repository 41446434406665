import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatCalendarCellClassFunction, MatCalendarView, MatDatepicker } from '@angular/material/datepicker';
import {
  BACKEND_DATE_FORMAT,
  BACKEND_DATE_NO_YEAR_FORMAT,
  BASE_LEAP_YEAR,
  DATE_FORMAT,
  DATE_NO_YEAR_FORMAT,
} from '@morpho/core';
import * as moment from 'moment-timezone';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

type FormatType = 'moment';

@Component({
  standalone: false,
  selector: 'om-date-input',
  templateUrl: './date-input.component.html',
  providers: [getCustomFormFieldProviders(forwardRef(() => DateInputComponent))],
})
export class DateInputComponent extends CustomFormFieldControlComponent implements OnChanges {
  @ViewChild('datePicker', { read: undefined, static: false }) datePicker: MatDatepicker<moment.Moment>;
  @ViewChild('legendElement') legendElement: TemplateRef<any>;
  @ViewChild('legendContainer', { read: ViewContainerRef, static: true }) legendContainer: ViewContainerRef;

  static baseYear = BASE_LEAP_YEAR;

  displayFormat = DATE_FORMAT;
  parseFormat = BACKEND_DATE_FORMAT;

  displayValue = '';
  datePickerClass: string;
  datePickerStartView: 'month' | 'year' | 'multi-year' = 'month';
  private datePickerCurrentView = 'month';
  private highlightedDatesFormattedSet: Set<string> = new Set();

  childValue: moment.Moment | null;

  @Input() min: string;

  @Input() max: string;

  @Input() format: FormatType;

  @Input() hideClearDate: Boolean;

  @Input() legend: string;

  @Input() message: string;

  @Input()
  get highlightedDates(): string[] {
    return this._highlightedDates;
  }
  set highlightedDates(dates: string[]) {
    if (dates) {
      this._highlightedDates = [...dates];

      dates.forEach(date => {
        if (!this.stringToMoment(date).isValid()) {
          return;
        }
        const formattedDate = this.stringToMoment(date).format(BACKEND_DATE_FORMAT);
        this.highlightedDatesFormattedSet.add(formattedDate);
      });
    }
  }
  private _highlightedDates: string[];

  @Output() onDateChanged = new EventEmitter<string>();

  @Input()
  get value() {
    return this._value;
  }
  set value(value: string | moment.Moment | null) {
    this._value = value || null;
    this.emitChanges();

    if (!this._value) {
      this.displayValue = this.placeholder || '';
      this.childValue = null;
      return;
    }

    this.childValue = moment(value, this.parseFormat);
    this.updateDisplay();
  }
  protected _value: string | moment.Moment | null;

  @Input()
  get hideYear() {
    return this._hideYear;
  }
  set hideYear(value: boolean) {
    value = coerceBooleanProperty(value);
    if (!value) {
      this._hideYear = value;
      return;
    }
    this._hideYear = value;
    this.displayFormat = DATE_NO_YEAR_FORMAT;
    this.parseFormat = BACKEND_DATE_NO_YEAR_FORMAT;
    this.datePickerClass = 'om-datepicker-no-year';
    this.datePickerStartView = 'year';
  }
  private _hideYear = false;

  @HostListener('click') onClick() {
    if (this.disabled) {
      return;
    }
    this.datePicker.opened ? this.datePicker.close() : this.datePicker.open();
  }

  onChildValueChange(date: moment.Moment) {
    const parsedDate = moment(date).format(BACKEND_DATE_FORMAT);
    this.onDateChanged.emit(parsedDate);
    this.updateValue();
  }

  stringToMoment(dateString: string) {
    if (this._hideYear) {
      //ensure we have a leap year if it's not known
      const momentDate = moment(dateString, this.parseFormat);
      momentDate.set({ year: +DateInputComponent.baseYear });
      return momentDate;
    }

    //format more precise time formats into BACKEND_DATE_FORMAT
    return moment(dateString, this.parseFormat);
  }

  private updateValue() {
    this._value =
      this.format === 'moment' && this.childValue ? this.childValue : this.childValue?.format(this.parseFormat) || null;
    this.emitChanges();
    this.updateDisplay();
  }

  updateDisplay() {
    this.displayValue = this.childValue?.format(this.displayFormat) || this.placeholder || '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value) {
      this.childValue = moment.isMoment(this._value) ? this._value : this.stringToMoment(this._value || '');
      this.updateValue();
    }

    if (this._hideYear && (changes.hideYear || changes.min || changes.max)) {
      if (this.min && !this.min.startsWith(`${DateInputComponent.baseYear}-`)) {
        this.min = `${DateInputComponent.baseYear}-${this.min}`;
      }
      if (this.max && !this.max.startsWith(`${DateInputComponent.baseYear}-`)) {
        this.max = `${DateInputComponent.baseYear}-${this.max}`;
      }
      this.min = this.min || `${DateInputComponent.baseYear}-01-01`;
      this.max = this.max || `${DateInputComponent.baseYear}-12-31`;
    }
  }

  public focus() {
    this.datePicker?.open();
  }

  clearDate(event: MouseEvent) {
    event.stopPropagation();
    this.childValue = null;
    this.updateValue();
  }

  //
  // Historic pricings - indicators and legend
  //===========================================
  highlightDateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    if (view !== 'month') {
      return '';
    }

    const date = moment(cellDate).format(BACKEND_DATE_FORMAT);

    return this.highlightedDatesFormattedSet.has(date) ? 'highlight-date' : '';
  };

  shouldShowLegend(): boolean {
    const isMonthView = this.datePickerCurrentView === 'month';
    const hasLegendOrMessage = !!this.legend || !!this.message;
    return !!this.highlightedDates && isMonthView && hasLegendOrMessage;
  }

  setLegendVisibility(event: MatCalendarView = this.datePickerStartView) {
    this.datePickerCurrentView = event;
    const shouldShowLegend = this.shouldShowLegend();

    if (shouldShowLegend) {
      this.attachLegend();
    } else {
      this.removeLegend();
    }
  }

  private attachLegend() {
    const calendar = document.getElementsByClassName('mat-datepicker-content')[0];
    const legend = this.legendContainer.createEmbeddedView(this.legendElement, {
      message: this.message,
      legend: this.legend,
    }).rootNodes[0];

    calendar.appendChild(legend);
  }

  private removeLegend() {
    document.getElementsByClassName('datepicker-legend')[0]?.remove();
  }
}
