export enum StatusCode {
  // backend
  PASSWORD_CHANGE_REQUIRED = 'password_change',
  BLOCKED_BY_RATE_LIMIT = 'rate_limit',
  NOT_AUTHENTICATED = 'not_authenticated',
  UNANNOUNCED = 'unannounced', // todo BE to implement
  PERMISSION_DENIED = 'permission_denied',
  PRECONDITION_FAILED = 'precondition_failed',

  // frontend
  PASSWORD_CHANGE_SUCCESS = 'password_changed',
}
