import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UtilService, ValueAndLabel } from '@morpho/core';
import { PricingService } from 'apps/bankangle/src/app/core/patterns/pricing/pricing.service';
import { StateService } from 'apps/bankangle/src/app/core/services/state.service';
import { Subject, debounceTime } from 'rxjs';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { PricerChangedEvent, PricerSwapDetails } from '../pricing.model';

@Component({
  standalone: false,
  selector: 'om-pricer',
  templateUrl: './pricer.component.html',
})
export class PricerComponent implements OnInit, OnChanges {
  private ngOnDestroy$ = new Subject<void>();

  @Input() initialFundingBasis: string;
  @Input() lastPriced: string;

  @Input()
  get allowReset(): boolean {
    return this._allowReset;
  }
  set allowReset(allowReset: boolean) {
    this._allowReset = coerceBooleanProperty(allowReset);
  }
  private _allowReset: boolean;

  @Input()
  get hideAdditionalFields(): boolean {
    return this._hideAdditionalFields;
  }
  set hideAdditionalFields(hideAdditionalFields: boolean) {
    this._hideAdditionalFields = coerceBooleanProperty(hideAdditionalFields);
  }
  private _hideAdditionalFields: boolean;

  @Output() pricerChanged = new EventEmitter<PricerChangedEvent | null>();
  @Output() swapOptionSelected = new EventEmitter<{ swap: any }>();

  @Input() swapDetails: PricerSwapDetails;
  private localSwapDetails: PricerSwapDetails | null;

  public isNextEmitDisabled = false;
  fundingBasisOptions: ValueAndLabel[];
  dayCountOptions: ValueAndLabel[];
  paymentFrequencyOptions: ValueAndLabel[];

  previousFormValue = '';
  formGroup = new UntypedFormGroup({
    fundingBasis: new UntypedFormControl(),
    dayCount: new UntypedFormControl(),
    paymentFrequency: new UntypedFormControl(),
  });

  get isLoading(): boolean {
    return this._isLoading;
  }
  set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this.setDisabledFormAttribute();
  }
  private _isLoading = false;

  isDayCountDisabled = false;
  isPaymentFrequencyDisabled = false;

  areAdditionalFieldsShown = false;

  private fundingBasisMapping: any;

  constructor(
    private pricingService: PricingService,
    private stateService: StateService,
    private utilService: UtilService,
  ) {
    this.stateService.get.constants$.subscribe(constants => {
      this.fundingBasisOptions = <ValueAndLabel[]>constants.arrayFor.combined_swap_options;
      this.dayCountOptions = <ValueAndLabel[]>(
        constants.arrayFor.day_count_options.filter(option => option.usable_in_pricing)
      );
      this.paymentFrequencyOptions = <ValueAndLabel[]>constants.arrayFor.payment_frequency_options;

      this.fundingBasisMapping = constants.mappingFor.combined_swap_options;
    });
  }

  ngOnInit(): void {
    this.formGroup
      .get('fundingBasis')
      ?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
      .subscribe(fundingBasis => {
        this.recalculateFields(fundingBasis);
      });

    this.formGroup.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(form => {
        if (this.isNextEmitDisabled) {
          this.isNextEmitDisabled = false;
        } else {
          this.emitChanges();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.swapDetails) {
      const swapDetails = changes.swapDetails.currentValue;
      if (!this.utilService.areObjectsEquivalent(swapDetails, this.localSwapDetails)) {
        if (swapDetails) {
          this.recalculateFields(swapDetails.fundingBasis, swapDetails.dayCount, swapDetails.paymentFrequency);
        } else {
          this.reset();
        }
      }
    }

    if (changes.lastPriced) {
      this.isLoading = this.formGroup.get('fundingBasis')?.value && !this.lastPriced;
    }
  }

  private recalculateFields(fundingBasis?: string, dayCount?: string, paymentFrequency?: number) {
    this.areAdditionalFieldsShown = !(!fundingBasis || fundingBasis.startsWith('MS /'));
    if (!fundingBasis) {
      return;
    }

    const basisDefaults = this.fundingBasisMapping[fundingBasis];
    this.formGroup.get('fundingBasis')?.setValue(fundingBasis, { emitEvent: false });
    this.formGroup.get('dayCount')?.setValue(dayCount ?? basisDefaults.day_count);
    this.formGroup.get('paymentFrequency')?.setValue(paymentFrequency ?? basisDefaults.payment_freq);

    this.isDayCountDisabled = !basisDefaults.daycount_selectable;
    this.isPaymentFrequencyDisabled = !basisDefaults.payment_freq_selectable;

    this.setDisabledFormAttribute();
  }

  private setDisabledFormAttribute() {
    const setFormAttribute = (key: string, disabled: boolean) => {
      if (disabled) {
        this.formGroup.get(key)?.disable({ emitEvent: false, onlySelf: true });
      } else {
        this.formGroup.get(key)?.enable({ emitEvent: false, onlySelf: true });
      }
    };
    setFormAttribute('fundingBasis', this.isLoading);
    setFormAttribute('dayCount', this.isLoading || this.isDayCountDisabled);
    setFormAttribute('paymentFrequency', this.isLoading || this.isPaymentFrequencyDisabled);
  }

  private emitChanges() {
    this.isLoading = true;

    const form = this.formGroup.value;
    if (!form?.fundingBasis) {
      this.areAdditionalFieldsShown = false;
      this.localSwapDetails = null;
      if (this.lastPriced) {
        const event: PricerChangedEvent = { swapDetails: null, pricingInfoList: null };
        this.pricerChanged.emit(event);
      } else {
        this.isLoading = false;
      }
      return;
    }

    const basisDefaults = this.fundingBasisMapping[form.fundingBasis];
    const defaultDayCount = basisDefaults['day_count'] ?? basisDefaults['daycount'];
    const defaultPaymentFrequency = basisDefaults['payment_frequency'] ?? basisDefaults['payment_freq'];

    const swapDetails: PricerSwapDetails = { ...form };
    if (form.dayCount === defaultDayCount) {
      delete swapDetails.dayCount;
    }
    if (form.paymentFrequency === defaultPaymentFrequency) {
      delete swapDetails.paymentFrequency;
    }

    this.localSwapDetails = swapDetails;

    this.pricingService
      .buildPricingInfoList(swapDetails)
      .pipe(take(1), takeUntil(this.ngOnDestroy$))
      .subscribe(pricingInfoList => {
        if (pricingInfoList.length) {
          this.pricerChanged.emit({ swapDetails, pricingInfoList });
        }
      });
  }

  reprice() {
    this.emitChanges();
  }

  reset() {
    this.isLoading = true;
    this.formGroup.reset({}, { emitEvent: false });
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
