import { NgModule } from '@angular/core';
import { CoreModule } from '@morpho/core';
import { RichTextModule } from '@morpho/rich-text';
import { AgGridModule } from 'ag-grid-angular';
import { PrimitiveCellEditorComponent } from './cell-editors/primitive-cell-editor/primitive-cell-editor.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { BasicInputContainerComponent } from './components/basic-input-container/basic-input-container.component';
import { ButtonToggleInputComponent } from './components/button-toggle-input/button-toggle-input.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { DataTableInputComponent } from './components/data-table-input/data-table-input.component';
import { DataTableRowDeleteCellRenderer } from './components/data-table-input/data-table-row-delete-cell-renderer';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DateRangeInputComponent } from './components/date-range-input/date-range-input.component';
import { DatetimeInputComponent } from './components/datetime-input/datetime-input.component';
import { DragFileInputComponent } from './components/drag-file-input/drag-file-input.component';
import { DragFileModalInputComponent } from './components/drag-file-modal-input/drag-file-modal-input.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { ListInputComponent } from './components/list-input/list-input.component';
import { MaturityInputComponent } from './components/maturity-input/maturity-input.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { PlaceholderInputComponent } from './components/placeholder-input/placeholder-input.component';
import { PrimitiveInputComponent } from './components/primitive-input/primitive-input.component';
import { RadioInputComponent } from './components/radio-input/radio-input.component';
import { RichTextInputComponent } from './components/rich-text-input/rich-text-input.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { TableInputComponent } from './components/table-input/table-input.component';
import { PhoneInputDirective } from './directives/phone-input.directive';

const declarations = [
  AutocompleteInputComponent,
  BasicInputContainerComponent,
  ButtonToggleInputComponent,
  CheckboxInputComponent,
  DataTableInputComponent,
  DataTableRowDeleteCellRenderer,
  DateInputComponent,
  DateRangeInputComponent,
  DatetimeInputComponent,
  FileInputComponent,
  ListInputComponent,
  PrimitiveInputComponent,
  RadioInputComponent,
  SelectInputComponent,
  TableInputComponent,
  DragFileInputComponent,
  DragFileModalInputComponent,
  RichTextInputComponent,
  PrimitiveCellEditorComponent,
  MaturityInputComponent,
  PhoneInputComponent,
  PhoneInputDirective,
  PlaceholderInputComponent,
];

@NgModule({
  declarations: declarations,
  imports: [CoreModule, AgGridModule, RichTextModule],
  exports: [...declarations],
})
export class FormModule {}
