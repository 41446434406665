import { Editor, Element, Node, NodeEntry, Path, Transforms } from 'slate';
import { ElementType, EMPTY_PARAGRAPH } from '../../constants/rich-text.constants';
import { RichTextList, RichTextListItem, RichTextParagraph } from '../../models/rich-text.model';
import { EditorHelper } from '../editor-helper';

const LIST_TYPES = [ElementType.OrderedList, ElementType.UnorderedList];
type ListType = ElementType.OrderedList | ElementType.UnorderedList;

export const EMPTY_LIST_ITEM: RichTextListItem = {
  type: ElementType.ListItem,
  children: [EMPTY_PARAGRAPH],
};

function createNewList(type: ListType, paragraph?: RichTextParagraph): RichTextList {
  const listItem = { ...EMPTY_LIST_ITEM };
  if (paragraph) {
    listItem.children = [paragraph];
  }
  return {
    type: type,
    children: [listItem],
  };
}

function getFullList(editor: Editor, path: number[]) {
  const getParentList = (currentPath: number[]) => {
    const parent =
      Editor.above(editor, {
        match: node => {
          return (
            Element.isElement(node) &&
            ![ElementType.Paragraph, ElementType.Hyperlink, ElementType.ListItem].includes(node.type)
          );
        },
        mode: 'lowest',
        at: currentPath,
      }) ?? [];
    if (parent.length && Element.isElement(parent[0]) && LIST_TYPES.includes(parent[0].type)) {
      return parent;
    }
    return null;
  };

  let currentElement = Editor.node(editor, path);
  while (currentElement) {
    const newElement = getParentList(currentElement[1]);
    if (newElement) {
      currentElement = newElement;
    } else {
      return currentElement;
    }
  }
  return null;
}

function getListDetail(depth: number, list: NodeEntry<Node> | null, terminatedEarly = false) {
  if (!list) {
    return;
  }

  const pathStrings: Set<string> = new Set();
  let listType: ElementType | null = null;

  const checkChildren = ([node, path]: NodeEntry<Node>, currentDepth: number): boolean => {
    if (!Element.isElement(node)) {
      return false;
    }
    for (let i = 0; i < node.children.length; i += 1) {
      if (!LIST_TYPES.includes(node.type)) {
        continue;
      }

      if (currentDepth === depth) {
        listType = node.type;
        pathStrings.add(JSON.stringify(path));
        if (terminatedEarly) {
          return true;
        }
      } else {
        const childNode = node.children[i];
        if (checkChildren([childNode, [...path, Number(i)]], currentDepth + 1)) {
          return true;
        }
      }
    }

    return false;
  };

  checkChildren(list, 1);

  return { type: listType, paths: Array.from(pathStrings).map(path => JSON.parse(path)) };
}

function getAllSelectedListItems(editor: Editor): NodeEntry<Node>[] {
  if (!editor.selection) {
    return [];
  }

  const firstListItem = EditorHelper.getBlock(editor);
  const listItems = Array.from(
    Editor.nodes(editor, {
      at: editor.selection,
      match: (node, path) => Element.isElement(node) && node.type === ElementType.ListItem,
      mode: 'lowest',
    }),
  );

  if (
    Element.isElement(firstListItem) &&
    firstListItem[0].type === ElementType.ListItem &&
    JSON.stringify(firstListItem?.[1]) !== JSON.stringify(listItems[0]?.[1])
  ) {
    listItems.unshift(firstListItem);
  }

  return listItems;
}

function wrapIntoList(editor: Editor, type: ListType | ElementType, path: number[], isWrappingParagraph = false) {
  const hasNestedPath = path.length > 1;
  const lastNodeRelative = Node.last(editor, hasNestedPath ? path.slice(0, -1) : []);
  const isLastNode = !Path.endsBefore(path, lastNodeRelative[1]);
  const relativeEndPath = hasNestedPath ? [...path.slice(0, -1), path.slice(-1)[0] + 1] : [editor.children.length];
  if (isLastNode) {
    Transforms.insertNodes(editor, { type: ElementType.Paragraph, children: [] }, { at: relativeEndPath });
  }
  if (isWrappingParagraph) {
    Transforms.wrapNodes(editor, { type: ElementType.ListItem, children: [] }, { at: path });
  }

  Transforms.wrapNodes(editor, { type, children: [] }, { at: path });
  if (isLastNode && !isWrappingParagraph) {
    Transforms.delete(editor, { at: relativeEndPath });
  }
}

function toggleList(editor: Editor, type: ListType) {
  Editor.withoutNormalizing(editor, () => {
    if (!editor.selection) {
      // TODO: if the first element in the doc is a list - this wraps the new list around it
      Transforms.insertNodes(editor, createNewList(type), { at: [0] });
      return;
    }

    const listItems = getAllSelectedListItems(editor);
    listItems.forEach(([_, listItemPath]) => {
      const fullList = getFullList(editor, listItemPath);
      if (fullList) {
        const fullListPath = fullList[1];
        const fullListType = (fullList[0] as any).type;
        if (type === fullListType) {
          let currentType = fullListType;
          let counter = 0;
          while (currentType === fullListType && counter < 4) {
            Transforms.unwrapNodes(editor, { at: fullListPath });
            const newFullList = Editor.node(editor, fullListPath);
            currentType = (newFullList[0] as any).type;
            counter += 1;
          }
        } else {
          const depth = listItemPath.length - fullListPath.length;
          const detail = getListDetail(depth, fullList);
          detail?.paths.forEach(path => {
            Transforms.setNodes(editor, { type }, { at: path });
          });
        }
      }
    });

    const paragraphs = EditorHelper.getParagraphBlocks(editor);
    const paragraphProperties: Partial<RichTextParagraph> = {
      indentation: undefined,
    };

    paragraphs.forEach(([_, path]) => {
      Transforms.setNodes(editor, paragraphProperties, { at: path });
      wrapIntoList(editor, type, path, true);
    });
  });
}

function indent(editor: Editor): boolean {
  const listItems = getAllSelectedListItems(editor);
  Editor.withoutNormalizing(editor, () => {
    listItems.forEach(([node, path]) => {
      const fullList = getFullList(editor, path);
      let type = ElementType.OrderedList;
      if (fullList) {
        const depth = path.length - fullList[1].length + 1;
        const detail = getListDetail(depth, fullList, true);
        if (detail?.type) {
          type = detail.type;
        } else {
          const parentList = Editor.node(editor, path.slice(0, -1));
          if (Element.isElement(parentList[0])) {
            type = parentList[0].type;
          }
        }
      }
      wrapIntoList(editor, type, path);
    });
  });
  return !!listItems.length;
}

function outdent(editor: Editor, force = false): boolean {
  let hasAppliedOutdent = false;

  const listItems = getAllSelectedListItems(editor);
  [...listItems].reverse().forEach(([node, path]) => {
    if (force) {
      if (path.length === 1) {
        return;
      }
      const [parentNode] = Editor.node(editor, Path.parent(path));
      if (!(Element.isElement(parentNode) && LIST_TYPES.includes(parentNode.type))) {
        return;
      }
    } else {
      hasAppliedOutdent = true;
      if (path.length < 3) {
        return;
      }
      const [grandparentNode] = Editor.node(editor, Path.parent(Path.parent(path)));
      if (!(Element.isElement(grandparentNode) && LIST_TYPES.includes(grandparentNode.type))) {
        return;
      }
    }

    Editor.withoutNormalizing(editor, () => {
      Transforms.splitNodes(editor, {
        at: path,
      });

      const newListPath = path.slice(0, -1);
      newListPath[newListPath.length - 1] += 1;

      Transforms.moveNodes(editor, {
        at: [...newListPath, 0],
        to: newListPath,
      });

      const nextPath = [...newListPath];
      nextPath[nextPath.length - 1] += 1;
      const [nextNode] = Editor.node(editor, nextPath);

      if (!(nextNode as any).children.length) {
        Transforms.removeNodes(editor, { at: nextPath });
      }
    });

    hasAppliedOutdent = true;
  });

  return hasAppliedOutdent;
}

export const ListEditor = {
  toggleList,
  indent,
  outdent,
};
