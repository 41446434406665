import { Injectable } from '@angular/core';
import { UtilService } from '@morpho/core';
import { Store } from '@ngrx/store';
import { map, Observable, of, switchMap, take, tap } from 'rxjs';
import { RatingsApiService } from './ratings-api.service';
import { AGENCY_PRODUCT_MAP, AgencyName, AgencyProduct } from './ratings-constants';
import { RatingsAction } from './state/ratings.actions';
import {
  BulkRatingsResponse,
  EntityRatings,
  EntityRatingsMap,
  LTOutlookMap,
  RatingsMap,
  SeniorityRatings,
} from './state/ratings.model';
import { RatingsSelector } from './state/ratings.selectors';

const agencies = [AgencyName.Moodys, AgencyName.Fitch, AgencyName.SP, AgencyName.Scope, AgencyName.Dbrs];
const terms = [
  AgencyProduct.ShortTerm,
  AgencyProduct.LongTerm,
  AgencyProduct.CoveredBond,
  AgencyProduct.SeniorPreferred,
  AgencyProduct.SeniorUnsecured,
  AgencyProduct.SeniorUnsecuredNonPreferred,
  AgencyProduct.LowerTierTwo,
];

@Injectable({
  providedIn: 'root',
})
export class RatingsService {
  constructor(
    private ratingsApiService: RatingsApiService,
    private store: Store,
    private utilService: UtilService,
  ) {}

  getRatingForVeritasId(veritasId: number): Observable<EntityRatings | undefined> {
    return this.getRatingForVeritasIds([veritasId]).pipe(map(ratingsMap => ratingsMap?.[veritasId]));
  }

  getRatingForVeritasIds(veritasIds: number[]): Observable<EntityRatingsMap | undefined> {
    const filteredVeritasIds = new Set<number>(veritasIds);

    return this.store.select(RatingsSelector.issuingEntityRatings).pipe(
      take(1),
      switchMap((ratings: EntityRatingsMap) => {
        const newVeritasIds = Array.from(filteredVeritasIds).filter(veritasId => !ratings?.[veritasId]);
        if (!newVeritasIds.length) {
          return of(ratings);
        }
        return this.ratingsApiService.getBulkRatings(newVeritasIds).pipe(
          tap((response: BulkRatingsResponse['results']) => {
            this.store.dispatch(
              RatingsAction.setRatingsForIssuingEntities({
                params: {
                  ratings: response,
                },
              }),
            );
          }),
          switchMap(() => this.store.select(RatingsSelector.issuingEntityRatings).pipe(take(1))),
        );
      }),
      map((ratings: EntityRatingsMap) => {
        const filteredRatings: EntityRatingsMap = {};
        filteredVeritasIds.forEach(id => {
          if (ratings?.[id]) {
            filteredRatings[id] = ratings[id];
          }
        });
        return filteredRatings;
      }),
    );
  }

  addRatingsToArray(items: any[]): Observable<any[]> {
    const veritasIds = this.getVeritasIds(items);

    return this.getRatingForVeritasIds(veritasIds).pipe(
      map(ratings => {
        if (!ratings) {
          return items;
        }

        return items.map((item: any) => {
          const itemRatings = item.veritas_issuing_entity_ids
            ? this.getFirstRatingsWithData(ratings, item.veritas_issuing_entity_ids)
            : ratings[item.veritas_issuing_entity_id];

          return {
            ...item,
            ...(itemRatings
              ? {
                  ...this.createRatings(itemRatings, item.seniority),
                  ...this.createLongTermOutlooks(itemRatings, item.seniority),
                }
              : { ...this.createEmptyMapping('ratings'), ...this.createEmptyMapping('outlooks') }),
          };
        });
      }),
    );
  }

  addRatingsToMap(items: { [key: string]: any }): Observable<{ [key: string]: any }> {
    const veritasIds = this.getVeritasIds(Object.values(items));

    return this.getRatingForVeritasIds(veritasIds).pipe(
      map(ratings => {
        if (!ratings) {
          return items;
        }

        Object.entries(items).forEach(([key, item]) => {
          const itemRatings = item.veritas_issuing_entity_ids
            ? this.getFirstRatingsWithData(ratings, item.veritas_issuing_entity_ids)
            : ratings[item.veritas_issuing_entity_id];

          items[key] = {
            ...item,
            ...(itemRatings ? this.createRatings(itemRatings, item.seniority) : this.createEmptyMapping('ratings')),
          };
        });

        return items;
      }),
    );
  }

  generateKeys(postfix: string) {
    return this.utilService.getPossiblePermutations([agencies, terms, [postfix]]).map((val: string[]) => {
      return val.join('_');
    });
  }

  createLongTermOutlooks(ratings: EntityRatings, seniority?: string): LTOutlookMap {
    const outlookKeys = this.generateKeys('outlook');
    const useLongTermFallback = !!seniority && ['senior_unsecured', 'senior_preferred'].includes(seniority);
    return outlookKeys.reduce((acc, key) => {
      if (key.includes(AgencyProduct.LongTerm) && !key.includes(AgencyProduct.LowerTierTwo)) {
        acc = {
          ...acc,
          [key]: this.getLongTermRatingOutlook(ratings, this.returnAgencyName(key), seniority, useLongTermFallback),
        };
      }
      return acc;
    }, {} as LTOutlookMap);
  }

  createRatings(ratings: EntityRatings, seniority?: string): RatingsMap {
    const ratingKeys = this.generateKeys('rating');
    const useLongTermFallback = !!seniority && ['senior_unsecured', 'senior_preferred'].includes(seniority);
    return ratingKeys.reduce((acc, key) => {
      if (key.includes(AgencyProduct.ShortTerm)) {
        acc = { ...acc, [key]: this.getShortTermRatingValue(ratings, this.returnAgencyName(key)) };
      } else if (key.includes(AgencyProduct.LongTerm) && !key.includes(AgencyProduct.LowerTierTwo)) {
        acc = {
          ...acc,
          [key]: this.getLongTermRatingValue(ratings, this.returnAgencyName(key), seniority, useLongTermFallback),
        };
      } else {
        acc = {
          ...acc,
          [key]: this.getSeniorityRatingValue(ratings, this.returnAgencyName(key), this.returnSeniority(key)),
        };
      }
      return acc;
    }, {} as RatingsMap);
  }

  createEmptyMapping(key: string): RatingsMap | LTOutlookMap {
    const keys = this.generateKeys(key);
    return keys.reduce(
      (acc, key) => {
        acc = { ...acc, [key]: 0 };
        return acc;
      },
      {} as RatingsMap | LTOutlookMap,
    );
  }

  returnAgencyName(key: string): AgencyName {
    return key.split('_')[0] as AgencyName;
  }

  returnSeniority(key: string): string {
    return AGENCY_PRODUCT_MAP[key.split('_')[1] as AgencyProduct];
  }

  private getVeritasIds(items: any[]): number[] {
    let veritasIds: number[] = [];

    items.forEach(item => {
      if (item.hasOwnProperty('veritas_issuing_entity_id')) {
        veritasIds.push(item.veritas_issuing_entity_id);
      } else if (item.hasOwnProperty('veritas_issuing_entity_ids')) {
        veritasIds = [...veritasIds, ...item.veritas_issuing_entity_ids];
      }
    });

    return veritasIds.filter(id => !!id);
  }

  private getLongTermRatingValue(
    ratings: EntityRatings,
    agency: AgencyName,
    seniority?: string,
    useLongTermFallback?: boolean,
  ): number {
    let ratingScore = 0;
    if (seniority) {
      ratingScore = ratings[agency].seniority[seniority].value;
    }

    if (!seniority || (!ratingScore && useLongTermFallback)) {
      ratingScore = ratings[agency]?.long_term.value;
    }

    return ratingScore;
  }

  private getShortTermRatingValue(ratings: EntityRatings, agency: AgencyName): number {
    return ratings[agency]?.short_term.value;
  }

  private getSeniorityRatingValue(ratings: EntityRatings, agency: AgencyName, seniority: string): number {
    return ratings[agency]?.seniority[seniority]?.value;
  }

  private getLongTermRatingOutlook(
    ratings: EntityRatings,
    agency: AgencyName,
    seniority?: string,
    useLongTermFallback?: boolean,
  ): string {
    let ratingOutlook = '';
    if (seniority) {
      ratingOutlook = ratings[agency].seniority[seniority].outlook;
    }

    if (!seniority || (!ratingOutlook && useLongTermFallback)) {
      ratingOutlook = ratings[agency]?.long_term.outlook;
    }

    return ratingOutlook;
  }

  private getSeniorityEnityRatings(a: SeniorityRatings, b: SeniorityRatings): SeniorityRatings {
    Object.entries(a).forEach(([seniority, ratings]) => {
      a[seniority] = ratings.value ? ratings : b[seniority];
    });
    return a;
  }

  private getFirstRatingsWithData(ratingsMap: EntityRatingsMap, veritasIds: number[]): EntityRatings {
    const ratings: EntityRatings[] = [];
    veritasIds.forEach((veritasId: number) => {
      if (veritasId) {
        const ratingData = JSON.parse(JSON.stringify(ratingsMap[veritasId] ?? null));
        if (ratingData) {
          ratings.push(ratingData);
        }
      }
    });
    const mergedRatings = ratings.reduce((acc: EntityRatings, current: EntityRatings) => {
      Object.entries(current).forEach(([agency, rating]) => {
        acc[agency] ??= rating;
        acc[agency].long_term = rating.long_term.value ? rating.long_term : acc[agency].long_term;
        acc[agency].short_term = rating.long_term.value ? rating.short_term : acc[agency].short_term;
        acc[agency].seniority = this.getSeniorityEnityRatings(acc[agency].seniority, rating.seniority);
      });
      return acc;
    }, {} as EntityRatings);

    return mergedRatings;
  }
}
