import { FocusMonitor } from '@angular/cdk/a11y';
/**
 * NOTES FOR EXTENDING THIS CLASS
 *
 * If you overwrite set value(), make sure you include
 * this.emitChanges(true);
 *
 * If you overwrite ngOnDestroy(), make sure you include
 * this.destroy();
 */
// https://material.angular.io/guide/creating-a-custom-form-field-control
// https://angular.io/api/forms/ControlValueAccessor
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { IoService, LevelsUtilService, PopupService, TrackByService } from '@morpho/core';
import { RichTextService } from '@morpho/rich-text';
import { Subject, takeUntil } from 'rxjs';
import { FormUtilService } from '../services/form-util.service';
import { ValidationService } from '../services/validation.service';

@Component({
  standalone: false,
  template: '',
})
export abstract class CustomFormFieldControlComponent
  implements OnDestroy, MatFormFieldControl<any>, ControlValueAccessor
{
  static nextId = 0;
  @HostBinding()
  id = `om-custom-form-input-${CustomFormFieldControlComponent.nextId++}`;

  private ngOnDestroy$ = new Subject<void>();

  focused: boolean;
  private hasBeenFocused: boolean;
  private hasBeenTouched: boolean;
  shouldLabelFloat: boolean;
  controlType?: string | undefined;
  autofilled?: boolean | undefined;

  stateChanges = new Subject<void>();

  private hasValueBeenInitialised = false;

  constructor(
    protected elementRef: ElementRef<HTMLElement>,
    focusMonitor: FocusMonitor,
    @Optional() protected ioService: IoService,
    @Optional() protected popupService: PopupService,
    @Optional() public trackBy: TrackByService,
    @Optional() protected utilService: FormUtilService,
    @Optional() protected validationService: ValidationService,
    @Optional() protected levelsUtilService: LevelsUtilService,
    @Optional() protected richTextService: RichTextService,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
    focusMonitor
      ?.monitor(elementRef.nativeElement, true)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(origin => {
        const focused = !!origin;
        if (this.focused && !focused) {
          this.hasBeenFocused = true;
        }
        this.focused = focused;
      });
  }

  @Input()
  get value(): any | null {
    return this._value;
  }
  set value(val: any | null) {
    this._value = val;
    this.emitChanges(true);
  }
  protected _value: any;

  get empty() {
    return !!this.value;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  private _errorState: boolean;
  get errorState() {
    if (this.ngControl?.touched) {
      this.hasBeenTouched = true;
    } else if (this.hasBeenTouched) {
      this.hasBeenTouched = false;
      this.hasBeenFocused = false;
    }
    const errorState = !!(this.ngControl?.invalid && (this.hasBeenTouched || this.hasBeenFocused));
    if (this._errorState !== errorState) {
      this._errorState = errorState;
    }
    return errorState;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value) {
    this._placeholder = value === undefined ? '' : value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @HostBinding('attr.aria-describedby') describedBy = '';
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  /*
   * Override the below function in derived classes if enter
   * default behaviour is needed
   * */
  @HostListener('keydown.enter', ['$event'])
  onEnterPressed(event: KeyboardEvent) {
    event.preventDefault();
  }

  emitChanges(isInitial = false, isFromError = false) {
    if (isInitial && this.hasValueBeenInitialised) {
      return;
    }
    this.stateChanges.next();
    if (!isInitial || !isFromError) {
      this.onChange(this.value);
    }
    this.hasValueBeenInitialised = true;
  }

  onContainerClick(event: MouseEvent) {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  private onChange(value: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy() {
    this.destroy();
  }

  private destroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
    this.stateChanges.complete();
  }

  // to override
  public focus() {}
}
