import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BACKEND_DATE_FORMAT, BACKEND_DATE_NO_YEAR_FORMAT, DATE_FORMAT, DATE_NO_YEAR_FORMAT } from '@morpho/core';
import * as moment from 'moment-timezone';
import { DateRangeValue } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

type FormatType = 'moment';
@Component({
  standalone: false,
  selector: 'om-date-range-input',
  templateUrl: './date-range-input.component.html',
  providers: [getCustomFormFieldProviders(forwardRef(() => DateRangeInputComponent))],
})
export class DateRangeInputComponent extends CustomFormFieldControlComponent implements OnChanges {
  @ViewChild('rangePicker', { read: undefined, static: false }) datePicker: MatDatepicker<moment.Moment>;

  displayFormat = DATE_FORMAT;
  parseFormat = BACKEND_DATE_FORMAT;

  displayValue = '';
  datePickerClass: string;
  datePickerStartView = 'month';

  startDate: moment.Moment;
  endDate: moment.Moment;

  @Input()
  min: string;

  @Input()
  max: string;

  @Input()
  format: FormatType;

  @Input()
  get value() {
    return this._value;
  }
  set value(value: DateRangeValue) {
    this._value = value;
    this.emitChanges(true);

    if (!this._value) {
      this.displayValue = this.placeholder || '';
      return;
    }

    this.startDate = moment(value.start, this.parseFormat);
    this.endDate = moment(value.end, this.parseFormat);
    this.updateDisplay();
  }
  _value: DateRangeValue;

  @Input()
  get hideYear() {
    return this._hideYear;
  }
  set hideYear(value: boolean) {
    this._hideYear = coerceBooleanProperty(value);
    if (!this._hideYear) {
      return;
    }
    this.displayFormat = DATE_NO_YEAR_FORMAT;
    this.parseFormat = BACKEND_DATE_NO_YEAR_FORMAT;
    this.datePickerClass = 'om-datepicker-no-year';
    this.datePickerStartView = 'year';
  }
  private _hideYear = false;

  @HostListener('click') onClick() {
    if (this.disabled) {
      return;
    }
    this.datePicker.opened ? this.datePicker.close() : this.datePicker.open();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.value) {
      this.startDate = moment.isMoment(this.value.start)
        ? this.value.start
        : this.stringToMoment(this.value.start || '');
      this.endDate = moment.isMoment(this.value.end) ? this.value.end : this.stringToMoment(this.value.end || '');
      this.updateValue();
    }

    if (this.hideYear && (changes.hideYear || changes.min || changes.max)) {
      if (this.min && !this.min.startsWith('2000-')) {
        this.min = `2000-${this.min}`;
      }
      if (this.max && !this.max.startsWith('2000-')) {
        this.max = `2000-${this.max}`;
      }
      this.min = this.min || '2000-01-01';
      this.max = this.max || '2000-12-31';
    }
  }

  onValueChange() {
    this.updateValue();
  }

  private stringToMoment(dateString: string) {
    if (this.hideYear) {
      dateString = `2000-${dateString}`;
    }
    return moment(dateString, this.parseFormat);
  }

  private updateValue() {
    if (this.format === 'moment') {
      this.value = { start: this.startDate, end: this.endDate };
    } else {
      this.value = { start: this.startDate.format(this.parseFormat), end: this.endDate.format(this.parseFormat) };
    }
    this.emitChanges();
    this.updateDisplay();
  }

  private updateDisplay() {
    this.displayValue = this.startDate.format(this.displayFormat) + ' - ' + this.endDate.format(this.displayFormat);
  }

  public focus() {
    this.datePicker?.open();
  }
}
