@if (slides) {
  <div class="overlay-content">
    <div class="om-carousel-modal is-modal">
      @if (slides.length > 1) {
        <button mat-mini-fab class="is-not-strong" [disabled]="currentIndex === 0" (click)="moveLeft()">
          <mat-icon svgIcon="left"></mat-icon>
        </button>
      }
      <mat-tab-group [selectedIndex]="currentIndex" class="is-inside-container">
      @for (slide of slides; track slide; let i = $index) {
          <mat-tab>
            <div class="mdc-dialog-container">
              <om-carousel-slide
                [slideData]="slide"
                [carouselDialogRef]="dialogRef"
                [slideComponent]="slideComponent"
                (closeSlide)="closeSlide(slides, i)"
              ></om-carousel-slide>
            </div>
          </mat-tab>
        }
      </mat-tab-group>
      @if (slides.length > 1) {
        <button
          mat-mini-fab
          class="is-not-strong"
          (click)="moveRight()"
          [disabled]="currentIndex === slides.length - 1"
        >
          <mat-icon svgIcon="right"></mat-icon>
        </button>
      }
    </div>
    @if (slides.length > 1) {
      <ul class="om-carousel-modal-nav">
        @for (slide of slides; track slide; let i = $index) {
          <li>
            <button
              (click)="setCurrentIndex(i)"
              class="om-carousel-modal__nav-button"
              [class.is-active]="i === currentIndex"
            ></button>
          </li>
        }
      </ul>
    }
  </div>
}
