import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RatingsState } from './ratings.model';
import { RATINGS_STATE_KEY } from './ratings.reducer';

const ratingsState = createFeatureSelector<RatingsState>(RATINGS_STATE_KEY);

export const RatingsSelector = {
  state: ratingsState,
  ratings: createSelector(ratingsState, (state: RatingsState) => state.ratings),
  issuingEntityRatings: createSelector(ratingsState, (state: RatingsState) => state.issuingEntityRatings),
};
