/*
  This file contains regex for urls that should be cached
  when (at the time of writing, though not restricted to) calling
  via the ./api.service.ts callApi method, which is mostly used for
  the dynamic form (alter/compute fields) and form options endpoints.
*/
export const callApiUrlsToCache = [
  new RegExp('/api/transactions/is-field-required/'),
  new RegExp('/api/transactions/default-swap-required/'),
  new RegExp('/api/transactions/is-swap-required/'),
  new RegExp('/api/transactions/is-swap-margin-required/'),
  new RegExp('/api/pricer/bond-yield/'),
  new RegExp('/api/pricer/bond-price/'),
];
