<h1 mat-dialog-title [class.is-boolean-popup-title]="isBooleanPopup">
  {{ data.title }}
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h1>
<div mat-dialog-content>
  @if (data.icon) {
    <img class="is-transparent" [src]="'assets/img/' + data.icon + '.svg' | cdn" />
  }
  @if (data.strong_message) {
    <p>
      <b>{{ data.strong_message }}</b>
    </p>
  }
  @if (data.message) {
    @switch (data.message | elementTypeFromValue) {
      @case (elementValueType.Richtext) {
        <om-rich-text-input [value]="data.message" [readonly]="true"></om-rich-text-input>
      }
      @default {
        <p class="is-pre-line">{{ data.message }}</p>
      }
    }
  }
  @if (data.additionalInfo) {
    <p class="is-pre-line">
      <b>{{ data.additionalInfo }}</b>
    </p>
  }
  @if (data.events?.length) {
    <ol>
      @for (item of data.events; track item) {
        <li>
          {{ item.description }}
        </li>
      }
    </ol>
  }
  @if (data.info) {
    <div class="info-container">
      @if (data.info.description) {
        <span>{{ data.info.description }}&nbsp;</span>
      }
      @if (data.info.actions?.length) {
        @for (action of data.info.actions; track action) {
          @if (action.type === 'redirect') {
            @if (action.external) {
              <a mat-button class="is-text" [href]="action.url | safeUrl">{{ action.text }}</a>
            } @else {
              <a mat-button class="is-text" [routerLink]="action.url">{{ action.text }}</a>
            }
          }
        }
      }
    </div>
  }
</div>
<div mat-dialog-actions>
  @if (isBooleanPopup) {
    <button mat-button [ngClass]="data.left_action?.class" (click)="onAction(data.left_action)">
      {{ data.left_action?.text || 'Cancel' }}
    </button>
    <button mat-button [ngClass]="data.right_action?.class" (click)="onAction(data.right_action, true)">
      {{ data.right_action?.text || 'OK' }}
      @if (isLoading) {
        <mat-spinner></mat-spinner>
      }
    </button>
  } @else {
    <button mat-button [ngClass]="data.action?.class" (click)="onAction(data.action)">
      {{ data.action?.text || 'OK' }}
    </button>
  }
</div>
