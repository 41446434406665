import { Injectable } from '@angular/core';
import { ColDef, IToolPanelParams } from 'ag-grid-community';
import { Observable, map } from 'rxjs';
import { AgGridPanel, BaseSidebarConfig } from '../ag-grid-panels.model';
import { FilterSidebarItemConfig, FilterType, SelectFilterOption } from '../models/ag-grid-panel.model';

@Injectable({
  providedIn: 'root',
})
export class AgGridPanelsService {
  getPanelConfig(params: IToolPanelParams, panelKey: AgGridPanel): any {
    return params.api.getSideBar()?.toolPanels?.find((option: BaseSidebarConfig) => option.id === panelKey);
  }

  generateFilterColumnConfig(filterColumns: FilterSidebarItemConfig[], gridOptions: any): FilterSidebarItemConfig[] {
    const colDefs = gridOptions.columnDefs;
    const rowData = gridOptions.rowData;

    return filterColumns.reduce((acc: FilterSidebarItemConfig[], current: FilterSidebarItemConfig) => {
      if (current.type === FilterType.SelectSingle || current.type === FilterType.SelectMultiple) {
        if (!current.options) {
          const colDef = colDefs?.find((colDef: ColDef) => colDef?.colId === current.id);
          if (colDef?.filterParams?.omGroupFilterOptions) {
            current.options = colDef.filterParams.omGroupFilterOptions.map((col: any) => {
              return {
                value: col.value,
                label: col.label,
                selected: false,
              };
            });
          }

          const omTranslation = colDef?.filterParams?.omTranslation;

          if (omTranslation) {
            current.options = this.generateOptionsFromGridData(rowData, current.id, omTranslation);
          }
        }
      }
      if (!current.label) {
        const colDef = colDefs?.find((colDef: ColDef) => colDef?.colId === current.id);
        current.label = colDef?.headerName;
      }
      acc?.push(current);
      return acc;
    }, []);
  }

  generateOptions(
    filterColOption$: Observable<FilterSidebarItemConfig[]>,
    gridOptions: any,
  ): Observable<FilterSidebarItemConfig[]> {
    return filterColOption$.pipe(
      map((filterColOption: FilterSidebarItemConfig[]) => {
        const colDefs = gridOptions.columnDefs;
        const rowData = gridOptions.rowData;

        return filterColOption.reduce((acc: FilterSidebarItemConfig[], current: FilterSidebarItemConfig) => {
          if (current.type === FilterType.SelectSingle || current.type === FilterType.SelectMultiple) {
            if (!current.options) {
              const colDef = colDefs?.find((colDef: ColDef) => colDef?.colId === current.id);
              if (colDef?.filterParams?.omGroupFilterOptions) {
                current.options = colDef.filterParams.omGroupFilterOptions.map((col: any) => {
                  return {
                    value: col.value,
                    label: col.label,
                    selected: false,
                  };
                });
              }

              const omTranslation = colDef?.filterParams?.omTranslation;

              if (omTranslation) {
                current.options = this.generateOptionsFromGridData(rowData, current.id, omTranslation);
              }
            }
          }
          if (!current.label) {
            const colDef = colDefs?.find((colDef: ColDef) => colDef?.colId === current.id);
            current.label = colDef?.headerName;
          }
          acc?.push(current);
          return acc;
        }, []);
      }),
    );
  }

  removeDuplicateObjects(arrayOfObjects: any[], key: string): any[] {
    return arrayOfObjects?.filter((item, index) => {
      return (
        index ===
        arrayOfObjects.findIndex(object => {
          return item[key] === object[key];
        })
      );
    });
  }

  generateOptionsFromGridData(rowData: any, id: string, omTranslation: Record<string, string>): SelectFilterOption[] {
    const mappedRowData = rowData?.map((row: any) => {
      return {
        value: row[id],
        label: omTranslation?.[row[id]] ?? row[id],
        selected: false,
      };
    });
    return this.removeDuplicateObjects(mappedRowData, 'value');
  }
}
