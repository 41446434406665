import { Component, Input } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-linear-progress-indicator',
  templateUrl: './linear-progress-indicator.component.html',
})
export class LinearProgressIndicatorComponent {
  @Input() progress: number;
}
