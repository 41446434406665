import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TrackByService {
  index(index: number) {
    return index;
  }

  id(index: number, item: any) {
    return item.id;
  }

  label(index: number, item: any) {
    return item.label;
  }

  name(index: number, item: any) {
    return item.name;
  }

  title(index: number, item: any) {
    return item.title;
  }

  text(index: number, item: any) {
    return item.text;
  }

  value(index: number, item: any) {
    return item.value;
  }

  divider_label(index: number, item: any) {
    return item.divider_label;
  }

  fieldName(index: number, item: any) {
    return item.fieldName;
  }

  field_name(index: number, item: any) {
    return item.field_name;
  }
}
