import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

@Component({
  standalone: false,
  selector: 'om-data-table-row-delete-cell-renderer',
  template: `<button mat-icon-button class="is-flat is-warning" (click)="delete()">
    <mat-icon svgIcon="remove"></mat-icon>
  </button>`,
})
export class DataTableRowDeleteCellRenderer implements ICellRendererAngularComp {
  private rowNode?: IRowNode<any>;
  private onClick: (rowNode: IRowNode) => void;

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
    this.onClick = params.colDef?.cellRendererParams.onClick;
    return true;
  }

  delete() {
    if (this.rowNode) {
      this.onClick(this.rowNode);
    }
  }
}
