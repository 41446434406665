@if (columnDefinitions$ | async; as columnDefinitions) {
  <table mat-table [dataSource]="data$ | async" class="om-grid">
    @for (columnDef of columnDefinitions; track columnDef) {
      <ng-container [matColumnDef]="columnDef.val">
        <th mat-header-cell *matHeaderCellDef>{{ columnDef.label }}</th>
        <td mat-cell *matCellDef="let row">
          @switch (columnDef.val) {
            @case ('actions') {
              <om-action-menu
                [config]="row.actionMenu"
                [dataToEmit]="row"
                (selected)="onRowActionSelected($event)"
              ></om-action-menu>
            }
            @case ('location') {
              <div class="mat-column-image">
                <img class="om-flag" [src]="row.country | countryFlag" [alt]="row.country" />
                {{ row.city }}
              </div>
            }
            @default {
              <span>{{ row[columnDef.val] }}</span>
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="columns$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: columns$ | async"></tr>
  </table>
}
