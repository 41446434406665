export enum FormPrefixes {
  DocumentGeneratorComment = 'om-document-generator-comment',
  DocumentGeneratorFlexibleEdit = 'om-document-generator-flexible-edit',
  DocumentGeneratorMain = 'om-document-generator-main',
  DocumentGeneratorReadonly = 'om-document-generator-readonly',
}

// https://www.regextester.com/93652
export const urlValidationRegex = new RegExp(
  '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
  'i',
);

export const callableStructureRegexString =
  '^(\\d+(?:\\.\\d+)?[MYmy]?)(?:[nN][cC])(\\d+[MYmy]?)(?:[+Xx](\\d+[MYmy]?))?$';
