<mat-expansion-panel (closed)="closePanel()" (opened)="openPanel()">
  <mat-expansion-panel-header>
    <mat-panel-title
      ><span> {{ columnConfig.label }} </span>
      @if (selectedOption && !isPanelOpen) {
        <mat-chip-listbox>
          <mat-chip>
            {{ chipDisplay }}
          </mat-chip>
        </mat-chip-listbox>
      }
    </mat-panel-title>
    @if (isPanelOpen && columnConfig?.description) {
      <mat-panel-description>
        {{ columnConfig.description }}
      </mat-panel-description>
    }
  </mat-expansion-panel-header>
  <section>
    <mat-radio-group (change)="onChange($event)">
      @for (option of columnConfig.options; track option) {
        <mat-radio-button [value]="option.value" [checked]="option.value === selectedOption">
          {{ option.label }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </section>
</mat-expansion-panel>
