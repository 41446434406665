import { createAction, props } from '@ngrx/store';
import { SavedIssuerList } from 'apps/bankangle/src/app/core/patterns/issuer/issuer.model';
import { AuthenticatedUser } from './authenticated-user.model';

const AUTHENTICATED_USER_ACTION = '[Authenticated User]';

const GET_AUTHENTICATED_USER = `${AUTHENTICATED_USER_ACTION} Get Authenticated User`;
const GET_AUTHENTICATED_USER_SUCCESS = `${GET_AUTHENTICATED_USER} SUCCESS`;
const GET_AUTHENTICATED_USER_FAIL = `${GET_AUTHENTICATED_USER} FAIL`;

const GET_FAVOURITE_ISSUERS = `${AUTHENTICATED_USER_ACTION} Get Favourite Issuers`;
const GET_FAVOURITE_ISSUERS_SUCCESS = `${GET_FAVOURITE_ISSUERS} SUCCESS`;
const GET_FAVOURITE_ISSUERS_FAIL = `${GET_FAVOURITE_ISSUERS} FAIL`;

const IS_IMPERSONATION = `${AUTHENTICATED_USER_ACTION} Is Being Impersonated`;
const SET_COMPANY_LOGO = `${AUTHENTICATED_USER_ACTION} Set Company Logo`;

export const AuthenticatedUserEffect = {
  GET_AUTHENTICATED_USER: GET_AUTHENTICATED_USER,
  GET_FAVOURITE_ISSUERS,
};

export const AuthenticatedUserAction = {
  getAuthenticatedUser: createAction(GET_AUTHENTICATED_USER),
  getAuthenticatedUserSuccess: createAction(GET_AUTHENTICATED_USER_SUCCESS, props<{ params: AuthenticatedUser }>()),
  getAuthenticatedUserFail: createAction(GET_AUTHENTICATED_USER_FAIL),

  getFavouriteIssuers: createAction(GET_FAVOURITE_ISSUERS),
  getFavouriteIssuersSuccess: createAction(GET_FAVOURITE_ISSUERS_SUCCESS, props<{ params: SavedIssuerList }>()),
  getFavouriteIssuersFail: createAction(GET_FAVOURITE_ISSUERS_FAIL),

  isImpersonation: createAction(IS_IMPERSONATION),
};
