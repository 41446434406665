import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactComponent } from '../components/contact/contact.component';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private matDialog: MatDialog) {}

  openContactModal() {
    this.matDialog.open(ContactComponent, {
      width: 'fit-content',
    });
  }
}
