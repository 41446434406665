import { Injectable } from '@angular/core';
import { environment } from '@morpho/environment';
import { FileSaverOptions, saveAs } from 'file-saver';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PopupService } from './popup.service';

export enum CookieName {
  ImpersonateUser = 'impersonate_user',
  AliasAccount = 'use_alias',
  CSFRToken = 'csrftoken',
  BulletLevelsSelectedViewId = 'bullet_levels_selected_view_id',
}

export enum TabEventName {
  Impersonate = 'impersonate',
  ChangeAlias = 'change_alias',
  Logout = 'logout',
  Login = 'login', // todo go to next
}

@Injectable({
  providedIn: 'root',
})
export class IoService {
  private readonly localStorage$: Subject<StorageEvent> = new Subject();

  constructor(private popupService: PopupService) {
    window.addEventListener('storage', event => {
      this.localStorage$.next(event);
    });
  }

  getSessionItem(key: string) {
    const json = sessionStorage.getItem(key);
    return json ? JSON.parse(json) : json;
  }

  setSessionItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getCookie(name: CookieName): string | null {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
  }

  setCookie(name: CookieName, value: string): void {
    const cookie = `${name}=${value};`;
    const path = `path=/;`;
    const secure = environment.deployed ? 'secure;' : '';
    const samesite = 'samesite=lax;';
    const maxAge = 'max-age=1209600;'; // 2 weeks
    document.cookie = `${cookie}${path}${secure}${samesite}${maxAge}`;
  }

  deleteCookie(name: string): void {
    document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  }

  clearCookies() {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.deleteCookie(name);
    });
  }

  emitTabEvent(key: string, value?: string) {
    window.localStorage.setItem(key, value ?? Date.now().toString());
  }

  monitorTabEvent(key: TabEventName): Observable<string | null | undefined> {
    return this.localStorage$.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.newValue),
    );
  }

  copy(text: string, params?: { isHtml?: boolean; successMessage?: string }) {
    const onCopy = (event: ClipboardEvent) => {
      event.clipboardData?.setData('text/plain', text);

      if (params?.isHtml) {
        let cleanHtml = '' + text;
        cleanHtml = cleanHtml.replace(/<!--[\s\S]*?-->/gi, ''); // remove comments
        cleanHtml = cleanHtml.replace(/>[\s\n]*/gi, '>'); // remove tag whitespace
        cleanHtml = cleanHtml.replace(/[\s\n]*</gi, '<'); // remove tag whitespace
        const html = new DOMParser().parseFromString(cleanHtml, 'text/html');
        [...html.body.querySelectorAll('*')].reverse().forEach(element => {
          element.removeAttribute('style');
        });
        cleanHtml = html.body.innerHTML;

        event.clipboardData?.setData('text/html', cleanHtml);
      }

      event.preventDefault();
      setTimeout(() => {
        this.popupService.message(params?.successMessage ?? 'Copied to clipboard');
      }, 250);
    };
    document.addEventListener('copy', onCopy);
    document.execCommand('copy');
    document.removeEventListener('copy', onCopy);
  }

  downloadFile(data: string | Blob, filename?: string, options?: FileSaverOptions) {
    saveAs(data, filename, options);
  }

  openUrlInNewWindow(url: string) {
    const newWindow = window.open(url, '_blank');
    newWindow?.blur();
    window.focus();
    return;
  }
}
