import { createAction, props } from '@ngrx/store';
import { UploadedDocumentInstance } from '../document-extractor.model';
import { DocumentExtractorState } from './document-extractor.model';

const UPLOAD_DOCUMENT = '[Document Extractor] Upload Document';
const UPLOAD_DOCUMENT_SUCCESS = `${UPLOAD_DOCUMENT} SUCCESS`;
const UPLOAD_DOCUMENT_FAIL = `${UPLOAD_DOCUMENT} FAIL`;

const GET_UPLOADED_DOCUMENT = '[Document Extractor] Get Uploaded Document';
const GET_UPLOADED_DOCUMENT_SUCCESS = `${GET_UPLOADED_DOCUMENT} SUCCESS`;
const GET_UPLOADED_DOCUMENT_FAIL = `${GET_UPLOADED_DOCUMENT} FAIL`;

const SET_FORM_PROGRESS = '[Document Extractor] Set Form Progress';

const SET_FORM_IS_SAVING_OFF = `[Document Extractor] Set Form Is Saving Off`;
const SET_FORM_IS_SAVING_ON = `[Document Extractor] Set Form Is Saving On`;

const SET_FORM_STATUS = `[Document Extractor] Set Form Status`;

const TOGGLE_SIDENAV = `[Document Extractor] Toggle Sidenav`;
const TOGGLE_SELECTION_SIDEBAR = `[Document Extractor] Toggle Selection Sidebar`;

const ACCEPT_LOW_CONFIDENCE = `[Document Extractor] Accept Low Confidence`;
const ACCEPT_LOW_CONFIDENCE_SUCCESS = `${ACCEPT_LOW_CONFIDENCE} Success`;

const SET_SEEN_FIELDS = `[Document Extractor] Set Seen Fields`;

const RESET = `[Document Extractor] Reset`;

export const DocumentExtractorEffect = {
  GET_UPLOADED_DOCUMENT,
  UPLOAD_DOCUMENT,
  ACCEPT_LOW_CONFIDENCE,
  ACCEPT_LOW_CONFIDENCE_SUCCESS,
  SET_SEEN_FIELDS,
};

export const DocumentExtractorAction = {
  uploadDocument: createAction(UPLOAD_DOCUMENT, props<{ params: { uploadData: FormData } }>()),
  uploadDocumentSuccess: createAction(UPLOAD_DOCUMENT_SUCCESS, props<{ params: UploadedDocumentInstance }>()),
  uploadDocumentFail: createAction(UPLOAD_DOCUMENT_FAIL, props<{ params: { error: any } }>()),

  getUploadedDocument: createAction(GET_UPLOADED_DOCUMENT, props<{ params: { docId: number } }>()),
  getUploadedDocumentSuccess: createAction(
    GET_UPLOADED_DOCUMENT_SUCCESS,
    props<{ params: UploadedDocumentInstance }>(),
  ),
  getUploadedDocumentFail: createAction(GET_UPLOADED_DOCUMENT_FAIL, props<{ params: { error: any } }>()),

  setFormProgress: createAction(SET_FORM_PROGRESS, props<{ params: { formProgress: number } }>()),

  toggleSidenav: createAction(TOGGLE_SIDENAV),
  toggleSelectionSidebar: createAction(TOGGLE_SELECTION_SIDEBAR),

  setFormIsSavingOff: createAction(SET_FORM_IS_SAVING_OFF),
  setFormIsSavingOn: createAction(SET_FORM_IS_SAVING_ON),

  setFormStatus: createAction(
    SET_FORM_STATUS,
    props<{
      params: {
        formProgress: DocumentExtractorState['formProgress'];
      };
    }>(),
  ),

  acceptLowConfidence: createAction(ACCEPT_LOW_CONFIDENCE, props<{ params: { id: number; formData: FormData } }>()),
  acceptLowConfidenceSuccess: createAction(ACCEPT_LOW_CONFIDENCE_SUCCESS),

  setSeenFields: createAction(SET_SEEN_FIELDS, props<{ params: { seenFields: string[] } }>()),

  reset: createAction(RESET),
};
