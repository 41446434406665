@if (title || (searchTerm && 'No results found for'); as title) {
  <span>
    {{ title }}
    @if (searchTerm) {
      <span>{{ searchTerm }}</span>
    }
  </span>
}
@if (message || (searchTerm && 'Please try searching with another term.'); as message) {
  <span>{{ message }}</span>
}
