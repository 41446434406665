import { animate, state, style, transition, trigger } from '@angular/animations';
import { sidebarWidthMini, sidebarWidthOpen, toggleSidebarAnimationTransition } from './sidenav.constants';

const sidebar = {
  open: {
    width: sidebarWidthOpen,
    widthPx: `${sidebarWidthOpen}px`,
  },
  mini: {
    width: sidebarWidthMini,
    widthPx: `${sidebarWidthMini}px`,
  },
  timings: toggleSidebarAnimationTransition,
};

export const toggleSidebarAnimation = trigger('sidenavAnimation', [
  state(
    'mini',
    style({
      width: sidebar.mini.widthPx,
    }),
  ),
  state(
    'open',
    style({
      width: sidebar.open.widthPx,
    }),
  ),
  transition('mini => open', animate(sidebar.timings)),
  transition('open => mini', animate(sidebar.timings)),
]);

export const toggleSidebarContentAnimation = trigger('sidenavContentAnimation', [
  state(
    'mini',
    style({
      'margin-left': sidebar.mini.widthPx,
    }),
  ),
  state(
    'open',
    style({
      'margin-left': sidebar.open.widthPx,
    }),
  ),
  transition('mini => open', animate(sidebar.timings)),
  transition('open => mini', animate(sidebar.timings)),
]);
