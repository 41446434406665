import { Component, ViewEncapsulation } from '@angular/core';
import { IoService } from '../../services/io.service';

@Component({
  standalone: false,
  selector: 'om-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactComponent {
  constructor(public ioService: IoService) {}
}
