import { Editor } from 'slate';
import { ElementType } from '../../constants/rich-text.constants';

export const withMentions = (editor: Editor) => {
  const { isInline, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === ElementType.Mention || isVoid(element);
  };

  editor.isInline = element => {
    return element.type === ElementType.Mention || isInline(element);
  };

  return editor;
};
