import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, max?: number): string {
    if (max && value.length > max) {
      return `${value.slice(0, max).trim()}...`;
    }
    return value;
  }
}
