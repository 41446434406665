import { QuoteType } from '@morpho/core';
import { ToolPanelDef } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { FilterSidebarItemConfig } from './models/ag-grid-panel.model';

export enum AgGridPanel {
  ColumnSideBar = 'column',
  CustomCurvesSideBar = 'customCurves',
  FilterSideBar = 'filter',
  GroupSideBar = 'group',
  HistoricalCurvesSideBar = 'historicalCurves',
  MaturitiesSideBar = 'maturities',
  PricingBreakdownSideBar = 'pricingBreakdown',
  SwapsSideBar = 'swaps',
  None = '',
}

export interface BaseSidebarConfig extends ToolPanelDef {
  id: AgGridPanel;
  inGroupMenu?: boolean;
}
export interface ColumnSidebarConfig extends BaseSidebarConfig {
  quoteType$?: Observable<QuoteType>;
}
export interface FilterSidebarConfig extends BaseSidebarConfig {
  model$: Observable<Record<string, any>>;
  columns$: Observable<FilterSidebarItemConfig[]> | Subject<FilterSidebarItemConfig[]>;
}
export interface GroupSidebarConfig extends BaseSidebarConfig {
  columnIds: string[];
}
