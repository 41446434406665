import { Pipe, PipeTransform } from '@angular/core';
import { BaseSidebarConfig } from '@morpho/ag-grid-panel';
import { AgGridAngular } from 'ag-grid-angular';
import { SideBarDef, ToolPanelDef } from 'ag-grid-community';

@Pipe({
  standalone: false,
  name: 'getSidepanels',
})
export class GetSidepanelsPipe implements PipeTransform {
  transform(agGrid: AgGridAngular, inGroupMenu: boolean): ToolPanelDef[] {
    const panels =
      (agGrid?.sideBar as SideBarDef)?.toolPanels ?? (agGrid?.gridOptions?.sideBar as SideBarDef)?.toolPanels ?? [];
    return panels.filter((panel: BaseSidebarConfig) => (panel.inGroupMenu ?? false) === inGroupMenu) as ToolPanelDef[];
  }
}
