<mat-toolbar>
  <section>
    <h2>{{ tableName }}</h2>
    @if (quoteType) {
      <mat-form-field class="om-reset is-inline">
        <om-button-toggle-input
          class="variant-2"
          [formControl]="quoteTypeFormControl"
          [options]="quoteTypeOptions"
        ></om-button-toggle-input>
      </mat-form-field>
    }
  </section>
  <section right>
    @if (filters?.length) {
      <div><span>Filtered by </span>{{ filters | arrayToString }}</div>
    }
    @for (panel of agGrid | getSidepanels: false; track panel) {
      <button
        mat-button
        class="is-chip"
        [ngClass]="{
          'is-active': panel.labelKey === activePanelKey,
          'om-filter-button': panel.labelKey === AgGridPanel.FilterSideBar
        }"
        (click)="toggleSidePanel(panel.labelKey)"
      >
        {{ panel.labelDefault }}
        @if (filters?.length && panel.labelKey === 'filter') {
          <span>{{ filters.length }}</span>
        }
      </button>
    }
    @if (agGrid | getSidepanels: true; as toolPanels) {
      @if (toolPanels.length) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="more_vertical_strong"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @for (panel of toolPanels; track panel) {
            <button
              mat-menu-item
              [ngClass]="{
                'is-active': panel.labelKey === activePanelKey,
                'om-filter-button': panel.labelKey === AgGridPanel.FilterSideBar
              }"
              (click)="toggleSidePanel(panel.labelKey)"
            >
              <mat-icon svgIcon="{{ panel.iconKey }}"></mat-icon>
              <span>{{ panel.labelDefault }}</span>
            </button>
          }
        </mat-menu>
      }
    }

    <div class="om-vertical-line"></div>

    <button
      mat-icon-button
      class="is-chip"
      [omTooltip]="(isGraphVisible$ | async) ? 'Hide graph' : 'Show graph'"
      (click)="toggleGraph()"
    >
      <mat-icon svgIcon="chart_line"></mat-icon>
    </button>
  </section>
</mat-toolbar>
