import { createAction, props } from '@ngrx/store';

const GET_CONSTANTS = '[Constants] Get Constants';
const GET_CONSTANTS_SUCCESS = `${GET_CONSTANTS} SUCCESS`;
const GET_CONSTANTS_FAIL = `${GET_CONSTANTS} FAIL`;

export const ConstantsEffect = {
  GET_CONSTANTS,
};

export const ConstantsAction = {
  getConstants: createAction(GET_CONSTANTS),
  getConstantsSuccess: createAction(GET_CONSTANTS_SUCCESS, props<{ params: any }>()),
  getConstantsFail: createAction(GET_CONSTANTS_FAIL),
};
