import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomRouterState } from './router.model';

export const ROUTER_STATE_KEY = 'router';

const routerState = createFeatureSelector<{ navigationId: number; state: CustomRouterState }>(ROUTER_STATE_KEY);

export const RouterSelector = {
  state: createSelector(routerState, state => state?.state),
  urlParams: createSelector(routerState, state => state?.state.urlParams),
  queryParams: createSelector(routerState, state => state?.state.queryParams),
  referrer: createSelector(routerState, state => state?.state.queryParams?.referrer),
  path: createSelector(routerState, state => state?.state.path),
};
