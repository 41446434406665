import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import {
  ActionMenuConfig,
  ActionMenuEvent,
  DATETIME_FORMAT,
  DATE_FORMAT,
  DATE_NO_YEAR_FORMAT,
  OptionIconTag,
  UtilService,
  ValueAndLabel,
} from '@morpho/core';
import { FormConfig, FormInputConfig, PrimitiveInputConfig, TextareaInputConfig } from '@morpho/form';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Props } from 'tippy.js';
import { DynamicFormInputDirectiveParams } from '../../directives/dynamic-form-input.directive';
import { DynamicFormModel } from '../../models/dynamic-form.model';
import { DynamicFormService } from '../../services/dynamic-form.service';

interface FieldTagConfig {
  type: 'instant-isin' | 'final-terms' | 'custom-termsheet';
  icon: string;
  text: string;
}

@Component({
  standalone: false,
  selector: 'om-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormFieldComponent implements OnInit, OnDestroy {
  private readonly maxLongLabel = 30;
  private ngOnDestroy$ = new Subject<void>();

  readonly lowConfidenceTooltip = 'Extracted with low confidence, click to confirm';
  readonly lowConfidenceTooltipProperties: Partial<Props> = {
    theme: 'light-border tooltip-bigger-width',
  };

  @Input() model: DynamicFormModel;

  @Input() form: FormGroup;
  @Input() formConfig: FormConfig;
  @Input() fieldConfig: FormInputConfig | any;

  @Output() inputChange: EventEmitter<any> = new EventEmitter();
  private userInput$ = new Subject<void>();

  @Input() prefixActions: ActionMenuConfig;
  @Input() suffixActions: ActionMenuConfig;
  @Input() editActions: ActionMenuConfig;
  @Input() findActions: ActionMenuConfig;
  @Input() hasDeleteAction = false;
  @Input() isLowConfidenceChecked: boolean;
  @Input() hideContent: boolean;

  @Output() actionSelected: EventEmitter<ActionMenuEvent> = new EventEmitter();
  @Output() lowConfidenceChecked: EventEmitter<any> = new EventEmitter();

  @HostBinding('class') cssClass: string;

  inputDirectiveParams: DynamicFormInputDirectiveParams;
  errorMessage: string | undefined;
  fieldWarningMessage: string;
  fieldInstantIsin: boolean;
  fieldFinalTerms: boolean;
  fieldBothTags: boolean;
  tooltip: string;
  tagsConfig: FieldTagConfig[];

  @ViewChild('formField') formField: MatFormField;

  get isErrorState() {
    return this.errorMessage && this.form.controls[this.fieldConfig.name].touched;
  }

  constructor(
    private dynamicFormService: DynamicFormService,
    private utilService: UtilService,
  ) {}

  @HostListener('focusout') onFocusout() {
    this.form.controls[this.fieldConfig.name].markAsTouched();
  }

  ngOnInit() {
    this.form
      .get(this.fieldConfig.name)
      ?.valueChanges.pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        debounceTime(500),
        tap(value => {
          this.setFieldWarningText();
          this.initializeFieldTags();
          this.inputChange.emit({ name: this.fieldConfig.name, value, hasDeleteAction: this.hasDeleteAction });
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe(() => {});

    this.inputDirectiveParams = {
      fieldConfig: this.fieldConfig,
    };

    this.setErrorMessage();
    this.setFieldWarningText();
    this.initializeFieldTags();

    this.form
      .get(this.fieldConfig.name)
      ?.statusChanges.pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(status => {
        this.setFieldWarningText();
        this.setErrorMessage();
        this.initializeFieldTags();
      });

    if (this.fieldConfig.label?.length > this.maxLongLabel) {
      this.tooltip = `<b>${this.fieldConfig.label}</b><br>`;
    }
    if (this.fieldConfig.help) {
      this.tooltip = (this.tooltip ?? '') + this.fieldConfig.help;
    }
    if (this.fieldConfig.templates?.length) {
      const templatesToolTipBuilder: string[] = [];
      const templatesDocuments: string[] = this.fieldConfig.templates;
      if (this.tooltip) {
        templatesToolTipBuilder.push(this.tooltip, '<hr>');
      }
      templatesToolTipBuilder.push('<b>Used in:</b> ');
      if (templatesDocuments.length > 1) {
        templatesToolTipBuilder.push(
          templatesDocuments.slice(0, -1).join(', ') + ` & ${templatesDocuments[templatesDocuments.length - 1]}`,
        );
      } else {
        templatesToolTipBuilder.push(templatesDocuments[0]);
      }
      this.tooltip = templatesToolTipBuilder.join('');
    }
  }

  initializeFieldTags() {
    const value = this.form.get(this.fieldConfig.name)?.value;
    const option = this.fieldConfig?.options?.find((option: ValueAndLabel) => option.value === value);
    const tags = option?.tags ?? [];
    this.tagsConfig = this.setTagConfig(tags);
  }

  setFieldWarningText() {
    if (!(this.formConfig?.isFlexibleEdit || this.formConfig?.isPartialSaveAllowed)) {
      this.fieldWarningMessage = '';
      return;
    }

    const isContentTag = this.dynamicFormService.isContentTag(this.fieldConfig.name);

    if (!this.fieldConfig.required && !isContentTag) {
      this.fieldWarningMessage = '';
      return;
    }

    const value = this.form.get(this.fieldConfig.name)?.value;

    if (isContentTag && !value) {
      this.fieldWarningMessage =
        'Please take note that deleting the text will also delete it from the document upon saving the edits.';
      return;
    }

    if (!this.utilService.formInputHasValue(value)) {
      const savedValue = this.model.savedFormValue[this.fieldConfig.name];
      if (this.utilService.formInputHasValue(savedValue)) {
        this.fieldWarningMessage = 'Input data cannot be deleted. Try changing it instead';
      } else {
        this.fieldWarningMessage = 'Information missing';
      }
      return;
    }

    if (Array.isArray(value) && value.filter(val => !this.utilService.formInputHasValue(val)).length) {
      this.fieldWarningMessage = 'Information missing';
      return;
    }

    this.fieldWarningMessage = '';
  }

  private setTagConfig(tags: OptionIconTag[]): FieldTagConfig[] {
    const tagConfigs: Record<string, FieldTagConfig> = {
      custom_termsheet: {
        type: 'custom-termsheet',
        icon: 'assets/img/new_releases.svg',
        text: 'Custom TS',
      },
      instant_isin: {
        type: 'instant-isin',
        icon: 'assets/img/lightning.svg',
        text: 'Instant ISIN',
      },
      final_terms: {
        type: 'final-terms',
        icon: 'assets/img/task.svg',
        text: 'Final Terms',
      },
    };

    return Object.entries(tagConfigs)
      .filter(([key]) => tags.some(tag => tag?.icon?.includes(key)))
      .map(([, value]) => value);
  }

  private setErrorMessage() {
    const errors = this.form.get(this.fieldConfig.name)?.errors;
    this.errorMessage = this.getErrorMessage(errors, this.fieldConfig);
  }

  // todo move to service, possible incorporate message in the validation error, so can return error.message
  private getErrorMessage(errors: ValidationErrors | null | undefined, fieldConfig: FormInputConfig): string {
    if (!errors) {
      return '';
    }

    const defaultErrorMessage = 'This field is not valid.';

    if (errors.pattern) {
      return fieldConfig.regex_message || defaultErrorMessage;
    }

    if (errors.min || errors.max) {
      switch (fieldConfig.element) {
        case 'primitive':
          if (fieldConfig.type !== 'number') {
            break;
          }
          const hasMin = fieldConfig.min || fieldConfig.min === 0;
          const hasMax = fieldConfig.max || fieldConfig.max === 0;

          if (hasMin) {
            if (hasMax) {
              return `Ensure value is between ${fieldConfig.min} and ${fieldConfig.max}.`;
            }
            return `Ensure value is not less than ${fieldConfig.min}.`;
          }
          return `Ensure value is not greater than ${fieldConfig.max}.`;

        case 'date':
        case 'datetime':
          let dateFormat;
          let type;
          if (fieldConfig.element === 'datetime') {
            dateFormat = DATETIME_FORMAT;
            type = 'date & time';
          } else {
            dateFormat = fieldConfig.hideYear ? DATE_NO_YEAR_FORMAT : DATE_FORMAT;
            type = 'date';
          }
          const minDate = fieldConfig.min ? moment(fieldConfig.min).format(dateFormat) : null;
          const maxDate = fieldConfig.max ? moment(fieldConfig.max).format(dateFormat) : null;

          if (minDate) {
            if (maxDate) {
              return `Enter a ${type} between ${minDate} and ${maxDate}.`;
            }
            return `Ensure ${type} is not before ${minDate}.`;
          }
          return `Ensure ${type} is not after ${maxDate}.`;
      }
    }

    if (errors.email) {
      return 'Enter a valid email address.';
    }

    if (errors.maxlength) {
      const maxlength = (fieldConfig as PrimitiveInputConfig | TextareaInputConfig).maxlength;
      const length = this.form.get(fieldConfig.name)?.value.length;
      return `Enter no more than ${maxlength} characters.` + (length ? ` Currently ${length} characters.` : '');
    }

    // validation todo, create validators for above + validators and errors for:
    // file size
    // daterange?
    // todo backend api validation, possibly overwrite backend messages

    // todo filesize

    if (errors.customMessage) {
      return errors.customMessage;
    }

    if (errors.required) {
      return 'This field is required.';
    }

    if (errors.serverError) {
      let flattenedErrors;
      if (Array.isArray(errors.serverError)) {
        flattenedErrors = errors.serverError;
      } else if (Object.keys(errors.serverError).length) {
        flattenedErrors = Object.values(errors.serverError).flat();
      }

      if (flattenedErrors) {
        return flattenedErrors.length ? flattenedErrors.join('. ') : defaultErrorMessage;
      }
      return errors.serverError;
    }

    // return the first error message, if one exists
    for (const error of Object.values(errors)) {
      if (error.hasOwnProperty('message')) {
        return error.message;
      }
    }
    return defaultErrorMessage;
  }

  onActionSelected(event: ActionMenuEvent) {
    this.actionSelected.emit(event);
  }

  onMenuOpened() {
    this.formField._elementRef.nativeElement.classList.add('action-menu-opened');
  }

  onMenuClosed() {
    this.formField._elementRef.nativeElement.classList.remove('action-menu-opened');
  }

  onConfirmLowConfidence() {
    this.lowConfidenceChecked.emit({
      field_name: this.fieldConfig.name,
      data: this.form.get(this.fieldConfig.name)?.value,
    });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
    this.userInput$.next();
    this.userInput$.complete();
  }
}
