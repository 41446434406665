import { BasisType, ValueAndLabel, patchObjectData } from '@morpho/core';
import { Action, createReducer, on } from '@ngrx/store';
import { SecondaryPlotMode } from '@shared-echarts/models';
import { sortObjectArrayAlphabetically } from '../../../../constants/sortComparators';
import { PricingInboxGroup } from '../../models/pricing-inbox.model';
import {
  AdditionalCurve,
  CurveLevel,
  GLOBAL_FIELD,
  HISTORICAL_PRICING,
  NewIssueCurveType,
  PRICING,
} from '../../models/pricing-request.model';
import {
  generateCurveID,
  getCurveTypeFromCurveID,
  getHistoricalDateFromCurveID,
  getIssuingEntityFromCurveID,
  getPricingBasisForTab,
  getValueFromCurveID,
} from '../../syndicate-pricer.functions';
import { PricingCompletionAction } from './pricing-completion.actions';
import {
  IssuerPricingDataMap,
  Maturities,
  PricingCompletionPageSettings,
  PricingCompletionState,
  PricingTabDataMap,
  SharedTabData,
} from './pricing-completion.model';

export const PRICING_COMPLETION_STATE_KEY = 'pricingCompletion';
const PRICING_PATH = 'pricingRequest.pricing';

const setPricingTabDataMap = (pricingTabs: ValueAndLabel[], pricingTabDataMap: PricingTabDataMap) =>
  Object.entries(pricingTabDataMap).reduce(
    (acc: PricingTabDataMap, [tabName, issuerPricingDataMap]: [string, IssuerPricingDataMap]) => {
      if (pricingTabs.find(tab => tab.value === tabName)) {
        acc[tabName] = issuerPricingDataMap;
      }
      return acc;
    },
    {},
  );

const removeHistoricalDataFromSharedTabData = (
  dates: string[],
  sharedTabData: SharedTabData,
  issuingEntityIds?: string[],
) => {
  Object.keys(sharedTabData.pricedKeys).forEach(rowId => {
    if (rowId.includes(HISTORICAL_PRICING)) {
      if (
        dates.includes(getHistoricalDateFromCurveID(rowId)) &&
        (!issuingEntityIds || issuingEntityIds.includes(getIssuingEntityFromCurveID(rowId)))
      ) {
        delete sharedTabData.pricedKeys[rowId];
      }
    }
  });
  const historicalDataByIssuer = sharedTabData.historicalPricingRequestsData;
  if (historicalDataByIssuer) {
    (issuingEntityIds || Object.keys(historicalDataByIssuer)).forEach(issuingEntitiesId => {
      Object.keys(historicalDataByIssuer[issuingEntitiesId]).forEach(date => {
        if (!dates.includes(date)) {
          delete historicalDataByIssuer[issuingEntitiesId][date];
        }
      });
    });
  }
};

export const initialPageSettings: PricingCompletionPageSettings = {
  pricingGraph: {
    secondaryPlotMode: SecondaryPlotMode.Both,
  },
};

const initialState: PricingCompletionState = {
  pageSettings: initialPageSettings,
};

function patchTabData(params: {
  state: PricingCompletionState;
  tabName: string;
  path?: string;
  data?: any;
  issuingEntity?: string | number;
}): PricingCompletionState {
  let issuerPricingDataMap = params.state?.pricingTabDataMap?.[params.tabName];
  if (!issuerPricingDataMap) {
    return params.state;
  }
  issuerPricingDataMap = JSON.parse(JSON.stringify(issuerPricingDataMap)) as IssuerPricingDataMap;

  const updatedIssuerPricingDataMap = [
    ...(params.issuingEntity ? [params.issuingEntity] : Object.keys(issuerPricingDataMap)),
  ].reduce((issuerPricingDataMap, issuingEntityId) => {
    const entityDataPath = params.path ? `${issuingEntityId}.${params.path}` : String(issuingEntityId);
    return patchObjectData(issuerPricingDataMap, entityDataPath, params.data);
  }, issuerPricingDataMap);
  return patchObjectData(params.state, `pricingTabDataMap`, {
    [params.tabName]: updatedIssuerPricingDataMap,
  }) as PricingCompletionState;
}

function patchSharedTabData(params: {
  state: PricingCompletionState;
  tabName: string;
  path?: string;
  data?: any;
}): PricingCompletionState {
  const patchedState = JSON.parse(JSON.stringify(params.state));

  if (!patchedState.sharedTabDataMap?.[params.tabName]) {
    return patchedState;
  }

  const tabPath = `sharedTabDataMap.${params.tabName}`;
  const dataPath = params.path ? `${tabPath}.${params.path}` : tabPath;

  return patchObjectData(patchedState, dataPath, params.data) as PricingCompletionState;
}

const reducer = createReducer(
  initialState,

  on(
    PricingCompletionAction.addProcessToLoadingStack,
    (state: PricingCompletionState, action): PricingCompletionState => {
      return {
        ...state,
        loadingProcesses: state.loadingProcesses ? state.loadingProcesses + 1 : 1,
      };
    },
  ),

  on(
    PricingCompletionAction.removeProcessFromLoadingStack,
    (state: PricingCompletionState, action): PricingCompletionState => {
      return {
        ...state,
        loadingProcesses: state.loadingProcesses && state.loadingProcesses > 0 ? state.loadingProcesses - 1 : 0,
      };
    },
  ),

  on(PricingCompletionAction.updateSavingStatus, (state: PricingCompletionState, action): PricingCompletionState => {
    return {
      ...state,
      ...action.params,
    };
  }),

  on(PricingCompletionAction.savePricingSuccess, (state: PricingCompletionState, action): PricingCompletionState => {
    const updatedPricingTabDataMap: PricingTabDataMap = JSON.parse(JSON.stringify(state.pricingTabDataMap ?? {}));
    if (!updatedPricingTabDataMap) {
      return state;
    }

    Object.entries(action.params.savedPricingRequestsMap).forEach(([tabName, pricingRequests]) => {
      pricingRequests.forEach(pricingRequest => {
        const issuerPricingData = updatedPricingTabDataMap[tabName][pricingRequest.issuing_entity.id];
        issuerPricingData.savedPricingRequest = pricingRequest;
      });
    });

    return {
      ...state,
      pricingTabDataMap: updatedPricingTabDataMap,
    };
  }),

  on(PricingCompletionAction.setSelectedTab, (state: PricingCompletionState, action): PricingCompletionState => {
    return {
      ...state,
      selectedTab: action.params,
    };
  }),

  on(PricingCompletionAction.addPricingTabs, (state: PricingCompletionState, action): PricingCompletionState => {
    return {
      ...state,
      pricingTabs: sortObjectArrayAlphabetically([...(state.pricingTabs ?? []), ...action.params.pricingTabs], 'value'),
      pricingTabDataMap: setPricingTabDataMap(state.pricingTabs ?? [], state.pricingTabDataMap ?? {}),
    };
  }),

  on(
    PricingCompletionAction.removePricingRequestsSuccess,
    (state: PricingCompletionState, action): PricingCompletionState => {
      const updatedState: PricingCompletionState = JSON.parse(JSON.stringify(state));

      updatedState.pricingInboxGroups?.forEach((group: PricingInboxGroup) => {
        group.issuing_entities.forEach(entity => {
          entity.pricing_requests = entity.pricing_requests.filter(
            request => !action.params.requestIds.includes(request.id as number),
          );
          return entity;
        });
        return group;
      });

      const tabName = action.params.tabName;
      if (tabName) {
        if (updatedState.pricingTabDataMap) {
          delete updatedState.pricingTabDataMap[tabName];
        }
        if (updatedState.sharedTabDataMap) {
          delete updatedState.sharedTabDataMap[tabName];
        }
      }

      return {
        ...updatedState,
      };
    },
  ),

  on(PricingCompletionAction.updatePricingTabData, (state: PricingCompletionState, action): PricingCompletionState => {
    const mergedTabDataMap = JSON.parse(JSON.stringify(state.pricingTabDataMap ?? {}));
    const mergedSharedDataMap = JSON.parse(JSON.stringify(state.sharedTabDataMap ?? {}));

    const mergeMap = (newMap: Record<string, any>, originalMap: Record<string, any>) => {
      Object.entries(newMap ?? {}).forEach(([key, value]) => {
        const valueCopy = JSON.parse(JSON.stringify(value));
        if (valueCopy.secondaryBondsFilter && !Object.keys(valueCopy.secondaryBondsFilter).length) {
          delete valueCopy.secondaryBondsFilter;
        }
        originalMap[key] = {
          ...originalMap[key],
          ...valueCopy,
        };
      });
    };

    mergeMap(action.params.pricingTabDataMap ?? {}, mergedTabDataMap);
    mergeMap(action.params.sharedTabDataMap ?? {}, mergedSharedDataMap);

    return {
      ...state,
      sharedTabDataMap: {
        ...(state.sharedTabDataMap ?? {}),
        ...action.params.sharedTabDataMap,
      },
      pricingTabDataMap: {
        ...(state.pricingTabDataMap ?? {}),
        ...mergedTabDataMap,
      },
    };
  }),

  on(PricingCompletionAction.toggleGraphVisibility, (state: PricingCompletionState): PricingCompletionState => {
    return {
      ...state,
      isGraphVisible: !state.isGraphVisible,
    };
  }),

  on(PricingCompletionAction.setPageSettings, (state: PricingCompletionState, action): PricingCompletionState => {
    return {
      ...state,
      savedViewId: action.params.id ?? state.savedViewId,
      pageSettings: action.params.pageSettings,
    };
  }),

  on(
    PricingCompletionAction.savePageSettingsSuccess,
    (state: PricingCompletionState, action): PricingCompletionState => {
      return {
        ...state,
        savedViewId: action.params.savedViewId,
      };
    },
  ),

  on(PricingCompletionAction.setIssuingEntities, (state: PricingCompletionState, action): PricingCompletionState => {
    return {
      ...state,
      issuingEntities: action.params.issuingEntities,
    };
  }),

  /***********************************************************************************************
   *                                       PRICING INBOX                                         *
   ***********************************************************************************************/

  on(PricingCompletionAction.getPricingInboxGroupsSuccess, (state, action): PricingCompletionState => {
    return {
      ...state,
      pricingInboxGroups: action.params.pricingInboxGroups,
    };
  }),

  /***********************************************************************************************
   *                                      PRICING PAGE                                           *
   ***********************************************************************************************/

  on(PricingCompletionAction.setPricingBasisForTabSuccess, (state, action): PricingCompletionState => {
    const tabName = action.params.tab;
    let patchedState: PricingCompletionState = JSON.parse(JSON.stringify(state));
    const issuerPricingDataMap = patchedState.pricingTabDataMap?.[tabName];
    if (!issuerPricingDataMap) {
      return state;
    }
    const oldPricingBasis = getPricingBasisForTab(issuerPricingDataMap);

    Object.keys(issuerPricingDataMap).forEach(issuingEntityId => {
      const updatedAdditionalCurves = (
        issuerPricingDataMap[issuingEntityId].pricingRequest.pricing.additional_curves ?? []
      ).filter(curve => {
        const isNotBenchmarkType =
          curve.type !== NewIssueCurveType.BenchmarkInput && curve.type !== NewIssueCurveType.BenchmarkYield;

        const isNotYieldOrReferenceCurveWithOldPricingBasis = !(
          (curve.type === NewIssueCurveType.Yield || curve.type === NewIssueCurveType.ReferenceCurve) &&
          curve.value === oldPricingBasis
        );

        return isNotBenchmarkType && isNotYieldOrReferenceCurveWithOldPricingBasis;
      });

      if (action.params.basisType === BasisType.Govie) {
        updatedAdditionalCurves.push({
          type: NewIssueCurveType.BenchmarkInput,
          value: action.params.pricingBasis,
          data: action.params.initialBenchmarkSelection ?? {},
        });
        updatedAdditionalCurves.push({
          type: NewIssueCurveType.BenchmarkYield,
          value: action.params.pricingBasis,
          data: action.params.benchmarkYields ?? {},
        });
      }

      patchedState = patchTabData({
        state: patchedState,
        tabName,
        path: `${PRICING_PATH}.additional_curves`,
        issuingEntity: issuingEntityId,
        data: updatedAdditionalCurves,
      });
    });

    const sharedTabData = state.sharedTabDataMap?.[action.params.tab];
    if (sharedTabData) {
      patchedState = patchSharedTabData({
        state: patchedState,
        tabName: action.params.tab,
        data: {
          pricedKeys: {},
        },
      });
    }

    return patchTabData({
      state: patchedState,
      tabName,
      path: `${PRICING_PATH}.funding_basis`,
      data: action.params.pricingBasis,
    });
  }),

  on(PricingCompletionAction.setOverviewSwapBasis, (state, action): PricingCompletionState => {
    return patchObjectData(state, 'summaryTabSwapBasis', action.params.swapBasis) as PricingCompletionState;
  }),

  on(
    PricingCompletionAction.updatePricingDataForTab,
    (state: PricingCompletionState, action): PricingCompletionState => {
      const rowType = action.params.data.curveType;
      const tabName = action.params.tab;
      const issuingEntityId = action.params.data.issuingEntityId;
      const stateCopy: PricingCompletionState = JSON.parse(JSON.stringify(state));
      if (
        [NewIssueCurveType.Spread, NewIssueCurveType.NewIssuePremium, NewIssueCurveType.Total].includes(rowType) &&
        issuingEntityId
      ) {
        const updatedPricingLevels = stateCopy.pricingTabDataMap?.[tabName]?.[
          issuingEntityId
        ].pricingRequest.pricing.levels?.reduce((updateLevels: CurveLevel[], level) => {
          if (level.key === rowType) {
            const newData: Maturities = {};
            Object.keys(level.data).forEach(maturity => {
              newData[maturity] = action.params.data.maturities?.[maturity] ?? '';
            });
            updateLevels.push({
              key: rowType,
              data: newData,
            });
          } else {
            updateLevels.push(level);
          }
          return updateLevels;
        }, []);
        return patchTabData({
          state,
          tabName,
          path: `${PRICING_PATH}.levels`,
          issuingEntity: issuingEntityId,
          data: updatedPricingLevels,
        });
      }
      return state;
    },
  ),

  on(PricingCompletionAction.setQuoteType, (state, action): PricingCompletionState => {
    return patchTabData({
      state,
      tabName: action.params.tabName,
      path: `${PRICING_PATH}.quote_type`,
      data: action.params.quoteType,
    });
  }),

  on(PricingCompletionAction.setPricingBreakdownSuccess, (state, action) => {
    const stateCopy = patchTabData({
      state,
      tabName: action.params.tabName,
      path: `${PRICING_PATH}.breakdown`,
      data: action.params.pricingBreakdown,
    });

    const pricingMap: Record<string, Maturities> = JSON.parse(
      JSON.stringify(state.sharedTabDataMap?.[action.params.tabName].tempPricingMap ?? {}),
    );

    const issuerPricingDataMap = stateCopy.pricingTabDataMap?.[action.params.tabName];
    if (!issuerPricingDataMap) {
      return stateCopy;
    }
    const updatedIssuerPricingDataMap = Object.keys(issuerPricingDataMap).reduce((acc, issuingEntityId) => {
      const newLevels: CurveLevel[] = JSON.parse(JSON.stringify(action.params.levels));
      const currentLevels = issuerPricingDataMap[issuingEntityId].pricingRequest.pricing.levels;

      newLevels.forEach(level => {
        const currentLevelData = currentLevels.find(currentLevel => currentLevel.key === level.key)?.data;
        if (currentLevelData) {
          level.data = currentLevelData;
        }
      });
      if (
        !Object.values(newLevels.find(level => level.key === NewIssueCurveType.Total)?.data ?? {}).find(
          value => !!value,
        )
      ) {
        Object.keys(pricingMap).forEach(rowId => {
          if (
            rowId.includes(issuingEntityId) &&
            (rowId.includes(NewIssueCurveType.Swap) || rowId.includes(`${PRICING}/${NewIssueCurveType.Yield}`))
          ) {
            delete pricingMap[rowId];
          }
        });
      }
      return patchObjectData(acc, `${issuingEntityId}.${PRICING_PATH}.levels`, newLevels);
    }, issuerPricingDataMap);

    patchObjectData(
      stateCopy,
      `pricingTabDataMap.${action.params.tabName}`,
      updatedIssuerPricingDataMap,
    ) as PricingCompletionState;

    return patchSharedTabData({
      state: stateCopy,
      tabName: action.params.tabName,
      data: {
        tempPricingMap: pricingMap,
      },
    });
  }),

  on(PricingCompletionAction.setShowLatestHistorical, (state, action) => {
    return patchTabData({
      state,
      tabName: action.params.tab,
      path: `${PRICING_PATH}.show_latest_historical`,
      data: action.params.showLatestHistorical,
    });
  }),

  on(PricingCompletionAction.setShowSwapReferenceCurves, (state, action) => {
    return patchTabData({
      state,
      tabName: action.params.tabName,
      path: `${PRICING_PATH}.show_references`,
      data: action.params.showSwapReferenceCurves,
    });
  }),

  on(PricingCompletionAction.setComparables, (state, action): PricingCompletionState => {
    if (!state.selectedTab) {
      return state;
    }

    let updatedState = state;
    Object.keys(state.pricingTabDataMap ?? {}).forEach(tabName => {
      updatedState = patchTabData({
        state: updatedState,
        tabName,
        path: 'pricingRequest',
        data: action.params,
      });
    });

    return updatedState;
  }),

  on(PricingCompletionAction.setCombinedFilters, (state, action): PricingCompletionState => {
    return patchSharedTabData({
      state,
      tabName: action.params.tabName,
      data: { combinedFilters: action.params.filters },
    });
  }),

  on(PricingCompletionAction.setSecondaryBondsFilter, (state, action): PricingCompletionState => {
    const patchedState = patchSharedTabData({
      state: state,
      tabName: action.params.tabName,
      data: { combinedFilters: action.params.filter },
    });

    return patchTabData({
      state: patchedState,
      tabName: action.params.tabName,
      path: 'pricingRequest',
      data: { filters: action.params.filter },
    });
  }),

  on(PricingCompletionAction.getSecondaryBondsSuccess, (state, action): PricingCompletionState => {
    return patchSharedTabData({
      state,
      tabName: action.params.tabName,
      path: 'secondaryBonds',
      data: action.params.bonds,
    });
  }),

  on(PricingCompletionAction.getHistoricalSecondaryBondsPricedSuccess, (state, action): PricingCompletionState => {
    return patchSharedTabData({
      state,
      tabName: action.params.tab,
      path: 'secondaryPricedKeys',
      data: action.params.secondaryPricedKeys,
    });
  }),

  on(PricingCompletionAction.updateSecondaryBondDates, (state, action): PricingCompletionState => {
    return patchTabData({
      state,
      tabName: action.params.tab,
      path: `${PRICING_PATH}.included_dates`,
      data: action.params.selectedDates,
    });
  }),

  on(PricingCompletionAction.setSecondaryBondsRegression, (state, action): PricingCompletionState => {
    return patchSharedTabData({
      state,
      tabName: action.params.tab,
      data: { regression: action.params.regression },
    });
  }),

  on(PricingCompletionAction.getHistoricalPricingRequestDataSuccess, (state, action): PricingCompletionState => {
    let patchedState = { ...state };
    if (!state.sharedTabDataMap?.[action.params.tab]?.historicalPricingRequestsData) {
      patchedState = patchSharedTabData({
        state: patchedState,
        tabName: action.params.tab,
        path: 'historicalPricingRequestsData',
        data: {},
      });
    }

    return Object.keys(action.params.historicalPricingRequestsData ?? {}).reduce(
      (patchedState: PricingCompletionState, issuingEntitiesId: string) => {
        patchedState = patchSharedTabData({
          state: patchedState,
          tabName: action.params.tab,
          path: `historicalPricingRequestsData.${issuingEntitiesId}`,
          data: action.params.historicalPricingRequestsData[issuingEntitiesId],
        });
        return patchedState;
      },
      patchedState,
    );
  }),

  on(PricingCompletionAction.getHistoricalPricingListSuccess, (state, action): PricingCompletionState => {
    return patchSharedTabData({
      state,
      tabName: action.params.tab,
      path: 'availableHistoricalDates',
      data: { [action.params.issuingEntityId]: action.params.historicalDates },
    });
  }),

  on(PricingCompletionAction.discardPricingChanges, (state, _): PricingCompletionState => {
    let stateCopy = JSON.parse(JSON.stringify(state));
    Object.keys(stateCopy.pricingTabDataMap).forEach(tab => {
      const issuerPricingDataMap: IssuerPricingDataMap = JSON.parse(JSON.stringify(state?.pricingTabDataMap?.[tab]));
      if (issuerPricingDataMap) {
        const updatedIssuerPricingDataMap = [...Object.keys(issuerPricingDataMap)].reduce(
          (updatedIssuerPricingDataMap, issuingEntityId) => {
            const entityDataPath = `${issuingEntityId}.pricingRequest`;
            const data = issuerPricingDataMap[issuingEntityId].savedPricingRequest;
            return patchObjectData(updatedIssuerPricingDataMap, entityDataPath, data);
          },
          issuerPricingDataMap,
        );
        stateCopy = patchObjectData(state, `pricingTabDataMap`, {
          [tab]: updatedIssuerPricingDataMap,
        }) as PricingCompletionState;
      }
    });

    return stateCopy;
  }),

  on(PricingCompletionAction.getGovieBondsSuccess, (state, action): PricingCompletionState => {
    return patchObjectData(state, 'govieBonds', {
      [action.params.pricingBasis]: action.params.bonds,
    }) as PricingCompletionState;
  }),

  on(PricingCompletionAction.setSecondaryBondsGrouping, (state, action): PricingCompletionState => {
    const tabName = action.params.tabName || state.selectedTab;
    if (!tabName) {
      return state;
    }

    return patchSharedTabData({
      state,
      tabName,
      path: 'secondaryBondsGrouping',
      data: action.params.secondaryBondsGrouping,
    });
  }),

  on(PricingCompletionAction.repriceMaturitiesSuccess, (state, action): PricingCompletionState => {
    let patchedState: PricingCompletionState = JSON.parse(JSON.stringify(state));

    const issuerPricingDataMap = state.pricingTabDataMap?.[action.params.tab];
    if (!issuerPricingDataMap) {
      return state;
    }

    const issuingEntityId = getIssuingEntityFromCurveID(action.params.rowId);
    const curveType = getCurveTypeFromCurveID(action.params.rowId);

    const curveValue =
      curveType === NewIssueCurveType.Yield
        ? getPricingBasisForTab(state.pricingTabDataMap?.[action.params.tab] ?? {})
        : getValueFromCurveID(action.params.rowId);

    (issuingEntityId === GLOBAL_FIELD ? Object.keys(issuerPricingDataMap) : [issuingEntityId]).forEach(
      issuingEntityId => {
        const pricingBasis = issuerPricingDataMap[issuingEntityId]?.pricingRequest.pricing.funding_basis;
        const additionalCurves = issuerPricingDataMap[issuingEntityId]?.pricingRequest.pricing.additional_curves;
        const sharedTabData = patchedState.sharedTabDataMap?.[action.params.tab];
        let isNewCurve = true;
        const updatedAdditionalCurves = JSON.parse(JSON.stringify(additionalCurves ?? [])).map(
          (additionalCurve: AdditionalCurve) => {
            if (
              additionalCurve.type === curveType &&
              !action.params.rowId.includes(HISTORICAL_PRICING) &&
              (curveType === NewIssueCurveType.OverviewSwap || additionalCurve.value === curveValue)
            ) {
              isNewCurve = false;
              return {
                type: curveType,
                value: curveValue,
                data: {
                  ...additionalCurve.data,
                  ...action.params.maturities,
                },
              };
            } else if (additionalCurve.type === NewIssueCurveType.Historical) {
              const historicalDate = getHistoricalDateFromCurveID(action.params.rowId);
              if (additionalCurve.value === historicalDate) {
                isNewCurve = false;
                return {
                  ...additionalCurve,
                  data: {
                    ...additionalCurve.data,
                    ...action.params.maturities,
                  },
                };
              }
            }
            return additionalCurve;
          },
        );

        if (isNewCurve) {
          if (
            curveType &&
            (curveType === NewIssueCurveType.OverviewSwap ||
              ([NewIssueCurveType.Yield, NewIssueCurveType.ReferenceCurve].includes(curveType) &&
                curveValue === pricingBasis))
          ) {
            updatedAdditionalCurves.push({
              type: curveType,
              value: curveValue,
              data: action.params.maturities,
            });
          } else {
            const tempPricingMap = sharedTabData?.tempPricingMap ?? {};
            const updatedMaturities = { ...tempPricingMap[action.params.rowId], ...action.params.maturities };
            if (Object.values(updatedMaturities).filter(maturityPricing => !!maturityPricing).length) {
              tempPricingMap[action.params.rowId] = updatedMaturities;
            } else if (tempPricingMap[action.params.rowId] || action.params) {
              delete tempPricingMap[action.params.rowId];
            }

            patchedState = patchSharedTabData({
              state: patchedState,
              tabName: action.params.tab,
              data: { tempPricingMap },
            });
          }
        }

        patchedState = patchSharedTabData({
          state: patchedState,
          tabName: action.params.tab,
          data: {
            pricedKeys: {
              ...sharedTabData?.pricedKeys,
              [action.params.rowId]: true,
            },
          },
        });

        patchedState = patchTabData({
          state: patchedState,
          tabName: action.params.tab,
          issuingEntity: issuingEntityId,
          path: `${PRICING_PATH}.additional_curves`,
          data: updatedAdditionalCurves,
        });
      },
    );

    return patchedState;
  }),

  on(PricingCompletionAction.updateTabMaturities, (state, action): PricingCompletionState => {
    const stateCopy: PricingCompletionState = JSON.parse(JSON.stringify(state));
    const issuerPricingDataMap = stateCopy.pricingTabDataMap?.[action.params.tab];
    if (!issuerPricingDataMap) {
      return state;
    }

    const maturitiesToClear: string[] = [];
    const updatedIssuerPricingDataMap = Object.keys(issuerPricingDataMap).reduce((acc, issuingEntityId) => {
      const updatedLevels = issuerPricingDataMap[issuingEntityId].pricingRequest.pricing.levels.map(level => {
        Object.keys(level.data).forEach(maturity => {
          if (!maturitiesToClear.includes(maturity) && !action.params.maturities.includes(maturity)) {
            maturitiesToClear.push(maturity);
          }
        });
        const newLevelData: Maturities = {};
        action.params.maturities.forEach(maturity => {
          newLevelData[maturity] = level.data[maturity] ?? '';
        });
        return { ...level, data: newLevelData };
      });
      return patchObjectData(acc, `${issuingEntityId}.${PRICING_PATH}.levels`, updatedLevels);
    }, issuerPricingDataMap);

    if (maturitiesToClear.length && stateCopy.sharedTabDataMap?.[action.params.tab]) {
      const pricingMap = stateCopy.sharedTabDataMap[action.params.tab].tempPricingMap;
      const rowIds = Object.keys(pricingMap);
      maturitiesToClear.forEach(maturity => {
        rowIds.forEach(rowId => {
          if (pricingMap[rowId][maturity]) {
            delete pricingMap[rowId][maturity];
          }
        });
      });
    }
    return patchObjectData(
      stateCopy,
      `pricingTabDataMap.${action.params.tab}`,
      updatedIssuerPricingDataMap,
    ) as PricingCompletionState;
  }),

  on(
    PricingCompletionAction.updateAdditionalCurves,
    (state: PricingCompletionState, action): PricingCompletionState => {
      let patchedState: PricingCompletionState = JSON.parse(JSON.stringify(state));
      const issuerPricingDataMap = patchedState.pricingTabDataMap?.[action.params.tab];
      const sharedTabData = patchedState.sharedTabDataMap?.[action.params.tab];
      if (!issuerPricingDataMap) {
        return state;
      }
      const pricingBasis = getPricingBasisForTab(issuerPricingDataMap);

      const areCurvesEqual = (curve1: AdditionalCurve, curve2: AdditionalCurve) => {
        return curve1.type === curve2.type && curve1.value === curve2.value;
      };

      const additionalCurveRemovalCondition = (
        additionalCurve: AdditionalCurve,
        curveType: NewIssueCurveType,
        newCurves: AdditionalCurve[],
      ) => {
        let baseCondition = !newCurves.find(newCurve => areCurvesEqual(newCurve, additionalCurve));
        if (action.params.addOntoExisting) {
          baseCondition = !baseCondition;
        }
        switch (curveType) {
          case NewIssueCurveType.ReferenceCurve:
            return baseCondition && ![pricingBasis, state.summaryTabSwapBasis].includes(additionalCurve.value);
          case NewIssueCurveType.BenchmarkInput:
          case NewIssueCurveType.BenchmarkYield:
            return true;
          case NewIssueCurveType.Swap:
          case NewIssueCurveType.Historical:
          case NewIssueCurveType.Custom:
          default:
            return baseCondition;
        }
      };

      const curveTypesBeingUpdated = Object.keys(action.params.additionalCurveMap);
      const pricedKeysToClear = new Set<string>();
      const updatedIssuerPricingDataMap = (action.params.issuingEntityIds || Object.keys(issuerPricingDataMap)).reduce(
        (acc, issuingEntityId) => {
          const currentAdditionalCurves =
            issuerPricingDataMap[issuingEntityId].pricingRequest.pricing.additional_curves ?? [];
          const updatedAdditionalCurves: AdditionalCurve[] = currentAdditionalCurves.filter(
            curve => !curveTypesBeingUpdated.includes(curve.type),
          );
          Object.entries(action.params.additionalCurveMap).forEach(
            ([curveType, newAdditionalCurves]: [NewIssueCurveType, AdditionalCurve[]]) => {
              currentAdditionalCurves.forEach(curve => {
                if (curve.type === curveType) {
                  if (additionalCurveRemovalCondition(curve, curveType, newAdditionalCurves)) {
                    const curveId = generateCurveID({
                      tab: action.params.tab,
                      curveType,
                      title: curve.value,
                      ...([NewIssueCurveType.ReferenceCurve, NewIssueCurveType.Custom].includes(curveType)
                        ? {}
                        : {
                            issuingEntityId,
                          }),
                    });
                    pricedKeysToClear.add(curveId);
                    return;
                  }
                  updatedAdditionalCurves.push(curve);
                }
              });

              newAdditionalCurves.forEach(newCurve => {
                if (updatedAdditionalCurves.find(curve => areCurvesEqual(curve, newCurve))) {
                  return;
                }
                let curve = newCurve;
                const curveId = generateCurveID({
                  tab: action.params.tab,
                  curveType,
                  issuingEntityId,
                  title: newCurve.value,
                });

                curve = {
                  ...curve,
                  data: newCurve.data ?? sharedTabData?.tempPricingMap[curveId] ?? {},
                };
                updatedAdditionalCurves.push({ ...curve });
              });
            },
          );

          return patchObjectData(acc, `${issuingEntityId}.${PRICING_PATH}.additional_curves`, updatedAdditionalCurves);
        },
        issuerPricingDataMap,
      );

      if (sharedTabData) {
        curveTypesBeingUpdated.forEach(curveType => {
          if (curveType === NewIssueCurveType.Historical) {
            removeHistoricalDataFromSharedTabData(
              action.params.additionalCurveMap[NewIssueCurveType.Historical]?.map(curve => curve.value),
              sharedTabData,
              action.params.issuingEntityIds,
            );
          } else {
            pricedKeysToClear.forEach(rowId => {
              if (sharedTabData.pricedKeys[rowId]) {
                delete sharedTabData.pricedKeys[rowId];
              }
            });
            Object.keys(sharedTabData.tempPricingMap).forEach(rowId => {
              if (rowId.includes(curveType)) {
                delete sharedTabData.tempPricingMap[rowId];
              }
            });
          }
        });

        patchedState = patchSharedTabData({
          state: patchedState,
          tabName: action.params.tab,
          data: {
            tempPricingMap: sharedTabData.tempPricingMap,
          },
        });
      }

      return patchObjectData(
        patchedState,
        `pricingTabDataMap.${action.params.tab}`,
        updatedIssuerPricingDataMap,
      ) as PricingCompletionState;
    },
  ),

  on(PricingCompletionAction.updateIncludedIsins, (state: PricingCompletionState, action): PricingCompletionState => {
    return patchTabData({
      state,
      tabName: action.params.tab,
      path: `${PRICING_PATH}.included_isins`,
      data: [...action.params.includedIsins],
    });
  }),

  on(PricingCompletionAction.setLegendSelection, (state: PricingCompletionState, action): PricingCompletionState => {
    return patchSharedTabData({
      state,
      tabName: action.params.tab,
      path: 'legendSelection',
      data: action.params.selection,
    });
  }),

  on(PricingCompletionAction.toggleExportLoadingStatus, (state: PricingCompletionState, _): PricingCompletionState => {
    return {
      ...state,
      isExportLoading: !state.isExportLoading,
    };
  }),

  on(
    PricingCompletionAction.clearSecondaryBondsData,
    (state: PricingCompletionState, action): PricingCompletionState => {
      const tabs: string[] = action.params?.tabName
        ? [action.params.tabName]
        : Object.keys(state.sharedTabDataMap ?? {});
      let patchedState = { ...state };
      tabs.forEach(tabName => {
        patchedState = patchSharedTabData({
          state: patchedState,
          tabName,
          data: {
            secondaryPricingMap: {},
            secondaryPricedKeys: {},
          },
        });
      });
      return patchedState;
    },
  ),

  on(PricingCompletionAction.resetPricing, (): PricingCompletionState => {
    return {
      ...initialState,
    };
  }),

  on(PricingCompletionAction.clearPricingInbox, (state: PricingCompletionState, _): PricingCompletionState => {
    return patchObjectData(state, 'pricingCompletion.pricingInboxGroups') as PricingCompletionState;
  }),

  on(
    PricingCompletionAction.updateSecondaryLevels,
    PricingCompletionAction.setSecondaryBondsOverride,
    PricingCompletionAction.setSelectedBondsSuccess,
    (state: PricingCompletionState, action): PricingCompletionState => {
      let patchedState = JSON.parse(JSON.stringify(state));

      if (action.params.secondaryLevels) {
        patchedState = patchTabData({
          state: patchedState,
          tabName: action.params.tabName,
          path: `${PRICING_PATH}`,
          data: { secondary_levels: action.params.secondaryLevels },
        });
      }

      if (action.params.unsavedSecondaryLevels) {
        patchedState = patchSharedTabData({
          state: patchedState,
          tabName: action.params.tabName,
          data: { unsavedSecondaryLevels: action.params.unsavedSecondaryLevels },
        });
      }
      return patchedState;
    },
  ),
);

export function pricingCompletionReducer(state: PricingCompletionState, action: Action) {
  return reducer(state, action);
}
