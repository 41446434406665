import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@morpho/environment';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ContactService } from '../../elements/services/contact.service';
import { IoService } from '../../elements/services/io.service';
import { NipMode, NipRouterState, PRICING_INBOX_URL } from './routes.constants';
import { RouterState } from './state/router.model';
import { RouterSelector } from './state/router.selectors';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private currentPath: string;
  get previousPath(): string {
    return this._previousPath;
  }
  private _previousPath: string;

  get referrer(): string {
    return this._referrer;
  }
  private _referrer: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private contactService: ContactService,
    private store: Store<RouterState>,
    private ioService: IoService,
  ) {
    const ref = this.ioService.getSessionItem('referrer');
    if (ref) {
      this._referrer = ref;
    }

    this.store.select(RouterSelector.path).subscribe((emittedPath: string) => {
      this._previousPath = this.currentPath;
      this.currentPath = emittedPath;
    });

    this.router.events
      .pipe(
        switchMap(() =>
          combineLatest([this.store.select(RouterSelector.path), this.store.select(RouterSelector.referrer)]),
        ),
        filter(([, referrer]) => !!referrer),
        tap(([, referrer]) => {
          this._referrer = referrer;
        }),
        debounceTime(500),
        filter(([path]) => !path.startsWith('/view-levels')),
        tap(() => {
          this.ioService.setSessionItem('referrer', this._referrer);
          this.setUrlParam('referrer');
        }),
      )
      .subscribe();
  }

  getAbsoluteUrl(path: string): string {
    return window.location.origin + (environment.appBaseHref + path).replace('//', '/');
  }

  routeTo(path: string, mouseEvent?: MouseEvent, queryParams?: Record<string, any>, replaceUrl = false): void {
    if (!path) {
      return;
    }

    if (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey)) {
      const url = this.router.serializeUrl(this.router.createUrlTree([path], { queryParams: { ...queryParams } }));
      const newWindow = window.open(this.getAbsoluteUrl(url), '_blank');
      newWindow?.blur();
      window.focus();
      return;
    }

    const route = this.parseRoute(path);
    this.router.navigate([route.routerLink], { queryParams: { ...route.queryParams, ...queryParams }, replaceUrl });
  }

  parseRoute(path: string): { routerLink: string; queryParams?: { [key: string]: string } } {
    if (!path.includes('?') || path.startsWith('/l/')) {
      return {
        routerLink: path,
      };
    }

    const split = path.split('?');
    const urlSearchParams = new URLSearchParams('?' + split[1]);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    return {
      routerLink: split[0],
      queryParams,
    };
  }

  checkForActionUrlParam(): void {
    let queryParams = { ...this.activatedRoute.snapshot.queryParams };
    if (queryParams.action) {
      if (queryParams.action === 'contact') {
        this.contactService.openContactModal();
      }
      queryParams = { ...queryParams, action: null };
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
    }
  }

  // todo, replace with setQueryParams
  removeUrlParam(key: string) {
    this.setUrlParam(key);
  }

  setUrlParam(key: string, value?: string | string[] | null) {
    const queryParams: any = {};
    if (value) {
      queryParams[key] = value;
    } else {
      queryParams[key] = null;
    }

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  setQueryParams(queryParams: Record<string, string | string[] | null>) {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  appendUrlSegment(segmentValue: string) {
    const path = this.router.url.split('?')[0];
    this.router.navigate([`${path}/${segmentValue}`], { queryParamsHandling: 'merge' });
  }

  getTabName(): Observable<string> {
    return this.activatedRoute.queryParams.pipe(map(data => data.tab));
  }

  setTabName(tabName: string): void {
    const queryParams: any = {
      tab: tabName,
    };

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  routeToPricingRequest(params: {
    mode?: NipMode;
    tabName?: string;
    queryParams?: Partial<NipRouterState['queryParams']>;
    event?: MouseEvent;
    replaceUrl?: boolean;
  }) {
    this.store
      .select(RouterSelector.state)
      .pipe(take(1))
      .subscribe(routerState => {
        const queryParams = { ...routerState.queryParams, ...params.queryParams };
        const mode = params.mode || routerState.urlParams.mode;
        const tabName = params.tabName || routerState.urlParams.activeTab;
        const path = `${PRICING_INBOX_URL}/${mode}${tabName ? '/' + tabName : ''}`;
        this.routeTo(path, params.event, queryParams, params.replaceUrl);
      });
  }
}
