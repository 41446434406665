import { Injectable } from '@angular/core';
import {
  BACKEND_DATE_FORMAT,
  CALLABLE_REGEX_PATTERN,
  DATE_FORMAT,
  EMPTY_LEVEL,
  FIXED_PRECISION,
  FLOATING_PRECISION,
  MaturityType,
  PERPETUAL_REGEX_PATTERN,
  PricingUnits,
  TENOR_REGEX_PATTERN,
  UtilService,
  WEEKS_IN_YEAR,
} from '@morpho/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LevelsUtilService {
  constructor(private utilService: UtilService) {}

  convertBpsToYield(value: string): string {
    if (!value) {
      return '';
    }
    const numbers = this.utilService.getValuesFromRange(value) ?? [];
    return this.utilService.getRangeFromValues(
      numbers.map(num => this.utilService.roundValue(Number(num) / 100).toString()),
    );
  }

  convertYieldToBps(value: string) {
    if (!value) {
      return '';
    }
    const numbers = this.utilService.getValuesFromRange(value) ?? [];
    return this.utilService.getRangeFromValues(
      numbers.map(num => this.utilService.roundValue(Number(num) * 100).toString()),
    );
  }

  getMaturityTypeFromMaturity(maturity: string): MaturityType {
    if (maturity.match(TENOR_REGEX_PATTERN)) {
      return MaturityType.Tenor;
    } else if (maturity.match(CALLABLE_REGEX_PATTERN)) {
      return MaturityType.CallableStructure;
    } else if (maturity.match(PERPETUAL_REGEX_PATTERN)) {
      return MaturityType.PerpetualStructure;
    } else if (moment(maturity, BACKEND_DATE_FORMAT, true).isValid()) {
      return MaturityType.Date;
    } else {
      return MaturityType.None;
    }
  }

  getValueFromMaturity(maturity: string, asOf?: string): number {
    if (!maturity) {
      return 0;
    }
    const maturityType = this.getMaturityTypeFromMaturity(maturity);
    switch (maturityType) {
      case MaturityType.CallableStructure:
      case MaturityType.PerpetualStructure:
        return Number(maturity.toLowerCase().split('nc')[1].split('+')[0]);
      case MaturityType.Date:
        return this.utilService.roundValue(
          (asOf && moment(asOf).isValid() ? moment(asOf) : moment()).diff(maturity, 'years', true) * -1,
        );
      case MaturityType.Tenor:
        return this.getValueFromTenor(maturity) ?? 0;
      default:
        console.error('getValueFromMaturity not implemented for ', maturity);
        return 0;
    }
  }

  /**
   * Convert a tenor string into a numeric value, with 1Y = 1
   * For week or month tenors convert to floating representation in years
   * @param  {string} tenor - tenor value
   * @return {number | null} -  numeric value of tenor in years or null if error in parsing
   */
  getValueFromTenor(tenor: string): number | null {
    if (tenor.match(new RegExp(/^\d+$/gm))) {
      tenor = `${tenor}y`;
    }
    let xValue: number | null = parseFloat(tenor.slice(0, -1));

    if (xValue.toString() === 'NaN') {
      return null;
    }

    switch (tenor[tenor.length - 1]?.toLowerCase()) {
      case 'w':
        xValue /= WEEKS_IN_YEAR;
        break;
      case 'm':
        xValue /= 12;
        break;
    }

    return this.utilService.roundValue(xValue);
  }

  sortMaturities(maturities: string[]): string[] {
    const sortedMaturities = [...maturities].sort((a: string, b: string): number => {
      return this.getValueFromMaturity(a) - this.getValueFromMaturity(b);
    });
    return sortedMaturities;
  }

  isMaturityDateInThePast(maturity: string): boolean {
    return moment(maturity, BACKEND_DATE_FORMAT, true).isValid() && this.getValueFromMaturity(maturity) < 0;
  }

  getColumnHeaderForMaturity(maturity: string, maturityType?: MaturityType) {
    return (maturityType ?? this.getMaturityTypeFromMaturity(maturity)) === MaturityType.Date
      ? moment(maturity).format(DATE_FORMAT)
      : maturity;
  }

  getLevelPrecision(precisionParameter: string): number {
    return this.utilService.isFixed(precisionParameter) || precisionParameter === 'Step-Up'
      ? FIXED_PRECISION
      : FLOATING_PRECISION;
  }

  getUnitsFromPrecision(precision: number, forceUnit?: boolean) {
    return precision === FIXED_PRECISION ? PricingUnits.Percentage : forceUnit ? ` (${PricingUnits.BasisPoints})` : '';
  }

  getFormattedLevel(params: { value: number | string; couponType?: string; basis?: string; forceUnit?: boolean }) {
    if (['null', 'undefined', 'NaN', ''].includes(`${params.value}`)) {
      return '';
    }
    const precisionParameter = params.basis ?? params.couponType;
    const precision = precisionParameter ? this.getLevelPrecision(precisionParameter) : FIXED_PRECISION;
    const unit = this.getUnitsFromPrecision(precision, params.forceUnit);

    return `${Number(params.value).toFixed(precision)}${unit}`;
  }

  getDisplayValueFromBasis(spread: string | number, fundingBasis: string, curveType?: string): string {
    const value = this.getValueFromBasis(spread, fundingBasis, curveType);

    if (value === null) {
      return EMPTY_LEVEL;
    }

    return this.getFormattedLevel({
      value,
      basis: fundingBasis,
    });
  }

  getValueFromBasis(spread: string | number, fundingBasis: string, curveType?: string): number | null {
    const parsedSpread = this.utilService.parseNumber(spread);

    if (parsedSpread && this.utilService.isFixed(fundingBasis ?? '') && curveType !== 'pricing') {
      return Number(this.convertBpsToYield(parsedSpread.toString()));
    }
    return parsedSpread;
  }

  // TODO: Add curve level type here (Need to move interface to lib instead of bankangle)
  // TODO: Does this belong here?
  getCellClassFromLevel(level: any): string[] {
    const classes = [];

    if (level.coloring) {
      switch (level.coloring) {
        case 'new-level':
          classes.push('is-level-new');
          break;
        case 'wider-level':
          classes.push('is-level-wider');
          break;
        case 'tighter-level':
          classes.push('is-level-tighter');
          break;
        default:
          break;
      }
    }

    if (level.is_greyed_out) {
      classes.push('is-level-greyed-out');
    }

    if (level.is_target_basis_met === false) {
      classes.push('is-level-below-target-basis');
    }

    if (level.priced?.length && level.priced.some((priced: any) => priced.spread !== null)) {
      classes.push('is-priced');
    }

    return classes;
  }

  formatSpreadValue(params: {
    value: number | string;
    isPercentageCondition?: boolean;
    emptyLevelValue?: string;
    convertBpsToYield?: boolean;
    units?: PricingUnits | string;
    precision?: number;
    forceUnits?: boolean;
  }): string {
    const isPercentage = params.isPercentageCondition ?? params.units === PricingUnits.Percentage;

    let baseValue = String(params.value);

    const unit = isPercentage ? PricingUnits.Percentage : params.forceUnits && params.units ? params.units : '';

    const decimalPlace = params.precision ?? isPercentage ? FIXED_PRECISION : FLOATING_PRECISION;

    const hasArea = baseValue.includes(PricingUnits.Area);

    if (hasArea) {
      baseValue = baseValue.replaceAll(PricingUnits.Area, '');
    }

    const values = this.utilService.getValuesFromRange(
      isPercentage && params.convertBpsToYield ? this.convertBpsToYield(baseValue) : baseValue,
    );

    let formattedRange;
    if (values) {
      formattedRange =
        values.length === 2
          ? `${Number(values[0]).toFixed(decimalPlace)} – ${Number(values[1]).toFixed(decimalPlace)}${unit}`
          : `${Number(values[0]).toFixed(decimalPlace)}${unit}`;
    }

    return formattedRange ? `${formattedRange}${hasArea ? ' ' + PricingUnits.Area : ''}` : params.emptyLevelValue ?? '';
  }
}
