import { Injectable } from '@angular/core';
import { PdfZoomOptions } from '@morpho/core';
import { Observable, Subject, startWith } from 'rxjs';

export type CustomControlZoomValue = PdfZoomOptions | undefined;

@Injectable({
  providedIn: 'root',
})
export class PdfViewerService {
  private zoomIncrement = 1;
  private _pdfViewerReady: boolean;

  private pdfViewerReady$: Subject<boolean> = new Subject();
  private customControlZoom$: Subject<CustomControlZoomValue> = new Subject();

  private fitToPageWidth$: Subject<boolean> = new Subject<boolean>();

  private readonly storedZoomKey = 'omPdfZoom';
  private readonly storedIsOriginalSizeKey = 'omPdfOriginalSize';
  private readonly storedZoomFitToPageWidthKey = 'omPdfZoomFitToPageWidth';

  isPdfViewerReady(): Observable<boolean> {
    return this.pdfViewerReady$.asObservable();
  }

  setPdfViewerIsReady(isReady: boolean) {
    if (isReady === this._pdfViewerReady) {
      return;
    }
    this._pdfViewerReady = isReady;
    this.pdfViewerReady$.next(this._pdfViewerReady);
  }

  getCustomControlZoom(): Observable<CustomControlZoomValue> {
    return this.customControlZoom$.asObservable();
  }

  setCustomControlZoom(zoom: CustomControlZoomValue) {
    this.customControlZoom$.next(zoom);
  }

  getStoredZoom(): number {
    return Number(localStorage.getItem(this.storedZoomKey));
  }

  setStoredZoom(zoom: number) {
    localStorage.setItem(this.storedZoomKey, zoom.toString());
  }

  getStoredIsOriginalSize(): boolean {
    return localStorage.getItem(this.storedIsOriginalSizeKey) === 'true';
  }

  setStoredIsOriginalSize(isOriginalSize: boolean) {
    localStorage.setItem(this.storedIsOriginalSizeKey, isOriginalSize.toString());
  }

  getStoredFitToPageWidth(): boolean {
    return !!localStorage.getItem(this.storedZoomFitToPageWidthKey);
  }

  isFitToPageWidth() {
    return this.fitToPageWidth$.pipe(startWith(this.getStoredFitToPageWidth()));
  }

  toggleFitToPageWidth() {
    localStorage.setItem(this.storedZoomFitToPageWidthKey, this.getStoredFitToPageWidth() ? '' : 'true');
    this.fitToPageWidth$.next(this.getStoredFitToPageWidth());
  }

  resetAll() {
    this.resetFitToPageWidth();
    this.resetStoredZoom();
    this.resetStoredIsOriginalSize();
  }
  resetFitToPageWidth() {
    localStorage.removeItem(this.storedZoomFitToPageWidthKey);
    this.fitToPageWidth$.next(this.getStoredFitToPageWidth());
  }
  resetStoredZoom() {
    localStorage.removeItem(this.storedZoomKey);
  }

  resetStoredIsOriginalSize() {
    localStorage.removeItem(this.storedIsOriginalSizeKey);
  }

  getZoomValue(direction: PdfZoomOptions.In | PdfZoomOptions.Out, currentZoom: number): number {
    if (direction === 'out' && currentZoom < 0.2) {
      return currentZoom;
    }

    let zoomValue = currentZoom * 10;

    zoomValue = direction === PdfZoomOptions.In ? zoomValue + this.zoomIncrement : zoomValue - this.zoomIncrement;
    zoomValue = zoomValue / 10;

    return parseFloat(zoomValue.toFixed(1));
  }
}
