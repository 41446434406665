import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { NotActiveComponent } from './components/not-active/not-active.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

export const ERROR_ROUTES = [
  { path: '403', component: ForbiddenComponent },
  { path: '404', component: NotFoundComponent },
  { path: '405', component: NotActiveComponent },
  { path: '**', component: NotFoundComponent },
];
