import { Action, createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import { Curve } from '../../../models/curve.model';
import { CurveAnalysisAction } from './curve-analysis.actions';
import { CurveAnalysisLoadingStage, CurveAnalysisState } from './curve-analysis.model';

export const CURVE_ANALYSIS_STATE_KEY = 'curveAnalysis';

const initialState: CurveAnalysisState = {
  loadingState: {
    [CurveAnalysisLoadingStage.Init]: false,
    [CurveAnalysisLoadingStage.Get]: false,
    [CurveAnalysisLoadingStage.Rate]: false,
    [CurveAnalysisLoadingStage.Price]: false,
    [CurveAnalysisLoadingStage.Regression]: false,
    [CurveAnalysisLoadingStage.InitialSelection]: false,
  },
  legendSelection: {},
};

const setInitialBondSelection = (curves: Curve[]): Record<string, true> => {
  const bonds: Record<string, true> = {};
  curves.forEach(curve => curve.bonds.forEach(bond => (bonds[bond.isin] = true)));
  return bonds;
};

const reducer = createReducer(
  initialState,

  on(CurveAnalysisAction.setRawCurves, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      rawCurves: action.params.curves,
      selectedBonds: setInitialBondSelection(action.params.curves),
      pricedCurves: undefined,
    };
  }),

  on(CurveAnalysisAction.setPricedCurves, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      pricedCurves: action.params.curves,
      lastPriced: moment().toISOString(),
    };
  }),

  on(CurveAnalysisAction.setPricingInfoList, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      pricingInfoList: action.params.pricingInfoList,
    };
  }),

  on(CurveAnalysisAction.setCurvesToDisplay, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      selectedCurves: [...action.params.curveIds],
    };
  }),

  on(CurveAnalysisAction.setBondsToDisplay, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      selectedBonds: { ...state.selectedBonds, ...action.params },
    };
  }),

  on(CurveAnalysisAction.setLegendSelection, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      legendSelection: { ...state.legendSelection, ...action.params.selection },
    };
  }),

  on(CurveAnalysisAction.updateLoadingState, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      loadingState: {
        ...state.loadingState,
        [CurveAnalysisLoadingStage.Init]: false,
        [action.params.stage]: action.params.isLoading,
      },
    };
  }),

  on(CurveAnalysisAction.setSelectedIssuers, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      previouslySelectedIssuers: state.selectedIssuers,
      selectedIssuers: action.params.issuers,
    };
  }),

  on(CurveAnalysisAction.setPricerValue, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      pricerValue: action.params.value,
    };
  }),

  on(CurveAnalysisAction.setCurveRegressions, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      regression: action.params.config,
      pricedCurves: state.pricedCurves?.map(curve => ({
        ...curve,
        regression: action.params.curveRegressions[curve.identifier],
      })),
    };
  }),

  on(CurveAnalysisAction.setColorConfig, (state: CurveAnalysisState, action): CurveAnalysisState => {
    return {
      ...state,
      colorConfig: action.params,
    };
  }),

  on(CurveAnalysisAction.resetState, (): CurveAnalysisState => {
    return {
      ...initialState,
    };
  }),

  on(CurveAnalysisAction.resetAnalysis, (state: CurveAnalysisState, _): CurveAnalysisState => {
    return {
      ...state,
      selectedIssuers: undefined,
      selectedCurves: undefined,
      pricedCurves: undefined,
      rawCurves: undefined,
    };
  }),
);

export function curveAnalysisReducer(state: CurveAnalysisState, action: Action) {
  return reducer(state, action);
}
