import { getCdnUrl } from '@morpho/core';
import { Action, createReducer, on } from '@ngrx/store';
import { TermsheetClauseType } from 'apps/bankangle/src/app/features/dealer-profile/model';
import { Constants } from 'apps/bankangle/src/app/models/constants';
import { ConstantsAction } from './constants.actions';
import { ConstantsState, FrontendConstants } from './constants.model';

export const CONSTANTS_STATE_KEY = 'constants';

const esgIconDirectory = getCdnUrl('assets/img/esg/');
const frontendConstants: FrontendConstants = {
  pricing_request_coupon_type_options: [
    {
      value: 'Fixed Rate',
      label: 'Fixed Rate',
    },
    {
      value: 'Floating Rate',
      label: 'Floating Rate',
    },
  ],
  pricing_request_frequency_options: [
    {
      value: 'one_off',
      label: 'One-off',
    },
    {
      value: 'daily',
      label: 'Daily',
    },
    {
      value: 'weekly',
      label: 'Weekly',
    },
    {
      value: 'bi_weekly',
      label: 'Bi Weekly',
    },
    {
      value: 'monthly',
      label: 'Monthly',
    },
  ],
  esg_frameworks: [
    {
      value: 'green-alt',
      label: 'Green Bond Framework',
      src: esgIconDirectory + 'bond_framework_green_1.svg',
    },
    {
      value: 'green',
      label: 'Green Bond Framework',
      src: esgIconDirectory + 'bond_framework_green_2.svg',
    },
    {
      value: 'social-alt',
      label: 'Social Bond Framework',
      src: esgIconDirectory + 'bond_framework_social_1.svg',
    },
    {
      value: 'social',
      label: 'Social Bond Framework',
      src: esgIconDirectory + 'bond_framework_social_2.svg',
    },
    {
      value: 'sustainable-alt',
      label: 'Sustainable Bond Framework',
      src: esgIconDirectory + 'bond_framework_sustainable_1.svg',
    },
    {
      value: 'sustainable',
      label: 'Sustainable Bond Framework',
      src: esgIconDirectory + 'bond_framework_sustainable_2.svg',
    },
  ],
  esg_standards_and_principles: [
    {
      value: 'ICMA Green Bond Principles',
      label: 'ICMA Green Bond Principles',
      short_name: 'ICMA',
      src: esgIconDirectory + 'standards_and_principles_green.svg',
    },
    {
      value: 'ICMA Social Bond Principles',
      label: 'ICMA Social Bond Principles',
      short_name: 'ICMA',
      src: esgIconDirectory + 'standards_and_principles_social.svg',
    },
    {
      value: 'ICMA Sustainability Bond Guidelines',
      label: 'ICMA Sustainability Bond Guidelines',
      short_name: 'ICMA',
      src: esgIconDirectory + 'standards_and_principles_sustainable.svg',
    },
    {
      value: 'Climate Bond Initiative',
      label: 'Climate Bond Initiative',
      short_name: 'CBI',
      src: esgIconDirectory + 'standards_and_principles_climate.png',
    },
  ],
  issuance_themes: [
    {
      value: 'green',
      label: 'Green',
      src: getCdnUrl('assets/img/issuance-themes/green-1.svg'),
    },
    {
      value: 'social',
      label: 'Social',
      src: getCdnUrl('assets/img/issuance-themes/social-1.svg'),
    },
    {
      value: 'sukuk',
      label: 'Sukuk',
      src: getCdnUrl('assets/img/issuance-themes/sukuk-1.svg'),
    },
    {
      value: 'uridashi',
      label: 'Uridashi',
      src: getCdnUrl('assets/img/issuance-themes/uridashi-1.svg'),
    },
    {
      value: 'sustainable',
      label: 'Sustainable',
      src: getCdnUrl('assets/img/issuance-themes/sustainable-1.svg'),
    },
  ],
  sdg_categories: [
    {
      value: 'ESG1',
      label: '1. No Poverty',
      src: esgIconDirectory + 'SDG_Goal_1.svg',
    },
    {
      value: 'ESG2',
      src: esgIconDirectory + 'SDG_Goal_2.svg',
      label: '2. Zero Hunger',
    },
    { value: 'ESG3', src: esgIconDirectory + 'SDG_Goal_3.svg', label: '3. Good Health and Wellbeing' },
    { value: 'ESG4', src: esgIconDirectory + 'SDG_Goal_4.svg', label: '4. Quality Education' },
    { value: 'ESG5', src: esgIconDirectory + 'SDG_Goal_5.svg', label: '5. Gender Equality' },
    { value: 'ESG6', src: esgIconDirectory + 'SDG_Goal_6.svg', label: '6. Clean Water and Sanitation' },
    { value: 'ESG7', src: esgIconDirectory + 'SDG_Goal_7.svg', label: '7. Affordable and Clean Energy' },
    { value: 'ESG8', src: esgIconDirectory + 'SDG_Goal_8.svg', label: '8. Decent work and Economic Growth' },
    {
      value: 'ESG9',
      src: esgIconDirectory + 'SDG_Goal_9.svg',
      label: '9. Industry, Innovation and Infrastructure',
    },
    { value: 'ESG10', src: esgIconDirectory + 'SDG_Goal_10.svg', label: '10. Reduced Inequalities' },
    {
      value: 'ESG11',
      src: esgIconDirectory + 'SDG_Goal_11.svg',
      label: '11. Sustainable Cities and Communities',
    },
    {
      value: 'ESG12',
      src: esgIconDirectory + 'SDG_Goal_12.svg',
      label: '12. Responsible Consumption and Production',
    },
    { value: 'ESG13', src: esgIconDirectory + 'SDG_Goal_13.svg', label: '13. Climate Action' },
    { value: 'ESG14', src: esgIconDirectory + 'SDG_Goal_14.svg', label: '14. Life Below Water' },
    { value: 'ESG15', src: esgIconDirectory + 'SDG_Goal_15.svg', label: '15. Life on Land' },
    {
      value: 'ESG16',
      src: esgIconDirectory + 'SDG_Goal_16.svg',
      label: '16. Peace and Justice Strong Institutions',
    },
    { value: 'ESG17', src: esgIconDirectory + 'SDG_Goal_17.svg', label: '17. Partnerships for the Goals' },
  ],

  status: [
    {
      label: 'Posting',
      description: 'Dealers will see these levels in their searches',
      value: 'public',
      cellClass: 'is-accent',
    },
    {
      label: 'Reverse Enquiries',
      description: 'Dealers will see "R/E" in place of the levels you show on the grid, encouraging them to reach out',
      value: 'reverse_inquiry_only',
      cellLabel: 'Reverse Enquiries (R/E)',
      cellClass: 'is-alert',
    },
    {
      label: 'Off',
      description:
        'Dealers will no longer have access to the levels but will still see the curve details in their saved searches and on your profile',
      value: 'private',
      cellClass: 'is-warning',
    },
  ],
  pricing_request_frequency: [
    {
      label: 'One Off',
      description: 'Dealers will see these levels in their searches',
      value: 'one_off',
      cellClass: 'is-accent',
    },
    {
      label: 'Daily',
      description: 'Dealers will see these levels in their searches',
      value: 'daily',
      cellClass: 'is-accent',
    },
    {
      label: 'Weekly',
      description: 'Dealers will see these levels in their searches',
      value: 'weekly',
      cellClass: 'is-accent',
    },
    {
      label: 'Bi Weekly',
      description: 'Dealers will see these levels in their searches',
      value: 'bi_weekly',
      cellClass: 'is-accent',
    },
    {
      label: 'Monthly',
      description: 'Dealers will see these levels in their searches',
      value: 'monthly',
      cellClass: 'is-accent',
    },
    {
      label: 'Outdated',
      description:
        'Dealers will no longer have access to the levels but will still see the curve details in their saved searches and on your profile',
      value: 'outdated',
      cellClass: 'is-warning',
    },
  ],
  trade_status_options: [
    {
      value: 'CTS',
      label: 'Custom Termsheet Template',
      tooltip: 'Enabled for Termsheets with their custom template',
      src: getCdnUrl('assets/img/trade-status/custom_termsheet_v2.svg'),
    },
    {
      value: 'TS',
      label: 'Termsheets',
      tooltip: 'Enabled for Origin Termsheet',
      src: getCdnUrl('assets/img/trade-status/termsheet_v2.svg'),
    },
    {
      value: 'I',
      label: 'Instant ISIN',
      tooltip: 'Enabled for Instant ISIN',
      src: getCdnUrl('assets/img/trade-status/instant_isin.svg'),
    },
    {
      value: 'FT',
      label: 'Final Terms',
      tooltip: 'Enabled for Final Terms and all relevant Post Trade documents',
      src: getCdnUrl('assets/img/trade-status/final_terms_v2.svg'),
    },
    {
      value: 'M',
      label: 'Receive an e-mandate from this issuer after sharing a termsheet',
      tooltip: 'Receive an e-mandate from this issuer after sharing a termsheet',
      src: getCdnUrl('assets/img/trade-status/e_mandate.svg'),
    },
    {
      value: 'D',
      label: 'Download documents and share them offline',
      tooltip: 'Download documents and share them offline',
      src: getCdnUrl('assets/img/trade-status/download.svg'),
    },
  ],
  termsheet_types: [
    { value: 'bond', label: 'Bond Termsheets' },
    { value: 'swap', label: 'Swap Termsheets' },
  ],
  disclaimer_types: [
    { value: 'swap', label: 'Swap Disclaimer' },
    { value: 'issuer', label: 'Issuer Disclaimer' },
    { value: 'investor', label: 'Investor Disclaimer' },
  ],
  termsheet_clause_types: [
    { value: TermsheetClauseType.DISCLAIMER, label: 'Disclaimer' },
    { value: TermsheetClauseType.FALLBACK_PROVISION, label: 'Fallback Provision' },
    { value: TermsheetClauseType.TARGET_MARKET, label: 'Additional Target Market Language' },
  ],
};

const classMapping = {
  s1: 'variation-1',
  s2: 'variation-2',
  s3: 'variation-3',
};

const mappingExtensions: any = {
  product_types: {
    covered_bond: { class: classMapping.s1 },
    senior_preferred: { class: classMapping.s2 },
    senior_secured: { class: classMapping.s2 },
    senior_unsecured: { class: classMapping.s2 },
    senior_unsecured_non_preferred: { class: classMapping.s2 },
    additional_tier_1: { class: classMapping.s3 },
    lower_tier_2: { class: classMapping.s3 },
    hybrid: { class: classMapping.s3 },
  },
};

const keysForMapping = [
  'combined_swap_options',
  'currency_options',
  'esg_frameworks',
  'esg_standards_and_principles',
  'funding_basis_options',
  'issuance_themes',
  'product_types',
  'sdg_categories',
  'special_swap_mapping',
  'swap_options',
  'trade_status_options',
  'termsheet_types',
  'disclaimer_types',
  'termsheet_clause_types',
  'structure_options',
];

const keysForTranslation = [
  'country_options',
  'coupon_type_options',
  'documentation_options',
  'distribution_options',
  'funding_basis_options',
  'issuance_themes',
  'issuer_types',
  'product_types',
  'programme_subtype_options',
  'sdg_categories',
  'swap_type_options',
  'trade_status_options',
  'moodys_st_ratings',
  'moodys_ratings',
  'fitch_st_ratings',
  'fitch_ratings',
  'sp_st_ratings',
  'sp_ratings',
  'scope_st_ratings',
  'scope_ratings',
  'dbrs_st_ratings',
  'dbrs_ratings',
  'pricing_request_frequency_options',
];

const initialState: ConstantsState | Partial<ConstantsState> = {};

const reducer = createReducer(
  initialState,

  on(ConstantsAction.getConstantsSuccess, (state, action: { params: any }) => {
    const constants = {
      ...action.params,
      ...frontendConstants,
    };

    const constantsMapping: any = {};
    for (const key of keysForMapping) {
      constantsMapping[key] = Array.isArray(constants[key])
        ? constants[key]?.reduce((accumulator: any, current: any) => {
            const mappingExtension = mappingExtensions[key] ? mappingExtensions[key][current.value] : null;
            accumulator[current.value] = {
              ...current,
              ...mappingExtension,
            };
            return accumulator;
          }, {})
        : constants[key];
    }

    const constantsTranslation: any = {};
    for (const key of keysForTranslation) {
      constantsTranslation[key] = constants[key]?.reduce((accumulator: any, current: any) => {
        accumulator[current.value] = current.label;
        return accumulator;
      }, {});
    }

    return {
      arrayFor: constants,
      translationFor: constantsTranslation,
      mappingFor: constantsMapping,
    };
  }),
);

export function constantsReducer(state: Constants, action: Action) {
  return reducer(state, action);
}
