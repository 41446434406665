import { Injectable } from '@angular/core';
import {
  AgGridUtilService,
  BACKEND_DATE_FORMAT,
  BasisType,
  DEFAULT_QUOTE_TYPE,
  EMPTY_LEVEL,
  KeyCode,
  LevelsUtilService,
  ONE_BILLION,
  QuoteType,
  TENOR_REGEX_PATTERN,
  UtilService,
  ValueAndLabel,
  ctrlKey,
  shiftKey,
} from '@morpho/core';
import { PrimitiveCellEditorParams } from '@morpho/form';
import { Column } from 'ag-grid-community';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { sortByAlphabeticalInverse } from '../../../constants/sortComparators';
import { TeamColleague } from '../../../core/elements/models/team-colleague.model';
import { Constants } from '../../../models/constants';
import { RegressionObject } from '../../../models/regression.model';
import { RegressionService } from '../../curve-analysis/services/regression.service';
import { DEFAULT_PRICING_BREAKDOWN, PricingBreakDownType, PricingBreakdownEditableRows } from '../constants/breakdown';
import { GovieBond, SecondaryBond } from '../models/comparable.model';
import {
  CurveLevel,
  NewIssueCurveType,
  PricingRequestCreation,
  PricingRequestResponse,
  PricingType,
  RequestingDesk,
  SyndicateTeam,
} from '../models/pricing-request.model';
import { SecondaryBondFilters, SecondaryBondsBody } from '../models/secondaries.model';
import {
  IssuerPricingData,
  IssuerPricingDataMap,
  Maturities,
  NewIssueRowDef,
  PricingTabDataMap,
  SharedTabData,
  SharedTabDataMap,
} from '../state/pricing-completion/pricing-completion.model';
import {
  generateComparableCurveId,
  getDataFromComparableCurveId,
  getHistoricalSecondaryBondDates,
  getSelectedHistoricalDatesForIssuer,
} from '../syndicate-pricer.functions';

@Injectable({
  providedIn: 'root',
})
export class SyndicatePricerUtilService {
  generateComparableCurveId = generateComparableCurveId;
  getDataFromComparableCurveId = getDataFromComparableCurveId;

  constructor(
    private agGridUtilService: AgGridUtilService,
    private levelsUtilService: LevelsUtilService,
    private regressionService: RegressionService,
    private utilService: UtilService,
  ) {}

  isGovieBasis(basis: string, constants: Constants) {
    return this.getBasisType(basis, constants) === BasisType.Govie;
  }

  getEntityIdsFromBody(body: { issuing_entity_ids?: number[]; issuing_entity_id?: number }): number[] | null {
    return body.issuing_entity_ids ?? (body.issuing_entity_id ? [body.issuing_entity_id] : null) ?? null;
  }

  getBasisType(basis: string, constants: Constants) {
    return constants.arrayFor.basis_metadata_mapping[basis as any]?.type as BasisType;
  }

  generateDefaultSecondaryBondsFilter(pricingType: PricingType): Partial<SecondaryBondsBody> {
    const defaultFilter: SecondaryBondFilters = {
      seniorities: [pricingType.seniority],
      currencies: [pricingType.currency],
      coupon_types: [pricingType.coupon_type, ...(pricingType.coupon_type === 'Fixed Rate' ? ['Step-Up'] : [])],
      liquidity_score: '<= 5',
    };

    return defaultFilter;
  }

  generatePricingTypeFromTabName(tabName: string, constants: Constants): PricingType {
    const [currency, couponType, seniority] = tabName.split('_');

    const couponTypeValue: string = constants.arrayFor.structure_options.find(
      structureType => structureType.short_name === couponType,
    )?.value as string;

    const seniorityValue: string = constants.arrayFor.product_types.find(
      productType => productType.short_name === seniority,
    )?.value as string;

    return {
      currency,
      coupon_type: couponTypeValue,
      seniority: seniorityValue,
    };
  }

  isPricedToOriginalBasis(tabName: string, basis: string, constants: Constants): boolean {
    if (!basis || this.getBasisType(basis, constants) !== BasisType.Fixed) {
      return false;
    }
    const pricingType = this.generatePricingTypeFromTabName(tabName, constants);
    const [couponType, currency] = basis.split('_');
    if (currency === pricingType.currency && pricingType.coupon_type.toUpperCase().includes(couponType)) {
      return true;
    }
    return false;
  }

  generatePricingTabFromName(tabName?: string): ValueAndLabel {
    if (!tabName) {
      return { label: '', value: '' };
    }
    return { label: tabName.replaceAll('_', ' '), value: tabName };
  }

  generatePricingTabFromObject(
    params: { seniority: string; currency: string; coupon_type: string },
    constants: Constants,
  ): ValueAndLabel {
    const seniority = constants.mappingFor.product_types[params.seniority]?.short_name;
    const currency = constants.mappingFor.currency_options[params.currency]?.value as string;
    const couponType = constants.arrayFor.structure_options.find(
      option => option.value === params.coupon_type,
    )?.short_name;

    const name = `${currency}_${couponType}_${seniority}`;
    return this.generatePricingTabFromName(name);
  }

  getYieldValue(quoteType: QuoteType, data: any): string | null {
    if (!data) {
      return null;
    }
    const discountMarginKey = this.agGridUtilService.generateQuoteTypeField('discount_margin', quoteType);
    const ytmKey = this.agGridUtilService.generateQuoteTypeField('ytm', quoteType);

    return data[discountMarginKey] ?? data[ytmKey] ?? null;
  }

  getPriceValue(quoteType: QuoteType, data: any) {
    if (!data) {
      return null;
    }
    const priceKey = this.agGridUtilService.generateQuoteTypeField('price', quoteType);
    return data[priceKey] ?? null;
  }

  optionFromRequestingDesk(desk: RequestingDesk): ValueAndLabel {
    return {
      value: desk.id,
      label: desk.name,
    };
  }

  optionFromSyndicateTeam(team: SyndicateTeam): ValueAndLabel {
    return {
      value: team.id,
      label: team.name,
    };
  }

  optionFromTeamColleague(colleague: TeamColleague): ValueAndLabel {
    return {
      value: colleague.id,
      label: `${colleague.first_name} ${colleague.last_name}`,
    };
  }

  calculateMaturityAggregate(maturity: string, levels: CurveLevel[], pricingBreakdown: PricingBreakDownType): string {
    const valuesToAggregate: string[] = [];
    levels.forEach(level => {
      valuesToAggregate.push(level.data[maturity] ?? '');
    });
    return valuesToAggregate.find(value => !!value)
      ? this.utilService.getValueAggregate(valuesToAggregate, pricingBreakdown === PricingBreakDownType.NewIssue)
      : '';
  }

  getMaturityForPricing(params: {
    maturity: string;
    tab: string;
    pricingRequest: PricingRequestResponse;
    pricingMap: Record<string, Maturities>;
    issuingEntityId: string;
  }) {
    if (!params.pricingRequest) {
      return '';
    }
    let totalPricing;
    const pricingBreakdown = params.pricingRequest?.pricing?.breakdown ?? DEFAULT_PRICING_BREAKDOWN;
    if (pricingBreakdown === PricingBreakDownType.Total) {
      totalPricing = this.calculateMaturityAggregate(
        params.maturity,
        params.pricingRequest.pricing.levels ?? [],
        pricingBreakdown,
      );
    } else {
      totalPricing =
        params.pricingRequest.pricing.levels?.find(level => level.key === NewIssueCurveType.Total)?.data?.[
          params.maturity
        ] ?? '';
    }
    return totalPricing;
  }

  formatRangeValue(params: {
    value: number | string;
    data: NewIssueRowDef;
    constants: Constants;
    isEditMode: boolean;
    basis?: string;
  }): string {
    return this.levelsUtilService.formatSpreadValue({
      ...params,
      ...(params.basis
        ? {
            isPercentageCondition: this.getBasisType(params.basis, params.constants) === BasisType.Fixed,
          }
        : {}),
      emptyLevelValue: params.isEditMode ? EMPTY_LEVEL : '',
      convertBpsToYield: ![
        NewIssueCurveType.Interpolated,
        NewIssueCurveType.Spread,
        NewIssueCurveType.NewIssuePremium,
        NewIssueCurveType.Total,
      ].includes(params.data.curveType),
      units: params.data.units,
    });
  }

  levelValueFormatter(params: {
    value: number | string;
    data: NewIssueRowDef;
    basis: string;
    isEditMode: boolean;
    constants: Constants;
  }): string {
    if (!params.data) {
      return '';
    } else if (!(params.value || params.value === 0)) {
      return EMPTY_LEVEL;
    }
    return this.formatRangeValue({ ...params });
  }

  getTabIdFromGroupKey(groupKey: string, constants: Constants): string {
    const [currency, seniority, couponType] = groupKey.split(':');

    const parsedSeniority = constants.mappingFor.product_types[seniority].short_name;
    const parsedCouponType = constants.arrayFor.structure_options.find(
      option => option.value === couponType,
    )?.short_name;

    return `${currency}_${parsedCouponType}_${parsedSeniority}`;
  }

  generatePricingDataMaps(pricingRequests: (PricingRequestResponse | PricingRequestCreation)[], constants: Constants) {
    const pricingTabDataMap: PricingTabDataMap = {};
    const sharedTabDataMap: SharedTabDataMap = {};
    pricingRequests.forEach(pricingRequest => {
      const tabName = this.generatePricingTabFromObject({ ...pricingRequest }, constants).value as string;
      const issuingEntityId =
        typeof pricingRequest.issuing_entity === 'number'
          ? pricingRequest.issuing_entity
          : pricingRequest.issuing_entity.id;

      pricingTabDataMap[tabName] = {
        ...pricingTabDataMap[tabName],
        [issuingEntityId]: {
          pricingRequest: pricingRequest,
          savedPricingRequest: pricingRequest,
        },
      };

      if (!pricingRequest.pricing.levels.length) {
        const initialPricingLevels = PricingBreakdownEditableRows[
          pricingRequest.pricing.breakdown ?? DEFAULT_PRICING_BREAKDOWN
        ].reduce((levels: CurveLevel[], rowType) => {
          const data: Maturities = {};
          pricingRequest.maturities.forEach(maturity => (data[maturity] = ''));
          levels.push({ key: rowType, data });
          return levels;
        }, []);

        pricingTabDataMap[tabName][issuingEntityId].pricingRequest.pricing.levels = initialPricingLevels;
      }

      sharedTabDataMap[tabName] = { tempPricingMap: {}, pricedKeys: {} };
    });
    return { pricingTabDataMap, sharedTabDataMap };
  }

  getMaturityString(maturities: string[]): string {
    return maturities.join(', ');
  }

  getPricingBasisFromRowId(rowId: string): string {
    return rowId.split('/').slice(-1)[0];
  }

  getMappedMaturities(maturities: string): string[] {
    if (!maturities) {
      return [];
    }
    const maturitiesArray = maturities?.split(',');
    return maturitiesArray.reduce((acc: string[], maturity: string) => {
      if (maturity.match(TENOR_REGEX_PATTERN)) {
        const result = this.generateTenorsList(maturity);
        acc.push(...result);
      } else {
        acc.push(maturity);
      }
      return acc;
    }, [] as string[]);
  }

  generateTenorsList(tenors: string): string[] {
    const formattedTenors = tenors
      .toUpperCase()
      .replaceAll(' ', '')
      .split(',')
      .map((tenor: string) => {
        const tenorFormatted = tenor.replaceAll('Y', '');
        const tenors = this.utilService.getValuesFromRange(tenorFormatted);
        if (tenors?.length === 2) {
          const rangeStart = Number(tenors[0]);
          const rangeEnd = Number(tenors[1]);
          const list = [];
          for (let i = rangeStart; i <= rangeEnd; i++) {
            list.push(i.toString());
          }
          return list;
        }
        return tenorFormatted;
      })
      .flatMap((tenor: string) => tenor)
      .filter(tenor => tenor.length)
      .map(tenor => {
        if (tenor.match(new RegExp(/^\d+$/gm))) {
          return tenor + 'Y';
        }
        return tenor;
      });
    return Array.from(new Set<string>(formattedTenors));
  }

  getBondsWithTenorOverAYear(bonds: SecondaryBond[]): SecondaryBond[] {
    return bonds.filter(bond => {
      const tenorValueInYears = this.levelsUtilService.getValueFromTenor(bond.tenor);
      return tenorValueInYears && tenorValueInYears >= 1;
    });
  }

  getCompletionDatesForTab(issuerPricingDataMap: IssuerPricingDataMap): string[] {
    return Object.values(issuerPricingDataMap).reduce(
      (completionDates: string[], issuerPricingData: IssuerPricingData) => {
        const completedAt = issuerPricingData.pricingRequest.pricing.completed_at;
        if (completedAt) {
          completionDates.push(moment(completedAt).format(BACKEND_DATE_FORMAT));
        }
        return completionDates;
      },
      [],
    );
  }

  handleKeyboardNavigation(event: KeyboardEvent, params: PrimitiveCellEditorParams, cellValue?: string) {
    const isShiftKey = shiftKey(event);
    const isCtrlKey = ctrlKey(event);

    const moveToNextCell = (): void => {
      params.api.stopEditing();
      isShiftKey ? params.api.tabToPreviousCell() : params.api.tabToNextCell();
    };
    switch (event.key) {
      case KeyCode.Up:
      case KeyCode.Down:
      case KeyCode.Left:
      case KeyCode.Right:
        if (cellValue) {
          event.stopPropagation();
        } else if (!cellValue) {
          let startRowIdx: number;
          let endRowIdx: number;
          let focusedColumn: Column<any>;
          let selectedColumns: Column<any>[];

          if ([KeyCode.Left, KeyCode.Right].includes(event.key)) {
            startRowIdx = params.rowIndex;
            endRowIdx = params.rowIndex;

            let colSliceParams: [number | undefined, number | undefined];
            let colFocusIdx: number;
            const displayedColumns = params.api.getAllDisplayedColumns();
            const colId = params.colDef.colId;
            const colIdx = displayedColumns.findIndex(col => col.getColId() === colId);

            if (event.key === KeyCode.Left) {
              if (isCtrlKey) {
                const leftEdge = 0;
                if (isShiftKey) {
                  // ctrl+shift+left
                  colFocusIdx = colIdx;
                  colSliceParams = [leftEdge, colIdx + 1];
                } else {
                  // ctrl+left
                  colFocusIdx = leftEdge;
                  colSliceParams = [leftEdge, leftEdge + 1];
                }
              } else {
                const left1 = Math.max(0, colIdx - 1);
                if (isShiftKey) {
                  // shift+left
                  colFocusIdx = colIdx;
                  colSliceParams = [left1, left1 + 2];
                } else {
                  // left
                  colFocusIdx = left1;
                  colSliceParams = [left1, left1 + 1];
                }
              }
            } else {
              if (isCtrlKey) {
                const rightEdge = displayedColumns.length - 1;
                if (isShiftKey) {
                  // ctrl+shift+right
                  colFocusIdx = colIdx;
                  colSliceParams = [colIdx, rightEdge + 1];
                } else {
                  // ctrl+right
                  colFocusIdx = rightEdge;
                  colSliceParams = [rightEdge, rightEdge + 1];
                }
              } else {
                const right1 = Math.min(displayedColumns.length - 1, colIdx + 1);
                if (isShiftKey) {
                  // shift+right
                  colFocusIdx = colIdx;
                  colSliceParams = [colIdx, right1 + 1];
                } else {
                  // right
                  colFocusIdx = right1;
                  colSliceParams = [right1, right1 + 1];
                }
              }
            }

            selectedColumns = displayedColumns.slice(...colSliceParams);
            focusedColumn = displayedColumns[colFocusIdx];
          } else {
            // Up, Down
            focusedColumn = params.column;
            selectedColumns = [params.column];
            if (event.key === KeyCode.Up) {
              const top = 0;
              if (isCtrlKey) {
                if (isShiftKey) {
                  // ctrl+shift+up
                  startRowIdx = params.rowIndex;
                  endRowIdx = top;
                } else {
                  // ctrl+up
                  startRowIdx = top;
                  endRowIdx = top;
                }
              } else {
                const up1 = Math.max(top, params.rowIndex - 1);
                if (isShiftKey) {
                  // shift+up
                  startRowIdx = params.rowIndex;
                  endRowIdx = up1;
                } else {
                  // up
                  startRowIdx = up1;
                  endRowIdx = up1;
                }
              }
            } else {
              const bottom = params.api.getRenderedNodes().length - 1;
              if (isCtrlKey) {
                if (isShiftKey) {
                  // ctrl+shift+down
                  startRowIdx = params.rowIndex;
                  endRowIdx = bottom;
                } else {
                  // ctrl+down
                  startRowIdx = bottom;
                  endRowIdx = bottom;
                }
              } else {
                const down1 = Math.min(bottom, params.rowIndex + 1);
                if (isShiftKey) {
                  // shift+down
                  startRowIdx = params.rowIndex;
                  endRowIdx = down1;
                } else {
                  // down
                  startRowIdx = down1;
                  endRowIdx = down1;
                }
              }
            }
          }

          event.stopPropagation();
          event.preventDefault();
          params.api.clearRangeSelection();
          params.api.setFocusedCell(startRowIdx, focusedColumn);
          params.api.addCellRange({
            rowStartIndex: startRowIdx,
            rowEndIndex: endRowIdx,
            columns: selectedColumns,
          });
        }
        break;
      case KeyCode.Tab:
        event.preventDefault();
        moveToNextCell();
        break;
      case KeyCode.Enter:
        params.api.setGridOption('tabToNextCell', params =>
          this.agGridUtilService.changeTabDirectionToVertical(params),
        );
        moveToNextCell();
        params.api.setGridOption('tabToNextCell', params =>
          this.agGridUtilService.changeTabDirectionToHorizontal(params),
        );
        break;
    }
  }

  formatBenchmarkLabel(isin: string, bonds: GovieBond[]): string {
    const selectedBond = bonds?.find(bond => bond.isin === isin);
    if (!selectedBond) {
      return EMPTY_LEVEL;
    }
    let formattedCoupon = EMPTY_LEVEL;
    const parsedCoupon: number = parseFloat(selectedBond.coupon ?? '');
    if (!!parsedCoupon || parsedCoupon === 0) {
      const fraction: { numerator: number; denominator: number } | null =
        this.utilService.convertFloatToFraction(parsedCoupon);
      if (fraction) {
        formattedCoupon = `${parsedCoupon > 1 ? Math.floor(parsedCoupon) + ' ' : ''}${fraction.numerator}/${
          fraction.denominator
        }`;
      } else {
        formattedCoupon = parsedCoupon.toString();
      }
    }
    return this.utilService.formatFractionsInString(
      `${formattedCoupon} ${this.utilService.convertFromBackendDateStringFormat(selectedBond.maturity_date, 'date')}`,
    );
  }

  getBasisLabel(basis: string, constants: Constants) {
    return constants.mappingFor.funding_basis_options?.[basis]?.label ?? '';
  }

  getSelectedHistoricalSecondaryBondDates(
    issuerPricingDataMap: IssuerPricingDataMap,
    sharedTabData: SharedTabData,
    isViewMode: boolean,
  ) {
    const historicalDatesSet: Set<string> = new Set(getHistoricalSecondaryBondDates(issuerPricingDataMap));

    if (isViewMode) {
      this.getCompletionDatesForTab(issuerPricingDataMap).forEach(date => historicalDatesSet.add(date));
    }

    Object.values(
      getSelectedHistoricalDatesForIssuer(issuerPricingDataMap, sharedTabData.availableHistoricalDates),
    ).forEach(dates => {
      dates.forEach(date => historicalDatesSet.add(moment(date).format(BACKEND_DATE_FORMAT)));
    });

    const showMostRecent = Object.values(issuerPricingDataMap)[0].pricingRequest.pricing.show_latest_historical;

    if (showMostRecent) {
      Object.values(sharedTabData.availableHistoricalDates ?? {}).forEach(dates => {
        if (dates.length) {
          historicalDatesSet.add(moment(dates[0]).format(BACKEND_DATE_FORMAT));
        }
      });
    }
    return [...historicalDatesSet].sort(sortByAlphabeticalInverse);
  }

  getSelectedHistoricalDatesByIssuer(params: {
    issuerPricingDataMap: IssuerPricingDataMap;
    availableHistoricalDates?: Record<string, string[]>;
    issuingEntityId?: string | number;
  }): Record<string, string[]> {
    return getSelectedHistoricalDatesForIssuer(
      params.issuerPricingDataMap,
      params.availableHistoricalDates,
      params.issuingEntityId,
    );
  }

  getMaturityForInterpolatedCurve(regressionObject: RegressionObject, maturity: string) {
    const tenorValue = this.levelsUtilService.getValueFromMaturity(maturity);
    if (
      tenorValue &&
      regressionObject.data.length &&
      tenorValue >= regressionObject.data[0][0] &&
      tenorValue <= regressionObject.data[regressionObject.data.length - 1][0]
    ) {
      const computedValue: number | null = this.regressionService.applyRegressionEquation(tenorValue, regressionObject);
      return `${computedValue ?? ''}`;
    } else {
      return '';
    }
  }

  getBondYield(isin: string, govieBonds: GovieBond[]) {
    const selectedBond = govieBonds?.find(bond => bond.isin === isin);
    return selectedBond ? this.getYieldValue(DEFAULT_QUOTE_TYPE, selectedBond) : '';
  }

  getBondSelectionForTenor(tenor: number, bonds: GovieBond[], asOf?: string): GovieBond[] {
    if (!tenor) {
      return [];
    }
    return (
      bonds.filter(bond => {
        const bondTenor: number = this.levelsUtilService.getValueFromMaturity(bond.maturity, asOf);
        return Math.abs(bondTenor - tenor) <= 0.5 * Math.ceil(tenor / 10);
      }) ?? []
    );
  }

  getDefaultBenchmarkSelection(params: {
    maturities: string[];
    pricingBasis: string;
    govieBonds: GovieBond[];
    asOf?: string;
  }): Record<string, string> {
    const benchmarkSelection: Record<string, string> = {};
    const getClosestBenchmark = (bonds: [Moment, GovieBond][], tenorMoment: Moment, after?: boolean) => {
      let closestBond: [Moment, GovieBond] | undefined;
      let dateDiff: number | undefined;
      bonds.forEach(([maturityMoment, bond]) => {
        if ((!after && maturityMoment.isBefore(tenorMoment)) || (after && maturityMoment.isAfter(tenorMoment))) {
          const difference = Math.abs(tenorMoment.diff(maturityMoment));
          if (!dateDiff || difference < dateDiff) {
            dateDiff = difference;
            closestBond = [maturityMoment, bond];
          }
        }
      });
      return closestBond ? closestBond[1].isin : '';
    };
    params.maturities.forEach(maturity => {
      let defaultBenchmark: string = '';
      const tenor = this.levelsUtilService.getValueFromMaturity(maturity);
      const bondSelection = this.getBondSelectionForTenor(tenor, params.govieBonds, params.asOf);
      const currentMoment = (params.asOf && moment(params.asOf).isValid() ? moment(params.asOf) : moment()).utc();
      const tenorMoment = currentMoment.add(tenor, 'y');

      if (params.pricingBasis === 'GILT') {
        const filteredBondSelection: [Moment, GovieBond][] = bondSelection
          .filter(bond => bond.issue_size >= 10 * ONE_BILLION)
          .map(bond => [moment(bond.maturity_date).utc(), bond]);
        const bondsMaturingWithinYear = filteredBondSelection.filter(
          ([maturityMoment]) => maturityMoment.year() === tenorMoment.year(),
        );

        if (bondsMaturingWithinYear.length) {
          if (bondsMaturingWithinYear.length === 1) {
            defaultBenchmark = bondsMaturingWithinYear[0][1].isin;
          } else {
            const bondsWithinSameMonth = bondsMaturingWithinYear.filter(
              ([maturityMoment]) => maturityMoment.month() === tenorMoment.month(),
            );
            if (bondsWithinSameMonth.length === 1) {
              defaultBenchmark = bondsWithinSameMonth[0][1].isin;
            } else {
              defaultBenchmark =
                getClosestBenchmark(bondsMaturingWithinYear, tenorMoment) ??
                getClosestBenchmark(bondsMaturingWithinYear, tenorMoment, true);
            }
          }
        } else {
          defaultBenchmark = getClosestBenchmark(filteredBondSelection, tenorMoment);
        }
      } else {
        const oTRGovies = bondSelection.filter(bond => bond.use_for_govie_pricing);
        if (oTRGovies.length) {
          if (oTRGovies.length === 1) {
            defaultBenchmark = oTRGovies[0].isin;
          } else {
            switch (params.pricingBasis) {
              case 'UST':
                const oTRMaturingWithinYear: [Moment, GovieBond][] = oTRGovies
                  .filter(bond => moment(bond.maturity_date).utc().year() === tenorMoment.year())
                  .map(bond => [moment(bond.issue_date).utc(), bond]);

                if (oTRMaturingWithinYear.length) {
                  defaultBenchmark = getClosestBenchmark(oTRMaturingWithinYear, currentMoment);
                }
                break;
              default:
                defaultBenchmark = oTRGovies[0].isin;
            }
          }
        }
      }
      if (defaultBenchmark) {
        benchmarkSelection[maturity] = defaultBenchmark;
      }
    });
    return benchmarkSelection;
  }

  getBenchmarkYields(benchmarkBonds: Maturities, govieBonds: GovieBond[]) {
    return Object.entries(benchmarkBonds).reduce((acc: Maturities, [maturity, benchmarkBond]: [string, string]) => {
      if (benchmarkBond) {
        acc[maturity] = this.levelsUtilService.convertYieldToBps(this.getBondYield(benchmarkBond, govieBonds) ?? '');
      }
      return acc;
    }, {});
  }
}
