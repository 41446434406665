import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingComponent {
  @Input() message: string;
}
