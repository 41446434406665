import { Injectable } from '@angular/core';
import { FormInputConfig } from '@morpho/form';
import { Constants } from '../../../models/constants';
import { Curve } from '../../../models/curve.model';
import { Bond } from '../models/comparable.model';

@Injectable({ providedIn: 'root' })
export class PricingRequestService {
  generateComparablesFromCurves(curves: Curve[]): Bond[] {
    const comparables: Bond[] = curves.reduce((accumulator, curve) => {
      return [
        ...accumulator,
        ...curve.bonds.map(bond => {
          return {
            ...bond,
            issuing_entity: curve.issuing_entity,
            seniority: curve.seniority,
            funding_basis: curve.funding_basis,
            currency: curve.currencies[0],
            coupon_type: curve.coupon_types[0],
          };
        }),
      ];
    }, []);
    return comparables;
  }

  generatePricingTypeFields(constants: Constants, initialData?: Record<string, any>): FormInputConfig[] {
    const currenciesOptions = constants.arrayFor.currency_options.filter(option => option.is_priceable);
    const senioritiesOptions = constants.arrayFor.product_types;
    const couponTypeOptions = constants.arrayFor.pricing_request_coupon_type_options;

    const fields: FormInputConfig[] = [
      {
        name: 'currencies',
        label: 'Currencies',
        element: 'autocomplete',
        options: currenciesOptions,
        multiple: true,
        required: true,
        initial: initialData?.currencies ?? (initialData?.currency ? [initialData.currency] : undefined),
      },
      {
        name: 'coupon_types',
        label: 'Coupon Types',
        element: 'autocomplete',
        options: couponTypeOptions,
        multiple: true,
        required: true,
        initial: initialData?.coupon_types ?? (initialData?.coupon_type ? [initialData.coupon_type] : undefined),
      },
      {
        name: 'seniorities',
        label: 'Seniorities',
        element: 'autocomplete',
        options: senioritiesOptions,
        multiple: true,
        required: true,
        initial: initialData?.seniorities ?? (initialData?.seniority ? [initialData.seniority] : undefined),
      },
      {
        element: 'maturity',
        name: 'maturities',
        label: 'Maturities',
        help: 'Use a Comma or Enter for each Maturity',
        initial: this.generateMaturities(initialData?.maturities),
      },
    ];

    return fields;
  }

  generateMaturities(maturities?: string[]): string {
    if (maturities) {
      return maturities.join(',');
    }
    return '';
  }
}
