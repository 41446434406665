import { CustomRouterState } from '@morpho/core';

export const PRICING_INBOX_URL = '/new-issue-pricer';

export enum NipMode {
  Input = 'input',
  Override = 'override',
  View = 'view',
}

export interface NipRouterState extends CustomRouterState {
  queryParams: {
    issuing_entity: number[] | number;
    group?: string;
    date?: string;
  };
  urlParams: {
    mode: NipMode;
    tabName?: string;
  };
}
