import { Injectable } from '@angular/core';
import { ApiService, ValueAndLabel } from '@morpho/core';
import { FormUtilService } from '@morpho/form';
import { Store } from '@ngrx/store';
import { Observable, filter, map, mergeMap, of, shareReplay, take } from 'rxjs';
import { StateService } from '../../services/state.service';
import { AuthenticatedUserState } from '../../state/authenticated-user/authenticated-user.model';
import { AuthenticatedUserSelector } from '../../state/authenticated-user/authenticated-user.selectors';
import { IssuerMetadata, IssuerOnIssuerList, PartialIssuer, SavedIssuerList } from './issuer.model';

@Injectable({
  providedIn: 'root',
})
export class IssuerApiService {
  readonly issuers$: Observable<PartialIssuer[]> = this.apiService.get('companies/issuers/').pipe(shareReplay());
  readonly issuingEntities$: Observable<any[]> = this.apiService.get('companies/issuing-entities/').pipe(shareReplay());

  readonly issuerOptions$: Observable<ValueAndLabel[]> = this.apiService
    .get('companies/issuer-options/')
    .pipe(shareReplay());
  readonly issuingEntityOptions$: Observable<ValueAndLabel[]> = this.apiService
    .get('companies/issuing-entity-options/')
    .pipe(shareReplay());

  readonly issuerFlattenedOptions$ = this.issuerOptions$.pipe(
    map((options: ValueAndLabel[]) => this.formUtilService.flattenOptions(options)),
    shareReplay(),
  );
  readonly issuingEntityFlattenedOptions$ = this.issuingEntityOptions$.pipe(
    map((options: ValueAndLabel[]) => this.formUtilService.flattenOptions(options)),
    shareReplay(),
  );

  constructor(
    private apiService: ApiService,
    private formUtilService: FormUtilService,
    private stateService: StateService,
    private store: Store<any>,
  ) {}

  getFavouriteIssuers(): Observable<SavedIssuerList> {
    const endpoint = 'companies/my-issuer-lists/';
    return this.apiService.get(endpoint).pipe(
      mergeMap((response: SavedIssuerList[]) => {
        for (const list of response) {
          if (list.name === 'favourites') {
            return of(list);
          }
        }
        return this.createFavouriteIssuers();
      }),
    );
  }

  createFavouriteIssuers(): Observable<SavedIssuerList> {
    const endpoint = 'companies/my-issuer-lists/';
    const body = {
      name: 'favourites',
      issuers: [],
    };
    return this.store.select(AuthenticatedUserSelector.state).pipe(
      filter(state => !!state.id),
      take(1),
      mergeMap(user => {
        if (user.isImpersonation) {
          return of(null);
        }
        return this.apiService.post(endpoint, body);
      }),
    );
  }

  setIssuerFavourite(isFavourite: boolean, issuer?: IssuerOnIssuerList, issuerIds?: number[]): Observable<void> {
    let issuersId: number[];

    if (issuer) {
      if (isFavourite === issuer.is_favourite) {
        return of();
      }
      issuer = { ...issuer };
      issuer.is_favourite = isFavourite;
      issuersId = [issuer.issuer.id];
    } else {
      issuersId = issuerIds ?? [];
    }

    return this.stateService.get.authenticatedUser$.pipe(
      mergeMap((user: AuthenticatedUserState) => {
        const endpoint = `companies/my-issuer-lists/${user.favouriteListId}/${isFavourite ? 'add' : 'remove'}/`;
        const body = {
          issuer_ids: issuersId,
        };
        return this.apiService.post(endpoint, body);
      }),
    );
  }

  getIssuerMetadata(issuerId: number): Observable<IssuerMetadata> {
    const endpoint = `levels/level-input-metadata/${issuerId}/`;
    return this.apiService.get(endpoint);
  }

  updateIssuerMetadata(issuerId: string, metadata: IssuerMetadata): Observable<IssuerMetadata> {
    const endpoint = `levels/level-input-metadata/${issuerId}/`;
    return this.apiService.patch(endpoint, metadata);
  }

  requestAccessToIssuer(issuerId: number): Observable<{ issuerId: number }> {
    const endpoint = `accessrequests/accessrequests/${issuerId}/request/`;
    const body = {};
    return this.apiService.post(endpoint, body).pipe(
      map((response: { issuer: number }) => ({
        issuerId,
      })),
    );
  }
}
