export enum QuoteType {
  Bid = 'bid',
  Mid = 'mid',
  Ask = 'ask',
}

export const DEFAULT_QUOTE_TYPE = QuoteType.Mid;

export enum SecondaryBondsGrouping {
  BBGTicker = 'bbg_ticker',
  CouponType = 'coupon_type',
  IssuingEntity = 'issuing_entity',
  Seniority = 'seniority',
  Size = 'size',
  None = 'none',
}

export const DEFAULT_SECONDARY_GROUPING = SecondaryBondsGrouping.BBGTicker;
