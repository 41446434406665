import { Injectable } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { IssuerEntity, IssuingEntity, PartialIssuer } from './issuer.model';

@Injectable({
  providedIn: 'root',
})
export class IssuerUtilService {
  optionFromIssuer(issuer: PartialIssuer): ValueAndLabel {
    let label = issuer.company_name;
    if (issuer.short_name) {
      label += ` (${issuer.short_name})`;
    }
    return {
      value: issuer.id,
      label,
    };
  }

  optionFromIssuingEntity(entity: IssuingEntity | IssuerEntity): ValueAndLabel {
    return {
      value: entity.id,
      label: entity.name,
    };
  }
}
