export const playgroundLinks = [
  {
    label: 'Playground',
    icon: 'playground',
    options: [
      {
        label: 'UI Kit',
        url: './playground/showcase/ui-kit',
      },
      {
        label: 'Form',
        url: './playground/showcase/form',
      },
      {
        label: 'Page',
        url: './playground/showcase/page',
      },
      {
        label: 'Me',
        url: './playground/me',
      },
    ],
  },
];

export const europeanCountries = [
  'AT',
  'BE',
  'BG',
  'CH',
  'DE',
  'DK',
  'FI',
  'FR',
  'ES',
  'IS',
  'IT',
  'LU',
  'NL',
  'NO',
  'SE',
];
