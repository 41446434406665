import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TwoFactorAuthenticationComponent } from '../components/two-factor-authentication/two-factor-authentication.component';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private matDialog: MatDialog) {}

  twoFactorAuthenticationSettings() {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'is-modal',
    };
    return this.matDialog.open(TwoFactorAuthenticationComponent, dialogConfig).afterClosed();
  }
}
