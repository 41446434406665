import { Injectable } from '@angular/core';
import { BackendValueAndLabel, UtilService } from '@morpho/core';
import {
  AutocompleteInputConfig,
  BackendInputOptions,
  BackendOptionGroup,
  ButtonToggleInputConfig,
  CheckboxInputConfig,
  DataInputConfig,
  DataTableInputConfig,
  DateInputConfig,
  DatetimeInputConfig,
  FileInputConfig,
  FormConfig,
  FormInputConfig,
  InputOptions,
  LabelConfig,
  ListInputConfig,
  PrimitiveInputConfig,
  RadioInputConfig,
  RichTextInputConfig,
  TableInputConfig,
} from '@morpho/form';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormTranslationService {
  readonly attributeDictionary: { [key: string]: 'help' | 'hint' | 'warning' } = {
    help_text: 'help',
    hint_text: 'hint',
    warning_text: 'warning',
  };

  constructor(private utilService: UtilService) {}

  translateBackendOptions(options: any[], formConfig = {}): FormInputConfig[] {
    return options.map(opt => this.translateBackendConfig(opt, formConfig));
  }

  translateBackendConfig(fieldConfig: any, formConfig: FormConfig): FormInputConfig {
    const mappedConfig: FormInputConfig = {
      name: fieldConfig.name,
      initial: fieldConfig.initial ?? fieldConfig.suggestion,
      label: fieldConfig.label,
      locked: fieldConfig.locked,

      placeholder: fieldConfig.placeholder,

      warning: fieldConfig.warning,
      icon: fieldConfig.icon,
      required: formConfig.isPartialSaveAllowed
        ? fieldConfig.required || fieldConfig.allow_null === false
        : fieldConfig.required,
      disabled: fieldConfig.disabled,
      hidden: !fieldConfig.visible,

      regex: fieldConfig.regex,
      regex_message: fieldConfig.regex_message,

      existsCondition: fieldConfig.config?.exists,
      alterFields: fieldConfig.config?.alter_fields,
      computeField: fieldConfig.config?.compute_field,
      validateField: fieldConfig.config?.validate_field,

      element: fieldConfig.element,

      templates: fieldConfig.templates,

      content: fieldConfig.content,
      suggestion: fieldConfig.suggestion,
      low_confidence: fieldConfig.low_confidence,

      ...this.translateBackendConfigForSpecificInput(fieldConfig),
    };

    for (const [backendKey, frontendKey] of Object.entries(this.attributeDictionary)) {
      mappedConfig[frontendKey] = fieldConfig[backendKey] || fieldConfig[frontendKey];
    }

    if (mappedConfig.initial === '' || mappedConfig.initial === undefined) {
      mappedConfig.initial = null;
    }

    return mappedConfig;
  }

  private translateBackendConfigForSpecificInput(fieldConfig: any): Partial<FormInputConfig> {
    if (fieldConfig.element === 'input') {
      switch (fieldConfig.type) {
        case 'text':
        case 'email':
        case 'number':
        case 'url': // todo fully support
          return {
            element: 'primitive',
            type: fieldConfig.type === 'url' ? 'text' : fieldConfig.type,
            min: fieldConfig.min,
            max: fieldConfig.max,
            maxlength: fieldConfig.max_length,
            decimalPlaces: fieldConfig.decimal_places,
          } as PrimitiveInputConfig;
        case 'token':
          return {
            element: 'list',
            type: 'text',
          } as ListInputConfig;
        case 'multiint':
          return {
            element: 'list',
            type: 'number',
            regex: fieldConfig.regex || '^-*\\d*$',
            regex_message: fieldConfig.regex_message || 'Whole numbers only',
          } as ListInputConfig;
        case 'multidecimal':
          return {
            element: 'list',
            type: 'number',
            decimalPlaces: fieldConfig.decimal_places,
          } as ListInputConfig;
        case 'multidate':
          return {
            element: 'list',
            type: fieldConfig.hide_year ? 'date-no-year' : 'date',
          } as ListInputConfig;
        case 'file':
          return {
            element: ['full', 'slim'].includes(fieldConfig.variant) ? 'drag_file' : 'file',
            accept: fieldConfig.accept?.split(',').map((item: string) => item.trim()),
            maxSize: fieldConfig.max_size,
            variant: fieldConfig.variant,
          } as FileInputConfig;
        case 'checkbox':
          return {
            element: 'checkbox',
            options: fieldConfig.options,
          } as CheckboxInputConfig;
        case 'radio':
        case 'toggle':
        case 'button_toggle':
          return {
            element: fieldConfig.type === 'radio' ? 'radio' : 'button_toggle',
            options: fieldConfig.options
              ? fieldConfig.options
              : [
                  { value: true, label: fieldConfig.true_label || 'Yes' },
                  { value: false, label: fieldConfig.false_label || 'No' },
                ],
          } as RadioInputConfig | ButtonToggleInputConfig;
        case 'date':
          return {
            element: 'date',
            min: fieldConfig.min,
            max: fieldConfig.max,
            hideYear: fieldConfig.hide_year,
          } as DateInputConfig;
        case 'phone':
          return {
            element: 'phone',
          };
        case 'datetime':
          return {
            element: 'datetime',
            min: fieldConfig.min,
            max: fieldConfig.max,
          } as DatetimeInputConfig;
        default:
          this.utilService.logError(
            `BackendTranslationNotImplemented for dynamic form input: ${fieldConfig.name} (${fieldConfig.element} / ${fieldConfig.type})`,
          );
          return {};
      }
    }

    switch (fieldConfig.element) {
      case 'data':
        return {
          element: 'data',
        } as DataInputConfig;
      case 'textarea':
        return {
          element: 'primitive',
          type: 'textarea',
          maxlength: typeof fieldConfig.max_length === 'number' ? { max_length: fieldConfig.max_length } : undefined,
        } as PrimitiveInputConfig;
      case 'table':
        return {
          element: 'table',
          type:
            fieldConfig.child_attributes?.element === 'input'
              ? fieldConfig.child_attributes?.type
              : fieldConfig.child_attributes?.element,
          labelPrefix: fieldConfig.child_label_prefix,
          labels: fieldConfig.child_labels,
          size: fieldConfig.size,
          sizeSource: fieldConfig.size_source,
          options: fieldConfig.child_attributes?.options,
        } as TableInputConfig;
      case 'data_table':
        return {
          element: 'data_table',
          labels: fieldConfig.child_labels,
          labelHeader: fieldConfig.child_labels_header,
          labelPrefix: fieldConfig.child_label_prefix,
          minSize: fieldConfig.min_length,
          maxSize: fieldConfig.max_length,
          columns: (fieldConfig.fields ?? []).map((childFieldConfig: any) => ({
            ...childFieldConfig,
            ...this.translateBackendConfigForSpecificInput(childFieldConfig),
          })),
        } as DataTableInputConfig;
      case 'label':
        return {
          element: 'label',
        } as LabelConfig;
      case 'select':
        return {
          element: 'autocomplete',
          options: this.convertOptionTags(fieldConfig.options),
          multiple: fieldConfig.type === 'multiple',
          relaxed: fieldConfig.type === 'suggestion',
        } as AutocompleteInputConfig;
      case 'richtext':
        return {
          element: 'richtext',
          options: fieldConfig.options,
        } as RichTextInputConfig;
      case '':
        break;
      default:
        this.utilService.logError(
          `BackendTranslationNotImplemented for dynamic form input: ${fieldConfig.name} (${fieldConfig.element} / ${fieldConfig.type})`,
        );
        break;
    }
    return {};
  }

  private buildContentFieldConfig(reducedFieldConfig: any): any {
    return {
      element: 'textarea',
      placeholder: 'Write here',
      icon: '',
      hint_text: '',
      warning_text: '',
      instant_isin: false,
      locked: false,
      required: false,
      allow_null: true,
      visible: true,
      disable_suggestions: false,
      config: {},
      ...reducedFieldConfig,
    };
  }

  translateBackendContentConfig(reducedFieldConfig: any): FormInputConfig {
    const fieldConfig = this.buildContentFieldConfig(reducedFieldConfig);
    return this.translateBackendConfig(fieldConfig, {});
  }

  convertTagReference(reference: string): any {
    switch (reference) {
      case 'instant_isin':
        return { icon: 'assets/img/trade-status/instant_isin.svg', tooltip: 'Enabled for Instant ISIN' };
      case 'final_terms':
        return {
          icon: 'assets/img/trade-status/final_terms_v2.svg',
          tooltip: 'Enabled for Final Terms and all relevant Post Trade documents',
        };
      case 'custom_termsheet':
        return {
          icon: 'assets/img/trade-status/custom_termsheet_v2.svg',
          tooltip: 'Enabled for Termsheets with their custom template',
        };
      default:
        return null;
    }
  }

  convertOptionTags(options: BackendInputOptions): InputOptions {
    return options.map((option: BackendValueAndLabel | BackendOptionGroup) =>
      (option as BackendOptionGroup).options !== undefined
        ? {
            ...option,
            options: this.convertOptionTags((option as BackendOptionGroup).options),
          }
        : {
            ...option,
            tags: (option as BackendValueAndLabel).tags
              ?.map(tag => this.convertTagReference(tag))
              .filter(tag => tag !== null),
          },
    ) as InputOptions;
  }
}
