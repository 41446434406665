<mat-list>
  <ng-container>
    @for (item of localValue; track item; let i = $index) {
      <mat-list-item>
        <om-basic-input-container
          [item]="localValue[i]"
          [isEdit]="true"
          [type]="type"
          [context]="context"
          suffixIconButton="cross"
          suffixIconButtonClass="is-warning"
          (itemChange)="onInputChange($event, i)"
          (onClick)="removeItem(i)"
        ></om-basic-input-container>
      </mat-list-item>
    }
    <mat-list-item>
      <om-basic-input-container
        [(item)]="childValue"
        [type]="type"
        [context]="context"
        [hideButton]="type === 'date' || type === 'date-no-year' || type === 'select'"
        [isButtonDisabled]="disabled || (!childValue && childValue !== 0)"
        suffixIconButton="plus"
        suffixIconButtonClass="is-accent"
        (onSubmit)="addItem($event)"
        (onClick)="addItem($event)"
        (controlValueChange)="onControlValueChange($event)"
      ></om-basic-input-container>
    </mat-list-item>
  </ng-container>
</mat-list>
