import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CoreApiService } from '../../services/core-api.service';
import { ConstantsAction, ConstantsEffect } from './constants.actions';

@Injectable()
export class ConstantsEffects {
  getConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConstantsEffect.GET_CONSTANTS),
      mergeMap(() =>
        this.coreApiService.getConstants().pipe(
          map(response => ConstantsAction.getConstantsSuccess({ params: response })),
          catchError(() => of(ConstantsAction.getConstantsFail())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private coreApiService: CoreApiService,
  ) {}
}
