import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PdfTextLayerRenderedEvent, PdfZoomOptions, PdfZoomScaleOptions, PinchZoomDirection } from '@morpho/core';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../patterns/api/api.service';
import { PdfViewerService } from '../../services/pdf-viewer.service';
import { TrackByService } from '../../services/track-by.service';
import { UtilService } from '../../services/util.service';

@Component({
  standalone: false,
  selector: 'om-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  host: { class: 'om-pdf-viewer' },
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  @HostBinding('class.has-controls') @Input() hasControls = false;

  private ngOnDestroy$ = new Subject<void>();

  readonly defaultLoadingText = 'Please wait while we load the document';

  isFitToPageWidth: boolean;

  data$: Observable<(ArrayBuffer | null)[]>;
  zoom = this.pdfViewerService.getStoredZoom();
  zoomScale: PdfZoomScaleOptions | undefined;

  originalSize = this.pdfViewerService.getStoredIsOriginalSize();
  autoResize: boolean;
  fitToPage: boolean;

  @Input() loadingText: string;

  @Input()
  set fitToPageHeight(fitToPageHeight: boolean) {
    if (fitToPageHeight === true) {
      this._fitToPageHeight = true;
      this.zoomScale = PdfZoomScaleOptions.pageHeight;
      this.zoom = 1;
    } else {
      this._fitToPageHeight = false;
    }
  }
  get fitToPageHeight(): boolean {
    return this._fitToPageHeight;
  }
  private _fitToPageHeight = false;

  @Input()
  set showControls(showControls: boolean) {
    this._showControls = showControls;
    this.hasControls = showControls;
  }
  get showControls(): boolean {
    return this._showControls;
  }
  private _showControls: boolean;

  @Input()
  set url(url: string) {
    if (url) {
      this._urls = [url];
    } else {
      this._urls = [];
    }
    this.setData();
  }
  private _urls: string[] = [];

  @Input() cssClasses: string[];

  @Output() pdfReady: EventEmitter<boolean> = new EventEmitter();
  @Output() pagesInitialized: EventEmitter<boolean> = new EventEmitter();
  @Output() textLayerRendered: EventEmitter<PdfTextLayerRenderedEvent> = new EventEmitter();
  @Output() redlineError: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public trackBy: TrackByService,
    public apiService: ApiService,
    public utilService: UtilService,
    private pdfViewerService: PdfViewerService,
  ) {}

  ngOnInit(): void {
    this.pdfViewerService
      .isFitToPageWidth()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(isFitToPageWidth => {
        this.isFitToPageWidth = isFitToPageWidth;
        this.setupZoom(this.isFitToPageWidth);
        if (this.isFitToPageWidth) {
          this.setZoom(1);
        }
        if (!this.zoom) {
          this.setIsOriginalSize(true);
          this.setZoom(1);
        }
      });

    this.pdfViewerService
      .getCustomControlZoom()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(zoomOption => {
        if (zoomOption) {
          this.onZoom(zoomOption);
        }
      });
  }

  onPageRendered() {
    this.pdfViewerService.setPdfViewerIsReady(true);
    this.pdfReady.emit(true);
  }

  onPagesInitialized() {
    this.pdfViewerService.setPdfViewerIsReady(false);
    this.pagesInitialized.emit(true);
  }

  onPinchZoom(pinchZoomDirection: PinchZoomDirection) {
    if (pinchZoomDirection === PinchZoomDirection.In) {
      this.onZoom(PdfZoomOptions.In);
    } else {
      this.onZoom(PdfZoomOptions.Out);
    }
  }

  onTextLayerRendered(event: PdfTextLayerRenderedEvent) {
    this.textLayerRendered.emit(event);
  }

  private onZoom(direction: PdfZoomOptions.In | PdfZoomOptions.Out) {
    const zoomValue = this.pdfViewerService.getZoomValue(direction, this.zoom);
    this.setZoom(zoomValue);
    this.pdfViewerService.resetFitToPageWidth();
  }

  private setupZoom(isFitToPageWidth: boolean) {
    if (isFitToPageWidth) {
      this.zoomScale = PdfZoomScaleOptions.pageWidth;
      this.autoResize = true;
      this.fitToPage = true;
      this.setIsOriginalSize(false);
    } else {
      this.autoResize = false;
      this.setIsOriginalSize(!this.fitToPage);
    }
    this.pdfViewerService.setStoredIsOriginalSize(this.originalSize);
  }

  private setData = this.utilService.debounce(() => {
    this.data$ = of(this._urls).pipe(
      mergeMap(arr => {
        return arr?.length
          ? forkJoin(
              arr.map(url => {
                return this.apiService.getFromExternalAPI(url, {}, 'arraybuffer').pipe(
                  map((response: ArrayBuffer) => response.slice(0)),
                  catchError(e => {
                    if (url.includes('redline')) {
                      this.redlineError.emit(true);
                    }
                    return of(null);
                  }),
                );
              }),
            )
          : of([]);
      }),
    );
  }, 250);

  private setZoom(zoomValue: number) {
    if (zoomValue !== this.zoom) {
      this.zoom = zoomValue;
      this.pdfViewerService.setStoredZoom(this.zoom);
    }
  }

  private setIsOriginalSize(isOriginalSize: boolean) {
    this.originalSize = isOriginalSize;
    this.pdfViewerService.setStoredIsOriginalSize(this.originalSize);
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();

    this.pdfViewerService.setPdfViewerIsReady(false);
  }
}
