import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PopupService } from '@morpho/core';
import { DEFAULT_FORM_ERROR } from '../constants/wording';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private popupService: PopupService) {}

  showFormErrors(form: FormGroup, errorMsg = DEFAULT_FORM_ERROR) {
    Object.values(form.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
    });
    this.popupService.error(errorMsg);
  }
}
