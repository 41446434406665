import { NgModule } from '@angular/core';
import { CellClassParams, GridApi, ITooltipParams } from 'ag-grid-community';
import * as Moment from 'moment-timezone';
import { BACKEND_DATETIME_PRECISE_FORMAT } from './elements/constants/display';
import { SECONDS_PRECISION_REGEX } from './elements/constants/regex-pattern';

interface OmColDef {
  gridName?: string;
  omTranslation?: Record<string, string>;
  isNotSecondaryCurve?: (params: CellClassParams | ITooltipParams) => boolean;
  omExportMapping?: (value: any, data?: any) => any;
}

declare module 'ag-grid-community' {
  interface ColDef extends OmColDef {}
  interface ColGroupDef extends OmColDef {}

  interface GridApi {
    sortBy(colId: string, sort?: 'asc' | 'desc'): void;
  }
}

declare module 'moment-timezone' {
  interface Moment {
    preciseUTCFormat(): string;
  }
}

GridApi.prototype.sortBy = function (colId: string, sort?: 'asc' | 'desc') {
  if (!sort) {
    sort = colId === 'last_updated' ? 'desc' : 'asc';
  }
  (this as GridApi).applyColumnState({ state: [{ colId, sort }] });
};

Moment.prototype.preciseUTCFormat = function () {
  const milliseconds = this._i?.match(SECONDS_PRECISION_REGEX);
  const baseFormatting = (this as Moment.Moment).utc().format(BACKEND_DATETIME_PRECISE_FORMAT);
  if (!milliseconds || !milliseconds.length) {
    return baseFormatting;
  }
  return baseFormatting.replace(SECONDS_PRECISION_REGEX, milliseconds[0]);
};

@NgModule({})
export class PrototypeModule {}
