import { Component, Input } from '@angular/core';

export interface ShareButtonOption {
  type: 'bloomberg_msg' | 'bloomberg_chat' | 'email' | 'excel' | 'url';
  onClick?: (exportType?: string, shareOptionType?: string) => void;
}

interface FullShareButtonOption extends ShareButtonOption {
  icon: string;
  label: string;
}

@Component({
  standalone: false,
  selector: 'om-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent {
  private defaultOptionMapping = {
    bloomberg_msg: {
      icon: 'bloomberg',
      label: 'Copy for Bloomberg MSG',
    },
    bloomberg_chat: {
      icon: 'bloomberg',
      label: 'Copy for Bloomberg Chat',
    },
    email: {
      icon: 'email',
      label: 'Email Me this Run',
    },
    excel: {
      icon: 'excel',
      label: 'Excel Export',
    },
    url: {
      icon: 'share_url',
      label: 'Create Shareable URL',
    },
  };

  @Input() buttonClass: string;

  @Input()
  set options(options: ShareButtonOption[]) {
    this.buttons = options.map(option => ({
      ...this.defaultOptionMapping[option.type],
      ...option,
    }));
  }

  @Input()
  isLoading: boolean;

  buttons: FullShareButtonOption[];

  onShare(option: FullShareButtonOption) {
    if (option.onClick) {
      option.onClick(option.type);
    }
  }
}
