import { Component, HostBinding, HostListener } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  standalone: false,
  selector: 'om-row-expand-cell-renderer',
  template: '',
})
export class RowExpandCellRendererComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams;

  @HostBinding('class.is-expanded') isExpanded: boolean;

  @HostListener('click') onClick() {
    this.isExpanded = !this.params.node.expanded;
    this.params.node.setExpanded(this.isExpanded);
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }
}
