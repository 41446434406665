import { Injector, NgModule } from '@angular/core';
import { CoreModule } from '@morpho/core';
import { BaseEditor, BaseElement, Editor } from 'slate';
import { AngularEditor, SlateError, SlateModule } from 'slate-angular';
import { HistoryEditor } from 'slate-history';
import { EquationEditorComponent } from './components/equation-editor/equation-editor.component';
import { HyperlinkEditorComponent } from './components/hyperlink-editor/hyperlink-editor.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import { RichTextInlineComponent } from './components/rich-text-inline/rich-text-inline.component';
import { TableEditControl } from './components/table-edit-control/table-edit-control.component';
import { ElementType } from './constants/rich-text.constants';
import { RichTextTableEditorContainer } from './plugins/table/table.model';
import { TextAlign } from './plugins/text/text.constants';

export class RichTextEditorContainer extends RichTextTableEditorContainer {}

type CustomEditor = BaseEditor &
  HistoryEditor & {
    // AngularEditor
    insertData: (data: DataTransfer) => void;
    insertFragmentData: (data: DataTransfer) => boolean;
    insertTextData: (data: DataTransfer) => boolean;
    setFragmentData: (data: DataTransfer, originEvent?: 'drag' | 'copy' | 'cut') => void;
    deleteCutData: () => void;
    onKeydown: (event: KeyboardEvent) => void;
    onClick: (event: MouseEvent) => void;
    injector: Injector;
    isBlockCard: (node: Node) => boolean;
    isExpanded: (node: Element) => boolean;
    onError: (errorData: SlateError) => void;
    hasRange: (editor: AngularEditor, range: Range) => boolean;

    // Custom Editor
    type?: string;
    container?: RichTextEditorContainer;
    onKeyDown?: (editor: Editor, event: KeyboardEvent) => void; // custom
  };

type CustomElement = BaseElement & { type: ElementType; text_align?: TextAlign };
declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: { text: string };
  }
}

@NgModule({
  declarations: [
    RichTextEditorComponent,
    RichTextInlineComponent,
    HyperlinkEditorComponent,
    TableEditControl,
    EquationEditorComponent,
  ],
  imports: [CoreModule, SlateModule],
  exports: [RichTextEditorComponent, TableEditControl],
})
export class RichTextModule {}
