import { Component, forwardRef } from '@angular/core';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

@Component({
  standalone: false,
  selector: 'om-placeholder-input',
  templateUrl: './placeholder-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => PlaceholderInputComponent)),
})
export class PlaceholderInputComponent extends CustomFormFieldControlComponent {}
