<mat-form-field (focusout)="onFocusout()" (focusin)="onFocusin()">
  <mat-chip-grid #chipList>
    <ng-container>
      @for (maturity of maturityInputs; track $index) {
        <mat-chip-row (removed)="removeChip(maturity)">
          {{ maturity }}
          <span><mat-icon svgIcon="remove" matChipRemove></mat-icon></span>
        </mat-chip-row>
      }
    </ng-container>

    <input
      #childInput
      (keydown)="onKeyDown($event)"
      [matChipInputFor]="chipList"
      [placeholder]="maturityPlaceholder"
      [(ngModel)]="maturityValue"
      autocomplete="off"
    />
  </mat-chip-grid>
</mat-form-field>

<input
  matInput
  [matDatepicker]="picker"
  placeholder="Choose a date"
  [(ngModel)]="dateValue"
  (ngModelChange)="onDateValueChange($event)"
  class="is-hidden"
  [min]="minDate"
/>
<button mat-icon-button matSuffix class="om-calendar-toggle" (click)="openDatePicker()">
  <mat-icon svgIcon="calendar"></mat-icon>
</button>
<mat-datepicker #picker></mat-datepicker>
