import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormInputConfig } from '@morpho/form';

export interface DynamicFormInputDirectiveParams {
  fieldConfig: FormInputConfig;
}

@Directive({
  standalone: false,
  selector: '[omDynamicFormInput]',
})
export class DynamicFormInputDirective implements OnChanges {
  @Input('omDynamicFormInput') params: DynamicFormInputDirectiveParams;

  @HostBinding('attr.placeholder') placeholder: string | undefined;

  ngOnChanges(changes: SimpleChanges) {
    this.placeholder = this.params.fieldConfig.placeholder;
  }
}
