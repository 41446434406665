import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IoService } from '@morpho/core';
import { ElementType, RichTextHyperlink } from '@morpho/rich-text';
import { BaseSelection, Editor, Element, Node, NodeEntry } from 'slate';
import { HyperlinkEditor } from '../../plugins/hyperlink/hyperlink-commands';

enum HyperlinkViewState {
  View,
  Edit,
}

type ActionButton = {
  icon: string;
  action: any;
};

const URL_PREFIX = 'https://';
@Component({
  standalone: false,
  selector: 'om-hyperlink-editor',
  templateUrl: './hyperlink-editor.component.html',
  styleUrls: ['./hyperlink-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HyperlinkEditorComponent implements OnInit, OnDestroy {
  @Input() editor: Editor;
  @Input() activeSelection: BaseSelection;

  @Output() close = new EventEmitter<boolean>();

  readonly HyperlinkViewState = HyperlinkViewState;
  hyperlinkViewState: HyperlinkViewState;

  url = '';

  currentSelection: any;

  readonly editActions: ActionButton[] = [
    {
      icon: 'approve',
      action: this.addUrl.bind(this),
    },
    {
      icon: 'close',
      action: this.onCancel.bind(this),
    },
  ];

  readonly viewActions: ActionButton[] = [
    {
      icon: 'copy_to_clipboard',
      action: this.onCopy.bind(this),
    },
    {
      icon: 'edit',
      action: this.onEdit.bind(this),
    },
    {
      icon: 'delete',
      action: this.onDelete.bind(this),
    },
  ];

  private onClickAnywhere: (event: PointerEvent & { path: HTMLElement[] }) => void;

  constructor(private ioService: IoService) {}

  ngOnInit() {
    const activeLink = HyperlinkEditor.getActiveElement(this.editor, ElementType.Hyperlink);
    if (activeLink) {
      this.hyperlinkViewState = HyperlinkViewState.View;
      const [node]: NodeEntry<Node> = activeLink;
      if (Element.isElement(node)) {
        const thisElement = node as RichTextHyperlink;
        this.url = thisElement.url;
      }
    } else {
      this.hyperlinkViewState = HyperlinkViewState.Edit;
      if (!this.url.length) {
        this.url = `${URL_PREFIX}${this.url}`;
      }
    }

    this.currentSelection = this.editor.selection;
  }

  addUrl() {
    HyperlinkEditor.insertUrl(this.editor, this.url, this.currentSelection);
    this.closeEditor();
  }

  onCancel() {
    this.closeEditor();
  }

  onCopy() {
    if (this.url) {
      this.ioService.copy(this.url);
    }
  }

  onEdit() {
    this.hyperlinkViewState = HyperlinkViewState.Edit;
  }

  onDelete() {
    HyperlinkEditor.removeLink(this.editor, this.currentSelection);
    this.closeEditor();
  }

  private closeEditor() {
    this.close.emit(true);
  }

  ngOnDestroy(): void {
    if (this.onClickAnywhere) {
      window.removeEventListener('click', this.onClickAnywhere);
    }
  }
}
