import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthError, AuthService, PopupService } from '@morpho/core';
import { environment } from '@morpho/environment';
import { StatusCode } from 'apps/bankangle/src/app/constants/status';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public authService: AuthService,
    private popupService: PopupService,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.apiUrl)) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: any) => {
        switch (error.status) {
          case 401:
            if (!req.url.includes('auth/logged_in/')) {
              this.authService.goToLoginPage();
            } else if (error.error?.redirect) {
              window.location.href = error.error?.redirect;
            }
            break;
          case 403:
            const code = error.error?.code;
            switch (code) {
              case StatusCode.BLOCKED_BY_RATE_LIMIT:
              case StatusCode.UNANNOUNCED:
                window.location.href = window.origin + `${environment.appBaseHref}/status/${code}`.replace('//', '/');
                break;
              case StatusCode.PASSWORD_CHANGE_REQUIRED:
                window.location.href =
                  window.origin +
                  `${environment.appBaseHref}/change-password`.replace('//', '/') +
                  `?redirect=${window.location.href}`;
                break;
              default:
                let message = error.error?.detail;
                if (!message) {
                  message = typeof error.error === 'string' ? error.error : 'Permission Denied';
                }
                this.popupService.error(message);
                break;
            }
            break;
          default:
            break;
        }
        this.authService.notifyError(error);
        return throwError(error);
      }),
    );
  }

  throwConnectionError(): Observable<HttpEvent<any>> {
    const error: AuthError = {
      message: 'No internet connection',
      name: 'no-internet',
      status: 0,
    };
    this.authService.notifyError(error);
    return throwError(new HttpErrorResponse({ error }));
  }
}
