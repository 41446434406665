import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { BACKEND_DATETIME_FORMAT, BACKEND_DATE_FORMAT, UtilService, ValueAndLabel } from '@morpho/core';
import * as moment from 'moment';
import { callableStructureRegexString } from '../constants/inputs';
import { DateInputConfig, DateRangeInputConfig, DatetimeInputConfig, OptionGroup } from '../models/form.model';

@Injectable({
  providedIn: 'root',
})
export class FormUtilService extends UtilService {
  flattenOptions(optionList: ValueAndLabel[] | OptionGroup[]): ValueAndLabel[] {
    if (!optionList) {
      return [];
    }

    const hasNestedOptions = !!(optionList[0] as OptionGroup)?.divider_label;

    if (hasNestedOptions) {
      return (optionList as OptionGroup[]).reduce((acc, val) => {
        if (val.options) {
          acc.push(...val.options);
        }
        return acc;
      }, [] as ValueAndLabel[]);
    }

    return optionList as ValueAndLabel[];
  }

  buildFieldSelectionMap(
    fieldOptions: ValueAndLabel[],
    enabledOptions: (string | number)[],
  ): Record<string | number, boolean> {
    return fieldOptions.reduce(
      (acc, option) => {
        return { ...acc, [option.value as string | number]: enabledOptions.includes(option.value as string | number) };
      },
      {} as Record<string | number, boolean>,
    );
  }

  buildFieldSelectionArray(selectionMap: Record<string | number, boolean>): (string | number)[] {
    return Object.entries(selectionMap).reduce(
      (acc, [optionKey, value]) => {
        if (value) {
          acc.push(optionKey);
        }
        return acc;
      },
      [] as (string | number)[],
    );
  }

  getAttributeFromOptions(
    searchValue: any,
    attributeToSearch: 'label' | 'value',
    options: OptionGroup[] | ValueAndLabel[],
    attributeToReturn?: 'label' | 'value' | null,
  ) {
    options = this.flattenOptions(options as OptionGroup[]);

    const foundOption = options.find(option => {
      const optionValue = option[attributeToSearch];

      if (attributeToSearch === 'label') {
        return this.sanitiseStringValue(searchValue) === this.sanitiseStringValue(optionValue.toString());
      } else {
        return searchValue === optionValue;
      }
    });

    return attributeToReturn && foundOption ? foundOption[attributeToReturn] : foundOption;
  }

  getPropertyFromOptions(
    options: ValueAndLabel[] | OptionGroup[],
    value: string | number,
    propertyToReturn: 'value' | 'label',
  ) {
    if (!options.length) {
      return value;
    }
    // todo: can dry this
    if ('value' in options[0]) {
      const valueAndLabels = options as ValueAndLabel[];
      for (const valueAndLabel of valueAndLabels) {
        if (valueAndLabel.value === value) {
          return valueAndLabel.label;
        }
      }
    } else {
      const optionGroups = options as OptionGroup[];
      for (const optionGroup of optionGroups) {
        for (const valueAndLabel of optionGroup.options) {
          if (valueAndLabel.value === value) {
            return valueAndLabel.label;
          }
        }
      }
    }
    return value;
  }

  isCallableStructureValid(structure: string) {
    if (!RegExp(callableStructureRegexString).test(structure)) {
      return false;
    }

    const convertToMonth = (text: string): number => {
      return !text
        ? 0
        : text.includes('m')
          ? parseFloat(text.replace('m', ''))
          : 12 * parseFloat(text.replace('y', ''));
    };
    structure = structure.toLowerCase().replace('x', '+');
    const split = structure.split('+');
    const part1and2 = split[0].split('nc');

    const part1 = convertToMonth(part1and2[0]);
    const part2 = convertToMonth(part1and2[1]);
    const part3 = convertToMonth(split[1]);

    return (
      part1 <= 1200 && // 100 years
      part2 < part1 &&
      part3 < part1 - part2
    );
  }

  callableStructureValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !control.value || this.isCallableStructureValid(control.value) ? null : { invalid: true };
    };
  }

  stringToMoment(dateString: string, params: Partial<DateInputConfig | DatetimeInputConfig | DateRangeInputConfig>) {
    const format =
      params.element === 'date' || params.element === 'date_range' ? BACKEND_DATE_FORMAT : BACKEND_DATETIME_FORMAT;
    return moment(dateString, format);
  }
}
