<om-date-input
  [disabled]="disabled"
  format="moment"
  [min]="minDate"
  [max]="maxDate"
  [placeholder]="placeholder || fallbackPlacholder"
  [required]="required"
  [(ngModel)]="dateInputMoment"
  (ngModelChange)="onDateInputChange()"
>
</om-date-input>
<div class="om-time-container">
  <input
    matInput
    [disabled]="disabled"
    [required]="required"
    type="time"
    [(ngModel)]="timeString"
    (ngModelChange)="onTimeChange()"
  />
  <mat-select [disabled]="disabled" [(value)]="timezone" (selectionChange)="onTzChange()">
    @for (option of timezones; track option) {
      <mat-option [value]="option.value"> {{ option.label }}</mat-option>
    }
  </mat-select>
</div>
