import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { UtilService } from '@morpho/core';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  standalone: false,
  selector: 'om-grid-search',
  templateUrl: './grid-search.component.html',
  styleUrls: ['./grid-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridSearchComponent implements OnChanges {
  @Input()
  gridReference: AgGridAngular;

  @Input()
  placeholder = 'Filter...';

  @Input()
  filterText: string;

  @Output() filterTextChange = new EventEmitter<string>();

  constructor(private utilService: UtilService) {}

  private applyFilterWithDebounce = this.utilService.debounce(() => {
    if (this.gridReference) {
      this.gridReference.api.updateGridOptions({
        quickFilterText: this.utilService.stripAccentsFromString(this.filterText ?? ''),
      });
      this.filterTextChange.emit(this.filterText);
    }
  }, 300);

  applyFilter() {
    if (!this.gridReference) {
      return;
    }
    this.applyFilterWithDebounce();
  }

  clearForm() {
    this.filterText = '';
    this.applyFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.gridReference?.currentValue) {
      this.applyFilter();
    }
  }
}
