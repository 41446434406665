import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReplaySubject, map, mergeMap } from 'rxjs';
import { ApiService } from '../../../patterns/api/api.service';

@Component({
  standalone: false,
  selector: 'om-document-compare-viewer',
  templateUrl: './document-compare-viewer.component.html',
  styleUrls: ['./document-compare-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentCompareViewerComponent {
  private url$ = new ReplaySubject<string>();

  readonly loadingText = 'Please wait while we compare the documents';

  compareUrl$ = this.url$.pipe(
    mergeMap(url => {
      return this.apiService.getFromExternalAPI(url);
    }),
    map(result => {
      return this.domSanitizer.bypassSecurityTrustResourceUrl(result.url);
    }),
  );

  @Input()
  set url(url: string) {
    this.url$.next(url);
  }

  constructor(
    private apiService: ApiService,
    private domSanitizer: DomSanitizer,
  ) {}
}
