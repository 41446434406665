import { NipMode, RouterSelector } from '@morpho/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sortByAlphabetical } from 'apps/bankangle/src/app/constants/sortComparators';
import { NewIssueCurveType, OVERVIEW_TAB, PricingType } from '../../models/pricing-request.model';
import {
  getAdditionalPricingCurvesByType,
  getBenchmarkBondsForTab,
  getComparablesInformation,
  getDisplayMaturitiesForTab,
  getHistoricalSecondaryBondDates,
  getPricingBasisForTab,
  getSelectedBonds,
  getSelectedHistoricalDatesForIssuer,
} from '../../syndicate-pricer.functions';
import { IssuerPricingDataMap, PricingCompletionState, SharedTabData } from './pricing-completion.model';
import { PRICING_COMPLETION_STATE_KEY } from './pricing-completion.reducer';

const pricingCompletionState = createFeatureSelector<PricingCompletionState>(PRICING_COMPLETION_STATE_KEY);
const routerState = RouterSelector.state;

const getCurrentTabData = (state: PricingCompletionState): IssuerPricingDataMap | undefined =>
  state.pricingTabDataMap?.[state.selectedTab ?? ''];

const getCurrentSharedTabData = (state: PricingCompletionState): SharedTabData | undefined =>
  state.sharedTabDataMap?.[state.selectedTab ?? ''];

export const PricingCompletionSelector = {
  state: pricingCompletionState,

  isIndividualPricing: createSelector(routerState, state => !state.queryParams.group),
  isGroupPricing: createSelector(routerState, state => !!state.queryParams.group),

  mode: createSelector(routerState, state => state?.urlParams?.mode as NipMode),
  isInputMode: createSelector(routerState, state => state.urlParams.mode === NipMode.Input),
  isOverrideMode: createSelector(routerState, state => state.urlParams.mode === NipMode.Override),
  isEditMode: createSelector(
    routerState,
    state => state.urlParams.mode === NipMode.Input || state.urlParams.mode === NipMode.Override,
  ),
  isViewMode: createSelector(routerState, state => state?.urlParams?.mode === NipMode.View),

  isLoading: createSelector(pricingCompletionState, state => !!state.loadingProcesses),
  isExportLoading: createSelector(pricingCompletionState, state => !!state.isExportLoading),

  isSaving: createSelector(pricingCompletionState, state => state.isSaving),
  updatedAt: createSelector(pricingCompletionState, state => {
    return Object.values(getCurrentTabData(state) ?? {})?.[0]?.pricingRequest.pricing.updated_at;
  }),
  updatedBy: createSelector(pricingCompletionState, state => {
    return Object.values(getCurrentTabData(state) ?? {})?.[0]?.pricingRequest.pricing.updated_by;
  }),
  selectedTab: createSelector(pricingCompletionState, state => state.selectedTab),
  isOverviewTab: createSelector(pricingCompletionState, state => state.selectedTab === OVERVIEW_TAB),
  isGraphVisible: createSelector(pricingCompletionState, state => state.isGraphVisible),
  savedViewId: createSelector(pricingCompletionState, state => state.savedViewId),
  pageSettings: createSelector(pricingCompletionState, state => state.pageSettings),
  issuingEntities: createSelector(pricingCompletionState, state => state.issuingEntities),
  govieBonds: createSelector(pricingCompletionState, state => state.govieBonds ?? {}),

  /***********************************************************************************************
   *                                       PRICING INBOX                                         *
   ***********************************************************************************************/

  pricingInboxGroups: createSelector(pricingCompletionState, state => state.pricingInboxGroups),

  /***********************************************************************************************
   *                                       PRICING TAB                                          *
   ***********************************************************************************************/

  comparables: createSelector(pricingCompletionState, state =>
    getComparablesInformation(state.pricingTabDataMap ?? {}),
  ),

  displayMaturities: createSelector(pricingCompletionState, state =>
    state.selectedTab && state.pricingTabDataMap
      ? getDisplayMaturitiesForTab(state.selectedTab, state.pricingTabDataMap ?? {})
      : [],
  ),

  pricingTabDataMap: createSelector(pricingCompletionState, state => state.pricingTabDataMap),
  sharedTabDataMap: createSelector(pricingCompletionState, state => state.sharedTabDataMap),

  currentPricingTabData: createSelector(pricingCompletionState, state => {
    return state.pricingTabDataMap?.[state.selectedTab ?? ''];
  }),
  currentSharedTabData: createSelector(
    pricingCompletionState,
    state => state.sharedTabDataMap?.[state.selectedTab ?? ''],
  ),

  pricingTabs: createSelector(pricingCompletionState, state =>
    Object.keys(state.pricingTabDataMap ?? {}).sort(sortByAlphabetical),
  ),
  tempPricingMap: createSelector(pricingCompletionState, state => getCurrentSharedTabData(state)?.tempPricingMap),

  quoteType: createSelector(
    pricingCompletionState,
    state => Object.values(getCurrentTabData(state) ?? {})?.[0]?.pricingRequest.pricing.quote_type,
  ),
  secondaryBonds: createSelector(pricingCompletionState, state => getCurrentSharedTabData(state)?.secondaryBonds),
  secondaryBondsFilter: createSelector(pricingCompletionState, state => {
    return getCurrentSharedTabData(state)?.combinedFilters;
  }),

  secondaryBondsGrouping: createSelector(pricingCompletionState, state => {
    return getCurrentSharedTabData(state)?.secondaryBondsGrouping;
  }),

  historicalSecondaryBonds: createSelector(
    pricingCompletionState,
    state => getCurrentSharedTabData(state)?.historicalSecondaryBonds,
  ),
  historicalSecondaryBondsDates: createSelector(pricingCompletionState, state =>
    getHistoricalSecondaryBondDates(getCurrentTabData(state) ?? {}),
  ),
  historicalPricingRequestsData: createSelector(
    pricingCompletionState,
    state => getCurrentSharedTabData(state)?.historicalPricingRequestsData,
  ),
  availableHistoricalDates: createSelector(
    pricingCompletionState,
    state => getCurrentSharedTabData(state)?.availableHistoricalDates,
  ),
  historicalBonds: createSelector(pricingCompletionState, state => getCurrentSharedTabData(state)?.historicalBonds),

  selectedHistoricalDates: createSelector(pricingCompletionState, state =>
    getSelectedHistoricalDatesForIssuer(
      getCurrentTabData(state) ?? {},
      getCurrentSharedTabData(state)?.availableHistoricalDates,
    ),
  ),

  selectedPricingTabData: createSelector(pricingCompletionState, state => getCurrentTabData(state)),
  selectedPricingTabBasis: createSelector(pricingCompletionState, state =>
    getPricingBasisForTab(state.pricingTabDataMap?.[state.selectedTab ?? ''] ?? {}),
  ),

  benchmarkBonds: createSelector(pricingCompletionState, state => {
    return getBenchmarkBondsForTab(getCurrentTabData(state) ?? {});
  }),

  pricingType: createSelector(pricingCompletionState, state => {
    const pricingType: PricingType = { ...Object.values(getCurrentTabData(state) ?? {})?.[0]?.pricingRequest };
    return pricingType;
  }),

  swapPricingBases: createSelector(pricingCompletionState, state => {
    return [
      ...new Set(
        getAdditionalPricingCurvesByType(NewIssueCurveType.Swap, getCurrentTabData(state) ?? {}).map(
          curve => curve.value,
        ),
      ),
    ];
  }),

  customCurves: createSelector(pricingCompletionState, state => {
    return getAdditionalPricingCurvesByType(NewIssueCurveType.Custom, getCurrentTabData(state) ?? {});
  }),

  showLatestHistorical: createSelector(pricingCompletionState, state => {
    return Object.values(getCurrentTabData(state) ?? {})?.some(
      issuerPricingData => issuerPricingData.pricingRequest.pricing.show_latest_historical,
    );
  }),
  showSwapReferenceCurves: createSelector(pricingCompletionState, state => {
    return Object.values(getCurrentTabData(state) ?? {})?.[0]?.pricingRequest.pricing.show_references;
  }),

  regression: createSelector(pricingCompletionState, state => getCurrentSharedTabData(state)?.regression),

  pricingBreakdown: createSelector(pricingCompletionState, state => {
    return Object.values(getCurrentTabData(state) ?? {})?.[0]?.pricingRequest.pricing.breakdown;
  }),
  selectedBonds: createSelector(pricingCompletionState, state => getSelectedBonds(getCurrentTabData(state) ?? {})),
  legendSelection: createSelector(pricingCompletionState, state => getCurrentSharedTabData(state)?.legendSelection),
  /***********************************************************************************************
   *                                       SUMMARY TAB                                          *
   ***********************************************************************************************/

  summaryTabSwapBasis: createSelector(pricingCompletionState, state => state.summaryTabSwapBasis),
};
