import { Component, forwardRef, Input } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

@Component({
  standalone: false,
  selector: 'om-button-toggle-input',
  templateUrl: './button-toggle-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => ButtonToggleInputComponent)),
})
export class ButtonToggleInputComponent extends CustomFormFieldControlComponent {
  @Input()
  options: ValueAndLabel[];

  onEnterPressed(event: KeyboardEvent) {}
}
