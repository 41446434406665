import { Pipe, PipeTransform } from '@angular/core';
import { MentionElement } from '@morpho/rich-text';

interface MentionInfo {
  organisation?: string;
  team?: string;
  user?: string;
}

@Pipe({
  standalone: false,
  name: 'mentionsInfo',
})
export class MentionsInfoPipe implements PipeTransform {
  transform(mentionData: MentionElement, isTooltip = false): unknown {
    const mentionInfo = this.getMentionInfo(mentionData);
    let vals: string[] = [];

    if (mentionInfo.user) {
      vals = [mentionInfo.user, mentionInfo.organisation ?? '', mentionInfo.team ?? ''];
    } else {
      vals = [...(mentionInfo.team ? [mentionInfo.team] : []), mentionInfo.organisation ?? ''];
    }

    if (isTooltip) {
      let tooltipString = '';
      vals.forEach(val => {
        tooltipString += `<li>${val}</li>`;
      });
      return `<ul>${tooltipString}</ul>`;
    }

    if (mentionInfo.user) {
      return mentionInfo.user ?? '';
    }
    return mentionInfo.organisation + `${mentionInfo.team ? ' ' + mentionInfo.team : ''}`;
  }

  private getMentionInfo(mentionElement: MentionElement): MentionInfo {
    const org = mentionElement.bank || mentionElement.issuer || mentionElement.law_firm;
    const team = mentionElement.bank_team || mentionElement.issuer_team || mentionElement.law_firm_team;
    const mentionInfo = {
      user: mentionElement.user?.name,
      ...(org ? { organisation: org.name } : {}),
      ...(team ? { team: team.name } : {}),
    };

    return mentionInfo;
  }
}
