@if (data$ | async; as data) {
  @for (src of data; track src) {
    @if (src !== null) {
      <pdf-viewer
        [autoresize]="autoResize"
        [class.fit-to-page]="isFitToPageWidth"
        [ngClass]="cssClasses"
        [fit-to-page]="fitToPage"
        [original-size]="originalSize"
        (page-rendered)="onPageRendered()"
        [render-text]="true"
        [src]="src"
        [zoom]="zoom"
        [zoom-scale]="zoomScale"
        (text-layer-rendered)="onTextLayerRendered($event)"
        (onPinchZoom)="onPinchZoom($event)"
        (pages-initialized)="onPagesInitialized()"
        omPinchZoom
      ></pdf-viewer>
    } @else {
      <h1>Error generating PDF, please refresh or try again later</h1>
    }
  }
  <om-pdf-viewer-controller></om-pdf-viewer-controller>
} @else {
  <om-loading [message]="loadingText || defaultLoadingText"></om-loading>
}
