import { Injectable } from '@angular/core';
import {
  EMPTY_TEXT,
  ElementType,
  MentionElement,
  RichText,
  RichTextBlock,
  RichTextEquation,
  RichTextInline,
  RichTextList,
  RichTextListItem,
  RichTextParagraph,
  RichTextTableRow,
} from '@morpho/rich-text';

@Injectable({
  providedIn: 'root',
})
export class RichTextService {
  sanitiseRichTextInputValue(richtextValue: (RichTextBlock | RichTextParagraph)[]) {
    richtextValue.forEach(val => {
      if (val['children']) {
        val['children'] = this.sanitiseRichTextChildren(val['children']);
      }
      if ((val as RichTextParagraph).indentation === 0) {
        delete (val as RichTextParagraph).indentation;
      }
      if ((val as RichTextParagraph).text_align === 'left') {
        delete (val as RichTextParagraph).text_align;
      }
    });
    return richtextValue;
  }

  private sanitiseRichTextChildren(
    children: RichTextInline[] | (RichTextList | RichTextListItem)[] | RichTextTableRow[],
  ) {
    children = children.map((child: any) => {
      Object.keys(child).forEach(key => {
        if (child[key] === false) {
          delete child[key];
        }
        if (key === 'children') {
          child[key] = this.sanitiseRichTextChildren(child[key]);
        }
      });
      return child;
    });

    return children;
  }

  processRichTextDataForEditor(richText: RichText | RichTextInline[]): any[] {
    return richText.map(richTextBlock => {
      if (
        (richTextBlock as RichTextEquation).type === ElementType.Equation ||
        (richTextBlock as MentionElement).type === ElementType.Mention
      ) {
        return {
          ...richTextBlock,
          children: [EMPTY_TEXT],
        };
      }
      return {
        ...richTextBlock,
        ...((richTextBlock as any).children
          ? { children: this.processRichTextDataForEditor((richTextBlock as any).children) }
          : {}),
      };
    });
  }

  processRichTextDataForForm(richText: RichText | RichTextInline[]): any[] {
    return richText.map(richTextBlock => {
      if ((richTextBlock as RichTextEquation).type === ElementType.Equation) {
        //* If Equation - and has children - remove the children
        const richTextEquation = JSON.parse(JSON.stringify(richTextBlock));
        if (richTextEquation.children) {
          delete richTextEquation.children;
        }

        return richTextEquation;
      }
      return {
        ...richTextBlock,
        ...((richTextBlock as any).children
          ? { children: this.processRichTextDataForForm((richTextBlock as any).children) }
          : {}),
      };
    });
  }

  richTextToSimpleText(content: RichText): string {
    return this.richTextExtractText(content).trim();
  }

  private richTextExtractText(content: RichText | RichTextBlock, type?: string): string {
    let text = '';
    if (Array.isArray(content)) {
      content.forEach(richTextBlock => {
        text += this.richTextExtractText(richTextBlock);
      });
      if (type === 'paragraph' && text.length) {
        text = `${text.trim()}\n`;
      }
    } else {
      Object.entries(content).forEach(([key, value]) => {
        if (key === 'text') {
          text += value;
        }
        if (key === 'children') {
          text += this.richTextExtractText(value, content.type);
        }
      });
    }
    return text.toLowerCase();
  }
}
