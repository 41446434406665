import { SidebarType, ValueAndLabel } from '@morpho/core';
import { OptionGroup } from '@morpho/form';
import { CommentUsersData } from '@morpho/rich-text';
import { createAction, props } from '@ngrx/store';
import { TradeActivity, TradeWorkingGroup } from '../models';
import { TradeBlotterTrade } from '../models/trade-blotter-trade';
import {
  ActiveTradeState,
  TradeDownloadOption,
  UploadedTradeDocument,
  WorkingGroupAddParams,
  WorkingGroupEditParams,
} from './trades.model';

const GET_TRADES = '[Trades] Get Trades';
const GET_TRADES_SUCCESS = `${GET_TRADES} SUCCESS`;
const GET_TRADES_FAIL = `${GET_TRADES} FAIL`;

const GET_TRADE_ACTIVITIES = '[Trade] Get Activities';
const GET_TRADE_ACTIVITIES_SUCCESS = `${GET_TRADE_ACTIVITIES} SUCCESS`;
const GET_TRADE_ACTIVITIES_FAIL = `${GET_TRADE_ACTIVITIES} FAIL`;

const GET_TRADE_DETAILS = '[Trade] Get Details';
const GET_TRADE_DETAILS_SUCCESS = `${GET_TRADE_DETAILS} SUCCESS`;
const GET_TRADE_DETAILS_FAIL = `${GET_TRADE_DETAILS} FAIL`;

const GET_TRADE_DOCUMENT_COMPLETION = '[Trade] Get Documentation Percentage Complete';
const GET_TRADE_DOCUMENT_COMPLETION_SUCCESS = `${GET_TRADE_DOCUMENT_COMPLETION} SUCCESS`;

const SET_TRADE_DOCUMENT_COMPLETION = '[Trade] Set Documentation Percentage Complete';
const SET_TRADE_DOCUMENT_COMPLETION_SUCCESS = `${SET_TRADE_DOCUMENT_COMPLETION} SUCCESS`;

const GET_TRADE_PERMISSIONS = '[Trade] Get Permissions';
const GET_TRADE_PERMISSIONS_SUCCESS = `${GET_TRADE_PERMISSIONS} SUCCESS`;
const GET_TRADE_PERMISSIONS_FAIL = `${GET_TRADE_PERMISSIONS} FAIL`;

const GET_TRADE_DOWNLOAD_OPTIONS = '[Trade] Get Download Options';
const GET_TRADE_DOWNLOAD_OPTIONS_SUCCESS = '[Trade] Get Download Options Success';
const GET_TRADE_DOWNLOAD_OPTIONS_FAIL = '[Trade] Get Download Options Fail';

const GET_TRADE_REPORT_OPTIONS = '[Trade] Get Report Options';
const GET_TRADE_REPORT_OPTIONS_SUCCESS = '[Trade] Get Report Options Success';

const GET_TRADE_STAGES = '[Trade] Get Stages';
const GET_TRADE_STAGES_SUCCESS = `${GET_TRADE_STAGES} SUCCESS`;
const GET_TRADE_STAGES_FAIL = `${GET_TRADE_STAGES} FAIL`;

const GET_UPLOADED_TRADE_DOCUMENTS = '[Trade] Get Uploaded Trade Documents';
const GET_UPLOADED_TRADE_DOCUMENTS_SUCCESS = `${GET_UPLOADED_TRADE_DOCUMENTS} SUCCESS`;
const GET_UPLOADED_TRADE_DOCUMENTS_FAIL = `${GET_UPLOADED_TRADE_DOCUMENTS} FAIL`;

const SEND_MESSAGE_OPTIMISTIC = '[Trade] Send Message OPTIMISTIC';
const SEND_MESSAGE_UNDO = `${SEND_MESSAGE_OPTIMISTIC} UNDO`;

const MOVE_TRADE_OPTIMISTIC = `[Trade] Move Trade OPTIMISTIC`;
const MOVE_TRADE_SUCCESS = `${MOVE_TRADE_OPTIMISTIC} SUCCESS`;
const MOVE_TRADE_UNDO = `${MOVE_TRADE_OPTIMISTIC} UNDO`;

const GET_TRADE_WORKING_GROUPS = '[Trade] Get Working Groups';
const GET_TRADE_WORKING_GROUPS_SUCCESS = `${GET_TRADE_WORKING_GROUPS} SUCCESS`;
const GET_TRADE_WORKING_GROUPS_FAIL = `${GET_TRADE_WORKING_GROUPS} FAIL`;

const GET_TRADE_COMMENT_USERS = '[Trade] Get Trade Comment Users';
const GET_TRADE_COMMENT_USERS_SUCCESS = `${GET_TRADE_COMMENT_USERS} SUCCESS`;
const GET_TRADE_COMMENT_USERS_FAIL = `${GET_TRADE_COMMENT_USERS} FAIL`;

const ADD_TRADE_WORKING_GROUP = '[Trade] Add Working Group';
const ADD_TRADE_WORKING_GROUP_SUCCESS = `${ADD_TRADE_WORKING_GROUP} SUCCESS`;
const ADD_TRADE_WORKING_GROUP_FAIL = `${ADD_TRADE_WORKING_GROUP} FAIL`;

const ADD_TRADE_WORKING_GROUP_USERS = '[Trade] Add Working Group Users';
const ADD_TRADE_WORKING_GROUP_USERS_SUCCESS = `${ADD_TRADE_WORKING_GROUP_USERS} SUCCESS`;
const ADD_TRADE_WORKING_GROUP_USERS_FAIL = `${ADD_TRADE_WORKING_GROUP_USERS} FAIL`;

const DELETE_TRADE_WORKING_GROUP_USERS = '[Trade] Delete Working Group Users';
const DELETE_TRADE_WORKING_GROUP_USERS_SUCCESS = `${DELETE_TRADE_WORKING_GROUP_USERS} SUCCESS`;
const DELETE_TRADE_WORKING_GROUP_USERS_FAIL = `${DELETE_TRADE_WORKING_GROUP_USERS} FAIL`;

const TOGGLE_TIMELINE_SIDEBAR = `[Trades] Toggle Timeline Sidebar`;

const RESET_ACTIVE_TRADE = `[Trades] Reset Active Trade`;

export const TradesEffect = {
  ADD_TRADE_WORKING_GROUP,
  ADD_TRADE_WORKING_GROUP_USERS,
  DELETE_TRADE_WORKING_GROUP_USERS,
  GET_TRADES,
  GET_TRADE_ACTIVITIES,
  GET_TRADE_DETAILS,
  GET_TRADE_DOCUMENT_COMPLETION,
  GET_TRADE_DOWNLOAD_OPTIONS,
  GET_TRADE_PERMISSIONS,
  GET_TRADE_STAGES,
  GET_TRADE_WORKING_GROUPS,
  GET_TRADE_COMMENT_USERS,
  GET_TRADE_REPORT_OPTIONS,
  MOVE_TRADE_OPTIMISTIC,
  SET_TRADE_DOCUMENT_COMPLETION,
  GET_UPLOADED_TRADE_DOCUMENTS,
};

export const TradesAction = {
  getTradeDownloadOptions: createAction(GET_TRADE_DOWNLOAD_OPTIONS, props<{ params: { tradeId: number } }>()),
  getTradeDownloadOptionsSuccess: createAction(
    GET_TRADE_DOWNLOAD_OPTIONS_SUCCESS,
    props<{ params: { tradeId: number; downloadOptions: TradeDownloadOption[] } }>(),
  ),
  getTradeDownloadOptionsFail: createAction(GET_TRADE_DOWNLOAD_OPTIONS_FAIL),

  getTradeReportOptions: createAction(GET_TRADE_REPORT_OPTIONS),
  getTradeReportOptionsSuccess: createAction(
    GET_TRADE_REPORT_OPTIONS_SUCCESS,
    props<{ params: { reportOptions: ValueAndLabel[] } }>(),
  ),

  getTradeActivities: createAction(GET_TRADE_ACTIVITIES, props<{ params: { id: number } }>()),
  getTradeActivitiesSuccess: createAction(
    GET_TRADE_ACTIVITIES_SUCCESS,
    props<{ params: { id: number; activities: ActiveTradeState['activities'] } }>(),
  ),
  getTradeActivitiesFail: createAction(GET_TRADE_ACTIVITIES_FAIL),

  getTradeDetails: createAction(GET_TRADE_DETAILS, props<{ params: { id: number } }>()),
  getTradeDetailsSuccess: createAction(
    GET_TRADE_DETAILS_SUCCESS,
    props<{
      params: {
        id: number;
        details: ActiveTradeState['details'];
        shortDescription: ActiveTradeState['shortDescription'];
      };
    }>(),
  ),
  getTradeDetailsFail: createAction(GET_TRADE_DETAILS_FAIL),

  getTradeDocumentCompletion: createAction(GET_TRADE_DOCUMENT_COMPLETION, props<{ params: { version: number } }>()),
  getTradeDocumentCompletionSuccess: createAction(
    GET_TRADE_DOCUMENT_COMPLETION_SUCCESS,
    props<{ params: { complete: number | null } }>(),
  ),
  setTradeDocumentCompletion: createAction(
    SET_TRADE_DOCUMENT_COMPLETION,
    props<{ params: { version: number; value: number } }>(),
  ),
  setTradeDocumentCompletionSuccess: createAction(
    SET_TRADE_DOCUMENT_COMPLETION_SUCCESS,
    props<{ params: { percentageCompleted: number | null } }>(),
  ),

  getTradePermissions: createAction(GET_TRADE_PERMISSIONS, props<{ params: { id: number } }>()),
  getTradePermissionsSuccess: createAction(
    GET_TRADE_PERMISSIONS_SUCCESS,
    props<{ params: { id: number; permissions: ActiveTradeState['permissions'] } }>(),
  ),
  getTradePermissionsFail: createAction(GET_TRADE_PERMISSIONS_FAIL),

  getTradeStages: createAction(GET_TRADE_STAGES, props<{ params: { id: number } }>()),
  getTradeStagesSuccess: createAction(
    GET_TRADE_STAGES_SUCCESS,
    props<{ params: { id: number; stages: ActiveTradeState['stages'] } }>(),
  ),
  getTradeStagesFail: createAction(GET_TRADE_STAGES_FAIL),

  getUploadedTradeDocuments: createAction(GET_UPLOADED_TRADE_DOCUMENTS, props<{ params: { id: number } }>()),
  getUploadedTradeDocumentsSuccess: createAction(
    GET_UPLOADED_TRADE_DOCUMENTS_SUCCESS,
    props<{ params: { id: number; uploadedTradeDocuments: UploadedTradeDocument[] } }>(),
  ),
  getUploadedTradeDocumentsFail: createAction(GET_UPLOADED_TRADE_DOCUMENTS_FAIL),

  moveTradeOptimistic: createAction(MOVE_TRADE_OPTIMISTIC, props<{ params: TradeBlotterTrade }>()),
  moveTradeSuccess: createAction(MOVE_TRADE_SUCCESS, props<{ params: TradeBlotterTrade }>()),
  moveTradeUndo: createAction(MOVE_TRADE_UNDO, props<{ params: TradeBlotterTrade }>()),

  sendMessageOptimistic: createAction(SEND_MESSAGE_OPTIMISTIC, props<{ params: TradeActivity }>()),
  sendMessageUndo: createAction(SEND_MESSAGE_UNDO),

  getTradeWorkingGroups: createAction(GET_TRADE_WORKING_GROUPS, props<{ params: { id: number } }>()),

  getTradeWorkingGroupsSuccess: createAction(
    GET_TRADE_WORKING_GROUPS_SUCCESS,
    props<{
      params: { id: number; workingGroups: ActiveTradeState['workingGroups'] };
    }>(),
  ),
  getTradeWorkingGroupsFail: createAction(GET_TRADE_WORKING_GROUPS_FAIL, props<{ workingGroupErrors: any }>()),

  getTradeCommentUsers: createAction(GET_TRADE_COMMENT_USERS, props<{ params: { id: number } }>()),
  getTradeCommentUsersSuccess: createAction(
    GET_TRADE_COMMENT_USERS_SUCCESS,
    props<{
      params: { commentUsers: CommentUsersData[]; commentUsersOptions: OptionGroup[] };
    }>(),
  ),
  getTradeCommentUsersFail: createAction(GET_TRADE_COMMENT_USERS_FAIL, props<{ tradeCommentsUsersErrors: any }>()),

  addTradeWorkingGroup: createAction(ADD_TRADE_WORKING_GROUP, props<{ params: WorkingGroupAddParams }>()),
  addTradeWorkingGroupSuccess: createAction(
    ADD_TRADE_WORKING_GROUP_SUCCESS,
    props<{ params: { tradeId: number; workingGroup: TradeWorkingGroup } }>(),
  ),
  addTradeWorkingGroupFail: createAction(ADD_TRADE_WORKING_GROUP_FAIL),

  addWorkingGroupUsers: createAction(ADD_TRADE_WORKING_GROUP_USERS, props<{ params: WorkingGroupEditParams }>()),
  addWorkingGroupUsersSuccess: createAction(
    ADD_TRADE_WORKING_GROUP_USERS_SUCCESS,
    props<{ params: { tradeId: number; workingGroupId: number; workingGroup: TradeWorkingGroup } }>(),
  ),
  addWorkingGroupUsersFail: createAction(ADD_TRADE_WORKING_GROUP_USERS_FAIL),

  deleteWorkingGroupUsers: createAction(DELETE_TRADE_WORKING_GROUP_USERS, props<{ params: WorkingGroupEditParams }>()),
  deleteWorkingGroupUsersSuccess: createAction(
    DELETE_TRADE_WORKING_GROUP_USERS_SUCCESS,
    props<{ params: { tradeId: number; workingGroupId: number; workingGroup: TradeWorkingGroup } }>(),
  ),
  deleteWorkingGroupUsersFail: createAction(DELETE_TRADE_WORKING_GROUP_USERS_FAIL, props<{ params: { error: any } }>()),

  toggleTimelineSidebar: createAction(TOGGLE_TIMELINE_SIDEBAR, props<{ params: SidebarType | null }>()),

  resetActiveTrade: createAction(RESET_ACTIVE_TRADE),
};
