@if (config) {
  <mat-card appearance="outlined">
    <mat-card-header>
      <img mat-card-avatar [src]="image" />
      <mat-card-title>{{ actor }}</mat-card-title>
      <mat-card-subtitle>{{ date }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <em>
        @if (config.type === activityCardType.COMMENT) {
          <p [ngClass]="{ 'has-content': config?.field_name | isContentTag }">
            <span #titleClamp class="content-title-clamp" [style.--max-lines]="2"
              >{{ config.title }} <span class="content-label">{{ config.field_label }}</span></span
            >
          </p>
        } @else {
          <p>
            {{ config.title }}
          </p>
        }
      </em>
      @switch (config.type) {
        @case (activityCardType.FUTURE_EVENT) {
          <ul>
            @if (config.context_string) {
              <li>
                <span>{{ config.context_string }}: </span>
                <em>
                  @if (config.secondary_datetime) {
                    <ng-container>{{ config.secondary_datetime | dateToDisplayFormat: 'datetime' }}</ng-container>
                  } @else {
                    <ng-container>{{ config.secondary_datetime_text }}</ng-container>
                  }
                </em>
              </li>
            }

            @if (config.info) {
              <li>"{{ config.info }}"</li>
            }
          </ul>
        }
        @case (activityCardType.INFO) {
          <ul>
            @if (config.info) {
              <li>{{ config.info }}</li>
            }
            @if (config.message) {
              <li>"{{ config.message }}"</li>
            }
          </ul>
        }
        @case (activityCardType.UPDATE) {
          <ul>
            @for (update of config.updates; track update) {
              <li>
                @if (update.field_name | isContentTag) {
                  <div class="has-content">
                    <span #titleClamp class="content-title-clamp content-label" [style.--max-lines]="1">{{
                      update.old_value
                    }}</span>
                  </div>
                } @else {
                  <ng-container> {{ update.field_label }} </ng-container>
                }
                @switch (update.element) {
                  @case ('data_table') {
                    <em>updated</em>
                  }
                  @case ('richtext') {
                    <em>set to:</em>
                    <om-activity-card-collapse-wrapper [previewHeight]="previewHeight">
                      <om-richtext-value-viewer [value]="update.new_value"></om-richtext-value-viewer>
                    </om-activity-card-collapse-wrapper>
                  }
                  @default {
                    @if (update.field_name | isContentTag) {
                      <om-activity-card-collapse-wrapper [previewHeight]="previewHeight">
                        <em>set to: </em>
                        <span>"{{ update.new_value | valueToDisplayFormat: update.type }}"</span>
                      </om-activity-card-collapse-wrapper>
                    } @else {
                      <em>set to: </em>
                      {{ update.new_value | valueToDisplayFormat: update.type }}
                    }
                  }
                }
              </li>
            }
          </ul>
        }
        @case (activityCardType.COMMENT) {
          @if (config.comment) {
            <ul>
              <li>
                <om-activity-card-collapse-wrapper [previewHeight]="previewHeight">
                  <om-richtext-value-viewer
                    [value]="config.comment"
                    [editorType]="EditorType.Mentions"
                  ></om-richtext-value-viewer>
                </om-activity-card-collapse-wrapper>
              </li>
            </ul>
          }
          @if (config.suggestion) {
            <ul>
              @switch (config.suggestion | elementTypeFromValue) {
                @case (elementValueType.Richtext) {
                  <li>
                    <om-activity-card-collapse-wrapper [previewHeight]="previewHeight">
                      <om-richtext-value-viewer [value]="config.suggestion"></om-richtext-value-viewer>
                    </om-activity-card-collapse-wrapper>
                  </li>
                }
                @default {
                  <li>
                    @if (config.field_name ?? '' | isContentTag) {
                      <om-activity-card-collapse-wrapper [previewHeight]="previewHeight">
                        <span>"{{ config.suggestion }}"</span>
                      </om-activity-card-collapse-wrapper>
                    } @else {
                      <span>{{ config.suggestion }}</span>
                    }
                  </li>
                }
              }
            </ul>
          }
        }
        @case (activityCardType.LIST) {
          @if (config.list) {
            <ul>
              @for (item of config.list; track item) {
                <li>{{ item }}</li>
              }
            </ul>
          }
        }
        @case (activityCardType.REDIRECT) {
          <ul class="is-redirect">
            @for (item of config.redirect; track item) {
              <li>
                <a
                  mat-button
                  class="is-text"
                  [class.visited]="clickedLinks[item.url]"
                  (click)="markAsClicked(item.url)"
                  [routerLink]="item.url | routing: 'routerLink'"
                  [queryParams]="item.url | routing: 'queryParams'"
                  >{{ item.name }}
                </a>
              </li>
            }
          </ul>
        }
      }
    </mat-card-content>
  </mat-card>
}
