import { ChartConfigOptions } from '@shared-echarts/models';
import { PricerSwapDetails } from '../core/patterns/pricing/pricing.model';

export enum SavedViewIdentifier {
  CURVE_ANALYSIS = 'curveAnalysis',
  BULLET_LEVELS = 'issuerGrid',
  CALLABLE_LEVELS = 'callables',
  CUSTOM_BULLET_LEVELS = 'customIssuerCurves',
  SYNDICATE_PRICER = 'syndicatePricerConfig',
}

export interface SavedViewColumn {
  columnId: string;
  pinned?: 'left' | 'right' | null;
  visible?: boolean;
  width?: number;
  filterValues?: (string | number)[];
  sort?: 'asc' | 'desc';
  sortOrder?: number;
}

export interface SavedViewBasicData {
  search?: { [key: string]: any };
  columns?: SavedViewColumn[];
  selectedRows?: string[];
  unselectedRows?: string[];
  selectedDetailRows?: Record<string, boolean>;
  pricingDetails?: PricerSwapDetails;
  quickFilter?: string;
  chartConfig?: ChartConfigOptions;
  pageSettings?: any;
}

export interface SavedViewData extends SavedViewBasicData {
  privatePlacements?: SavedViewBasicData;
  secondaryScreener?: SavedViewBasicData;
  repackScreener?: SavedViewBasicData;
}

export interface SavedView {
  filters_preferences?: any; // todo remove once fully migrated
  id?: number;
  identifier: SavedViewIdentifier;
  name?: string;
  data: SavedViewData;
  is_deletable?: boolean;
  provide_alerts?: boolean;
  date_created?: string;
  shared_by?: null;
}
