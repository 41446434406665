<ng-template #unconfirmedDevices>
  @if (hasUnconfirmedDevices) {
    <article>
      <section>The following devices have been added but not yet confirmed:</section>
      @for (device of devices; track device) {
        @if (!device.confirmed) {
          <section>
            <strong>{{ device.name }}</strong>
            <button mat-button class="is-strong is-accent" (click)="onDeviceSetup(device)">Confirm</button>
            <button mat-button class="is-warning" (click)="onRemoveDevice(device.id)">Remove</button>
          </section>
        }
      }
    </article>
  }
</ng-template>

<ng-template #newDeviceTemplate>
  <section class="new-device-section">
    To setup a new device please give it a name then click generate and follow the instructions
  </section>
  <form>
    <mat-form-field>
      <mat-label>New device</mat-label>
      <input
        matInput
        required
        hideRequiredMarker
        autocomplete="off"
        placeholder="Device Name"
        [formControl]="newDeviceControl"
      />
    </mat-form-field>
    <button mat-button class="is-strong is-accent" (click)="onCreateDevice()">Generate</button>
  </form>
</ng-template>

<h2 mat-dialog-title>
  Two-Factor Authentication
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h2>
@if (!isLoading) {
  <div mat-dialog-content>
    <!-- The good -->
    @switch (state) {
      @case (TwoFactorAuthenticationState.Enable) {
        <h3 class="is-accent">Two-Factor Authentication is enabled!</h3>
        <article>
          <section>The following devices are activated:</section>
          @for (device of devices; track device) {
            @if (device.confirmed) {
              <section>
                <strong>{{ device.name }}</strong>
                <button mat-button class="is-warning" (click)="onRemoveDevice(device.id)">Remove</button>
              </section>
            }
          }
        </article>
        <ng-container *ngTemplateOutlet="unconfirmedDevices"></ng-container>
        <ng-container *ngTemplateOutlet="newDeviceTemplate"></ng-container>
      }
      <!-- The bad -->
      @case (TwoFactorAuthenticationState.Disable) {
        <h3 class="is-warning">
          Two-Factor Authentication <br />
          is not yet enabled.
        </h3>
        <ng-container *ngTemplateOutlet="unconfirmedDevices"></ng-container>
        <ng-container *ngTemplateOutlet="newDeviceTemplate"></ng-container>
      }
      <!-- The ugly -->
      @case (TwoFactorAuthenticationState.Setup) {
        <section>
          Scan the QR code below in your authenticator app of choice and enter the code displayed to enable Two-Factor
          Authentication.
        </section>
        <form>
          <mat-form-field>
            <mat-label>Security Code</mat-label>
            <input
              matInput
              required
              hideRequiredMarker
              autocomplete="off"
              [formControl]="otp"
              placeholder="Security Code"
            />
          </mat-form-field>
          <button mat-button class="is-strong is-accent" (click)="enableDevice()">Enable</button>
        </form>
        @if (newDevice.url) {
          <qrcode [qrdata]="newDevice.url" [width]="256"></qrcode>
        }
        <section>
          Having trouble scanning the QR code?
          <button mat-button class="is-text is-accent" (click)="onCopyQRCode()">
            <span>Click here to copy to clipboard</span><mat-icon svgIcon="copy_to_clipboard"></mat-icon>
          </button>
        </section>
      }
    }
  </div>
} @else {
  <mat-spinner></mat-spinner>
}
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
</div>
