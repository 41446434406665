import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { PocSettings } from 'apps/bankangle/src/app/models/poc';
import { map, mergeMap } from 'rxjs/operators';
import { CoreApiService } from '../../services/core-api.service';
import { UserLogicService } from '../../services/user-logic.service';
import { AuthenticatedUser, AuthenticatedUserState } from '../authenticated-user/authenticated-user.model';
import { PermissionAction, PermissionEffect } from './permission.actions';
import { PermissionSelector } from './permission.selectors';

@Injectable()
export class PermissionEffects {
  getTradeShortcuts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionEffect.GET_TRADE_SHORTCUTS),
      mergeMap(() =>
        this.coreApiService
          .getTradeShortcuts()
          .pipe(map(response => PermissionAction.getTradeShortcutsSuccess({ params: response }))),
      ),
    ),
  );

  getPocSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionEffect.GET_POC_SETTINGS),
      mergeMap(() =>
        this.coreApiService
          .getPocSettings()
          .pipe(map(response => PermissionAction.getPocSettingsSuccess({ params: response }))),
      ),
    ),
  );

  calculatePermissions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PermissionEffect.CALCULATE_PERMISSIONS),
      concatLatestFrom((action: { type: string; params: { authenticatedUser: AuthenticatedUserState } }) =>
        this.store.select(PermissionSelector.pocSettings),
      ),
      map(([action, pocSettings]) => {
        const permissions = this.userLogicService.calculatePermissions(
          action.params.authenticatedUser as AuthenticatedUser,
          pocSettings as PocSettings,
        );

        return PermissionAction.setCalculatedPermissions({ params: permissions });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private coreApiService: CoreApiService,
    private store: Store<any>,
    private userLogicService: UserLogicService,
  ) {}
}
