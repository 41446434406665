import { Injectable } from '@angular/core';
import { ApiService, getCdnUrl } from '@morpho/core';
import { Store } from '@ngrx/store';
import { AnalyticInfo } from 'apps/bankangle/src/app/models';
import { ActivityData } from 'apps/bankangle/src/app/models/activity-monitoring';
import { CurveType, CurvesRequestOptions, CurvesResponse } from 'apps/bankangle/src/app/models/curve.model';
import { PocSettings } from 'apps/bankangle/src/app/models/poc';
import { SavedView, SavedViewIdentifier } from 'apps/bankangle/src/app/models/saved-view';
import { BackendSearchResult, SearchItem, SearchResult } from 'apps/bankangle/src/app/models/search-results';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DealerColleaguesResponse } from '../../features/dealers/model/dealers.model';
import { TradeShortcut } from '../../models/trade';
import { AuthenticatedUserAction } from '../state/authenticated-user/authenticated-user.actions';
import { AuthenticatedUser } from '../state/authenticated-user/authenticated-user.model';

@Injectable({
  providedIn: 'root',
})
export class CoreApiService {
  constructor(
    private apiService: ApiService,
    private store: Store<any>,
  ) {}

  getAnalyticInfo(): Observable<AnalyticInfo> {
    const endpoint = 'analytic_info/';
    return this.apiService.get(endpoint);
  }

  getConstants(): Observable<any> {
    const endpoint = 'constants/';
    return this.apiService.get(endpoint);
  }

  getCurveHistoryForExport(identifier: string, body: any): Observable<any> {
    const endpoint = `levels/i/${identifier}/history/`;
    const options = { responseType: 'blob' };
    return this.apiService.post(endpoint, body, options);
  }

  getDocumentForExport(body: any): Observable<any> {
    const payload = {
      filename: body.filename,
      sheets: [
        {
          tables: [
            {
              headers: body.headers,
              data: body.data,
              mapping: body.mapping,
            },
          ],
        },
      ],
    };
    const endpoint = `export/${body.format}/`;
    const options = { responseType: 'blob' };
    return this.apiService.post(endpoint, payload, options);
  }

  getPocSettings(): Observable<PocSettings> {
    const endpoint = 'poc-settings/';
    return this.apiService.get(endpoint);
  }

  getTradeShortcuts(): Observable<TradeShortcut[]> {
    const endpoint = 'transactions/shortcuts/';
    return this.apiService.get(endpoint);
  }

  getAuthenticatedUser(): Observable<AuthenticatedUser> {
    const endpoint = 'user_info/';
    return this.apiService.get(endpoint).pipe(
      map(user => {
        user.isStaff = user.is_staff;
        user.isDealer = user.bank_id !== null;
        user.isIssuer = user.issuer_id !== null;
        user.isLawyer = user.law_firm_id !== null;
        user.fullName = `${user.first_name} ${user.last_name}`;
        user.companyUrl = user.isDealer
          ? `/dealers/${user.bank_id}`
          : user.isIssuer
            ? `/issuers/${user.issuer_id}`
            : undefined;

        if (user.isDealer) {
          this.store.dispatch(AuthenticatedUserAction.getFavouriteIssuers());
        }

        return user;
      }),
    );
  }

  getCurves(options: CurvesRequestOptions): Observable<CurvesResponse> {
    const endpoint = 'levels/';

    const params = {
      ...(options.curveTypes?.includes(CurveType.ALL) ? {} : { source: options.curveTypes }),
      ...(options.issuerIds?.length ? { issuer_id: options.issuerIds } : {}),
      ...(options.includeEmpty ? { include_empty: true } : {}),
    };
    return this.apiService.get(endpoint, params).pipe(
      map((response: CurvesResponse) => {
        // todo this is a new api so we shouldn't need to map anything
        response.curves.map((curve: any) => {
          if (!curve.levels) {
            curve.levels = {};
          }
          curve.documentations = curve.documentations.map((documentation: string) => documentation.toUpperCase());
          return curve;
        });
        return response;
      }),
    );
  }

  getDealerColleagues(savedViewId: number): Observable<DealerColleaguesResponse[][]> {
    const endpoint = `bank/customfilters/${savedViewId}/colleagues/`;
    return this.apiService.get(endpoint);
  }

  shareSavedView(id: number, users: number[]): Observable<any> {
    const endpoint = `bank/customfilters/${id}/share/`;
    const body = {
      id,
      user_ids: users,
    };
    return this.apiService.post(endpoint, body);
  }

  getSavedViews(identifier: SavedViewIdentifier): Observable<SavedView[]> {
    const endpoint = `bank/customfilters/`;
    const body = {
      identifier,
    };
    return this.apiService.get(endpoint, body).pipe(
      map(views => {
        // todo remove this map after BE migration to change saved views
        views = views.map((view: SavedView) => {
          if (!view.data) {
            (view as any).data = {};
            return view;
          }
          if ((view.data as any)?.pricing && !view.data.pricingDetails?.fundingBasis) {
            view.data.pricingDetails = {
              fundingBasis: (view.data as any).pricing,
            };
          }
          for (const key of ['privatePlacements', 'secondaryScreener', 'repackScreener']) {
            if ((view.data as any)[key]?.pricing && !(view.data as any)[key].pricingDetails?.fundingBasis) {
              (view.data as any)[key].pricingDetails = {
                fundingBasis: (view.data as any)[key].pricing,
              };
            }
          }
          return view;
        });
        return views;
      }),
    );
  }

  addSavedView(savedView: SavedView): Observable<SavedView> {
    const endpoint = `bank/customfilters/`;
    return this.apiService.post(endpoint, savedView);
  }

  updateSavedView(savedView: SavedView): Observable<SavedView> {
    const endpoint = `bank/customfilters/${savedView.id}/`;
    return this.apiService.patch(endpoint, savedView);
  }

  deleteSavedView(savedViewId: number): Observable<void> {
    const endpoint = `bank/customfilters/${savedViewId}/`;
    return this.apiService.delete(endpoint);
  }

  monitorActivity(activityName: string, data?: ActivityData): Observable<any> {
    return this.apiService.post(`monitoring/checkpoints/${activityName}/`, data).pipe(catchError(() => of(undefined)));
  }

  search(query: string): Observable<SearchResult[]> {
    return this.apiService.get('search/', { q: query }).pipe(
      map((backEndSearchResults: BackendSearchResult) => {
        const results: SearchResult[] = [];

        const mapper = (searchItem: SearchItem) => {
          const flagUrlSplit = searchItem.flag_url ? searchItem.flag_url.split('/') : '';
          return {
            ...searchItem,
            subtitle: searchItem.documentations?.join(', '),
            flag_url: flagUrlSplit
              ? getCdnUrl(`assets/img/flags/${flagUrlSplit[flagUrlSplit.length - 1].replace('.gif', '.svg')}`)
              : '',
          };
        };

        if (backEndSearchResults.issuers.length) {
          const title = 'Issuers (' + backEndSearchResults.issuers.length.toString() + ')';
          results.push({ title, entities: backEndSearchResults.issuers.map(mapper) });
        }
        if (backEndSearchResults.dealers.length) {
          const title = 'Dealers (' + backEndSearchResults.dealers.length.toString() + ')';
          results.push({ title, entities: backEndSearchResults.dealers.map(mapper) });
        }

        return results;
      }),
    );
  }
}
