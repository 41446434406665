import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ValueAndLabel } from '@morpho/core';
import { AgGridSidebarComponentBase } from '../../ag-grid-panels-base';
import { SelectFilterConfig, SelectFilterOption } from '../../models/ag-grid-panel.model';

@Component({
  standalone: false,
  selector: 'om-select-single-filter',
  templateUrl: './select-single-filter.component.html',
  styleUrls: ['./select-single-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectSingleFilterComponent extends AgGridSidebarComponentBase implements OnChanges {
  @Input() columnConfig: SelectFilterConfig;
  @Input() model: Record<string, any>;
  @Output() sendfilters = new EventEmitter<{ id: string; label: string; filters: any }>();

  selectedOption: string;
  chipDisplay: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.model) {
      return;
    }
    if (changes.model) {
      if (this.model[this.columnConfig.id]) {
        const selectedOption = this.columnConfig.options?.find((option: ValueAndLabel) => {
          return option.value === this.model[this.columnConfig.id];
        });
        if (selectedOption) {
          selectedOption.selected = true;
          this.updateOption(selectedOption);
        }
      }
    }
  }

  onChange(option: SelectFilterOption) {
    this.updateOption(option);
  }

  updateOption(option: SelectFilterOption) {
    this.selectedOption = <string>option.value;
    this.chipDisplay = option.label;
    this.sendfilters.emit({
      id: this.columnConfig.id,
      label: this.columnConfig.label ?? '',
      filters: this.selectedOption,
    });
  }

  reset() {
    const option = this.columnConfig.options?.find(option => option.value === this.columnConfig.default);
    if (option) {
      this.updateOption(option);
    }
  }
}
