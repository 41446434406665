import { Component, ElementRef, forwardRef, HostListener, Input, ViewChild } from '@angular/core';
import { ctrlKey, KeyCode, ONE_BILLION, ONE_MILLION, ONE_QUADRILLION, ONE_THOUSAND } from '@morpho/core';
import { PrimitiveInputConfig } from '../../models/form.model';
import { CustomFormFieldControlComponent } from '../custom-form-field-control.component';
import { getCustomFormFieldProviders } from '../custom-form-field-control.functions';

const trailingZeroesPlusOptionalCharsRegex = /\d+\.\d*?(0+)[^\d]*$/;

type PrimitiveInputType = PrimitiveInputConfig['type'] | null;
type BMKType = 'K' | 'M' | 'B' | 'k' | 'm' | 'b';

@Component({
  standalone: false,
  selector: 'om-primitive-input',
  templateUrl: './primitive-input.component.html',
  providers: getCustomFormFieldProviders(forwardRef(() => PrimitiveInputComponent)),
})
export class PrimitiveInputComponent extends CustomFormFieldControlComponent {
  @ViewChild('childInput') childInput: ElementRef;

  @Input() decimalPlaces: number;

  @Input() isCellEditor = false;

  @Input() allowedKeyRegex: RegExp;

  isEmail = false;

  @Input()
  get type(): PrimitiveInputType {
    return this._type;
  }
  set type(type: PrimitiveInputType) {
    this._type = type;
    if (type === 'number') {
      this.childType = 'text';
    } else {
      this.childType = type;
    }
    if (type === 'email') {
      this.isEmail = true;
    }
    if (type === 'textarea') {
      this.onEnterPressed = (event: KeyboardEvent) => {};
    }
  }
  _type: PrimitiveInputType;
  childType: PrimitiveInputType;

  @Input()
  get value(): any | null {
    return this._value;
  }
  set value(val: any | null) {
    this._value = val;
    this.emitChanges();
    if ((this._type === 'number' || this._type === 'integer') && val !== null) {
      if (
        (this.endsWithPoint || this.trailingZeroesPlusOptionalCharsMatch) &&
        Number(this.childValue) === Number(val)
      ) {
        return;
      }
      if (this.childValue === '.' && val !== 0) {
        return;
      }
      this.childValue = this.utilService.applyCommasToNumber(val);
    } else {
      this.childValue = val;
    }
  }
  _value: any | null;
  childValue: string;

  @Input()
  get maxlength(): number | null {
    return this._maxlength;
  }
  set maxlength(maxlength: number | null) {
    this._maxlength = maxlength;
  }
  private _maxlength: number | null;

  @Input()
  get max(): number | null {
    return this._max;
  }
  set max(max) {
    this._max = max;
  }
  private _max: number | null;

  @Input()
  get min(): number | null {
    return this._min;
  }
  set min(min) {
    this._min = min;
  }
  private _min: number | null;

  private endsWithPoint: boolean;

  private trailingZeroesPlusOptionalCharsMatch: RegExpMatchArray | null;

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const arrowKeys = [KeyCode.Up, KeyCode.Down, KeyCode.Left, KeyCode.Right];
    if (this.allowedKeyRegex) {
      if (arrowKeys.includes(event.key as KeyCode) || ctrlKey(event)) {
        return true;
      }
      if (event.key.match(this.allowedKeyRegex)) {
        return true;
      }
      return false;
    }
    return true;
  }

  onChildValueChange() {
    if (this._type !== 'number' && this._type !== 'integer') {
      this._value =
        (this.allowedKeyRegex
          ? [...this.childValue].filter(letter => letter.match(this.allowedKeyRegex)).join('')
          : this.childValue) || null;
      this.emitChanges();
      return;
    }

    if (!this.childValue || this.childValue === '-') {
      this._value = null;
      this.emitChanges();
      return;
    }
    const lastChar = this.childValue ? this.childValue[this.childValue.length - 1] : '';
    this.endsWithPoint = lastChar === '.';

    // check if entered value has trailing zeroes
    this.trailingZeroesPlusOptionalCharsMatch = this.childValue.match(trailingZeroesPlusOptionalCharsRegex);

    const val = this.convertInputToNumber(this.childValue);
    this._value = val;
    this.emitChanges();

    let convertedNumberString = this.utilService.applyCommasToNumber(val);

    if (this.trailingZeroesPlusOptionalCharsMatch) {
      convertedNumberString = convertedNumberString.includes('.')
        ? `${convertedNumberString}${this.trailingZeroesPlusOptionalCharsMatch[1]}`
        : `${convertedNumberString}.${this.trailingZeroesPlusOptionalCharsMatch[1]}`;
    }

    if (this.endsWithPoint && !convertedNumberString.includes('.') && this.type === 'number') {
      convertedNumberString = convertedNumberString + '.';
    }

    const diff = convertedNumberString.length - this.childValue.length;
    const selectionPosition = Math.max(0, (this.childInput.nativeElement.selectionStart || 0) + diff);

    this.childInput.nativeElement.value = convertedNumberString;
    this.childInput.nativeElement.setSelectionRange(selectionPosition, selectionPosition);
  }

  public focus() {
    this.childInput?.nativeElement?.focus();
  }

  private convertInputToNumber(numberString: string): number {
    let lastChar = numberString ? numberString[numberString.length - 1] : '';
    if (['K', 'M', 'B', 'k', 'm', 'b'].includes(lastChar)) {
      numberString = numberString.slice(0, -1);
    } else {
      lastChar = '';
    }
    let numericValue = this.getNumericValue(numberString);
    if (lastChar) {
      numericValue = this.applyIncrease(numericValue, numberString, lastChar as BMKType);
    }
    return numericValue;
  }

  private applyIncrease(numberValue: number, numberString: string, factor: BMKType): number {
    if (!numberValue) {
      numberValue = numberString.startsWith('-') ? -1 : 1;
    }

    let increaseFactor = 1;
    switch (factor.toUpperCase()) {
      case 'K':
        increaseFactor = ONE_THOUSAND;
        break;
      case 'M':
        increaseFactor = ONE_MILLION;
        break;
      case 'B':
        increaseFactor = ONE_BILLION;
        break;
      default:
        break;
    }

    const newValue = numberValue * increaseFactor;
    if (newValue > ONE_QUADRILLION) {
      // bigger than a quadrillion
      // will have values like 1.1e+21
      return numberValue;
    }
    return Math.round(newValue);
  }

  onBlur() {
    if (this.childValue && this.decimalPlaces && this._type === 'number') {
      this.formatTrailingZeroes();
    }
  }

  private formatTrailingZeroes() {
    const strSplit = this.childValue.split('.');
    if (this.childValue.includes('.')) {
      const difference = this.decimalPlaces - strSplit[1].length;
      if (difference < 0) {
        const trailingZeroesMatch = this.childValue.match(trailingZeroesPlusOptionalCharsRegex);
        if (trailingZeroesMatch) {
          if (-difference >= trailingZeroesMatch[1].length) {
            this.childValue = this.childValue.slice(0, -trailingZeroesMatch[1].length);
          } else {
            this.childValue = this.childValue.slice(0, difference);
          }
        }
      } else if (difference > 0) {
        this.childValue = this.childValue + '0'.repeat(difference);
      }
    }
  }

  private getNumericValue(value: string): number {
    let numericCharsOnly = value.replace(/[^\d.]/g, '');
    if (!numericCharsOnly.length) {
      return value.startsWith('-') ? -0 : 0;
    }
    if (numericCharsOnly === '.') {
      numericCharsOnly = '0.';
    }
    if (value.startsWith('-')) {
      numericCharsOnly = `-${numericCharsOnly}`;
    }
    return parseFloat(numericCharsOnly);
  }
}
