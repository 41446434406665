import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-empty-search',
  templateUrl: './empty-search.component.html',
  styleUrls: ['./empty-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmptySearchComponent {
  @Input() title: string;
  @Input() searchTerm: string;
  @Input() message: string;
}
