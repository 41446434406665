import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateService } from '../../core/services/state.service';

@Pipe({
  standalone: false,
  name: 'mappingFromConstants',
})
export class MappingFromConstantsPipe implements PipeTransform {
  constructor(private stateService: StateService) {}

  transform(propertyField: string, propertyName: string): Observable<unknown> {
    return this.stateService.get.constants$.pipe(
      map(constants => {
        return constants.mappingFor[propertyName][propertyField];
      }),
    );
  }
}
