import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { DocumentListItem } from '../document-list.model';

const iconKey = ['docx', 'pdf', 'csv', 'xlsm'] as const;

export type IconKey = (typeof iconKey)[number];

export type IconMap = {
  [key in IconKey]: string;
};

@Component({
  standalone: false,
  selector: 'om-document-download-button',
  templateUrl: './document-download-button.component.html',
  styleUrls: ['./document-download-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentDownloadButtonComponent {
  @Input() documentDownloadItem: DocumentListItem;
  @Output() selected = new EventEmitter();
  @Output() menuClosed = new EventEmitter();

  readonly documentIcons: IconMap = {
    docx: 'file_word',
    pdf: 'file_pdf',
    csv: 'document',
    xlsm: 'file_excel',
  };

  constructor(private dialogService: DialogService) {}

  onDownloadModal(config: BooleanPopupParams) {
    this.dialogService.booleanPopup(config).subscribe();
  }

  onMenuClosed(): void {
    this.menuClosed.emit();
  }

  onSelectDocument(): void {
    this.selected.emit();
  }
}
