<h2 mat-dialog-title>
  {{ actionText.CREATE_TRADE }}
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h2>

<div mat-dialog-content [class.has-error]="hasError" [class.is-loading]="isLoading">
  @if (!hasError) {
    <div class="upload-area">
      <div class="upload-area__header">
        <img class="is-transparent" [src]="'assets/img/placeholder/upload.svg' | cdn" />
      </div>
      @if (isLoading) {
        <om-linear-progress-indicator [progress]="currentProgress.progress"></om-linear-progress-indicator>
      }
      <p class="upload-area__text" [innerHTML]="currentProgress.progressStep"></p>
      <p class="upload-area__subtext">{{ currentProgress.progressStepDescription }}</p>
      @if (currentProgress.progressStepNote) {
        <p class="upload-area__subtext">
          {{ currentProgress.progressStepNote }}
        </p>
      }
    </div>
  } @else {
    <div class="upload-area">
      <div class="upload-area__header">
        <img class="is-transparent" [src]="'assets/img/placeholder/upload-error.svg' | cdn" />
      </div>
      <p class="upload-area__text">Something went wrong</p>
      @if (errorMessages.length) {
        <p class="upload-area__subtext">We couldn't finish extracting your file because:</p>
        @for (message of errorMessages; track message) {
          <p class="upload-area__subtext">{{ message }}</p>
        }
        <p class="upload-area__subtext">Try uploading a new file.</p>
      } @else {
        <p class="upload-area__subtext">
          We couldn’t finish extracting your file. Check that the file is a PDF or DOCX and retry the upload.
        </p>
      }
    </div>
    <button mat-button class="is-chip" (click)="onRetry()">
      <span>Retry</span><mat-icon svgIcon="reload"></mat-icon>
    </button>
  }
</div>
