<button mat-icon-button mat-dialog-close>
  <mat-icon svgIcon="close"></mat-icon>
</button>

<h2 mat-dialog-title>Contact Origin</h2>

<mat-dialog-content>
  <span>You can contact Origin Support in the following ways:</span>

  <mat-list>
    <mat-list-item>
      <mat-icon mat-list-icon svgIcon="bloomberg"></mat-icon>
      <div mat-line>
        <span>Bloomberg Chat: &lt;IB ORIGIN SUPPORT&gt;</span>
        <button mat-icon-button (click)="ioService.copy('<IB ORIGIN SUPPORT>')">
          <mat-icon svgIcon="copy_to_clipboard"></mat-icon>
        </button>
      </div>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon svgIcon="email"></mat-icon>
      <div mat-line>
        Email:
        <a href="mailto:support@originmarkets.com">support&#64;originmarkets.com</a>
      </div>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon svgIcon="phone"></mat-icon>
      <div mat-line>
        Phone: (Asian Business Hours)
        <a href="tel:+85297207800">+852 9720 7800</a>
      </div>
    </mat-list-item>

    <mat-list-item>
      <mat-icon mat-list-icon svgIcon="phone"></mat-icon>
      <div mat-line>
        Phone: (European Business Hours)
        <a href="tel:+442079935375">+44 207 993 5375</a>
      </div>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
