import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicActionBase, PopupService, ValueAndLabel, followCursorTooltipProperties } from '@morpho/core';
import { BooleanPopupParams, DialogService } from '@morpho/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TeamColleague } from 'apps/bankangle/src/app/core/elements/models/team-colleague.model';
import { StateService } from 'apps/bankangle/src/app/core/services/state.service';
import { AuthenticatedUserState } from 'apps/bankangle/src/app/core/state/authenticated-user/authenticated-user.model';
import { AuthenticatedUserSelector } from 'apps/bankangle/src/app/core/state/authenticated-user/authenticated-user.selectors';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, mergeMap, startWith, takeUntil } from 'rxjs/operators';
import {
  TradeWorkingGroup,
  TradeWorkingGroupCompany,
  TradeWorkingGroupTeam,
  TradeWorkingGroupUser,
} from '../../models';
import { TradesApiService } from '../../services/trades-api.service';
import { TradesAction } from '../../state/trades.actions';
import { ActiveTradeState, TradesState } from '../../state/trades.model';
import { TRADES_STATE_KEY } from '../../state/trades.reducer';

export interface TradeWorkingGroupModalAction extends DynamicActionBase {
  type: 'working_group_modal';
}

export interface TradeWorkingGroupLists {
  filteredTeamList: TradeWorkingGroupTeam[];
  filteredList: TeamColleague[] | TradeWorkingGroupCompany[];
}

enum STAGES {
  Initial = 'initial',
  SearchMembers = 'searchMembers',
  SearchCompanies = 'searchCompanies',
  SearchCompanyEmployees = 'searchCompanyEmployees',
}
@Component({
  standalone: false,
  selector: 'om-trade-working-group-modal',
  templateUrl: './trade-working-group-modal.component.html',
})
export class TradeWorkingGroupModalComponent implements OnDestroy {
  private readonly ngOnDestroy$: Subject<void> = new Subject<void>();

  followCursorTooltipProperties = followCursorTooltipProperties;

  tradeWorkingGroups$: Observable<TradeWorkingGroup[] | undefined>;
  addUserOptions$: Observable<ValueAndLabel>;
  addUserList$: Observable<TeamColleague[]>;
  filteredTeamList$: Observable<TradeWorkingGroupTeam[]>;
  filteredList$: Observable<TeamColleague[] | TradeWorkingGroupCompany[]>;
  filteredWorkingGroupLists$: Observable<TradeWorkingGroupLists>;
  trade$: Observable<TradesState> = this.store.pipe(select(TRADES_STATE_KEY));
  authenticatedUser$: Observable<AuthenticatedUserState> = this.store.select(AuthenticatedUserSelector.state);
  disabledMap: Record<number, boolean> = {};

  tradeId: number | undefined;

  allUsers: TradeWorkingGroupUser[] = [];
  allCompanyIds: string[] = [];
  userGroupToEdit: number;

  entityToKeyMapping: { [key: string]: string } = {
    Issuer: 'I',
    'Law Firm': 'LF',
    Dealer: 'D',
  };

  filterControl: FormControl = new FormControl('');

  idsToAdd: number[] = [];

  companySelected: TradeWorkingGroupCompany | undefined;

  STAGES = STAGES;
  currentStage: STAGES;

  companiesList$ = this.trade$.pipe(
    map((state: TradesState) => state.activeTrade),
    mergeMap((trade: ActiveTradeState) => {
      if (!trade.permissions?.can_add_additional_working_groups) {
        return of([]);
      } else {
        return this.tradesApiService.getWorkingGroupAdditionalCompanies(trade.id as number).pipe(
          map((companies: TradeWorkingGroupCompany[]) => {
            if (this.allCompanyIds) {
              return companies.filter(
                (company: TradeWorkingGroupCompany) =>
                  !this.allCompanyIds.includes(`${this.entityToKeyMapping[company.company_type_label]}${company.id}`),
              );
            } else {
              return [];
            }
          }),
        );
      }
    }),
  );

  constructor(
    private actions$: Actions,
    private dialogService: DialogService,
    private popupService: PopupService,
    private tradesApiService: TradesApiService,
    private stateService: StateService,
    private store: Store<any>,
  ) {
    this.tradeWorkingGroups$ = this.trade$.pipe(
      map((state: TradesState) => {
        this.tradeId = state?.activeTrade?.id;
        this.allUsers = [];
        this.allCompanyIds = [];
        // concatenate all working group users into one list
        if (state?.activeTrade?.workingGroups) {
          state?.activeTrade?.workingGroups.forEach((workingGroup: TradeWorkingGroup) => {
            this.allUsers = [...this.allUsers, ...workingGroup.users];
            this.allCompanyIds = [
              ...this.allCompanyIds,
              `${this.entityToKeyMapping[workingGroup.company.company_type_label]}${workingGroup.company.id}`,
            ];
          });
        }
        return state?.activeTrade?.workingGroups;
      }),
    );

    this.currentStage = this.STAGES.Initial;

    this.actions$
      .pipe(ofType(TradesAction.deleteWorkingGroupUsersFail), takeUntil(this.ngOnDestroy$))
      .subscribe(action => {
        const keys = Object.keys(action.params.error);
        this.popupService.error(action.params.error[keys[0]]);
      });
  }

  cancelToStage(stage: STAGES, id?: number) {
    this.idsToAdd = [];
    this.companySelected = undefined;
    this.goToStage(stage, id);
  }

  goToStage(stage: STAGES, id?: number) {
    this.currentStage = stage;
    switch (stage) {
      case this.STAGES.Initial:
        break;
      case this.STAGES.SearchCompanies:
        this.getAddCompanyList();
        break;
      case this.STAGES.SearchMembers:
        if (!id) {
          return;
        }
        this.userGroupToEdit = id;
        this.getAddUserList(id);
        break;
      default:
        break;
    }
  }

  addWorkingGroup() {
    if (this.idsToAdd.length) {
      // sent to working group
      this.stateService.get.router$.subscribe(state => {
        const tradeId = state.urlParams.tradeId;
        if (!tradeId) {
          return;
        }
        this.store.dispatch(
          TradesAction.addTradeWorkingGroup({
            params: { tradeId, userIds: this.idsToAdd },
          }),
        );
      });

      this.currentStage = this.STAGES.Initial;
      this.resetForm();
    }
  }

  addToWorkingGroup() {
    if (this.idsToAdd.length) {
      // sent to working group
      this.stateService.get.router$.subscribe(state => {
        const tradeId = state.urlParams.tradeId;
        if (!tradeId) {
          return;
        }
        this.store.dispatch(
          TradesAction.addWorkingGroupUsers({
            params: { tradeId, workingGroupId: this.userGroupToEdit, userIds: this.idsToAdd },
          }),
        );
      });

      this.currentStage = this.STAGES.Initial;
      this.resetForm();
    }
  }

  selectCompany() {
    if (this.currentStage === this.STAGES.SearchCompanies && this.companySelected) {
      this.currentStage = this.STAGES.SearchCompanyEmployees;
      this.getAddUserList(this.companySelected.id, this.companySelected);
      this.filterControl.reset();
    }
  }

  deleteUser(workingGroupId: number, user: TradeWorkingGroupUser) {
    const params: BooleanPopupParams = {
      title: `Remove ${user.full_name} from Working Group?`,
      message: `Are you sure you want to remove ${user.full_name} from the working group?
      They will no longer have any access to the trade.`,
      right_action: {
        text: 'Remove',
        class: 'is-warning',
      },
    };

    this.dialogService.booleanPopup(params).subscribe(response => {
      if (response) {
        this.stateService.get.router$.subscribe(state => {
          const tradeId = state.urlParams.tradeId;
          if (!tradeId) {
            return;
          }
          this.store.dispatch(
            TradesAction.deleteWorkingGroupUsers({
              params: { tradeId, workingGroupId, userIds: [user.id] },
            }),
          );
        });
      }
    });
  }

  isTeamSelected(team: TradeWorkingGroupTeam): boolean {
    const teamSelected = team.team_users.every(user => this.idsToAdd.includes(user.id));
    team.team_users.forEach(user => {
      this.disabledMap[user.id] = teamSelected;
    });

    if (teamSelected) {
      (team as any).selected = teamSelected;
    }

    return teamSelected;
  }

  updateTeamSelectedIds(team: TradeWorkingGroupTeam) {
    team.team_users.forEach(user => {
      this.updateSelectedIds(user, (team as any).selected);
    });
  }

  updateSelectedIds(item: TeamColleague, teamSelected = false) {
    if (teamSelected) {
      this.idsToAdd = [...new Set([...this.idsToAdd, item.id])];
    } else if (!this.idsToAdd.includes(item.id)) {
      this.idsToAdd = [...this.idsToAdd, item.id];
    } else {
      this.idsToAdd = this.idsToAdd.filter(itemId => itemId !== item.id);
    }
  }

  private getAddUserList(workingGroupId: number, company?: TradeWorkingGroupCompany) {
    // get members of the current working group
    const currentGroupUserIds$ = this.trade$.pipe(
      map((state: TradesState) => {
        const temp = state.activeTrade.workingGroups?.filter((wg: TradeWorkingGroup) => wg.id === workingGroupId);
        if (!temp?.length) {
          return [];
        }

        return state.activeTrade.workingGroups
          ?.filter((wg: TradeWorkingGroup) => wg.id === workingGroupId)
          .map((wg: TradeWorkingGroup) => (wg.users ? wg.users : []))[0]
          .map((user: TradeWorkingGroupUser) => user.id);
      }),
    );

    // get all members of the user's company/working group
    const allUsers$ = company
      ? this.tradesApiService.getCompanyUsers(
          company.id,
          company.company_type_label === 'Law Firm'
            ? 'law-firms'
            : company.company_type_label === 'Dealer'
              ? 'banks'
              : 'issuers',
        )
      : this.tradesApiService.getWorkingGroupUsers(workingGroupId);

    // get list of members available to add to working group
    this.addUserList$ = combineLatest([currentGroupUserIds$, allUsers$]).pipe(
      map(([currentGroupUserIds, allUsers]) => {
        return allUsers.filter((user: TeamColleague) => !currentGroupUserIds?.includes(user.id));
      }),
    );

    // get all teams and team members available to add to working group
    const addTeamList$ = this.addUserList$.pipe(
      map(users => {
        return users.reduce((acc: TradeWorkingGroupTeam[], user: TeamColleague) => {
          const teamIndex = acc.findIndex(acc => acc.team_name === user.team);
          if (teamIndex === -1) {
            acc.push({
              team_name: user.team ?? '',
              team_users: [user],
              employer_name: user.employer_name ?? '',
              employer_url: user.employer_url ?? '',
            });
          } else {
            acc[teamIndex].team_users.push(user);
          }
          return acc;
        }, []);
      }),
    );

    // filter lists by the form field value
    this.filteredTeamList$ = combineLatest([addTeamList$, this.filterControl.valueChanges.pipe(startWith(''))]).pipe(
      map(([teams, filterString]) => {
        return teams.filter((filteredTeam: TradeWorkingGroupTeam) =>
          filterString
            ? [filteredTeam.employer_name, filteredTeam.team_name].find((item: string) =>
                item.toLowerCase().includes(filterString.toLowerCase()),
              )
            : filteredTeam,
        );
      }),
    );

    this.filteredList$ = combineLatest([this.addUserList$, this.filterControl.valueChanges.pipe(startWith(''))]).pipe(
      map(([users, filterString]) => {
        return users.filter((filteredUser: TeamColleague) =>
          filterString
            ? [filteredUser.display_title, filteredUser.display_subtitle].find((item: string) =>
                item.toLowerCase().includes(filterString.toLowerCase()),
              )
            : filteredUser,
        );
      }),
    );

    this.filteredWorkingGroupLists$ = combineLatest([this.filteredTeamList$, this.filteredList$]).pipe(
      map(([filteredTeamList, filteredList]) => ({
        filteredTeamList,
        filteredList,
      })),
    );
  }

  private getAddCompanyList() {
    if (!this.tradeId) {
      return;
    }

    // filter the list by the form field value
    this.filteredList$ = combineLatest([
      this.companiesList$.pipe(filter((companiesList: TradeWorkingGroupCompany[]) => !!companiesList?.length)),
      this.filterControl.valueChanges.pipe(startWith('')),
    ]).pipe(
      map(([companies, filterString]) => {
        return companies.filter((filteredCompany: TradeWorkingGroupCompany) =>
          filterString
            ? [filteredCompany.display_title, filteredCompany.display_subtitle].find((item: string) =>
                item.toLowerCase().includes(filterString.toLowerCase()),
              )
            : filteredCompany,
        );
      }),
    );

    this.filteredWorkingGroupLists$ = this.filteredList$.pipe(
      map(filteredList => ({
        filteredTeamList: [],
        filteredList,
      })),
    );
  }

  private resetForm() {
    this.idsToAdd = [];
    this.userGroupToEdit = 0;
    this.filterControl.reset();
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
