import { ctrlKey, KeyCode, shiftKey } from '@morpho/core';
import { Editor, Element, Transforms } from 'slate';
import { ElementType, EMPTY_PARAGRAPH } from '../../constants/rich-text.constants';
import { TextEditor } from './text-commands';
import { InlineStyle } from './text.constants';

export const withText = (editor: Editor) => {
  const { normalizeNode, onKeyDown } = editor;

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    if (JSON.stringify(path) === `[${editor.children.length - 1}]`) {
      const lastChild = editor.children[editor.children.length - 1];
      if (!Element.isElement(lastChild) || lastChild.type !== ElementType.Paragraph) {
        Transforms.insertNodes(editor, EMPTY_PARAGRAPH, { at: [editor.children.length] });
        return;
      }
    }

    normalizeNode(entry);
  };

  editor.onKeyDown = (editor: Editor, event: KeyboardEvent) => {
    if (ctrlKey(event)) {
      switch (event.code) {
        case KeyCode.B:
          TextEditor.toggleInline(editor, InlineStyle.Bold);
          return;
        case KeyCode.I:
          TextEditor.toggleInline(editor, InlineStyle.Italic);
          return;
        case KeyCode.U:
          TextEditor.toggleInline(editor, InlineStyle.Underline);
          return;
        default:
          break;
      }
    }

    switch (event.code) {
      case KeyCode.Tab:
        if (onParagraphTab(editor, event)) {
          event.preventDefault();
        }
        break;
      default:
        break;
    }

    onKeyDown?.(editor, event);
  };

  return editor;
};

function onParagraphTab(editor: Editor, event: KeyboardEvent) {
  return shiftKey(event) ? TextEditor.outdent(editor) : TextEditor.indent(editor);
}
