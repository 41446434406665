import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UtilService, ValueAndLabel } from '@morpho/core';

import {
  BasePoint,
  BaseRange,
  BaseSelection,
  Editor,
  Element,
  Node,
  NodeEntry,
  Range,
  Transforms,
  createEditor,
} from 'slate';
import { AngularEditor, withAngular } from 'slate-angular';

import { withHistory } from 'slate-history';
import { EMPTY_PARAGRAPH, EditorType, ElementType, MENTION_TRIGGER_CHAR } from '../../constants/rich-text.constants';
import { RichText } from '../../models/rich-text.model';
import { withEquations } from '../../plugins/equation/equation-plugin';
import { HyperlinkEditor } from '../../plugins/hyperlink/hyperlink-commands';
import { withHyperlinks } from '../../plugins/hyperlink/hyperlink-plugin';
import { ListEditor } from '../../plugins/list/list-commands';
import { withLists } from '../../plugins/list/list-plugin';
import { withMentions } from '../../plugins/mentions/mentions-plugin';

import { OptionGroup } from '@morpho/form';
import { Subject } from 'rxjs';
import { Props } from 'tippy.js';
import { MentionsOption } from '../../models/mentions.model';
import { MentionsEditor } from '../../plugins/mentions/mentions-commands';
import { withPasting } from '../../plugins/pasting/pasting-plugin';
import { TableEditor } from '../../plugins/table/table-commands';
import { withTables } from '../../plugins/table/table-plugin';
import { TextEditor } from '../../plugins/text/text-commands';
import { withText } from '../../plugins/text/text-plugin';
import { InlineStyle, TextAlign } from '../../plugins/text/text.constants';
import { RichTextEditorContainer } from '../../rich-text.module';
import { RichTextService } from '../../services/rich-text.service';
import { EquationEditorComponent } from '../equation-editor/equation-editor.component';
import { RichTextInlineComponent } from '../rich-text-inline/rich-text-inline.component';
import { TableEditAction } from '../table-edit-control/table-edit-control.component';

@Component({
  standalone: false,
  selector: 'om-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextEditorComponent implements RichTextEditorContainer {
  @ViewChild('menuBtnHyperlink', { read: MatMenuTrigger, static: false }) hyperlinkTrigger: MatMenuTrigger;

  @ViewChild('triggerForMentionsLookup', { read: MatMenuTrigger, static: false })
  triggerForMentionsLookup: MatMenuTrigger;
  @ViewChild('mentionsLookup', { read: MatMenu, static: true }) mentionsLookup: MatMenu;
  @ViewChild('triggerWrapper', { read: ElementRef }) triggerWrapper: ElementRef;

  readonly ElementType = ElementType;
  readonly InlineStyle = InlineStyle;
  readonly TextAlign = TextAlign;
  readonly tableCellClass = 'om-richtext-table-cell';
  readonly hoveredTableColumnClass = `${this.tableCellClass}--is-column-hovered`;
  readonly hoveredTableRowClass = `${this.tableCellClass}--is-row-hovered`;

  readonly tooltipProperties: Partial<Props> = {
    theme: 'light-border tooltip-with-list',
  };

  @Input()
  get model(): RichText | null {
    if (this.isEmpty()) {
      return null;
    }
    return this._model;
  }
  set model(model: RichText | null) {
    if (model) {
      const richTextValue = this.richTextService.processRichTextDataForEditor(model);

      if (!this.utilService.areObjectsEquivalent(this.richTextValue, richTextValue)) {
        this.richTextValue = richTextValue;
      }
    } else {
      this.richTextValue = this.richTextDefault;
    }

    this._model = model;
  }
  private _model: RichText | null;

  @Input()
  get editorType(): EditorType {
    return this._editorType;
  }
  set editorType(editorType: EditorType) {
    this._editorType = editorType;
    this.editor = this.setUpPluginsByType(editorType);
  }
  private _editorType: EditorType;

  @Output() modelChange: EventEmitter<RichText | null> = new EventEmitter<RichText | null>();

  @Input() readonly = false;

  @Input() userFullName: string;

  @ViewChild('paragraphTemplate', { read: TemplateRef, static: true }) paragraphTemplate: TemplateRef<any>;
  @ViewChild('hyperlinkTemplate', { read: TemplateRef, static: true }) hyperlinkTemplate: TemplateRef<any>;

  @ViewChild('mentionTemplate', { read: TemplateRef, static: true }) mentionTemplate: TemplateRef<any>;

  @ViewChild('orderedListTemplate', { read: TemplateRef, static: true }) orderedListTemplate: TemplateRef<any>;
  @ViewChild('unorderedListTemplate', { read: TemplateRef, static: true }) unorderedListTemplate: TemplateRef<any>;
  @ViewChild('listItemTemplate', { read: TemplateRef, static: true }) listItemTemplate: TemplateRef<any>;

  @ViewChild('tableTemplate', { read: TemplateRef, static: true }) tableTemplate: TemplateRef<any>;
  @ViewChild('tableRowTemplate', { read: TemplateRef, static: true }) tableRowTemplate: TemplateRef<any>;
  @ViewChild('tableHeaderTemplate', { read: TemplateRef, static: true }) tableHeaderTemplate: TemplateRef<any>;
  @ViewChild('tableDataTemplate', { read: TemplateRef, static: true }) tableDataTemplate: TemplateRef<any>;

  editor = this.setUpPluginsByType(EditorType.Full);

  activeSelection: BaseSelection;

  activeInlineProperties: Record<InlineStyle | string, boolean> = {};
  activeBlockProperties: Set<ElementType> = new Set<ElementType>();
  textAlignedValue: TextAlign | null = null;

  activeHyperlink: NodeEntry<Node> | null;
  isHyperlinkEditorVisible = false;

  richTextValue: RichText;

  richTextDefault: RichText = [EMPTY_PARAGRAPH];
  EditorType = EditorType;

  //* Mention stuff
  mentionTarget: Range | null;
  searchText = '';
  activeIndex = 0;
  mentionsLookupListPosition = {
    x: 0,
    y: 0,
  };

  target: Range;

  @Input() mentionsOptions: OptionGroup[];
  filteredMentionsOptions: OptionGroup[] = [];

  readonly listOptions = [
    { value: ElementType.UnorderedList, icon: 'unordered_list' },
    { value: ElementType.OrderedList, icon: 'ordered_list' },
  ];

  readonly inlineStyleOptions = [
    { value: InlineStyle.Bold, icon: 'bold' },
    { value: InlineStyle.Italic, icon: 'italic' },
    { value: InlineStyle.Underline, icon: 'underline' },
  ];

  readonly alignmentOptions = [
    { value: TextAlign.Left, icon: 'align_left' },
    { value: TextAlign.Center, icon: 'align_center' },
    { value: TextAlign.Right, icon: 'align_right' },
    { value: TextAlign.Justify, icon: 'align_justify' },
  ];

  readonly editActionsColumn: TableEditAction[] = [
    {
      label: 'Insert column left',
      action: this.onTableAction.bind(this, 'insert', 'column', 'left'),
    },
    {
      label: 'Insert column right',
      action: this.onTableAction.bind(this, 'insert', 'column', 'right'),
    },
    {
      label: 'Delete column',
      action: this.onTableAction.bind(this, 'delete', 'column', null),
    },
  ];

  readonly editActionsRow: TableEditAction[] = [
    {
      label: 'Insert row above',
      action: this.onTableAction.bind(this, 'insert', 'row', 'above'),
    },
    {
      label: 'Insert row below',
      action: this.onTableAction.bind(this, 'insert', 'row', 'below'),
    },
    {
      label: 'Delete row',
      action: this.onTableAction.bind(this, 'delete', 'row', null),
    },
  ];

  private readonly ngOnDestroy$ = new Subject<void>();

  @HostBinding('class.has-table-selection') hasTableSelection = false;
  selectedTableCellKeyMap: Record<string, boolean> = {};

  constructor(
    private richTextService: RichTextService,
    private utilService: UtilService,
    private renderer: Renderer2,
    private ref: ElementRef,
  ) {}

  private setUpPluginsByType(editorType: EditorType): Editor {
    let editor: Editor = withHistory(withAngular(<any>createEditor()));
    switch (editorType) {
      case EditorType.Full:
        editor = withEquations(withPasting(withHyperlinks(withTables(withLists(withText(editor))))));
        break;
      case EditorType.Mentions:
        editor = withMentions(editor);
        break;
    }

    (editor as any).container = this;
    return editor;
  }

  onTableAction(
    action: 'insert' | 'delete',
    type: 'row' | 'column',
    position: 'above' | 'below' | 'left' | 'right' | null,
    elementId: string,
  ) {
    const path = this.getTableControlPath(elementId);
    this.cleanCellClasses();

    switch (action) {
      case 'insert':
        switch (type) {
          case 'row':
            TableEditor.insertTableRow(this.editor, position as 'above' | 'below', path);
            break;
          case 'column':
            TableEditor.insertTableColumn(this.editor, position as 'left' | 'right', path);
            break;
          default:
            break;
        }
        break;
      case 'delete':
        switch (type) {
          case 'row':
            TableEditor.deleteTableRow(this.editor, path);
            break;
          case 'column':
            TableEditor.deleteTableColumn(this.editor, path);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  checkActiveProperties() {
    if (this.editorType == EditorType.Mentions) {
      return;
    }
    this.activeSelection = this.editor.selection ?? this.activeSelection;
    this.activeInlineProperties = TextEditor.getActiveInlineProperties(this.editor);
    this.activeHyperlink = HyperlinkEditor.getActiveElement(this.editor, ElementType.Hyperlink);
    this.setActiveElementTypes();
    this.setTextAlignedValue();
  }

  setActiveElementTypes() {
    const attributesToCheck: ElementType[] = [ElementType.OrderedList, ElementType.UnorderedList];
    attributesToCheck.forEach(elementType => {
      if (TextEditor.isActiveElement(this.editor, elementType)) {
        this.activeBlockProperties.add(elementType);
      } else {
        this.activeBlockProperties.delete(elementType);
      }
    });
  }

  setTextAlignedValue() {
    const attributesToCheck: TextAlign[] = [TextAlign.Center, TextAlign.Justify, TextAlign.Left, TextAlign.Right];
    for (const textAlign of attributesToCheck) {
      if (TextEditor.getIsActiveTextAlignment(this.editor, textAlign)) {
        this.textAlignedValue = textAlign;
        break;
      }
      this.textAlignedValue = TextAlign.Left;
    }
  }

  onValueChange(event: RichText) {
    const value = this.richTextService.processRichTextDataForForm(event);
    this.modelChange.emit(value);
    this.checkActiveProperties();

    if (this.editorType === EditorType.Mentions) {
      this.checkForMentionsTrigger();
    }
  }

  checkForMentionsTrigger() {
    const { selection, operations } = this.editor;

    if (operations[0].type === 'insert_text' && operations[0].text === MENTION_TRIGGER_CHAR) {
      if (selection?.anchor) {
        this.mentionTarget = {
          anchor: Editor.before(this.editor, selection.anchor) as BasePoint,
          focus: selection.focus,
        };
      }
      this.searchText = '';
      this.activeIndex = 0;

      this.updateSuggestionsLocation();
      return;
    }

    if (selection && Range.isCollapsed(selection) && this.mentionTarget) {
      const beforeRange = Editor.range(this.editor, this.mentionTarget.anchor, selection.focus);
      const beforeText = Editor.string(this.editor, beforeRange);

      const regexString = `^${MENTION_TRIGGER_CHAR}(\\w*)$`;
      const regexObj = new RegExp(regexString, 'gm');

      const beforeMatch = beforeText && regexObj.test(beforeText);

      if (beforeMatch) {
        this.searchText = beforeText.slice(1);
        this.activeIndex = 0;

        this.updateSuggestionsLocation();
        return;
      }
    }

    if (this.mentionTarget) {
      this.mentionTarget = null;
      this.updateSuggestionsLocation();
    }
  }

  updateSuggestionsLocation() {
    if (!this.mentionsOptions) {
      return;
    }

    this.filteredMentionsOptions = this.mentionsOptions
      .map(group => {
        const filteredOptions = group.options.filter(option =>
          [option.value, option.label].join(' ').toLowerCase().includes(this.searchText.toLowerCase()),
        );
        return {
          divider_label: group.divider_label,
          options: filteredOptions,
        };
      })
      .filter(group => group.options.length);

    if (this.mentionTarget && this.filteredMentionsOptions.length) {
      //* Get Richtext position
      const parentRect = this.ref.nativeElement.getBoundingClientRect();

      //* Get current position
      const nativeRange = AngularEditor.toDOMRange(this.editor as unknown as AngularEditor, this.mentionTarget);
      const rect = nativeRange.getBoundingClientRect();

      const leftPosition = rect.left - parentRect.left;
      const topPosition = rect.top - parentRect.top;

      //* Move Mentions Trigger to correct position
      this.renderer.setStyle(this.triggerWrapper.nativeElement, 'left', `${leftPosition + window.pageXOffset}px`);
      this.renderer.setStyle(this.triggerWrapper.nativeElement, 'top', `${topPosition + window.pageYOffset + 25}px`);
      //* Trigger menu
      this.triggerForMentionsLookup.openMenu();
      return;
    }
    //* Remove positioning from Mentions Trigger
    this.resetMentionsTrigger();

    if (this.triggerForMentionsLookup && !this.searchText) {
      this.triggerForMentionsLookup.closeMenu();
    }
  }

  selectMention(option: ValueAndLabel) {
    this.removeSearchText();
    const mention: MentionsOption = {
      name: option.label,
      description: option.description ?? '',
      data: option.data,
    };
    MentionsEditor.insertMention(this.editor, mention);
    this.resetMentionsTrigger();
  }

  removeSearchText() {
    if (this.editor?.selection) {
      const range: BaseRange = {
        anchor: {
          path: this.editor?.selection?.anchor.path,
          offset: this.editor?.selection?.anchor?.offset - this.searchText.length - 1,
        },
        focus: this.editor?.selection?.focus,
      };
      Transforms.select(this.editor, range);
      Transforms.delete(this.editor);
    }
  }

  resetMentionsTrigger() {
    //* Remove positioning from Mentions Trigger
    setTimeout(() => {
      this.renderer.removeStyle(this.triggerWrapper.nativeElement, 'left');
      this.renderer.removeStyle(this.triggerWrapper.nativeElement, 'top');
    });
  }

  renderElement = (element: Element & { type: string }) => {
    switch (element.type) {
      case ElementType.Paragraph:
        return this.paragraphTemplate;
      case ElementType.Hyperlink:
        return this.hyperlinkTemplate;
      case ElementType.Mention:
        return this.mentionTemplate;
      case ElementType.OrderedList:
        return this.orderedListTemplate;
      case ElementType.UnorderedList:
        return this.unorderedListTemplate;
      case ElementType.ListItem:
        return this.listItemTemplate;
      case ElementType.Table:
        return this.tableTemplate;
      case ElementType.TableRow:
        return this.tableRowTemplate;
      case ElementType.TableHeaderCell:
        return this.tableHeaderTemplate;
      case ElementType.TableDataCell:
        return this.tableDataTemplate;
      case ElementType.Equation:
        return EquationEditorComponent;

      default:
        return null;
    }
  };

  renderText = () => {
    return RichTextInlineComponent;
  };

  keydown = (event: KeyboardEvent) => {
    this.editor.onKeyDown?.(this.editor, event);
    this.checkActiveProperties();

    if (this.mentionTarget) {
      switch (event.key) {
        case 'ArrowDown':
          //* if there is a menu open
          // * focus first item
          if (this.mentionsLookup) {
            this.mentionsLookup.focusFirstItem();
          }
          event.preventDefault();
          break;
        case 'ArrowUp':
          event.preventDefault();

          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();

          break;
        case 'Escape':
          event.preventDefault();
          break;
      }
    }
  };

  processMouseDownEvent(event: MouseEvent) {
    event.preventDefault();
  }

  toggleInlineStyle(event: MouseEvent, style: InlineStyle) {
    this.processMouseDownEvent(event);
    TextEditor.toggleInline(this.editor, style);
  }

  setTextAlign(event: MouseEvent, align: TextAlign) {
    this.processMouseDownEvent(event);
    TextEditor.alignText(this.editor, align);
  }

  indent(event: MouseEvent) {
    this.processMouseDownEvent(event);
    TextEditor.indent(this.editor);
    ListEditor.indent(this.editor);
  }

  isEmpty(): boolean {
    return TextEditor.isEmpty(this.editor);
  }

  outdent(event: MouseEvent) {
    this.processMouseDownEvent(event);
    TextEditor.outdent(this.editor);
    ListEditor.outdent(this.editor);
  }

  toggleList(event: MouseEvent, type: ElementType) {
    this.processMouseDownEvent(event);
    ListEditor.toggleList(this.editor, type as ElementType.OrderedList | ElementType.UnorderedList);
  }

  createTable(event: MouseEvent) {
    this.processMouseDownEvent(event);
    const size = { columns: 2, rows: 3 };
    console.log('TODO: set table size from ui');
    TableEditor.insertTable(this.editor, size);
  }

  undo(event: MouseEvent) {
    this.processMouseDownEvent(event);
    this.editor.undo();
  }

  redo(event: MouseEvent) {
    this.processMouseDownEvent(event);
    this.editor.redo();
  }

  toggleHyperlinkEditor() {
    this.isHyperlinkEditorVisible = !this.isHyperlinkEditorVisible;
  }

  closeHyperlinkEditor() {
    this.hyperlinkTrigger.closeMenu();
  }

  onTableCellMouseEnter(event: MouseEvent) {
    this.toggleTableHover(event, true);
  }

  onTableCellMouseLeave(event: MouseEvent) {
    this.toggleTableHover(event, false);
  }

  private cleanCellClasses() {
    const cells = document.getElementsByClassName(this.tableCellClass);
    for (const element of cells) {
      element.classList.remove(this.hoveredTableColumnClass);
      element.classList.remove(this.hoveredTableRowClass);
    }
  }

  private toggleTableHover(event: MouseEvent, isHovered: boolean) {
    if (event.target instanceof globalThis.Element) {
      const targetElement = event.target as globalThis.Element;
      const ancestor = this.getElementAncestorByTag(targetElement as HTMLElement, 'table');
      if (!ancestor) {
        return;
      }
      const sibs: globalThis.Element[] = this.getPreviousElementSiblings(targetElement as HTMLElement);
      const columnLocation = sibs.length;
      const tableFirstRow = ancestor.children[0];
      const hoveredColumn = tableFirstRow.children[columnLocation];
      isHovered && !hoveredColumn.classList.contains(this.hoveredTableColumnClass)
        ? hoveredColumn.classList.add(this.hoveredTableColumnClass)
        : hoveredColumn.classList.remove(this.hoveredTableColumnClass);

      const hoveredRow = sibs.length ? sibs[sibs.length - 1] : targetElement;
      isHovered && !hoveredRow.classList.contains(this.hoveredTableRowClass)
        ? hoveredRow.classList.add(this.hoveredTableRowClass)
        : hoveredRow.classList.remove(this.hoveredTableRowClass);
    }
  }

  private getTableControlPath(elementId: string): number[] {
    const path = this.getElementPath(elementId);
    path.pop(); //* get parent path - because of component structure
    return path;
  }

  private getElementPath(elementId: string): number[] {
    const cellElement = document.getElementById(elementId);
    let pathArray: number[] = [];
    if (cellElement) {
      pathArray = this.getSlateElementTree(cellElement, pathArray);
    }
    return pathArray;
  }

  private getSlateElementTree(cellElement: HTMLElement, pathArr: number[]): number[] {
    const cellElementParent = cellElement?.parentElement;
    if (cellElementParent && cellElementParent?.tagName !== 'SLATE-EDITABLE') {
      const sibs = this.getPreviousElementSiblings(cellElementParent);
      pathArr.unshift(sibs.length);
      this.getSlateElementTree(cellElementParent, pathArr);
    }
    return pathArr;
  }

  private getElementAncestorByTag(element: HTMLElement, tag: string): globalThis.Element | null {
    return element?.closest(tag);
  }

  private getPreviousElementSiblings(element: HTMLElement): globalThis.Element[] {
    const sibs: globalThis.Element[] = [];
    let sibling: globalThis.Element | null = element as globalThis.Element;
    while ((sibling = sibling.previousElementSibling)) {
      sibs.push(sibling);
    }
    return sibs;
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
