<mat-toolbar>
  @if (!readonly) {
    <section left>
      <button mat-button class="is-strong is-chip is-accent" (click)="onNewView()">
        <mat-icon svgIcon="search"></mat-icon>
        <span> New {{ name }}</span>
      </button>
    </section>
  }
  @if (savedViews?.length) {
    <section>
      <span>Saved:</span>
      <om-flexible-button-list
        [buttonListData]="savedViewsButtonList"
        [selected]="selectedSavedView?.name"
        (selectedChange)="onSaveViewSelectedChange($event)"
      ></om-flexible-button-list>
    </section>
  }
  @if (selectedSavedView?.data.search && !readonly) {
    @if (selectedSavedView?.id) {
      <section right>
        <button mat-button class="is-accent" [matMenuTriggerFor]="actionMenu">
          <span>Save {{ name }}</span>
          <mat-icon iconPositionEnd svgIcon="chevron_down"></mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu">
          <button mat-menu-item class="is-accent" (click)="onSaveView()">
            <mat-icon svgIcon="save"></mat-icon>
            <span>Save {{ name }}</span>
          </button>
          <button mat-menu-item class="is-accent" (click)="onSaveAsNewView()">
            <mat-icon svgIcon="save"></mat-icon>
            <span>Save as New {{ name }}</span>
          </button>
          @if (isDealer$ | async) {
            <button mat-menu-item class="is-accent" (click)="onShareView()">
              <mat-icon svgIcon="share"></mat-icon>
              <span>Share {{ name }}</span>
            </button>
          }
          <button mat-menu-item class="is-accent" (click)="onRenameView()">
            <mat-icon svgIcon="edit"></mat-icon>
            <span>Rename {{ name }}</span>
          </button>
          <button mat-menu-item class="is-warning" (click)="onDeleteView()">
            <mat-icon svgIcon="delete"></mat-icon>
            <span>Delete {{ name }}</span>
          </button>
        </mat-menu>
      </section>
    } @else {
      <section right>
        <button mat-button class="is-accent" (click)="onSaveAsNewView()">
          <mat-icon svgIcon="save"></mat-icon>
          <span>Save as New {{ name }}</span>
        </button>
      </section>
    }
  }
</mat-toolbar>
