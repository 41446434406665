@if (config) {
  @if (config.actions && config.actions.length === 1 && !config.forceDropdown) {
    @if (config.actions[0].url) {
      <a
        mat-icon-button
        class="om-hide-external-link-icon"
        [ngClass]="config.actions[0].class"
        [omTooltip]="config.actions[0].label"
        target="_blank"
        [href]="config.actions[0].url"
      >
        <mat-icon [svgIcon]="config.actions[0].icon"></mat-icon>
      </a>
    } @else {
      <button
        mat-icon-button
        [ngClass]="config.actions[0].class"
        [omTooltip]="config.actions[0].label"
        (click)="onClick(config.actions[0].name)"
      >
        <mat-icon [svgIcon]="config.actions[0].icon"></mat-icon>
      </button>
    }
  } @else {
    <button
      #actionMenuTrigger="matMenuTrigger"
      mat-icon-button
      [matMenuTriggerFor]="actionMenu"
      [ngClass]="badgeClass + config.class"
      [matBadge]="badgeText"
      (menuOpened)="onMenuOpened()"
      (menuClosed)="onMenuClosed()"
    >
      @if (actionMenuTrigger.menuOpen) {
        <mat-icon [svgIcon]="config.openIcon || config.icon || 'more'"></mat-icon>
      } @else {
        <mat-icon [svgIcon]="config.icon || 'more'"></mat-icon>
      }
    </button>
    <mat-menu #actionMenu="matMenu" (menuOpened)="onMenuOpened()" (menuOpened)="onMenuClosed()">
      @if (config.actions) {
        <ng-container *ngTemplateOutlet="actionsTemplate; context: { actions: config.actions }"></ng-container>
      } @else {
        @for (section of config.sections; track section; let i = $index) {
          @if (section.actions.length) {
            @if (section.label) {
              <span matSubheader>{{ section.label }}</span>
            }
            <ng-container
              *ngTemplateOutlet="actionsTemplate; context: { section: section, actions: section.actions }"
            ></ng-container>
            @if (i + 1 < config.sections?.length) {
              <mat-divider> </mat-divider>
            }
          }
        }
      }
    </mat-menu>
    <ng-template #actionsTemplate let-actions="actions" let-section="section">
      @for (action of actions; track $index) {
        <span [omTooltip]="action.tooltip">
          @if (action.url) {
            <a
              mat-menu-item
              class="om-hide-external-link-icon"
              [ngClass]="action.class"
              target="_blank"
              [href]="action.url"
              [attr.download]="action.name === 'download' ? 'origin-document' : null"
              [disabled]="action.disabled"
            >
              @if (action.icon) {
                <mat-icon [svgIcon]="action.icon"></mat-icon>
              }
              <span>{{ action.label }}</span>
            </a>
          } @else {
            <button
              mat-menu-item
              [ngClass]="action.class"
              [disabled]="action.disabled"
              (click)="onClick(action.name, section?.name)"
            >
              @if (action.icon) {
                <mat-icon [svgIcon]="action.icon"></mat-icon>
              }
              <span>{{ action.label }}</span>
            </button>
          }
        </span>
      }
    </ng-template>
  }
}
