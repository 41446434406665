import { AuthNotification, NotificationsContainer, StandardNotification } from '@morpho/core';
import { Action, createReducer, on } from '@ngrx/store';
import { NotificationsAction } from './notifications.actions';

export const NOTIFICATIONS_STATE_KEY = 'notifications';

export interface NotificationsState {
  standard: StandardNotification[];
  standardUnseen: number;
  auth: AuthNotification[];
  authUnread: number;
  [key: string]: any;
}

export const initialState: NotificationsState = {
  standard: [],
  standardUnseen: 0,
  auth: [],
  authUnread: 0,
};

function markNotificationAsRead(
  id: number,
  notifications: (StandardNotification | AuthNotification)[],
): (StandardNotification | AuthNotification)[] | null {
  notifications = [...notifications];
  for (let i = 0; i < notifications.length; i += 1) {
    if (notifications[i].id === id) {
      notifications[i] = {
        ...notifications[i],
        is_read: true,
      };
      return notifications;
    }
  }
  return null;
}
function getAuthUnread(notifications: AuthNotification[]): number {
  return notifications.filter(notification => !notification.is_read).length;
}

const reducer = createReducer(
  initialState,
  on(
    NotificationsAction.getNotificationsSuccess,
    (state, action: { params: NotificationsContainer }): NotificationsState => ({
      standard: action.params.notifications,
      standardUnseen: action.params.notifications_count,
      auth: action.params.auth_notifications,
      authUnread: action.params.auth_notifications_count,
    }),
  ),

  on(
    NotificationsAction.markNotificationAsReadSuccess,
    (state, action: { params: { notificationId: number } }): NotificationsState => {
      const updatedStandardNotifications = markNotificationAsRead(action.params.notificationId, state.standard);
      if (updatedStandardNotifications) {
        return { ...state, standard: updatedStandardNotifications as StandardNotification[] };
      }

      const updatedAuthNotifications = markNotificationAsRead(
        action.params.notificationId,
        state.auth,
      ) as AuthNotification[];
      if (updatedAuthNotifications) {
        return {
          ...state,
          auth: updatedAuthNotifications,
          authUnread: getAuthUnread(updatedAuthNotifications),
        };
      }

      return state;
    },
  ),

  on(
    NotificationsAction.markStandardNotificationsAsReadSuccess,
    (state): NotificationsState => ({
      ...state,
      standard: state.standard.map(notification => ({ ...notification, is_read: true })),
    }),
  ),

  on(
    NotificationsAction.markStandardNotificationsAsSeenSuccess,
    (state): NotificationsState => ({
      ...state,
      standardUnseen: 0,
    }),
  ),

  on(
    NotificationsAction.markAuthNotificationsAsReadSuccess,
    (state): NotificationsState => ({
      ...state,
      auth: state.auth.map(notification => ({ ...notification, is_read: true })),
      authUnread: 0,
    }),
  ),

  on(
    NotificationsAction.respondToAuthRequestSuccess,
    (state, action: { params: { notificationId: number } }): NotificationsState => {
      const updatedAuthNotifications = markNotificationAsRead(
        action.params.notificationId,
        state.auth,
      ) as AuthNotification[];
      if (updatedAuthNotifications) {
        return {
          ...state,
          auth: updatedAuthNotifications,
          authUnread: getAuthUnread(updatedAuthNotifications),
        };
      }
      return state;
    },
  ),
);

export function notificationsContainerReducer(state: NotificationsState, action: Action): NotificationsState {
  return reducer(state, action);
}
