import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { QuoteType } from '@morpho/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { ColDef, ColumnMovedEvent, ColumnVisibleEvent, IToolPanelParams, ModelUpdatedEvent } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { AgGridPanel, ColumnSidebarConfig } from '../../ag-grid-panels.model';
import { AgGridPanelsService } from '../../services/ag-grid-panels.service';

@Component({
  standalone: false,
  selector: 'om-column-sidebar-panel',
  templateUrl: './column-sidebar-panel.component.html',
  styleUrls: ['./column-sidebar-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColumnSidebarPanelComponent implements IToolPanelAngularComp, OnDestroy {
  private params: IToolPanelParams;
  config: ColumnSidebarConfig;
  allOptionsSelected = false;
  indeterminate = false;
  columnDefs: ColDef<any>[];

  selectedQuoteType$: Observable<QuoteType> | undefined;
  readonly quoteTypes = [
    {
      label: 'Bid',
      value: QuoteType.Bid,
    },
    {
      label: 'Mid',
      value: QuoteType.Mid,
    },
    {
      label: 'Ask',
      value: QuoteType.Ask,
    },
  ];

  private readonly eventListeners = [
    {
      type: 'modelUpdated',
      function: (event: ModelUpdatedEvent) => this.onModelUpdated(),
    },
    {
      type: 'columnVisible',
      function: (event: ColumnVisibleEvent) => this.onColumnVisible(event),
    },
    {
      type: 'columnMoved',
      function: (event: ColumnMovedEvent) => this.onColumnMoved(event),
    },
  ];

  constructor(private agGridPanelsService: AgGridPanelsService) {}

  agInit(params: IToolPanelParams): void {
    setTimeout(() => this.refresh(params));
  }

  refresh(params: IToolPanelParams): boolean | void {
    this.params = params;
    this.config = this.agGridPanelsService.getPanelConfig(params, AgGridPanel.ColumnSideBar);
    this.selectedQuoteType$ = this.config.quoteType$;
    this.eventListeners.forEach(listener => {
      this.params.api.addEventListener(listener.type, listener.function);
    });
  }

  private onModelUpdated(): void {
    const columnDefs = this.params.api.getColumnDefs();
    this.columnDefs = columnDefs?.length ? columnDefs?.filter((columnDef: ColDef) => !!columnDef.headerName) : [];
    this.allOptionsSelected =
      this.columnDefs != null && [...this.columnDefs].every((colDef: ColDef) => this.checkVisibility(colDef));
  }

  private onColumnVisible(event: ColumnVisibleEvent | any) {
    const colDef: ColDef = event.column?.colDef;
    const isHidden = this.checkVisibility(colDef);
    colDef.hide = !isHidden;
    const colIndex = this.columnDefs?.findIndex((cDef: ColDef) => cDef.colId === colDef.colId);
    if (colIndex) {
      this.columnDefs.splice(colIndex, 1, colDef);
      this.updateColDefCheckState(colDef);
    }
  }

  private onColumnMoved(event: ColumnMovedEvent | any) {
    const groupState = this.params.api.getColumnState();
    const groupedColumn = groupState.find(colDef => colDef.colId === 'ag-Grid-AutoColumn');
    const colDef: ColDef = event.column?.colDef;
    const value = groupedColumn ? 2 : 1;
    const newIndex = event.toIndex - value;
    const colIndex = this.columnDefs.findIndex((cDef: ColDef) => cDef?.colId === colDef?.colId);
    this.columnDefs.splice(colIndex, 1);
    this.columnDefs.splice(newIndex, 0, colDef);
  }

  private checkVisibility(colDef: ColDef): boolean | undefined {
    return this.params.api?.getColumn(colDef.colId || colDef.field || '')?.isVisible();
  }

  private updateColDefCheckState(colDef: ColDef): void {
    this.allOptionsSelected =
      this.columnDefs != null && [...this.columnDefs].every((colDef: ColDef) => this.checkVisibility(colDef));

    this.indeterminate =
      (this.columnDefs ?? []).filter((colDef: ColDef) => !this.checkVisibility(colDef)).length > 0 &&
      !this.allOptionsSelected;
  }

  private updateColumns(colDef: ColDef) {
    const isHidden = this.checkVisibility(colDef);
    this.params.api.setColumnsVisible([colDef?.colId as string], !isHidden);
    this.updateColDefCheckState(colDef);
  }

  toggleVisibility(event: MatCheckboxChange) {
    const colDef: ColDef = event.source.value as ColDef;
    colDef.hide = !event.checked;
    this.updateColumns(colDef);
  }

  toggleAllColumns(checked: boolean) {
    this.allOptionsSelected = checked;
    this.columnDefs.forEach((colDef: ColDef) => {
      this.params.api.setColumnsVisible([colDef?.colId as string], checked);
    });
    this.columnDefs = this.columnDefs?.map((colDef: ColDef) => {
      return {
        ...colDef,
        hide: !checked,
      };
    });
  }

  onChangeQuoteType(quoteType: QuoteType) {
    if (!this.params.context.onQuoteTypeChange) {
      console.error('context.onQuoteTypeChange required for changing quote type');
      return;
    }
    this.params.context.onQuoteTypeChange(quoteType);
  }

  ngOnDestroy() {
    this.eventListeners.forEach(listener => {
      this.params.api.removeEventListener(listener.type, listener.function);
    });
  }
}
