import { ValueAndLabel } from '@morpho/core';

interface BaseFilterConfig {
  id: string;
  label?: string;
  description?: string;
  previewSize?: number;
  default?: any | any[];
}

export type MultipleSelectOption = ValueAndLabel & { selected?: boolean };

export interface SelectFilterOption extends ValueAndLabel {
  selected?: boolean;
}

export enum FilterType {
  SelectSingle = 'select_single',
  SelectMultiple = 'select_multiple',
  Date = 'date',
  SizeAndCurrency = 'size_currency',
}

export interface SelectFilterConfig extends BaseFilterConfig {
  type: FilterType.SelectSingle | FilterType.SelectMultiple;
  options?: MultipleSelectOption[];
  allOptionsSelected?: boolean;
  disableMiniFilter?: boolean;
}
export interface DateFilterConfig extends BaseFilterConfig {
  type: FilterType.Date;
  options?: ValueAndLabel[];
}
export interface SizeFilterConfig extends BaseFilterConfig {
  type: FilterType.SizeAndCurrency;
  options?: ValueAndLabel[];
}

export type FilterSidebarItemConfig = SelectFilterConfig | DateFilterConfig | SizeFilterConfig;
