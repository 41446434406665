import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getCdnUrl } from '../../../../helper.functions';
import { AuthService } from '../../../auth/auth.service';
import { RoutingService } from '../../../routing/routing.service';

@Component({
  standalone: false,
  selector: 'om-not-active',
  templateUrl: './not-active.component.html',
  styleUrls: ['./not-active.component.scss'],
})
export class NotActiveComponent {
  readonly logoUrl = getCdnUrl('/assets/img/origin-markets/logo-square-white.svg');
  constructor(
    private authService: AuthService,
    private router: Router,
    private routingService: RoutingService,
  ) {}

  goBackToLoginPage() {
    this.authService.logout();
  }

  tryToLogin() {
    this.authService.refreshLogin();
  }
}
