import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { BACKEND_DATE_FORMAT, DATE_FORMAT } from '@morpho/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import * as moment from 'moment';

export interface DateCellEditorConfig {
  omParseFormat?: string;
  omDisplayFormat?: string;
}

export interface DateCellEditorParams extends DateCellEditorConfig, ICellEditorParams {}

@Component({
  standalone: false,
  selector: 'om-date-cell-editor',
  templateUrl: './date-cell-editor.component.html',
})
export class DateCellEditorComponent implements AfterViewInit, ICellEditorAngularComp {
  @ViewChild('matDatepicker') matDatepicker: MatDatepicker<Date>;

  selectedDate: any;
  fieldName: string;

  parseFormat: string;
  displayFormat: string;

  private stopEditing: () => void;
  private onKeyDown: (event: KeyboardEvent) => void;

  @HostListener('document:keydown.tab', ['$event'])
  onTabPressed(event: KeyboardEvent) {
    this.stopEditing();
    this.onKeyDown(event);
  }
  agInit(params: DateCellEditorParams): void {
    const fieldName = params.colDef.field;
    if (!fieldName) {
      return;
    }
    this.parseFormat = params.omParseFormat ?? BACKEND_DATE_FORMAT;
    this.displayFormat = params.omDisplayFormat ?? DATE_FORMAT;
    const backendValue = params.data[fieldName] ?? '';
    this.selectedDate = backendValue ? moment(backendValue, this.parseFormat).format(this.displayFormat) : null;
    this.stopEditing = params.stopEditing;
    this.onKeyDown = params.onKeyDown;
  }

  ngAfterViewInit() {
    this.matDatepicker.open();
  }

  getValue() {
    if (!this.selectedDate) {
      return null;
    }

    // if it is not a moment object...
    if (!moment.isMoment(this.selectedDate)) {
      // convert to moment using displayed date format - as is probably the cell value
      this.selectedDate = moment(this.selectedDate, this.displayFormat);
    }
    // if it is now a moment
    if (moment.isMoment(this.selectedDate)) {
      // return formatted string
      return this.selectedDate.format(this.parseFormat);
    }

    return null;
  }

  isPopup(): boolean {
    return false;
  }

  onClose() {
    this.stopEditing();
  }
}
