import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: false,
  selector: 'om-logging-in',
  templateUrl: './logging-in.component.html',
  styleUrls: ['./logging-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoggingInComponent {}
