import { Injectable } from '@angular/core';
import { PRICING_INBOX_URL } from '@morpho/core';
import { environment } from '@morpho/environment';
import { DealerTeam, IssuerTeam, TeamName } from 'apps/bankangle/src/app/constants/teams';
import { action, page } from 'apps/bankangle/src/app/constants/wording';
import { NavigationAction, NavigationItem } from 'apps/bankangle/src/app/models/navigation';
import { PocSettings } from 'apps/bankangle/src/app/models/poc';
import { AuthenticatedUser } from '../state/authenticated-user/authenticated-user.model';
import { CalculatedPermissions } from '../state/permission/permission.model';

@Injectable({
  providedIn: 'root',
})
export class UserLogicService {
  constructor() {}

  private getNavigationPermissions(
    user: AuthenticatedUser,
    pocSettings: PocSettings,
  ): { [key: string]: boolean | undefined } {
    const dealer_dashboard =
      user.isDealer &&
      !([DealerTeam.Legal, DealerTeam.Operations, DealerTeam.Support] as TeamName[]).includes(user.team_name);

    const support_dashboard =
      (user.isDealer && user.team_name === DealerTeam.Support) ||
      (user.isIssuer && user.team_name === IssuerTeam.Support);

    const bulletGridPermissions =
      !(
        [
          DealerTeam.Legal,
          DealerTeam.Operations,
          DealerTeam.Support,
          IssuerTeam.Legal,
          IssuerTeam.Operations,
          IssuerTeam.Support,
          IssuerTeam.InvestorRelations,
        ] as TeamName[]
      ).includes(user.team_name) && !user.isLawyer;

    const curve_analysis = bulletGridPermissions;
    const xccy_pricer = bulletGridPermissions;

    const dealerFundingMatrix =
      user.isStaff ||
      (user.isDealer &&
        !([DealerTeam.Legal, DealerTeam.Operations, DealerTeam.Support] as TeamName[]).includes(user.team_name));

    const bullet_levels = dealerFundingMatrix;
    const callable_levels = dealerFundingMatrix;
    const secondary_screener = dealerFundingMatrix;
    const repack_screener = dealerFundingMatrix;

    const custom_levels =
      user.isDealer &&
      ([DealerTeam.Dcm, DealerTeam.Mtn, DealerTeam.Syndicate, DealerTeam.Structuring] as TeamName[]).includes(
        user.team_name,
      );

    const levelInputs = user.isIssuer && user.team_name === IssuerTeam.Funding;
    const bullet_level_inputs = levelInputs;
    const callable_level_inputs = levelInputs;
    const levels_dealer_view_bullets = levelInputs;
    const levels_dealer_view_callables = levelInputs;

    const template_testing = user.isStaff;

    const syndicate_pricer =
      pocSettings.syndicate_pricer &&
      user.isDealer &&
      ([DealerTeam.Dcm, DealerTeam.Syndicate] as TeamName[]).includes(user.team_name);

    const trade_blotter =
      user.isStaff ||
      user.isLawyer ||
      (user.isDealer &&
        pocSettings.termsheet_workflow &&
        (
          [
            DealerTeam.Dcm,
            DealerTeam.Mtn,
            DealerTeam.Syndicate,
            DealerTeam.FlowSales,
            DealerTeam.StructuredSales,
            DealerTeam.Structuring,
            DealerTeam.Trading,
            DealerTeam.Legal,
            DealerTeam.Operations,
          ] as TeamName[]
        ).includes(user.team_name)) ||
      (user.isIssuer &&
        pocSettings.termsheet_workflow &&
        ([IssuerTeam.Funding, IssuerTeam.Legal, IssuerTeam.Operations] as TeamName[]).includes(user.team_name));

    const create_termsheet =
      (user.isDealer &&
        pocSettings.termsheet_workflow &&
        ([DealerTeam.Dcm, DealerTeam.Mtn, DealerTeam.Syndicate] as TeamName[]).includes(user.team_name)) ||
      (user.isIssuer &&
        pocSettings.termsheet_workflow &&
        pocSettings.issuer_led_trade_workflow &&
        user.team_name === IssuerTeam.Funding);

    const create_final_terms =
      (user.isDealer &&
        pocSettings.termsheet_workflow &&
        ([DealerTeam.Legal, DealerTeam.Operations] as TeamName[]).includes(user.team_name)) ||
      (user.isIssuer &&
        pocSettings.termsheet_workflow &&
        pocSettings.issuer_led_trade_workflow &&
        ([IssuerTeam.Funding, IssuerTeam.Legal, IssuerTeam.Operations] as TeamName[]).includes(user.team_name));

    const create_trade = create_termsheet || create_final_terms;

    const dealers = !user.isDealer;
    const issuers = !(user.isIssuer && user.team_name === IssuerTeam.Support);
    const users = user.isStaff;

    const email_viewer = user.isStaff;

    const trade_ids_manager =
      (pocSettings.trade_id_manager && (user.team_name === IssuerTeam.Legal || user.team_name === DealerTeam.Legal)) ||
      user.isStaff;

    return {
      dealer_dashboard,
      support_dashboard,
      curve_analysis,
      bullet_levels,
      callable_levels,
      secondary_screener,
      repack_screener,
      custom_levels,
      levelInputs,
      bullet_level_inputs,
      callable_level_inputs,
      levels_dealer_view_bullets,
      levels_dealer_view_callables,
      template_testing,
      trade_blotter,
      create_trade,
      create_termsheet,
      create_final_terms,
      dealers,
      issuers,
      users,
      email_viewer,
      syndicate_pricer,
      xccy_pricer,
      trade_ids_manager,
    };
  }

  calculatePermissions(user: AuthenticatedUser, pocSettings: PocSettings): CalculatedPermissions {
    // NOTE: add class 'is-new' to an option to show the "NEW" badge
    const fullNavigationOptions: NavigationItem[] = [
      {
        id: 'home_section',
        label: page.HOME,
        icon: 'home',
        isSectionCollapsable: true,
        options: [
          {
            id: 'dealer_dashboard',
            label: page.DEALER_DASHBOARD,
            url: '/dashboard',
          },
          {
            id: 'support_dashboard',
            label: page.SUPPORT_DASHBOARD,
            url: '/user-list',
          },
        ],
      },
      {
        id: 'section_marketplace',
        label: page.FUNDING_LEVELS,
        icon: 'funding_levels',
        options: [
          {
            id: 'syndicate_pricer',
            label: page.SYNDICATE_PRICER,
            url: PRICING_INBOX_URL,
          },
          {
            id: 'curve_analysis',
            label: page.CURVE_ANALYSIS,
            url: '/curve-analysis',
          },
          {
            id: 'bullet_levels',
            label: page.BULLET_LEVELS,
            url: '/levels/bullets/privatePlacements',
          },
          {
            id: 'callable_levels',
            label: page.CALLABLE_LEVELS,
            url: '/levels/callables',
          },
          {
            id: 'custom_levels',
            label: page.CUSTOM_LEVELS,
            url: '/levels/custom/bullets',
          },
          {
            id: 'secondary_screener',
            label: page.SECONDARY_SCREENER,
            url: '/levels/bullets/secondaryCurves',
          },
          {
            id: 'repack_screener',
            label: page.REPACK_SCREENER,
            url: '/levels/bullets/bondDatabase',
          },
          {
            id: 'bullet_level_inputs',
            label: page.BULLET_LEVELS_INPUT,
            url: '/levels/input/bullets',
          },
          {
            id: 'levels_dealer_view_bullets',
            label: page.BULLET_LEVELS_ISSUER,
            url: '/levels/bullets',
          },
          {
            id: 'callable_level_inputs',
            label: page.CALLABLE_LEVELS_INPUT,
            url: '/levels/input/callables',
          },
          {
            id: 'levels_dealer_view_callables',
            label: page.CALLABLE_LEVELS_ISSUER,
            url: '/levels/callables',
          },
          {
            id: 'xccy_pricer',
            label: page.PRICER_DEBUG,
            url: '/xccy-pricer',
            class: 'is-new',
          },
        ],
      },
      {
        id: 'section_template_testing',
        label: page.TEMPLATES,
        icon: 'templates',
        options: [
          {
            id: 'template_testing',
            label: page.TEMPLATES,
            url: '/templates',
          },
        ],
      },
      {
        id: 'documentation_section',
        label: page.DOCUMENTATION_SECTION,
        icon: 'trades',
        options: [
          {
            id: 'trade_blotter',
            label: page.TRADE_PAGES,
            url: '/trades',
          },
          {
            id: 'create_trade',
            label: action.CREATE_TRADE,
            action: NavigationAction.CreateTrade,
          },
        ],
      },
      {
        id: 'custom_section',
        label: page.CUSTOM_SECTION,
        icon: 'custom_pages',
        options: [
          {
            id: 'trade_ids_manager',
            label: page.TRADE_IDS_MANAGER,
            url: '/trade-ids-manager',
          },
        ],
      },
      {
        id: 'section_network',
        label: 'Network',
        icon: 'clients',
        isSectionCollapsable: true,
        options: [
          {
            id: 'dealers',
            label: page.DEALER_PAGES,
            url: '/dealers',
          },
          {
            id: 'issuers',
            label: page.ISSUER_PAGES,
            url: '/issuers',
          },
          {
            id: 'users',
            label: page.USER_PAGES,
            url: '/user-list',
          },
        ],
      },
      {
        id: 'section_emails',
        label: 'Emails',
        icon: 'email',
        isSectionCollapsable: true,
        options: [
          {
            id: 'email_viewer',
            label: page.EMAIL_PAGES,
            url: '/emails',
          },
        ],
      },
    ];

    const navigationPermission = this.getNavigationPermissions(user, pocSettings);

    const navigation = fullNavigationOptions
      .reduce((sections: NavigationItem[], section: NavigationItem) => {
        section.options = section.options?.reduce((childOptions: NavigationItem[], childOption: NavigationItem) => {
          if (navigationPermission[childOption.id]) {
            childOptions.push(childOption);
          }
          return childOptions;
        }, []);

        if (section.options?.length) {
          sections.push(section);
        }

        return sections;
      }, [])
      .map((section: NavigationItem) => {
        if (section.isSectionCollapsable && section.options?.length === 1) {
          section = { ...section, ...section.options[0] };
          delete section.options;
          return section;
        }

        if (section.url) {
          return section;
        }

        for (const option of section.options as NavigationItem[]) {
          if (option.url) {
            section.url = option.url;
            return section;
          }
        }

        return section;
      });

    const canViewAuthNotifications = !!(
      (user.isIssuer && user.team_name === IssuerTeam.Funding) ||
      (user.isDealer &&
        ![DealerTeam.Legal, DealerTeam.Operations, DealerTeam.Support].includes(user.team_name as DealerTeam))
    );
    return {
      navigation,
      canViewAuthNotifications,
      canCreateTrade: !!navigationPermission.create_termsheet,
      canCreateFinalTerms: !!navigationPermission.create_final_terms,
      hasDashboardAccess: !!(navigationPermission.dealer_dashboard || navigationPermission.issuer_dashboard),
      isCustomerSupportChatEnabled: !user.isImpersonation,
      areToursEnabled: !!(['dev', 'qa', 'demo2', 'login2'].includes(environment.name) && !user.isImpersonation),
    };
  }
}
