import { Editor, Element, Node, NodeEntry, Transforms } from 'slate';
import { ElementType } from '../../constants/rich-text.constants';
import { RichTextParagraph } from '../../models/rich-text.model';
import { EditorHelper } from '../editor-helper';
import { InlineStyle, TextAlign } from './text.constants';

function alignText(editor: Editor, position: TextAlign) {
  Editor.withoutNormalizing(editor, () => {
    const newProperties: Partial<RichTextParagraph> = {
      text_align: position === TextAlign.Left ? undefined : position,
    };
    Transforms.setNodes(editor, newProperties, {
      match: node => {
        return (node as any).type === ElementType.Paragraph;
      },
      mode: 'lowest',
    });
  });
}

function setIndentation(editor: Editor, amount: number): boolean {
  // todo, += indent of paragraph where it is not direct child of list item
  // add `indentation` property to paragraph, bind this in the template to have e.g. margin-left=20*indentation
  let hasAppliedIndentation = false;
  Editor.withoutNormalizing(editor, () => {
    const paragraphs = EditorHelper.getParagraphBlocks(editor);
    paragraphs.forEach(([node, path]) => {
      const newIndentation = Math.max((node.indentation ?? 0) + amount, 0) || undefined;
      Transforms.setNodes<RichTextParagraph>(editor, { indentation: newIndentation }, { at: path });
    });
    hasAppliedIndentation = !!paragraphs.length;
  });
  return hasAppliedIndentation;
}

function indent(editor: Editor) {
  return setIndentation(editor, 1);
}

function outdent(editor: Editor) {
  return setIndentation(editor, -1);
}

function getActiveInlineProperties(editor: Editor): Record<InlineStyle, boolean> {
  return <Record<InlineStyle, boolean>>Editor.marks(editor) ?? {};
}

function getIsActiveTextAlignment(editor: Editor, textAlign: TextAlign): boolean {
  if (!editor.selection) {
    return false;
  }

  const [link] = Editor.nodes(editor, {
    at: editor.selection,
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.text_align === textAlign,
  });
  return !!link ?? false;
}

function isActiveElement(editor: Editor, elementType: ElementType): NodeEntry<Node> | null {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && Element.isElement(n) && n.type === elementType,
  });
  return link ?? null;
}

function toggleInline(editor: Editor, style: InlineStyle) {
  const active = getActiveInlineProperties(editor);
  if (active[style]) {
    Editor.removeMark(editor, style);
  } else {
    Editor.addMark(editor, style, true);
  }
}

function isEmpty(editor: Editor) {
  return !EditorHelper.getPlainText(editor).length;
}

export const TextEditor = {
  alignText,
  getActiveInlineProperties,
  getIsActiveTextAlignment,
  indent,
  isActiveElement,
  isEmpty,
  outdent,
  toggleInline,
};
