import { Pipe, PipeTransform } from '@angular/core';
import { RoutingService } from '../routing.service';

@Pipe({
  standalone: false,
  name: 'routing',
})
export class RoutingPipe implements PipeTransform {
  constructor(private routingService: RoutingService) {}

  transform(path: string, type: 'routerLink' | 'queryParams', additionalData?: any): any {
    const route = this.routingService.parseRoute(path);
    switch (type) {
      case 'routerLink':
        return route.routerLink;
      case 'queryParams':
        return { ...route.queryParams, ...additionalData };
    }
  }
}
