import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: false,
  name: 'emptyFiller',
})
export class EmptyFillerPipe implements PipeTransform {
  transform(value: string, fillerText?: string): unknown {
    return value || fillerText || '-';
  }
}
