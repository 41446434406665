<h2 mat-dialog-title>
  Add Pricing Tabs ({{ dialogStep }}/2)
  <button mat-icon-button mat-dialog-close><mat-icon svgIcon="close"></mat-icon></button>
</h2>

<div mat-dialog-content>
  @switch (dialogStep) {
    @case (1) {
      @if (tabFormModel) {
        <om-dynamic-form [model]="tabFormModel"></om-dynamic-form>
      }
    }
    @case (2) {
      <om-maturities-table #maturitiesTable [tabFormModel]="tabFormModel"></om-maturities-table>
    }
  }
</div>

<div class="mat-mdc-dialog-actions">
  <button mat-button (click)="previous()">
    {{ dialogStep === 1 ? 'Cancel' : 'Back' }}
  </button>
  <button mat-button (click)="next()">
    {{ dialogStep === 1 ? 'Next' : 'Save' }}
    @if (isLoading) {
      <mat-spinner class="is-small"></mat-spinner>
    }
  </button>
</div>
