<mat-toolbar class="is-expanding">
  <section>
    <mat-form-field class="is-inline">
      @if (issuerFormControl) {
        <om-autocomplete-input
          [formControl]="issuerFormControl"
          placeholder="Select issuers"
          [options]="issuerOptions$ | async"
          [suggestions]="issuerSuggestions$ | async"
          prefixIcon="search"
          allowValueNotInOptions
          multiple
        >
        </om-autocomplete-input>
      }
    </mat-form-field>
  </section>
</mat-toolbar>
