import { Action, createReducer, on } from '@ngrx/store';
import { RatingsAction } from './ratings.actions';
import { EntityRatingsMap, RatingsState } from './ratings.model';

export const RATINGS_STATE_KEY = 'ratings';

const initialState: RatingsState = {};

const reducer = createReducer(
  initialState,

  on(RatingsAction.setRatingsForIssuingEntities, (state, action): RatingsState => {
    const ratings = action.params.ratings.reduce((accumulator, entity) => {
      (accumulator as EntityRatingsMap)[entity.id] = entity.ratings;
      return accumulator;
    }, {});

    return {
      ...state,
      issuingEntityRatings: { ...state.issuingEntityRatings, ...ratings },
    };
  }),
);

export function ratingsReducer(state: RatingsState, action: Action) {
  return reducer(state, action);
}
