export enum NotificationType {
  TermsheetShared = 'termsheet_shared',
  TradeReviewRequested = 'trade_review_requested',
  TradeWorkingGroupUpdated = 'trade_working_group_updated',
}

export interface NotificationsContainer {
  notifications: StandardNotification[];
  notifications_mark_all_read_url: string;
  notifications_mark_all_seen_url: string;
  notifications_see_all_url: string;
  notifications_count: number;
  auth_notifications_url: string;
  auth_notifications_count: number;
  auth_notifications: AuthNotification[];
}

interface BaseNotification {
  content: string;
  extra_content?: string | null;
  id: number;
  is_read: boolean;
  mark_read_url: string;
  title: string;
}

export interface StandardNotification extends BaseNotification {
  created: string;
  data: NotificationData;
  link_name: string;
  link_url: string | null;
  modified: string;
  notice_type: string;
}

export interface AuthNotification extends BaseNotification {
  accept_url?: string | null;
  decline_url?: string | null;
  profile_url?: string | null;
  timestamp: string;
}

export interface NotificationData {
  size?: string;
  stage?: string;
  tenor?: string;
  dealer?: string;
  issuer?: string;
  is_test?: boolean;
  product?: string;
  actor_id?: number;
  currency?: string;
  trade_id?: number;
  is_tapped?: boolean;
  structure?: string;
  actor_name?: string;
  actor_team?: string;
  actor_type?: string;
  enquiry_type?: string;
  actor_company?: string;
  documentation?: string;
  maturity_date?: string;
  final_termsheet?: string;
  callable_structure?: any;
  comment?: string | null;
  message?: string;
  already_picked_up?: boolean;
  change_set?: NotificationChangeset[];
  indicative_termsheet?: string;
  expiration_date?: string;
  reviewers?: number[];
  added_user_ids?: number[];
  removed_user_ids?: number[];
  working_group_id?: number;
}

export interface NotificationChangeset {
  new_value: boolean | number | string | string;
  old_value?: any;
  field_name: string;
  field_label: string;
}
