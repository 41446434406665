import { Component, ElementRef, HostBinding, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreApiService, PopupService, RoutingService } from '@morpho/core';
import { ERROR_ON_FORM, ERROR_ON_FORM_SECTION, FormConfig } from '@morpho/form';
import { catchError, filter, Observable, of, startWith, tap } from 'rxjs';
import {
  CreateTradeModalToggleButton,
  DynamicFormModalCloseEvent,
  DynamicFormModalParams,
  DynamicFormModel,
  FormModelParams,
} from '../../models/dynamic-form.model';
import { ElementValueType } from '../../pipes/element-type-from-value.pipe';
import { DynamicFormApiService } from '../../services/dynamic-form-api.service';
import { DynamicFormService } from '../../services/dynamic-form.service';

@Component({
  standalone: false,
  selector: 'om-dynamic-form-modal',
  templateUrl: './dynamic-form-modal.component.html',
  styleUrls: ['./dynamic-form-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicFormModalComponent {
  @HostBinding('class.is-loading') isLoading = false;
  @ViewChild('content') content: ElementRef;

  model: DynamicFormModel;
  data: DynamicFormModalParams;
  formConfig: FormConfig;
  currentSectionIndex = 0;
  finalSectionIndex: number;
  buttonToggleFormControl = new FormControl();
  selectedToggleButtonConfig: CreateTradeModalToggleButton;
  savedFormModelMap: { [key: string]: DynamicFormModel } = {};

  public readonly pendingPromises = { count: 0 };
  readonly elementValueType = ElementValueType;

  constructor(
    private coreApiService: CoreApiService,
    @Inject(MAT_DIALOG_DATA) data: DynamicFormModalParams,
    public dialogRef: MatDialogRef<DynamicFormModalComponent>,
    private dynamicFormService: DynamicFormService,
    private dynamicFormApiService: DynamicFormApiService,
    private popupService: PopupService,
    private routingService: RoutingService,
  ) {
    this.data = data;
    this.formConfig = { method: data.method, ...data.formConfig };

    const params = this.generateFormModelParams(data);

    this.loadFormModel(params).subscribe(model => {
      if (model) {
        this.setModel(model);
      }
    });

    if (data.toggle_buttons) {
      this.buttonToggleFormControl.setValue(data.toggle_buttons.initial_value);
    }

    this.buttonToggleFormControl.valueChanges
      .pipe(
        startWith(data.toggle_buttons?.initial_value),
        filter(value => !!value),
        tap(value => {
          const config = data.toggle_buttons?.config[value];

          if (config) {
            this.selectedToggleButtonConfig = config;

            if (this.savedFormModelMap[value]) {
              this.setModel(this.savedFormModelMap[value]);
              return;
            }

            const params = this.generateFormModelParams(config);

            this.loadFormModel(params).subscribe(model => {
              if (model) {
                this.savedFormModelMap[value] = model;
                this.setModel(model);
              }
            });
          }
        }),
      )
      .subscribe();
  }

  private generateFormModelParams(config: DynamicFormModalParams | CreateTradeModalToggleButton): FormModelParams {
    return {
      options: config.options,
      url: config.url,
      optionsUrl: config.optionsUrl,
    };
  }

  private loadFormModel(params: FormModelParams): Observable<any> {
    if (params.options) {
      return of(this.dynamicFormService.generateFormModel(params.options, this.formConfig));
    } else if (params.url || params.optionsUrl) {
      const optionsUrl =
        params.optionsUrl || this.dynamicFormApiService.generateOptionsApiEndpoint(params.url as string);

      return this.dynamicFormService.generateFormModelFromApi(optionsUrl, this.formConfig).pipe(
        catchError(error => {
          console.log(error);
          return of(null);
        }),
      );
    }

    return of(null);
  }

  private setModel(model?: DynamicFormModel) {
    if (model) {
      this.model = model;
    }
    this.finalSectionIndex = Math.max(0, this.model.sectionsForDisplay.length - 1);
    this.currentSectionIndex = Math.min(this.currentSectionIndex, this.finalSectionIndex);
  }

  onInputChange(event: any) {
    const name = event.name;
    this.pendingPromises.count += 1;
    this.dynamicFormService
      .onUserInput(name, this.model, this.formConfig)
      .then(model => {
        this.pendingPromises.count -= 1;
        this.setModel();
      })
      .catch(() => (this.pendingPromises.count -= 1));
  }

  onPrevious() {
    if (this.currentSectionIndex === 0) {
      this.dialogRef.close();
    } else {
      this.currentSectionIndex -= 1;
      this.scrollTop();
    }
  }

  onNext() {
    if (!this.model) {
      return;
    }
    if (!this.finalSectionIndex) {
      this.onSubmit();
      return;
    }

    const currentSection = this.model.sectionsForDisplay[this.currentSectionIndex];
    const isSectionValid = this.dynamicFormService.checkFormValidityAndTouch(this.model, [currentSection]);

    if (isSectionValid) {
      if (this.finalSectionIndex === this.currentSectionIndex) {
        this.onSubmit();
      } else {
        this.currentSectionIndex += 1;
        this.scrollTop();
      }
    } else {
      this.popupService.error(this.finalSectionIndex ? ERROR_ON_FORM_SECTION : ERROR_ON_FORM);
    }
  }

  private onSubmit() {
    if (!this.model) {
      return;
    }
    if (!this.dynamicFormService.checkFormValidityAndTouch(this.model)) {
      this.popupService.error(ERROR_ON_FORM);
      return;
    }

    const fieldMapping = this.model.fieldMapping;
    const submitUrl = this.selectedToggleButtonConfig?.url || this.data?.url;

    this.isLoading = true;
    this.dynamicFormService.getValueToSubmit(this.model, this.formConfig).subscribe(formValue => {
      if (!submitUrl) {
        if (this.selectedToggleButtonConfig?.submitAction) {
          this.selectedToggleButtonConfig.submitAction(formValue);
        }
        const closeEvent: DynamicFormModalCloseEvent = {
          apiResponse: {},
          formValue,
          fieldMapping,
        };
        this.dialogRef.close(closeEvent);
        return;
      }

      this.coreApiService
        .callApi(
          submitUrl,
          this.data.submit_mapping ? this.data.submit_mapping(formValue) : formValue,
          this.data.method,
        )
        .subscribe({
          next: response => {
            if (this.data.success_message) {
              this.popupService.message(this.data.success_message);
            }
            if (this.data.success_url) {
              this.routingService.routeTo(this.data.success_url);
            }
            this.isLoading = false;
            const closeEvent: DynamicFormModalCloseEvent = {
              apiResponse: response,
              formValue,
              fieldMapping,
            };
            this.dialogRef.close(closeEvent);
          },
          error: error => {
            this.handleServerError(error);
            this.isLoading = false;
            if (error.modal) {
              this.dialogRef.close({ apiResponse: error });
            } else {
              this.popupService.apiError(error);
            }
          },
        });
    });
  }

  private handleServerError(error: any) {
    const errorFields = Object.keys(error);

    let firstErrorIndex: number | undefined;

    errorFields.forEach(errorField => {
      const errorPanelIndex = this.model.sections.reduce((acc, val, i) => {
        if (val.fieldNames.includes(errorField)) {
          return i;
        }
        return acc;
      }, null);
      if (errorPanelIndex === null) {
        return;
      }
      if (firstErrorIndex === undefined || firstErrorIndex > errorPanelIndex) {
        firstErrorIndex = errorPanelIndex;
      }
    });

    if (firstErrorIndex === undefined) {
      return;
    }

    this.currentSectionIndex = firstErrorIndex;

    setTimeout(() => {
      this.dynamicFormService.setApiErrorsOnFormModel(this.model, error);
      this.dynamicFormService.updateFormModel(this.model, this.formConfig);
    });
  }

  private scrollTop() {
    if (this.content?.nativeElement) {
      this.content.nativeElement.scrollTop = 0;
    }
  }
}
