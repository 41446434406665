import { Editor, Range, Transforms } from 'slate';
import { jsx } from 'slate-hyperscript';
import { ElementType } from '../../constants/rich-text.constants';
import {
  RichTextEquation,
  RichTextInlineText,
  RichTextList,
  RichTextListItem,
  RichTextParagraph,
} from '../../models/rich-text.model';
import { EditorHelper } from '../editor-helper';
import { EquationEditor } from '../equation/equation-commands';
import { TextAlign } from '../text/text.constants';
import { deserializeEquation } from './pasting-equation';

export const withPasting = (editor: Editor) => {
  const { insertData } = editor;

  editor.insertData = (data: DataTransfer) => {
    const html = data.getData('text/html');
    // const html = windowsWordEquationInDocHtmlClipboard;
    // const html = windowsWordEquationHtmlClipboard;
    // const html = windowsWordEquationHtmlClipboardSmall;
    // const html = windowsWordExampleHtmlClipboard;
    // const html = macPagesExampleHtmlClipboard;
    // const html = wordOnlineExampleHtmlClipboard;
    // const html = googleDocExampleHtmlClipboard;
    // const html = windowsWordDisclaimerClipboard;
    // const html = macWordDisclaimerClipboard;

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html');
      const headerChildren = Array.from(parsed.head.children);
      const styleSheetNode = headerChildren.find(el => el.nodeName === 'STYLE');
      const source = (
        headerChildren.find(
          el => el.nodeName === 'META' && (el as HTMLMetaElement).name === 'ProgId',
        ) as HTMLMetaElement
      )?.content?.split('.')[0];

      let styleSheet: Record<string, Record<string, string>> | undefined;
      if (styleSheetNode) {
        const styleSheetArray = styleSheetNode?.textContent
          ?.split('}')
          .filter(el => !(el.includes('<!--') || el.includes('-->')))
          .map(el => el.trim());

        styleSheet = styleSheetArray?.reduce((acc: Record<string, Record<string, string>>, el) => {
          const [selector, properties] = el.split('{');
          acc[selector.replace(/[\n.]|↵/g, '').trim()] = properties
            .replaceAll(';', '')
            .trim()
            .split('\n\t')
            .reduce((acc, el) => {
              const [key, value] = el.split(':');
              return { ...acc, [key.trim()]: value.trim() };
            }, {});

          return acc;
        }, {});
      }
      if (!(parsed.body.firstChild as HTMLElement)?.hasAttribute?.('slateelement')) {
        const fragment = deserialize(parsed.body, styleSheet, source);

        if (fragment?.length) {
          Editor.withoutNormalizing(editor, () => {
            if (editor.selection && Range.isExpanded(editor.selection)) {
              Transforms.delete(editor);
            }
            const [[, currentParagraphPath]] = EditorHelper.getParagraphBlocks(editor);
            Transforms.splitNodes(editor);

            const pathToInsert = [
              ...currentParagraphPath.slice(0, -1),
              currentParagraphPath[currentParagraphPath.length - 1] + 1,
            ];

            EditorHelper.insertNestedBlock(editor, fragment, pathToInsert);
          });
          return;
        }
      }
    }

    insertData(data);
  };

  return editor;
};

function cleanText(text: string | null) {
  return text
    ?.replace('', ' ') // windows word sometimes has unicode \x13\x10 instead of space
    .replace(/[\n\r\t]+/g, ' ');
}

type extractOptions = {
  listItemDepth?: number;
};

export const extractInlineText = (
  element: HTMLElement,
  parentAttributes: Partial<RichTextInlineText>,
  options?: extractOptions,
) => {
  const attributes = { ...parentAttributes };

  if (element.nodeType === Node.COMMENT_NODE) {
    const comment = element.textContent;
    if (comment?.startsWith('[if gte msEquation 12]')) {
      const latex = deserializeEquation(element);
      return EquationEditor.createEquationElement(latex);
    }
  }

  switch (element.nodeName) {
    case 'B':
      attributes.bold = true;
      break;
    case 'I':
      attributes.italic = true;
      break;
    case 'U':
      attributes.underline = true;
      break;
    default:
      break;
  }

  let children: any = Array.from(element.childNodes);

  let sliceIndex = 0;
  if (
    element.nodeName.includes(`STYLE='MSO-LIST:IGNORE'`) ||
    element.getAttribute?.('style')?.includes('mso-list:Ignore')
  ) {
    sliceIndex = 2;
    let hasWhitespaceAtBeginning = true;
    while (hasWhitespaceAtBeginning && children.length) {
      if (!cleanText(children[0].textContent)?.trim()) {
        children = children.slice(1);
      } else {
        hasWhitespaceAtBeginning = false;
      }
    }
  }

  children = children
    .slice(sliceIndex)
    .map((child: HTMLElement) => extractInlineText(child, attributes, options))
    .flat();

  if (element.nodeName === '#text') {
    const text = cleanText(element.textContent);
    const node = jsx('text', { ...attributes, text }, children);
    return node;
  } else {
    return children;
  }
};

export const extractParagraph = (element: HTMLElement, options?: extractOptions) => {
  const children = extractInlineText(element, {}, options).flat();

  const textAlign: TextAlign = element
    .getAttribute('style')
    ?.split(';')
    .find(el => el.startsWith('text-align'))
    ?.slice(11)
    .trim() as TextAlign;

  const node: RichTextParagraph = {
    type: ElementType.Paragraph,
    children,
    ...([TextAlign.Right, TextAlign.Center, TextAlign.Justify].includes(textAlign) ? { text_align: textAlign } : {}),
  };
  return node;
};

export const extractEquation = (element: HTMLElement, options?: extractOptions) => {
  const children = extractInlineText(element, {}, options).flat();
  const content = deserializeEquation(element);
  const node: RichTextEquation = { type: ElementType.Equation, children, content };
  return node;
};

const getChildNodes = (element: HTMLElement) => {
  return Array.from(element.childNodes).filter(child => child.nodeType !== 3);
};

export const extractTable = (
  element: HTMLElement,
  styleSheet?: Record<string, Record<string, string>>,
  source?: string,
) => {
  let isComplete = false;
  const nodes: any = getChildNodes(element)
    .map((child: HTMLElement) => {
      if (child.nodeName === 'TR') {
        const columns = getChildNodes(child)
          .filter(column => column.nodeName !== '#comment')
          .map((column: HTMLElement) => {
            const childNodes = Array.from(column.childNodes);
            let paragraphs: any[];
            if (source === 'Excel') {
              const styles = styleSheet?.[column.className];
              const parentAttributes: Partial<RichTextInlineText> = {};
              let textAlign: TextAlign | undefined = (column as any).align;

              if (styles) {
                const fontWeight = styles['font-weight'];
                const fontStyle = styles['font-style'];
                const textDecoration = styles['text-decoration'];
                const cellTextAlign = styles['text-align'];

                if (fontWeight) {
                  parentAttributes.bold = fontWeight === 'bold' || fontWeight === '700';
                }

                if (fontStyle) {
                  parentAttributes.italic = fontStyle === 'italic';
                }

                if (textDecoration) {
                  parentAttributes.underline = textDecoration === 'underline';
                }

                if (cellTextAlign) {
                  textAlign = cellTextAlign as TextAlign;
                }
              }

              paragraphs = [
                {
                  type: ElementType.Paragraph,
                  children: childNodes
                    .map((textBlock: HTMLElement) => extractInlineText(textBlock, parentAttributes, {}))
                    .flat(),
                  ...(textAlign ? { text_align: textAlign } : {}),
                },
              ];
            } else {
              paragraphs = childNodes.reduce((accumulator: any, textBlock: HTMLElement) => {
                if (textBlock.nodeName !== '#text') {
                  accumulator.push(extractParagraph(textBlock));
                }
                return accumulator;
              }, []);
            }
            return {
              type: ElementType.TableDataCell,
              children: paragraphs,
            };
          });
        return {
          type: ElementType.TableRow,
          children: columns,
        };
      } else if (child.nodeName === 'TBODY') {
        isComplete = true;
        return extractTable(child, styleSheet, source);
      } else if (child.nodeName !== '#comment' && child.nodeName !== 'colgroup') {
        isComplete = true;
        return extractParagraph(child);
      }
    })
    .filter(node => !!node);

  if (isComplete) {
    return nodes;
  }
  return {
    type: ElementType.Table,
    children: nodes,
  };
};

export const deserialize = (
  element: HTMLElement,
  styleSheet?: Record<string, Record<string, string>>,
  source?: string,
) => {
  if (
    ['MsoListParagraphCxSpFirst', 'MsoListParagraphCxSpMiddle', 'MsoListParagraphCxSpLast'].includes(element.className)
  ) {
    const styleString = element.getAttribute('style');
    const listItemDepth = Number(
      styleString
        ?.match(/mso-list:[a-z0-9 ]*/)?.[0]
        ?.split(' ')[1]
        ?.replace('level', '') || 1,
    );

    const paragraph = extractParagraph(element, { listItemDepth });
    const listItem: RichTextListItem = { type: ElementType.ListItem, children: [paragraph] };
    const type = cleanText(element.textContent)
      ?.trim()
      ?.match(/^[A-Za-z0-9]/)
      ? ElementType.OrderedList
      : ElementType.UnorderedList;

    let node: RichTextList = { type, children: [listItem] };
    for (let i = 0; i < listItemDepth - 1; i += 1) {
      node = { type, children: [node] };
    }
    return node;
  }

  if (element.nodeName === 'LI') {
    // todo extract as nested list
    return extractParagraph(element);
  }

  if (element.nodeName === 'P') {
    return extractParagraph(element);
  }

  if (element.nodeName === 'TABLE') {
    return extractTable(element, styleSheet, source);
  }

  const nodes: any = Array.from(element.childNodes)
    .map((child: any) => deserialize(child, styleSheet, source))
    .flat();

  return nodes;
};
