import { Component, HostBinding, Input } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Sidenav } from '../sidenav.models';

@Component({
  standalone: false,
  selector: 'om-sidenav-toggle',
  templateUrl: './sidenav-toggle.component.html',
})
export class SidenavToggleComponent {
  @HostBinding('attr.footer') footer = true;

  @Input() position: Sidenav['position'];
  @Input() toggleAction: Action<string>;

  constructor(private store: Store<any>) {}

  toggleSidebar() {
    this.store.dispatch(this.toggleAction);
  }
}
