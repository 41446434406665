import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilService, ValueAndLabel } from '@morpho/core';
import { DynamicFormModel } from '@morpho/dynamic-form';
import { FormService } from '@morpho/form';
import { Store } from '@ngrx/store';
import { StateService } from 'apps/bankangle/src/app/core/services/state.service';
import { Observable, combineLatest, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { SyndicatePricerApiService } from '../../../services/syndicate-pricer-api.service';
import { SyndicatePricerUtilService } from '../../../services/syndicate-pricer-util.service';
import { Maturities, PricingCompletionState } from '../../../state/pricing-completion/pricing-completion.model';
import { PricingCompletionSelector } from '../../../state/pricing-completion/pricing-completion.selectors';
import { getDisplayMaturitiesForTab } from '../../../syndicate-pricer.functions';

@Component({
  standalone: false,
  selector: 'om-maturities-table',
  templateUrl: './maturities-table.component.html',
  styleUrls: ['./maturities-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaturitiesTableComponent implements OnInit {
  @Input() tabFormModel: DynamicFormModel;
  public get maturities() {
    return this.maturitiesForm.value;
  }

  maturitiesForm: FormGroup;
  rows: ValueAndLabel[];
  warningMessages: string[];

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private store: Store<PricingCompletionState>,
    private syndicatePricerUtilService: SyndicatePricerUtilService,
    private utilService: UtilService,
    private formService: FormService,
    private syndicatePricerApiService: SyndicatePricerApiService,
  ) {}

  ngOnInit() {
    const tabForm = this.tabFormModel.form;
    const tabFormValue = tabForm.getRawValue();

    const maturitiesForm = this.formBuilder.group({});

    const existingMaturityMap$: Observable<Maturities> = this.store
      .select(PricingCompletionSelector.pricingTabDataMap)
      .pipe(
        take(1),
        map(pricingTabDataMap => {
          const maturityMap: Maturities = {};
          if (pricingTabDataMap) {
            Object.keys(pricingTabDataMap ?? {}).forEach(tabName => {
              maturityMap[tabName] = getDisplayMaturitiesForTab(tabName, pricingTabDataMap).join(',');
            });
          }
          return maturityMap;
        }),
      );

    const initMaturitiesForm$ = of(null).pipe(
      map(() => {
        return this.utilService.getPossiblePermutations([
          tabFormValue.currencies,
          tabFormValue.coupon_types,
          tabFormValue.seniorities,
        ]);
      }),
      withLatestFrom(this.stateService.get.constants$),
      map(([permutations, constants]) => {
        this.validateCurves(tabFormValue, permutations);
        return permutations.map(([currency, coupon_type, seniority]: [string, string, string]) => {
          return this.syndicatePricerUtilService.generatePricingTabFromObject(
            { currency, coupon_type, seniority },
            constants,
          );
        });
      }),
      withLatestFrom(existingMaturityMap$),
      map(([newTabs, maturityMap]) => {
        const existingTabs = Object.keys(maturityMap);
        const newTabsMaturityMap: Record<string, any> = {};
        newTabs.forEach(tab => {
          const tabName = tab.value.toString();
          if (!existingTabs.includes(tabName)) {
            newTabsMaturityMap[tabName] = tabFormValue.maturities;
          }
        });
        return newTabsMaturityMap;
      }),
      tap(maturityMap => {
        Object.entries(maturityMap).forEach(([tabName, maturity]) => {
          maturitiesForm.addControl(tabName, new FormControl(maturity));
        });
      }),
      tap(() => {
        this.maturitiesForm = maturitiesForm;
        this.updateRows();
      }),
    );

    initMaturitiesForm$.subscribe();
  }

  validateCurves(formValue: any, permutations: unknown[][]) {
    combineLatest([this.store.select(PricingCompletionSelector.issuingEntities)])
      .pipe(
        take(1),
        switchMap(([pricingTabEntityIds]) => {
          const pricingRequestEntityIds = this.syndicatePricerUtilService.getEntityIdsFromBody(formValue);
          const request: any[] = [];
          const source = pricingRequestEntityIds ? 'create-pricing-request' : 'add-pricing-tab';
          const entity_ids = pricingRequestEntityIds ?? Object.keys(pricingTabEntityIds ?? {}).map(id => Number(id));
          permutations.forEach(([currency, couponType, seniority]: [string, string, string]) => {
            entity_ids.forEach((id: number) => {
              request.push({
                issuing_entity_id: id,
                currency: currency,
                seniority: seniority,
                coupon_type: couponType,
              });
            });
          });
          return this.syndicatePricerApiService.validatePricingRequestCreation(source, request);
        }),
      )
      .subscribe(res => (this.warningMessages = res));
  }
  private updateRows() {
    this.rows = Object.keys(this.maturitiesForm.value).map(key => ({ value: key, label: key.replaceAll('_', ' ') }));
  }

  deleteRow(tabName: string | number | boolean) {
    this.maturitiesForm.removeControl(tabName.toString());
    this.updateRows();
  }

  public isValid() {
    if (this.maturitiesForm.valid) {
      return true;
    }
    this.formService.showFormErrors(this.maturitiesForm, 'Requested curves maturities are required!');
    return false;
  }
}
