import { Injectable, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService, CustomRouterState, RouterSelector, UtilService } from '@morpho/core';
import { USERPILOT_KEY, USERPILOT_PROXY, environment, license, localProxyTarget } from '@morpho/environment';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { detect } from 'detect-browser';
import * as moment from 'moment-timezone';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { debounceTime, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticatedUserAction } from '../state/authenticated-user/authenticated-user.actions';
import { AuthenticatedUserSelector } from '../state/authenticated-user/authenticated-user.selectors';
import { ConstantsAction } from '../state/constants/constants.actions';
import { DisplayActions } from '../state/display/display.actions';
import { NotificationsAction } from '../state/notifications.actions';
import { PermissionAction } from '../state/permission/permission.actions';
import { PermissionSelector } from '../state/permission/permission.selectors';
import { CoreApiService } from './core-api.service';
import { StateService } from './state.service';

declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private router$: Observable<CustomRouterState> = this.store.select(RouterSelector.state);
  private defaultTitle = 'Origin';

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private coreApiService: CoreApiService,
    private router: Router,
    private stateService: StateService,
    private store: Store<any>,
    private titleService: Title,
    private utilService: UtilService,
    private zone: NgZone,
  ) {
    this.checkBrowser();
    this.enableFun();
    this.displayProxyTarget();
    this.initSentry();
    this.initTours();
    // this.preventBrowserNavigation();
    this.sendAnalytics();
    this.setNgrxState();
    this.setTitle();
    this.showCanvas();
  }

  private checkBrowser() {
    const browser = detect();
    if (browser) {
      const name = browser.name;
      const version = Number(browser.version?.split('.')[0]);
      if (['chrome', 'edge', 'edge-chromium'].includes(name) && version >= 90) {
        // Chrome 90: 14-Apr-2021
        // https://en.wikipedia.org/wiki/Google_Chrome_version_history
        // Edge 90: 15-Apr-2021
        // https://docs.microsoft.com/en-us/deployedge/microsoft-edge-release-schedule
        return;
      }
      if (name === 'firefox' && version >= 88) {
        // Firefox 88: 19-Apr-2021
        // https://en.wikipedia.org/wiki/Firefox_version_history
        return;
      }
    }
    this.store.dispatch(
      DisplayActions.showBanner({
        params: {
          name: 'out-of-date-browser',
          text: 'Your browser version is no longer supported by Origin. Please update your browser for a smoother experience',
          class: 'is-warning',
        },
      }),
    );
  }

  private enableFun() {
    this.stateService.get.authenticatedUser$.subscribe(user => {
      const today = moment();
      const month = today.format('MMMM');
      const day = today.date();

      // update these each year
      const easterSunday = '2024-03-31';
      const cnyDates = ['2024-02-09', '2024-02-20'];

      if (today.isBetween(cnyDates[0], cnyDates[1], 'date', '[]')) {
        document.body.classList.add('om-lunar-new-year');
      } else if (today.isBetween(moment(easterSunday).subtract(7, 'days'), easterSunday, 'date', '[]')) {
        document.body.classList.add('om-easter');
      } else if (user.isStaff && month === 'October' && day > 20) {
        document.body.classList.add('om-halloween');
      } else if (month === 'December') {
        document.body.classList.add('om-christmas');
      }
    });
  }

  private displayProxyTarget() {
    if (!environment.deployed) {
      this.store.dispatch(
        DisplayActions.showBanner({
          params: {
            name: 'proxy-target',
            text: `Your backend is pointing to environment *** ${localProxyTarget} ***`,
            class: 'is-label',
          },
        }),
      );
    }
  }

  private initSentry() {
    if (!environment.deployed) {
      return;
    }

    const releaseId = document.firstChild?.nodeValue?.trim();
    if (releaseId) {
      Sentry.init({
        dsn: license.sentry,
        environment: environment.name,
        release: `morpho@${releaseId}`,
        debug: false,
        tracesSampleRate: 0,
        integrations: [Sentry.replayIntegration()],
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }

  private initTours() {
    const proxyUrl = (USERPILOT_PROXY as any)[environment.name];
    window.userpilotSettings = {
      token: USERPILOT_KEY,
      domain: proxyUrl,
      endpoint: proxyUrl,
      proxy: 'all',
    };

    const userpilotSrc = `https://${proxyUrl}/sdk/latest.js`;

    const onToursLoaded = () => {
      this.store
        .select(RouterSelector.path)
        .pipe(
          debounceTime(250),
          switchMap(() => {
            if (this.apiService.isNetworkIdle) {
              return of(null);
            }
            return this.apiService.networkIdle$.pipe(take(1));
          }),
          tap(() => {
            window.userpilot?.reload();
          }),
        )
        .subscribe();

      this.actions$
        .pipe(
          ofType(DisplayActions.reloadUserpilot),
          debounceTime(250),
          tap(() => {
            window.userpilot?.reload();
          }),
        )
        .subscribe();
    };

    this.store
      .select(PermissionSelector.areToursEnabled)
      .pipe(
        filter(areToursEnabled => areToursEnabled != null),
        take(1),
        mergeMap(areToursEnabled => {
          if (areToursEnabled) {
            return of(areToursEnabled);
          } else {
            return EMPTY;
          }
        }),
        mergeMap(() => {
          return forkJoin([this.stateService.get.authenticatedUser$, this.stateService.get.pocSettings$]);
        }),
        tap(([user, pocSettings]) => {
          const script = document.createElement('script');
          script.src = userpilotSrc;
          script.onload = () => {
            const identifier = `${environment.name}_${user.id}`;
            const properties = {
              company_name: user.company_name,
              company_type: user.company_type_label,
              team_name: user.team_name,
              issuer_led_trade_workflow: pocSettings.issuer_led_trade_workflow,
            };
            window.userpilot.identify(identifier, properties);
            onToursLoaded();
          };
          document.head.appendChild(script);
        }),
      )
      .subscribe();
  }

  private preventBrowserNavigation() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        history.pushState(null, document.title, location.href);
      }
    });
    window.addEventListener('popstate', () => {
      history.pushState(null, document.title, location.href);
    });
  }

  private sendAnalytics() {
    if (!['qa', 'demo2', 'login2'].includes(environment.name)) {
      return;
    }
    forkJoin([this.stateService.get.authenticatedUser$, this.coreApiService.getAnalyticInfo()]).subscribe(
      ([user, analytics]) => {
        const userProperties: any = {
          ...analytics,
          is_morpho: true,
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth,
          ...(user.isImpersonation ? { impersonating_user: user.id } : null),
          ...(window.matchMedia
            ? {
                is_dark_theme_preferred: window.matchMedia('(prefers-color-scheme: dark)').matches,
              }
            : null),
        };
        delete userProperties.user_id;

        const heapScript = document.createElement('script');
        heapScript.type = 'text/javascript';
        // eslint-disable-next-line max-len
        heapScript.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load(${license.heap},${JSON.stringify({
            secureCookie: environment.deployed,
          })});
          heap.identify(${analytics.user_id});
          heap.addUserProperties(${JSON.stringify(userProperties)});
          `;
        document.body.appendChild(heapScript);
      },
      error => {},
    );
  }

  private setNgrxState() {
    forkJoin([
      this.store.select(AuthenticatedUserSelector.state).pipe(
        filter(user => !!user.id),
        take(1),
      ),
      this.actions$.pipe(ofType(PermissionAction.getPocSettingsSuccess), take(1)),
    ]).subscribe(([authenticatedUser]) => {
      this.store.dispatch(PermissionAction.calculatePermissions({ params: { authenticatedUser: authenticatedUser } }));
    });

    forkJoin([
      this.store.select(AuthenticatedUserSelector.state).pipe(
        filter(user => !!user.id),
        take(1),
      ),
      this.store.select(PermissionSelector.canCreateTrade).pipe(
        filter(canCreateTrade => canCreateTrade != undefined),
        take(1),
      ),
    ]).subscribe(([authenticatedUser, canCreateTrade]) => {
      if (authenticatedUser.isDealer && canCreateTrade) {
        this.store.dispatch(PermissionAction.getTradeShortcuts());
      }
    });

    this.store.dispatch(PermissionAction.getPocSettings());
    this.store.dispatch(ConstantsAction.getConstants());
    this.store.dispatch(NotificationsAction.getNotifications());
    this.store.dispatch(AuthenticatedUserAction.getAuthenticatedUser());

    const dispatchAction = () => {
      this.store.dispatch(
        DisplayActions.setWindowSize({
          params: {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
          },
        }),
      );
    };
    dispatchAction();
    const onResize = this.utilService.debounce(dispatchAction, 500);
    window.addEventListener('resize', onResize);
  }

  private setTitle() {
    this.router$.subscribe((router: CustomRouterState) => {
      const pageTitle = router?.data?.title;
      this.titleService.setTitle(pageTitle ? `${pageTitle} - ${this.defaultTitle}` : this.defaultTitle);
    });
  }

  private showCanvas() {
    window.addEventListener('visibilitychange', () => {
      const canvasList = document.getElementsByTagName('canvas');
      if (document.visibilityState === 'visible') {
        for (const canvas of canvasList) {
          canvas.style.visibility = 'hidden';
          setTimeout(() => {
            canvas.style.visibility = 'visible';
          }, 100);
        }
      }
    });
  }
}
