import { Pipe, PipeTransform } from '@angular/core';
import { LegacyService } from '@morpho/core';

enum RouterPipeParams {
  DEALER = 'dealer',
  DEALER_DASHBOARD = 'dealerDashboard',
  ISSUERS_DEALER_LIST = 'issuersDealerList',
}

@Pipe({
  standalone: false,
  name: 'router',
})
export class RouterPipe implements PipeTransform {
  constructor(private legacyService: LegacyService) {}

  transform(id: number, dataType: RouterPipeParams | string): string {
    switch (dataType) {
      case RouterPipeParams.DEALER:
        return `/dealers/${id}`;
      case RouterPipeParams.DEALER_DASHBOARD:
        return `/dashboard/${id}`;
      case RouterPipeParams.ISSUERS_DEALER_LIST:
        return `/dealers`;
      default:
        return 'undefined route';
    }
  }
}
