import { getCdnUrl } from '@morpho/core';

const iconDirectory = getCdnUrl('assets/icons/');
const fontawesomeDirectory = getCdnUrl('assets/fontawesome-pro-5.12.0/svgs/');
const getMaterialIcon = (name: string) =>
  getCdnUrl(`assets/material-design-icons/web/${name}/materialsymbolsoutlined/${name}_wght300_24px.svg`);

export const icons = {
  origin: iconDirectory + 'origin-markets.svg',
  playground: fontawesomeDirectory + 'light/smile-wink.svg',
  theme: fontawesomeDirectory + 'light/palette.svg',
  customer_support: getMaterialIcon('help'),

  // generic
  back: fontawesomeDirectory + 'light/long-arrow-left.svg',
  left: fontawesomeDirectory + 'light/arrow-left.svg',
  right: fontawesomeDirectory + 'light/arrow-right.svg',
  check: iconDirectory + 'check.svg',
  check_circle: fontawesomeDirectory + 'light/check-circle.svg',
  chevron_up: fontawesomeDirectory + 'light/chevron-up.svg',
  chevron_down: fontawesomeDirectory + 'light/chevron-down.svg',
  chevron_left: fontawesomeDirectory + 'light/chevron-left.svg',
  chevron_right: fontawesomeDirectory + 'light/chevron-right.svg',
  caret_down: fontawesomeDirectory + 'solid/caret-down.svg',
  cross: iconDirectory + 'cross.svg',
  dash: iconDirectory + 'dash.svg',
  external_link: iconDirectory + 'external-link.svg',
  pending: fontawesomeDirectory + 'light/clock.svg',
  warning: fontawesomeDirectory + 'solid/exclamation-circle.svg',
  completed: iconDirectory + 'check.svg',
  chart_line: fontawesomeDirectory + 'light/chart-line.svg',
  info: fontawesomeDirectory + 'light/info-circle.svg',
  visibility_off: getMaterialIcon('visibility_off'),

  // actions
  add: iconDirectory + 'plus.svg',
  approve: fontawesomeDirectory + 'light/check.svg',
  plus: iconDirectory + 'plus.svg',
  duplicate: fontawesomeDirectory + 'light/copy.svg',
  remove: fontawesomeDirectory + 'light/times.svg',
  close: fontawesomeDirectory + 'light/times.svg',
  clear: fontawesomeDirectory + 'light/times.svg',
  reload: fontawesomeDirectory + 'regular/redo.svg',
  find_doc: fontawesomeDirectory + 'regular/file-search.svg', // TODO: Check with Taher re icons to use
  upload: fontawesomeDirectory + 'light/upload.svg',
  revert: fontawesomeDirectory + 'light/undo.svg',
  find_in_page: getMaterialIcon('find_in_page'),
  find_in_inputs: getMaterialIcon('manage_search'),
  check_resolved: getMaterialIcon('check'),

  copy_to_clipboard: fontawesomeDirectory + 'light/copy.svg',
  bloomberg: iconDirectory + 'bloomberg.svg',
  delete: fontawesomeDirectory + 'light/trash-alt.svg',
  delete_undo: fontawesomeDirectory + 'light/trash-undo-alt.svg',
  download: fontawesomeDirectory + 'light/download.svg',
  excel: iconDirectory + 'excel.svg',
  email: fontawesomeDirectory + 'light/envelope.svg',
  more: fontawesomeDirectory + 'solid/ellipsis-h.svg',
  phone: fontawesomeDirectory + 'light/phone.svg',
  refresh: fontawesomeDirectory + 'light/sync.svg',
  share: fontawesomeDirectory + 'light/share-alt.svg',
  share_url: fontawesomeDirectory + 'light/link.svg',

  notes: fontawesomeDirectory + 'light/comment-plus.svg',
  notes_solid: fontawesomeDirectory + 'solid/comment-plus.svg',

  view: iconDirectory + 'view.svg',
  file_download: iconDirectory + 'file-download.svg',
  folder: fontawesomeDirectory + 'light/folder.svg',
  zipped_folder: iconDirectory + 'zipped_folder.svg',

  // rich text
  underline: getMaterialIcon('format_underlined'),
  bold: getMaterialIcon('format_bold'),
  italic: getMaterialIcon('format_italic'),
  align_left: getMaterialIcon('format_align_left'),
  align_center: getMaterialIcon('format_align_center'),
  align_right: getMaterialIcon('format_align_right'),
  align_justify: getMaterialIcon('format_align_justify'),
  indent: getMaterialIcon('format_indent_increase'),
  outdent: getMaterialIcon('format_indent_decrease'),
  ordered_list: getMaterialIcon('format_list_numbered'),
  unordered_list: getMaterialIcon('format_list_bulleted'),
  table: getMaterialIcon('table'),
  hyperlink: getMaterialIcon('link'),
  undo: getMaterialIcon('undo'),
  redo: getMaterialIcon('redo'),
  text_format: getMaterialIcon('text_format'),

  // Login
  password_hidden: fontawesomeDirectory + 'solid/eye-slash.svg',
  password_visible: fontawesomeDirectory + 'solid/eye.svg',

  // trades
  activities: fontawesomeDirectory + 'light/hourglass-end.svg',
  inputs: fontawesomeDirectory + 'light/list-ul.svg',
  document: fontawesomeDirectory + 'light/file-alt.svg',
  documentationOptionalDocuments: fontawesomeDirectory + 'light/file-plus.svg',
  archive: iconDirectory + 'archive.svg',
  unarchive: iconDirectory + 'unarchive.svg',
  more_vertical: fontawesomeDirectory + 'light/ellipsis-v.svg',
  more_vertical_strong: iconDirectory + 'more-vertical-strong.svg',
  add_document: fontawesomeDirectory + 'light/plus-circle.svg',
  resend_review: fontawesomeDirectory + 'light/redo.svg',
  global_review_complete: fontawesomeDirectory + 'light/check-double.svg',
  single_review_complete: fontawesomeDirectory + 'light/check.svg',
  private_comment: fontawesomeDirectory + 'light/lock-alt.svg',
  file_upload: iconDirectory + 'file-upload.svg',
  draft_termsheet: iconDirectory + 'draft-termsheet.svg',

  // document-generator
  documentationHistory: getMaterialIcon('difference'),
  no_comments: getMaterialIcon('speaker_notes_off'),
  no_edits: getMaterialIcon('edit_off'),
  person_alert: getMaterialIcon('person_alert'),

  // pdf viewer controls
  zoom_plus: fontawesomeDirectory + 'light/search-plus.svg',
  zoom_minus: fontawesomeDirectory + 'light/search-minus.svg',
  max_width: fontawesomeDirectory + 'light/arrows-h.svg',

  // form
  calendar: fontawesomeDirectory + 'light/calendar-alt.svg',
  edit: fontawesomeDirectory + 'light/pen.svg',
  save: fontawesomeDirectory + 'light/save.svg',
  search: fontawesomeDirectory + 'light/search.svg',
  file: fontawesomeDirectory + 'light/paperclip.svg',
  file_pdf: fontawesomeDirectory + 'light/file-pdf.svg',
  file_word: fontawesomeDirectory + 'light/file-word.svg',
  file_excel: fontawesomeDirectory + 'light/file-excel.svg',
  comment: fontawesomeDirectory + 'light/comment-alt-lines.svg',
  locked_comment: iconDirectory + 'locked-comment.svg',
  suggestion: fontawesomeDirectory + 'light/comment-alt-edit.svg',
  error: fontawesomeDirectory + 'light/exclamation-circle.svg',
  from_termsheet: iconDirectory + 'from-termsheet-2.svg',
  file_description: getMaterialIcon('description'),

  // main view top
  authNotifications: fontawesomeDirectory + 'light/user-friends.svg',
  help: fontawesomeDirectory + 'light/question-circle.svg',
  settings: fontawesomeDirectory + 'light/cog.svg',
  standardNotifications: fontawesomeDirectory + 'light/bell.svg',

  // main view side
  clients: fontawesomeDirectory + 'light/address-book.svg',
  funding_levels: fontawesomeDirectory + 'light/university.svg',
  home: fontawesomeDirectory + 'light/home.svg',
  profile: fontawesomeDirectory + 'light/user.svg',
  templates: fontawesomeDirectory + 'light/books.svg',
  trades: fontawesomeDirectory + 'light/file-alt.svg',
  custom_pages: fontawesomeDirectory + 'light/window.svg',

  // issuer
  request_access: fontawesomeDirectory + 'light/lock-alt.svg',
  blackout: fontawesomeDirectory + 'solid/exclamation-triangle.svg',
  dealer_comment: fontawesomeDirectory + 'light/comment-alt.svg',
  dealer_comment_add: fontawesomeDirectory + 'light/comment-alt-plus.svg',
  general_comments: fontawesomeDirectory + 'light/file-alt.svg',
  custom_issuer: fontawesomeDirectory + 'solid/pencil.svg',
  priority: fontawesomeDirectory + 'solid/arrow-up.svg',
  priority_not: fontawesomeDirectory + 'light/arrow-up.svg',
  favourite: fontawesomeDirectory + 'solid/star.svg',
  favourite_not: fontawesomeDirectory + 'light/star.svg',
  reaffirm_curve: fontawesomeDirectory + 'light/sync-alt.svg',
  request_curve_update: iconDirectory + 'request-update-3.svg',

  has_access: fontawesomeDirectory + 'solid/check-circle.svg',
  requested_access: fontawesomeDirectory + 'solid/clock.svg',
  no_access: fontawesomeDirectory + 'solid/do-not-enter.svg',

  // user list
  active: fontawesomeDirectory + 'solid/user.svg',
  inactive: fontawesomeDirectory + 'solid/user-slash.svg',
  blocked: iconDirectory + 'ban.svg',
  alias: fontawesomeDirectory + 'solid/user-friends.svg',

  // working group
  addUser: fontawesomeDirectory + 'light/user-plus.svg',
  team: getMaterialIcon('groups'),

  // dealer list
  missing_data: fontawesomeDirectory + 'solid/exclamation-triangle.svg',
  unannounced_dealer: fontawesomeDirectory + 'solid/pen.svg',

  // user widgets
  availableLicenses: fontawesomeDirectory + 'light/users.svg',
  activeUsers: fontawesomeDirectory + 'light/users-medical.svg',

  // syndicate pricer
  open_request: fontawesomeDirectory + 'light/envelope-open.svg',
  input_pricing: fontawesomeDirectory + 'light/file-invoice-dollar.svg',
  request_pricing: iconDirectory + 'request_pricing.svg',
  view_pricing: fontawesomeDirectory + 'light/eye.svg',
  change_funding_basis: fontawesomeDirectory + 'light/exchange.svg',
  create_request: fontawesomeDirectory + 'light/plus-square.svg',
  calculate_pricing: fontawesomeDirectory + 'light/calculator.svg',

  //timeline
  shared: fontawesomeDirectory + 'light/user.svg',
  skipped: iconDirectory + 'forward.svg',

  //extractor
  low_confidence: iconDirectory + 'low-confidence-score.svg',
  help_info: getMaterialIcon('lightbulb'),
};
