<mat-expansion-panel (closed)="closePanel()" (opened)="openPanel()">
  <mat-expansion-panel-header>
    <mat-panel-title
      ><span> {{ columnConfig.label }} </span>
      @if (isSelectAllIndeterminate && !isPanelOpen) {
        <mat-chip-listbox>
          @for (option of displayOptions; track option) {
            <mat-chip
              >{{ option.label }}
              <span><mat-icon svgIcon="remove" matChipRemove (click)="onSelectOption(option, false)"></mat-icon></span>
            </mat-chip>
          }
          @if (hiddenOptions.length) {
            <mat-chip>+{{ hiddenOptions.length }} MORE</mat-chip>
          }
        </mat-chip-listbox>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>
  @if (!columnConfig.disableMiniFilter) {
    <om-shared-search fieldClass="is-chip" (onSearch)="applyMiniFilter($event)"></om-shared-search>
  }
  <section>
    <p>
      @if (!isSelectAllHidden) {
        <mat-checkbox
          [checked]="isSelectAllChecked"
          [indeterminate]="isSelectAllIndeterminate"
          (change)="onSelectAll($event.checked)"
        >
          Select All
        </mat-checkbox>
      }
      @for (option of filteredOptions; track option) {
        <mat-checkbox [(ngModel)]="option.selected" (ngModelChange)="onSelectOption(option, $event)">{{
          option.label
        }}</mat-checkbox>
      }
    </p>
  </section>
</mat-expansion-panel>
